import strings from "./strings.js";
import trkData from "./data.js";
import state from "./state.js";
import domNodes from "./domNodes.js";
import { openDialogPanel } from "./panel-nav.js";
import { createListing, defaultListItemSort } from "./item-listing.js";
import { mapModes } from "./const.js";
import user from "./user.js";
import { getDisplayFilterForEventType } from "./display-filter.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";

import _ from "lodash";
import $j from "jquery";
import { el, svg } from "redom"; // https://redom.js.org/

export function openAlertsForAsset(asset) {
	if (user.isAnonymous) {
		return;
	}
	// TODO in live mode we should include any alerts still requiring acknowledgement that aren't already included in the live listing
	var dataSource =
		state.activeMapMode === mapModes.LIVE
			? trkData.live.normalizedEventsByAssetId
			: trkData.history.normalizedEventsByAssetId;
	var alerts = _.sortBy(
		_.filter(dataSource[asset.Id], getDisplayFilterForEventType("alerts")),
		defaultListItemSort
	).reverse();
	createListing(alerts, "alerts");
	openDialogPanel(
		domNodes.dialogs.assetAlerts,
		strings.ALERTS,
		asset,
		false,
		null,
		"asset",
		"asset-alerts",
		openAlertsForAsset
	);
}

export function loadAssetAlerts(asset) {
	// clear existing
	var table = $j("#AssetAlerts").DataTable();
	table.clear().draw();

	var btn = $j("#RefreshAssetAlerts");
	var data = { assetId: asset.Id };
	btn.addClass("disabled");
	toggleLoadingMessage(true, "asset-alerts");
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetAssetAlerts"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			btn.removeClass("disabled");
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					// populate alerts table
					var assetAlertsData = [];
					var alerts = result.Alerts;
					for (var i = 0; i < alerts.length; i++) {
						var alert = alerts[i];
						var filterItems = [];
						for (var j = 0; j < alert.Filters.length; j++) {
							filterItems.push(el("li", alert.Filters[j]));
						}
						var filters = el("ul", filterItems);
						assetAlertsData.push([
							alert.Id,
							el(
								"form",
								{ method: "get", action: "Alerts/EditAlert/" + alert.Id },
								el(
									"button.alert-edit.btn.btn-secondary",
									{ title: strings.EDIT, dataset: { alertId: alert.Id } },
									svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#edit" } }))
								)
							),
							alert.Name,
							alert.Description,
							alert.Type,
							filters,
							el(
								"button.alert-remove.btn.btn-secondary",
								{
									title: strings.REMOVE_FROM_ALERT,
									disabled: alert.IsLinkedByGroup,
									dataset: { alertId: alert.Id, alertType: alert.Type, linkedByGroup: alert.IsLinkedByGroup },
								},
								svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#minus" } }))
							),
						]);
					}

					table.rows.add(assetAlertsData).draw();
				} else {
					handleWebServiceError(strings.MSG_GET_ALERTS_ERROR);
				}
			}
			toggleLoadingMessage(false, "asset-alerts");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_GET_ALERTS_ERROR);
			toggleLoadingMessage(false, "asset-alerts");
			btn.removeClass("disabled");
		},
	});
}
