import trkData from "./data.js";
import { convertToLatLngPreference } from "./preferences.js";
import { map } from "./map-base.js";
import { extendBounds } from "./map-bounds.js";
import { markerClick, markerUnhover, markerHover } from "./marker-click.js";
import user from "./user.js";
import { isItemIncluded } from "./polyfills.js";
import { addItemToMap } from "./map-items.js";
import AssetSprite from "./gleo-asset-sprite.js";
import {convertNamedColorToArray} from "./color.js";

import $ from "jquery";
// import L from "leaflet";
import _ from "lodash";

export function addPlaceMarker(latlng, location, place) {
	if (latlng == null) {
		return null;
	}
	extendBounds(latlng);

	var color = place.Color ?? "red";
	// if (color == null) {
	// color = "white";
	// }
	// var imagePath = createMarkerPath("Generic", color, null, null, null, false);
	// var imagePath = createMarkerPath("Generic", "white", null, null, null, false);

	var placeMap = map;
	var isCurrentlyActive = !isItemIncluded(user.displayPreferences.hiddenPlaces, place.Id);
	if (!isCurrentlyActive) {
		placeMap = null;
	}

	const marker = new AssetSprite(latlng, "Generic", color);

	marker.data = {
		location: null,
		placeId: null,
		tint: null, // convertNamedColorToArray(color), // temporarily remove tint
	};
	if (placeMap != null) {
		addItemToMap(marker);
	}

	location.marker = marker;
	if (location != null) {
		//$j.data(marker, 'location', location);
		marker.data.location = location;
	}
	if (place != null) {
		//$j.data(marker, 'placeId', place.Id);
		marker.data.placeId = place.Id;
	}
	marker.on("pointerover", function (e) {
		markerHover(marker);
		//var evt = e.originalEvent;
		//evt.preventDefault = true;
		//$j('#fence-tooltip').tooltip('option', 'position', { of: evt, my: 'left+15 bottom+15', at: 'right center', }).tooltip('option', 'content', place.Name).tooltip('open');
	});
	marker.on("pointerout", function (e) {
		markerUnhover(marker);
		//$j('#fence-tooltip').tooltip('close');
	});
	marker.on("click", function (e) {
		//placeClick(marker);
		markerClick(marker, "place", e.latlng, true);
	});
	trkData.placeMarkers.push(marker);
	return marker;
}

export function enablePlaceMarkerDraggable(marker) {
	if (marker === undefined || marker === null) {
		return;
	}

	marker.draggable = true;
	marker.data.isMoved = true;

	function onDragEnd(ev) {
		var latLng = ev.target.geometry.asLatLng();

		$("#add-place-location").text(convertToLatLngPreference(latLng[0], latLng[1]));
		$("#hfPlaceLat").val(latLng[0]);
		$("#hfPlaceLng").val(latLng[1]);
	};

	marker.on("dragend", onDragEnd);
	marker.once("dragdisable", () => {
		marker.off("dragend", onDragEnd);
	});
}
