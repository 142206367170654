import preferences from "./preferences.js";
import { findAssetById } from "./assets.js";
import user from "./user.js";
import { findJourneyById } from "./journey.js";

export function createMarkerPath(icon, color, heading, alpha, assetId, forceAlpha, type, isFirst, isLast, number) {
	if (color === undefined || color === null) {
		color = "";
	}
	if (heading === undefined || heading === null) {
		heading = "";
	}
	if (alpha === undefined || alpha === null) alpha = "";

	number = typeof number !== "undefined" ? number : "";
	type = typeof type !== "undefined" ? type : "";

	var headingVal = "";
	if (heading != "") {
		heading = Math.floor(heading);
		heading = Math.floor(heading / 5) * 5; // round to nearest 5 degrees
		headingVal = "&heading=" + heading;
	}

	var alphaVal = "";
	if (alpha !== "") {
		if (preferences.PREFERENCE_ALPHA_POSITIONS || forceAlpha) {
			if (Math.ceil(alpha) !== 255) {
				alphaVal = "&alpha=" + Math.ceil(alpha);
			}
		}
	}

	var colorVal = "&color=" + color;
	// if custom icon and no heading, no color needed
	if (icon.toLowerCase() === "upload" && heading === "") {
		colorVal = "";
	}

	var aidVal = "";
	var lmVal = "";
	if (icon.toLowerCase() === "upload") {
		aidVal = "&aid=" + assetId;
		if (assetId != null) {
			var asset = findAssetById(assetId);
			if (asset !== null) {
				lmVal = "&lm=" + asset.IconModified;
			}
		}
	}

	var typeVal = "";
	if (type !== null && type !== "") {
		typeVal = "&type=" + type;
	}

	var numberVal = "";
	if (number !== null && number !== "") {
		numberVal = "&num=" + number;
	}

	var flagVal = "";
	var hideFlags = false;
	if ((isFirst === true || isLast === true) && assetId != null) {
		if (assetId != null) {
			var asset = findAssetById(assetId);
			hideFlags = asset.HideFlags;
		}
	}
	if (!hideFlags) {
		if (isFirst === true) {
			flagVal = "&flag=begin";
		} else if (isLast === true) {
			flagVal = "&flag=end";
		}
	}

	var imagePath =
		"/markers/" + icon + "?" + colorVal + headingVal + alphaVal + aidVal + typeVal + flagVal + numberVal + lmVal;
	if (user.isImpersonated && aidVal !== "") {
		imagePath += "&ishr=" + encodeURIComponent(user.id);
	}
	// if (options.useStaticSubdomains) {
	// 	// TODO: handle this with a httpmodule instead?
	// 	// serve static content from other subdomains to improve request handling
	// 	var domain = "static";
	// 	switch (icon.toLowerCase()) {
	// 		case "person":
	// 		case "boat":
	// 		case "airplane":
	// 		case "yacht":
	// 			domain += "1";
	// 			break;
	// 		case "truck":
	// 		case "car":
	// 		case "upload":
	// 		case "helicopter":
	// 			domain += "2";
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// 	// return 'https://domain.actualdomain.com{imagePath}'
	// }
	return imagePath;
}

export function getGenericIconUrlForItemType(type, item) {
	var icon = null;
	switch (type) {
		case "fences":
			icon =
				"url(" + createMarkerPath("Fence", item.Color.substring(1), null, null, null, false, null, false, false) + ")";
			break;
		case "places":
			icon = "url(" + createMarkerPath("Generic", item.Color, null, null, null, false, null, false, false) + ")";
			break;
		case "assets":
			icon = "url(" + createMarkerPath(item.Class, item.Color, null, null, item.Id, false, null, false, false) + ")";
			break;
		//case 'journeys': // fallthrough
		case "trips":
			if (type === "trips") {
				var journey = findJourneyById(item.JourneyId);
				var asset = findAssetById(journey.AssetId);
				icon =
					"url(" + createMarkerPath(asset.Class, asset.Color, null, null, asset.Id, false, null, false, false) + ")";
			} else {
				icon =
					"url(" +
					createMarkerPath("Fence", item.Color.substring(1), null, null, null, false, null, false, false) +
					")";
			}
			break;
		//case 'groups': // TODO... SVG support here to match listing
		//    icon = 'url(' + createMarkerPath('Fence', item.Color.substring(1), null, null, null, false, null, false, false) + ')';
		//    break;
		//case 'shared-views': // TODO... SVG support here
		//    break;
		default: // dialog
			break;
	}
	return icon;
}
