import strings from "./strings.js";

import $j from "jquery";

const preferences = {
	// include defaults for intellisense
	PREFERENCE_SPEED: 0,
	PREFERENCE_LAT_LNG: 1,
	PREFERENCE_FUEL_UNIT: 1,
	PREFERENCE_MOROCCO_OVERLAY: false,
	PREFERENCE_REMOVE_ROADS: false,
	PREFERENCE_GROUP_POSITIONS: true,
	PREFERENCE_ALPHA_POSITIONS: true,
	PREFERENCE_EMERGENCY_AUDIO: false,
};

export default preferences;

export function convertAltitudeToPreference(altitude) {
	if (altitude == null) return "";
	if (altitude == "") return "";

	switch (preferences.PREFERENCE_SPEED) {
		case 0:
		case 3: // knots/feet
			return (altitude * 3.2808399).toFixed(1) + " " + altitudeText();
		case 1:
		case 2:
		default:
			return altitude.toFixed(1) + " " + altitudeText();
			break;
	}
}

export function convertSpeedToPreference(speed) {
	switch (preferences.PREFERENCE_SPEED) {
		case 0:
			return (speed * 2.23693629).toFixed(1) + " " + speedText();
		case 1:
			return (speed * 3.6).toFixed(1) + " " + speedText();
		case 2:
		case 3:
			return (speed * 1.94384449).toFixed(1) + " " + speedText();
		default:
			return (speed * 2.23693629).toFixed(1) + " " + speedText();
	}
}

export function convertToLatLngPreference(lat, lng, grid) {
	var pref = preferences.PREFERENCE_LAT_LNG;
	if ((pref == 3 && grid == null) || (pref == 4 && grid == null) || (pref == 5 && grid == null)) {
		pref = 0;
	}
	switch (pref) {
		case 1:
			return convertDecimalDegrees(lat, lng);
		case 2:
			return convertDegreesDecimalMinutes(lat, lng);
		case 3:
		case 4:
			return grid;
		case 5:
			if (grid !== null && grid.length === 8) {
				return grid;
			}
			return strings.NOT_IN_UK;
		case 0:
		default:
			return lat.toFixed(6) + ", " + lng.toFixed(6);
		//case 3:
		// pull Grid from position
		//    return '';
		//return convertMGRS(lat, lng);
	}
}

function convertDegreesDecimalMinutes(lat, lng) {
	var directionLat = lat > 0 ? "N" : "S";
	var directionLng = lng > 0 ? "E" : "W";
	var degreesLat = Math.floor(Math.abs(lat));
	var degreesLng = Math.floor(Math.abs(lng));
	var minutesLat = ((Math.abs(lat) - Math.abs(degreesLat)) * 60).toFixed(4);
	var minutesLng = ((Math.abs(lng) - Math.abs(degreesLng)) * 60).toFixed(4);
	return degreesLat + "°" + minutesLat + "'" + directionLat + " " + degreesLng + "°" + minutesLng + "'" + directionLng;
}

function convertDecimalDegrees(lat, lng) {
	var originalLat = lat;
	lat = Math.abs(lat);
	var degreesLat = Math.floor(lat);
	var minutesLat = Math.floor((lat - degreesLat) * 60);
	var secondsLat = (((lat - degreesLat) * 60 - minutesLat) * 60).toFixed(4);
	var directionLat = originalLat > 0 ? "N" : "S";

	var originalLng = lng;
	lng = Math.abs(lng);
	var degreesLng = Math.floor(lng);
	var minutesLng = Math.floor((lng - degreesLng) * 60);
	var secondsLng = (((lng - degreesLng) * 60 - minutesLng) * 60).toFixed(4);
	var directionLng = originalLng > 0 ? "E" : "W";

	return (
		degreesLat +
		"°" +
		minutesLat +
		"'" +
		secondsLat +
		'"' +
		directionLat +
		" " +
		degreesLng +
		"°" +
		minutesLng +
		"'" +
		secondsLng +
		'"' +
		directionLng
	);
}

export function convertFromMetresToUserDistancePreference(distance) {
	switch (preferences.PREFERENCE_SPEED) {
		case 3: // knots/nautical miles - avionics
		case 2: // knots/nautical miles
			return ((distance / 1000) * 0.539956803).toFixed(2) + " nmi";
		case 0: // miles
			var feet = 3.2808399 * distance;
			if (feet > 5280) return (feet / 5280).toFixed(2) + " mi";
			return Math.floor(feet) + " ft";
		case 1: // metres/kilometres
		default:
			if (distance < 1000) return Math.floor(distance) + " m";
			return (distance / 1000).toFixed(2) + " km";
	}
}

export function distanceText() {
	switch (preferences.PREFERENCE_SPEED) {
		case 3: // knots/nautical miles - avionics
		case 2: // knots/nautical miles
			return "nmi";
		case 0: // miles
			return "mi";
		case 1: // metres/kilometres
		default:
			return "km";
	}
}

export function fuelText() {
	switch (preferences.PREFERENCE_FUEL_UNIT) {
		case 1:
			// imp mpg
			return strings.FUEL_IMPERIAL;
		case 2:
		case 3:
			// litres
			return strings.FUEL_LITRES;
		case 0:
		default:
			// us mpg
			return strings.FUEL_US;
	}
}

export function altitudeText() {
	switch (preferences.PREFERENCE_SPEED) {
		case 0:
		case 3:
			return "ft";
		case 1:
		case 2:
		default:
			return "m";
	}
}

export function speedText() {
	switch (preferences.PREFERENCE_SPEED) {
		case 1:
			return "kph";
		case 2:
		case 3:
			return "knots";
		case 0:
		default:
			return "mph";
	}
}

export function convertFuelEfficiencyToStandard(fuelEfficiency, fuelEfficiencyType) {
	var efficiency = NaN;
	fuelEfficiency = $j.parseFloat(fuelEfficiency);
	fuelEfficiencyType = parseInt(fuelEfficiencyType);
	switch (fuelEfficiencyType) {
		case 0: // us mpg
			efficiency = (235.2146 / fuelEfficiency).toFixed(4);
			break;
		case 1: // imperial mpg
			efficiency = (282.4809363 / fuelEfficiency).toFixed(4);
			break;
		case 2: // kpl
			efficiency = (100 / fuelEfficiency).toFixed(4);
			break;
		case 3: // l/100 km
			efficiency = fuelEfficiency;
			break;
		default:
			break;
	}
	if (isNaN(efficiency)) return "";
	efficiency = parseFloat(efficiency);
	efficiency = $j.formatGlobalization(efficiency, "n4");
	return efficiency;
}

export function fuelEfficiencyType(type) {
	switch (type) {
		case 0: // us mpg
			return strings.FUEL_USMPG;
		case 1: // imperial mpg
			return strings.FUEL_IMPERIALMPG;
		case 2: // kpl
			return strings.FUEL_KPL;
		case 3: // l/100 km
		default:
			return strings.FUEL_L100KM;
	}
}
