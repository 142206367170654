import trkData from "./data.js";
import { mapModes } from "./const.js";
import state from "./state.js";
import preferences from "./preferences.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import { findAssetById } from "./assets.js";
import { setMapBounds } from "./map-bounds.js";
import { isItemIncluded } from "./polyfills.js";
import domNodes from "./domNodes.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { resizeApp } from "./window-layout.js";
import { handleWebServiceError } from "./ajax.js";
import strings from "./strings.js";
import { updateItemListingIcon, showResultLimitsIfApplicable } from "./item-listing.js";
import { addAssetEvents } from "./asset-events.js";
import user, { displayPreferencesAdd, displayPreferencesRemove } from "./user.js";
import { createHistoryPositionResults, processAssetHistoryPositionsResult } from "./position-history.js";
import { updateGroupVisibilityStatus } from "./asset-group.js";
import { updateActiveAssetInformation } from "./assets-active.js";

import _ from "lodash";

// Will dispatch an event whenever an asset is selected or deselected from the list.
export const assetActiveNotifier = new EventTarget();


/// NOTE: Even though the function name is about an "active" asset, the
/// contept of "active assets" is different - see assets-active.js

export function toggleAssetActive(assetId, makeAssetActive, updateUI) {
	var asset = findAssetById(assetId);
	if (asset === null) {
		return Promise.resolve(true);
	}

	var isCurrentlyActive = !isItemIncluded(user.displayPreferences.hiddenAssets, assetId);
	if (makeAssetActive === null) {
		// assuming toggling active/inactive if not specified
		makeAssetActive = !isCurrentlyActive;
	}

	if (isCurrentlyActive === makeAssetActive) {
		return Promise.resolve(true);
	}

	const ev = new CustomEvent(
		makeAssetActive ? "select" : "deselect",
		{detail: asset}
	);
	assetActiveNotifier.dispatchEvent(ev);

	const promises = [];

	// show/hide waypoints (if any)
	// todo: should waypoints be hidden altogether in history mode?
	var waypoints = _.filter(trkData.waypoints, { AssetId: asset.Id });
	waypoints.forEach((waypoint) => {
		if (makeAssetActive) {
			addItemToMap(waypoint.Location.marker);
		} else {
			removeItemFromMap(waypoint.Location.marker);
		}
	});

	if (state.activeMapMode !== mapModes.LIVE) {
		var isAssetFound = trkData.history.positionsByAssetId[assetId] !== undefined;

		// toggle any map items for the asset
		if (isAssetFound) {
			// show/hide positions connecting line
			if (trkData.history.mapLinesByAssetId[asset.Id] !== undefined) {
				if (makeAssetActive) {
					addItemToMap(trkData.history.mapLinesByAssetId[asset.Id]);
				} else {
					removeItemFromMap(trkData.history.mapLinesByAssetId[asset.Id]);
				}
			}

			// show/hide markers
			if (makeAssetActive) {
				if (preferences.PREFERENCE_GROUP_POSITIONS) {
					if (trkData.history.markerClustersByAssetId[asset.Id] !== undefined) {
						addItemToMap(trkData.history.markerClustersByAssetId[asset.Id]);
					}
				} else {
					_.each(trkData.history.markersByAssetId[assetId], function (item) {
						if (!item.data.location.IsHidden) {
							addItemToMap(item);
						}
					});
				}
			} else {
				// remove marker cluster
				if (preferences.PREFERENCE_GROUP_POSITIONS) {
					if (trkData.history.markerClustersByAssetId[asset.Id] !== undefined) {
						removeItemFromMap(trkData.history.markerClustersByAssetId[asset.Id]);
					}
				} else {
					_.each(trkData.history.markersByAssetId[assetId], function (item) {
						removeItemFromMap(item);
					});
				}
			}

			setMapBounds();
		}

		if (!isAssetFound && makeAssetActive && state.hasQueriedHistory) {
			// query for this specific asset's results to add to existing
			promises.push(querySingleAsset(assetId));
		}
	} else {
		// show/hide latest position from map and from history
		_.each(trkData.live.markersByAssetId[assetId], function (marker) {
			if (makeAssetActive && marker.data.hide !== true) {
				// data.hide is for markers only temporarily visible, loaded outside of active view
				addItemToMap(marker);
			} else {
				removeItemFromMap(marker);
			}
		});
		if (trkData.live.mapLinesByAssetId[asset.Id] !== undefined) {
			if (makeAssetActive) {
				addItemToMap(trkData.live.mapLinesByAssetId[asset.Id]);
			} else {
				removeItemFromMap(trkData.live.mapLinesByAssetId[asset.Id]);
			}
		}
	}

	updateAssetVisibilityStatus(assetId, makeAssetActive);

	// grey out / make active
	var index = _.indexOf(trkData.visible.assets, assetId);
	if (makeAssetActive) {
		updateAssetListingIcon(assetId, asset.Color, true, asset.Class);
		displayPreferencesRemove("hiddenAssets", assetId);
		if (index === -1) {
			trkData.visible.assets.push(assetId);
		}
	} else {
		updateAssetListingIcon(assetId, asset.Color, false, asset.Class);
		displayPreferencesAdd("hiddenAssets", assetId);
		if (index !== -1) {
			trkData.visible.assets.splice(index, 1);
		}
	}

	// these UI updates should really only happen after all assets have been toggled, so for group changes this is rough
	if (updateUI) {
		// update group visibility statuses
		_.each(asset.GroupIds, function (assetGroupId) {
			updateGroupVisibilityStatus(assetGroupId);
		});
		updateGroupVisibilityStatus("all-assets");

		updateActiveAssetInformation();
	}

	return Promise.all(promises);
}

export async function querySingleAsset(assetId) {
	// add a single asset's positions to the existing query results
	var fromDate = trkData.history.fromDate;
	var toDate = trkData.history.toDate;
	// disable show button and show loading message
	domNodes.mapMode.show.disabled = true;
	toggleLoadingMessage(true, assetId);
	var data = {
		fromDate: fromDate,
		toDate: toDate,
		assetIds: assetId,
		zoomLevel: null,
		sensitivity: null,
		format: user.dateFormat,
		lang: user.dateCulture,
		loadLimitedData: false,
		isMobile: state.isMobile,
	};

	return fetch(wrapUrl("/services/GPSService.asmx/GetAssetPositionsForDateRange"), {
		method: "POST",
		body: JSON.stringify(data),
		headers: new Headers({
			"Content-Type": "application/json; charset=utf-8",
		}),
	})
		.then((res) => res.json())
		.then(function (msg) {
			domNodes.mapMode.show.disabled = false;
			toggleLoadingMessage(false, assetId);
			if (msg.d) {
				var result = msg.d;
				if (result.IsLimited === true && result.IncludesLimitedData === false) {
					showResultLimitsIfApplicable(result, false);
					return;
				}

				state.hasQueriedHistory = true;
				_.each(result.Assets, function (assetResult) {
					processAssetHistoryPositionsResult(assetResult);
				});
				if (result.Events !== null) {
					addAssetEvents(result.Events, mapModes.HISTORY);
				}
				createHistoryPositionResults();
				if (state.activeMapMode === mapModes.HISTORY) {
					resizeApp(true);
					setMapBounds();
				}
				showResultLimitsIfApplicable(result, false);
				trkData.history.isLimited = result.IsLimited;
				trkData.history.isLoadedLimitedData = result.IncludesLimitedData;
			}
		})
		.catch(function (error) {
			handleWebServiceError(strings.MSG_SINGLE_ASSET_ERROR);
			// re-enable show button and clear loading message
			domNodes.mapMode.show.disabled = false;
			toggleLoadingMessage(false, assetId);
		});
}

export function updateAssetListingIcon(id, color, isActive, icon) {
	updateItemListingIcon(id, color, isActive, icon, domNodes.assets);
}

function updateAssetVisibilityStatus(assetId, makeActive) {
	var index = _.indexOf(trkData.visible.assets, assetId);
	if (makeActive) {
		if (index === -1) {
			trkData.visible.assets.push(assetId);
		}
	} else {
		if (index !== -1) {
			trkData.visible.assets.splice(index, 1);
		}
	}
	_.each(domNodes.assets[assetId], function (assetNode) {
		var visibilityIcon = assetNode.querySelector(".showhide");
		if (makeActive) {
			visibilityIcon
				.querySelector("use")
				.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#visible");
			visibilityIcon.classList.add("active");
		} else {
			visibilityIcon
				.querySelector("use")
				.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#invisible");
			visibilityIcon.classList.remove("active");
		}
	});
}

export function hideAllAssetsExcept(assetId) {
	_.each(trkData.assets, function (asset) {
		var makeActive = asset.Id === assetId;
		toggleAssetActive(asset.Id, makeActive, false);
	});

	_.each(trkData.groups, function (group) {
		updateGroupVisibilityStatus(group.Id);
	});
	updateGroupVisibilityStatus("all-assets");
	updateActiveAssetInformation();
}
