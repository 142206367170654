import state from "./state.js";
import trkData from "./data.js";
import options from "./options.js";
import preferences from "./preferences.js";
import log from "./log.js";
import { getOSRMLanguage } from "./routing.js";
import { language, viewModes } from "./const.js";
import domNodes from "./domNodes.js";
import { mapModes } from "./const.js";
import { updateChosenLocation } from "./map-chooselocation.js";
import { wrapUrl } from "./wrapurl.js";
import {
	enableLayerIridiumNext,
	enableLayerGlobalstar,
	enableLayerInmarsat,
	enableLayerOrbcomm,
	enableLayerGeostationary,
	disableLayerIridiumNext,
	disableLayerGlobalstar,
	disableLayerInmarsat,
	disableLayerOrbcomm,
	disableLayerGeostationary,
} from "./map-satellite.js";
import {
	enableRadar,
	enableLayerClouds,
	checkWeatherForecast,
	disableRadar,
	enableRadarAustraliaActive,
	disableRadarAustralia,
	enableWorldIR,
	disableWorldIR,
	enableLayerOil,
	disableLayerOil,
	enableLayerMaritime,
	disableLayerMaritime,
	disableLayerClouds,
	enableLayerWeather,
	disableLayerWeather,
} from "./map-radar-weather.js";
import { baseTileLayers } from "./map-maptype.js";
import { throttles, intervals } from "./timers.js";
import { resizeApp } from "./window-layout.js";
import { updateAssetState } from "./asset-state.js";
import { updateMapModePanel } from "./map-ui.js";
import { changeMapType, initMapTypes } from "./map-maptype.js";
import { updateGroupFunctionBadges, updateAssetFunctionBadges } from "./badges.js";
import { updateActiveAssetInformation, queryActiveAssets } from "./assets-active.js";
import { setUrlHistoryForActivePanel } from "./panel-browser-history.js";
import { updateTimeBasedNotificationIndicatorsForAsset } from "./asset-notification.js";
import { updateTimeBasedNotificationIndicatorsForGroup } from "./notifications.js";
import { updateLiveFollowStatus } from "./asset-live-follow.js";
import { populateDiagnostics } from "./signalr.js";
import { queryLiveAssets, updateLiveAssets } from "./asset-live.js";
import { getAssetDataGroupForViewAndMapModes } from "./map-viewmode.js";
import { showMapMarkersForDataGroup, hideMapMarkersForDataGroup } from "./data-group.js";
import { filterDateClick } from "./item-listing.js";
import {enableTimeSlider, disableTimeSlider} from "./time-slider.js";

import { initGleo } from "./map-gleo.js";

import $ from "jquery";
import $j from "jquery";
import L from "leaflet";
import _ from "lodash";
const Cookies = window.Cookies; // from 'js.cookie.js', v2.2.0
import { el, text } from "redom";
import omnivore from "leaflet-omnivore";

//// Also possible: import L_Routing from "leaflet-routing-machine"
//import "leaflet-routing-machine/src/index.js";
import "../legacy/leaflet-routing-machine-all.js"; // Customized LRM

import SymbolGroup from "./gleo/src/loaders/SymbolGroup.mjs";

export let directionsService = null;
export let map = null;

/*global MapToolbar */
// import MapToolbar from '../MapToolbar.js';

/*global MBMAP_OPEN, MBLIGHT_OPEN, MBDARK_OPEN, MQ*/
// Set up by scripts/mapquest.js .

L.Icon.Default.imagePath = "/content/images/";

export const layers = {
	traffic: null,
	weather: null,
	clouds: null,
};

const baseLayerOpenStreetMap = L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
	maxZoom: 18,
});

export { baseLayerOpenStreetMap, baseTileLayers };

export function onTileLoaded(layer) {
	return function (evt) {
		var coords = evt.coords;
		var data = {
			//Uid: user.id,
			Type: layer,
			Z: coords.z,
			X: coords.x,
			Y: coords.y,
		};
		trkData.tileLoadedQueue.push(data);
		// add track to queue and throttle/debounce
		throttles.tilesLoaded();
	};
}

export function initMap() {
	// map initialization
	state.bounds = L.latLngBounds();

	var modifyMapBoxUrl = function (url) {
		return url
			.replace("{$z}", "{z}")
			.replace("{$x}", "{x}")
			.replace("{$y}", "{y}")
			.replace("/256/", "/512/")
			.replace("api.mapbox.com", "{s}.tiles.mapbox.com")
			.replace("a.tiles.mapquest.com", "{s}.tiles.mapquest.com");
	};

	for (var baseLayer in options.baseLayers) {
		var item = options.baseLayers[baseLayer];
		if (item.arcgis !== null && item.arcgis !== "" && options.keys.arcGis !== "") {
			item.layer = L.esri.Vector.vectorBasemapLayer(item.arcgis, { apiKey: options.keys.arcGis });
		} else if (item.isVector === true) {
			item.layer = L.maplibreGL({ style: item.url });
		} else {
			switch (baseLayer) {
				case "road":
					item.url = typeof MBMAP_OPEN === "undefined" ? item.url : modifyMapBoxUrl(MBMAP_OPEN);
					break;
				case "roadlight":
					item.url = typeof MBLIGHT_OPEN === "undefined" ? item.url : modifyMapBoxUrl(MBLIGHT_OPEN);
					break;
				case "roaddark":
					item.url = typeof MBDARK_OPEN === "undefined" ? item.url : modifyMapBoxUrl(MBDARK_OPEN);
					break;
			}
			item.layer = L.tileLayer(item.url, {
				subdomains: item.subdomains != null ? item.subdomains : ["a", "b", "c", "d"],
				minZoom: 1, // hmm
				maxZoom: item.maxZoom,
				zIndex: baseLayer == "satellitelabels" ? 3 : 2,
				tileSize: item.tileSize,
				zoomOffset: item.tileSize == 512 ? -1 : 0,
				opacity: 0.9999,
				subdomains: item.subdomains !== "" ? item.subdomains : "abc",
			});
			item.layer.on("tileload", onTileLoaded(baseLayer));
		}
		baseTileLayers.push(item.layer);
	}

	if (preferences.PREFERENCE_REMOVE_ROADS || preferences.PREFERENCE_MOROCCO_OVERLAY) {
		trkData.isSatelliteLabelOverlayEnabled = false;
	}
	var mapOptions = {
		layers: [options.baseLayers.road.layer],
		closePopupOnClick: false, // test
		attributionControl: false,
		zoomControl: false,
		preferCanvas: true, // todo: revisit rendering performance
		worldCopyJump: true, // test
		maxBounds: options.maxBounds,
		maxBoundsViscosity: 1,
		minZoom: options.minimumZoom,
	};

	map = L.map("map", mapOptions).on("load", mapLoaded);
	// A pane for L.Paths that should be beneath the Gleo symbols (i.e. in another
	// <canvas> or <svg> with a lower z-index)
	map.createPane('back-lines');
	const backPane = map.getPane('back-lines');
	const overlayPane = map.getPane('overlayPane');
	backPane.style.zIndex = 350;

	// Hijack the canvas renderer in the back-lines pane, in order to hook up custom
	// behaviour when hovering over a trajectory line or fence polygon - in
	// order to hoist up the CSS rules regarding pointer cursor to the default
	// overlay pane (which is over the back pane in terms of z-index).
	const backCanvas = map._getPaneRenderer('back-lines');
	backCanvas._handleMouseHover = function (ev, point) {
		if (this._mouseHoverThrottled) {
			return;
		}

		let layer, candidateHoveredLayer;

		for (let order = this._drawFirst; order; order = order.next) {
			layer = order.layer;
			if (layer.options.interactive && layer._containsPoint(point)) {
				candidateHoveredLayer = layer;
			}
		}

		if (candidateHoveredLayer !== this._hoveredLayer) {
			this._handleMouseOut(ev);

			if (candidateHoveredLayer) {
				// this._container.classList.add('leaflet-interactive'); // change cursor
				overlayPane.classList.add('leaflet-interactive'); // change cursor
				this._fireEvent([candidateHoveredLayer], ev, 'mouseover');
				this._hoveredLayer = candidateHoveredLayer;
			}
		}

		this._fireEvent(this._hoveredLayer ? [this._hoveredLayer] : false, ev);

		this._mouseHoverThrottled = true;
		setTimeout((() => {
			this._mouseHoverThrottled = false;
		}), 32);
	};

	backCanvas._handleMouseOut = function _handleMouseOut(ev) {
		const layer = this._hoveredLayer;
		if (layer) {
			// if we're leaving the layer, fire mouseout
			// this._container.classList.remove('leaflet-interactive');
			overlayPane.classList.remove('leaflet-interactive');
			this._fireEvent([layer], ev, 'mouseout');
			this._hoveredLayer = null;
			this._mouseHoverThrottled = false;
		}
	},


	initGleo(map);

	map.setView([0, 0], options.minimumZoom);
	L.control.scale({ position: "bottomright" }).addTo(map);
	trkData.previousZoom = options.minimumZoom;

	directionsService = L.Routing.control({
		router: new L.Routing.OSRMv1({
			serviceUrl: "/api/routing/route",
			language: getOSRMLanguage(language),
		}),
		formatter: new L.Routing.Formatter({
			language: getOSRMLanguage(language),
			units: preferences.PREFERENCE_SPEED === 1 ? "metric" : "imperial",
		}),
		//autoRoute: false, // todo: revisit this
		useZoomParameter: true,
	})
		.on("routingstart", function (e) {
			console.log("routing start");
			console.log(e);
		})
		.on("routesfound", function (e) {
			console.log("routes found");
			console.log(e);
		})
		.on("routingerror", function (e) {
			console.log("routing error!");
			console.log(e);
		})
		.on("routeselected", function (e) {
			console.log("route selected");
			console.log(e);
			if (e.route != null) {
				trkData.routeLine = L.polyline(e.route.coordinates);
				if (options.enabledFeatures.indexOf("UI_GEOFENCE_FROM_ROUTE") !== -1) {
					$("#PlaceRouteGeofence,#RouteGeofence,#RouteGeofenceCreate").removeClass("disabled");
				}
			}
		});
	directionsService.control = directionsService.onAdd(map);

	// drag to zoom key events
	$j(document).on("keydown", function (event) {
		// keys[event.which] = true;
		if (event.which === 68) {
			// ctrl+alt+D
			if (event.ctrlKey && event.altKey) {
				populateDiagnostics();
				$j("#map_diagnostics").toggle();
			}
		}
		if (event.which === 17) {
			// while held, it will trigger repeatedly
			if (!trkData.zoom.isActive) {
				// start a timer to clear it after a few seconds, since the user may have
				// used a control combination for a browser shortcut that has no associated keyup
				enableDragZoom();
				state.zoomTimeout = setTimeout(disableDragZoom, 3000);
			} else {
				// reset the timeout
				clearTimeout(state.zoomTimeout);
				state.zoomTimeout = setTimeout(disableDragZoom, 3000);
			}
		}
	});

	$(document).on("keyup", function (event) {
		// delete keys[event.which];
		if (event.which === 17 && trkData.zoom.isActive) {
			disableDragZoom();
		}
	});

	initMapTypes();

	$j("#root").on("mouseleave", "#map_layers", function (e) {
		// clear previous timeout, settimeout to half a second
		intervals.mapLayers = setTimeout(collapseMapLayers, 1000);
	});

	$j("#root").on("mouseenter", "#map_layers", function (e) {
		clearTimeout(intervals.mapLayers);
	});
}

function mapLoaded(ev) {
	log("Map loaded.");
	trkData.mapLayers.other = L.layerGroup();
	trkData.mapLayers.other.setZIndex(8000);

	map.addLayer(trkData.mapLayers.other);

	trkData.mapLayers.normal = L.layerGroup();
	trkData.mapLayers.sharedView = L.layerGroup();
	map.addLayer(trkData.mapLayers.normal);

	changeMapType(options.defaultMapType.toLowerCase());

	if (Cookies.get("layer-iridiumnext") !== undefined || $j.inArray("iridiumnext", options.enableLayers) !== -1) {
		enableLayerIridiumNext();
	}

	if (Cookies.get("layer-globalstar") !== undefined || $j.inArray("globalstar", options.enableLayers) !== -1) {
		enableLayerGlobalstar();
	}

	if (Cookies.get("layer-inmarsat") !== undefined || $j.inArray("inmarsat", options.enableLayers) !== -1) {
		enableLayerInmarsat();
	}

	if (Cookies.get("layer-orbcomm") !== undefined || $j.inArray("orbcomm", options.enableLayers) !== -1) {
		enableLayerOrbcomm();
	}

	if (Cookies.get("layer-geostationary") !== undefined || $j.inArray("geostationary", options.enableLayers) !== -1) {
		enableLayerGeostationary();
	}

	if (Cookies.get("layer-traffic") !== undefined || $j.inArray("traffic", options.enableLayers) !== -1) {
		enableLayerTraffic();
	}

	if (Cookies.get("layer-custom") !== undefined) {
		enableCustomLayers(Cookies.get("layer-custom"));
	}

	// enable custom layers that are enabled by default
	for (var i = 0; i < options.customLayers.length; i++) {
		var layer = options.customLayers[i];
		if (layer.enabled === true) {
			enableCustomLayers(layer.id.toString());
		}
	}

	if (!options.disablePanels) {
		if (Cookies.get("eventsPanel") !== undefined) {
			$j("#event-panel-container").toggle();
			$j("#map_panels").hide();
		}
	}

	map.on("zoomend", checkWeatherForecast);
	map.on("viewreset", function (e) {});
	map.on("zoomend", function (e) {
		var nowZoom = map.getZoom();
		if (nowZoom != trkData.previousZoom) {
			console.log("zoom changed to " + map.getZoom());
		}
		trkData.previousZoom = nowZoom;
	});
	map.on("click", function (event) {
		console.log("map click");
		// console.log(event);
		updateChosenLocation(event.latlng);
	});

	MapToolbar.buttons.$shape = $j(".tool-shape").get(0); //document.getElementById("shape_b");
	//MapToolbar.buttons.$line = $j('.tool-line').get(0); //document.getElementById("line_b");
	MapToolbar.buttons.$circle = $j(".tool-circle").get(0); //document.getElementById("circle_b");

	// map click event for polyline and polygon (adding/deleting points/segments)
	MapToolbar.polyClickEvent = map.on("click", function (event) {
		//console.log(MapToolbar.currentFeature);
		if (
			!MapToolbar.isSelected(MapToolbar.buttons.$shape) &&
			!MapToolbar.isSelected(MapToolbar.buttons.$line) &&
			!MapToolbar.isSelected(MapToolbar.buttons.$circle)
		)
			return;
		if (MapToolbar.currentFeature) {
			MapToolbar.addPoint(event.latlng, MapToolbar.currentFeature);
		}
	});

	$("#map-functions").on("click", "button", function (e) {
		e.preventDefault();
		$(this).bsTooltip("hide");
	});

	$("#map-mode").on("click", "#map-mode-minimize", function (e) {
		e.preventDefault();
		if (domNodes.mapMode.container.classList.contains("is-minimized")) {
			domNodes.mapMode.container.classList.remove("is-minimized");
			this.querySelector("use").setAttributeNS(
				"http://www.w3.org/1999/xlink",
				"href",
				"/content/svg/tracking.svg?v=15#angle-up"
			);
		} else {
			domNodes.mapMode.container.classList.add("is-minimized");
			this.querySelector("use").setAttributeNS(
				"http://www.w3.org/1999/xlink",
				"href",
				"/content/svg/tracking.svg?v=15#angle-down"
			);
		}
	});

	$("#map-functions").on("click", "#map-functions-minimize", function (e) {
		e.preventDefault();
		if (domNodes.mapTools.container.classList.contains("is-minimized")) {
			domNodes.mapTools.container.classList.remove("is-minimized");
			this.querySelector("use").setAttributeNS(
				"http://www.w3.org/1999/xlink",
				"href",
				"/content/svg/tracking.svg?v=15#minus"
			);
		} else {
			domNodes.mapTools.container.classList.add("is-minimized");
			this.querySelector("use").setAttributeNS(
				"http://www.w3.org/1999/xlink",
				"href",
				"/content/svg/tracking.svg?v=15#square"
			);
		}
	});

	$("#map-functions").on("click", "#map-functions-close", function (e) {
		e.preventDefault();
		if (domNodes.mapTools.container.classList.contains("is-closed")) {
			domNodes.mapTools.container.classList.remove("is-closed");
			this.querySelector("use").setAttributeNS(
				"http://www.w3.org/1999/xlink",
				"href",
				"/content/svg/tracking.svg?v=15#times"
			);
		} else {
			domNodes.mapTools.container.classList.add("is-closed");
			this.querySelector("use").setAttributeNS(
				"http://www.w3.org/1999/xlink",
				"href",
				"/content/svg/tracking.svg?v=15#external-link"
			);
		}
	});

	$j("#map-functions").on("click", "#map-zoom-in", function (e) {
		e.preventDefault();
		map.zoomIn();
	});
	$j("#map-functions").on("click", "#map-zoom-out", function (e) {
		e.preventDefault();
		map.zoomOut();
	});
	$("#map-functions").on("click", "#map-legend", function (e) {
		e.preventDefault();
		$(domNodes.modals.mapLegend).modal("show");
	});

	$j("#map_layers").on("click", "#map_layers_select", function (e) {
		e.preventDefault();
		if ($j("#map_layers_list:visible").length > 0) {
			collapseMapLayers();
		} else {
			expandMapLayers();
		}
	});

	$("#map-layers").on("click", "#map-layers-list", function (e) {
		e.stopPropagation();
	});

	$("#map-type").on("click", "#map-type-list", function (e) {
		e.stopPropagation();
	});

	$j("#map-layers").on("click", ".layer.iridiumnext", function (e) {
		e.preventDefault();
		if (!trkData.iridiumnext.isActive) {
			enableLayerIridiumNext();
		} else {
			disableLayerIridiumNext();
		}
	});

	$j("#map-layers").on("click", ".layer.custom", function (e) {
		e.preventDefault();
		var layerId = $j(this).data("layerId");
		if (_.indexOf(trkData.activeLayers, layerId) === -1) {
			enableCustomLayer(layerId);
		} else {
			disableCustomLayer(layerId);
		}
	});

	$j("#map-layers").on("click", ".layer.custom-group", function (e) {
		e.preventDefault();
		var layerSwitches = $j(this).next().find(".layer.custom");

		var toDisableDescendents = layerSwitches.is(function() {
			return $j(this).hasClass('active');
		});

		var layerIds = layerSwitches.map(function() {
			return $j(this).data("layerId");
		}).get();

		for (var i = 0; i < layerIds.length; i++) {
			if (toDisableDescendents) {
				disableCustomLayer(layerIds[i]);
			} else {
				enableCustomLayer(layerIds[i]);
			}
		}
	});

	$j("#map-layers").on("click", ".layer.customize", function (e) {
		e.preventDefault();
		window.location = "/Settings/ListMapLayers";
	});

	$j("#map-layers").on("click", ".layer.globalstar", function (e) {
		e.preventDefault();
		if (!trkData.globalstar.isActive) {
			enableLayerGlobalstar();
		} else {
			disableLayerGlobalstar();
		}
	});

	$j("#map-layers").on("click", ".layer.inmarsat", function (e) {
		e.preventDefault();
		if (!trkData.inmarsat.isActive) {
			enableLayerInmarsat();
		} else {
			disableLayerInmarsat();
		}
	});

	$j("#map-layers").on("click", ".layer.orbcomm", function (e) {
		e.preventDefault();
		if (!trkData.orbcomm.isActive) {
			enableLayerOrbcomm();
		} else {
			disableLayerOrbcomm();
		}
	});

	$j("#map-layers").on("click", ".layer.geostationary", function (e) {
		e.preventDefault();
		if (!trkData.geostationary.isActive) {
			enableLayerGeostationary();
		} else {
			disableLayerGeostationary();
		}
	});

	$j("#map-layers").on("click", ".layer.radar", function (e) {
		e.preventDefault();
		if (!trkData.radar.isActive) {
			enableRadar();
		} else {
			disableRadar();
		}
	});

	$j("#map-layers").on("click", ".layer.radar-australia", function (e) {
		e.preventDefault();
		if (!trkData.radarAustralia.isActive) {
			//enableRadarAustralia(false, enableRadarAustraliaActive);
			enableRadarAustraliaActive();
		} else {
			disableRadarAustralia();
		}
	});

	$j("#map-layers").on("click", ".layer.worldIR", function (e) {
		e.preventDefault();
		if (!trkData.worldIR.isActive) {
			enableWorldIR();
		} else {
			disableWorldIR();
		}
	});

	$j("#map-layers").on("click", ".layer.oil", function (e) {
		e.preventDefault();
		if (!trkData.oil.isActive) {
			enableLayerOil();
		} else {
			disableLayerOil();
		}
	});

	$j("#map-layers").on("click", ".layer.maritime", function (e) {
		e.preventDefault();
		if (!trkData.maritime.isActive) {
			enableLayerMaritime();
		} else {
			disableLayerMaritime();
		}
	});

	$j("#map-layers").on("click", ".layer.traffic", function (e) {
		e.preventDefault();
		if (!trkData.traffic.isActive) {
			enableLayerTraffic();
		} else {
			disableLayerTraffic();
		}
	});

	$j("#map-layers").on("click", ".layer.clouds", function (e) {
		e.preventDefault();
		if (!trkData.clouds.isActive) {
			enableLayerClouds();
		} else {
			disableLayerClouds();
		}
	});

	$j("#map-layers").on("click", ".layer.weather", function (e) {
		e.preventDefault();
		if (!trkData.weather.isActive) {
			enableLayerWeather(true);
		} else {
			disableLayerWeather();
		}
	});
}

function enableLayerTraffic() {
	if (trkData.traffic.isActive) {
		return;
	}

	// extra check for whether traffic API is available
	if (layers.traffic == null && typeof MQ !== "undefined") {
		layers.traffic = MQ.trafficLayer({ layers: ["flow"] });
	}
	if (layers.traffic != null) {
		addLayer(layers.traffic);
		trkData.traffic.isActive = true;
		$("#map-layers-list .traffic").addClass("active");
		Cookies.set("layer-traffic", true, { expires: 365, path: "/", secure: true });
	}
}

function disableLayerTraffic() {
	if (!trkData.traffic.isActive) {
		return;
	}

	removeLayer(layers.traffic);
	trkData.traffic.isActive = false;
	$("#map-layers-list .traffic").removeClass("active");
	Cookies.remove("layer-traffic");
}

function addCustomLayer(layerId) {
	for (var i = 0; i < options.customLayers.length; i++) {
		var layer = options.customLayers[i];
		if (layer.id == layerId) {
			if (layer.layer != null) {
				addOverlay(layer.layer);
				return;
			} else {
				switch (layer.type) {
					case 1:
						var layerBounds = undefined;
						if (layer.bounds !== undefined && layer.bounds !== "|||") {
							var bounds = layer.bounds.split("|");
							layerBounds = L.latLngBounds(L.latLng(bounds[0], bounds[1]), L.latLng(bounds[2], bounds[3]));
						}
						layer.layer = new L.tileLayer(layer.url, {
							bounds: layerBounds,
							opacity: layer.opacity,
							minZoom: layer.minZoom,
							maxZoom: layer.maxZoom,
							zIndex: 6,
						});
						break;
					case 5:
						var layerBounds = undefined;
						if (layer.bounds !== undefined && layer.bounds !== "|||") {
							var bounds = layer.bounds.split("|");
							layerBounds = L.latLngBounds(L.latLng(bounds[0], bounds[1]), L.latLng(bounds[2], bounds[3]));
						}
						layer.layer = L.tileLayer.wms(layer.url, {
							bounds: layerBounds,
							opacity: layer.opacity,
							minZoom: layer.minZoom,
							maxZoom: layer.maxZoom,
							layers: layer.layers,
							format: layer.format,
							transparent: layer.transparent,
							zIndex: 6,
						});
						break;
					case 0:
						layer.styles = [];
						$.ajax({
							url: wrapUrl("/uploads/layers/" + layer.path),
							type: "get",
							dataType: "xml",
							async: false,
							success: function (data) {
								onKMZData(layer, data);
							},
						});
						break;
					case 2:
						// pull json from URL
						layer.layer = L.geoJSON(null, {
							onEachFeature: function (feature, layer) {
								var items = [];
								if (feature.properties != null) {
									for (var name in feature.properties) {
										if (!feature.properties.hasOwnProperty(name)) {
											// skip inherited properties
											continue;
										}
										items.push(el("strong", name + ":"));
										items.push(text(" " + feature.properties[name]));
										items.push(el("br"));
									}
								}
								if (items.length > 0) {
									var tooltip = el("div", items);
									layer.bindPopup(tooltip); // perhaps bindTooltip instead?
								}
							},
						});
						$j.ajax({
							dataType: "json",
							url: wrapUrl("/uploads/layers/" + layer.path),
							success: function (data) {
								$(data.features).each(function (key, data) {
									layer.layer.addData(data);
								});
							},
						});
						break;
					case 3:
						layer.layer = L.geoJSON(null, {
							onEachFeature: function (feature, layer) {
								if (feature.properties != null) {
									if (feature.properties.name != null) {
										layer.bindTooltip(feature.properties.name);
									}
								}
							},
						});
						omnivore.gpx(wrapUrl("/uploads/layers/" + layer.path), null, layer.layer).on("ready", function () {});
						break;
					case 4:
						var bounds = layer.bounds.split("|");
						var layerBounds = L.latLngBounds(L.latLng(bounds[0], bounds[1]), L.latLng(bounds[2], bounds[3]));
						layer.layer = L.imageOverlay(wrapUrl("/uploads/layers/" + layer.path), layerBounds);
						break;
				}
				if (layer.layer != null) {
					addOverlay(layer.layer);
				}
				return;
			}
		}
	}
}

function onKMZData(layer, data) {
	// todo: KMZ support and GroundOverlay support
	// consider https://raw.githubusercontent.com/falcacibar/leaflet-plugins/f2ce428c1bbca178dd2c887a4721ef528d829ace/layer/vector/KML.js
	// and hybrid support of L.imageOverlay.. should be able to parse
	// GroundOverlay and use Icon and LatLonBox for placement
	// jszip and jsziputils for unzipping KMZ? or have the server handle unzipping KMZ on upload?
	// pull the styling first
	var excludedProperties = ["stroke", "stroke-opacity", "fill-opacity", "styleUrl", "styleHash"];
	$(data)
		.find("Style")
		.each(function (index) {
			var item = $(this);
			// may have multiple styles associated with it
			// so we should prioritize via PolyStyle, LineStyle, IconStyle, LabelStyle
			var style = {
				// defaults
				fillColor: null,
				fillOpacity: 0.2,
				color: "#3388ff",
				opacity: 1.0,
				weight: 3,
			};
			// parse KML styles, which should be in abgr format
			var fillColor = item.find("PolyStyle color").text();
			var strokeColor = item.find("LineStyle color").text();
			var strokeWeight = item.find("LineStyle width").text();
			var iconScale = item.find("IconStyle scale").text();
			if (iconScale !== "" && parseFloat(iconScale) !== NaN) {
				style.radius = 10 * parseFloat(iconScale);
			}
			if (strokeWeight !== "") {
				style.weight = strokeWeight;
			}
			if (strokeColor === "") {
				strokeColor = item.find("IconStyle color").text();
			}
			if (strokeColor === "") {
				strokeColor = item.find("LabelStyle color").text();
			}
			if (strokeColor !== "") {
				// parse opacity
				style.color = "#" + strokeColor.substring(6, 8) + strokeColor.substring(4, 6) + strokeColor.substring(2, 4);
				style.opacity = parseInt(strokeColor.substring(0, 2), 16) / 255;
			}
			if (fillColor !== "") {
				style.fillColor = "#" + fillColor.substring(6, 8) + fillColor.substring(4, 6) + fillColor.substring(2, 4);
				style.fillOpacity = parseInt(fillColor.substring(0, 2), 16) / 255;
				if (strokeColor === "") {
					style.color = style.fillColor;
					style.opacity = style.fillOpacity;
				}
			}
			// non-KML
			if (strokeColor === "") {
				style.color = item.find("color").text();
			}
			if (style.color === "") {
				style.color = "#ffffff"; // default isn't always populated
			}

			layer.styles[item.attr("id")] = style;
		});

	$(data)
		.find("StyleMap")
		.each(function (index) {
			var item = $(this);
			layer.styles[item.attr("id")] = layer.styles[item.find("styleUrl").eq(0).text().substr(1)];
		});

	layer.layer = L.geoJSON(null, {
		onEachFeature: function (feature, layer) {
			if (feature.properties != null) {
				var hasPopup = false;
				if (feature.properties.name != null) {
					layer.bindTooltip(feature.properties.name);
					if (feature.properties.description != null) {
						layer.bindPopup(el("div", [el("h3", feature.properties.name), text(feature.properties.description)]));
						hasPopup = true;
					}
				}
				if (!hasPopup) {
					var rows = [];
					_.each(feature.properties, function (val, key, list) {
						if (_.indexOf(excludedProperties, key) !== -1) {
							return;
						}
						rows.push(el("tr", [el("td", key), el("td", val)]));
					});
					if (rows.length > 0) {
						layer.bindPopup(el("table.feature-properties", el("tbody", rows)));
					}
				}
			}
		},
		pointToLayer: function (feature, latlng) {
			// check for custom styling for the placemark
			if (feature.properties != undefined && feature.properties.styleUrl != undefined) {
				var iconStyle = layer.styles[feature.properties.styleUrl.substr(1)];
				if (iconStyle === null) {
					// map be using a style map
					iconStyle = layer.styles[feature.properties.styleUrl.substr(1) + "-normal"];
				}
				if (iconStyle !== null) {
					// with placemarks, GoogleEarth sets both a PolyStyle and an IconStyle with the PolyStyle having a fill of black
					// so we should ignore fillColor for them and set the fillColor to the strokeColor at a lower opacity
					if (iconStyle.color !== null) {
						iconStyle.fillColor = iconStyle.color;
						if (iconStyle.opacity !== null) {
							iconStyle.fillOpacity = iconStyle.opacity * 0.5;
						}
					}
					return L.circleMarker(latlng, iconStyle);
				}
			}
			return L.marker(latlng);
		},
		style: function (feature) {
			if (feature.properties != undefined && feature.properties.styleUrl != undefined) {
				var layerStyle = layer.styles[feature.properties.styleUrl.substr(1)];
				if (layerStyle === undefined) {
					// map be using a style map
					layerStyle = layer.styles[feature.properties.styleUrl.substr(1) + "-normal"];
				}
				if (layerStyle !== undefined) {
					return layerStyle;
				}
			}
		},
	});
	omnivore.kml.parse(data, null, layer.layer).on("ready", function () {});
}

function removeCustomLayer(layerId) {
	for (var i = 0; i < options.customLayers.length; i++) {
		var layer = options.customLayers[i];
		if (layer.id == layerId) {
			if (layer.layer != null) {
				removeOverlay(layer.layer);
				return;
			}
		}
	}
}

function enableCustomLayers(layers) {
	var layerIds = layers.split(",");
	for (var i = 0; i < layerIds.length; i++) {
		var layerId = parseInt(layerIds[i]);
		enableCustomLayer(layerId);
	}
}

function updateActiveLayerGroups() {
	var groups = $j(".layer.custom-group");
	for (var i = 0; i < groups.length; i++) {
		var group = $j(groups[i]);
		if (group.next().find(".layer.custom.active").length > 0) {
			group.addClass("active");
		} else {
			group.removeClass("active");
		}
	}
}

function enableCustomLayer(layerId) {
	// check if currently enabled
	if ($j.inArray(layerId, trkData.activeLayers) !== -1) {
		return;
	}

	addCustomLayer(layerId);

	trkData.activeLayers.push(layerId);
	$j("#map-layers-list .custom-" + layerId).addClass("active");
	updateActiveLayerGroups();
	Cookies.set("layer-custom", trkData.activeLayers.join(","), { expires: 365, path: "/", secure: true });
}

function disableCustomLayer(layerId) {
	var index = $j.inArray(layerId, trkData.activeLayers);
	if (index === -1) return;

	removeCustomLayer(layerId);

	// remove from array
	trkData.activeLayers.splice(index, 1);
	$j("#map-layers-list .custom-" + layerId).removeClass("active");
	updateActiveLayerGroups();
	Cookies.set("layer-custom", trkData.activeLayers.join(","), { expires: 365, path: "/", secure: true });
}

function collapseMapLayers() {
	$j("#map_layers_list").hide();
	$j("body").off("click.maplayers");
}

function expandMapLayers() {
	$j("#map_layers_list").show();

	$j("body").on("click.maplayers", function (e) {
		if ($(e.target).parents("#map_layers").length == 0) {
			collapseMapLayers();
		}
	});
}

function zoomMouseDown(event) {
	// simulate shift
	map.boxZoom._onMouseDown.call(map.boxZoom, {
		clientX: event.originalEvent.clientX,
		clientY: event.originalEvent.clientY,
		which: 1,
		shiftKey: true,
	});
}

export function enableDragZoom() {
	$("#map-zoom-region").addClass("active");
	trkData.zoom.isActive = true;
	map.dragging.disable();
	map.boxZoom.addHooks();
	map.on("mousedown", zoomMouseDown);
	map.on("boxzoomend", disableDragZoom);
}

export function disableDragZoom() {
	clearTimeout(state.zoomTimeout);
	state.zoomTimeout = null;

	// disable icon state
	$("#map-zoom-region").removeClass("active");
	trkData.zoom.isActive = false;
	map.off("mousedown", zoomMouseDown);
	map.dragging.enable();
	map.boxZoom.removeHooks();
	L.DomUtil.removeClass(map._container, "leaflet-control-boxzoom-active");
}

export function addOverlay(overlay) {
	if (overlay == null) return;
	var isFound = false;
	if (map.hasLayer(overlay)) return;
	map.addLayer(overlay);
}

export function isOverlayActive(overlay) {
	if (map.hasLayer(overlay)) return true;

	return false;
}

export function removeOverlay(overlay) {
	map.removeLayer(overlay);
}

export function addLayer(layer) {
	//layer.setMap(map);
	addOverlay(layer);
}

export function removeLayer(layer) {
	//layer.setMap(null);
	removeOverlay(layer);
}

export function switchMapMode(toMode, sensitivity, doHistoryQuery, toPredefinedDateRange, forViewMode) {
	// switching map view modes (i.e. from Live to History)
	sensitivity = sensitivity !== undefined ? sensitivity : null;
	toPredefinedDateRange = toPredefinedDateRange !== undefined ? toPredefinedDateRange : false;
	doHistoryQuery = doHistoryQuery !== undefined ? doHistoryQuery : false;
	forViewMode = forViewMode !== undefined ? forViewMode : viewModes.NORMAL;

	if (toMode !== mapModes.LIVE && toMode !== mapModes.HISTORY && toMode !== mapModes.TIME_SLIDER) {
		// Handle legacy values of `toMode` - truthy means live, falsy means history.
		toMode = !!toMode ?
			mapModes.LIVE :
			mapModes.HISTORY
	}

	/*toLive = toLive !== undefined ? toLive : false;

	if (toLive === null) {
		toLive = false;
	}*/

	console.log("switchMapMode", toMode, sensitivity, doHistoryQuery, toPredefinedDateRange, forViewMode);

	var switchMapModePromises = [];

	// do whatever changes are necessary for the passed viewMode (defaulting to current)
	if (forViewMode === viewModes.NORMAL) {
		// check whether it's already in the selected mode
		if (toMode === state.activeMapMode && state.activeMapMode !== null) {
			return true;
		}

		hideMapMarkersForDataGroup(getAssetDataGroupForViewAndMapModes(forViewMode, state.activeMapMode));
		if (state.activeMapMode === mapModes.LIVE) {
			if (intervals.positionStatus != null) {
				clearInterval(intervals.positionStatus);
			}
		} else if (state.activeMapMode === mapModes.TIME_SLIDER) {
			disableTimeSlider();
		}

		state.activeMapMode = toMode;

		if (toMode === mapModes.LIVE) {
			// query for live positions
			if (!trkData.live.isInitialized) {
				switchMapModePromises.push(queryLiveAssets());
			} else {
				switchMapModePromises.push(updateLiveAssets());
			}
		} else if (toMode === mapModes.HISTORY) {
			// query for historic positions
			if (doHistoryQuery || !state.hasQueriedHistory) {
				if (!toPredefinedDateRange) {
					// todo: move this crap out of here
					var historyCustom = document.getElementById("history-custom");
					filterDateClick(historyCustom);
					historyCustom.classList.add("btn-primary");
				}
				switchMapModePromises.push(queryActiveAssets(sensitivity, toPredefinedDateRange));
			}
		} else if (toMode === mapModes.TIME_SLIDER) {
			switchMapModePromises.push(enableTimeSlider(sensitivity, toPredefinedDateRange));
		}

		const assetDataGroup = getAssetDataGroupForViewAndMapModes(forViewMode, state.activeMapMode);
		showMapMarkersForDataGroup(assetDataGroup);
		_.each(trkData.assets, function (asset) {
			updateAssetState(asset, asset.State);
			updateAssetFunctionBadges(assetDataGroup, asset.Id);
			updateTimeBasedNotificationIndicatorsForAsset(asset);
		});
		updateGroupFunctionBadges(assetDataGroup, null, "asset");
		var groupIds = _.map(trkData.groups, "Id");
		groupIds.push("all-assets");
		_.each(groupIds, function (groupId) {
			updateTimeBasedNotificationIndicatorsForGroup(groupId);
		});
		updateActiveAssetInformation(forViewMode);

		if (state.activeMapMode === mapModes.LIVE) {
			updateLiveFollowStatus();
		}
	} else if (forViewMode === viewModes.SHARED_VIEW) {
	}
	updateMapModePanel();

	setUrlHistoryForActivePanel(); // TODO why is this here
	resizeApp(true);

	return Promise.all(switchMapModePromises);
}