import strings from "./strings.js";
import trkData from "./data.js";
import domNodes from "./domNodes.js";
import state from "./state.js";
import user from "./user.js";
import options from "./options.js";
import { populateCheckboxList, svgPath } from "./dom-util.js";
import preferences from "./preferences.js";
import { clearSharedViewData, cloneSharedView, querySharedViewData } from "./shared-view.js";
import { openDialogPanel } from "./panel-nav.js";
import { moveSecondaryPanelToPrimary } from "./panel.js";
import { getDescriptionForDriver } from "./driver.js";
import { sortAssetGroups } from "./item-sorting.js";
import { checkForShareViewChange } from "./shared-view.js";
import { restrictAllChildren } from "./dom-util.js";
import { changePrimaryButtonLabel } from "./modal.js";
import { addGroupToGroupListWithName } from "./group-list.js";
import { querySharedViewInvites } from "./shared-view.js";

import $ from "jquery";
import _ from "lodash";
import moment from "moment"; // https://www.npmjs.com/package/moment

export function initSharedViewDialog() {
	$(domNodes.dialogs.sharedView).on("click", ".next-step,.prev-step", function (e) {
		console.log("next/prev step");
		var isNext = this.classList.contains("next-step");
		var currentStep = domNodes.dialogs.sharedView.querySelector(".wizard-step.is-active");
		var currentStepId = currentStep.getAttribute("data-step-id");
		var currentStepStep = parseInt(currentStep.getAttribute("data-step-step"));
		console.log(currentStepId, isNext);
		if (isNext) {
			goToWizardStep(trkData.wizards.sharedView, null, ++currentStepStep);
		} else {
			goToWizardStep(trkData.wizards.sharedView, null, --currentStepStep);
		}
	});
}

export function openSharedViewDialog(sharedView, step) {
	if (user.isAnonymous) {
		return;
	}

	if (sharedView === undefined) {
		sharedView = null;
	}

	// only reset the current step?
	_.each(trkData.wizards.sharedView.steps, function (step) {
		if (step.form !== undefined) {
			step.form.resetForm();
			step.form.currentForm.reset();
		}
	});

	// hide shared view information dialog

	var dialog = domNodes.dialogs.sharedView;
	var wizard = dialog.querySelector(".step-wizard");
	wizard.currentStep = null;
	var wizardHeader = wizard.querySelector(".wizard-header");

	// dialog title based on section when editing
	var dialogTitle = sharedView !== null ? strings.EDIT_SHARED_VIEW : strings.ADD_SHARED_VIEW;

	var chosenStep = goToWizardStep(wizard, step);

	var editSharedView = null;
	if (sharedView === null) {
		trkData.sharedView.current = null;
		clearSharedViewData(true);
		$(domNodes.infoDialogs.sharedViewInformation).dialog("close");

		editSharedView = {
			AssetGroupIds: [],
			AssetIds: [],
			PlaceIds: [],
			FenceIds: [],

			Color: "#bbbbbb",
			Description: "",
			DriverIds: [],
			Name: "",

			ExpiresOnEpoch: null,
			FromDateEpoch: null,
			ToDateEpoch: null,
			IsAvailable: true,
			IsEnabled: true,
			IsTimeframeRelative: true,
			RelativeTimeframeNumber: 7,
			RelativeTimeframeType: "d",
			IsMessagingEnabled: false,
			IsPublic: true,
			Password: "",
			Preferences: {
				DefaultMode: "",
				EmergencyAudio: false,
				Fuel: preferences.PREFERENCE_FUEL_UNIT,
				HistoryViewNumber: "7",
				HistoryViewType: "d",
				Language: preferences.PREFERENCE_LANGUAGE,
				LatLng: preferences.PREFERENCE_LAT_LNG,
				MapType: trkData.MAP_TYPES[state.currentMapType],
				PositionAlpha: preferences.PREFERENCE_ALPHA_POSITIONS,
				PositionConsolidation: preferences.PREFERENCE_GROUP_POSITIONS,
				RemoveRoads: preferences.PREFERENCE_REMOVE_ROADS,
				Speed: preferences.PREFERENCE_SPEED,
				Timezone: preferences.PREFERENCE_TIMEZONE,
			},
		};

		// new shared view, show wizard, have it be placed in the "primary" panel
		wizardHeader.classList.add("is-visible");
		dialog.classList.add("is-wizard");
	} else {
		// editing an existing sharedView
		var deferreds = [];
		if (trkData.sharedView.current !== sharedView) {
			// load and display data
			deferreds.push(querySharedViewData(sharedView, null, false));
		}

		trkData.sharedView.current = sharedView;
		editSharedView = sharedView;
		//editSharedView = cloneSharedView(trkData.sharedView.current);

		// existing shared view, hide wizard
		wizardHeader.classList.remove("is-visible");
		dialog.classList.remove("is-wizard");

		dialogTitle = chosenStep.title;
	}

	// populate from existing saved values

	document.getElementById("SharedViewId").value = editSharedView.Id;

	// details
	var name = document.getElementById("SharedViewName");
	name.value = editSharedView.Name;

	var description = document.getElementById("SharedViewDescription");
	description.value = editSharedView.Description;

	var $color = $(document.getElementById("SharedViewColor"));
	$color.val(editSharedView.Color);
	$color.css("background-color", editSharedView.Color);
	$color.next("span").css("background-color", editSharedView.Color);

	// access controls
	if (editSharedView.IsEnabled) {
		document.getElementById("SharedViewIsEnabledYes").checked = true;
	} else {
		document.getElementById("SharedViewIsEnabledNo").checked = true;
	}

	if (editSharedView.IsPublic) {
		document.getElementById("SharedViewIsPublicYes").checked = true;
		document.getElementById("SharedViewPrivatePassword").classList.remove("is-visible");
		document.getElementById("SharedViewPassword").classList.remove("required");
	} else {
		document.getElementById("SharedViewIsPublicNo").checked = true;
		document.getElementById("SharedViewPrivatePassword").classList.add("is-visible");
		document.getElementById("SharedViewPassword").classList.add("required");
	}
	if (editSharedView.Password === undefined || editSharedView.Password === null) {
		document.getElementById("SharedViewPassword").value = "";
	} else {
		document.getElementById("SharedViewPassword").value = editSharedView.Password;
	}

	if (editSharedView.ExpiresOnEpoch !== null) {
		document.getElementById("SharedViewDoesExpireYes").checked = true;
		document.getElementById("SharedViewExpireDate").classList.add("is-visible");
		document.getElementById("SharedViewExpiresOn").classList.add("required");
		$("#SharedViewExpiresOn").datetimepicker("setDate", moment(editSharedView.ExpiresOnEpoch).toDate());
	} else {
		document.getElementById("SharedViewDoesExpireNo").checked = true;
		document.getElementById("SharedViewExpireDate").classList.remove("is-visible");
		document.getElementById("SharedViewExpiresOn").value = "";
		document.getElementById("SharedViewExpiresOn").classList.remove("required");
	}

	if (editSharedView.IsTimeframeRelative) {
		document.getElementById("SharedViewDataTimeframeRelative").checked = true;
		document.getElementById("SharedViewRelativeDateRange").classList.add("is-visible");
		document.getElementById("SharedViewDataTimeframeRelativeNumber").disabled = false;
		document.getElementById("SharedViewDataTimeframeRelativeType").disabled = false;
	} else {
		document.getElementById("SharedViewDataTimeframeRelativeNumber").disabled = true;
		document.getElementById("SharedViewDataTimeframeRelativeType").disabled = true;
		if (editSharedView.FromDateEpoch !== null || editSharedView.ToDateEpoch !== null) {
			document.getElementById("SharedViewDataTimeframeCustom").checked = true;
			document.getElementById("SharedViewDateRange").classList.add("is-visible");
		} else {
			document.getElementById("SharedViewDataTimeframeAll").checked = true;
			document.getElementById("SharedViewDateRange").classList.remove("is-visible");
		}
	}

	if (editSharedView.RelativeTimeframeNumber === null) {
		document.getElementById("SharedViewDataTimeframeRelativeNumber").value = "7";
	} else {
		document.getElementById("SharedViewDataTimeframeRelativeNumber").value = editSharedView.RelativeTimeframeNumber;
	}
	if (editSharedView.RelativeTimeframeType === null) {
		document.getElementById("SharedViewDataTimeframeRelativeType").value = "d";
	} else {
		document.getElementById("SharedViewDataTimeframeRelativeType").value = editSharedView.RelativeTimeframeType;
	}

	if (editSharedView.FromDateEpoch !== null) {
		$("#SharedViewFrom").datetimepicker("setDate", moment(editSharedView.FromDateEpoch).toDate());
		$("#SharedViewTo").datetimepicker("option", "minDate", moment(editSharedView.FromDateEpoch).toDate());
	} else {
		document.getElementById("SharedViewFrom").value = "";
		$("#SharedViewTo").datetimepicker(
			"option",
			"maxDate",
			options.dateRangeMin === null ? null : new Date(options.dateRangeMin)
		);
	}
	if (editSharedView.ToDateEpoch !== null) {
		$("#SharedViewTo").datetimepicker("setDate", moment(editSharedView.ToDateEpoch).toDate());
		$("#SharedViewFrom").datetimepicker("option", "maxDate", moment(editSharedView.ToDateEpoch).toDate());
	} else {
		document.getElementById("SharedViewTo").value = "";
		$("#SharedViewFrom").datetimepicker(
			"option",
			"maxDate",
			options.dateRangeMax === null ? null : new Date(options.dateRangeMax)
		);
	}

	if (editSharedView.IsMessagingEnabled) {
		document.getElementById("SharedViewIsMessagingEnabledYes").checked = true;
	} else {
		document.getElementById("SharedViewIsMessagingEnabledNo").checked = true;
	}

	// data visualization
	document.getElementById("SharedViewDefaultMode").value = editSharedView.Preferences.DefaultMode;
	//document.getElementById('SharedViewHistoryViewNumber').value = editSharedView.Preferences.HistoryViewNumber;
	//document.getElementById('SharedViewHistoryViewType').value = editSharedView.Preferences.HistoryViewType;

	//if (editSharedView.Preferences.DefaultMode === mapModes.HISTORY) {
	//    document.getElementById('SharedViewModeHistory').classList.add('is-visible');
	//} else {
	//    document.getElementById('SharedViewModeHistory').classList.remove('is-visible');
	//}

	// map settings
	document.getElementById("SharedViewMapType").value = editSharedView.Preferences.MapType;
	if (editSharedView.Preferences.PositionConsolidation) {
		document.getElementById("SharedViewPositionConsolidationYes").checked = true;
	} else {
		document.getElementById("SharedViewPositionConsolidationNo").checked = true;
	}
	if (editSharedView.Preferences.PositionAlpha) {
		document.getElementById("SharedViewPositionAlphaYes").checked = true;
	} else {
		document.getElementById("SharedViewPositionAlphaNo").checked = true;
	}
	if (editSharedView.Preferences.RemoveRoads) {
		document.getElementById("SharedViewRemoveRoadsYes").checked = true;
	} else {
		document.getElementById("SharedViewRemoveRoadsNo").checked = true;
	}
	//if (editSharedView.Preferences.EmergencyAudio) {
	//    document.getElementById('SharedViewEmergencyAudioYes').checked = true;
	//} else {
	//    document.getElementById('SharedViewEmergencyAudioNo').checked = true;
	//}

	// permissions

	// preferences
	if (editSharedView.Preferences.Language === null) {
		document.getElementById("SharedViewLanguage").value = "";
	} else {
		document.getElementById("SharedViewLanguage").value = editSharedView.Preferences.Language;
	}
	document.getElementById("SharedViewTimezone").value = editSharedView.Preferences.Timezone;
	document.getElementById("SharedViewLatLngFormat").value = editSharedView.Preferences.LatLng;
	document.getElementById("SharedViewSpeedFormat").value = editSharedView.Preferences.Speed;
	document.getElementById("SharedViewFuelFormat").value = editSharedView.Preferences.Fuel;

	// permissions
	if (editSharedView.AssetIds.length > 0) {
		document.getElementById("SharedViewPermissionsAssets").checked = true;
		document.getElementById("accordion-shared-view-assets-content").classList.add("show");
	} else {
		document.getElementById("SharedViewPermissionsAssets").checked = false;
		document.getElementById("accordion-shared-view-assets-content").classList.remove("show");
	}

	// groups
	if (editSharedView.AssetGroupIds.length > 0) {
		document.getElementById("SharedViewPermissionsGroups").checked = true;
		document.getElementById("accordion-shared-view-groups-content").classList.add("show");
	} else {
		document.getElementById("SharedViewPermissionsGroups").checked = false;
		document.getElementById("accordion-shared-view-groups-content").classList.remove("show");
	}

	// geofences
	if (editSharedView.FenceIds.length > 0) {
		document.getElementById("SharedViewPermissionsGeofences").checked = true;
		document.getElementById("accordion-shared-view-geofences-content").classList.add("show");
	} else {
		document.getElementById("SharedViewPermissionsGeofences").checked = false;
		document.getElementById("accordion-shared-view-geofences-content").classList.remove("show");
	}

	// places
	if (editSharedView.PlaceIds.length > 0) {
		document.getElementById("SharedViewPermissionsPlaces").checked = true;
		document.getElementById("accordion-shared-view-places-content").classList.add("show");
	} else {
		document.getElementById("SharedViewPermissionsPlaces").checked = false;
		document.getElementById("accordion-shared-view-places-content").classList.remove("show");
	}

	// drivers
	if (editSharedView.DriverIds.length > 0) {
		document.getElementById("SharedViewPermissionsDrivers").checked = true;
		document.getElementById("accordion-shared-view-drivers-content").classList.add("show");
	} else {
		document.getElementById("SharedViewPermissionsDrivers").checked = false;
		document.getElementById("accordion-shared-view-drivers-content").classList.remove("show");
	}

	//}
	// groups
	// TODO use checkbox list for groups
	var cont = document.getElementById("shared-view-groups-list");
	cont.innerHTML = "";
	var fragment = document.createDocumentFragment();
	var sortedGroups = sortAssetGroups();
	for (var i = 0; i < sortedGroups.length; i++) {
		var group = sortedGroups[i];
		addGroupToGroupListWithName(group, 0, fragment, "SharedViewAssetGroupIds", null, true);
	}
	cont.appendChild(fragment);
	if (sharedView !== null) {
		// select groups if chosen
		_.each(sharedView.AssetGroupIds, function (assetGroupId) {
			var group = document.getElementById("SharedViewAssetGroupIds" + assetGroupId);
			if (group !== undefined) {
				group.checked = true;
				restrictAllChildren(group, cont);
			}
		});
	}

	// assets
	var sharedViewAssets = _.filter(trkData.assets, function (item) {
		return true;
	});
	populateCheckboxList(
		"shared-view-assets-list",
		sharedViewAssets,
		"SharedViewAssetIds",
		function (item) {
			if (sharedView === null) {
				return false;
			}
			return _.indexOf(sharedView.AssetIds, item.Id) !== -1;
		},
		function (item) {
			return item.Name;
		},
		"assets",
		function (item) {
			return item.UniqueId;
		}
	);
	var sharedViewGeofences = _.filter(trkData.fences, function (item) {
		return true;
	});
	populateCheckboxList(
		"shared-view-geofences-list",
		sharedViewGeofences,
		"SharedViewFenceIds",
		function (item) {
			if (sharedView === null) {
				return false;
			}
			return _.indexOf(sharedView.FenceIds, item.Id) !== -1;
		},
		function (item) {
			return item.Name;
		},
		"fences",
		function (item) {
			return item.Description;
		}
	);
	var sharedViewPlaces = _.filter(trkData.places, function (item) {
		return true;
	});
	populateCheckboxList(
		"shared-view-places-list",
		sharedViewPlaces,
		"SharedViewPlaceIds",
		function (item) {
			if (sharedView === null) {
				return false;
			}
			return _.indexOf(sharedView.PlaceIds, item.Id) !== -1;
		},
		function (item) {
			return item.Name;
		},
		"places",
		function (item) {
			return item.Description;
		}
	);
	var sharedViewDrivers = _.filter(trkData.drivers, function (item) {
		return true;
	});
	populateCheckboxList(
		"shared-view-drivers-list",
		sharedViewDrivers,
		"SharedViewDriverIds",
		function (item) {
			if (sharedView === null) {
				return false;
			}
			return _.indexOf(sharedView.DriverIds, item.Id) !== -1;
		},
		function (item) {
			return item.DriverId;
		},
		"drivers",
		getDescriptionForDriver
	);

	// disable groupings if they have no options available
	var assetsOption = document.getElementById("SharedViewPermissionsAssets");
	if (trkData.assets.length === 0) {
		assetsOption.disabled = true;
	} else if (!assetsOption.classList.contains("disabled")) {
		assetsOption.disabled = false;
	}
	var fencesOption = document.getElementById("SharedViewPermissionsGeofences");
	if (trkData.fences.length === 0) {
		fencesOption.disabled = true;
	} else if (!fencesOption.classList.contains("disabled")) {
		fencesOption.disabled = false;
	}
	var placesOption = document.getElementById("SharedViewPermissionsPlaces");
	if (trkData.places.length === 0) {
		placesOption.disabled = true;
	} else if (!placesOption.classList.contains("disabled")) {
		placesOption.disabled = false;
	}
	var assetGroupsOption = document.getElementById("SharedViewPermissionsGroups");
	if (trkData.groups.length === 1) {
		assetGroupsOption.disabled = true;
	} else if (!assetGroupsOption.classList.contains("disabled")) {
		assetGroupsOption.disabled = false;
	}
	var driversOption = document.getElementById("SharedViewPermissionsDrivers");
	if (trkData.drivers.length === 0) {
		driversOption.disabled = true;
	} else if (!driversOption.classList.contains("disabled")) {
		driversOption.disabled = false;
	}

	trkData.sharedView.temp = cloneSharedView(editSharedView);

	var buttonText = sharedView !== null ? strings.SAVE_CHANGES : strings.CREATE_SHARED_VIEW;
	changePrimaryButtonLabel(domNodes.dialogs.sharedView, buttonText);
	var callback = function (item) {
		openSharedViewDialog(item, step);
	};
	openDialogPanel(
		dialog,
		dialogTitle,
		sharedView,
		false,
		checkForShareViewChange(sharedView, true),
		"shared-view",
		sharedView !== null ? "edit-shared-view" : null,
		sharedView !== null ? callback : null
	);
	$(domNodes.infoDialogs.sharedViewInformation).dialog("close");
	if (sharedView === null) {
		moveSecondaryPanelToPrimary();
	}
}

// // TODO reusable wizard component (see Shared Views)
// var ItemWizard = function (steps, container, options) {
// 	this.initialized = false;
//
// 	this.options = {};
// 	this.data = {
// 		steps: [],
// 	};
// 	this.domNodes = {
// 		wizardContainer: container,
// 	};
//
// 	var self = this;
// 	function init(steps, options) {
// 		$.extend(true, self.options, options);
// 		self.initialized = true;
// 		self.data.steps = steps;
//
// 		var container = self.domNodes.wizardContainer;
// 	}
//
// 	function validateStep(step) {}
//
// 	this.changeStep = function (step) {};
//
// 	init(steps, options);
// };

export function goToWizardStep(wizard, stepId, stepNum) {
	var SUCCESS_STEP_ID = "success";
	var steps = wizard.steps;
	var stepsById = _.keyBy(steps, "id");
	var chosenStep = null;
	if (stepId !== undefined && stepId !== null) {
		chosenStep = stepsById[stepId];
	} else if (stepNum !== undefined && stepNum !== null) {
		chosenStep = _.find(steps, { step: stepNum });
	}
	if (chosenStep === undefined || chosenStep === null) {
		chosenStep = _.find(steps, { step: 1 });
	}
	console.log("chosen step", chosenStep);

	if (wizard.currentStep !== undefined && wizard.currentStep !== null) {
		// only validate if moving forward
		if (chosenStep.step > wizard.currentStep.step) {
			if (wizard.currentStep.form !== undefined) {
				var isValid = $(wizard.currentStep.form.currentForm).valid();
				console.log("check form validity", isValid, wizard.currentStep.form);
				if (!isValid) {
					wizard.currentStep.form.focusInvalid();
					return;
				}
			}
		}
	}

	wizard
		.querySelector(".step-title use")
		.setAttributeNS("http://www.w3.org/1999/xlink", "href", svgPath(chosenStep.icon));
	wizard.querySelector(".step-title span").textContent = chosenStep.title;
	var allSteps = wizard.querySelectorAll(".wizard-step");
	_.each(allSteps, function (wizardStep) {
		if (wizardStep.getAttribute("data-step-id") === chosenStep.id) {
			wizardStep.classList.add("is-active");
		} else {
			wizardStep.classList.remove("is-active");
		}
	});

	var navigableSteps = _.reject(steps, function (step) {
		return step.id === SUCCESS_STEP_ID;
	});
	var lastStepNum = _.last(navigableSteps).step;

	var isFirstStep = chosenStep.step === 1;
	var isLastStep = chosenStep.step === lastStepNum;
	wizard.querySelector(".wizard-buttons .prev-step").disabled = isFirstStep;
	wizard.querySelector(".wizard-buttons .next-step").disabled = isLastStep;
	if (isLastStep) {
		wizard.querySelector(".wizard-buttons .next-step").classList.remove("is-visible");
		wizard.querySelector(".wizard-buttons .final-step").classList.add("is-visible");
	} else {
		wizard.querySelector(".wizard-buttons .next-step").classList.add("is-visible");
		wizard.querySelector(".wizard-buttons .final-step").classList.remove("is-visible");
	}
	wizard.currentStep = chosenStep;

	if (chosenStep.id === SUCCESS_STEP_ID) {
		wizard.querySelector(".wizard-buttons").classList.add("toggle-content");
	} else {
		wizard.querySelector(".wizard-buttons").classList.remove("toggle-content");
	}

	// set progress meter
	var totalSteps = lastStepNum;
	var width = ((chosenStep.step - 1) * (100 / totalSteps)).toFixed(0) + "%";
	var progressBar = wizard.querySelector(".progress-bar");
	progressBar.style.width = width;
	progressBar.textContent = width;

	return chosenStep;
}

export function openSharedViewShareDialog(sharedView) {
	if (sharedView === undefined || sharedView === null) {
		return;
	}
	trkData.sharedView.current = sharedView;

	document.getElementById("SharedViewShareLink").textContent = sharedView.Link;

	if (!sharedView.IsPublic) {
		document.getElementById("SharedViewSharePrivate").classList.add("is-visible");
	} else {
		document.getElementById("SharedViewSharePrivate").classList.remove("is-visible");
	}

	querySharedViewInvites(sharedView);
	var dialog = domNodes.dialogs.sharedViewShare;
	openDialogPanel(
		dialog,
		strings.SHARE,
		sharedView,
		false,
		checkForShareViewChange(sharedView),
		"shared-view",
		"share",
		openSharedViewShareDialog
	);
	$(domNodes.infoDialogs.sharedViewInformation).dialog("close");
}
