import trkData from "./data.js";
import { getGenericIconUrlForItemType } from "./marker-path.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import { el, text, svg } from "redom"; // https://redom.js.org/
import { createCheckboxFilterList } from "../utility.mjs";

export function includeRowIfNotNull(header, content, headerOptions) {
	if (content === null || content === undefined) {
		return null;
	}
	if (content === "") {
		return null;
	}
	return el("tr", [el("td.field", headerOptions, header + ":"), el("td", content)]);
}

export function createAccordionCard(id, parentId, header, body) {
	var contentId = "accordion-" + id + "-content";

	var cardheadertext = el("span.mr-auto", header);
	var cardicon = svg(
		"svg.list-item-action",
		svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#angle-down" } })
	);

	var cardheaderbutton = el(
		"button.btn.btn-link.d-flex.flex-row.w-100.p-0",
		{
			type: "button",
			"data-toggle": "collapse",
			"data-target": "#" + contentId,
			"aria-expanded": "false",
			"aria-controls": contentId,
		},
		[cardheadertext, cardicon]
	);
	var cardbody = el(".card-body", body);
	var cardcontent = el("#" + contentId + ".collapse", { "data-parent": "#" + parentId }, cardbody);
	var cardheader = el(".card-header.p-0.pl-2#accordion-" + id + "-head", cardheaderbutton);

	return el(".card", [cardheader, cardcontent]);
}

export function createDialogTitleFragment(name, subHeading) {
	var titleFragment = document.createDocumentFragment();
	titleFragment.appendChild(document.createTextNode(name));

	if (subHeading !== null && subHeading !== "") {
		var deviceName = document.createElement("div");
		deviceName.className = "device-name";
		deviceName.textContent = subHeading;
		titleFragment.appendChild(deviceName);
	}
	return titleFragment;
}

export function svgPath(icon) {
	return "/content/svg/tracking.svg?v=15#" + icon;
}

export function getSvgIconForItemType(type, item) {
	if (type === "shared-views") {
		return svgPath("share-alt-square");
	} else if (type === "groups") {
		return svgPath("folder-open-solid");
	} else if (type === "journeys") {
		return svgPath("folder-open-solid");
	} else if (type === "drivers") {
		return svgPath("steering-wheel");
	} else if (type == "fences") {
		return svgPath("square-full-duo");
	}
	return null;
}

export function populateCheckboxList(
	containerId,
	items,
	name,
	isCheckedFunction,
	getNameFunction,
	type,
	getDescriptionFunction,
	getSecondColumnFunction
) {
	var container = document.getElementById(containerId);
	let cont = container.querySelector(".list");
	var hiddenCont = container.querySelector(".list-hidden");
	cont.innerHTML = "";
	hiddenCont.innerHTML = "";

	var assetAssetsContainer = document.createDocumentFragment();
	var assetHiddenContainer = document.createDocumentFragment();
	for (var k = 0; k < items.length; k++) {
		var item = items[k];
		var isChecked = isCheckedFunction(item);
		var formCheck = document.createElement("div");
		formCheck.className = "custom-control custom-checkbox";
		var input = document.createElement("input");
		input.setAttribute("type", "checkbox");
		input.setAttribute("id", name + k);
		input.setAttribute("name", name + "-name");
		input.className = "custom-control-input";
		input.setAttribute("value", item.Id);
		if (isChecked) {
			input.setAttribute("checked", "checked");
		}
		formCheck.appendChild(input);

		var label = document.createElement("label");
		label.setAttribute("for", name + k);
		label.className = "custom-control-label";

		if (type !== undefined && type !== null) {
			var icon = getGenericIconUrlForItemType(type, item);
			var svgIcon = getSvgIconForItemType(type, item);
			if (svgIcon !== null) {
				var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
				var iconType = document.createElementNS("http://www.w3.org/2000/svg", "use");
				iconType.setAttributeNS("http://www.w3.org/1999/xlink", "href", svgIcon);
				svg.appendChild(iconType);
				if (item.Color !== undefined && item.Color !== null) {
					svg.setAttribute(
						"style",
						"color: " + item.Color + "; --color-primary: " + item.Color + "; --color-secondary: " + item.Color
					);
				}
				label.appendChild(svg);
				label.classList.add("has-svg-icon");
			} else if (icon !== null) {
				label.classList.add("has-icon");
				label.style.backgroundImage = icon;
			}
		}

		var span = document.createElement("span");
		if (getSecondColumnFunction !== undefined && getSecondColumnFunction !== null) {
			span.className = "col-6 pl-0";
		}
		var nameText = document.createTextNode(getNameFunction(item));
		span.appendChild(nameText);

		if (getDescriptionFunction !== undefined && getDescriptionFunction !== null) {
			var description = getDescriptionFunction(item);
			if (description !== null) {
				var desc = document.createElement("div");
				desc.textContent = description;
				desc.classList.add("meta");
				span.appendChild(desc);
			}
		}

		label.appendChild(span);

		if (getSecondColumnFunction !== undefined && getSecondColumnFunction !== null) {
			var second = document.createElement("div");
			second.className = "col-6 p-0";
			var secondText = document.createTextNode(getSecondColumnFunction(item));
			second.appendChild(secondText);
			label.appendChild(second);
		}

		formCheck.appendChild(label);
		assetAssetsContainer.appendChild(formCheck);

		var hidden = input.cloneNode();
		hidden.setAttribute("id", hidden.id + "-hidden");
		hidden.setAttribute("name", name);
		assetHiddenContainer.appendChild(hidden);
	}
	cont.appendChild(assetAssetsContainer);
	hiddenCont.appendChild(assetHiddenContainer);

	// shouldn't we check if it exists first?
	var list = createCheckboxFilterList(containerId);
	return list;
}

export function disabledCheckboxEl(checked) {
	var check = el("input.form-check-input", { type: "checkbox", disabled: true });
	if (checked) {
		check.setAttribute("checked", "checked");
	}
	var box = el("div.form-check", check);
	return box;
}

export function unrestrictDirectChildren(item, cont) {
	var items = cont.querySelectorAll('input[data-parent="' + item.value + '"]');
	_.each(items, function (item) {
		item.disabled = false;
	});
	//$('input[data-parent=' + item.val() + ']').each(function (index, elem) {
	//    var child = $(elem);
	//    child.prop('disabled', false);
	//});
}

export function restrictAllChildren(item, cont) {
	// all children checkboxes must be checked and disabled
	var items = cont.querySelectorAll('input[data-parent="' + item.value + '"]');
	_.each(items, function (item) {
		item.disabled = true;
		item.checked = true;
		restrictAllChildren(item, cont);
	});
	//$('input[data-parent=' + item.val() + ']').each(function (index, elem) {
	//    var child = $(elem);
	//    child.prop('checked', true).prop('disabled', true);
	//    restrictAllChildren(child);
	//});
}

export function getValueIfCheckboxSelected(element) {
	var elem = $j(element);
	var include = elem.closest(".parameter-options").prev(".parameter-toggle").find("input:checkbox").prop("checked");
	//var include = elem.prevAll('input:checkbox').prop('checked');
	if (include) return elem.val();
	else return null;
}

export function formattedTextToDiv(formatted) {
	// split the text on newlines and add html breaks instead
	// returning an array of nodes
	if (formatted === undefined || formatted === null || formatted === "") {
		return null;
	}

	var lines = formatted.split(/\r?\n/g);
	var nodes = [];
	for (var i = 0; i < lines.length; i++) {
		var line = lines[i];
		nodes.push(text(line));
		if (i != lines.length - 1) {
			nodes.push(el("br"));
		}
	}
	return el("div", nodes);
}

export function htmlEscape(str) {
	return String(str)
		.replace(/&/g, "&amp;")
		.replace(/"/g, "&quot;")
		.replace(/'/g, "&#39;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;");
}

function fallbackCopyTextToClipboard(text) {
	var textArea = document.createElement("textarea");
	textArea.value = text;
	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	var success = false;
	try {
		var successful = document.execCommand("copy");
		var msg = successful ? "successful" : "unsuccessful";
		console.log("Fallback: Copying text command was " + msg);
		success = true;
	} catch (err) {
		console.error("Fallback: Oops, unable to copy", err);
	}

	document.body.removeChild(textArea);
	return success;
}

export function copyTextToClipboard(text) {
	var def = $.Deferred();

	if (text === undefined || text === null || text === "") {
		def.reject();
		return def;
	}

	if (!navigator.clipboard) {
		if (fallbackCopyTextToClipboard(text)) {
			console.log("fallback success");
			def.resolve(true);
		} else {
			console.log("fallback fail");
			def.reject();
		}
		return def;
	}

	navigator.clipboard.writeText(text).then(
		function () {
			def.resolve(true);
			console.log("Async: Copying to clipboard was successful!");
		},
		function (err) {
			def.reject();
			console.error("Async: Could not copy text: ", err);
		}
	);
	return def;
}
