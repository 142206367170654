import trkData from "./data.js";
import log from "./log.js";
import state from "./state.js";
import { intervals } from "./timers.js";
import { wrapUrl } from "./wrapurl.js";

import L from "leaflet";
import $j from "jquery";

// Snap To Roads Queueing
function addSnapToRoadsRequestToQueue(item, ignoreMode) {
	trkData.snapToRoadsQueue.push(item);
	if (!state.isSnapToRoadsQueueRunning) {
		state.isSnapToRoadsQueueRunning = true;
		intervals.snapToRoadsInterval = setInterval(processSnapToRoadsQueue, 250); // 10 per second maximum, 50 ms cushion
	}
}

function clearSnapToRoadsQueue() {
	if (intervals.snapToRoadsInterval != null) {
		clearInterval(intervals.snapToRoadsInterval);
	}
	state.isSnapToRoadsQueueRunning = false;
	state.isSnapToRoadsQueueProcessing = false;
	trkData.snapToRoadsQueue = [];
}

function processSnapToRoadsQueue() {
	if (state.isSnapToRoadsQueueProcessing) {
		return;
	}
	state.isSnapToRoadsQueueProcessing = true;
	if (!state.isSnapToRoadsQueueRunning) {
		return;
	}

	// singleton that can only be started once
	if (trkData.snapToRoadsQueue.length == 0) {
		clearSnapToRoadsQueue();
		return;
	}

	var item = trkData.snapToRoadsQueue[0];

	// call snap.ashx with p in the format of lng,lat;
	// todo: include bearings
	var points = "";
	var timestamps = "";
	var radiuses = "";
	for (var i = 0; i < item.points.length; i++) {
		if (points != "") {
			points += ";";
			timestamps += ";";
			radiuses += ";";
		}
		var point = item.points[i].latLng;
		var timestamp = item.points[i].timestamp;
		var accuracy = item.points[i].accuracy;
		points += point.lng + "," + point.lat;
		timestamps += timestamp;
		radiuses += accuracy != null ? (accuracy > 50 ? 50 : accuracy) : "10";
	}

	var data = {
		p: points,
		t: timestamps,
		r: radiuses,
	};

	$j.ajax({
		type: "POST",
		url: wrapUrl("/api/routing/snap"),
		dataType: "json",
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		success: function (results) {
			var stopProcessing = true;
			var pathToAdd = [];
			if (results.code == "Ok") {
				var newPoints = decode_polyline(results.polyline);
				for (var i = 0; i < newPoints.length; i++) {
					pathToAdd.push(L.latLng(newPoints[i][0], newPoints[i][1]));
				}
			} else {
				for (var i = 0; i < item.points.length; i++) {
					pathToAdd.push(item.points[i]);
				}
			}

			trkData.snapToRoadsQueue.shift();

			if (item.target != null) {
				var locations = item.target.getLatLngs();
				for (var i = 0; i < pathToAdd.length; i++) {
					locations.push(pathToAdd[i]);
				}
				item.target.setLatLngs(locations);
			}
			if (stopProcessing) {
				state.isSnapToRoadsQueueProcessing = false;
			}
		},
		error: function () {
			var pathToAdd = [];
			for (var i = 0; i < item.points.length; i++) {
				pathToAdd.push(item.points[i].latLng);
			}
			if (item.target != null) {
				var locations = item.target.getLatLngs();
				for (var i = 0; i < pathToAdd.length; i++) {
					locations.push(pathToAdd[i]);
				}
				item.target.setLatLngs(locations);
			}
			trkData.snapToRoadsQueue.shift();
			state.isSnapToRoadsQueueProcessing = false;
		},
	});
}

// End Snap To Roads Queueing

export function snapLinePathsToRoads(target, linePath) {
	// this function will queue and update target's mapLine with the snapped polylines
	// directions service only handles 10 waypoints at a time
	// take sections of 10 at a time from the positions
	// must tie sections together as well (8 waypoints max)
	var priorEndingPosition = null;
	var routePositions = [];
	for (var i = 0; i < linePath.length; i++) {
		var section = linePath[i];
		var positionPoints = section.reverse();
		var isValidRoute = section.length > 1;

		if (isValidRoute) {
			log("Creating directions route from " + positionPoints.length + " positions.");
			// todo: never leave one position leftover
			for (var j = 0; j < positionPoints.length; j += 15) {
				//  construct the routes and QUEUE them up due to processing limits
				var startIndex = j;
				var endIndex = positionPoints.length > j + 14 ? j + 14 : positionPoints.length;
				routePositions = positionPoints.slice(startIndex, endIndex + 1);
				if (priorEndingPosition != null) {
					// add prior ending position as first position (origin)
					// in order to have a continuous, connected path
					routePositions.unshift(priorEndingPosition);
				}
				var originalRoutePositions = routePositions.slice();
				var origin = routePositions.shift();
				var destination = routePositions.pop();
				var routeWaypoints = [];
				for (var k = 0; k < routePositions.length; k++) {
					routeWaypoints.push({
						location: routePositions[k],
					});
				}
				var route = {
					origin: origin,
					destination: destination,
					waypoints: routeWaypoints,
				};
				priorEndingPosition = destination;
				var queueItem = {
					target: target,
					points: originalRoutePositions,
					route: route,
				};
				addSnapToRoadsRequestToQueue(queueItem);
			}
		}
	}
}

function decode_polyline(str, precision) {
	var index = 0,
		lat = 0,
		lng = 0,
		coordinates = [],
		shift = 0,
		result = 0,
		byte = null,
		latitude_change,
		longitude_change,
		factor = Math.pow(10, precision || 5);

	// Coordinates have variable length when encoded, so just keep
	// track of whether we've hit the end of the string. In each
	// loop iteration, a single coordinate is decoded.
	while (index < str.length) {
		// Reset shift, result, and byte
		byte = null;
		shift = 0;
		result = 0;

		do {
			byte = str.charCodeAt(index++) - 63;
			result |= (byte & 0x1f) << shift;
			shift += 5;
		} while (byte >= 0x20);

		latitude_change = result & 1 ? ~(result >> 1) : result >> 1;

		shift = result = 0;

		do {
			byte = str.charCodeAt(index++) - 63;
			result |= (byte & 0x1f) << shift;
			shift += 5;
		} while (byte >= 0x20);

		longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

		lat += latitude_change;
		lng += longitude_change;

		coordinates.push([lat / factor, lng / factor]);
	}

	return coordinates;
}
