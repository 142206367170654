import { findTripById } from "./trips.js";
import { findSharedViewById } from "./shared-view.js";
import { findJourneyById } from "./journey.js";
import { handleAjaxFormSubmission, toggleLoadingMessage } from "./ajax.js";
import trkData from "./data.js";
import { findAssetIdsUnderGroup } from "./assets.js";
import { findGroupById } from "./asset-group.js";
import { throttles } from "./timers.js";
import strings from "./strings.js";
import { renderDomElement } from "./datatables.js";
import user from "./user.js";
import { devices, findDeviceById, isSendCommandDisabledForDevice } from "./devices.js";
import { wrapUrl } from "./wrapurl.js";
import { addAssetMessages } from "./messages.js";
import { trkDataGroups } from "./const.js";
import { handleWebServiceError } from "./ajax.js";
import { convertToLatLngPreference } from "./preferences.js";
import { formattedTextToDiv } from "./dom-util.js";
import domNodes from "./domNodes.js";
import { openDialogPanel } from "./panel-nav.js";
import { getPositionLinkForEvent } from "./positions.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import { el, svg, text } from "redom"; // https://redom.js.org/
import moment from "moment"; // https://www.npmjs.com/package/moment

export function loadHistoryMessagesForSharedView(sharedViewId) {
	var sharedView = findSharedViewById(sharedViewId);
	var isLoaded = $.Deferred();

	// check if already loaded
	if (trkData.sharedView.isMessagesLoaded) {
		isLoaded.resolve(true);
		return isLoaded;
	}

	var from = null;
	var to = null;
	if (sharedView.FromDateEpoch !== null) {
		from = moment.utc(sharedView.FromDateEpoch).format(user.dateFormat);
	}
	if (sharedView.ToDateEpoch !== null) {
		to = moment.utc(sharedView.ToDateEpoch).format(user.dateFormat);
	}

	var queryAssetIds = sharedView.AssetIds.slice(0);
	var assetGroupIds = sharedView.AssetGroupIds;
	_.each(assetGroupIds, function (assetGroupId) {
		var group = findGroupById(assetGroupId);
		if (group !== null) {
			var groupAssetIds = findAssetIdsUnderGroup(group);
			queryAssetIds = queryAssetIds.concat(groupAssetIds);
		}
	});

	if (queryAssetIds.length === 0) {
		isLoaded.resolve(true);
		return isLoaded;
	}

	// query for the asset's messages for this date range
	var data = {
		assetIds: queryAssetIds,
		fromDate: from,
		toDate: to,
		isUtc: true,
		format: user.dateFormat,
		lang: user.dateCulture,
	};

	handleAjaxFormSubmission(
		"GetMessageHistoryForAssetsByDateRange",
		data,
		null,
		null,
		null,
		strings.MSG_MESSAGE_HISTORY_ERROR,
		function (result) {
			trkData.sharedView.isMessagesLoaded = true;
			_.each(result.Assets, function (assetResult) {
				addAssetMessages(assetResult, trkDataGroups.SHARED_VIEW_HISTORY, null, sharedView);
			});
			isLoaded.resolve(true);
		},
		function (xhr, staus, error) {
			isLoaded.reject(false);
		}
	);

	return isLoaded;
}

export function loadHistoryMessagesForTrip(tripId) {
	// separate query per date range here
	var trip = findTripById(tripId);
	var journey = findJourneyById(trip.JourneyId);

	var isLoaded = $.Deferred();

	var queryAssetIds = [];
	var tripMessages = trkData.trips.messagesByTripId[tripId];
	if (tripMessages === undefined || tripMessages === null) {
		queryAssetIds.push(journey.AssetId);
	}

	if (queryAssetIds.length === 0) {
		isLoaded.resolve(true);
		return isLoaded;
	}

	// query for the asset's messages for this date range
	var data = {
		assetIds: queryAssetIds,
		fromDate: moment.utc(trip.FromEpoch).format(user.dateFormat),
		toDate: moment.utc(trip.ToEpoch).format(user.dateFormat),
		isUtc: true,
		format: user.dateFormat,
		lang: user.dateCulture,
	};
	var loadingKey = "message-history-trip-" + queryAssetIds[0];
	toggleLoadingMessage(true, loadingKey);
	$.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetMessageHistoryForAssetsByDateRange"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					_.each(result.Assets, function (assetResult) {
						addAssetMessages(assetResult, trkDataGroups.JOURNEY_HISTORY, trip);
					});
				}
			}
			toggleLoadingMessage(false, loadingKey);
			isLoaded.resolve(true);
		},
		error: function (xhr, status, error) {
			isLoaded.reject(false);
			handleWebServiceError(strings.MSG_MESSAGE_HISTORY_ERROR);
			toggleLoadingMessage(false, loadingKey);
		},
	});

	return isLoaded;
}

export function loadHistoryMessagesForAssets(assetIds) {
	// todo: loading indicator and error should be populated in the message dialog

	var isLoaded = $.Deferred();
	var queryAssetIds = [];
	_.each(assetIds, function (assetId) {
		var assetMessages = trkData.history.messagesByAssetId[assetId];
		if (assetMessages === undefined || assetMessages === null) {
			queryAssetIds.push(assetId);
		}
	});

	if (queryAssetIds.length === 0) {
		isLoaded.resolve(true);
		return isLoaded;
	}

	// query for the asset's messages for this date range
	var data = {
		assetIds: queryAssetIds,
		fromDate: trkData.history.fromDate,
		toDate: trkData.history.toDate,
		isUtc: false,
		format: user.dateFormat,
		lang: user.dateCulture,
	};
	var loadingKey = "message-history-" + queryAssetIds[0];
	toggleLoadingMessage(true, loadingKey);
	$.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetMessageHistoryForAssetsByDateRange"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					_.each(result.Assets, function (assetResult) {
						addAssetMessages(assetResult, trkDataGroups.NORMAL_HISTORY);
					});
				}
			}
			toggleLoadingMessage(false, loadingKey);
			isLoaded.resolve(true);
		},
		error: function (xhr, status, error) {
			isLoaded.reject(false);
			handleWebServiceError(strings.MSG_MESSAGE_HISTORY_ERROR);
			toggleLoadingMessage(false, loadingKey);
		},
	});

	return isLoaded;
}

export function loadMessageHistory(asset) {
	var device = findDeviceById(asset.DeviceId);
	if (isSendCommandDisabledForDevice(device)) {
		$j("#MessagesSendCommand").prop("disabled", true);
	} else {
		$j("#MessagesSendCommand").prop("disabled", false);
	}

	toggleLoadingMessage(true, "message-history");
	var btn = $j("#RefreshMessages");
	btn.prop("disabled", true);
	var data = { assetId: asset.Id };
	$j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetMessageHistoryForAsset"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			btn.prop("disabled", false);
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true && result.Assets.length > 0) {
					result = result.Assets[0];

					var includeGateway = false;
					if (_.indexOf(devices.SKYWAVE_IDP_DUAL_MODE, asset.DeviceId) !== -1) {
						includeGateway = true;
					}

					// todo: limit the amount of data for rendering purposes?
					var inboxData = [];
					for (var i = 0; i < result.Inbox.length; i++) {
						var item = result.Inbox[i];
						var labelItems = [];
						if (item.IsAcknowledged) {
							if (item.AcknowledgedBy != null) {
								labelItems.push(text(item.AcknowledgedBy));
								labelItems.push(el("br"));
							}
							if (item.AcknowledgedOn != null) {
								labelItems.push(text(item.AcknowledgedOn));
							}
						}
						var checkbox = el("input.custom-control-input", {
							type: "checkbox",
							disabled: true,
							checked: item.IsAcknowledged,
						});
						if (item.IsAcknowledged) {
							checkbox.setAttribute("checked", "checked");
						}
						var acknowledgedElement = el("div.custom-control.custom-checkbox", [
							checkbox,
							text(" "),
							el("label.custom-control-label", labelItems),
						]);
						var priorityImage = el("img", {
							src: "/content/images/icon-priority-" + item.PriorityValue + ".png",
							alt: item.Priority,
							style: { border: 0 },
						});
						var address = null;
						if (item.Position != null) {
							address = item.Position.Address;
							if (address == null || address.trim() == "" || asset.HideAddress) {
								address = convertToLatLngPreference(
									item.Position.DisplayLat,
									item.Position.DisplayLng,
									item.Position.Grid
								);
							}
							address = el("a.location", { href: "#", dataset: { marker: item.Position.Id } }, address);
						}
						inboxData.push([
							priorityImage,
							formattedTextToDiv(item.Text),
							//el('pre', item.Text), // would <pre> be preferable?
							address,
							item.CreatedOn,
							item.Source,
							acknowledgedElement,
							el(
								"button.command.delete.delete-message.btn.btn-outline-danger",
								{ title: strings.DELETE, dataset: { messageId: item.Id } },
								svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#trash-alt-light" } }))
							),
						]);
					}

					var inboxCompleted = new $j.Deferred();
					var outboxCompleted = new $j.Deferred();
					var alertsCompleted = new $j.Deferred();

					$("#MessageHistoryInbox").dataTable({
						data: inboxData,
						destroy: true,
						filter: false,
						info: false,
						jQueryUI: true,
						autoWidth: false,
						lengthChange: false,
						paging: true,
						pagingType: "simple",
						pageLength: 10,
						deferRender: true,
						order: [[3, "desc"]],
						columnDefs: [
							{
								targets: "_all",
								render: $.fn.dataTable.render.text(),
							},
						],
						columns: [
							{ width: "45px", class: "center", type: "string", render: renderDomElement }, // priority
							{ sortable: false, render: renderDomElement }, // text
							{ sortable: false, render: renderDomElement }, // position
							{ width: "75px" }, // time
							{ width: "50px" }, // source
							{ width: "75px", class: "center", type: "string", render: renderDomElement }, // isacknowledged
							{
								sortable: false,
								class: "center",
								visible: user.isAdmin || user.canEditAssets,
								render: renderDomElement,
							}, // delete
						],
						language: strings.DATATABLE,
						drawCallback: function (settings) {
							//$j('button.command.delete', dialog).button({ text: null, icons: { primary: 'ui-icon-trash' } });
						},
						initComplete: function (settings, json) {
							inboxCompleted.resolve();
						},
					});

					// outbox
					var outboxData = [];
					for (var i = 0; i < result.Outbox.length; i++) {
						var item = result.Outbox[i];
						outboxData.push([
							item.Type,
							item.Text,
							item.RawText,
							item.Source,
							item.CreatedOn,
							item.IsSent,
							item.IsError,
							item.Response,
							item.SentOn,
							item.Id,
							item.Status,
							el(
								"button.command.delete.delete-message.btn.btn-outline-danger",
								{ title: strings.DELETE, dataset: { messageId: item.Id } },
								svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#trash-alt-light" } }))
							),
						]);
					}

					$("#MessageHistoryOutbox").dataTable({
						data: outboxData,
						destroy: true,
						filter: false,
						info: false,
						jQueryUI: true,
						autoWidth: false,
						lengthChange: false,
						paging: true,
						pagingType: "simple",
						pageLength: 10,
						deferRender: true,
						order: [[4, "desc"]],
						columnDefs: [
							{
								targets: "_all",
								render: $.fn.dataTable.render.text(),
							},
						],
						columns: [
							{ class: "center", width: "100px" }, // type
							{
								// text
								sortable: false,
								render: function (data, type, row, meta) {
									if (type == "display") {
										var raw = row[meta.col + 1];
										if (data == null || data == "") {
											if (raw != null && raw != "") {
												var btn = el("button.btn.btn-sm.message-info.btn-secondary", { title: raw }, [
													svg("svgn", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#info-circle" } })),
													text(" "),
													el("span", "Info"), // TODO: i18n
												]);
												return btn.outerHTML;
											}
										}
										return $.fn.dataTable.render.text().display(data);
									}
									return data;
								},
							}, // text
							{ visible: false }, // rawtext
							{ width: "50px", visible: includeGateway }, // source
							{ width: "125px" }, // createdon
							{
								width: "50px",
								class: "center",
								render: function (data, type, row, meta) {
									var isSent = data;
									var isError = row[meta.col + 1];
									var response = row[meta.col + 2];
									var id = row[9];
									var status = row[10];
									var error = null;
									if (isError) {
										error = el("a.error", { title: response, href: "#", dataset: { messageId: id } }, strings.ERROR);
										status = "";
									}
									if (status == null) {
										status = "";
									}
									var checkbox = el("input.custom-control-input", {
										type: "checkbox",
										disabled: true,
										checked: isSent,
									});
									if (isSent) {
										checkbox.setAttribute("checked", "checked");
									}
									var sent = el("div.custom-control.custom-checkbox", [
										checkbox,
										el("label.custom-control-label", [error, text(status)]),
									]);
									return sent.outerHTML;
								},
							}, // issent
							{ visible: false }, // IsError
							{ visible: false }, // Response
							{ width: "125px" },
							{ visible: false }, // Id
							{ visible: false }, // Status
							{
								sortable: false,
								class: "center",
								visible: user.isAdmin || user.canEditAssets,
								render: renderDomElement,
							}, // delete
						],
						language: strings.DATATABLE,
						initComplete: function (settings, json) {
							outboxCompleted.resolve();
						},
						drawCallback: function (settings) {
							//$j('button.command.delete', dialog).button({ text: null, icons: { primary: 'ui-icon-trash' } });
							//$j('#MessageHistoryOutbox button.message-info').button({ text: null, icons: { primary: 'ui-icon-info' } }).tooltip();
							$j("#MessageHistoryOutbox button.message-info").bsTooltip({ placement: "right" });
							$j("#MessageHistoryOutbox a.error").bsTooltip().parent().addClass("error");
						},
					});

					var alertsData = [];
					for (var i = 0; i < result.Alerts.length; i++) {
						var item = result.Alerts[i].Event;
						item.Position = result.Alerts[i].Position;
						if (item.Alert == null) {
							continue;
						}

						var eventposition = getPositionLinkForEvent(item);

						var type = item.Alert.Type;
						if (item.Alert.Name != null) {
							type = item.Alert.Name + ": " + type;
						}
						type = el("span", [text(item.TypeName + ":"), el("br"), text(type)]);
						var ack = el("span", item.Alert.AcknowledgedStatus);
						if (item.Type === 14) {
							if (item.Alert.RequiresAcknowledgement) {
								ack = el(
									"button.AlertAcknowledge.btn.btn-sm.btn-primary",
									{ role: "button", dataset: { alert: item.Id } },
									strings.ACKNOWLEDGE
								);
							}
							if (item.Alert.Acknowledged) {
								ack = el(
									"span",
									strings.ACKNOWLEDGE_STATUS.replace("{Status}", item.Alert.AcknowledgedStatus)
										.replace("{Time}", item.Alert.AcknowledgedOn)
										.replace("{User}", item.Alert.AcknowledgedBy)
								);
							}
						}
						alertsData.push([
							"",
							item.Alert.Priority,
							type,
							formattedTextToDiv(item.Alert.AcknowledgedText),
							eventposition, // Position
							item.Time,
							ack,
							item.Type,
							item.Alert.Color,
						]);
					}

					$("#MessageHistoryAlerts").dataTable({
						data: alertsData,
						destroy: true,
						filter: false,
						info: false,
						jQueryUI: true,
						autoWidth: false,
						lengthChange: false,
						paging: true,
						pagingType: "simple",
						pageLength: 10,
						deferRender: true,
						order: [[5, "desc"]],
						columnDefs: [
							{
								targets: "_all",
								render: $.fn.dataTable.render.text(),
							},
						],
						columns: [
							{ sortable: false, width: "20px" }, // Color Display
							{ sortable: true }, // Priority
							{ sortable: true, render: renderDomElement }, // Type
							{ sortable: false, render: renderDomElement }, // Resolution
							{ sortable: false, render: renderDomElement }, // Position/lat/lng
							{ width: "75px" }, // Time
							{ sortable: false, render: renderDomElement }, // Acknowledge
							{ visible: false }, // Event Type Id
							{ visible: false }, // Color
						],
						language: strings.DATATABLE,
						initComplete: function (settings, json) {
							alertsCompleted.resolve();
						},
						drawCallback: function (settings) {},
						rowCallback: function (row, data, index) {
							if (data[8] != "") {
								$j("td:eq(0)", row).css("background-color", data[8]);
							}
						},
					});

					trkData.messaging.alerts = result.Alerts;
					trkData.messaging.inbox = result.Inbox;
					trkData.messaging.outbox = result.Outbox;
				} else {
					// output result.ErrorMessage
				}
				asset.LastIncomingMessage = null;
				asset.MessageStatuses = [];
				asset.UnconfirmedMessages = [];
				throttles.updatePositionStatus();
				$j.when(inboxCompleted, outboxCompleted, alertsCompleted).done(function () {
					// todo: hide loading indicators
					//dialog.dialog('open');
				});
			}
			toggleLoadingMessage(false, "message-history");
		},
		error: function (xhr, status, error) {
			btn.prop("disabled", false);
			handleWebServiceError(strings.MSG_MESSAGE_HISTORY_ERROR);
			// re-enable show button and clear loading message
			toggleLoadingMessage(false, "message-history");
		},
	});
}

export function loadMessageHistoryDialog(asset) {
	$(domNodes.dialogs.messageHistory).data("assetId", asset.Id);
	openDialogPanel(
		domNodes.dialogs.messageHistory,
		strings.MESSAGE_LOG,
		asset,
		true,
		function () {
			trkData.messaging.inbox = null;
			trkData.messaging.outbox = null;
		},
		"asset",
		"view-logs-message",
		loadMessageHistoryDialog
	);

	// clear table rows
	loadMessageHistory(asset);
}
