import { mapModes, panels } from "./const.js";
import state from "./state.js";
import options from "./options.js";

export function setUrlHistoryForActivePanel(panelAction) {
	var action = "";
	var defaultAction = " ";
	if (panelAction !== undefined) {
		action = "/" + panelAction;
		defaultAction = "#" + panelAction;
	}
	var modeText =
		state.activeMapMode === null ? options.defaultMode : state.activeMapMode === mapModes.HISTORY ? "history" : "live";
	switch (state.activePanel) {
		case panels.ASSETS:
			history.replaceState("panel-active", "", "#" + modeText + "/assets" + action);
			break;
		case panels.GEOFENCES:
			history.replaceState("panel-active", "", "#" + modeText + "/fences" + action);
			break;
		case panels.PLACES:
			history.replaceState("panel-active", "", "#" + modeText + "/places" + action);
			break;
		case panels.JOURNEYS:
			history.replaceState("panel-active", "", "#" + modeText + "/journeys" + action);
			break;
		case panels.SHARED_VIEWS:
			history.replaceState("panel-active", "", "#" + modeText + "/shared-views" + action);
			break;
		default:
			history.replaceState("panel-active", "", defaultAction);
			break;
	}
}
