export const viewModes = {
	NORMAL: Symbol("view-normal"),
	SHARED_VIEW: Symbol("view-shared"),
};

export const panels = {
	//LIVE: 0,
	//HISTORY: 1,
	ASSETS: 0,
	BEHAVIOR_ANALYSIS: 2,
	GEOFENCES: 3,
	PLACES: 4,
	ADD_ITEM: 5,
	FOLLOW_ASSETS: 6,
	JOURNEYS: 10,
	SHARED_VIEWS: 12,
};

export const mapModes = {
	LIVE: Symbol("map-live"),
	HISTORY: Symbol("map-history"),
	TIME_SLIDER: Symbol("map-time-slider"),
};

export const trkDataGroups = {
	NORMAL_LIVE: Symbol("live"),
	NORMAL_HISTORY: Symbol("history"),
	JOURNEY_HISTORY: Symbol("journey-history"),
	SHARED_VIEW_HISTORY: Symbol("shared-history"),
	SHARED_VIEW_JOURNEY: Symbol("shared-journet"),
	// NORMAL_LIVE: 0,
	// NORMAL_HISTORY: 1,
	// JOURNEY_HISTORY: 2,
	// SHARED_VIEW_HISTORY: 3,
	// SHARED_VIEW_JOURNEY: 4,
};

export const sortModes = {
	ALPHABETICAL: 0,
	CUSTOM: 1,
	COLOR: 2,
	MANUFACTURER: 3,
	DEVICE_TYPE: 4,
};

export const sortDirections = {
	ASC: 0,
	DESC: 1,
};

export let language = "en";

export function setLanguage(l) {
	language = l;
}
