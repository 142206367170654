import trkData from "./data.js";
import { viewModes, mapModes } from "./const.js";
import state from "./state.js";
import strings from "./strings.js";
import domNodes from "./domNodes.js";
import { updateLimitedDataResults } from "./limited-data.js";
import user from "./user.js";

import _ from "lodash";
import moment from "moment"; // https://www.npmjs.com/package/moment

export function updateMapModePanel() {
	var historyCustom = document.getElementById("history-custom");
	if (state.activeViewMode === viewModes.NORMAL && state.activeMapMode === mapModes.LIVE) {
		domNodes.moduleName.textContent = strings.MODE_LIVE;
		domNodes.assetsModeLive.classList.add("is-visible");
		domNodes.assetsModeHistory.classList.remove("is-visible");
		domNodes.mapMode.container.classList.add("mode-live");
		domNodes.mapMode.container.classList.remove("mode-history");
		var historyButtons = document.getElementById("filter-history-range").querySelectorAll("button");
		_.each(historyButtons, function (button) {
			button.classList.remove("active");
		});
		domNodes.mapMode.dateRange.classList.remove("is-visible");
		domNodes.mapMode.modeLiveButton.classList.add("active");
		historyCustom.classList.remove("btn-primary");
		domNodes.mapMode.container.classList.remove("is-limited");
		domNodes.mapMode.container.classList.remove("has-excess");
		domNodes.mapMode.container.classList.remove("is-filtered");

		// hide calendar
	} else {
		domNodes.moduleName.textContent = strings.MODE_HISTORY;
		domNodes.assetsModeLive.classList.remove("is-visible");
		domNodes.assetsModeHistory.classList.add("is-visible");
		domNodes.mapMode.container.classList.add("mode-history");
		domNodes.mapMode.container.classList.remove("mode-live");
		domNodes.mapMode.modeLiveButton.classList.remove("active");

		domNodes.mapMode.container.classList.remove("is-filtered");
		domNodes.mapMode.container.classList.remove("has-excess");
		domNodes.mapMode.container.classList.remove("is-limited");

		if (state.activeViewMode === viewModes.NORMAL) {
			// if any pre-defined date range buttons are active then hide inactivate custom and hide datepickers

			var historyButtons = document.getElementById("filter-history-range").querySelectorAll("button");
			var isPredefinedRange = false;
			_.each(historyButtons, function (button) {
				if (button.classList.contains("active") && button.id !== "history-live" && button.id !== "history-custom") {
					isPredefinedRange = true;
				}
			});
			if (isPredefinedRange) {
				domNodes.mapMode.dateRange.classList.remove("is-visible");
				historyCustom.classList.remove("btn-primary");
				historyCustom.classList.remove("active");
			}

			if (trkData.history.isLimited || trkData.history.limitedData !== null) {
				domNodes.mapMode.container.classList.add("has-excess");
				if (trkData.history.isLoadedLimitedData || trkData.history.limitedData !== null) {
					domNodes.mapMode.container.classList.add("is-limited");
					if (trkData.history.limitedData !== null) {
						domNodes.mapMode.container.classList.add("is-filtered");
					}
				}
			}
		} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
			if (trkData.sharedView.isLimited || trkData.sharedView.limitedData !== null) {
				domNodes.mapMode.container.classList.add("has-excess");
				if (trkData.sharedView.isLoadedLimitedData || trkData.sharedView.limitedData !== null) {
					domNodes.mapMode.container.classList.add("is-limited");
					if (trkData.sharedView.limitedData !== null) {
						domNodes.mapMode.container.classList.add("is-filtered");
					}
				}
			}
		}
		updateLimitedDataResults(state.activeViewMode);
	}
	updateMapModeDateRange();
}

export function updateMapModeDateRange() {
	var from = null;
	var to = null;
	if (state.activeViewMode === viewModes.NORMAL) {
		if (trkData.history.limitedData !== null) {
			from = trkData.history.limitedData.fromDate;
			to = trkData.history.limitedData.toDate;
		} else {
			from = trkData.history.fromDateFull;
			to = trkData.history.toDate;
		}
	} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
		if (trkData.sharedView.limitedData !== null) {
			from = trkData.sharedView.limitedData.fromDate;
			to = trkData.sharedView.limitedData.toDate;
		} else {
			from = trkData.sharedView.fromDate;
			to = trkData.sharedView.toDate;
		}
	}

	if (to === "" || to === undefined) {
		to = null;
	}
	if (from === "" || from === undefined) {
		from = null;
	}

	if (from === null && to === null) {
		domNodes.mapMode.from.textContent = strings.ALL_TIME;
		domNodes.mapMode.to.textContent = "";
	} else {
		if (from !== null) {
			domNodes.mapMode.from.textContent = from;
		} else {
			domNodes.mapMode.from.textContent = "";
		}
		if (to === null) {
			domNodes.mapMode.to.textContent = moment().subtract(user.tickOffset, "ms").format(user.dateFormat); //.substring(0, user.dateFormat.indexOf(' ')) + ' HH:mm');
		} else {
			domNodes.mapMode.to.textContent = to;
		}
	}
}
