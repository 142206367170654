import trkData from "./data.js";
import strings from "./strings.js";
import domNodes from "./domNodes.js";
import { handleAjaxFormSubmission, formShowErrorMessage } from "./ajax.js";
import { renderIDPCommandLog } from "./datatables.js";
import { wrapUrl } from "./wrapurl.js";
import state from "./state.js";
import { toggleLoadingMessage } from "./ajax.js";
import { requestAVLInformation } from "./avl.js";
import { handleWebServiceError } from "./ajax.js";
import { openActionDialog } from "./modal.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";

export function requestIDPCommandLog(assetId, scrollTo) {
	var data = {
		assetId: assetId,
	};

	var btn = document.getElementById("RefreshIDPCommandLog");
	handleAjaxFormSubmission("IDPGetExecuteCommandResponses", data, btn, null, null, null, function (result) {
		renderIDPCommandLog(result.Commands);
		if (scrollTo) {
			document.getElementById("IDPCommand").scrollIntoView(false);
		}
	});
}

export function idpSendCommand() {
	var isFormValid = $(trkData.validation.idpCommandLog.currentForm).valid();
	if (!isFormValid) {
		return;
	}

	var cmd = document.getElementById("IDPCommand");
	cmd.focus();
	var command = cmd.value;
	var type = document.getElementById("IDPCommandType").value;
	var isLua = false;
	var isJson = false;
	if (type === "lua") {
		isLua = true;
	} else if (type === "json") {
		isJson = true;
	}

	var status = document.getElementById("idp-query-status");
	var btn = this;

	var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
		var data = {
			assetId: id,
			assetIds: assetIds,
			groupIds: groupIds,
			command: command,
			isLua: isLua,
			isJson: isJson,
			gateway: gateway,
			gatewayTimeout: gatewayTimeout,
			gatewayRetries: gatewayRetries,
		};

		$.when(
			handleAjaxFormSubmission("IDPSendExecuteCommandRequest", data, btn, status, null, strings.MSG_COMMAND_ERROR)
		).done(function () {
			requestIDPCommandLog(id, true);
			var cmd = document.getElementById("IDPCommand");
			cmd.value = "";
			state.nextFocus = cmd;

			$(domNodes.modals.messageAction).modal("hide");
		});
	};

	openActionDialog(strings.SEND_COMMAND, strings.SEND_COMMAND, callback);
}

export function populateIDPCryptoInformation(crypto) {
	//crypto.IsServiceEnabled
	//crypto.IsEnforced
	//crypto.CipherSuite
	//crypto.IsKeyDefault
	var serviceInfo = document.getElementById("idp-crypto-enabled");
	var enforced = document.getElementById("idp-crypto-required");
	var disableButton = document.getElementById("IDPCryptoDisableRequirement");
	var enableButton = document.getElementById("IDPCryptoEnableRequirement");
	var cipher = document.getElementById("idp-crypto-cipher");
	var key = document.getElementById("idp-crypto-key");
	var rekeyButton = document.getElementById("IDPCryptoRekey");

	serviceInfo.textContent =
		crypto.IsServiceEnabled === true ? strings.YES : crypto.IsServiceEnabled === false ? strings.NO : strings.UNKNOWN;
	enforced.textContent = crypto.IsEnforced === true ? strings.YES : strings.NO;

	if (crypto.IsEnforced === true) {
		disableButton.classList.remove("disabled");
		disableButton.classList.remove("toggle-content");
		disableButton.disabled = false;

		enableButton.classList.add("disabled");
		enableButton.classList.add("toggle-content");
		enableButton.disabled = true;
	} else {
		disableButton.classList.add("disabled");
		disableButton.classList.add("toggle-content");
		disableButton.disabled = true;

		enableButton.classList.remove("disabled");
		enableButton.classList.remove("toggle-content");
		enableButton.disabled = false;
	}

	cipher.textContent = crypto.CipherSuite !== null ? crypto.CipherSuite : strings.UNKNOWN;
	key.textContent = crypto.IsKeyDefault === true ? strings.DEFAULT : strings.CUSTOM;

	if (crypto.IsServiceEnabled === false) {
		rekeyButton.classList.add("disabled");
		rekeyButton.disabled = true;
	} else {
		rekeyButton.classList.remove("disabled");
		rekeyButton.disabled = false;
	}
}

export function populateIDPUpdaterInformation(updater) {
	// terminal info
	var terminalInfo = updater.TerminalInfos;
	var terminalInfoData = [];
	for (var i = 0; i < terminalInfo.length; i++) {
		var item = terminalInfo[i];
		if (item.Info !== null) {
			terminalInfoData.push([
				item.Info.FirmwarePackage,
				item.Info.FirmwareVersion,
				item.Info.FreeFilespaceBytes,
				item.Info.FreeRamBytes,
				item.Info.TerminalKind,
				item.CreatedOn,
			]);
		}
	}
	$("#IDPUpdaterVersionHistory").dataTable({
		data: terminalInfoData,
		destroy: true,
		filter: false,
		info: false,
		jQueryUI: true,
		autoWidth: false,
		lengthChange: false,
		paging: false,
		pageLength: 3,
		deferRender: true,
		order: [[5, "desc"]],
		columnDefs: [
			{
				targets: "_all",
				render: $.fn.dataTable.render.text(),
			},
		],
		columns: [{}, {}, {}, {}, {}, {}],
		language: strings.DATATABLE,
	});

	// update states
	var stateInfo = updater.States;
	var terminalStateData = [];
	for (var i = 0; i < stateInfo.length; i++) {
		var item = stateInfo[i];
		terminalStateData.push([item.State, item.CreatedOn]);
	}
	$("#IDPUpdaterStatus").dataTable({
		data: terminalStateData,
		destroy: true,
		filter: false,
		info: false,
		jQueryUI: true,
		autoWidth: false,
		lengthChange: false,
		paging: true,
		pageLength: 5,
		deferRender: true,
		order: [[1, "desc"]],
		columnDefs: [
			{
				targets: "_all",
				render: $.fn.dataTable.render.text(),
			},
		],
		columns: [{}, {}],
		language: strings.DATATABLE,
	});
}

export function requestIDPUpdaterInformation(assetId) {
	toggleLoadingMessage(true, "idp-updater");
	var data = {
		assetId: assetId,
	};
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/IDPUpdaterGetInformation"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg !== null && msg.d !== null && msg.d.Success === true) {
				populateIDPUpdaterInformation(msg.d);
			}
			toggleLoadingMessage(false, "idp-updater");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_IDP_INFORMATION_ERROR);
			toggleLoadingMessage(false, "idp-updater");
		},
	});
}

export function requestIDPCryptoInformation(assetId) {
	toggleLoadingMessage(true, "idp-crypto");
	var data = {
		assetId: assetId,
	};
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/IDPCryptoGetInformation"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg !== null && msg.d !== null && msg.d.Success === true) {
				populateIDPCryptoInformation(msg.d);
			}
			toggleLoadingMessage(false, "idp-crypto");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_IDP_INFORMATION_ERROR);
			toggleLoadingMessage(false, "idp-crypto");
		},
	});
}

export function resetIDPCounters(assetId) {
	var data = {
		assetId: assetId,
	};
	var status = document.getElementById("idp-query-status");
	toggleLoadingMessage(true, "idp-counters-reset");
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/ResetAssetCounters"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			var result = msg.d;
			if (result) {
				if (result.Success == true) {
					requestAVLInformation(assetId);
				} else {
					// message failure, keep text field to allow retry
					formShowErrorMessage(status, strings.MSG_SKYWAVE_COUNTERS_RESET_ERROR);
					if (result.ErrorMessage != null && result.ErrorMessage != "") {
						formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
					}
				}
			}
			toggleLoadingMessage(false, "idp-counters-reset");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_SKYWAVE_COUNTERS_RESET_ERROR);
			toggleLoadingMessage(false, "idp-counters-reset");
		},
	});
}
