import strings from "./strings.js";
import domNodes from "./domNodes.js";
import { closeRuler } from "./ruler.js";
import state from "./state.js";
import { mapModes } from "./const.js";
import { initializeSharedViewInformationDialog, deselectSharedView } from "./shared-view.js";
import { svgPath } from "./dom-util.js";
import { playbackPrevPosition, playbackNextPosition } from "./playback.js";
import { closeButton } from "./modal-dialog-buttons.js";
import { findAssetById } from "./assets.js";
import { findTripById } from "./trips.js";
import { createPositionHistorySummary } from "./position-history.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import { el, svg, setChildren } from "redom"; // https://redom.js.org/

export function initDialogs() {
	// Update the strings for the generic dialog buttons, now that the strings
	// have been loaded.
	closeButton.text = strings.CANCEL;

	$j(domNodes.infoDialogs.eventDetails).dialog({
		autoOpen: false,
		resizable: true,
		modal: false,
		width: 400,
		create: function () {
			createDialogTitlebar(this);
			replaceDialogButtons(this, $(this).dialog("option", "buttons"));
		},
	});

	//domNodes.infoDialogs.placeInformation = document.getElementById('place-information-dialog');
	//$(domNodes.infoDialogs.placeInformation).dialog({
	//    autoOpen: false,
	//    resizable: false,
	//    modal: false,
	//    maxWidth: 375,
	//    create: function (event, ui) {
	//        createDialogTitlebar(this);
	//        replaceDialogButtons(this, $(this).dialog('option', 'buttons'));
	//        $(this).closest('.ui-dialog')
	//            .draggable('option', 'containment', '#base-content')
	//            .attr('id', 'dialog-place-information');
	//    }
	//});
	//domNodes.infoDialogs.geofenceInformation = document.getElementById('geofence-information-dialog');
	//$(domNodes.infoDialogs.geofenceInformation).dialog({
	//    autoOpen: false,
	//    resizable: false,
	//    modal: false,
	//    maxWidth: 375,
	//    create: function (event, ui) {
	//        createDialogTitlebar(this);
	//        replaceDialogButtons(this, $(this).dialog('option', 'buttons'));
	//        $(this).closest('.ui-dialog')
	//            .draggable('option', 'containment', '#base-content')
	//            .attr('id', 'dialog-geofence-information');
	//    }
	//});

	domNodes.infoDialogs.sharedViewInformation.manualCloseCallback = function () {
		deselectSharedView();
	};
	$(domNodes.infoDialogs.sharedViewInformation).dialog({
		autoOpen: false,
		resizable: false,
		modal: false,
		width: 350,
		maxWidth: 400,
		minHeight: 0,
		create: function (event, ui) {
			// undocumented call to allow html in title (for icon) - can't be shared for some reason
			$(domNodes.infoDialogs.sharedViewInformation).data("uiDialog")._title = function (title) {
				setChildren(title[0], this.options.title);
			};

			createDialogTitlebar(this);
			replaceDialogButtons(this, $(this).dialog("option", "buttons"));
			$(this)
				.closest(".ui-dialog")
				.draggable("option", "containment", "#base-content")
				.attr("id", "dialog-shared-view-information");
			initializeSharedViewInformationDialog();
		},
		close: function (event, ui) {
			//if (trkData.sharedView.current !== null && !state.openPanels.secondary) {
			//    deselectSharedView();
			//}
		},
		open: function (event, ui) {
			//if (this.getAttribute('data-has-collapse')) {
			//    if (this.getAttribute('data-collapsed') === 'true') {
			//    }
			//}
		},
	});

	$(domNodes.infoDialogs.positionHistory).dialog({
		autoOpen: false,
		resizable: false,
		modal: false,
		maxWidth: 375,
		create: function (event, ui) {
			// undocumented call to allow html in title (for icon) - can't be shared for some reason
			$(domNodes.infoDialogs.positionHistory).data("uiDialog")._title = function (title) {
				setChildren(title[0], this.options.title);
			};

			createDialogTitlebar(this);
			replaceDialogButtons(this, $(this).dialog("option", "buttons"));
			$(this)
				.closest(".ui-dialog")
				.draggable("option", "containment", "#base-content")
				.attr("id", "dialog-position-history");
		},
	});

	$(domNodes.infoDialogs.positionHistory).on("change", "select.time-picker", function (e) {
		var dialog = $(domNodes.infoDialogs.positionHistory);
		var asset = findAssetById(dialog.data("assetId"));
		var trip = findTripById(dialog.data("tripId"));
		var fromId = $("#positions-begin-header .time-picker").val();
		var toId = $("#positions-end-header .time-picker").val();
		createPositionHistorySummary(asset, trip, fromId, toId);
	});

	$(domNodes.infoDialogs.mapItemInformation).dialog({
		autoOpen: false,
		resizable: false,
		modal: false,
		//width: 375,
		maxWidth: 375,
		minHeight: 0,
		open: function (event, ui) {
			// start listening for left/right key presses
			if (state.activeMapMode !== mapModes.LIVE) {
				// todo: check if mapInformation is for an asset
				$(document)
					.off("keydown.map-item-information-dialog")
					.on("keydown.map-item-information-dialog", function (e) {
						switch (e.which) {
							case 37: // left
								playbackPrevPosition();
								e.preventDefault();
								e.stopPropagation();
								break;
							case 39: // right
								playbackNextPosition();
								e.preventDefault();
								e.stopPropagation();
								break;
						}
					});
			}
		},
		create: function (event, ui) {
			// undocumented call to allow html in title (for icon) - can't be shared for some reason
			$(domNodes.infoDialogs.mapItemInformation).data("uiDialog")._title = function (title) {
				setChildren(title[0], this.options.title);
			};

			createDialogTitlebar(this);
			replaceDialogButtons(this, $(this).dialog("option", "buttons"));
			$(this)
				.closest(".ui-dialog")
				.draggable("option", "containment", "#base-content")
				.attr("id", "dialog-map-item-information"); // give it an id for styling
			$(domNodes.infoDialogs.mapItemInformation).bsTooltip({ selector: ".estimated", placement: "top" });
		},
	});

	$j(domNodes.infoDialogs.ruler).dialog({
		autoOpen: false,
		modal: false,
		maxWidth: 350,
		resizable: false,
		close: function () {
			closeRuler();
		},
		create: function () {
			createDialogTitlebar(this);
			replaceDialogButtons(this, $(this).dialog("option", "buttons"));
		},
	});

	$j(domNodes.infoDialogs.garminSubmission).dialog({
		autoOpen: false,
		modal: false,
		width: 400,
		close: function () {},
		create: function () {
			createDialogTitlebar(this);
			replaceDialogButtons(this, $(this).dialog("option", "buttons"));
		},
	});
}

export function moveOrOpenDialogRelativeTo($dialog, referencePoint, relativePlacement) {
	if (!$dialog.dialog("isOpen")) {
		if (window.screen.width > 768) {
			$dialog.dialog("option", "position", {
				my: "left center",
				at: relativePlacement,
				of: referencePoint,
				collision: "flipfit",
				within: domNodes.map,
				using: function (param1, param2) {
					$(this).css(param1);
				},
			});
		}
		$dialog.dialog("open"); // dialog must be opened away from the marker so it can be moved without triggering
	} else {
		// dialog was already open but may have been resized due to content changes
		var parent = $dialog.closest(".ui-dialog");
		parent.position({
			my: "left center",
			at: relativePlacement,
			of: referencePoint,
			collision: "flipfit",
			within: domNodes.map,
			using: function (param1, param2) {
				// if the dialog is currently in the same horizontal position as before
				// then we will assume the user has not manually moved it and it can be
				// repositioned programmatically to fit centered vertically
				if ($dialog.closest(".ui-dialog").position().left == param1.left) {
					// just move the dialog vertically
					$(this).css(param1);
				}
			},
		});
	}
	$dialog.dialog("moveToTop");
}

function replaceDialogButtons(dialog, buttons) {
	if (buttons === undefined || buttons.length === 0) {
		return;
	}
	var ui = dialog.parentNode;
	var pane = ui.querySelector(".ui-dialog-buttonset");
	var uiButtons = pane.querySelectorAll("button");
	_.each(uiButtons, function (uiButton, index, list) {
		var buttonType = buttons[index].buttonType === undefined ? "primary" : buttons[index].buttonType;
		var buttonIcon =
			buttons[index].svgIcon === undefined
				? null
				: svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#" + buttons[index].svgIcon } }));
		uiButton.className = "btn btn-" + buttonType;
		setChildren(uiButton, _.compact(buttonIcon, el("span", buttons[index].text)));
	});
}

function createDialogTitlebar(dialog) {
	var panel = $(dialog).parent();
	var title = $("div.ui-dialog-titlebar", panel).addClass("w-100");
	$(".ui-dialog-titlebar-close", title).remove();

	var bar = $('<div class="dialog-titlebar">');
	// svg icon for those that support it
	var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
	var use = document.createElementNS("http://www.w3.org/2000/svg", "use");
	svg.appendChild(use);
	use.setAttributeNS("http://www.w3.org/1999/xlink", "href", svgPath("info-circle"));
	title[0].insertBefore(svg, title[0].firstChild);
	//title[0].appendChild(svg);

	title.appendTo(bar); // move title to new flex-row for button support
	var $quickActions = $(".dialog-quick-actions", dialog);
	if ($quickActions.length > 0) {
		//// quick actions select list should be pre-defined somewhere?
		//var action = $('<div class="dropdown" id="' + dialog.id + '-actions-dropdown"><button id="' + dialog.id + '-actions" class="panel-options panel-button" value="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><svg><use xlink:href="/content/svg/tracking.svg?v=15#ellipsis-v"></use></svg></button></div>')
		//action.appendTo(bar);
		$quickActions.appendTo(bar);
	}
	if (dialog.getAttribute("data-has-collapse") !== null) {
		var collapse = document.createElement("button");
		collapse.setAttribute("data-for-dialog", dialog.id);
		collapse.className = "btn item-collapse panel-options";
		var collapseSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		var collapseUse = document.createElementNS("http://www.w3.org/2000/svg", "use");
		collapseSvg.appendChild(collapseUse);
		collapseUse.setAttributeNS("http://www.w3.org/1999/xlink", "href", svgPath("angle-up"));
		collapse.appendChild(collapseSvg);
		bar[0].appendChild(collapse);
	}

	var close = document.createElement("button");
	close.setAttribute("data-for-dialog", dialog.id);
	close.className = "btn item-close";
	var closeSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
	var closeUse = document.createElementNS("http://www.w3.org/2000/svg", "use");
	closeSvg.appendChild(closeUse);
	closeUse.setAttributeNS("http://www.w3.org/1999/xlink", "href", svgPath("times"));
	close.appendChild(closeSvg);
	bar[0].appendChild(close);

	//var close = $('<button type="button" data-for-dialog="' + dialog.id + '" class="btn item-close"><svg><use xlink:href="/content/svg/tracking.svg?v=15#times"></use></svg></button>');
	//close.appendTo(bar);
	bar.prependTo(panel);
}
