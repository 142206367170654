// Sorts DOM structures.
// Applies to list of assets, places or fences.

import user, { saveDisplayPreferences } from "./user.js";
import { language, sortModes, sortDirections } from "./const.js";
import domNodes from "./domNodes.js";
import trkData from "./data.js";
import { findDeviceById } from "./devices.js";
import { findSharedViewById } from "./shared-view.js";
import { findGroupById } from "./asset-group.js";
import { findAssetById } from "./assets.js";
import { findFenceById } from "./fence.js";
import { findPlaceById } from "./place.js";

import _ from "lodash";

export function sortByName(a, b) {
	// if .localeCompare is not available?
	return a.Name.localeCompare(b.Name, language);
}

function sortByColorAndName(a, b) {
	// color
	if (a.ColorSorted !== undefined && b.ColorSorted !== undefined) {
		if (a.ColorSorted.h < b.ColorSorted.h) {
			return -1;
		} else if (a.ColorSorted.h > b.ColorSorted.h) {
			return 1;
		} else if (a.ColorSorted.lum < b.ColorSorted.lum) {
			return -1;
		} else if (a.ColorSorted.lum > b.ColorSorted.lum) {
			return 1;
		} else if (a.ColorSorted.l < b.ColorSorted.l) {
			return -1;
		} else if (a.ColorSorted.l > b.ColorSorted.l) {
			return 1;
		}
	}

	// name
	return sortByName(a, b);
}

function sortBySortOrderAndName(items, sortOrders) {
	return items.sort(function (a, b) {
		var aIndex = sortOrders.indexOf(a.Id);
		if (aIndex === -1) {
			aIndex = 99999;
		}
		var bIndex = sortOrders.indexOf(b.Id);
		if (bIndex === -1) {
			bIndex = 99999;
		}
		if (aIndex < bIndex) {
			return -1;
		} else if (aIndex > bIndex) {
			return 1;
		}

		return sortByName(a, b);
	});
}

function sortItemsWithDirection(items, sortFunction, sortDirection) {
	if (sortDirection === undefined || sortDirection === sortDirections.ASC) {
		return items.sort(function (a, b) {
			return sortFunction(a, b);
		});
	} else {
		return items.sort(function (a, b) {
			return sortFunction(b, a);
		});
	}
}

function sortByManufacturer(a, b) {
	var aDevice = findDeviceById(a.DeviceId);
	var bDevice = findDeviceById(b.DeviceId);
	var manufacturerSort = aDevice.Manufacturer.localeCompare(bDevice.Manufacturer, language);
	if (manufacturerSort === 0) {
		return sortByName(a, b);
	}
	return manufacturerSort;
}

function sortByDeviceType(a, b) {
	var aDevice = findDeviceById(a.DeviceId);
	var bDevice = findDeviceById(b.DeviceId);
	var deviceSort = aDevice.Name.localeCompare(bDevice.Name, language);
	if (deviceSort === 0) {
		return sortByName(a, b);
	}
	return deviceSort;
}

export function sortItemsByMode(modeType, items, parentGroupId, itemType) {
	var mode = user.displayPreferences.sortMode[modeType];
	var direction = user.displayPreferences.sortDirection[modeType];
	switch (mode) {
		case sortModes.COLOR:
			return sortItemsWithDirection(items, sortByColorAndName, direction);
		case sortModes.CUSTOM:
			var itemSortOrders = user.displayPreferences.customSort[parentGroupId + "-" + itemType];
			if (itemSortOrders !== undefined) {
				return sortBySortOrderAndName(items, itemSortOrders);
			} else {
				return items.sort(sortByName);
			}
			break;
		case sortModes.MANUFACTURER:
			if (itemType === "assets") {
				return sortItemsWithDirection(items, sortByManufacturer, direction);
			} else {
				return sortItemsWithDirection(items, sortByName, direction);
			}
			break;
		case sortModes.DEVICE_TYPE:
			if (itemType === "assets") {
				return sortItemsWithDirection(items, sortByDeviceType, direction);
			} else {
				return sortItemsWithDirection(items, sortByName, direction);
			}
			break;
		case sortModes.ALPHABETICAL:
		default:
			return sortItemsWithDirection(items, sortByName, direction);
	}
}

export function saveItemCustomSortOrder() {
	var customSortOrder = {};

	var groupIds = _.map(trkData.groups, "Id");

	var panelTypes = ["assets", "places", "fences"];
	_.each(panelTypes, function (panelType) {
		customSortOrder[panelType + "-root-groups"] = [];
		var rootNode = document.getElementById(panelType + "-all");
		for (var i = 0; i < rootNode.childNodes.length; i++) {
			var groupId = rootNode.childNodes[i].id.substring(6);
			customSortOrder[panelType + "-root-groups"].push(groupId);
		}
		groupIds.push("all-" + panelType);
	});

	_.each(groupIds, function (groupId) {
		var groupContents = domNodes.groupContents[groupId];
		customSortOrder[groupId + "-groups"] = [];
		customSortOrder[groupId + "-assets"] = [];
		customSortOrder[groupId + "-places"] = [];
		customSortOrder[groupId + "-fences"] = [];
		var groupList = _.find(groupContents.childNodes, function (node) {
			return node.classList !== undefined && node.classList.contains("group-list-list");
		});
		if (groupList === undefined) {
			return;
		}
		var groupNodes = _.filter(groupList.childNodes, function (node) {
			return node.classList !== undefined && node.classList.contains("group");
		});
		var assetNodes = _.filter(groupList.childNodes, function (node) {
			return node.classList !== undefined && node.classList.contains("assets-item");
		});
		var placeNodes = _.filter(groupList.childNodes, function (node) {
			return node.classList !== undefined && node.classList.contains("places-item");
		});
		var fenceNodes = _.filter(groupList.childNodes, function (node) {
			return node.classList !== undefined && node.classList.contains("fences-item");
		});

		_.each(groupNodes, function (node, index) {
			var id = node.getAttribute("data-group-id");
			customSortOrder[groupId + "-groups"].splice(index, 0, id);
		});

		_.each(assetNodes, function (node, index) {
			var id = parseInt(node.getAttribute("data-asset-id"));
			customSortOrder[groupId + "-assets"].splice(index, 0, id);
		});

		_.each(placeNodes, function (node, index) {
			var id = parseInt(node.getAttribute("data-place-id"));
			customSortOrder[groupId + "-places"].splice(index, 0, id);
		});

		_.each(fenceNodes, function (node, index) {
			var id = node.getAttribute("data-fence-id");
			customSortOrder[groupId + "-fences"].splice(index, 0, id);
		});
	});

	user.displayPreferences.customSort = customSortOrder;
	saveDisplayPreferences();
}

export function toggleItemSorting(type, isEnabled) {
	// enable/disable custom item sorting for the passed item type
	// todo: lookup the current state of sorting enabled
	// and do nothing if it has not changed

	// groups are currently asset specific
	if (type === "assets") {
		_.each(domNodes.groups, function (itemNode) {
			var dragNode = itemNode.querySelector(".group-drag");
			if (isEnabled) {
				dragNode.classList.remove("disabled");
				dragNode.parentNode.classList.add("drag-enabled");
			} else {
				dragNode.classList.add("disabled");
				dragNode.parentNode.classList.remove("drag-enabled");
			}
		});
	}
	_.each(domNodes[type], function (typeNodes) {
		// assets is an array since it can be in multiple groups, others are not, for now
		if (!_.isArray(typeNodes)) {
			typeNodes = [typeNodes];
		}
		_.each(typeNodes, function (itemNode) {
			var dragNode = itemNode.querySelector(".item-drag");
			if (isEnabled) {
				dragNode.classList.remove("disabled");
				dragNode.parentNode.classList.add("drag-enabled");
			} else {
				dragNode.classList.add("disabled");
				dragNode.parentNode.classList.remove("drag-enabled");
			}
		});
	});
}

export function sortModeUpdated(typeUpdated) {
	var types = ["assets", "places", "fences"];
	if (typeUpdated !== undefined) {
		types = [typeUpdated];
	}

	_.each(types, function (itemType) {
		var list = document.getElementById(itemType + "-filter-options-list");
		var listOptions = list.querySelectorAll('.dropdown-item[data-option-type="sorting"]');
		_.each(listOptions, function (option) {
			var sortMode = parseInt(option.getAttribute("data-sort"));
			if (sortMode !== user.displayPreferences.sortMode[itemType]) {
				option.classList.remove("active");
				option.classList.remove("sort-asc");
				option.classList.remove("sort-desc");
			} else {
				option.classList.add("active");
				option.classList.remove("sort-asc");
				option.classList.remove("sort-desc");
				if (user.displayPreferences.sortDirection[itemType] === sortDirections.ASC) {
					option.classList.add("sort-asc");
				} else {
					option.classList.add("sort-desc");
				}
			}
		});

		//var isSortingEnabled = user.displayPreferences.sortMode.assets === sortModes.ALPHABETICAL
		//    || user.displayPreferences.sortMode.assets === sortModes.CUSTOM;
		var isSortingEnabled = user.displayPreferences.sortMode[itemType] === sortModes.CUSTOM;
		toggleItemSorting(itemType, isSortingEnabled);
	});
}

export function changeItemSort(type, newMode) {
	var currentMode = user.displayPreferences.sortMode[type];
	var currentDirection = user.displayPreferences.sortDirection[type];
	if (newMode === currentMode) {
		// toggle the sort direction, if possible (currently everything except custom)
		if (currentMode === sortModes.CUSTOM) {
			return;
		}
		if (currentDirection === sortDirections.ASC) {
			user.displayPreferences.sortDirection[type] = sortDirections.DESC;
		} else {
			user.displayPreferences.sortDirection[type] = sortDirections.ASC;
		}
	} else {
		// always start ascending in new mode
		user.displayPreferences.sortDirection[type] = sortDirections.ASC;
	}
	user.displayPreferences.sortMode[type] = newMode;
	sortModeUpdated(type);

	resortItemGroup("all-" + type);

	if (type === "assets") {
		// sort items in groups - currently asset specific
		_.each(trkData.groups, function (group) {
			resortItemGroup(group.Id);
		});

		// sort root groups - currently asset specific
		var rootGroups = _.filter(trkData.groups, function (group) {
			return group.ParentGroupId === null || group.ParentGroupId === undefined;
		});
		var sortedRootGroups = sortItemsByMode("assets", rootGroups, "assets-root", "groups");
		_.each(sortedRootGroups, function (group) {
			// root group should always be attached to parent node for this to work
			domNodes.groups[group.Id].parentNode.appendChild(domNodes.groups[group.Id]);
		});
	}
	saveDisplayPreferences();
}

export function resortItemGroup(groupId) {
	var groupContents = domNodes.groupContents[groupId];
	if (groupContents === undefined) {
		return;
	}

	var groupList = _.find(groupContents.childNodes, function (node) {
		return node.classList !== undefined && node.classList.contains("group-list-list");
	});
	var groupNodes = {};
	var assetNodes = {};
	var fenceNodes = {};
	var placeNodes = {};
	var sharedViewNodes = {};
	var groups = [];
	var assets = [];
	var fences = [];
	var places = [];
	var sharedViews = [];
	if (groupList !== undefined) {
		_.each(groupList.childNodes, function (node) {
			if (node.classList !== undefined && node.classList.contains("group")) {
				// subgroup
				var id = node.getAttribute("data-group-id");
				groupNodes[id] = node;
				var group = findGroupById(id);
				if (group !== null) {
					groups.push(group);
				}
			} else if (node.classList !== undefined && node.classList.contains("assets-item")) {
				// asset
				var id = parseInt(node.getAttribute("data-asset-id"));
				assetNodes[id] = node;
				var asset = findAssetById(id);
				if (asset !== null) {
					assets.push(asset);
				}
			} else if (node.classList !== undefined && node.classList.contains("fences-item")) {
				var id = node.getAttribute("data-fence-id");
				fenceNodes[id] = node;
				var fence = findFenceById(id);
				if (fence !== null) {
					fences.push(fence);
				}
			} else if (node.classList !== undefined && node.classList.contains("places-item")) {
				var id = parseInt(node.getAttribute("data-place-id"));
				placeNodes[id] = node;
				var place = findPlaceById(id);
				if (place !== null) {
					places.push(place);
				}
			} else if (node.classList !== undefined && node.classList.contains("shared-views-item")) {
				var id = parseInt(node.getAttribute("data-shared-view-id"));
				sharedViewNodes[id] = node;
				var sharedView = findSharedViewById(id);
				if (sharedView !== null) {
					sharedViews.push(sharedView);
				}
			}
		});
	}

	// the reordering could be improved upon
	// by only moving nodes whose order change
	var groupsSorted = sortItemsByMode("assets", groups, groupId, "groups");
	_.each(groupsSorted, function (group) {
		// move node to the bottom
		groupList.appendChild(groupNodes[group.Id]);
	});

	var assetsSorted = sortItemsByMode("assets", assets, groupId, "assets");
	_.each(assetsSorted, function (asset) {
		groupList.appendChild(assetNodes[asset.Id]);
	});

	var fencesSorted = sortItemsByMode("fences", fences, groupId, "fences");
	_.each(fencesSorted, function (fence) {
		groupList.appendChild(fenceNodes[fence.Id]);
	});

	var placesSorted = sortItemsByMode("places", places, groupId, "places");
	_.each(placesSorted, function (place) {
		groupList.appendChild(placeNodes[place.Id]);
	});

	var sharedViewsSorted = sortItemsByMode("shared-views", sharedViews, groupId, "shared-views");
	_.each(sharedViewsSorted, function (sharedView) {
		groupList.appendChild(sharedViewNodes[sharedView.Id]);
	});
}

export function sortItemGroups(groups) {
	// reset groups
	var groupsById = _.keyBy(groups, "Id");
	for (var i = 0; i < groups.length; i++) {
		groups[i].Groups = [];
	}

	var sortedGroups = groups.sort(function (a, b) {
		return a.Name.localeCompare(b.Name);
	});
	var rootGroups = { Groups: [] };
	for (var i = 0; i < sortedGroups.length; i++) {
		var group = sortedGroups[i];
		if (group.ParentGroupId == null || groupsById[group.ParentGroupId] === undefined) {
			sortItemGroup(groups, group, rootGroups);
		}
	}
	return rootGroups.Groups;
}

function sortItemGroup(groups, group, parent) {
	parent.Groups.push(group);

	for (var i = 0; i < groups.length; i++) {
		var otherGroup = groups[i];
		if (otherGroup.ParentGroupId == group.Id) {
			sortItemGroup(groups, otherGroup, group);
		}
	}
}

export function sortAssetGroups() {
	// reset groups
	for (var i = 0; i < trkData.groups.length; i++) {
		trkData.groups[i].Groups = [];
	}

	var sortedGroups = trkData.groups.sort(function (a, b) {
		return a.Name.localeCompare(b.Name);
	});
	var rootGroups = { Groups: [] };
	for (var i = 0; i < sortedGroups.length; i++) {
		var group = sortedGroups[i];
		if (group.ParentGroupId == null || trkData.groupsById[group.ParentGroupId] === undefined) {
			sortAssetGroup(group, rootGroups);
		}
	}
	return rootGroups.Groups;
}

function sortAssetGroup(group, parent) {
	parent.Groups.push(group);

	for (var i = 0; i < trkData.groups.length; i++) {
		var otherGroup = trkData.groups[i];
		if (otherGroup.ParentGroupId == group.Id) sortAssetGroup(otherGroup, group);
	}
}
