import strings from "./strings.js";
import trkData from "./data.js";
import user from "./user.js";
import { fuelText } from "./preferences.js";
import { devices } from "./devices.js";
import options from "./options.js";
import { findDeviceById } from "./devices.js";
import { hash1, hash2, validateIMEI } from "./device-imei.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";

import _ from "lodash";
import $ from "jquery";
import $j from "jquery";
import { el, text, setChildren } from "redom"; // https://redom.js.org/

import "inputmask/dist/jquery.inputmask.js";


export function deviceDialogChange(elem, asset) {
	var label = $j("#txtEditAssetUniqueIdLabel");
	var secondaryLabel = $j("#txtEditAssetSecondaryUniqueIdLabel");
	var input = $j("#txtEditAssetUniqueId");
	var secondaryInput = $j("#txtEditAssetSecondaryUniqueId");
	var imeiValid = $j("#txtEditAssetIMEIVerificationCode");
	var code = $j("#PortalRegistrationCode");
	var cannedMessageGroup = $j("#edit-asset-canned-message-group");
	var driverStatusTemplate = $j("#edit-asset-driver-status-template");
	var driverDeviceType = $j("#edit-asset-driver-device-type");
	var quickMessageTemplate = $j("#edit-asset-quick-message-template");
	var garminForms = $j("#edit-asset-garmin-forms");
	var addressBookGroup = $j("#edit-asset-address-book-group");
	var gatewayAccount = $j("#edit-asset-gateway-account");
	var secondaryGatewayAccount = $j("#edit-asset-secondary-gateway-account");
	var pollingInterval = $j("#edit-asset-polling-interval");
	var idpActivate = $j("#edit-asset-idp-activation");
	var bivyStatus = document.getElementById("edit-asset-bivy-status");
	var authCode = document.getElementById('edit-asset-spot');
	var authCodeInput = document.getElementById('txtEditAssetSpotAuthCode');
	var gatewayBehavior = $j("#edit-asset-gateway-behavior");
	var spotCustom = $j("#edit-asset-spot-custom");
	var assetConfigurationContainer = document.getElementById("edit-asset-configuration");
	var assetSoftwarePackageContainer = document.getElementById("edit-asset-software-package");
	var gatewayAccountSetup = $j("#gateway-account-setup");
	var secondaryGatewayAccountSetup = $j("#secondary-gateway-account-setup");
	var clearAssetHistory = $j("#edit-asset-clear-history");
	var encryptionKeys = $j("#AssetEncryptionKeys");
	var password = $j("#AssetPassword");
	var secondaryUniqueId = $j("#edit-asset-secondary-id");
	var secondaryGatewayAccountId = $("#ddlEditAssetSecondaryGatewayAccount");
	var inmarsatC = $j("#edit-asset-inmarsatc");
	var inmarsatCDetails = $j("#inmarsatc-gateway-details");
	var inmatsatCActivation = $j("#activate-inmarsatc");
	var microUseCorrectedCourse = $j("#edit-asset-micro-course");
	var suppressEmergency = $j("#edit-asset-suppress-emergency");
	//var synchronizeGeofences = $j('#edit-asset-synchronize-geofences');

	encryptionKeys.hide();
	password.hide();
	cannedMessageGroup.hide();
	driverStatusTemplate.hide();
	driverDeviceType.hide();
	quickMessageTemplate.hide();
	garminForms.hide();
	addressBookGroup.hide();
	gatewayAccount.hide();
	secondaryGatewayAccount.hide();
	pollingInterval.hide();
	spotCustom.hide();
	gatewayAccountSetup.hide();
	secondaryGatewayAccountSetup.hide();
	idpActivate.hide();
	bivyStatus.classList.remove("is-visible");
	authCode && authCode.classList.remove('is-visible');
	gatewayBehavior.hide();
	microUseCorrectedCourse.hide();
	suppressEmergency.hide();
	clearAssetHistory.hide();
	assetConfigurationContainer.classList.remove("is-visible");
	assetSoftwarePackageContainer.classList.remove("is-visible");
	secondaryUniqueId.hide();
	inmarsatC.hide();
	inmarsatCDetails.hide();
	inmatsatCActivation.hide();
	//synchronizeGeofences.hide();
	//synchronizeGeofences.find('button').prop('disabled', true);
	clearAssetHistory.find("button").prop("disabled", true).hide();
	input.inputmask("remove");
	secondaryInput.inputmask("remove");
	input.rules("remove", "regex");
	secondaryInput.rules("remove", "regex");
	secondaryInput.removeClass("required");
	secondaryGatewayAccountId.removeClass("required");
	var device = findDeviceById($j(elem).val());
	if (device != null) {
		if (device.UniqueIdMask != null) {
			input.inputmask({ mask: device.UniqueIdMask });
		}
		if (device.UniqueIdRegex != null) {
			input.rules("add", { regex: device.UniqueIdRegex });
		}
		if (device.SecondaryUniqueIdMask != null) {
			secondaryInput.inputmask({ mask: device.SecondaryUniqueIdMask });
		}
		if (device.SecondaryUniqueIdRegex != null) {
			secondaryInput.rules("add", { regex: device.SecondaryUniqueIdRegex });
		}
		if (device.IsSecondaryIdRequired) {
			secondaryInput.addClass("required");
			secondaryGatewayAccountId.addClass("required");
		}
		if (device.SupportsCannedMessages) {
			cannedMessageGroup.show();
		}
		if (device.SupportsAddressBooks) {
			addressBookGroup.show();
		}
		if (device.HasSecondaryId) {
			secondaryUniqueId.show();
		}

		if (device.GatewayAccountTypes !== null) {
			if (user.isAdmin) {
				gatewayAccountSetup.show();
			}
			gatewayAccount.show();
			var accountId = asset !== null && asset.GatewayAccountId !== null ? asset.GatewayAccountId : null;
			populateGatewayAccounts(gatewayAccount, $("#ddlEditAssetGatewayAccount"), device.GatewayAccountTypes, accountId);
		}

		if (device.SecondaryGatewayAccountTypes !== null) {
			if (user.isAdmin) {
				secondaryGatewayAccountSetup.show();
			}
			secondaryGatewayAccount.show();
			var accountId =
				asset !== null && asset.SecondaryGatewayAccountId !== null ? asset.SecondaryGatewayAccountId : null;
			populateGatewayAccounts(
				secondaryGatewayAccount,
				secondaryGatewayAccountId,
				device.SecondaryGatewayAccountTypes,
				accountId
			);
		}

		if (device.RequiresPollingInterval) {
			pollingInterval.show();
		}
		$j("#edit-asset-current-status .config span").hide();
		if (device.SupportedConfigurations != null) {
			assetConfigurationContainer.classList.add("is-visible");
			// hide non-supported configurations
			var configOptions = assetConfigurationContainer.querySelectorAll("option");
			configOptions.forEach(function (elem) {
				if (_.indexOf(device.SupportedConfigurations, elem.value) !== -1) {
					elem.classList.add("is-visible");
				} else {
					elem.classList.remove("is-visible");
				}
			});
		}

		if (asset != null && asset.Configuration != null && asset.Configuration != "") {
			$j("#edit-asset-current-status .config-" + asset.Configuration + " span").show();
		}

		driverStatusTemplate
			.show()
			.find("button")
			.prop("disabled", asset == null)
			.prop("disabled", asset == null);

		if (device.SupportsQuickMessages) {
			quickMessageTemplate
				.show()
				.find("button")
				.prop("disabled", asset == null)
				.prop("disabled", asset == null);
		}
		if (device.SupportsGarminForms) {
			garminForms.show();
		}

		if ($j.inArray(device.Id, devices.INMARSAT_C) !== -1) {
			inmarsatC.show();
			if (asset == null) {
				inmatsatCActivation.show();
			} else if (asset.InmarsatC != null && (asset.InmarsatC.DNID == null || asset.InmarsatC.MemberNumber == null)) {
				inmatsatCActivation.show();
			} else {
				inmarsatCDetails.show();
			}
		}

		if ($j.inArray(device.Id, devices.SPOT) != -1) {
			spotCustom.show();
		}

		if (
			$j.inArray(device.Id, devices.NAL) != -1 ||
			$j.inArray(device.Id, devices.GSAT_MICROS) != -1 ||
			$j.inArray(device.Id, devices.QUECLINK) != -1 ||
			device.Id == devices.IRIDIUM_EDGE ||
			$j.inArray(device.Id, devices.HUGHES) != -1
		) {
			password.show();
		}

		if ($j.inArray(device.Id, devices.NAL) != -1 || $j.inArray(device.Id, devices.GSAT_MICROS) != -1) {
			encryptionKeys.show();
			$j("#AssetEncryptionReceive,#AssetEncryptionTransmit").show();
		}

		if ($j.inArray(device.Id, devices.GSAT_MICROS) != -1) {
			microUseCorrectedCourse.show();
			suppressEmergency.show();
			$j("#AssetEncryptionReceive").hide();
			$j("#txtEditAssetTransmitKey").rules("add", {
				regex: "^([0-9a-fA-F]){64}|(placeholder)$",
				messages: { regex: "Encryption key must be 64 character hex" },
			});
		} else {
			$j("#txtEditAssetTransmitKey").rules("remove", "regex");
			//$j('#txtEditAssetTransmitKey').rules('remove', 'maxlength');
		}

		if ($j.inArray(device.Id, devices.SKYWAVE_IDP_CELL_ONLY) == -1) {
			if (
				$j.inArray(device.Id, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
				$j.inArray(device.Id, devices.SKYWAVE_IDP) != -1 ||
				$j.inArray(device.Id, devices.QUAKE_AIC) != -1 ||
				$j.inArray(device.Id, devices.NAL_GSM) != -1 ||
				_.indexOf(devices.GSATMICRO_GSM, device.Id) !== -1
			) {
				var minutes = 20;
				gatewayBehavior.show();
				$j("#EditAssetIDPGatewayBehaviorTimeout").val(minutes);
				if (
					$j.inArray(device.Id, devices.SKYWAVE_IDP_DUAL_MODE) == -1 &&
					$j.inArray(device.Id, devices.NAL_GSM) == -1 &&
					_.indexOf(devices.GSATMICRO_GSM, device.Id) === -1 &&
					$j.inArray(device.Id, devices.QUAKE_AIC) == -1
				) {
					// disable dual-mode options
					$j("input[name=EditAssetIDPGatewayBehavior][value!=1]", gatewayBehavior)
						.prop("disabled", true)
						.prop("checked", false)
						.next("label")
						.children("input:text")
						.prop("disabled", true);
					$j("input[name=EditAssetIDPGatewayBehavior][value=1]", gatewayBehavior).prop("checked", true);
				} else {
					$j("input[name=EditAssetIDPGatewayBehavior]", gatewayBehavior)
						.prop("disabled", false)
						.prop("checked", false)
						.next("label")
						.children("input:text")
						.prop("disabled", false);

					if (asset != null) {
						$j(
							'input[name=EditAssetIDPGatewayBehavior][value="' + asset.DefaultIDPGateway + '"]',
							gatewayBehavior
						).prop("checked", true);
						if (asset.DefaultIDPGatewayTimeout != null) {
							$j("#EditAssetIDPGatewayBehaviorTimeout").val(asset.DefaultIDPGatewayTimeout);
						}
						if (asset.DefaultIDPGatewayRetries != null) {
							$j("#EditAssetIDPGatewayBehaviorRetries,#EditAssetIDPGatewayBehaviorCellRetries").val(
								asset.DefaultIDPGatewayRetries
							);
						}
					} else {
						$j("input[name=EditAssetIDPGatewayBehavior][value=3]", gatewayBehavior).prop("checked", true);
					}
				}
			}
		}

		if (
			$j.inArray(device.Id, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
			$j.inArray(device.Id, devices.SKYWAVE_IDP) != -1
		) {
			// avl devices have access to driver device type (garmin/ibutton)
			driverDeviceType.show();
			// ibutton only available to avl 3.0
			if (
				asset != null &&
				asset.Configuration != "AVL3" &&
				asset.Configuration != "ATS" &&
				asset.Configuration != "GSEAgentsV1" &&
				asset.Configuration != "GSEAgentsV2"
			) {
				$j(".ibutton", driverDeviceType).hide();
			} else {
				$j(".ibutton", driverDeviceType).show();
			}

			if ($j.inArray(device.Id, devices.SKYWAVE_IDP_CELL_ONLY) == -1) {
				if (user.isAdmin) {
					if (asset != null) idpActivate.show();
				}
			}
		}

		// SPOT auth code verification
		if (_.indexOf(devices.SPOT, device.Id) !== -1) {
			authCode && authCode.classList.add('is-visible');
			// only required if adding asset or changing ESN
			if (asset !== null) {
				authCodeInput.classList.remove('required');
			} else {
				authCodeInput.classList.add('required');
			}
		}

		// GSatSolar default configuration
		if ((asset === null) && _.indexOf(devices.GSATSOLARS, device.Id) !== -1) {
			document.getElementById("ddlEditAssetConfiguration").value = "GSatSolarEnhanced";
		}

		if (asset !== null && device.Id === devices.BIVY_STICK) {
			document.getElementById("EditAssetBivyActive").checked = asset.Bivy !== null && asset.Bivy.Active === true;
			document.getElementById("EditAssetBivyRegistered").checked =
				asset.Bivy !== null && asset.Bivy.Registered === true;
			document.getElementById("EditAssetBivyMessaging").checked = asset.Bivy !== null && asset.Bivy.Messages === true;
			document.getElementById("EditAssetBivyPositions").checked = asset.Bivy !== null && asset.Bivy.Location === true;
			bivyStatus.classList.add("is-visible");
		}

		// load packages for device
		var softwarePackageList = document.getElementById("ddlEditAssetSoftwarePackage");
		// clear prior options
		while (softwarePackageList.firstChild) {
			softwarePackageList.removeChild(softwarePackageList.firstChild);
		}

		if (device.SoftwarePackageType !== null) {
			var hasDefault = false;
			var callback = function () {
				const options = [];
				var defaultOption = el("option", { value: "" }, strings.GROUP_NONE);

				_.each(trkData.softwarePackages, function (devicePackage) {
					var isForDevice = _.find(devicePackage.Devices, function (deviceAssignment) {
						return deviceAssignment.Id === device.Id;
					});
					if (isForDevice === undefined) {
						return;
					}
					// add each devicePackage as an <option>, selecting if its IsDefault
					var option = el("option", { value: devicePackage.Id }, devicePackage.Tag + " - " + devicePackage.Version);
					if (asset == null) {
						if (isForDevice.IsDefault === true) {
							hasDefault = true;
							option.selected = true;
							option.setAttribute("selected", "selected");
						}
					} else {
						if (asset.SoftwarePackageId !== null && devicePackage.Id === asset.SoftwarePackageId) {
							hasDefault = true;
							option.selected = true;
							option.setAttribute("selected", "selected");
						}
					}
					options.push(option);
				});
				if (!hasDefault) {
					defaultOption.selected = true;
					defaultOption.setAttribute("selected", "selected");
				}
				options.unshift(defaultOption);

				assetSoftwarePackageContainer.classList.add("is-visible");
				setChildren(softwarePackageList, options);
			};
			if (trkData.softwarePackages === null) {
				loadSoftwarePackages(callback);
			} else {
				callback();
			}
		}
	}

	if (asset != null) {
		if (user.isAdmin || user.canEditAssets) {
			// only show the clearAssetHistory for existing assets
			clearAssetHistory.show();
			clearAssetHistory.find("button").prop("disabled", false).show();
		}
	}

	$j("#edit-asset-mobile-instructions").hide();
	if (device != null && $j.inArray(device.Id, devices.MOBILE) != -1) {
		$j("#edit-asset-mobile").show();
		$j("#edit-asset-uniqueid").hide();
		// require mobile username and password
		$j("#txtEditAssetMobileUnme,#txtEditAssetMobilePwrd").addClass("required");
		if (asset == null) {
			$j("#edit-asset-mobile-instructions").show();
		}
	} else {
		$j("#edit-asset-mobile").hide();
		$j("#edit-asset-uniqueid").show();
	}

	if (
		asset != null &&
		device != null &&
		($j.inArray(device.Id, devices.MOBILE) !== -1 || $j.inArray(device.Id, devices.INMARSAT_C) !== -1)
	) {
		$j("#edit-asset-uniqueid").show();
		$j("#txtEditAssetUniqueId").prop("disabled", true);
		// don't require mobile username and password
		$j("#txtEditAssetMobileUnme,#txtEditAssetMobilePwrd").removeClass("required");
	}

	addressBookGroup.find("button").prop("disabled", true).hide();
	cannedMessageGroup.find("button").prop("disabled", true).hide();

	if (options.enableShoutConfigOta && asset != null && device != null && $j.inArray(device.Id, devices.NAL) != -1) {
		addressBookGroup.find("button").prop("disabled", false).show();
		cannedMessageGroup.find("button").prop("disabled", false).show();
	}

	if (asset == null && device != null && $j.inArray(device.Id, devices.NAL) != -1 && options.enableShoutConfigOta) {
		// show optional sync checkbox for when an asset is being added
		// when edited, it only applies if the group is being changed
		$j("#edit-asset-sync-ota").show();
	} else {
		$j("#edit-asset-sync-ota").hide();
	}

	// hardcoded differences
	switch (parseInt($j(elem).val())) {
		case devices.IRIDIUM_EXTREME:
			label.text("IMEI");
			input.addClass("imei 9575");
			imeiValid.addClass("imei-code");
			code.show();
			setTimeout(function () {
				// this is a hack to get around timing issues with the .inputmask plugin
				validateIMEI(input);
			}, 500);
			// supports canned message group/address book entry push
			addressBookGroup.find("button").prop("disabled", false).show();
			cannedMessageGroup.find("button").prop("disabled", false).show();
			break;
		default:
			label.text(strings.ASSET_UNIQUEID);
			input.removeClass("imei 9575");
			imeiValid.removeClass("imei-code");
			code.hide();
			break;
	}
	if (device != null) {
		label.text($j("#ddlEditAssetDeviceTypes option[value=" + device.UniqueIdType + "]").text());
		secondaryLabel.text($j("#ddlEditAssetDeviceTypes option[value=" + device.SecondaryUniqueIdType + "]").text());
	} else {
		label.text($j("#ddlEditAssetDeviceTypes option[value=0]").text());
		secondaryLabel.text($j("#ddlEditAssetDeviceTypes option[value=0]").text());
	}

	populateEditAssetLabelsTab(asset, device);
}

function populateGatewayAccounts($container, $input, types, accountId) {
	$input.empty();
	var defaultAccountId = null;
	var includesDirectIp = false;
	types.forEach(function (typeId) {
		if (typeId === 9) {
			includesDirectIp = true;
		}
	});
	if (includesDirectIp) {
		$input.append($("<option>").val(-1).text(strings.DEFAULT_COMMERCIAL_ACCOUNT));
	} else {
		// none can be a valid choice for some gateways (such as sms) if it is not required
		$input.append($("<option>").val("").text(strings.GROUP_NONE));
	}
	for (var i = 0; i < types.length; i++) {
		// add all user-defined gateway accounts with this type
		var gatewayType = types[i];
		var addedGateways = false;
		for (var j = 0; j < trkData.gatewayAccounts.length; j++) {
			var gateAccount = trkData.gatewayAccounts[j];
			if (gateAccount.type != gatewayType) {
				continue;
			}
			$input.append($("<option>").val(gateAccount.id).text(gateAccount.name));
			addedGateways = true;
			if (gateAccount.default) {
				defaultAccountId = gateAccount.id;
			}
		}
		if (gatewayType == 9 && !addedGateways) {
			// if additional iridium gateway accounts are not specified
			// then simply hide the default gateway to avoid confusion
			$container.hide();
		}
	}

	// select currently chosen account
	if (accountId !== null) {
		// SBD's default is valid, others are not
		if (accountId === "" && includesDirectIp) {
			$input.val(-1);
		} else {
			$input.val(accountId);
		}
	} else if (defaultAccountId !== null) {
		// choose the default gateway account, if specified
		$input.val(defaultAccountId);
	}
}

function populateEditAssetLabelsTab(asset, device) {
	$("#edit-asset-accordion .primary-card button").removeClass("disabled");
	if (!user.isAdmin) {
		$("#accordion-edit-asset-users-head button").addClass("disabled");
	}
	if (device == null) {
		$("#accordion-edit-asset-labels-head button").addClass("disabled");
		$("#accordion-edit-asset-garmin-head button").addClass("disabled");
		$("#accordion-edit-asset-assets-head button").addClass("disabled");
	} else {
		if (device.OutputPinCount === 0 && device.InputPinCount === 0) {
			$("#edit-asset-tab-labels").addClass("disabled");
		}
		if (!device.SupportsQuickMessages && !device.SupportsGarminForms) {
			$("#accordion-edit-asset-garmin-head button").addClass("disabled");
		}
		if (_.indexOf(devices.MOBILE, device.Id) === -1) {
			$("#accordion-edit-asset-assets-head").parent().removeClass("is-visible");
		} else {
			$("#accordion-edit-asset-assets-head").parent().addClass("is-visible");
		}
	}
	if (asset == null) {
		$("#accordion-edit-asset-alerts-head button").addClass("disabled");
	}

	$j("#edit-asset-labels-input, #labels-input-digital,#labels-input-analog").hide();
	$j("#edit-asset-labels-output").hide();
	if (device == null) {
		return;
	}

	var inputGroupEl = function (type, inputId, checkboxId, isToggle) {
		var checkbox = el("input." + type + ".custom-control-input", {
			id: checkboxId,
			name: checkboxId,
			type: "checkbox",
			checked: true,
		});
		checkbox.setAttribute("checked", "checked");
		return el("div.input-group.input-group-checkbox", [
			el(
				"div.input-group-prepend" + (isToggle ? ".parameter-toggle" : ""),
				el(
					"div.input-group-text",
					el("div.custom-control.custom-checkbox.empty-label", [
						checkbox,
						text(" "),
						el("label.custom-control-label", { for: checkboxId }, " "),
					])
				)
			),
			el("input.form-control", { id: inputId, name: inputId, type: "text", maxlength: 250 }),
		]);
	};

	if (device.OutputPinCount > 0 || device.InputPinCount > 0) {
		if (device.OutputPinCount > 0) {
			$j("#edit-asset-labels-output").show();

			// pop the fields
			var rows = [];
			for (var i = 0; i < device.OutputPinCount; i++) {
				var num = i + 1;
				var pinName = "EditAssetOutputLabel" + num;
				var onName = "EditAssetOnLabel" + num;
				var onEnabledName = "EditAssetOnEnabled" + num;
				var offName = "EditAssetOffLabel" + num;
				var offEnabledName = "EditAssetOffEnabled" + num;
				var pulseName = "EditAssetPulseLabel" + num;
				var pulseEnabledName = "EditAssetPulseEnabled" + num;
				var cols = [
					el("td.align-middle", el("label", { for: pinName }, num)),
					el("td", el("input#" + pinName + ".form-control", { type: "text", maxlength: 250 })),
					el(
						"td.idp.arc",
						asset != null ? document.querySelector("#ddlConfigurationARC select").cloneNode(true) : null
					),
					el("td", inputGroupEl("digital", onName, onEnabledName, false)),
					el("td", inputGroupEl("digital", offName, offEnabledName, false)),
					el("td", inputGroupEl("digital", pulseName, pulseEnabledName, false)),
				];
				rows.push(el("tr", cols));
			}
			setChildren(document.querySelector("#OutputLabels tbody"), rows);

			$j("#OutputLabels .idp").hide();
			if (asset != null) {
				if (
					$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
					$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP) != -1
				) {
					$j("#OutputLabels .idp").show();
				}
				if ($j.inArray(asset.Configuration, trkData.avlConfigurations) == -1) {
					$j("#OutputLabels .avl").hide();
				}
				if ($j.inArray(asset.Configuration, trkData.arcConfigurations) == -1) {
					$j("#OutputLabels .arc").hide();
				}
			}
		}

		if (device.InputPinCount > 0) {
			$j("#edit-asset-labels-input").show();
			$j("#InputLabelsDigital tbody, #InputLabelsAnalog tbody").empty();
			$j("#InputLabelsAnalog span.capacity").text("Fuel Capacity (" + fuelText() + ")");
			var num = 0;

			if (device.InputConfigurationPinsDigital != null) {
				$j("#labels-input-digital").show();
				var rows = [];
				for (var i = 0; i < device.InputConfigurationPinsDigital.length; i++) {
					var ioConfig = null;
					var avlConfig = null;
					var arcConfig = null;
					if (asset != null) {
						if (
							$.inArray(asset.DeviceId, devices.SKYWAVE_IDP) != -1 ||
							asset.DeviceId == devices.SKYWAVE_782 ||
							asset.DeviceId == devices.SKYWAVE_782_CELL ||
							asset.DeviceId == devices.SKYWAVE_ST9100 ||
							asset.DeviceId == devices.ORBCOMM_ST9101
						) {
							ioConfig = document.querySelector("#ddlConfigurationEIO select").cloneNode(true);
						} else if ($.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) != -1) {
							ioConfig = document.querySelector("#ddlConfigurationEEIO select").cloneNode(true);
						}
						avlConfig = document.querySelector("#ddlConfigurationAVL select").cloneNode(true);
						arcConfig = document.querySelector("#ddlConfigurationARC select").cloneNode(true);
					}

					num++;
					var pinName = "EditAssetInputLabel" + num;
					var devicePinName = "EditAssetInputLabelPin" + num;
					var deviceNOName = "EditAssetInputLabelNormallyOpen" + num;
					var deviceSOSName = "EditAssetInputLabelEmergency" + num;
					var onName = "EditAssetInputOnLabel" + num;
					var onEnabledName = "EditAssetInputOnEnabled" + num;
					var offName = "EditAssetInputOffLabel" + num;
					var offEnabledName = "EditAssetInputOffEnabled" + num;

					var cols = [
						el("td.align-middle", el("label", { for: pinName }, i + 1)),
						el("td", [
							el("input.form-control", { type: "text", maxlength: 250, id: pinName, name: pinName }),
							el("input", {
								id: devicePinName,
								name: devicePinName,
								value: device.InputConfigurationPinsDigital[i],
								type: "hidden",
							}),
						]),
						el("td.idp", i < 4 ? ioConfig : null),
						el("td.idp.avl", avlConfig),
						el("td.idp.arc", arcConfig),
						el("td", inputGroupEl("digital", onName, onEnabledName, true)),
						el("td", inputGroupEl("digital", offName, offEnabledName)),
						el(
							"td",
							el("div.custom-control.custom-checkbox.empty-label", [
								el("input.digital.custom-control-input", { type: "checkbox", id: deviceNOName, value: "true" }),
								el("label.custom-control-label", { for: deviceNOName }, " "),
							])
						),
						el(
							"td",
							el("div.custom-control.custom-checkbox.empty-label", [
								el("input.digital.custom-control-input", { type: "checkbox", id: deviceSOSName, value: "true" }),
								el("label.custom-control-label", { for: deviceSOSName }, " "),
							])
						),
					];
					rows.push(el("tr", cols));
				}
				setChildren(document.querySelector("#InputLabelsDigital tbody"), rows);
			}
			$j("#InputLabelsDigital .idp").hide();
			if (asset != null) {
				if (
					$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
					$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP) != -1
				) {
					$j("#InputLabelsDigital .idp").show();
				}
				if ($j.inArray(asset.Configuration, trkData.avlConfigurations) == -1) {
					$j("#InputLabelsDigital .avl").hide();
				}
				if ($j.inArray(asset.Configuration, trkData.arcConfigurations) == -1) {
					$j("#InputLabelsDigital .arc").hide();
				}
			}

			if (device.InputConfigurationPinsAnalog != null) {
				if (
					($j.inArray(device.Id, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
						$j.inArray(device.Id, devices.SKYWAVE_IDP) != -1) &&
					asset != null &&
					$j.inArray(asset.Configuration, trkData.avlConfigurations) != -1
				) {
					$j("#labels-input-analog .title").text(strings.SENSORS);
				} else {
					$j("#labels-input-analog .title").text(strings.INPUT + " - " + strings.ANALOG_PIN);
				}
				$j("#labels-input-analog").show();
				var rows = [];
				for (var i = 0; i < device.InputConfigurationPinsAnalog.length; i++) {
					num++;
					var pinName = "EditAssetInputLabel" + num;
					var devicePinName = "EditAssetInputLabelPin" + num;
					// var deviceFuelName = "EditAssetInputLabelFuel" + num;
					var analogBehaviorName = "EditAssetInputAnalogBehavior" + num;
					var voltName = "EditAssetInputMaxVoltage" + num;
					var fuelCapName = "EditAssetInputFuelCapacity" + num;
					var maxWeightName = "EditAssetInputWeightMax" + num;
					var analogUnitName = "EditAssetInputAnalogUnit" + num;
					var analogFactorName = "EditAssetInputAnalogFactor" + num;

					// TODO: i18n
					var behaviorSelect = el("select.analog.behavior.form-control", { id: analogBehaviorName }, [
						el("option", { value: "0" }, "None"),
						el("option", { value: "9" }, "Altitude"),
						el("option", { value: "7" }, "Battery Voltage"),
						el("option", { value: "8" }, "Battery Percentage"),
						el("option", { value: "5" }, "Engine RPM"),
						el("option", { value: "1" }, "Fuel Level"),
						el("option", { value: "2" }, "Fuel Consumed"),
						el("option", { value: "3" }, "Odometer"),
						el("option", { value: "6" }, "Power take-off (PTO)"),
						el("option", { value: "4" }, "Weight"),
					]);

					var cols = [
						el("td.align-middle.center", el("label", { for: pinName }, i + 1)),
						el("td", [
							el("input.form-control", { type: "text", maxlength: 250, id: pinName, name: pinName }),
							el("input", {
								id: devicePinName,
								name: devicePinName,
								value: device.InputConfigurationPinsAnalog[i],
								type: "hidden",
							}),
						]),
						el("td.idp.avl.center", [el("span.sensor"), text(" ")]), // remove text(' ')?
						el(
							"td.idp.arc",
							asset != null ? document.querySelector("#ddlConfigurationARC select").cloneNode(true) : " "
						),
						el("td", el("input.analog.form-control", { id: analogUnitName, type: "text", maxlength: 15 })),
						el("td", el("input.analog.form-control", { id: analogFactorName, type: "text", maxlength: 5 })),
						el("td", behaviorSelect),
						el("td", el("input.analog.override.fuel.form-control", { id: voltName, type: "text", maxlength: 13 })),
						el("td", el("input.analog.override.fuel.form-control", { id: fuelCapName, type: "text", maxlength: 14 })),
						el(
							"td",
							el("input.analog.override.weight.form-control", { id: maxWeightName, type: "text", maxlength: 14 })
						),
					];
					rows.push(el("tr", cols));
				}
				setChildren(document.querySelector("#InputLabelsAnalog tbody"), rows);
			}
			$j("#InputLabelsAnalog .idp").hide();
			if (asset != null) {
				if (
					$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
					$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP) != -1
				) {
					$j("#InputLabelsAnalog .idp").show();
				}
				if ($j.inArray(asset.Configuration, trkData.avlConfigurations) == -1) {
					$j("#InputLabelsAnalog .avl").hide();
				}
				if ($j.inArray(asset.Configuration, trkData.arcConfigurations) == -1) {
					$j("#InputLabelsAnalog .arc").hide();
				}
			}
		}

		$j("#EditAssetIOTemplate").empty();
		$j("#EditAssetIOTemplate").append($j("<option>").text(strings.GROUP_NONE).val(""));
		var selectedTemplate = null;
		for (var i = 0; i < device.IOTemplates.length; i++) {
			var template = device.IOTemplates[i];
			if (asset != null && asset.IOTemplateId == template.Id) {
				selectedTemplate = template;
			}
			$j("#EditAssetIOTemplate").append(
				$j("<option>")
					.val(template.Id)
					.text(template.Name)
					.prop("selected", (asset != null && asset.IOTemplateId) == template.Id ? true : false)
			);
		}

		if (selectedTemplate != null) {
			setLabelsByTemplate(device, asset, selectedTemplate);
			$j(
				"#labels-input-analog input, #labels-input-analog select, #labels-input-digital input, #labels-output input"
			).prop("disabled", true);
		} else {
			setLabelsByTemplate(device, asset, null);
		}

		// enable behavior overrides
		$j("#labels-input-analog select.behavior").each(function (index, elem) {
			toggleAnalogIOBehavior(this);
		});
	}
}

function loadSoftwarePackages(callback) {
	var data = {};
	toggleLoadingMessage(true, "get-software-packages");
	$.ajax({
		type: "POST",
		url: wrapUrl("/Services/GPSService.asmx/GetSoftwarePackages"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			toggleLoadingMessage(false, "get-software-packages");
			var result = msg.d;
			if (result) {
				if (result.Success == true) {
					trkData.softwarePackages = result.Packages;
					if (callback !== undefined && callback !== null) {
						callback();
					}
				} else {
					handleWebServiceError();
				}
			}
		},
		error: function (xhr, status, error) {
			handleWebServiceError();
			toggleLoadingMessage(false, "get-software-packages");
		},
	});
}

export function setLabelsByTemplate(device, asset, template) {
	if (template == null) template = asset;
	if (device.OutputPinCount > 0) {
		if (template != null) {
			for (var i = 0; i < device.OutputPinCount; i++) {
				var num = i + 1;
				var label = getOutputLabelByIndex(template, num);
				if (label == null)
					// no labels defined for this pin
					continue;
				var pinVal = label.Label == null ? "" : label.Label;
				var onVal = label.OnLabel == null ? "" : label.OnLabel;
				var offVal = label.OffLabel == null ? "" : label.OffLabel;
				var pulseVal = label.PulseLabel == null ? "" : label.PulseLabel;
				var onEnabled = !label.OnIsDisabled;
				var offEnabled = !label.OffIsDisabled;
				var pulseEnabled = !label.PulseIsDisabled;
				$j("#EditAssetOutputLabel" + num).val(pinVal);
				$j("#EditAssetOnLabel" + num).val(onVal);
				$j("#EditAssetOnEnabled" + num).prop("checked", onEnabled);
				$j("#EditAssetOnLabel" + num).prop("disabled", !onEnabled);
				$j("#EditAssetOffLabel" + num).val(offVal);
				$j("#EditAssetOffEnabled" + num).prop("checked", offEnabled);
				$j("#EditAssetOffLabel" + num).prop("disabled", !offEnabled);
				$j("#EditAssetPulseLabel" + num).val(pulseVal);
				$j("#EditAssetPulseEnabled" + num).prop("checked", pulseEnabled);
				$j("#EditAssetPulseLabel" + num).prop("disabled", !pulseEnabled);
			}
		}
	}
	if (template != null) {
		var num = 0;
		if (device.InputConfigurationPinsDigital != null) {
			for (var i = 0; i < device.InputConfigurationPinsDigital.length; i++) {
				num++;
				var label = getInputLabelByIndex(template, num);
				if (label == null)
					// no labels defined for this pin
					continue;

				var override = getInputLabelByIndex(asset, num);

				var pinVal = label.Label == null ? "" : label.Label;
				var onVal = label.OnLabel == null ? "" : label.OnLabel;
				var offVal = label.OffLabel == null ? "" : label.OffLabel;
				var onEnabled = !label.OnIsDisabled;
				var offEnabled = !label.OffIsDisabled;

				$j("#EditAssetInputLabel" + num).val(pinVal);
				$j("#EditAssetInputLabelPin" + num).val(device.InputConfigurationPinsDigital[i]);
				$j("#EditAssetInputLabelNormallyOpen" + num).prop("checked", label.IsNormallyOpen);
				$j("#EditAssetInputLabelEmergency" + num).prop("checked", label.IsEmergency);

				$j("#EditAssetInputOnLabel" + num).val(onVal);
				$j("#EditAssetInputOnEnabled" + num).prop("checked", onEnabled);
				$j("#EditAssetInputOnLabel" + num).prop("disabled", !onEnabled);
				$j("#EditAssetInputOffLabel" + num).val(offVal);
				$j("#EditAssetInputOffEnabled" + num).prop("checked", offEnabled);
				$j("#EditAssetInputOffLabel" + num).prop("disabled", !offEnabled);
			}
		}
		if (device.InputConfigurationPinsAnalog != null) {
			for (var i = 0; i < device.InputConfigurationPinsAnalog.length; i++) {
				num++;
				var label = getInputLabelByIndex(template, num);
				if (label == null) {
					// no labels defined for this pin
					$j("#EditAssetInputAnalogBehavior" + num).val("0");
					continue;
				}
				// asset overriding the template? not understanding this
				var override = getInputLabelByIndex(asset, num);
				var pinVal = label.Label == null ? "" : label.Label;
				var maxVoltage =
					override.MaxVoltage == null ? (label.MaxVoltage == null ? "" : label.MaxVoltage) : override.MaxVoltage;
				var fuelCapacity =
					override.FuelCapacity == null
						? label.FuelCapacity == null
							? ""
							: label.FuelCapacity
						: override.FuelCapacity;
				var weightMax =
					override.WeightMax == null ? (label.WeightMax == null ? "" : label.WeightMax) : override.WeightMax;

				$j("#EditAssetInputLabel" + num).val(pinVal);
				$j("#EditAssetInputLabelPin" + num).val(device.InputConfigurationPinsAnalog[i]);
				if (label.AnalogBehavior === null) {
					$j("#EditAssetInputAnalogBehavior" + num).val("0");
				} else {
					$j("#EditAssetInputAnalogBehavior" + num).val(label.AnalogBehavior);
				}
				$j("#EditAssetInputLabelFuel" + num).prop("checked", label.IsFuel);
				$j("#EditAssetInputAnalogUnit" + num).val(label.AnalogUnit);
				$j("#EditAssetInputAnalogFactor" + num).val(label.AnalogFactor);
				// can be overridden by asset
				$j("#EditAssetInputMaxVoltage" + num).val(maxVoltage);
				$j("#EditAssetInputFuelCapacity" + num).val(fuelCapacity);
				$j("#EditAssetInputWeightMax" + num).val(weightMax);
			}
		}
	}
}

function getInputLabelByIndex(asset, index) {
	if (asset == null) return null;
	if (asset.InputLabels == null) return null;
	for (var i = 0; i < asset.InputLabels.length; i++) {
		var label = asset.InputLabels[i];
		if (label.Number == index) return label;
		//return label.Label;
	}
	return null;
}

export function getOutputLabelByIndex(asset, index) {
	if (asset == null) return null;
	if (asset.OutputLabels == null) return null;
	for (var i = 0; i < asset.OutputLabels.length; i++) {
		var label = asset.OutputLabels[i];
		if (label.Number == index) return label;
	}
	return null;
}

export function toggleAnalogIOBehavior(elem) {
	var behavior = parseInt($j(elem).val());
	var tr = $j(elem).parent().parent();
	tr.find("input.override").prop("disabled", true);
	switch (behavior) {
		case 1:
			tr.find("input.fuel").prop("disabled", false);
			break;
		case 4:
			tr.find("input.weight").prop("disabled", false);
			break;
	}
}
