import strings from "./strings.js";
import trkData from "./data.js";
import domNodes from "./domNodes.js";
import { sortModes } from "./const.js";
import { toggleItemSorting } from "./item-sorting.js";
import { findAssetIdsUnderGroup } from "./assets.js";
import { findGroupById } from "./asset-group.js";
import user from "./user.js";

import _ from "lodash";

export function filterAssets(search) {
	var assetGroups = trkData.search.assetGroups.search(search);
	var assetGroupIds = _.map(assetGroups, "Id");

	var assets = trkData.search.assets.search(search);
	var assetIds = _.map(assets, "Id");

	//console.log('filter matches: ' + assetGroupIds.length +', ' + assetIds.length);
	//console.log(assetGroupIds);
	//console.log(assetIds);

	if (search === "") {
		assetIds = _.map(trkData.assets, "Id");
		assetGroupIds = _.map(trkData.groups, "Id");
		domNodes.filter.assetResults.classList.remove("is-visible");
		toggleItemSorting("assets", user.displayPreferences.sortMode.assets === sortModes.CUSTOM);
	} else {
		toggleItemSorting("assets", false);
	}

	if (assets.length === 0 && assetGroups.length === 0) {
		// hide all assets or show all assets?
	}

	// remove or hide nodes and groups that don't match the search
	// we'll attempt to simply hide the nodes to start with
	// but we may need to completely remove them instead for performance

	// if an asset matches the search, show it and any parent groups above it
	_.each(domNodes.assets, function (assetNodes, id, nodes) {
		if (_.indexOf(assetIds, parseInt(id)) !== -1) {
			// this asset matches
			_.each(assetNodes, function (assetNode) {
				assetNode.classList.remove("is-filtered");
			});
		} else {
			// asset not included in search results
			_.each(assetNodes, function (assetNode) {
				assetNode.classList.add("is-filtered");
			});
		}
	});

	// filter groups with no matching assets
	_.each(trkData.groups, function (group) {
		var groupNode = domNodes.groups[group.Id];
		if (groupNode.getAttribute("data-group-for") !== "assets") {
			return;
		}
		if (search !== "" && _.intersection(findAssetIdsUnderGroup(group), assetIds).length === 0) {
			groupNode.classList.add("is-filtered");
		} else {
			groupNode.classList.remove("is-filtered");
		}
	});

	// groups that did not have a matching asset are hidden at this point, re-show any groups that also match
	// it's cleaner to perform this after the asset filtering due to subgroups
	var assetIdsUnderGroups = [];
	_.each(trkData.groups, function (group) {
		if (_.indexOf(assetGroupIds, group.Id) !== -1) {
			assetIdsUnderGroups = _.union(assetIdsUnderGroups, findAssetIdsUnderGroup(group));
			// show this group and any assets or groups under it
			var groupNode = domNodes.groups[group.Id];
			if (groupNode.getAttribute("data-group-for") !== "assets") {
				return;
			}
			groupNode.classList.remove("is-filtered");

			unfilterGroupAndChildren(group);

			// if this is a subgroup, its parent groups must be included up to root
			var parentGroupId = group.ParentGroupId;
			while (parentGroupId !== undefined && parentGroupId !== null) {
				var parentGroup = findGroupById(parentGroupId);
				var parentGroupNode = domNodes.groups[parentGroup.Id];
				parentGroupNode.classList.remove("is-filtered");
				parentGroupId = parentGroup.ParentGroupId;
			}
		} else {
			// leave this group and its contents hidden
		}
	});

	if (assetIds.length === 0) {
		domNodes.groups["all-assets"].classList.add("is-filtered");
	} else {
		domNodes.groups["all-assets"].classList.remove("is-filtered");
	}

	// filter results is assets.length + assets under visible groups
	var allIncludedAssetIds = _.uniq(_.union(assetIds, assetIdsUnderGroups)).length;
	if (search !== "") {
		showFilterResults(allIncludedAssetIds, "assets");
	}
}

function unfilterGroupAndChildren(group) {
	// group content nodes are removed from the DOM and must be iterated
	var groupContents = domNodes.groupContents[group.Id];

	var groupAssets = groupContents.querySelectorAll(".group-item");
	_.each(groupAssets, function (groupItemNode) {
		groupItemNode.classList.remove("is-filtered");
	});
	var groupGroups = groupContents.querySelectorAll(".group");
	_.each(groupGroups, function (groupItemNode) {
		groupItemNode.classList.remove("is-filtered");
		var subGroup = findGroupById(groupItemNode.getAttribute("data-group-id"));
		unfilterGroupAndChildren(subGroup);
	});
}

function showFilterResults(num, type) {
	var results = domNodes.filter.assetResults;
	var resultNone = strings.FILTER_NO_ASSETS;
	var resultOne = strings.FILTER_SINGLE_ASSET;
	var resultMany = strings.FILTER_ASSETS;
	switch (type) {
		case "fences":
			results = domNodes.filter.fenceResults;
			resultNone = strings.FILTER_NO_GEOFENCES;
			resultOne = strings.FILTER_SINGLE_GEOFENCE;
			resultMany = strings.FILTER_GEOFENCES;
			break;
		case "places":
			results = domNodes.filter.placeResults;
			resultNone = strings.FILTER_NO_PLACES;
			resultOne = strings.FILTER_SINGLE_PLACE;
			resultMany = strings.FILTER_PLACES;
			break;
		case "shared-views":
			results = domNodes.filter.sharedViewResults;
			resultNone = strings.FILTER_NO_SHARED_VIEWS;
			resultOne = strings.FILTER_SINGLE_SHARED_VIEW;
			resultMany = strings.FILTER_SHARED_VIEWS;
			break;
	}

	results.classList.add("is-visible");

	var text = results.querySelector("span");
	if (num === 0) {
		text.textContent = resultNone;
	} else if (num === 1) {
		text.textContent = resultOne;
	} else {
		text.textContent = resultMany.replace("{0}", num);
	}
}

export function filterSharedViews(search) {
	// TODO make this generic across fences/places/shared views
	var sharedViews = trkData.search.sharedViews.search(search);
	var sharedViewIds = _.map(sharedViews, "Id");

	if (search === "") {
		sharedViewIds = _.map(trkData.sharedViews, "Id");
		domNodes.filter.sharedViewResults.classList.remove("is-visible");
	}

	// remove or hide nodes and groups that don't match the search
	// we'll attempt to simply hide the nodes to start with
	// but we may need to completely remove them instead for performance
	_.each(domNodes.sharedViews, function (sharedViewNode, id, nodes) {
		if (_.indexOf(sharedViewIds, parseInt(id)) !== -1) {
			// this shared-view is visible
			sharedViewNode.classList.remove("is-filtered");
		} else {
			// shared-view not included in search results
			sharedViewNode.classList.add("is-filtered");
		}
	});

	if (sharedViewIds.length === 0) {
		domNodes.groups["all-shared-views"].classList.add("is-filtered");
	} else {
		domNodes.groups["all-shared-views"].classList.remove("is-filtered");
	}

	if (search !== "") {
		showFilterResults(sharedViews.length, "shared-views");
	}
}

export function filterFences(search) {
	var fences = trkData.search.fences.search(search);
	var fenceIds = _.map(fences, "Id");

	if (search === "") {
		fenceIds = _.map(trkData.fences, "Id");
		domNodes.filter.fenceResults.classList.remove("is-visible");
	}

	// remove or hide nodes and groups that don't match the search
	// we'll attempt to simply hide the nodes to start with
	// but we may need to completely remove them instead for performance
	_.each(domNodes.fences, function (fenceNode, id, nodes) {
		if (_.indexOf(fenceIds, id) !== -1) {
			// this fence is visible
			fenceNode.classList.remove("is-filtered");
		} else {
			// fence not included in search results
			fenceNode.classList.add("is-filtered");
		}
	});

	if (fenceIds.length === 0) {
		domNodes.groups["all-fences"].classList.add("is-filtered");
	} else {
		domNodes.groups["all-fences"].classList.remove("is-filtered");
	}

	if (search !== "") {
		showFilterResults(fences.length, "fences");
	}
}

export function filterPlaces(search) {
	var places = trkData.search.places.search(search);
	var placeIds = _.map(places, "Id");

	if (search === "") {
		placeIds = _.map(trkData.places, "Id");
		domNodes.filter.placeResults.classList.remove("is-visible");
	}

	// remove or hide nodes and groups that don't match the search
	// we'll attempt to simply hide the nodes to start with
	// but we may need to completely remove them instead for performance
	_.each(domNodes.places, function (placeNode, id, nodes) {
		if (_.indexOf(placeIds, parseInt(id)) !== -1) {
			// this place is visible
			placeNode.classList.remove("is-filtered");
		} else {
			// place not included in search results
			placeNode.classList.add("is-filtered");
		}
	});

	if (placeIds.length === 0) {
		domNodes.groups["all-places"].classList.add("is-filtered");
	} else {
		domNodes.groups["all-places"].classList.remove("is-filtered");
	}

	if (search !== "") {
		showFilterResults(places.length, "places");
	}
}
