import { getSvgIconForItemType } from "./dom-util.js";
import strings from "./strings.js";
import { findGroupById } from "./asset-group.js";
import { sortAssetGroups } from "./item-sorting.js";
import trkData from "./data.js";

import _ from "lodash";
import { el, text, mount, setChildren } from "redom"; // https://redom.js.org/

export function populateGroupList() {
	// populate group parent list
	var cont = document.getElementById("ddlGroupParent");
	var defaultOption = el("option", { selected: true, value: "" }, strings.GROUP_NONE);
	defaultOption.setAttribute("selected", "selected");
	const options = [defaultOption];

	var sortedGroups = sortAssetGroups();
	for (var i = 0; i < sortedGroups.length; i++) {
		var group = sortedGroups[i];
		addGroupToGroupListInAddGroupDialog(group, 0, options);
	}
	setChildren(cont, options);
}

function addGroupToGroupListInAddGroupDialog(group, level, options) {
	if (group.IsDefault) {
		return;
	}
	var prepend = "";
	for (var i = 0; i < level; i++) {
		prepend += "--";
	}
	if (prepend != "") {
		prepend += " ";
	}
	options.push(el("option", { value: group.Id }, prepend + group.Name));
	level++;
	if (group.GroupIds != null) {
		for (var k = 0; k < group.GroupIds.length; k++) {
			var subGroup = findGroupById(group.GroupIds[k]);
			addGroupToGroupListInAddGroupDialog(subGroup, level, options);
		}
	}
}

export function addGroupToGroupListInAssetEditDialog(group, level, cont, asset) {
	if (group.IsDefault) {
		return;
	}
	var isChecked = false;
	if (asset != null) {
		for (var j = 0; j < asset.GroupIds.length; j++) {
			if (asset.GroupIds[j] == group.Id) {
				isChecked = true;
				break;
			}
		}
	}
	var checkbox = el("input.custom-control-input", {
		type: "checkbox",
		id: "EditAssetGroup" + group.Id,
		name: "EditAssetGroupIds",
		value: group.Id,
		checked: isChecked,
	});
	if (isChecked) {
		checkbox.setAttribute("checked", "checked");
	}
	var input = el("div.custom-control.custom-checkbox", { style: { marginLeft: level * 15 + "px" } }, [
		checkbox,
		text(" "),
		el("label.custom-control-label", { for: "EditAssetGroup" + group.Id }, group.Name),
	]);
	mount(cont, input);
	level++;
	if (group.GroupIds != null) {
		for (var k = 0; k < group.GroupIds.length; k++) {
			var subGroup = findGroupById(group.GroupIds[k]);
			addGroupToGroupListInAssetEditDialog(subGroup, level, cont, asset);
		}
	}
}

export function addItemGroupToItemGroupListInAssetEditDialog(group, level, cont, selectedGroupIds) {
	if (group.IsDefault) {
		return;
	}
	var isChecked = _.indexOf(selectedGroupIds, group.Id) !== -1;
	var checkbox = el("input.custom-control-input", {
		type: "checkbox",
		id: "EditAssetDriverGroup" + group.Id,
		name: "EditAssetDriverGroupIds",
		value: group.Id,
		checked: isChecked,
	});
	if (isChecked) {
		checkbox.setAttribute("checked", "checked");
	}
	var input = el("div.custom-control.custom-checkbox", { style: { marginLeft: level * 15 + "px" } }, [
		checkbox,
		text(" "),
		el("label.custom-control-label", { for: "EditAssetDriverGroup" + group.Id }, group.Name),
	]);
	mount(cont, input);
	level++;
	if (group.GroupIds != null) {
		for (var k = 0; k < group.GroupIds.length; k++) {
			var subGroup = _.find(trkData.driverGroups, { Id: group.GroupIds[k] });
			addItemGroupToItemGroupListInAssetEditDialog(subGroup, level, cont, selectedGroupIds);
		}
	}
}

export function addGroupToGroupListWithName(group, level, cont, name, parent, includeIcon) {
	if (group.IsDefault) {
		return;
	}

	var control = document.createElement("div");
	control.className = "custom-control custom-checkbox";
	if (level > 0) {
		control.style.marginLeft = level * 15 + "px";
	}

	var input = document.createElement("input");
	input.className = "custom-control-input";
	input.type = "checkbox";
	input.id = name + group.Id;
	input.name = name;
	input.value = group.Id;
	if (parent !== undefined && parent !== null) {
		input.setAttribute("data-parent", parent.Id);
	}

	var label = document.createElement("label");
	label.className = "custom-control-label";
	label.setAttribute("for", name + group.Id);

	var span = document.createElement("span");
	span.textContent = group.Name;

	if (includeIcon === true) {
		var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		var iconType = document.createElementNS("http://www.w3.org/2000/svg", "use");
		iconType.setAttributeNS("http://www.w3.org/1999/xlink", "href", getSvgIconForItemType("groups", group));
		svg.setAttribute("style", "color: " + group.Color);
		svg.appendChild(iconType);
		label.appendChild(svg);
		label.classList.add("has-svg-icon");
	}

	label.appendChild(span);
	control.appendChild(input);
	control.appendChild(label);
	cont.appendChild(control);
	level++;
	if (group.GroupIds != null) {
		for (var k = 0; k < group.GroupIds.length; k++) {
			var subGroup = findGroupById(group.GroupIds[k]);
			addGroupToGroupListWithName(subGroup, level, cont, name, group, includeIcon);
		}
	}
}
