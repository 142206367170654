import trkData from "./data.js";
import state from "./state.js";
import domNodes from "./domNodes.js";
import options from "./options.js";
import { mapModes } from "./const.js";
import { findAssetById, findAssetIdsUnderGroup } from "./assets.js";
import { toggleFenceActive, findFenceById, addFencePaths, centerOnFence } from "./fence.js";
import { map } from "./map-base.js";
import { waypointMarkers } from "./routing-waypoint.js";
import { findGroupById, updateGroupVisibilityStatus, toggleGroupExpanded } from "./asset-group.js";
import { findTripById } from "./trips.js";
import { findPlaceById, togglePlaceActive } from "./place.js";
import { hideMouseTooltip } from "./mouse-tooltip.js";
import { markerClick } from "./marker-click.js";
import { findSharedViewById } from "./shared-view.js";
import { findJourneyById } from "./journey.js";
import { getMapMarkersForDataGroup } from "./marker.js";
import { getAssetDataGroupForCurrentViewMode } from "./map-viewmode.js";
import { highlightPosition } from "./positions.js";
import { toggleTripActive } from "./trips.js";
import { getJourneyDataGroupForCurrentViewMode } from "./map-viewmode.js";
import { createPositionHistorySummary } from "./position-history.js";
import { selectSharedView } from "./shared-view.js";
import { showFenceTooltip } from "./fence.js";
import { openAssetLatestPosition } from "./asset-live.js";
import { openPositionsForAsset } from "./positions.js";
import { openAlertsForAsset } from "./asset-alerts.js";
import { openEventsForAsset } from "./asset-events.js";
import { openStatusForAsset } from "./asset-state.js";
import { openChatForAsset, openMessagesForAsset } from "./messages.js";
import { toggleAssetActive } from "./asset-select.js";
import { updateActiveAssetInformation } from "./assets-active.js";
import { loadMessageHistoryDialog } from "./messages-history.js";
import {
	openAssetSettingsPanel,
	openAssetGroupSettingsPanel,
	openFenceSettingsPanel,
	openPlaceSettingsPanel,
	openSharedViewSettingsPanel,
} from "./panel-settings.js";
import user from "./user.js";

import $ from "jquery";
import $j from "jquery";
import L from "leaflet";
import _ from "lodash";
import { isItemIncluded } from "./polyfills.js";

// Inits UI for everything related to assets.

export function initAssets() {
	var assetsContainer = $j("#panel-content-wrapper");
	assetsContainer.on("pointerover pointerout", "a.location,div.asset-recent-location", function (event) {
		var link = $j(this);
		var markerId = link.attr("data-marker");
		var markers = getMapMarkersForDataGroup(getAssetDataGroupForCurrentViewMode()).concat(
			getMapMarkersForDataGroup(getJourneyDataGroupForCurrentViewMode())
		);
		if (markers !== undefined) {
			for (var i = 0; i < markers.length; i++) {
				var loc = markers[i].data.location;
				if (loc.Id === markerId) {
					markers[i].fire(event.type);
					break;
				}
			}
		}
	});

	$("#dialog-functions").on("pointerover pointerout", "a.location,div.asset-recent-location", function (e) {
		var markerId = this.getAttribute("data-marker");
		var markers = getMapMarkersForDataGroup(getAssetDataGroupForCurrentViewMode()).concat(
			getMapMarkersForDataGroup(getJourneyDataGroupForCurrentViewMode())
		);
		if (markers !== undefined) {
			for (var i = 0; i < markers.length; i++) {
				var loc = markers[i].data.location;
				if (loc.Id === markerId) {
					markers[i].fire(e.type);
					break;
				}
			}
		}
	});

	assetsContainer.on("click", "a.location", function (e) {
		e.preventDefault();
		highlightPosition(this.getAttribute("data-marker"), null);
	});

	// position history date groupings
	assetsContainer.on("click", "span.date a", function (e) {
		e.preventDefault();
		var $ol = $(this).parent().next().next("ol");
		if ($ol.hasClass("is-visible")) {
			$ol.removeClass("is-visible");
		} else {
			$ol.addClass("is-visible");
		}
	});

	assetsContainer.on("click", ".trip-name", function (e) {
		console.log("trip click");
		e.preventDefault();
		var tripNode = this.parentNode.parentNode.parentNode;
		var thisTripId = parseInt(tripNode.getAttribute("data-trip-id"));
		//expandItemNotificationList(tripNode, true);
		var trip = findTripById(thisTripId);
		itemClickedInPrimaryPanel(trip, "trips");

		// if invisible, do nothing? (or auto-make visible?)
		if (_.indexOf(trkData.visible.trips, thisTripId) === -1) {
			return;
		}

		// zoom to trip bounds and open trip info panel
		var tripPositions = trkData.trips.positionsByTripId[trip.Id];
		var tripBounds = L.latLngBounds();
		_.each(tripPositions.Positions, function (position) {
			tripBounds.extend(L.latLng(position.Lat, position.Lng));
		});

		if (tripBounds.isValid()) {
			map.fitBounds(tripBounds, { padding: [10, 10] });
			if (map.getZoom() > options.maximumZoom) {
				map.setZoom(options.maximumZoom);
			}
		}

		var journey = findJourneyById(trip.JourneyId);
		var asset = findAssetById(journey.AssetId);
		createPositionHistorySummary(asset, trip);
	});

	// place name click
	assetsContainer.on("click", ".place-name", function (e) {
		e.preventDefault();
		var thisPlaceId = parseInt(this.parentNode.parentNode.parentNode.getAttribute("data-place-id"));
		var place = findPlaceById(thisPlaceId);
		itemClickedInPrimaryPanel(place, "places");
		if (_.indexOf(trkData.visible.places, thisPlaceId) === -1) {
			return;
		}

		// take to place
		for (var i = 0; i < trkData.placeMarkers.length; i++) {
			var placeId = trkData.placeMarkers[i].data.placeId;
			if (placeId == thisPlaceId) {
				//trkData.placeMarkers[i].fire('click');
				markerClick(trkData.placeMarkers[i], "place", null, false);
				break;
			}
		}
	});

	assetsContainer.on("click", ".shared-view-name", function (e) {
		e.preventDefault();
		var thisSharedViewId = parseInt(this.parentNode.parentNode.parentNode.getAttribute("data-shared-view-id"));
		var sharedView = findSharedViewById(thisSharedViewId);
		itemClickedInPrimaryPanel(sharedView, "shared-views");

		selectSharedView(sharedView);
	});

	assetsContainer.on("mouseover mouseout", "div.fence-name", function (e) {
		e.preventDefault();

		if (e.type == "mouseover") {
			var thisFenceId = this.parentNode.parentNode.parentNode.getAttribute("data-fence-id");
			if (_.indexOf(trkData.visible.fences, thisFenceId) === -1) {
				return;
			}
			var fence = findFenceById(thisFenceId);
			showFenceTooltip(fence);
		} else {
			hideMouseTooltip();
		}
	});

	// fence name click
	assetsContainer.on("click", ".fence-name", function (e) {
		e.preventDefault();
		var thisFenceId = this.parentNode.parentNode.parentNode.getAttribute("data-fence-id");
		var fence = findFenceById(thisFenceId);

		itemClickedInPrimaryPanel(fence, "fences");
		if (_.indexOf(trkData.visible.fences, thisFenceId) === -1) {
			return;
		}

		var centeredFence = centerOnFence(thisFenceId);
		if (centeredFence != null && centeredFence.markers.length > 0) {
			markerClick(centeredFence.markers[0], "fence", centeredFence.center, false);
		}
	});

	assetsContainer.on("click", "a.item-action", function (e) {
		e.preventDefault();

		var actionType = this.getAttribute("data-action");
		switch (actionType) {
			case "current-position":
				var thisAssetId = parseInt(this.getAttribute("data-asset-id"));
				var asset = findAssetById(thisAssetId);
				openAssetLatestPosition(asset);
				break;
			default:
				break;
		}
	});

	assetsContainer.on("click", ".asset-notify-item", function (e) {
		var assetId = this.parentNode.parentNode.parentNode.parentNode.getAttribute("data-asset-id");
		var asset = findAssetById(assetId);
		if (asset === undefined || asset === null) {
			return;
		}
		if (this.classList.contains("notify-position")) {
			openPositionsForAsset(asset);
		} else if (this.classList.contains("notify-alert")) {
			openAlertsForAsset(asset);
		} else if (this.classList.contains("notify-event")) {
			openEventsForAsset(asset);
		} else if (this.classList.contains("notify-status")) {
			openStatusForAsset(asset);
		} else if (this.classList.contains("notify-message")) {
			openMessagesForAsset(asset);
		} else if (this.classList.contains("notify-chat")) {
			openChatForAsset(asset);
		} else {
			console.warn("No match for notify item");
		}
	});

	assetsContainer.on("pointerover pointerout", "div.place-name", function (e) {
		var placeNode = this.parentNode.parentNode.parentNode;
		var thisPlaceId = parseInt(placeNode.getAttribute("data-place-id"));
		var place = findPlaceById(thisPlaceId);

		if (_.indexOf(trkData.visible.places, thisPlaceId) === -1) {
			return;
		}

		trkData.placesById[thisPlaceId].Location.marker.fire(e.type);
	});

	assetsContainer.on("pointerover pointerout", "div.trip-name", function (e) {
		var tripNode = this.parentNode.parentNode.parentNode;
		var thisTripId = parseInt(tripNode.getAttribute("data-trip-id"));
		var trip = findTripById(thisTripId);

		if (_.indexOf(trkData.visible.trips, thisTripId) === -1) return;

		// highlight latest position
		var tripPositions = trkData.trips.positionsByTripId[trip.Id];
		if (tripPositions !== undefined && tripPositions.Positions !== undefined && tripPositions.Positions.length > 0) {
			var latestVisiblePosition = _.find(tripPositions.Positions, function (item) {
				return !item.IsHidden;
			});
			if (latestVisiblePosition !== undefined) {
				var latestVisibleMarker = _.find(trkData.trips.markersByTripId[trip.Id], function (item) {
					return item.data.location.Id === latestVisiblePosition.Id;
				});
				if (latestVisibleMarker !== undefined) {
					latestVisibleMarker.fire(e.type);
				}
			}
		}
	});

	assetsContainer.on("pointerover pointerout", "div.asset-name", function (e) {
		var assetNode = this.parentNode.parentNode.parentNode;
		var thisAssetId = parseInt(assetNode.getAttribute("data-asset-id"));
		var asset = findAssetById(thisAssetId);

		if (_.indexOf(trkData.visible.assets, thisAssetId) === -1) {
			return;
		}

		if (state.activeMapMode === mapModes.LIVE) {
			// take to latest position
			var latestPosition = trkData.live.latestPositionsByAssetId[asset.Id];
			if (latestPosition !== undefined && latestPosition.Position !== undefined && latestPosition.Position !== null) {
				var positionMarker = trkData.live.markersByPositionId[latestPosition.Position.Id];
				if (positionMarker !== undefined) {
					let latLng = positionMarker.getLatLng?.();

					if (!latLng) {
						const lngLat = positionMarker.geometry.toCRS("EPSG:4326").coords;
						latLng = [lngLat[1], lngLat[0]];
					}

					if (map.getBounds().contains(latLng)) {
						positionMarker.fire(e.type);
					}
				}
			}
		} else {
			// take to most recent position
			if (trkData.history.positionsByAssetId[asset.Id] !== undefined) {
				var firstPosition = _.first(trkData.history.positionsByAssetId[asset.Id].Positions);
				if (firstPosition !== undefined) {
					var positionMarker = trkData.history.markersByPositionId[firstPosition.Id];
					if (positionMarker !== undefined) {
						if (map.getBounds().contains(positionMarker.getLatLng())) {
							positionMarker.fire(e.type);
						}
					}
				}
			}
		}
	});

	assetsContainer.on("click", "a.asset-name,div.asset-name", function (e) {
		e.preventDefault();
		var assetNode = this.parentNode.parentNode.parentNode;
		var thisAssetId = parseInt(assetNode.getAttribute("data-asset-id"));
		//expandItemNotificationList(assetNode, false);
		var asset = findAssetById(thisAssetId);
		itemClickedInPrimaryPanel(asset, "assets");
		//toggleAssetNotificationList(assetNode, true);

		// if invisible, do nothing? (or auto-make visible?)
		if (_.indexOf(trkData.visible.assets, thisAssetId) === -1) {
			return;
		}

		if (state.activeMapMode === mapModes.LIVE) {
			// take to latest position
			var latestPosition = trkData.live.latestPositionsByAssetId[asset.Id];
			if (latestPosition !== undefined && latestPosition.Position !== undefined && latestPosition.Position !== null) {
				var positionMarker = trkData.live.markersByPositionId[latestPosition.Position.Id];
				if (positionMarker !== undefined) {
					markerClick(positionMarker, "position", null, false);
				}
			}
		} else {
			// take to most recent position
			if (trkData.history.positionsByAssetId[asset.Id] !== undefined) {
				var firstPosition = _.first(trkData.history.positionsByAssetId[asset.Id].Positions);
				if (firstPosition !== undefined) {
					var positionMarker = trkData.history.markersByPositionId[firstPosition.Id];
					if (positionMarker !== undefined) {
						markerClick(positionMarker, "position", null, false);
					}
				}
			}
		}
	});

	// asset/fence show/hide checkboxes
	assetsContainer.on("click", "input.showhide,svg.showhide", function (e) {
		var $item = $(this);
		var assetId = $item.data("assetId");
		var fenceId = $item.data("fenceId");
		var placeId = $item.data("placeId");
		var groupId = $item.data("groupId");
		var tripId = $item.data("tripId");
		var journeyId = $item.data("journeyId");
		var wasActive = $item.hasClass("active") || $item.hasClass("indeterminate");
		var isNowActive = !wasActive;
		//var isChecked = $item.prop('checked');
		if (assetId != null) {
			toggleAssetActive(assetId, isNowActive, true);
		} else if (fenceId != null) {
			toggleFenceActive(fenceId, isNowActive, true);
		} else if (placeId != null) {
			togglePlaceActive(placeId, isNowActive, true);
		} else if (tripId != null && journeyId != null) {
			toggleTripActive(journeyId, tripId, isNowActive, true);
		} else if (groupId != null) {
			switch (groupId) {
				case "all-assets":
					// toggle all assets
					_.each(trkData.assets, function (asset) {
						toggleAssetActive(asset.Id, isNowActive, false);
					});

					// update group visibility statuses
					_.each(trkData.groups, function (group) {
						updateGroupVisibilityStatus(group.Id);
					});
					updateGroupVisibilityStatus("all-assets");

					// update UI for asset count now
					updateActiveAssetInformation();

					//// uncheck all groups if inactive
					//if (!isChecked) {
					//    _.each(trkData.groups, function (group) {
					//        $('#group-' + group.Id).children('input.showhide').prop('checked', false);
					//    });
					//}
					break;
				case "all-fences":
					// toggle all fences
					_.each(trkData.fences, function (fence) {
						toggleFenceActive(fence.Id, isNowActive, false);
					});
					updateGroupVisibilityStatus("all-fences");
					break;
				case "all-places":
					// toggle all places
					_.each(trkData.places, function (place) {
						togglePlaceActive(place.Id, isNowActive, false);
					});
					updateGroupVisibilityStatus("all-places");
					break;
				default:
					console.log(groupId);
					console.log(isNowActive);
					toggleGroupActive(groupId, isNowActive);
					updateGroupVisibilityStatus(groupId);
					updateGroupVisibilityStatus("all-assets");
					updateActiveAssetInformation();
					break;
			}
		}
	});

	assetsContainer.on("click", "a.mailbox", function (e) {
		e.preventDefault();
		var asset = findAssetById($j(this).parent().parent().data("assetId"));
		loadMessageHistoryDialog(asset);
	});

	assetsContainer.on("click", "div.status a", function (e) {
		e.preventDefault();
	});

	assetsContainer.on("click", "svg.waypoint", function (e) {
		e.preventDefault();
		var thisWaypointId = parseInt(this.getAttribute("data-waypoint-id"));
		var waypoint = _.find(trkData.waypoints, function (o) { return o.Id === thisWaypointId });
		if (waypoint !== undefined) {
			var isCurrentlyActive = !isItemIncluded(user.displayPreferences.hiddenAssets, waypoint.AssetId);
			if (isCurrentlyActive) {
				markerClick(waypoint.Location.marker, "waypoint", null, false);
			}
		}
	});

	assetsContainer.on("click", ".group-info", function (e) {
		// pan/zoom to visible assets/fences/places within group
		var parentNode = this.parentNode.parentNode;
		var groupId = parentNode.getAttribute("id").substring(6);
		var groupType = parentNode.getAttribute("data-group-for");
		var bounds = L.latLngBounds();

		// toggle expand/contract for groups only
		toggleGroupExpanded(groupId);

		switch (groupType) {
			case "assets":
				var groupAssetIds = [];
				if (groupId == "all-assets") {
					groupAssetIds = _.map(trkData.assets, "Id");
				} else {
					var group = findGroupById(groupId);
					if (group !== null) {
						groupAssetIds = findAssetIdsUnderGroup(group);

						itemClickedInPrimaryPanel(group, "groups");
					}
				}
				if (groupAssetIds.length === 0) {
					return;
				}

				_.each(groupAssetIds, function (assetId, index, list) {
					// don't include in bounds if it's not visible
					if (_.indexOf(trkData.visible.assets, assetId) === -1) {
						return;
					}

					if (state.activeMapMode === mapModes.LIVE) {
						// include latest position
						if (trkData.live.latestPositionsByAssetId[assetId] !== undefined) {
							bounds.extend(
								L.latLng(
									trkData.live.latestPositionsByAssetId[assetId].Position.Lat,
									trkData.live.latestPositionsByAssetId[assetId].Position.Lng
								)
							);
						}
					} else {
						// include all positions for asset
						if (trkData.history.positionsByAssetId[assetId] !== undefined) {
							_.each(trkData.history.positionsByAssetId[assetId].Positions, function (position) {
								bounds.extend(L.latLng(position.Lat, position.Lng));
							});
						}
					}
				});
				break;
			case "journeys":
				var journeyId = parseInt(groupId.substring(8));
				var journey = findJourneyById(journeyId);
				_.each(journey.Trips, function (trip) {
					if (_.indexOf(trkData.visible.trips, trip.Id) === -1) {
						return;
					}

					if (trkData.trips.positionsByTripId[trip.Id] !== undefined) {
						_.each(trkData.trips.positionsByTripId[trip.Id].Positions, function (position) {
							bounds.extend(L.latLng(position.Lat, position.Lng));
						});
					}
				});

				break;
			case "fences":
				var groupFenceIds = [];
				if (groupId == "all-fences") {
					groupFenceIds = _.map(trkData.fences, "Id");
				} else {
					// // todo: support fence groups
					// var group = findGroupById(groupId);
					// if (group !== null) {
					// 	groupFenceIds = findFenceIdsUnderGroup(group);
					// }
				}
				if (groupFenceIds.length === 0) {
					return;
				}
				_.each(groupFenceIds, function (fenceId, index, list) {
					// don't include in bounds if it's not visible
					if (_.indexOf(trkData.visible.fences, fenceId) === -1) {
						return;
					}

					var fenceMarkers = _.filter(trkData.fenceMarkers, function (item) {
						return item.data.fenceId == fenceId;
					});
					_.each(fenceMarkers, function (fenceMarker) {
						bounds.extend(fenceMarker.getBounds());
					});
				});
				break;
			case "places":
				var groupPlaceIds = [];
				if (groupId == "all-places") {
					groupPlaceIds = _.map(trkData.places, "Id");
				} else {
					// todo: support place groups
					var group = findGroupById(groupId);
					if (group !== null) {
						//groupPlaceIds = findPlaceIdsUnderGroup(group);
					}
				}
				if (groupPlaceIds.length === 0) {
					return;
				}

				var visiblePlaces = _.filter(trkData.places, function (place) {
					return _.indexOf(trkData.visible.places, place.Id) !== -1;
				});
				_.each(visiblePlaces, function (place) {
					if (place.Location.marker !== undefined) {
						bounds.extend(place.Location.marker.getLatLng());
					}
				});
				break;
			default:
				console.log("no group handler for: " + groupType);
				break;
		}

		// setmap bounds to bounds
		if (bounds.isValid()) {
			map.fitBounds(bounds, { padding: [10, 10] });
			if (map.getZoom() > options.maximumZoom) {
				map.setZoom(options.maximumZoom);
			}
		}
	});

	assetsContainer.on("click", "svg.group-toggle,.item-count", function (e) {
		e.preventDefault();
		var groupId = this.parentNode.parentNode.parentNode.getAttribute("id").substring(6);
		toggleGroupExpanded(groupId);
	});
}

function itemClickedInPrimaryPanel(item, itemType) {
	if (!state.openPanels.secondary) {
		return;
	}
	var isHandled = false;
	var panel = domNodes.panels.secondary;
	var panelGroup = panel.getAttribute("data-group-for");
	if (panelGroup === "dialog") {
		var selector = document.getElementById("panel-secondary-header");
		var dialogGroup = selector.getAttribute("data-group-for");
		if (dialogGroup === itemType) {
			var dialogItems = selector.querySelector("#panel-secondary-item-options");
			var dialogItem = dialogItems.querySelector('option[value="' + item.Id + '"]');
			if (dialogItem !== null && dialogItem.disabled === false) {
				var callback = domNodes.panels.secondary.nextItemCallback;
				if (callback !== undefined && callback !== null) {
					callback(item);
					isHandled = true;
				}
			}
		}
	}
	if (!isHandled) {
		switch (itemType) {
			case "assets":
				openAssetSettingsPanel(item);
				break;
			case "groups":
				openAssetGroupSettingsPanel(item);
				break;
			case "fences":
				openFenceSettingsPanel(item);
				break;
			case "places":
				openPlaceSettingsPanel(item);
				break;
			case "trips":
				break;
			case "shared-views":
				openSharedViewSettingsPanel(item);
				break;
			default:
				console.warn("No default item action for: " + itemType);
				break;
		}
	}
}

function toggleGroupActive(groupId, makeGroupActive) {
	$j("#group-" + groupId + " input.showhide.group").prop("checked", makeGroupActive);
	// toggle assets on/off for this group
	// loop through assets in group
	for (var i = 0; i < trkData.assets.length; i++) {
		var asset = trkData.assets[i];
		for (var j = 0; j < asset.GroupIds.length; j++) {
			if (asset.GroupIds[j] === groupId) {
				// todo: use a deferred method to update the UI
				toggleAssetActive(asset.Id, makeGroupActive, false);
				break;
			}
		}
	}

	// journeys
	if (groupId.indexOf("journey-") === 0) {
		var journeyId = parseInt(groupId.substring(8));
		var journey = findJourneyById(journeyId);
		if (journey !== null) {
			_.each(journey.Trips, function (trip) {
				toggleTripActive(journey.Id, trip.Id, makeGroupActive, false);
			});
		}
	}

	// loop through subgroups
	for (var i = 0; i < trkData.groups.length; i++) {
		if (trkData.groups[i].ParentGroupId == groupId) {
			toggleGroupActive(trkData.groups[i].Id, makeGroupActive);
		}
	}
}
