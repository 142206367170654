import trkData from "./data.js";
import options from "./options.js";
import preferences from "./preferences.js";
import state from "./state.js";
import { map, onTileLoaded } from "./map-base.js";
import { intervals } from "./timers.js";

import $ from "jquery";
import $j from "jquery";
import L from "leaflet";

export function initMapTypes() {
	hemaOverlay.on("tileload", onTileLoaded("hema"));
	moroccoOverlay.on("tileload", onTileLoaded("morocco"));
	sentinel2SatelliteOverlay.on("tileload", onTileLoaded("satellitealt"));

	$j("#root").on("mouseleave", "#map_type", function (e) {
		// clear previous timeout, settimeout to half a second
		intervals.mapType = setTimeout(collapseMapType, 1000);
	});

	$j("#root").on("mouseenter", "#map_type", function (e) {
		clearTimeout(intervals.mapType);
	});

	$("#map-type").on("click", "#map-type-labels-show", function (e) {
		if (this.checked) {
			trkData.isSatelliteLabelOverlayEnabled = true;
			changeMapType(state.currentMapType);
		} else {
			trkData.isSatelliteLabelOverlayEnabled = false;
			changeMapType(state.currentMapType);
		}
	});

	$j("#map-type").on("click", ".road", function (e) {
		e.preventDefault();
		changeMapType("road");
	});

	$j("#map-type").on("click", ".roadlight", function (e) {
		e.preventDefault();
		changeMapType("roadlight");
	});

	$j("#map-type").on("click", ".roaddark", function (e) {
		e.preventDefault();
		changeMapType("roaddark");
	});

	$j("#map-type").on("click", ".satellite", function (e) {
		e.preventDefault();
		changeMapType("satellite");
	});

	$j("#map-type").on("click", ".satellitealt", function (e) {
		e.preventDefault();
		changeMapType("satellitealt");
	});

	$j("#map-type").on("click", ".openstreetmap", function (e) {
		e.preventDefault();
		changeMapType("openstreetmap");
	});

	$j("#map-type").on("click", ".terrain", function (e) {
		e.preventDefault();
		changeMapType("terrain");
	});

	$j("#map-type").on("click", ".bing", function (e) {
		e.preventDefault();
		changeMapType("bing");
	});

	$j("#map_type").on("click", "#map_type_select", function (e) {
		e.preventDefault();
		if ($j("#map_type_list:visible").length > 0) {
			collapseMapType();
		} else {
			expandMapType();
		}
	});
}

// todo: remove as it does not exist anymore
const moroccoOverlay = L.tileLayer(
	"http://api.navcities.com/tilecache/tilecache.cgi/1.0.0/navcities31v2/{z}/{x}/{y}.png?type=google",
	{ zIndex: 2 }
);

const hemaOverlay = L.tileLayer("http://skippy.hema-labs.com/AUS/ExplorerMap_v1_2/{z}/{y}/{x}.png", {
	minZoom: 5,
	maxZoom: 14,
	bounds: L.latLngBounds([10, 95], [-50, 180]),
	zIndex: 2,
});

const sentinel2SatelliteOverlay = L.tileLayer.wms("https://{s}.tiles.maps.eox.at/wms?", {
	subdomains: ["a", "b", "c", "d", "e"],
	layers: "s2cloudless",
	format: "image/jpeg",
	crs: L.CRS.EPSG4326,
	attribution:
		"Sentinel-2 cloudless &copy; https://s2maps.eu by EOX IT Services GmbH (Contains modified Copernicus Sentinel data 2016 & 2017)",
	zIndex: 2,
});

export const baseTileLayers = [hemaOverlay, moroccoOverlay, sentinel2SatelliteOverlay];

export function changeMapType(type) {
	if (map == null) return;
	if (type == "road" && options.enableHemaMap) {
		type = "hema";
	} else if (preferences.PREFERENCE_MOROCCO_OVERLAY) {
		if (type != "satellite" && type != "satellitealt") {
			type = "morocco";
		}
	}
	state.currentMapType = type;
	$("#map-type-list a").removeClass("active");

	// bounds, minZoom, maxZoom
	switch (type) {
		case "satellitealt":
			var thisBaseLayer = sentinel2SatelliteOverlay;

			map.setMaxBounds(options.maxBounds);
			map.setMaxZoom(22);
			map.setMinZoom(options.minimumZoom);
			if (!map.hasLayer(thisBaseLayer)) {
				map.addLayer(thisBaseLayer);
			}
			for (var i = 0; i < baseTileLayers.length; i++) {
				var baseLayer = baseTileLayers[i];
				if (map.hasLayer(baseLayer) && baseLayer != thisBaseLayer) {
					map.removeLayer(baseLayer);
				}
			}

			var satelliteAlt = $("#map-type-list a.satellitealt").addClass("active");
			$("#map-type-current").text(satelliteAlt.text());
			break;

		case "satellite":
			map.setMaxBounds(options.maxBounds);
			map.setMaxZoom(20);
			map.setMinZoom(options.minimumZoom);

			var thisBaseLayer = options.baseLayers.satellitenolabels;
			if (trkData.isSatelliteLabelOverlayEnabled && !options.isSatelliteLabelsOverlay) {
				thisBaseLayer = options.baseLayers.satellitelabels;
			}

			if (!map.hasLayer(thisBaseLayer.layer)) {
				map.addLayer(thisBaseLayer.layer);
			}
			if (options.isSatelliteLabelsOverlay && trkData.isSatelliteLabelOverlayEnabled) {
				// add any label overlay layers
				if (!map.hasLayer(options.baseLayers.satellitelabels.layer)) {
					map.addLayer(options.baseLayers.satellitelabels.layer);
				}
				if (options.baseLayers.satellitelabelsadditional !== undefined) {
					if (!map.hasLayer(options.baseLayers.satellitelabelsadditional.layer)) {
						map.addLayer(options.baseLayers.satellitelabelsadditional.layer);
					}
				}
			}
			for (var i = 0; i < baseTileLayers.length; i++) {
				var baseLayer = baseTileLayers[i];
				if (options.isSatelliteLabelsOverlay && trkData.isSatelliteLabelOverlayEnabled) {
					if (
						map.hasLayer(baseLayer) &&
						baseLayer != options.baseLayers.satellitelabels.layer &&
						options.baseLayers.satellitelabelsadditional !== undefined &&
						baseLayer != options.baseLayers.satellitelabelsadditional.layer &&
						baseLayer != options.baseLayers.satellitenolabels.layer
					) {
						map.removeLayer(baseLayer);
					}
				} else {
					if (map.hasLayer(baseLayer) && baseLayer != thisBaseLayer.layer) {
						map.removeLayer(baseLayer);
					}
				}
			}

			var satellite = $("#map-type-list a.satellite").addClass("active");
			$("#map-type-current").text(satellite.text());
			if (!preferences.PREFERENCE_REMOVE_ROADS && !preferences.PREFERENCE_MOROCCO_OVERLAY) {
				$("#map-type-labels").show();
			}
			document.getElementById("map-type-labels-show").checked = trkData.isSatelliteLabelOverlayEnabled;
			break;
		case "morocco":
			var thisBaseLayer = moroccoOverlay;

			map.setMaxBounds(options.maxBounds);
			map.setMaxZoom(18);
			map.setMinZoom(options.minimumZoom);
			if (!map.hasLayer(thisBaseLayer)) {
				map.addLayer(thisBaseLayer);
			}

			var road = $("#map-type-list a.road").addClass("active");
			$("#map-type-current").text(road.text());
			$("#map-type-labels").hide();
			break;
		case "hema":
			var thisBaseLayer = hemaOverlay;
			map.setMaxBounds(L.latLngBounds([10, 95], [-50, 180]));
			map.setMaxZoom(14);
			map.setMinZoom(options.minimumZoom > 5 ? options.minimumZoom : 5);
			if (!map.hasLayer(thisBaseLayer)) {
				map.addLayer(thisBaseLayer);
			}
			// keep osm overlay?
			var hema = $("#map-type-list a.road").addClass("active");
			$("#map-type-current").text(hema.text());
			$("#map-type-labels").hide();
			break;
		case "road":
		default:
			var thisBaseLayer = options.baseLayers[type];
			map.setMaxBounds(options.maxBounds);
			map.setMaxZoom(thisBaseLayer.maxZoom);
			map.setMinZoom(options.minimumZoom > thisBaseLayer.minZoom ? options.minimumZoom : thisBaseLayer.minZoom);
			if (!map.hasLayer(thisBaseLayer.layer)) {
				map.addLayer(thisBaseLayer.layer);
			}
			for (var i = 0; i < baseTileLayers.length; i++) {
				var baseLayer = baseTileLayers[i];
				if (map.hasLayer(baseLayer) && baseLayer != thisBaseLayer.layer) {
					map.removeLayer(baseLayer);
				}
			}
			var custom = $("#map-type-list a." + type).addClass("active");
			$("#map-type-current").text(custom.text());
			$("#map-type-labels").hide();
			break;
	}
	// workaround for bug with GL layers that initialize with offset base map
	map.flyToBounds(map.getBounds());
}

function collapseMapType() {
	$j("#map_type_list").hide();
	$j("body").off("click.maptype");
}

function expandMapType() {
	$j("#map_type_list").show();

	$j("body").on("click.maptype", function (e) {
		if ($(e.target).parents("#map_type").length == 0) {
			collapseMapType();
		}
	});
}
