import trkData from "./data.js";
import { viewModes } from "./const.js";
import user from "./user.js";

import _ from "lodash";
import moment from "moment"; // https://www.npmjs.com/package/moment

export function updateLimitedDataResults(viewMode) {
	var source = null;
	if (viewMode === viewModes.NORMAL) {
		source = trkData.history;
	} else if (viewMode === viewModes.SHARED_VIEW) {
		source = trkData.sharedView;
	}
	if (source === null || source.limitedData === undefined || source.limitedData === null) {
		return;
	}

	// update UI
	// reverse paging system
	var lastVisibleNumber =
		source.limitedData.counts.Positions - source.limitedData.limit * (source.limitedData.currentPage - 1);
	var firstVisibleNumber = lastVisibleNumber - source.limitedData.limit + 1;
	if (firstVisibleNumber < 1) {
		firstVisibleNumber = 1;
	}

	document.getElementById("limited-data-range").textContent = "#" + firstVisibleNumber + " - #" + lastVisibleNumber;
	document.getElementById("limited-data-from").textContent =
		source.limitedData.pageDates[source.limitedData.currentPage].visibleFromLocal;
	document.getElementById("limited-data-to").textContent =
		source.limitedData.pageDates[source.limitedData.currentPage].visibleToLocal;

	// enable/disable paging buttons
	if (source.limitedData.currentPage === source.limitedData.pages) {
		document.getElementById("limited-data-prev").disabled = true;
		document.getElementById("limited-data-prev").classList.add("disabled");
	} else {
		document.getElementById("limited-data-prev").disabled = false;
		document.getElementById("limited-data-prev").classList.remove("disabled");
	}
	if (source.limitedData.currentPage === 1) {
		document.getElementById("limited-data-next").disabled = true;
		document.getElementById("limited-data-next").classList.add("disabled");
	} else {
		document.getElementById("limited-data-next").disabled = false;
		document.getElementById("limited-data-next").classList.remove("disabled");
	}
}

export function handleLimitedDataResult(viewMode, fromDateUtc, toDateUtc, counts, limit) {
	var source = null;
	if (viewMode === viewModes.NORMAL) {
		source = trkData.history;
	} else if (viewMode === viewModes.SHARED_VIEW) {
		source = trkData.sharedView;
	}
	if (source === null) {
		return;
	}

	var orderedPositions = _.sortBy(source.normalizedPositions, "Epoch");
	var firstVisible = _.find(orderedPositions, function (item) {
		return !item.Position.IsHidden;
	});
	var lastVisible = _.find(orderedPositions.reverse(), function (item) {
		return !item.Position.IsHidden;
	});
	var firstVisibleTime = null;
	var firstVisibleTimeLocal = null;
	var lastVisibleTime = null;
	var lastVisibleTimeLocal = null;
	if (firstVisible !== undefined) {
		firstVisibleTime = moment(firstVisible.Epoch * 1000)
			.subtract(user.tickOffset, "ms")
			.format(user.dateWithStandardTimeFormat);
		firstVisibleTimeLocal = firstVisible.Position.Time;
	}
	if (lastVisible !== undefined) {
		lastVisibleTime = moment(lastVisible.Epoch * 1000)
			.subtract(user.tickOffset, "ms")
			.format(user.dateWithStandardTimeFormat);
		lastVisibleTimeLocal = lastVisible.Position.Time;
	}
	if (source.limitedData === null) {
		// TODO set visible-positions-history.textContent to counts.Positions
		var pages = Math.ceil(counts.Positions / limit);

		source.limitedData = {
			counts: counts,
			currentPage: 1,
			pages: pages,
			fromDateUtc: fromDateUtc,
			fromDateEpoch:
				fromDateUtc !== null && fromDateUtc !== ""
					? moment.utc(fromDateUtc, user.dateWithStandardTimeFormat).valueOf()
					: null,
			toDateUtc: toDateUtc,
			toDateEpoch:
				toDateUtc !== null && toDateUtc !== ""
					? moment.utc(toDateUtc, user.dateWithStandardTimeFormat).valueOf()
					: null,
			pageDates: {},
			limit: limit,
		};
		// TODO proper timezone support
		source.limitedData.fromDate =
			source.limitedData.fromDateEpoch !== null
				? moment(source.limitedData.fromDateEpoch).subtract(user.tickOffset).format(user.dateFormat)
				: null;
		source.limitedData.toDate =
			source.limitedData.toDateEpoch !== null
				? moment(source.limitedData.toDateEpoch).subtract(user.tickOffset).format(user.dateFormat)
				: null;
	}

	source.limitedData.visibleFromDateEpoch = firstVisible !== undefined ? firstVisible.Epoch * 1000 : null; // TODO use an incrementing counter instead of time
	source.limitedData.visibleToDateEpoch = lastVisible !== undefined ? lastVisible.Epoch * 1000 : null; // TODO use an incrementing counter instead of time
	source.limitedData.visibleFrom = firstVisibleTime;
	source.limitedData.visibleFromLocal = firstVisibleTimeLocal;
	source.limitedData.visibleTo = lastVisibleTime;
	source.limitedData.visibleToLocal = lastVisibleTimeLocal;
	source.limitedData.pageDates[source.limitedData.currentPage] = {
		fromUtc: fromDateUtc,
		toUtc: toDateUtc,
		visibleFrom: firstVisibleTime,
		visibleFromLocal: firstVisibleTimeLocal,
		//visibleFrom: (source.limitedData.currentPage !== source.limitedData.pages ? firstVisibleTime : null),
		visibleFromUtc: moment.utc(firstVisible.Epoch * 1000).format(user.dateWithStandardTimeFormat),
		//visibleFromUtc: (source.limitedData.currentPage !== source.limitedData.pages ? moment.utc(firstVisible.Epoch * 1000).format(user.dateWithStandardTimeFormat) : null),
		visibleFromEpoch: firstVisible.Epoch * 1000,
		//visibleFromEpoch: (source.limitedData.currentPage !== source.limitedData.pages ? (firstVisible.Epoch * 1000) : null),
		visibleTo: lastVisibleTime,
		visibleToLocal: lastVisibleTimeLocal,
		//visibleTo: (source.limitedData.currentPage !== 1 ? lastVisibleTime : null),
		visibleToUtc: moment.utc(lastVisible.Epoch * 1000).format(user.dateWithStandardTimeFormat),
		//visibleToUtc: (source.limitedData.currentPage !== 1 ? moment.utc(lastVisible.Epoch * 1000).format(user.dateWithStandardTimeFormat) : null),
		visibleToEpoch: lastVisible.Epoch * 1000,
		//visibleToEpoch: (source.limitedData.currentPage !== 1 ? (lastVisible.Epoch * 1000) : null)
	};
	updateLimitedDataResults(viewMode);
}
