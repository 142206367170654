import strings from "./strings.js";
import trkData from "./data.js";
import preferences, { convertToLatLngPreference, distanceText, fuelText, speedText } from "./preferences.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import { handleAjaxFormSubmission, formShowErrorMessage, formShowSuccessMessage } from "./ajax.js";
import { renderDomElement } from "./datatables.js";
import user from "./user.js";
import { createIOOutput } from "./position-information.js";
import { fuelEfficiencyType, convertFuelEfficiencyToStandard } from "./preferences.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import { el, svg } from "redom"; // https://redom.js.org/

export function loadWaypointHistory(asset) {
	var btn = document.getElementById("RefreshWaypoints");
	var status = document.getElementById("waypoint-history-status");
	var data = { assetId: asset.Id };

	handleAjaxFormSubmission(
		"GetWaypointHistoryForAsset",
		data,
		btn,
		status,
		null,
		strings.MSG_IO_HISTORY_ERROR,
		function (result) {
			if (result.Success == true) {
				var itemData = [];
				for (var i = 0; i < result.Waypoints.length; i++) {
					var item = result.Waypoints[i];
					itemData.push([
						item.Name,
						convertToLatLngPreference(item.Lat, item.Lng, item.Grid),
						item.Message,
						item.CreatedOn,
						item.Status,
						item.ETA,
						item.DistanceTo,
						item.UpdatedOn,
						el(
							"button.delete-waypoint.btn.btn-outline-danger",
							{ title: strings.DELETE, dataset: { waypointId: item.Id } },
							svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#trash-alt-light" } }))
						),
						item.Id,
					]);
				}

				$("#WaypointHistory").dataTable({
					data: itemData,
					destroy: true,
					filter: false,
					info: false,
					jQueryUI: false,
					autoWidth: false,
					lengthChange: false,
					paging: true,
					pageLength: 5,
					deferRender: true,
					order: [[3, "desc"]],
					columnDefs: [
						{
							targets: "_all",
							render: $.fn.dataTable.render.text(),
						},
					],
					columns: [
						{ sortable: false }, // name
						{ sortable: false }, // lat/lng
						{ sortable: false }, // message
						{ width: "75px" }, // createdon
						{}, // status
						{}, // eta
						{ sortable: false }, // distance to
						{ width: "75px" }, // updatedon
						{ sortable: false, class: "center", render: renderDomElement },
					],
					language: strings.DATATABLE,
				});

				$("#WaypointHistory").removeAttr("style");
			} else {
				formShowErrorMessage(status, strings.MSG_IO_HISTORY_ERROR);
			}
		},
		null
	);
}

export function loadIOHistory(asset) {
	toggleLoadingMessage(true, "io-history");
	var data = { assetId: asset.Id };
	$j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetIOHistoryForAsset"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					var gpioData = [];
					for (var i = 0; i < result.GPIOs.length; i++) {
						var item = result.GPIOs[i];
						var settings = createIOOutput(asset, item);
						var address = null;
						if (item.Position != null) {
							address = item.Position.Address;
							if (address == null || address.trim() == "" || asset.HideAddress) {
								address = convertToLatLngPreference(
									item.Position.DisplayLat,
									item.Position.DisplayLng,
									item.Position.Grid
								);
							}
							address = el("a.location", { href: "#", dataset: { marker: item.Position.Id } }, address);
						}
						gpioData.push([el("pre", settings), address, item.CreatedOn]);
					}

					$("#IOHistory").dataTable({
						data: gpioData,
						destroy: true,
						filter: false,
						info: false,
						jQueryUI: true,
						autoWidth: false,
						lengthChange: false,
						paging: true,
						pageLength: 5,
						deferRender: true,
						order: [[2, "desc"]],
						columnDefs: [
							{
								targets: "_all",
								render: $.fn.dataTable.render.text(),
							},
						],
						columns: [
							{ sortable: false, render: renderDomElement }, // text
							{ sortable: false, render: renderDomElement }, // position
							{ width: "75px" }, // time
						],
						language: strings.DATATABLE,
					});

					$("#IOHistory").removeAttr("style");

					trkData.io = result.GPIOs;
				} else {
					// output result.ErrorMessage
				}
				//dialog.dialog('open');
			}
			toggleLoadingMessage(false, "io-history");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_IO_HISTORY_ERROR);
			toggleLoadingMessage(false, "io-history");
		},
	});
}

export function loadServiceMeterHistory(asset) {
	toggleLoadingMessage(true, "service-meter-history");
	var data = { assetId: asset.Id };
	$j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetServiceMeterHistoryForAsset"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					var meters = [];
					for (var i = 0; i < result.ServiceMeters.length; i++) {
						var meter = result.ServiceMeters[i];
						meters.push([
							meter.SM0Time == null ? "-" : meter.SM0Time,
							meter.SM0Distance == null ? "-" : meter.SM0Distance,
							meter.SM1Time == null ? "-" : meter.SM1Time,
							meter.SM1Distance == null ? "-" : meter.SM1Distance,
							meter.SM2Time == null ? "-" : meter.SM2Time,
							meter.SM2Distance == null ? "-" : meter.SM2Distance,
							meter.SM3Time == null ? "-" : meter.SM3Time,
							meter.SM3Distance == null ? "-" : meter.SM3Distance,
							meter.SM4Time == null ? "-" : meter.SM4Time,
							meter.SM4Distance == null ? "-" : meter.SM4Distance,
							meter.Odometer == null ? "-" : meter.Odometer,
							meter.Time,
						]);
					}

					$("#ServiceMeterHistory").dataTable({
						data: meters,
						destroy: true,
						filter: false,
						info: false,
						jQueryUI: true,
						autoWidth: false,
						lengthChange: false,
						paging: true,
						pageLength: 10,
						order: [[11, "desc"]],
						columnDefs: [
							{
								targets: "_all",
								render: $.fn.dataTable.render.text(),
							},
						],
						columns: [
							{ class: "center" },
							{ class: "center" },
							{ class: "center" },
							{ class: "center" },
							{ class: "center" },
							{ class: "center" },
							{ class: "center" },
							{ class: "center" },
							{ class: "center" },
							{ class: "center" },
							{ class: "center" },
							{ class: "nowrap" },
						],
						language: strings.DATATABLE,
					});
				}
			}
			toggleLoadingMessage(false, "service-meter-history");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.ERROR_SERVICE_METER_HISTORY);
			// re-enable show button and clear loading message
			toggleLoadingMessage(false, "service-meter-history");
		},
	});
}

export function loadFillupHistory(asset) {
	toggleLoadingMessage(true, "fillup-history");
	var data = { assetId: asset.Id };
	$j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetFillupHistoryForAsset"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					var fuelData = [];
					for (var i = 0; i < result.Fillups.length; i++) {
						var fillup = result.Fillups[i];
						var odometer = "";
						var odometerdiff = "";
						var efficiency = "";
						var efficiencydiff = "";
						if (result.Fillups[i].Odometer != null) {
							odometer = fillup.Odometer + " " + distanceText();
						}
						if (fillup.OdometerDifference != null) {
							odometerdiff = fillup.OdometerDifference + " " + distanceText();
						}
						if (fillup.FuelEfficiency != null) {
							var actualeff = $j.parseFloat(
								convertFuelEfficiencyToStandard(fillup.FuelEfficiencyL100KM, preferences.PREFERENCE_FUEL_UNIT)
							);
							var desiredeff = $j.parseFloat(
								convertFuelEfficiencyToStandard($j.parseFloat(asset.FuelEfficiency), preferences.PREFERENCE_FUEL_UNIT)
							);
							efficiency = actualeff + " " + fuelEfficiencyType(preferences.PREFERENCE_FUEL_UNIT);
							if (asset.FuelEfficiency != "") {
								// L/100km negative is positive and vice-versa
								efficiencydiff = ((1 - actualeff / desiredeff) * 100 * -1).toFixed(2);
								if (preferences.PREFERENCE_FUEL_UNIT == 3) {
									efficiencydiff *= -1;
								}
								if (efficiencydiff > 0) {
									efficiencydiff = "+" + efficiencydiff;
								}
								efficiencydiff += "%";
							}
						}
						var source = null;
						if (fillup.PositionId != null) {
							source = el("a.location", { href: "#", dataset: { marker: fillup.PositionId } }, strings.ASSET);
						} else {
							if (fillup.CreatedBy != "") {
								source = el("span", fillup.CreatedBy);
							}
						}
						fuelData.push([
							result.Fillups[i].Fuel.toFixed(2) + " " + fuelText(),
							odometer,
							odometerdiff,
							efficiency,
							efficiencydiff,
							source,
							result.Fillups[i].Time,
							el(
								"button.delete-refuel.btn.btn-secondary",
								{
									dataset: { refuelId: result.Fillups[i].Id },
									ariaLabel: strings.DELETE,
									title: strings.DELETE,
								},
								svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#trash-alt" } }))
							),
						]);
					}

					if (user.isAnonymous) {
						$j("#form-add-refuel fieldset").hide();
					}

					$("#RefuelHistory").dataTable({
						data: fuelData,
						destroy: true,
						filter: false,
						info: false,
						jQueryUI: true,
						autoWidth: false,
						lengthChange: false,
						paging: true,
						pageLength: 5,
						order: [[6, "desc"]],
						columnDefs: [
							{
								targets: "_all",
								render: $.fn.dataTable.render.text(),
							},
						],
						columns: [
							{ class: "nowrap" },
							{},
							{ visible: !user.isAnonymous }, // ??
							{},
							{},
							{ render: renderDomElement },
							{ class: "nowrap" },
							{ sortable: false, class: "center", render: renderDomElement },
						],
						language: strings.DATATABLE,
						drawCallback: function (oSettings) {},
						rowCallback: function (row, data, index) {
							if (data[4] != "") {
								if (data[4].indexOf("-") != -1) {
									$j("td:eq(4)", row).addClass("negative");
								} else {
									$j("td:eq(4)", row).addClass("positive");
								}
							}
						},
					});
				} else {
					// output result.ErrorMessage
				}
			}
			toggleLoadingMessage(false, "fillup-history");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_REFUEL_HISTORY_ERROR);
			// re-enable show button and clear loading message
			toggleLoadingMessage(false, "fillup-history");
		},
	});
}
