import trkData from "./data.js";
import strings from "./strings.js";
import { getCurrentMarkerForAsset } from "./assets.js";
import user from "./user.js";
import { devices } from "./devices.js";
import { findAssetById } from "./assets.js";
import { extendBounds } from "./map-bounds.js";
import state from "./state.js";
import domNodes from "./domNodes.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import AssetSprite from "./gleo-asset-sprite.js";
import { markerClick, markerUnhover, markerHover } from "./marker-click.js";
import { waypointMarkers } from "./routing-waypoint.js";
import { isItemIncluded } from "./polyfills.js";

import $ from "jquery";
import $j from "jquery";
import L from "leaflet";
import _ from "lodash";
import { el, svg, text, mount, setChildren } from "redom"; // https://redom.js.org/

export function findWaypointById(id) {
	var item = _.find(trkData.waypoints, { Id: parseInt(id) });
	return item === undefined ? null : item;
}

export function findWaypointByAsset(asset) {
	var item = _.find(trkData.waypoints, { AssetId: asset.Id });
	return item === undefined ? null : item;
}

export function populateWaypointInformation(waypoint, asset) {
	var info = [];
	info.push(el("div#WaypointRequestETASuccess.success", { style: { display: "none" } }, strings.WAYPOINT_REQUESTED));
	info.push(el("div.form-group", [el("label", strings.ASSET), el("div.item", asset.Name)]));
	if (waypoint.Message != null && waypoint.Message !== "") {
		info.push(el("div.form-group", [el("label", strings.MESSAGE), el("div.item", waypoint.Message)]));
	}
	info.push(
		el(
			"div#WaypointDistanceTo",
			{ style: { display: waypoint.DistanceTo != null ? "" : "none" }, dataset: { waypointId: waypoint.Id } },
			el("div.form-group", [el("label", strings.DISTANCE_TO), el("div.item", waypoint.DistanceTo)])
		)
	);
	info.push(
		el(
			"div#WaypointETA",
			{ style: { display: waypoint.ETA != null ? "" : "none" }, dataset: { waypointId: waypoint.Id } },
			el("div.form-group", [el("label", strings.ETA), el("div.item", waypoint.ETA)])
		)
	);
	var isRemoveVisible = waypoint.route != null;
	var getRouteClass = "";
	if (!isRemoveVisible && !canAssetBeRouted(asset)) {
		getRouteClass = ".disabled";
	}

	var buttonItems = [
		el(
			"button#WaypointGetRoute.btn.btn-primary.mr-1" + getRouteClass,
			{ style: { display: isRemoveVisible ? "none" : "" }, dataset: { waypointId: waypoint.Id } },
			[
				svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#compass" } })),
				text(" "),
				el("span", strings.GET_ROUTE),
			]
		),
		el(
			"button#WaypointRemoveRoute.btn.btn-secondary.mr-1",
			{ style: { display: isRemoveVisible ? "" : "none" }, dataset: { waypointId: waypoint.Id } },
			[
				svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#times" } })),
				text(" "),
				el("span", strings.CLEAR_ROUTE),
			]
		),
	];

	// asset have ETA ability? - not for shared users
	if (!user.isAnonymous) {
		if (
			!waypoint.IsServerSide &&
			($.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
				$.inArray(asset.DeviceId, devices.SKYWAVE_IDP) != -1)
		) {
			buttonItems.push(
				el("button#WaypointRequestETAUpdate.btn.btn-secondary", { dataset: { waypointId: waypoint.Id } }, [
					svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#clock-solid" } })),
					text(" "),
					el("span", strings.REQUEST_ETA),
				])
			);
		} else if (waypoint.IsServerSide) {
			buttonItems.push(
				el("button#WaypointMarkComplete.btn.btn-secondary", { dataset: { waypointId: waypoint.Id } }, [
					svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#check" } })),
					text(" "),
					el("span", strings.MARK_COMPLETE),
				])
			);
		}
	}
	info.push(el("div.form-group", buttonItems));
	info.push(
		el(
			"div#WaypointRoute",
			{ style: { display: isRemoveVisible ? "" : "none" }, dataset: { waypointId: waypoint.Id } },
			el("div#WaypointRoutePanel")
		)
	);
	return el("div#waypoint-information", info);
}

export function canAssetBeRouted(asset) {
	var assetMarker = getCurrentMarkerForAsset(asset);
	if (assetMarker === undefined) {
		return false;
	}
	return true;
}

export function updateWaypoint(waypointId) {
	// query waypoint Id
	toggleLoadingMessage(true, "waypoint-update");
	var data = { id: waypointId };
	$j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetWaypointById"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			var result = msg.d;
			if (result) {
				if (result.Success == true) {
					addOrUpdateWaypoint(result.Waypoint);
					updateWaypointListing();
				}
			}
			toggleLoadingMessage(false, "waypoint-update");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_LOAD_WAYPOINT_ERROR);
			toggleLoadingMessage(false, "waypoint-update");
		},
	});
}

export function addOrUpdateWaypoint(updatedWaypoint) {
	var index = null;
	var existingWaypoint = null;
	for (var i = 0; i < trkData.waypoints.length; i++) {
		if (trkData.waypoints[i].Id == updatedWaypoint.Id) {
			index = i;
			existingWaypoint = trkData.waypoints[i];
			break;
		}
	}

	if (existingWaypoint !== null) {
		if (existingWaypoint.Status === "Active") {
			if (updatedWaypoint.Status !== "Active") {
				// remove it from the list of waypoints we're tracking
				trkData.waypoints.splice(index, 1);

				// remove marker from map
				if (waypointMarkers != null) {
					for (var i = 0; i < waypointMarkers.length; i++) {
						// var waypointId = $j.data(waypointMarkers[i], 'waypointId');
						var waypointId = waypointMarkers[i].data.waypointId;
						if (waypointId === updatedWaypoint.Id) {
							removeItemFromMap(waypointMarkers[i]);
							waypointMarkers.splice(i, 1);
							break;
						}
					}
				}

				// clear route
				if (existingWaypoint.route != null) {
					existingWaypoint.routingService.remove();
					existingWaypoint.route = null;
				}

				if (state.openWindow !== null) {
					if ($j(state.openWindow).data("waypointId") === updatedWaypoint.Id) {
						state.openWindow.remove();
					}
				}
			} else {
				// replace with updated waypoint, update WP information, if shown
				var route = existingWaypoint.route;
				var routingService = existingWaypoint.routingService;
				existingWaypoint = updatedWaypoint;
				existingWaypoint.routingService = routingService;
				existingWaypoint.route = route;
			}
		}
	} else {
		// brand new waypoint, only bother with it if it's active
		if (updatedWaypoint.Status !== "Active") {
			return;
		}

		trkData.waypoints.push(updatedWaypoint);
		// add waypoint to map
		addWaypointMarker([updatedWaypoint.Location.Lat, updatedWaypoint.Location.Lng], updatedWaypoint.Location, updatedWaypoint);
	}

	if (updatedWaypoint.DistanceTo !== null) {
		$j("#WaypointDistanceTo[data-waypoint-id=" + updatedWaypoint.Id + "]")
			.show()
			.find("div.item")
			.text(updatedWaypoint.DistanceTo);
	}
	if (updatedWaypoint.ETA !== null) {
		$j("#WaypointETA[data-waypoint-id=" + updatedWaypoint.Id + "]")
			.show()
			.find("div.item")
			.text(updatedWaypoint.ETA);
	}
}

export function addWaypointMarker(latlng, location, waypoint) {
	if (latlng == null) {
		return null;
	}
	extendBounds(latlng);

	var asset = findAssetById(waypoint.AssetId);
	var isCurrentlyActive = !isItemIncluded(user.displayPreferences.hiddenAssets, asset.Id);

	const marker = new AssetSprite(latlng, "Waypoint", asset.Color);
	marker.data = {
		location: null,
		waypointId: null,
	};
	if (isCurrentlyActive) {
		addItemToMap(marker);
	}
	location.marker = marker;
	if (location != null) {
		marker.data.location = location;
	}
	if (waypoint != null) {
		marker.data.waypointId = waypoint.Id;
	}

	marker.on("click", function () {
		markerClick(marker, "waypoint", null, true);
	});
	marker.on("mouseover", function () {
		markerHover(marker);
	});
	marker.on("mouseout", function () {
		markerUnhover(marker);
	});
	waypointMarkers.push(marker);
	return marker;
}

export function updateWaypointListing() {
	_.each(domNodes.assets, function (assetNodes, index, list) {
		_.each(assetNodes, function (assetNode, nodeIndex, nodeList) {
			var waypointIndicators = assetNode.querySelectorAll(".waypoint");
			_.each(waypointIndicators, function (waypointIndicator) {
				if (waypointIndicator !== null) {
					waypointIndicator.parentNode.removeChild(waypointIndicator);
				}
			});
		});
	});
	console.log(trkData.waypoints.length);
	_.each(trkData.waypoints, function (waypoint, index, list) {
		var asset = findAssetById(waypoint.AssetId);

		var assetWaypoint = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		assetWaypoint.classList.add("waypoint");
		assetWaypoint.classList.add("notify-waypoint");
		assetWaypoint.style.fill = asset.Color;
		assetWaypoint.setAttribute("data-waypoint-id", waypoint.Id);
		var assetWaypointTitle = document.createElementNS("http://www.w3.org/2000/svg", "title");
		assetWaypointTitle.textContent = waypoint.Name;
		assetWaypoint.appendChild(assetWaypointTitle);
		var assetWaypointType = document.createElementNS("http://www.w3.org/2000/svg", "use");
		assetWaypointType.setAttributeNS(
			"http://www.w3.org/1999/xlink",
			"href",
			"/content/svg/tracking.svg?v=15#notify-waypoint"
		);
		assetWaypoint.appendChild(assetWaypointType);

		//var assetWaypoint = document.createElement('div');
		//assetWaypoint.className = 'waypoint';
		//assetWaypoint.setAttribute('data-waypoint-id', waypoint.Id);
		//var assetWaypointLink = document.createElement('a');
		//assetWaypointLink.setAttribute('href', '#');
		//var assetWaypointIcon = document.createElement('span');
		//assetWaypointIcon.className = 't-icon t-icon-waypoint';
		//assetWaypointIcon.style.backgroundImage = 'url(' + createMarkerPath('Waypoint', asset.Color, null, null, null, false) + ')';
		//assetWaypointIcon.title = waypoint.Name;
		//assetWaypointLink.appendChild(assetWaypointIcon);
		//assetWaypoint.appendChild(assetWaypointLink);
		_.each(domNodes.assets[asset.Id], function (assetNode) {
			assetNode.querySelector(".asset-indicators").appendChild(assetWaypoint.cloneNode(true));
		});
		assetWaypoint = null;
	});
}
