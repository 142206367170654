import trkData from "./data.js";
import state from "./state.js";
import domNodes from "./domNodes.js";
import log from "./log.js";
import strings from "./strings.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import { startChoosingMapLocation, stopChoosingMapLocation } from "./map-chooselocation.js";
import { createMarkerPath } from "./marker-path.js";
import { convertToLatLngPreference, convertFromMetresToUserDistancePreference } from "./preferences.js";

import $ from "jquery";
import $j from "jquery";
import L from "leaflet";
import { el, mount, text, setChildren } from "redom";

import { GeodesicLine } from "leaflet.geodesic/dist/leaflet.geodesic.esm.js";

export function openRuler() {
	$j("#map-ruler").addClass("active");
	if (trkData.ruler.isOpen) {
		return;
	}
	trkData.ruler.isOpen = true;
	startChoosingMapLocation(state.mapClickHandlers.RULER);
	trkData.ruler.points = [];
	var dialog = $j(domNodes.infoDialogs.ruler);
	dialog.dialog("open");
	$j("#rulerPoints").find("li").remove();
}

export function closeRuler() {
	$j("#map-ruler").removeClass("active");
	if (!trkData.ruler.isOpen) {
		return;
	}
	trkData.ruler.isOpen = false;
	stopChoosingMapLocation(state.mapClickHandlers.RULER);
	for (var i = 0; i < trkData.ruler.points.length; i++) {
		removeItemFromMap(trkData.ruler.points[i]);
	}
	trkData.ruler.points = [];
	if (trkData.ruler.polygon != null) {
		removeItemFromMap(trkData.ruler.polygon);
	}
	trkData.ruler.polygon = null;

	$j("#rulerPoints").find("li").remove();
	$j("#rulerTotalDistance").text("");
}

export function addPointToRuler(latlng) {
	if (!trkData.ruler.isOpen) {
		return;
	}

	var rulerTitle = String.fromCharCode("a".charCodeAt(0) + trkData.ruler.points.length).toUpperCase();
	var pointIcon = L.icon({
		iconUrl: createMarkerPath("Generic", "gold", null, null, null, false),
		iconSize: [36, 36],
		iconAnchor: [18, 18],
	});
	var rulerPoint = L.marker(latlng, { icon: pointIcon, draggable: true, text: rulerTitle });

	trkData.ruler.points.push(rulerPoint);
	addItemToMap(rulerPoint);

	if (trkData.ruler.polygon == null && trkData.ruler.points.length > 1) {
		log("add geodesic line");
		trkData.ruler.polygon = new GeodesicLine([[trkData.ruler.points[0].getLatLng(), trkData.ruler.points[1].getLatLng()]], {
			weight: 5,
			color: "#cea843",
			wrap: true,
			steps: 50,
			opacity: 0.7,
		});
		addItemToMap(trkData.ruler.polygon);
	}

	rulerPoint.on("drag", function (e) {
		updateRuler();
	});

	// add point to ruler dialog
	var point = el("li", [
		text(rulerTitle + " - " + convertToLatLngPreference(rulerPoint.getLatLng().lat, rulerPoint.getLatLng().lng) + " "),
		el("a.delete", { href: "#" }, strings.DELETE),
	]);
	mount(document.getElementById("rulerPoints"), point);
	updateRuler();
}

export function removeRulerPoint(index) {
	if (!trkData.ruler.isOpen) {
		return;
	}
	if (trkData.ruler.points.length === 0) {
		return;
	}
	if (trkData.ruler.polygon == null) {
		return;
	}
	removeItemFromMap(trkData.ruler.points[index]);
	var removed = trkData.ruler.points.splice(index, 1);
	$j("#rulerPoints li").eq(index).remove();
	updateRuler();
}

function updateRuler() {
	if (!trkData.ruler.isOpen) {
		return;
	}
	if (trkData.ruler.polygon == null) {
		return;
	}
	if (trkData.ruler.points.length <= 1) {
		removeItemFromMap(trkData.ruler.polygon);
		$("#rulerTotalDistance").text("");
		return;
	}

	var points = [];
	var rulerPointsContainer = document.getElementById("rulerPoints");
	var totalDistance = 0;
	var rulerPoints = [];
	for (var i = 0; i < trkData.ruler.points.length; i++) {
		var point = trkData.ruler.points[i].getLatLng().wrap();
		trkData.ruler.points[i].setLatLng(point);
		var pointDistance = null;
		points.push(point);
		var rulerTitle = String.fromCharCode("a".charCodeAt(0) + i).toUpperCase();
		if (i > 0) {
			pointDistance = trkData.ruler.points[i - 1].getLatLng().distanceTo(point);
			totalDistance += pointDistance;
		}
		var title = rulerTitle + " - " + convertToLatLngPreference(point.lat, point.lng) + " ";
		if (pointDistance != null) {
			title += "(" + convertFromMetresToUserDistancePreference(pointDistance) + ") ";
		}
		rulerPoints.push(el("li", [title, el("a.delete", { href: "#" }, strings.DELETE)]));
	}
	setChildren(rulerPointsContainer, rulerPoints);
	trkData.ruler.polygon.setLatLngs([points]);
	addItemToMap(trkData.ruler.polygon);

	// update total distance
	if (trkData.ruler.points.length > 1) {
		document.getElementById("rulerTotalDistance").textContent =
			convertFromMetresToUserDistancePreference(totalDistance);
	}
}
