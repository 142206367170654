import strings from "./strings.js";
import user from "./user.js";
import domNodes from "./domNodes.js";
import { mapModes } from "./const.js";

import $ from "jquery";
import _ from "lodash";
import moment from "moment"; // https://www.npmjs.com/package/moment
import { el } from "redom"; // https://redom.js.org/

export function populateSharedViewInformation(sharedView) {
	var frag = document.createDocumentFragment();

	var cont = document.createElement("div");
	cont.className = "container-fluid";
	var row = document.createElement("div");
	row.className = "section-meta row";
	var leftCol = document.createElement("div");
	leftCol.className = "col-7";
	var list = document.createElement("ul");

	var name = populateSharedViewListItem(
		strings.DEFAULT_MODE,
		sharedView.Preferences.DefaultMode === mapModes.LIVE ? strings.MODE_LIVE : strings.MODE_HISTORY
	);
	list.appendChild(name);

	if (sharedView.Description !== null && sharedView.Description !== "") {
		var description = populateSharedViewListItem(strings.DESCRIPTION, sharedView.Description);
		description.className = "pt-2";
		list.appendChild(description);
	}

	leftCol.appendChild(list);

	var shareCont = document.createElement("div");
	shareCont.className = "pt-3";
	var shareButton = document.createElement("a");
	shareButton.href = "#";
	shareButton.className = "btn btn-primary";
	shareButton.id = "SharedViewMapInformationShare";

	var shareIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
	var shareIconTitle = document.createElementNS("http://www.w3.org/2000/svg", "title");
	shareIconTitle.textContent = strings.SHARE_THIS_VIEW;
	shareIcon.appendChild(shareIconTitle);
	var shareIconType = document.createElementNS("http://www.w3.org/2000/svg", "use");
	shareIconType.setAttributeNS(
		"http://www.w3.org/1999/xlink",
		"href",
		"/content/svg/tracking.svg?v=15#share-alt-solid"
	);
	shareIcon.appendChild(shareIconType);
	shareButton.appendChild(shareIcon);

	var shareLabel = document.createElement("span");
	shareLabel.textContent = strings.SHARE_THIS_VIEW;
	shareButton.appendChild(shareLabel);
	shareCont.appendChild(shareButton);
	leftCol.appendChild(shareCont);

	row.appendChild(leftCol);

	// right column
	var rightCol = document.createElement("div");
	rightCol.className = "col-5 info-right";
	var rightList = document.createElement("ul");
	rightCol.appendChild(rightList);

	var status = populateSharedViewListItem(strings.STATUS, sharedView.IsEnabled ? strings.ENABLED : strings.DISABLED);
	rightList.appendChild(status);
	var expires = populateSharedViewListItem(
		strings.EXPIRES,
		sharedView.ExpiresOnEpoch === null ? strings.NEVER : moment.utc(sharedView.ExpiresOnEpoch).format(user.dateFormat)
	);
	rightList.appendChild(expires);
	var availability = populateSharedViewListItem(
		strings.AVAILABILITY,
		sharedView.IsPublic ? strings.PUBLIC : strings.PRIVATE
	);
	rightList.appendChild(availability);
	var messaging = populateSharedViewListItem(
		strings.TWO_WAY_MESSAGING,
		sharedView.IsMessagingEnabled ? strings.ENABLED : strings.DISABLED
	);
	rightList.appendChild(messaging);
	var created = populateSharedViewListItem(
		strings.CREATED_ON,
		moment.utc(sharedView.CreatedOnEpoch).format(user.dateFormat)
	);
	rightList.appendChild(created);
	var updated = populateSharedViewListItem(
		strings.UPDATED_ON,
		sharedView.UpdatedOnEpoch === null ? strings.NEVER : moment.utc(sharedView.UpdatedOnEpoch).format(user.dateFormat)
	);
	rightList.appendChild(updated);
	row.appendChild(rightCol);

	cont.appendChild(row);
	frag.appendChild(cont);
	return frag;
}

export function populateSharedViewSubmissionFromForm() {
	var id = parseInt(document.getElementById("SharedViewId").value);
	var name = document.getElementById("SharedViewName").value;
	var description = document.getElementById("SharedViewDescription").value;
	var color = document.getElementById("SharedViewColor").value;

	var permissions = getSharedViewSelectionOptions();
	var assetGroupIds = permissions.assetGroupIds;
	var assetIds = permissions.assetIds;
	var fenceIds = permissions.fenceIds;
	var placeIds = permissions.placeIds;
	var driverIds = permissions.driverIds;

	var isEnabled = document.getElementById("SharedViewIsEnabledYes").checked;
	var isPublic = document.getElementById("SharedViewIsPublicYes").checked;
	var password = document.getElementById("SharedViewPassword").value;
	var doesExpire = document.getElementById("SharedViewDoesExpireYes").checked;
	var expiresOn = $(document.getElementById("SharedViewExpiresOn")).datetimepicker("getDate"); // user's local date
	if (expiresOn !== null) {
		expiresOn = moment(expiresOn).format(user.dateFormat);
	}
	var isMessagingEnabled = document.getElementById("SharedViewIsMessagingEnabledYes").checked;
	var isTimeframeRelative = document.getElementById("SharedViewDataTimeframeRelative").checked;
	var relativeTimeframeNumber = null;
	var relativeTimeframeType = null;
	var isDataLimited = false;
	var from = null;
	var to = null;
	if (isTimeframeRelative) {
		relativeTimeframeNumber = document.getElementById("SharedViewDataTimeframeRelativeNumber").value;
		relativeTimeframeType = document.getElementById("SharedViewDataTimeframeRelativeType").value;
	} else {
		isDataLimited = document.getElementById("SharedViewDataTimeframeCustom").checked;
		from = $(document.getElementById("SharedViewFrom")).datetimepicker("getDate");
		if (from !== null) {
			from = moment(from).format(user.dateFormat);
		}
		to = $(document.getElementById("SharedViewTo")).datetimepicker("getDate");
		if (to !== null) {
			to = moment(to).format(user.dateFormat);
		}
	}

	var preferenceLanguage = document.getElementById("SharedViewLanguage").value;
	var preferenceTimezone = document.getElementById("SharedViewTimezone").value;
	var preferenceLatLng = parseInt(document.getElementById("SharedViewLatLngFormat").value);
	var preferenceSpeed = parseInt(document.getElementById("SharedViewSpeedFormat").value);
	var preferenceFuel = parseInt(document.getElementById("SharedViewFuelFormat").value);

	var preferenceRemoveRoads = document.getElementById("SharedViewRemoveRoadsYes").checked;
	var preferencePositionConsolidation = document.getElementById("SharedViewPositionConsolidationYes").checked;
	var preferencePositionAlpha = document.getElementById("SharedViewPositionAlphaYes").checked;
	var preferenceHistoryViewNumber = 7; //parseInt(document.getElementById('SharedViewHistoryViewNumber').value);
	var preferenceHistoryViewType = "d"; //document.getElementById('SharedViewHistoryViewType').value;
	var preferenceMapType = parseInt(document.getElementById("SharedViewMapType").value);
	var preferenceDefaultMode = parseInt(document.getElementById("SharedViewDefaultMode").value);

	var data = {
		id: id,
		name: name,
		description: description,
		color: color,
		assetGroupIds: assetGroupIds,
		assetIds: assetIds,
		fenceIds: fenceIds,
		placeIds: placeIds,
		driverIds: driverIds,
		isEnabled: isEnabled,
		isPublic: isPublic,
		password: password,
		doesExpire: doesExpire,
		expiresOn: expiresOn,
		//expiresOnUtc: expiresOn,
		isTimeframeRelative: isTimeframeRelative,
		relativeTimeframeNumber: relativeTimeframeNumber,
		relativeTimeframeType: relativeTimeframeType,
		isDataLimited: isDataLimited,
		isMessagingEnabled: isMessagingEnabled,
		//fromUtc: from,
		from: from,
		to: to,
		//toUtc: to,
		preferences: {
			preferenceSpeed: preferenceSpeed,
			preferenceFuel: preferenceFuel,
			preferenceTimezone: preferenceTimezone,
			preferenceLanguage: preferenceLanguage,
			preferenceRemoveRoads: preferenceRemoveRoads,
			preferencePositionConsolidation: preferencePositionConsolidation,
			preferencePositionAlpha: preferencePositionAlpha,
			preferenceHistoryViewNumber: preferenceHistoryViewNumber,
			preferenceHistoryViewType: preferenceHistoryViewType,
			preferenceLatLng: preferenceLatLng,
			preferenceMapType: preferenceMapType,
			preferenceDefaultMode: preferenceDefaultMode,
		},
	};
	return data;
}

function populateSharedViewListItem(label, text) {
	return el("li", [el("div.meta-header", label + ":"), el("div.meta-item", text)]);
}

export function getSharedViewSelectionOptions() {
	var dialog = domNodes.dialogs.sharedView;
	var assetGroupIds = [];
	var assetIds = [];
	var fenceIds = [];
	var placeIds = [];
	var driverIds = [];
	if (document.getElementById("SharedViewPermissionsGroups").checked) {
		assetGroupIds = _.map(dialog.querySelectorAll('input[name="SharedViewAssetGroupIds"]:checked'), "value");
	}
	if (document.getElementById("SharedViewPermissionsAssets").checked) {
		assetIds = _.map(dialog.querySelectorAll('input[name="SharedViewAssetIds"]:checked'), function (item) {
			return parseInt(item.value);
		});
	}
	if (document.getElementById("SharedViewPermissionsGeofences").checked) {
		fenceIds = _.map(dialog.querySelectorAll('input[name="SharedViewFenceIds"]:checked'), "value");
	}
	if (document.getElementById("SharedViewPermissionsPlaces").checked) {
		placeIds = _.map(dialog.querySelectorAll('input[name="SharedViewPlaceIds"]:checked'), function (item) {
			return parseInt(item.value);
		});
	}
	if (document.getElementById("SharedViewPermissionsDrivers").checked) {
		driverIds = _.map(dialog.querySelectorAll('input[name="SharedViewDriverIds"]:checked'), function (item) {
			return parseInt(item.value);
		});
	}
	return {
		assetIds: assetIds,
		fenceIds: fenceIds,
		placeIds: placeIds,
		driverIds: driverIds,
		assetGroupIds: assetGroupIds,
	};
}
