import { trkDataGroups } from "./const.js";
import {
	updateNotificationsInSecondaryPanel,
	getNotificationCounts,
	getNotificationCountsForGroup,
} from "./notifications.js";
import domNodes from "./domNodes.js";
import trkData from "./data.js";
import { findTripById } from "./trips.js";

import _ from "lodash";

function updateBadgesForNode(node, dataGroup, counts) {
	var badgePositions = node.querySelector('[data-action="asset-positions"] .badge');
	var badgeAlerts = node.querySelector('[data-action="asset-alerts"] .badge');
	var badgeEvents = node.querySelector('[data-action="asset-events"] .badge');
	var badgeStatus = node.querySelector('[data-action="asset-status"] .badge');
	var badgeMessages = node.querySelector('[data-action="asset-messages"] .badge');
	var badgeChat = node.querySelector('[data-action="asset-chat"] .badge');
	updateBadgesWithCounts(badgePositions, badgeAlerts, badgeEvents, badgeStatus, badgeChat, badgeMessages, counts);

	var notifications = node.querySelector(".notifications");
	// in history mode there can be a position returned outside of the queried time, so hasHistoryResult is used to differentiate
	if (
		(dataGroup === trkDataGroups.NORMAL_HISTORY ||
			dataGroup === trkDataGroups.JOURNEY_HISTORY ||
			dataGroup === trkDataGroups.SHARED_VIEW_HISTORY) &&
		counts.positions > 0 &&
		counts.hasHistoryResult
	) {
		notifications.classList.add("recent-positions");
	} else if (dataGroup === trkDataGroups.NORMAL_LIVE && counts.positions > 0) {
		notifications.classList.add("recent-positions");
	} else {
		notifications.classList.remove("recent-positions");
	}

	if (counts.alerts > 0) {
		notifications.classList.add("recent-alerts");
	} else {
		notifications.classList.remove("recent-alerts");
	}

	if (counts.hasEmergency) {
		notifications.classList.add("has-emergency");
	} else {
		notifications.classList.remove("has-emergency");
	}

	if (counts.events > 0) {
		notifications.classList.add("recent-events");
	} else {
		notifications.classList.remove("recent-events");
	}

	if (counts.status > 0) {
		notifications.classList.add("recent-status");
	} else {
		notifications.classList.remove("recent-status");
	}

	if (counts.messages > 0) {
		notifications.classList.add("recent-messages");
	} else {
		notifications.classList.remove("recent-messages");
	}
}

export function updateBadgesWithCounts(
	badgePositions,
	badgeAlerts,
	badgeEvents,
	badgeStatus,
	badgeChat,
	badgeMessages,
	counts
) {
	if (badgePositions !== null) {
		if (counts.positions > 0) {
			badgePositions.classList.add("active");
		} else {
			badgePositions.classList.remove("active");
		}
		badgePositions.textContent = counts.positions;
	}

	if (badgeAlerts !== null) {
		if (counts.alerts > 0) {
			badgeAlerts.classList.add("active");
		} else {
			badgeAlerts.classList.remove("active");
		}
		if (counts.hasEmergency) {
			badgeAlerts.classList.remove("bg-alert");
			badgeAlerts.classList.add("bg-emergency");
		} else {
			badgeAlerts.classList.add("bg-alert");
			badgeAlerts.classList.remove("bg-emergency");
		}
		var parent = badgeAlerts.parentNode;
		if (parent.classList.contains("nav-item")) {
			if (counts.hasEmergency) {
				parent.classList.add("notify-emergency-tab");
				parent.classList.remove("notify-alert-tab");
			} else {
				parent.classList.remove("notify-emergency-tab");
				parent.classList.add("notify-alert-tab");
			}
		}
		badgeAlerts.textContent = counts.alerts;
	}

	if (badgeEvents !== null) {
		if (counts.events > 0) {
			badgeEvents.classList.add("active");
		} else {
			badgeEvents.classList.remove("active");
		}
		badgeEvents.textContent = counts.events;
	}

	if (badgeStatus !== null) {
		if (counts.status > 0) {
			badgeStatus.classList.add("active");
		} else {
			badgeStatus.classList.remove("active");
		}
		badgeStatus.textContent = counts.status;
	}

	if (badgeMessages !== null) {
		if (counts.messagesDevice > 0) {
			badgeMessages.classList.add("active");
		} else {
			badgeMessages.classList.remove("active");
		}
		badgeMessages.textContent = counts.messagesDevice;
	}

	if (badgeChat !== null) {
		if (counts.messagesChat > 0) {
			badgeChat.classList.add("active");
		} else {
			badgeChat.classList.remove("active");
		}
		badgeChat.textContent = counts.messagesChat;
	}
}

export function updateGroupFunctionBadges(dataGroup, itemIds, type) {
	//console.log('update group badges ' + type + ': ' + (itemIds !== null ? itemIds.join(',') : 'all'));
	var nodeList = domNodes.groups; // todo: group types
	var groupIds = [];
	switch (type) {
		case "asset":
			groupIds.push("all-assets");
			// update appropriate asset groups and all-assets
			// get group ids that contain asset
			if (itemIds === null) {
				itemIds = _.map(trkData.assets, "Id");
			}
			itemIds.forEach(function (itemId) {
				trkData.groups.forEach(function (group) {
					if (group.AssetIds.indexOf(itemId) !== -1) {
						groupIds.push(group.Id);
					}
				});
			});
			break;
		case "trip":
			// update related journeys
			if (itemIds === null) {
			}
			dataGroup = trkDataGroups.JOURNEY_HISTORY;
			itemIds.forEach(function (tripId) {
				var trip = findTripById(tripId);
				groupIds.push(trip.JourneyId);
			});
			break;
	}
	groupIds = _.uniq(groupIds);
	groupIds.forEach(function (groupId) {
		var groupCounts = getNotificationCountsForGroup(dataGroup, groupId);
		if (dataGroup === trkDataGroups.JOURNEY_HISTORY) {
			groupId = "journey-" + groupId;
		}
		updateBadgesForNode(nodeList[groupId].querySelector(".group-info"), dataGroup, groupCounts);
		var notificationType = "groups";
		if (dataGroup === trkDataGroups.JOURNEY_HISTORY) {
			notificationType = "journeys";
		}
		updateNotificationsInSecondaryPanel(notificationType, groupId, groupCounts);
	});
}

export function updateAssetFunctionBadges(dataGroup, itemId) {
	// todo: performance, these should be stored instead of recalculated every time
	var counts = getNotificationCounts(dataGroup, itemId);

	if (dataGroup !== trkDataGroups.SHARED_VIEW_HISTORY) {
		var nodeList = domNodes.assets;
		if (dataGroup === trkDataGroups.JOURNEY_HISTORY) {
			nodeList = domNodes.trips;
		}

		var items = nodeList[itemId];
		if (!_.isArray(items)) {
			items = [items];
		}

		items.forEach(function (node) {
			updateBadgesForNode(node, dataGroup, counts);
		});
	}

	var notificationType = "assets";
	if (dataGroup === trkDataGroups.JOURNEY_HISTORY) {
		notificationType = "trips";
	} else if (dataGroup === trkDataGroups.SHARED_VIEW_HISTORY) {
		notificationType = "shared-views";
	}
	updateNotificationsInSecondaryPanel(notificationType, itemId, counts);
}
