// import Handlebars from "handlebars";
import _ from "lodash";

import asset from "../templates/asset.hbs"
import assetGroup from "../templates/assetGroup.hbs"
import geofence from "../templates/geofence.hbs"
import place from "../templates/place.hbs"
import sharedView from "../templates/sharedView.hbs"
import trip from "../templates/trip.hbs"

const templates = {
	asset: asset,
	assetGroup: assetGroup,
	geofence: geofence,
	place: place,
	module: null,
	sharedView: sharedView,
	trip: trip,
};

export default templates;