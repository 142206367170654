import strings from "./strings.js";
import user from "./user.js";
import trkData from "./data.js";
import options from "./options.js";
import { includeRowIfNotNull, svgPath } from "./dom-util.js";
import { canAssetBeRouted } from "./waypoint.js";
import { findFenceById, findAssetIdsInGeofence } from "./fence.js";

export function createQuickActionsForSharedView(sharedView) {
	document.getElementById("shared-view-information-actions").classList.add("is-visible");
	var quickActions = document.getElementById("shared-view-information-actions-list");
	var actionOptions = quickActions.querySelector('a[data-action="shared-view-options"]');
	actionOptions.setAttribute("data-shared-view-id", sharedView.Id);

	var actionToggleStatus = quickActions.querySelector('a[data-action="shared-view-status"]');
	actionToggleStatus.setAttribute("data-shared-view-id", sharedView.Id);
	if (sharedView.IsEnabled) {
		actionToggleStatus.title = strings.DISABLE;
		actionToggleStatus.querySelector("span").textContent = strings.DISABLE;
		actionToggleStatus.querySelector("use").setAttributeNS("http://www.w3.org/1999/xlink", "href", svgPath("ban"));
	} else {
		actionToggleStatus.title = strings.ENABLE;
		actionToggleStatus.querySelector("span").textContent = strings.ENABLE;
		actionToggleStatus.querySelector("use").setAttributeNS("http://www.w3.org/1999/xlink", "href", svgPath("check"));
	}
}

export function createQuickActionsForGeofence(fence) {
	document.getElementById("map-item-actions-geofence").classList.add("is-visible");
	var quickActions = document.getElementById("geofence-information-actions-list");
	var actionHide = quickActions.querySelector('a[data-action="fence-hide"]');
	actionHide.setAttribute("data-fence-id", fence.Id);
	var actionOptions = quickActions.querySelector('a[data-action="fence-options"]');
	actionOptions.setAttribute("data-fence-id", fence.Id);

	var isSendDisabled = false,
		isReplayDisabled = false,
		isGroupDisabled = false,
		isAlertDisabled = false,
		isReportDisabled = false;
	if (user.isAnonymous) {
		isSendDisabled = isGroupDisabled = isAlertDisabled = isReportDisabled = true;
	}

	if (findAssetIdsInGeofence(fence).length == 0) {
		isSendDisabled = isGroupDisabled = isReplayDisabled = isReportDisabled = true;
	}

	var actionSendMessage = quickActions.querySelector('a[data-action="fence-send-message"]');
	var actionHistory = quickActions.querySelector('a[data-action="fence-history"]');
	var actionCreateAlert = quickActions.querySelector('a[data-action="fence-create-alert"]');
	var actionLocationReport = quickActions.querySelector('a[data-action="fence-location-report"]');
	var actionGroupAssets = quickActions.querySelector('a[data-action="fence-group-assets"]');
	if (isSendDisabled) {
		actionSendMessage.classList.add("disabled");
	} else {
		actionSendMessage.classList.remove("disabled");
	}
	if (isReplayDisabled) {
		actionHistory.classList.add("disabled");
	} else {
		actionHistory.classList.remove("disabled");
	}
	if (isGroupDisabled) {
		actionGroupAssets.classList.add("disabled");
	} else {
		actionGroupAssets.classList.remove("disabled");
	}
	if (isAlertDisabled) {
		actionCreateAlert.classList.add("disabled");
	} else {
		actionCreateAlert.classList.remove("disabled");
	}
	if (isReportDisabled) {
		actionLocationReport.classList.add("disabled");
	} else {
		actionLocationReport.classList.remove("disabled");
	}
	actionSendMessage.setAttribute("data-fence-id", fence.Id);
	actionHistory.setAttribute("data-fence-id", fence.Id);
	actionCreateAlert.setAttribute("data-fence-id", fence.Id);
	actionLocationReport.setAttribute("data-fence-id", fence.Id);
	actionGroupAssets.setAttribute("data-fence-id", fence.Id);
}

export function createQuickActionsForWaypoint(waypoint, asset) {
	document.getElementById("map-item-actions-waypoint").classList.add("is-visible");
	var quickActions = document.getElementById("waypoint-information-actions-list");
	var actionHide = quickActions.querySelector('a[data-action="waypoint-hide"]');
	actionHide.setAttribute("data-asset-id", asset.Id);
	var actionOptions = quickActions.querySelector('a[data-action="waypoint-options"]');
	actionOptions.setAttribute("data-asset-id", asset.Id);
	var actionGetRoute = quickActions.querySelector('a[data-action="waypoint-route-asset"]');
	actionGetRoute.setAttribute("data-waypoint-id", waypoint.Id);
	var actionMarkComplete = quickActions.querySelector('a[data-action="waypoint-mark-complete"]');
	actionMarkComplete.setAttribute("data-waypoint-id", waypoint.Id);
	if (!canAssetBeRouted(asset) || waypoint.route != null) {
		actionGetRoute.classList.add("disabled");
	} else {
		actionGetRoute.classList.remove("disabled");
	}
	if (user.isAnonymous) {
		actionMarkComplete.classList.add("disabled");
	}
}

export function createQuickActionsForPlace(place) {
	document.getElementById("map-item-actions-place").classList.add("is-visible");
	var quickActions = document.getElementById("place-information-actions-list");
	var actionHide = quickActions.querySelector('a[data-action="place-hide"]');
	actionHide.setAttribute("data-place-id", place.Id);
	var actionOptions = quickActions.querySelector('a[data-action="place-options"]');
	actionOptions.setAttribute("data-place-id", place.Id);
	var isAssetRoutingDisabled =
		trkData.assets === null || trkData.assets.length === 0 || options.enabledFeatures.indexOf("ASSET_ROUTING") === -1;
	var isRoutingDisabled = options.enabledFeatures.indexOf("GET_ROUTE") === -1;
	var actionRouteAsset = quickActions.querySelector('a[data-action="place-route-asset"]');
	actionRouteAsset.setAttribute("data-place-id", place.Id);
	if (isAssetRoutingDisabled) {
		actionRouteAsset.classList.add("disabled");
	} else {
		actionRouteAsset.classList.remove("disabled");
	}
	var actionMeasureDistanceTo = quickActions.querySelector('a[data-action="place-measure-distance"]');
	actionMeasureDistanceTo.setAttribute("data-lat", place.Location.Lat);
	actionMeasureDistanceTo.setAttribute("data-lng", place.Location.Lng);
	var actionRouteFrom = quickActions.querySelector('a[data-action="place-route-from"]');
	actionRouteFrom.setAttribute("data-lat", place.Location.Lat);
	actionRouteFrom.setAttribute("data-lng", place.Location.Lng);
	var actionRouteTo = quickActions.querySelector('a[data-action="place-route-to"]');
	actionRouteTo.setAttribute("data-lat", place.Location.Lat);
	actionRouteTo.setAttribute("data-lng", place.Location.Lng);
	if (isRoutingDisabled) {
		actionRouteFrom.classList.add("disabled");
		actionRouteTo.classList.add("disabled");
	} else {
		actionRouteFrom.classList.remove("disabled");
		actionRouteTo.classList.remove("disabled");
	}
}

export function createQuickActionsForPosition(asset, location) {
	document.getElementById("map-item-actions-position").classList.add("is-visible");
	var quickActions = document.getElementById("position-information-actions-list");
	var actionPositionToPlace = quickActions.querySelector('a[data-action="position-to-place"]');
	actionPositionToPlace.classList.remove("disabled");
	actionPositionToPlace.setAttribute("data-asset-id", asset.Id);
	actionPositionToPlace.setAttribute("data-lat", location.Lat);
	actionPositionToPlace.setAttribute("data-lng", location.Lng);
	actionPositionToPlace.setAttribute("data-position-id", location.Id);
	if (!user.canEditPlaces) {
		actionPositionToPlace.classList.add("disabled");
	}
	var actionHide = quickActions.querySelector('a[data-action="position-hide"]');
	actionHide.setAttribute("data-asset-id", asset.Id);

	var actionActivity = quickActions.querySelector('a[data-action="position-activity"]');
	actionActivity.setAttribute("data-asset-id", asset.Id);

	var actionPositionVisibility = quickActions.querySelector('a[data-action="position-toggle-map"]');
	actionPositionVisibility.setAttribute("data-position-id", location.Id);
	actionPositionVisibility.setAttribute("data-asset-id", asset.Id);
	actionPositionVisibility.setAttribute("data-hidden", location.IsHidden === true);
	actionPositionVisibility
		.querySelector("use")
		.setAttributeNS(
			"http://www.w3.org/1999/xlink",
			"href",
			"/content/svg/tracking.svg?v=15#" + (location.IsHidden ? "visible" : "invisible")
		);
	actionPositionVisibility.querySelector("span").innerText = location.IsHidden
		? strings.SHOW_POSITION
		: strings.HIDE_POSITION;
	actionPositionVisibility.title = location.IsHidden ? strings.SHOW_POSITION : strings.HIDE_POSITION;

	var actionOptions = quickActions.querySelector('a[data-action="position-options"]');
	actionOptions.setAttribute("data-asset-id", asset.Id);
	var actionMeasureDistanceTo = quickActions.querySelector('a[data-action="position-measure-distance"]');
	actionMeasureDistanceTo.setAttribute("data-lat", location.Lat);
	actionMeasureDistanceTo.setAttribute("data-lng", location.Lng);
	var isRoutingDisabled = options.enabledFeatures.indexOf("GET_ROUTE") === -1;
	var actionRouteFrom = quickActions.querySelector('a[data-action="position-route-from"]');
	actionRouteFrom.setAttribute("data-lat", location.Lat);
	actionRouteFrom.setAttribute("data-lng", location.Lng);
	var actionRouteTo = quickActions.querySelector('a[data-action="position-route-to"]');
	actionRouteTo.setAttribute("data-lat", location.Lat);
	actionRouteTo.setAttribute("data-lng", location.Lng);
	if (isRoutingDisabled) {
		actionRouteFrom.classList.add("disabled");
		actionRouteTo.classList.add("disabled");
	} else {
		actionRouteFrom.classList.remove("disabled");
		actionRouteTo.classList.remove("disabled");
	}
}
