import strings from "./strings.js";
import trkData from "./data.js";
import options from "./options.js";
import user from "./user.js";
import domNodes from "./domNodes.js";
import { openDialogPanel } from "./panel-nav.js";
import { removeItemFromMap } from "./map-items.js";
import { directionsService } from "./map-base.js";
import { placeRoutesFound, placeRoutesError } from "./routing.js";

import $j from "jquery";
import _ from "lodash";
import { el, setChildren } from "redom"; // https://redom.js.org/

export function openPlaceRoutingForPlace(place) {
	if (options.enabledFeatures.indexOf("ASSET_ROUTING") === -1) {
		return;
	}

	openDialogPanel(
		domNodes.dialogs.placeRouting,
		strings.ROUTE_ASSET_TO_PLACE,
		place,
		false,
		closePlaceRouting,
		"place",
		"route-asset",
		openPlaceRoutingForPlace
	);
	openPlaceRouting(null, place);
}

export function openPlaceRoutingForAsset(asset) {
	if (options.enabledFeatures.indexOf("ASSET_ROUTING") === -1) {
		return;
	}
	openDialogPanel(
		domNodes.dialogs.placeRouting,
		strings.ROUTE_ASSET_TO_PLACE,
		asset,
		false,
		closePlaceRouting,
		"asset",
		"route-asset",
		openPlaceRoutingForAsset
	);
	openPlaceRouting(asset, null);
}

function openPlaceRouting(asset, place) {
	if (options.enabledFeatures.indexOf("ASSET_ROUTING") === -1) {
		return;
	}
	var routeGeofence = document.getElementById("place-route-geofence");
	if (user.canEditGeofences) {
		routeGeofence.classList.add("is-visible");
	}

	let items = [];
	for (var i = 0; i < trkData.places.length; i++) {
		var item = trkData.places[i];
		items.push(el("option", { value: item.Id }, item.Name));
	}
	setChildren(document.getElementById("PlaceRoutePlace"), items);

	// populate asset list
	items = [];
	for (var i = 0; i < trkData.assets.length; i++) {
		var item = trkData.assets[i];
		items.push(el("option", { value: item.Id }, item.Name));
	}
	setChildren(document.getElementById("PlaceRouteAsset"), items);

	$j("input[name=PlaceRouteVia]").prop("checked", false);
	$j("#PlaceRouteViaRoad").prop("checked", true);
	if (place != null) {
		$j("#PlaceRoutePlace").val(place.Id);
	}
	if (asset != null) {
		$j("#PlaceRouteAsset").val(asset.Id);
	}
}

export function closePlaceRouting() {
	console.log("close place routing");
	directionsService
		//.off('routingstart', placeRoutesLoading)
		.off("routesfound", placeRoutesFound)
		.off("routingerror", placeRoutesError);
	//directionsService.remove();
	directionsService.getPlan().setWaypoints([]);
	$j("#place-route-results").removeClass("is-visible");
	$j("#place-route-directions").empty();
	if (trkData.routeLine != null) {
		removeItemFromMap(trkData.routeLine);
	}
	trkData.routeLine = null;
	trkData.routeLineEncoded = null;
	trkData.routeLinesEncoded = null;
	$j("#PlaceRouteGeofence,#RouteGeofence,#RouteGeofenceCreate").addClass("disabled");

	var btn = document.getElementById("PlaceRouteCalculate");
	btn.disabled = false;
	var loading = document.getElementById("place-route-loading");
	var results = document.getElementById("place-route-results");
	var status = document.getElementById("place-route-status");
	loading.classList.remove("is-visible");
	status.classList.remove("is-visible");
	results.classList.remove("is-visible");
}
