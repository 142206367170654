// Open/close side panels

import trkData from "./data.js";
import { map } from "./map-base.js";
import domNodes from "./domNodes.js";
import state from "./state.js";
import { findFenceById, openGeofenceDialog, findAssetIdsInGeofence, toggleFenceActive } from "./fence.js";
import { viewModes, mapModes, panels } from "./const.js";
import { findAssetById } from "./assets.js";
import { openStatusForAsset } from "./asset-state.js";
import { findPlaceById, openPlaceDialog, togglePlaceActive, addOrUpdatePlaceClick } from "./place.js";
import { openRouting } from "./routing.js";
import { findWaypointById } from "./waypoint.js";
import { switchMapMode } from "./map-base.js";
import { clearSettingsNode, findSharedViewById, changeSharedViewStatus } from "./shared-view.js";
import { switchViewMode } from "./map-viewmode.js";
import { openPositionsForAsset, openPositionsForGroup } from "./positions.js";
import { openEventsForAsset } from "./asset-events.js";
import { addPointToRouting } from "./routing.js";
import { itemSettingsClick } from "./panel-settings.js";
import { openChatForGroup, openChatForAsset, openMessagesForGroup, openMessagesForAsset } from "./messages.js";
import { changeItemSort } from "./item-sorting.js";
import { openAlertsForAsset } from "./asset-alerts.js";
import {
	findGroupById,
	openEventsForGroup,
	openStatusForGroup,
	openAlertsForGroup,
	openAssetGroupDialog,
} from "./asset-group.js";
import { openActivityForAsset, openActivityForGroup } from "./activities.js";
import { setUrlHistoryForActivePanel } from "./panel-browser-history.js";
import { toggleAssetActive, hideAllAssetsExcept } from "./asset-select.js";
import { closePrimaryNavigation } from "./walkthrough.js";
import { toggleTripActive } from "./trips.js";
import { clearStatusMessage } from "./panel-nav.js";
import { openSharedViewDialog } from "./shared-view-dialog.js";
import { populateCheckboxList } from "./dom-util.js";
import { openRuler, addPointToRuler } from "./ruler.js";
import { queryActiveAssets } from "./assets-active.js";
import { openSharedViewSettingsPanel } from "./panel-settings.js";
import { togglePositionVisibility } from "./positions.js";
import { openPlaceRoutingForPlace } from "./place-routing.js";
import { openSendMessageDialog } from "./messages.js";
import { loadHistory } from "./position-history.js";
import { waypointGetRoute, waypointMarkComplete } from "./routing-waypoint.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import moment from "moment"; // https://www.npmjs.com/package/moment

export function initPanels() {
	// Called once the DOM nodes for the panel interaction have been created.

	$("#panel-primary").on("click", "#panel-primary-close", function (e) {
		e.preventDefault();
		closePrimaryPanel();
	});

	$("#panel-secondary").on("click", "#panel-secondary-close", function (e) {
		e.preventDefault();
		closeSecondaryPanel();
	});

	$("#panel-secondary").on("click", "#panel-dialog-back", function (e) {
		e.preventDefault();
		if (domNodes.panels.secondary.closeCallback !== undefined && domNodes.panels.secondary.closeCallback !== null) {
			domNodes.panels.secondary.closeCallback();
			domNodes.panels.secondary.closeCallback = undefined;
		}
		var itemSettings = $.proxy(itemSettingsClick, this);
		itemSettings();
	});

	$("#nav-header").on("click", "#toggle-nav-primary", function (e) {
		e.preventDefault();

		if (this.classList.contains("active")) {
			hidePrimaryPanel();
		} else {
			if (state.openPanels.secondary === true) {
				openSecondaryPanel(false);
			} else {
				openPrimaryPanel();
			}
		}
	});

	$("#panel-primary").on("click", ".item-settings", itemSettingsClick);
	$("#panel-secondary").on("click", ".breadcrumb-item a", function (e) {
		// navigate back up the breadcrumb tree
		e.preventDefault();
		if (this.callback !== undefined) {
			this.callback();
		}
	});
	$(document).on("click", "#acknowledge-alert-dialog .item-settings,.infoheader .item-settings", itemSettingsClick);

	$(".filter-options-list, .panel-options-list").on("click", "a.dropdown-item", handlePanelAction);
	$(".option-actions-list").on("click", "button.option-action", handlePanelAction);
}

export function closePrimaryPanel() {
	if (state.openPanels.secondary === true) {
		closeSecondaryPanel();
	}
	hidePrimaryPanel();
	state.openPanels.primary = false;
}

export function hidePrimaryPanel() {
	hideSecondaryPanel();
	var wasClosed = domNodes.panels.primary.classList.contains("is-closed");
	var wasOpen = domNodes.panels.primary.classList.contains("is-opened");
	domNodes.panels.primary.classList.add("is-closed");
	domNodes.panels.primary.classList.remove("is-opened");
	domNodes.nav.utility.classList.add("is-closed");
	domNodes.nav.utility.classList.remove("is-opened");
	domNodes.nav.primary.classList.remove("is-active");
	domNodes.content.base.classList.add("is-expanded");
	domNodes.content.base.classList.remove("is-retracted");
	var button = document.getElementById("toggle-nav-primary");
	button.classList.remove("active");
	button
		.querySelector("use")
		.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#expand");
	//state.openPanels.primary = false; // this isn't accurate, should just be hidden
	if (map !== null && (wasOpen || !wasClosed)) {
		panelSizeChange();
	}
}

export function moveSecondaryPanelToPrimary() {
	console.log("move panels");
	domNodes.content.base.classList.remove("is-collapsed");
	domNodes.content.base.classList.add("is-retracted");
	domNodes.nav.utility.classList.add("is-opened");
	domNodes.nav.primary.classList.add("is-active");
	domNodes.nav.utility.classList.remove("is-expanded");
	domNodes.panels.secondary.classList.add("is-over-primary");
	panelSizeChange();
	domNodes.simpleBars.secondary.recalculate();
}

function panelSizeChange() {
	map.invalidateSize({ pan: false, debouceMoveend: true });
}

export function openSecondaryPanel(closePrevious) {
	//var notificationOptions = document.getElementById('panel-options-notifications');
	//if (notificationOptions !== null) {
	//    notificationOptions.classList.remove('is-visible');
	//}
	if (state.openPanels.secondary === true && closePrevious) {
		// was already open with something, close out whatever was there before
		if (domNodes.panels.secondary.closeCallback !== undefined && domNodes.panels.secondary.closeCallback !== null) {
			domNodes.panels.secondary.closeCallback();
			domNodes.panels.secondary.closeCallback = undefined;
		}
	}
	//if (state.openPanels.secondary !== true) { // could be hidden and not just closed
	openPrimaryPanel();
	domNodes.panels.secondary.classList.remove("is-over-primary");
	domNodes.panels.secondary.classList.add("is-visible");
	domNodes.panels.secondary.classList.add("d-flex");
	domNodes.nav.utility.classList.remove("is-closed");
	domNodes.nav.utility.classList.add("is-expanded");
	domNodes.content.base.classList.add("is-collapsed");
	domNodes.content.base.classList.remove("is-retracted");
	if (state.openPanels.secondary !== true) {
		state.openPanels.secondary = true;
		panelSizeChange();
		domNodes.simpleBars.secondary.recalculate();
	}
	//}
}

function hideSecondaryPanel() {
	domNodes.panels.secondary.classList.remove("is-visible");
	domNodes.panels.secondary.classList.remove("d-flex");
	//domNodes.nav.utility.classList.remove('is-closed'); // why
	domNodes.nav.utility.classList.remove("is-expanded");
	domNodes.content.base.classList.remove("is-collapsed");
	domNodes.content.base.classList.add("is-retracted");
}

export function closeSecondaryPanel() {
	hideSecondaryPanel();
	domNodes.panels.secondary.removeAttribute("data-group-for");
	domNodes.panels.secondary.removeAttribute("data-item-type");
	domNodes.panels.secondary.removeAttribute("data-item-id");
	if (domNodes.panels.secondary.closeCallback !== undefined && domNodes.panels.secondary.closeCallback !== null) {
		domNodes.panels.secondary.closeCallback();
		domNodes.panels.secondary.closeCallback = undefined;
	}
	_.each(domNodes.assets, function (assetNodes) {
		_.each(assetNodes, clearSettingsNode);
	});
	_.each(domNodes.groups, clearSettingsNode);
	_.each(domNodes.fences, clearSettingsNode);
	_.each(domNodes.places, clearSettingsNode);
	_.each(domNodes.trips, clearSettingsNode);
	//_.each(domNodes.sharedViews, clearSettingsNode);
	if (state.openPanels.secondary === true) {
		panelSizeChange();
	}
	state.openPanels.secondary = false;
}

export function openPrimaryPanel() {
	// may be hidden but not closed
	//if (state.openPanels.primary !== true) {
	var wasOpen = domNodes.panels.primary.classList.contains("is-opened");
	var wasClosed = domNodes.panels.primary.classList.contains("is-closed");
	domNodes.panels.primary.classList.remove("is-closed");
	domNodes.panels.primary.classList.add("is-opened");
	domNodes.content.base.classList.remove("is-expanded");
	domNodes.content.base.classList.add("is-retracted");
	domNodes.nav.utility.classList.remove("is-closed");
	domNodes.nav.utility.classList.add("is-opened");
	domNodes.nav.primary.classList.add("is-active");
	closePrimaryNavigation();
	var button = document.getElementById("toggle-nav-primary");
	button.classList.add("active");
	button
		.querySelector("use")
		.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#collapse");
	if (!wasOpen || wasClosed) {
		panelSizeChange();
	}
	if (state.openPanels.primary !== true) {
		domNodes.simpleBars.primary.recalculate();
	}
	state.openPanels.primary = true;
	//}
}

export function changeActivePrimaryPanelToMatchSecondary(type) {
	switch (type) {
		case "fences":
			if (state.activePanel !== panels.GEOFENCES) {
				changeActivePanel(panels.GEOFENCES);
			}
			break;
		case "places":
			if (state.activePanel !== panels.PLACES) {
				changeActivePanel(panels.PLACES);
			}
			break;
		case "assets":
			if (state.activePanel !== panels.ASSETS) {
				changeActivePanel(panels.ASSETS);
			}
			//if (state.activeMapMode === mapModes.LIVE) {
			//    if (state.activePanel !== panels.LIVE) {
			//        changeActivePanel(panels.LIVE);
			//    }
			//} else if (state.activeMapMode === mapModes.HISTORY) {
			//    if (state.activePanel !== panels.HISTORY) {
			//        changeActivePanel(panels.HISTORY);
			//    }
			//}
			break;
		case "journeys": // fallthrough
		case "trips":
			if (state.activePanel !== panels.JOURNEYS) {
				changeActivePanel(panels.JOURNEYS);
			}
			break;
		case "shared-views":
			if (state.activePanel !== panels.SHARED_VIEWS) {
				changeActivePanel(panels.SHARED_VIEWS);
			}
			break;
		default: // dialog, todo: groups
			break;
	}
}

function handlePanelAction(e) {
	e.preventDefault();
	console.log("option click");
	switch (this.getAttribute("data-option-type")) {
		case "sorting":
			changeItemSort(this.getAttribute("data-sort-group"), parseInt(this.getAttribute("data-sort")));
			break;
		case "quick-actions":
			switch (this.getAttribute("data-action")) {
				case "run-report":
					openRunReportModal();
					break;
				case "upload-file":
					document.getElementById("ImportPlacesAndFences").disabled = true;
					var status = document.getElementById("upload-file-status");
					clearStatusMessage(status);
					$(domNodes.modals.uploadFile).modal("show");
					$("#UploadGeofencesMerge").prop("checked", false);
					$("#MergeGeofenceName").val("");
					$j("#UploadFilePreview,#UploadFileGeofencesMerge").hide();
					break;
				case "add-place":
					openPlaceDialog(null);
					break;
				case "add-geofence":
					openGeofenceDialog(null);
					break;
				case "add-journey":
					window.location = "/Settings/CreateJourney";
					break;
				case "add-shared-view":
					openSharedViewDialog(null);
					break;
				case "copy-to-shared-view":
					var form = document.createElement("form");
					form.method = "POST";
					form.action = "/Settings/CreateSharedView";

					_.each(trkData.visible.assets, function (assetId) {
						var formAssetId = document.createElement("input");
						formAssetId.name = "AssetIds";
						formAssetId.value = assetId;
						form.appendChild(formAssetId);
					});
					_.each(trkData.visible.fences, function (fenceId) {
						var formFenceId = document.createElement("input");
						formFenceId.name = "FenceIds";
						formFenceId.value = fenceId;
						form.appendChild(formFenceId);
					});
					_.each(trkData.visible.places, function (placeId) {
						var formPlaceId = document.createElement("input");
						formPlaceId.name = "PlaceIds";
						formPlaceId.value = placeId;
						form.appendChild(formPlaceId);
					});
					_.each(trkData.visible.groups, function (groupId) {
						var formAssetGroupId = document.createElement("input");
						formAssetGroupId.name = "AssetGroupIds";
						formAssetGroupId.value = groupId;
						form.appendChild(formAssetGroupId);
					});

					if (state.activeMapMode === mapModes.HISTORY) {
						var formFrom = document.createElement("input");
						formFrom.name = "From";
						formFrom.value = $("#txtDateFrom").val();
						form.appendChild(formFrom);

						var formTo = document.createElement("input");
						formTo.name = "To";
						formTo.value = $("#txtDateTo").val();
						form.appendChild(formTo);
					}

					var formMode = document.createElement("input");
					formMode.name = "QuickActionMode";
					formMode.value = state.activeMapMode === mapModes.LIVE ? 0 : 1;
					form.appendChild(formMode);

					var formAction = document.createElement("input");
					formAction.name = "IsQuickAction";
					formAction.value = "true";
					form.appendChild(formAction);

					var token = $('input[name="__RequestVerificationToken"]')[0].cloneNode();
					form.appendChild(token);
					document.body.appendChild(form);
					form.submit();
					break;
				case "position-to-place":
					var addOrUpdatePlace = $.proxy(addOrUpdatePlaceClick, this);
					addOrUpdatePlace();
					break;
				case "path-asset-options":
				case "path-trip-options":
				case "position-options":
				case "place-options":
				case "fence-options":
				case "waypoint-options":
					var itemSettings = $.proxy(itemSettingsClick, this);
					itemSettings();
					break;
				case "position-activity":
					var assetId = parseInt(this.getAttribute("data-asset-id"));
					var asset = findAssetById(assetId);
					openActivityForAsset(asset);
					break;
				case "position-hide":
				case "waypoint-hide":
					var assetId = parseInt(this.getAttribute("data-asset-id"));
					toggleAssetActive(assetId, false, true);
					$(domNodes.infoDialogs.mapItemInformation).dialog("close");
					break;
				case "path-asset-hide":
					var assetId = parseInt(this.getAttribute("data-asset-id"));
					toggleAssetActive(assetId, false, true);
					$(domNodes.infoDialogs.positionHistory).dialog("close");
					break;
				case "path-trip-hide":
					var journeyId = parseInt(this.getAttribute("data-journey-id"));
					var tripId = parseInt(this.getAttribute("data-trip-id"));
					toggleTripActive(journeyId, tripId, false, true);
					$(domNodes.infoDialogs.positionHistory).dialog("close");
					break;
				case "path-history":
					var from = this.getAttribute("data-from");
					var to = this.getAttribute("data-to");
					var assetId = parseInt(this.getAttribute("data-asset-id"));
					var dateFrom = document.getElementById("txtDateFrom");
					var dateTo = document.getElementById("txtDateTo");
					dateFrom.value = from;
					dateTo.value = to;

					// hide all assets except this one
					// todo: helper function for this?
					hideAllAssetsExcept(assetId);

					// switch mode to history and run it
					if (state.activeMapMode === mapModes.LIVE) {
						switchMapMode(mapModes.HISTORY, null, true);
					} else {
						queryActiveAssets(null);
					}
					//var dateFormat = user.dateFormat.substring(0, user.dateFormat.indexOf(' '));
					//historyDateFrom.value = fromDate.format(dateFormat + ' HH:mm');

					break;
				case "place-route-from":
				case "position-route-from":
					var lat = this.getAttribute("data-lat");
					var lng = this.getAttribute("data-lng");
					openRouting();
					trkData.routing.mapClickDestination = $(document.getElementById("RoutingDestination1"));
					addPointToRouting([lat, lng]);
					break;
				case "place-route-to":
				case "position-route-to":
					var lat = this.getAttribute("data-lat");
					var lng = this.getAttribute("data-lng");
					openRouting();
					trkData.routing.mapClickDestination = $(document.getElementById("RoutingDestination2"));
					addPointToRouting([lat, lng]);
					break;
				case "place-measure-distance":
				case "position-measure-distance":
					var lat = this.getAttribute("data-lat");
					var lng = this.getAttribute("data-lng");
					openRuler();
					addPointToRuler([lat, lng]);
					break;
				case "position-toggle-map":
					var id = this.getAttribute("data-position-id");
					var assetId = this.getAttribute("data-asset-id");
					var isNowHidden = this.getAttribute("data-hidden") !== "true";
					var sharedViewId = null;
					if (state.activeViewMode === viewModes.SHARED_VIEW && trkData.sharedView.current !== null) {
						sharedViewId = trkData.sharedView.current.Id;
					}
					$.when(togglePositionVisibility(assetId, id, isNowHidden, sharedViewId)).then(function () {
						if (!isNowHidden) {
							// click it?
						}
					});
					break;
				case "place-hide":
					var placeId = parseInt(this.getAttribute("data-place-id"));
					togglePlaceActive(placeId, false, true);
					$(domNodes.infoDialogs.mapItemInformation).dialog("close");
					break;
				case "place-route-asset":
					var placeId = parseInt(this.getAttribute("data-place-id"));
					var place = findPlaceById(placeId);
					openPlaceRoutingForPlace(place);
					break;
				case "fence-hide":
					toggleFenceActive(this.getAttribute("data-fence-id"), false, true);
					$(domNodes.infoDialogs.mapItemInformation).dialog("close");
					break;
				case "fence-send-message":
					var fence = findFenceById(this.getAttribute("data-fence-id"));
					openSendMessageDialog(null, null, fence);
					break;
				case "fence-create-alert":
					var fence = findFenceById(this.getAttribute("data-fence-id"));
					var assetIds = findAssetIdsInGeofence(fence);
					window.location = "/Alerts/CreateAlert?assetIds=" + assetIds.join() + "&fenceId=" + fence.Id;
					break;
				case "fence-group-assets":
					var fence = findFenceById(this.getAttribute("data-fence-id"));
					openAssetGroupDialog(null);
					var assetIds = findAssetIdsInGeofence(fence);
					var assets = new Array();
					for (var i = 0; i < assetIds.length; i++) {
						var asset = findAssetById(assetIds[i]);
						if (asset == null) {
							continue;
						}
						assets.push(asset);
						$("#edit-asset-group-assets-list input[value=" + asset.Id + "]").prop("checked", true);
					}
					break;
				case "fence-location-report":
					window.location = "/Reports/Location?fenceId=" + this.getAttribute("data-fence-id");
					break;
				case "fence-history":
					var fence = findFenceById(this.getAttribute("data-fence-id"));
					var assetIds = findAssetIdsInGeofence(fence);
					loadHistory(assetIds);
					break;
				case "waypoint-route-asset":
					var waypoint = findWaypointById(parseInt(this.getAttribute("data-waypoint-id")));
					waypointGetRoute(waypoint);
					break;
				case "waypoint-mark-complete":
					var waypoint = findWaypointById(parseInt(this.getAttribute("data-waypoint-id")));
					waypointMarkComplete(waypoint);
					break;
				case "shared-view-status":
					var sharedView = findSharedViewById(parseInt(this.getAttribute("data-shared-view-id")));
					changeSharedViewStatus(sharedView);
					break;
				case "shared-view-options":
					var sharedView = findSharedViewById(parseInt(this.getAttribute("data-shared-view-id")));
					openSharedViewSettingsPanel(sharedView);
					break;
				default:
					console.log("no action handler for: " + this.getAttribute("data-action"));
					break;
			}
			break;
		case "list-sort":
			//// for asset notifications, since the sorting options are outside of the list containers
			//var sortBy = this.getAttribute('data-sort');
			//var isActive = this.classList.contains('active');
			//var sortDirection = this.classList.contains('desc') ? 'asc' : 'desc'; // toggle sort direction if active
			//this.classList.remove('desc');
			//this.classList.remove('asc');
			//this.classList.add(sortDirection);
			//this.classList.add('active');
			//// todo: toggle other active options in this group (when we have more than 1 option!)
			//if (!isActive) {
			//    sortDirection = 'asc';
			//}
			//this.setAttribute('data-sort-dir', sortDirection);
			//if (domNodes.panels.secondary.currentList !== undefined) {
			//    domNodes.panels.secondary.currentList.sort(sortBy, { order: sortDirection });
			//}
			break;
		default:
			console.warn("Unknown panel option type: " + this.getAttribute("data-option-type"));
			break;
	}
}

export function changeActivePanel(toPanel, keepHidden) {
	var doCloseSecondaryPanel = false;
	console.log("changeActivePanel", state.activePanel, toPanel, state.activeMapMode);
	if (state.activePanel !== toPanel) {
		// when switching between panels, close the secondary panel if it does not apply to the
		// newly active panel
		closePrimaryNavigation();
		console.log("active panel change: " + state.activePanel + "-" + toPanel);
		doCloseSecondaryPanel = true;

		// not convinced this is the best time/place to swap view modes
		if (toPanel === panels.SHARED_VIEWS) {
			switchViewMode(viewModes.SHARED_VIEW);
		} else if (state.activePanel === panels.SHARED_VIEWS) {
			switchViewMode(viewModes.NORMAL);
		}
	}
	state.activePanel = toPanel;
	var primaryPanel = domNodes.panels.primary;
	$(".nav-panel-content", primaryPanel).removeClass("is-visible");
	// content panels are shared except for ADD/GEOFENCES/PLACES
	var contentMode = toPanel;
	var panelFilters = [
		{
			panel: panels.ASSETS,
			filter: document.getElementById("filter-assets"),
			options: document.getElementById("panel-options-assets"),
		},
		{
			panel: panels.GEOFENCES,
			filter: document.getElementById("filter-fences"),
			options: document.getElementById("panel-options-fences"),
		},
		{
			panel: panels.PLACES,
			filter: document.getElementById("filter-places"),
			options: document.getElementById("panel-options-places"),
		},
		{
			panel: panels.JOURNEYS,
			filter: document.getElementById("filter-journeys"),
			options: document.getElementById("panel-options-journeys"),
		},
		{
			panel: panels.SHARED_VIEWS,
			filter: document.getElementById("filter-shared-views"),
			options: document.getElementById("panel-options-shared-views"),
		},
	];
	var itemsForType = _.keyBy(panelFilters, "panel");

	_.each(primaryPanel.querySelectorAll(".filter-container"), function (item) {
		if (itemsForType[toPanel] !== undefined && itemsForType[toPanel].filter === item) {
			// only show filter box if there's contents
			item.classList.add("is-visible");
		} else {
			item.classList.remove("is-visible");
		}
	});
	_.each(primaryPanel.querySelectorAll(".panel-options-container"), function (item) {
		if (itemsForType[toPanel] !== undefined && itemsForType[toPanel].options === item) {
			item.classList.add("is-visible");
		} else {
			item.classList.remove("is-visible");
		}
	});

	if (contentMode === undefined) {
		return;
	}
	setUrlHistoryForActivePanel();
	$(".nav-panel-content[data-panel=" + contentMode + "]", primaryPanel).addClass("is-visible");
	var navPanel = $("ul.nav-panel")[0];
	$("li", navPanel).removeClass("nav-active");
	$("li[data-panel=" + toPanel + "]", navPanel).addClass("nav-active");
	var panelHeader = $(".panel-header", primaryPanel)[0];
	$(".panel-title", panelHeader).removeClass("is-visible");
	$(".panel-title[data-panel=" + toPanel + "]", panelHeader).addClass("is-visible");
	domNodes.simpleBars.primary.recalculate();

	if (doCloseSecondaryPanel && state.openPanels.secondary) {
		closeSecondaryPanel();
		if (keepHidden) {
			hidePrimaryPanel();
		}
	} else {
		// if a notification panel is open, its content must be refreshed
		updateSecondaryPanelNotificationContentForNewMode();
	}
}

export function updateSecondaryPanelNotificationContentForNewMode() {
	if (domNodes.panels.secondary.getAttribute("data-group-for") !== "dialog") {
		return;
	}

	var notifyForType = domNodes.panels.secondary.getAttribute("data-item-type");

	var secondaryDialog = document.getElementById("dialog-functions").querySelector(".dialog");

	switch (notifyForType) {
		case "assets":
			var assetId = parseInt(domNodes.panels.secondary.getAttribute("data-item-id"));
			var asset = findAssetById(assetId);
			if (asset === undefined || asset === null) {
				return;
			}
			if (secondaryDialog === domNodes.dialogs.assetPositions) {
				openPositionsForAsset(asset);
			} else if (secondaryDialog === domNodes.dialogs.assetEvents) {
				openEventsForAsset(asset);
			} else if (secondaryDialog === domNodes.dialogs.assetMessages) {
				openMessagesForAsset(asset);
			} else if (secondaryDialog === domNodes.dialogs.assetChat) {
				openChatForAsset(asset);
			} else if (secondaryDialog === domNodes.dialogs.assetStatus) {
				openStatusForAsset(asset);
			} else if (secondaryDialog === domNodes.dialogs.assetAlerts) {
				openAlertsForAsset(asset);
			} else if (secondaryDialog === domNodes.dialogs.assetActivity) {
				openActivityForAsset(asset);
			}
			break;
		case "groups":
			var groupId = domNodes.panels.secondary.getAttribute("data-item-id");
			var group = findGroupById(groupId);
			if (secondaryDialog === domNodes.dialogs.assetPositions) {
				openPositionsForGroup(group);
			} else if (secondaryDialog === domNodes.dialogs.assetEvents) {
				openEventsForGroup(group);
			} else if (secondaryDialog === domNodes.dialogs.assetMessages) {
				openMessagesForGroup(group);
			} else if (secondaryDialog === domNodes.dialogs.assetStatus) {
				openStatusForGroup(group);
			} else if (secondaryDialog === domNodes.dialogs.assetAlerts) {
				openAlertsForGroup(group);
			} else if (secondaryDialog === domNodes.dialogs.assetChat) {
				openChatForGroup(group);
			} else if (secondaryDialog === domNodes.dialogs.assetActivity) {
				openActivityForGroup(group);
			}
			break;
	}
}

export function highlightActiveItemInPrimaryPanel(itemType, id) {
	var settingsIcon = null;
	switch (itemType) {
		case "assets":
			var assetNodes = domNodes.assets[id];
			_.each(assetNodes, function (assetNode) {
				assetNode.classList.add("settings-active");
				settingsIcon = assetNode.querySelector(".item-settings");
			});
			break;
		case "groups":
			var groupNode = domNodes.groups[id];
			groupNode.classList.add("settings-active");
			settingsIcon = groupNode.querySelector(".item-settings");
			break;
		case "journeys":
			var groupNode = domNodes.groups["journey-" + id];
			groupNode.classList.add("settings-active");
			settingsIcon = groupNode.querySelector(".item-settings");
			break;
		case "fences":
			var fenceNode = domNodes.fences[id];
			fenceNode.classList.add("settings-active");
			settingsIcon = fenceNode.querySelector(".item-settings");
			break;
		case "places":
			var placeNode = domNodes.places[id];
			placeNode.classList.add("settings-active");
			settingsIcon = placeNode.querySelector(".item-settings");
			break;
		case "trips":
			var tripNode = domNodes.trips[id];
			tripNode.classList.add("settings-active");
			settingsIcon = tripNode.querySelector(".item-settings");
			break;
		case "shared-views":
			var sharedViewNode = domNodes.sharedViews[id];
			sharedViewNode.classList.add("settings-active");
			settingsIcon = sharedViewNode.querySelector(".item-settings");
			break;
		default:
			break;
	}
	if (settingsIcon !== null) {
		if (itemType === "groups" || itemType === "journeys") {
			settingsIcon
				.querySelector("use")
				.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#group-settings-solid");
		} else {
			settingsIcon
				.querySelector("use")
				.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#settings-solid");
		}
	}
}

function openRunReportModal() {
	trkData.validation.runReport.resetForm();
	trkData.validation.runReport.currentForm.reset();

	var reportAssets = trkData.assets;
	populateCheckboxList(
		"run-report-assets-list",
		reportAssets,
		"AssetIds",
		function (item) {
			return _.indexOf(trkData.visible.assets, item.Id) !== -1;
		},
		function (item) {
			return item.Name;
		},
		"assets",
		function (item) {
			return item.UniqueId;
		}
	);

	// populate date/time if in history mode
	if (state.activeMapMode === mapModes.HISTORY) {
		$("#RunReportFrom").val($("#txtDateFrom").val());
		$("#RunReportTo").val($("#txtDateTo").val());
		if ($("#RunReportTo").val() === "") {
			$("#RunReportTo").datetimepicker("setDate", moment().toDate());
		}
	}

	runReportOptionChanged();

	$(domNodes.modals.runReport).modal("show");
}

export function runReportOptionChanged() {
	var option = $("#RunReportReport option:selected")[0];
	var needsDateRange = option.getAttribute("data-date-range") === "true";
	var dateRange = document.getElementById("RunReportDateRange");
	if (needsDateRange) {
		dateRange.classList.add("is-visible");
	} else {
		dateRange.classList.remove("is-visible");
	}
	$("#run-report-form")[0].setAttribute("action", "/reports/" + option.value);
}
