import { viewModes, trkDataGroups } from "./const.js";
import { extendBounds } from "./map-bounds.js";
import { addItemToMap } from "./map-items.js";
import { markerClick, markerUnhover, markerHover } from "./marker-click.js";
import trkData from "./data.js";
import { getAssetDataGroupForCurrentViewMode } from "./map-viewmode.js";
import options from "./options.js";
import user from "./user.js";
import AssetSprite from "./gleo-asset-sprite.js";

import L from "leaflet";
import _ from "lodash";

export function addPositionMarkerToPoint(
	latlng,
	userAdded,
	location,
	asset,
	alpha,
	isCluster,
	isFirst,
	isLast,
	dataGroup,
	trip,
	sharedViewId
) {
	if (isCluster === undefined || isCluster === null) {
		isCluster = false;
	}
	if (latlng === undefined || latlng === null) {
		return null;
	}
	extendBounds(L.latLng(latlng));

	var color = asset.Color;
	if (color === undefined || color === null) {
		color = "";
	}
	var course = location.Course;
	if (course === undefined || course === null) {
		course = "";
	}
	var evtType = getHighestPriorityEventType(location.Events);
	// var imagePath = createMarkerPath(asset.Class, color, course, alpha, asset.Id, false, evtType, isFirst, isLast);
	// var icon = L.icon({
	// 	iconUrl: imagePath,
	// 	iconSize: [36, 36],
	// 	iconAnchor: [18, 18],
	// });
	// var marker = L.marker(latlng, { icon: icon });

	const marker = new AssetSprite(latlng, asset.Class, color, course, alpha, asset.Id, evtType, isFirst, isLast);

	marker.data = {
		location: null,
		assetId: null,
		alpha: null,
		isFirst: null,
		isLast: null,
		selected: null,
		message: null,
		tripId: null,
		sharedViewId: null,
		isHidden: false,
	};

	if (!isCluster && !location.IsHidden) {
		var viewMode = null;
		if (dataGroup === trkDataGroups.SHARED_VIEW_HISTORY || (sharedViewId !== undefined && sharedViewId !== null)) {
			viewMode = viewModes.SHARED_VIEW;
		}
		if (dataGroup === getAssetDataGroupForCurrentViewMode() || dataGroup === trkDataGroups.JOURNEY_HISTORY) {
			addItemToMap(marker, null, viewMode);
		}
	}
	//location.marker = marker; // associate marker with location so that it can be hidden/shown

	if (location !== null) {
		marker.data.location = location;
	}
	if (asset !== null) {
		marker.data.assetId = asset.Id;
	}
	if (trip !== undefined && trip !== null) {
		marker.data.tripId = trip.Id;
	}
	if (sharedViewId !== undefined && sharedViewId !== null) {
		marker.data.sharedViewId = sharedViewId;
	}
	if (alpha !== undefined && alpha !== null) {
		marker.data.alpha = alpha;
	}
	if (isFirst !== undefined && isFirst !== null && isFirst) {
		marker.data.isFirst = true;
	}
	if (isLast !== undefined && isLast !== null && isLast) {
		marker.data.isLast = true;
	}
	marker.on("pointerover", function (e) {
		markerHover(marker);
	});
	marker.on("pointerout", function (e) {
		markerUnhover(marker);
	});
	marker.on("click", function (ev) {
		markerClick(marker, "position", null, true);

		// Prevent propagation to the platina in order to prevent closing a cluster
		// spider when clicking on one of its members
		ev.stopPropagation();
	});
	// we don't simply check the current mode as results may come back asynchronously
	// so the current mode might not be the intended one
	var markers = getMapMarkersForDataGroup(dataGroup);
	if (markers !== undefined) {
		markers.push(marker);
	}
	return marker;
}

export function getMapMarkersForDataGroup(dataGroup) {
	switch (dataGroup) {
		// normal mode
		case trkDataGroups.NORMAL_LIVE:
			return Object.values(trkData.live.markersByAssetId).flat();
		case trkDataGroups.NORMAL_HISTORY:
			return trkData.history.markers;
		case trkDataGroups.JOURNEY_HISTORY:
			return trkData.trips.markers;
		// shared view mode
		case trkDataGroups.SHARED_VIEW_HISTORY:
			return trkData.sharedView.markers;
		case trkDataGroups.SHARED_VIEW_JOURNEY:
			return trkData.sharedView.trips.markers;
		//case trkDataGroups.BEHAVIOR_ANALYSIS:
		//    return trkData.behaviorAnalysis.markers;
		//case trkDataGroups.FOLLOW_ASSET:
		//    return trkData.followAsset.markers;
		default:
			console.warn("Unknown map marker group: " + dataGroup);
			return undefined;
	}
}

export function getHighestPriorityEventType(events) {
	if (events == null) return null;

	// indicator priority
	// emergency, alert triggered, checkin, reset, enter fence, exit fence, start, stop
	var evtTypePriority = [9, 14, 15, 10, 7, 8, 1, 2, 375, 376];
	var evtType = null;
	_.each(events, function (evt) {
		if (evt.Hide) {
			return;
		}
		if (user.isAnonymous || options.hideEmergencyEvents) {
			if (evt.Type === 9 || evt.Type === 124 || evt.Type === 126 || evt.Type === 29) {
				return;
			}
		}
		if (user.isAnonymous || options.hideAlertTriggeredEvents) {
			if (evt.Type === 14 || evt.Type === 16) {
				return;
			}
		}
		if (evtType === null) {
			evtType = evt.Type;
			return;
		}
		var priority = _.indexOf(evtTypePriority, evt.Type);
		if (priority !== -1) {
			var currentPriority = _.indexOf(evtTypePriority, evtType);
			if (priority < currentPriority || currentPriority === -1) {
				evtType = evt.Type;
			}
		}
	});
	return evtType;
}
