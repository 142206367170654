import HeatMap from "./HeatMap.mjs";
// import {ScalarField} from "./Field.mjs";
import Acetate from "../acetates/Acetate.mjs";
import { scale } from "../3rd-party/gl-matrix/mat3.mjs";

/**
 * @class QuadBin
 * @inherits HeatMap
 *
 * As `HeatMap`, but using a scalar field with a much lower resolution.
 *
 * This is meant to use `intensify`d `Dot`s exclusively. Any other symbols (e.g.
 * `HeatPoint`s) will be scaled up by a factor equal to the cell size.
 *
 * @example
 * ```js
 * import QuadBin from "gleo/fields/QuadBin.mjs";
 * import intensify from "gleo/symboldecorators/intensify.mjs";
 * import Dot from "gleo/symbols/Dot.mjs";
 *
 * const IntensityDot = intensify(Dot);
 *
 * const heatbin = new QuadBin(map, {
 * 	// colour stops, cell size, etc
 * });
 *
 * new IntensityDot(geometry, { intensity: 100 }).addTo(heatbin);
 * ```
 */

export default class QuadBin extends HeatMap {
	#cellSize;
	_offset;

	/**
	 * @constructor QuadBin(target: GliiFactory, opts?: QuadBin Options)
	 */
	constructor(
		target,
		{
			/**
			 * @option cellSize: Number = 32
			 * The size of cells, in CSS pixels.
			 */
			cellSize = 32,

			...opts
		} = {}
	) {
		super(target, opts);

		this.#cellSize = cellSize;
	}

	/// @property cellSize: Number
	/// The cell size, as defined by the homonymous option during instantiation. Read-only.
	get cellSize() {
		return this.#cellSize;
	}

	getFieldValueAt(x, y) {
		const floorX = Math.floor((this._offset[0] + x) / this.#cellSize);
		const floorY = Math.ceil((this._offset[1] + y) / this.#cellSize);

		return super.getFieldValueAt(floorX, floorY);
	}

	glProgramDefinition() {
		const opts = super.glProgramDefinition();

		return {
			...opts,
			vertexShaderMain: `
				gl_Position = vec4(aPos * uFactor, 0., 1.);
				vUV = aUV;
			`,
			uniforms: {
				uFactor: "vec2",
			},
		};
	}

	resize(x, y) {
		// This resizes both the scalar field and the acetate RGBA output framebuffer,
		// so the RGBA framebuffer is resized back, after the super() call is done.

		// Note this rounds up the size of the scalar field, thus (potentially)
		// making the cell size slightly smaller than the desired value.
		const cellX = Math.ceil(x / this.#cellSize);
		const cellY = Math.ceil(y / this.#cellSize);
		super.resize(cellX, cellY);

		// Size, in CSS pixels, of the quadbin's catchment area.
		const oversizeX = cellX * this.#cellSize;
		const oversizeY = cellY * this.#cellSize;

		this._factor = [x / oversizeX, y / oversizeY];
		this._offset = [(oversizeX - x) / 2, (oversizeY - y) / 2];

		this._program.setUniform(
			"uFactor",
			this._factor.map((n) => 1 / n)
		);

		Acetate.prototype.resize.call(this, x, y);
	}

	redraw(crs, matrix, viewportBbox) {
		// This will expand the affine transformation matrix and the viewport
		// bounding box by the quadbin's factor, in order to aggregate data
		// outside the visible bounds.
		// i.e. even if a data point falls just outside the visible bounds, *but*
		// inside a cell's catchment area, it has to be drawn into the scalar field.

		let box = viewportBbox
			.clone()
			.expandPercentages(this._factor[0] - 1, this._factor[1] - 1);

		let expandMatrix = scale(new Array(9), matrix, this._factor);

		return super.redraw(crs, expandMatrix, box);
	}
}
