import strings from "./strings.js";
import trkData from "./data.js";
import domNodes from "./domNodes.js";
import log from "./log.js";
import { findAssetById } from "./assets.js";
import { formShowSuccessMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import user from "./user.js";
import { wrapUrl } from "./wrapurl.js";
import { loadDialogButtons, closeButton } from "./modal-dialog-buttons.js";
import { getPositionLinkForEvent } from "./positions.js";
import { openDialogPanel } from "./panel-nav.js";
import { handleAjaxFormSubmission, toggleLoadingMessage } from "./ajax.js";
import { closeSecondaryPanel } from "./panel.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import { el, text } from "redom"; // https://redom.js.org/

export function initAlerts() {
	// unused TODO: remove
	$("#alert-data").DataTable({
		destroy: true,
		filter: false,
		info: true,
		jQueryUI: true,
		autoWidth: false,
		lengthChange: false,
		paging: true,
		pagingType: "full_numbers",
		deferRender: true,
		processing: false,
		order: [[1, "desc"]],
		columns: [
			{ sortable: false, width: "40px" }, // Color Display
			{ sortable: true }, // Priority
			{ sortable: true }, // Asset
			{ sortable: true }, // Type
			{ sortable: false }, // Description
			{ sortable: false }, // Resolution Procedure
			{ sortable: false }, // Position/lat/lng
			//{ sortable: false }, // Details/link
			{ width: "75px" }, // Time
			{ sortable: false, class: "center" }, // Acknowledge
			{ visible: false }, // AssetId (hidden)
			{ visible: false }, // Event Type Id
			{ visible: false }, // Color
		],
		dom: '<"H"lfr>t<"F"ip>',
		language: strings.DATATABLE,
		initComplete: function (oSettings, json) {
			var api = this.api();
			log("Alerts table initialized.");
			$j("#alert-data").data("init", true);
			trkData.alerts = [];
			queryAlertsRequiringAcknowledgement(null);
			api.clear();
		},
		drawCallback: function (oSettings) {
			var api = this.api();
			var rowCount = api.rows({ search: "applied" }).eq(0).length;
			if (rowCount > 0) {
				$j("#alerts-control-btn a .badge,#event-panel-tab-alerts .badge")
					.text(rowCount)
					.addClass("active")
					.removeClass("inactive");
			} else {
				$j("#alerts-control-btn a .badge,#event-panel-tab-alerts .badge")
					.text(rowCount)
					.removeClass("active")
					.addClass("inactive");
			}

			//$j('#alerts-control-btn a,#event-panel-tab-alerts').text(strings.ALERTS_HEADER.replace('{0}', api.rows({ search: 'applied' }).eq(0).length));

			//$j('.AlertAcknowledge').button({ text: null, icons: { primary: 'ui-icon-check' } });
		},
		rowCallback: function (row, data, index) {
			if (data[11] != "") {
				$j("td:eq(0)", row).css("background-color", data[11]);
			}
		},
	});

	var acknowledgeAlertButtons = [
		{
			id: "AcknowledgeDialog",
			text: strings.ACKNOWLEDGE,
			click: function () {
				acknowledgeAssetAlert(this, true);
			},
		},
		{
			id: "AcknowledgeAltDialog",
			buttonType: "secondary",
			class: "alert-acknowledge-alt",
			text: strings.ACKNOWLEDGE_ALT,
			click: function () {
				acknowledgeAssetAlert(this, true);
			},
		},
		closeButton,
	];
	loadDialogButtons(domNodes.dialogs.acknowledgeAlert, acknowledgeAlertButtons);
	$(domNodes.dialogs.acknowledgeAlert).on("click", "#alert-prev", function (e) {
		e.preventDefault();
		openAlertInDialog(-1);
	});
	$(domNodes.dialogs.acknowledgeAlert).on("click", "#alert-next", function (e) {
		e.preventDefault();
		openAlertInDialog(1);
	});

	$("#panel-secondary").on("click", "button.alert-acknowledge,button.alert-acknowledge-alt", function (e) {
		e.preventDefault();
		acknowledgeAssetAlert(this, false);
	});

	$j(domNodes.infoDialogs.mapItemInformation).on("click", "button.AlertAcknowledge", function (e) {
		e.preventDefault();

		acknowledgeAssetAlert(this, false);
	});

	$j(domNodes.infoDialogs.mapItemInformation).on("click", "button.AlertAcknowledgeAlt", function (e) {
		e.preventDefault();

		acknowledgeAssetAlert(this, false);
	});

	let assetsContainer = $j("#panel-content-wrapper");

	assetsContainer.on("click", "svg.asset-alert", function (e) {
		e.preventDefault();

		var asset = findAssetById(parseInt(this.getAttribute("data-asset-id")));
		if (asset == null) return;
		var alert = null;
		for (var i = 0; i < trkData.alerts.length; i++) {
			if (trkData.alerts[i].AssetId == asset.Id) {
				alert = trkData.alerts[i];
				break;
			}
		}
		if (alert == null) return;
		openAcknowledgeAlertDialog(asset, alert);
		//openAlertsRequiringAcknowledgementDialog(alt);
	});
}

function updateAlertDialog(event) {
	var form = document.getElementById("acknowledge-form");
	var currentEventId = parseInt(form.getAttribute("data-event-id"));
	var currentAssetId = parseInt(form.getAttribute("data-asset-id"));
	var currentIndex = parseInt(form.getAttribute("data-index"));

	var asset = findAssetById(currentAssetId);
	if (asset === null) {
		return;
	}

	var assetAlerts = _.filter(trkData.alerts, function (evt) {
		return evt.AssetId === asset.Id;
	});
	if (event.Id === currentEventId) {
		// the currently opened event was acknowledged
		// move to the next one, if available, with an acknowledged status
		// and show a no more if not
		if (assetAlerts.length === 0) {
			// no more events requiring acknowledgement for the asset
			var none = document.getElementById("asset-acknowledge-alerts-none");
			none.classList.add("is-visible");
			form.classList.remove("is-visible");
		} else {
			var newIndex = currentIndex;
			if (currentIndex > assetAlerts.length) {
				newIndex = assetAlerts.length;
			}
			var event = assetAlerts[newIndex - 1];
			if (event !== undefined && event !== null) {
				openAcknowledgeAlertDialog(asset, event);

				// persist acknowledged message
				formShowSuccessMessage(form.querySelector(".dialog-status"), strings.MSG_ALERT_ACKNOWLEDGE_SUCCESS);
			}
		}
	} else {
		if (event.AssetId === currentAssetId) {
			// number of events is updated, update the indexes shown
			var currentEvent = _.find(assetAlerts, { Id: currentEventId });
			populateAssetAlertIndex(asset, currentEvent);
		}
	}
}

function confirmAlert(event) {
	log("Confirming alert.");
	var isInAlertsListing = false;
	for (var i = 0; i < trkData.alerts.length; i++) {
		if (event.Id === trkData.alerts[i].Id) {
			trkData.alerts.splice(i, 1);
			isInAlertsListing = true;
			break;
		}
	}

	// update dialogs, if they are open
	updateAlertDialog(event);

	// update position information dialog (if exists)
	var form = $("#AcknowledgeAlert" + event.Id + ",#asset-alert-acknowledge-" + event.Id);
	if (form.length) {
		$("div.alert-resolution", form)
			.empty()
			.append(
				$('<div class="form-group">')
					.append($("<label>").text(strings.RESOLUTION))
					.append($('<span class="form-control-plaintext">').text(event.Alert.AcknowledgedText))
			)
			.append(
				$('<div class="form-group">')
					.append($("<label>").text(strings.STATUS))
					.append(
						$('<span class="form-control-plaintext">').text(
							strings.ACKNOWLEDGE_STATUS.replace("{Status}", event.Alert.AcknowledgedStatus)
								.replace("{Time}", event.Alert.AcknowledgedOn)
								.replace("{User}", event.Alert.AcknowledgedBy)
						)
					)
			);

		$("div.alert-acknowledge", form).remove();
	}

	var alertIcons = $('svg.alert-acknowledge-icon[data-event-id="' + event.Id + '"]');
	_.each(alertIcons, function (icon) {
		$(icon)
			.removeClass("pending-acknowledgement")
			.addClass("is-acknowledged")
			.find("use")
			.get(0)
			.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#check-circle-solid");
	});
	// todo: reindex the list, if it's visible

	// close notification (if any)
	$(".jGrowl-notification[data-event-id=" + event.Id + "]").trigger("jGrowl.close");

	if (isInAlertsListing) {
		// todo: call fnDeleteRow instead of redrawing whole table?
		updateAlertsListing();
	}
}

function updateAlertsListing() {
	//if ($j('#alert-data').data('init') != true) {
	//    return;
	//}

	var events = trkData.alerts;
	var assetAlerts = [];
	//var eventTable = $j('#alert-data').DataTable();

	//eventTable.clear();
	//var tableData = [];
	for (var i = 0; i < events.length; i++) {
		var item = events[i];
		//var row = mapAssetEventToTableRow(item, true);
		//if (row !== undefined && row !== null) {
		//    tableData.push(row);
		//}
		if ($j.inArray(item.AssetId, assetAlerts) == -1) {
			assetAlerts.push(parseInt(item.AssetId));
		}
	}
	//eventTable.rows.add(tableData).draw();
	//var rowCount = eventTable.rows({ search: 'applied' }).eq(0).length;
	//if (rowCount > 0) {
	//    $j('#alerts-control-btn a .badge,#event-panel-tab-alerts .badge').text(rowCount).addClass('active').removeClass('inactive');
	//} else {
	//    $j('#alerts-control-btn a .badge,#event-panel-tab-alerts .badge').text(rowCount).removeClass('active').addClass('inactive');
	//}

	var assetAlert = document.createElementNS("http://www.w3.org/2000/svg", "svg");
	assetAlert.classList.add("asset-alert");
	assetAlert.classList.add("notify-alert");
	var assetAlertTitle = document.createElementNS("http://www.w3.org/2000/svg", "title");
	assetAlertTitle.textContent = strings.ACKNOWLEDGE_ALERT;
	assetAlert.appendChild(assetAlertTitle);
	var assetAlertType = document.createElementNS("http://www.w3.org/2000/svg", "use");
	assetAlertType.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#notify-alert");
	assetAlert.appendChild(assetAlertType);

	_.each(domNodes.assets, function (assetNodes, index, list) {
		_.each(assetNodes, function (assetNode, nodeIndex, nodeList) {
			var alertIndicator = assetNode.querySelector(".asset-alert");
			if (alertIndicator !== null) {
				alertIndicator.parentNode.removeChild(alertIndicator);
			}
			if (_.indexOf(assetAlerts, parseInt(index)) !== -1) {
				var indicators = assetNode.querySelector(".asset-indicators");
				var alertIndicator = assetAlert.cloneNode(true);
				alertIndicator.setAttribute("data-asset-id", index);
				indicators.appendChild(alertIndicator);
			}
		});
	});
	assetAlert = null;
	// assetAlertLink = null;
	// assetAlertIcon = null;
}

function openAlertInDialog(offset) {
	var eventId = $("#hfAcknowledgeEventId").val();
	var assetId = $("#hfAcknowledgeAssetId").val();
	var asset = findAssetById(assetId);
	var indexes = getAssetAlertIndex(asset, eventId);
	var newIndex = indexes.index + offset - 1;
	console.log(newIndex);
	console.log(indexes);
	var assetEvents = _.filter(trkData.alerts, function (item) {
		return item.AssetId === asset.Id;
	});
	var event = assetEvents[newIndex];
	if (event !== undefined && event !== null) {
		openAcknowledgeAlertDialog(asset, event);
	}
}

export function queryAlertsRequiringAcknowledgement(defaultEvent) {
	if (user.isAnonymous) {
		return;
	}

	let eventId;
	if (trkData.lastAlertId == null) {
		eventId = defaultEvent;
	} else {
		eventId = trkData.lastAlertId;
	}

	var dataPost = { eventId: eventId };
	$j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetAlertsRequiringAcknowledgment"),
		data: JSON.stringify(dataPost),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg.d) {
				var events = msg.d;
				var eventsBefore = trkData.alerts.length;
				//if (events.length > 0)
				//    console.log(events);
				addAssetAlerts(events);
				if (trkData.alerts.length != eventsBefore) {
					// flash update notification
					var currBg = $j("#event-panel-tab-alerts").css("background-color");
					$j("#event-panel-tab-alerts")
						.stop()
						.css("background-color", "#ffff9c")
						.animate({ backgroundColor: currBg }, 3000, function () {
							$j("#event-panel-tab-alerts").css("background-color", "");
						});
					$j("#alerts-control-btn")
						.stop()
						.css("background-color", "#ffff9c")
						.animate({ backgroundColor: "#ffffff" }, 3000);
				}
			}
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_ALERTS_ERROR);
		},
	});
}

function acknowledgeAssetAlert(btn, fromDialog) {
	var isAlternative = btn.classList.contains("alert-acknowledge-alt");
	var form = $(btn).closest("form");
	var buttons = _.toArray(form[0].querySelectorAll("button"));
	var isFormValid = form.valid();
	if (!isFormValid) {
		return;
	}

	var assetId = parseInt(form[0].getAttribute("data-asset-id"));
	var eventId = parseInt(form[0].getAttribute("data-event-id"));
	var resolution = $(".alert-resolution textarea", form).val();
	var data = {
		assetId: assetId,
		eventId: eventId,
		resolution: resolution,
		alternative: isAlternative,
	};
	var status = $(".dialog-status", form)[0];
	handleAjaxFormSubmission(
		"AcknowledgeAlert",
		data,
		buttons,
		status,
		strings.MSG_ALERT_ACKNOWLEDGE_SUCCESS,
		strings.MSG_ALERT_ACKNOWLEDGE_ERROR,
		function (result) {
			if (fromDialog) {
				// move to next alert in acknowledge dialog if it is open for the asset
				var asset = findAssetById(assetId);
				var indexes = getAssetAlertIndex(asset, eventId);
				updateAcknowledgedEventData(result.AssetEvent);

				if (indexes.total > 1) {
					// move to next alert in dialog (same index)
					if (indexes.index >= indexes.total) {
						indexes.index = indexes.total;
					}
					var assetAlerts = _.filter(trkData.alerts, function (evt) {
						return evt.AssetId === asset.Id;
					});
					var event = assetAlerts[indexes.index - 1];
					if (event !== undefined && event !== null) {
						openAcknowledgeAlertDialog(asset, event);
					} else {
						closeSecondaryPanel(); // open asset options instead?
					}
				} else {
					// no more events requiring acknowledgement for the asset
					var none = document.getElementById("asset-acknowledge-alerts-none");
					none.classList.add("is-visible");
					form[0].classList.remove("is-visible");
				}
			} else {
				updateAcknowledgedEventData(result.AssetEvent);
			}
		}
	);
}

export function openAcknowledgeAlertDialog(asset, event) {
	var alert = null;
	let assetEvents;
	if (event === undefined || event === null) {
		// find first alert triggered event to acknowledge for this asset
		assetEvents = _.filter(trkData.alerts, function (evt) {
			return evt.AssetId === asset.Id;
		});
		if (assetEvents !== undefined && assetEvents.length > 0) {
			event = assetEvents[0];
		}
	} else {
		if (asset === undefined || asset === null) {
			asset = findAssetById(event.AssetId);
		}
	}

	if (event.Alert === undefined || event.Alert === null) {
		return;
	}

	trkData.validation.acknowledgeAlert.resetForm();
	trkData.validation.acknowledgeAlert.currentForm.reset();
	$("#hfAcknowledgeEventId").val(event.Id);
	$("#hfAcknowledgeAssetId").val(asset.Id);

	console.log(event);
	var index = populateAssetAlertIndex(asset, event);

	var form = document.getElementById("acknowledge-form");
	form.classList.add("is-visible");
	form.setAttribute("data-asset-id", asset.Id);
	form.setAttribute("data-event-id", event.Id);
	form.setAttribute("data-index", index);

	var none = document.getElementById("asset-acknowledge-alerts-none");
	none.classList.remove("is-visible");

	var type = event.Alert.Type;
	if (event.Alert.Name != null) {
		type = event.Alert.Name + ": " + type;
	}

	$("#AcknowledgeTime").text(event.Time);
	var position = getPositionLinkForEvent(event);
	$("#AcknowledgePosition").empty().append(position);
	if (position != "") {
		$("#AcknowledgePositionContainer").show();
		$("#AcknowledgeDetails").show();
	} else {
		$("#AcknowledgePositionContainer").hide();
		$("#AcknowledgeDetails").hide();
	}

	$("#AcknowledgeType").text(type).parent().parent().css("border-color", "").css("border-color", event.Alert.Color);

	if (event.Alert.Description != null) {
		$("#AcknowledgeDescriptionContainer").show();
	} else {
		$("#AcknowledgeDescriptionContainer").hide();
	}
	if (event.Alert.PhotoType != null && event.Alert.PhotoType != "") {
		$("#alert-photo")
			.prop(
				"src",
				"/uploads/images/alerts/" + event.Alert.Id + "_thumb." + event.Alert.PhotoType + "?rnd=" + new Date().getTime()
			)
			.show();
	} else {
		$("#alert-photo").hide();
	}
	$("#AcknowledgeDescription").text(event.Alert.Description);
	if (event.Alert.ResolutionProcedure != null) {
		$("#AcknowledgeProcedureContainer").show();
	} else {
		$("#AcknowledgeProcedureContainer").hide();
	}

	if (event.Alert.LabelAcknowledge != null) {
		$("#AcknowledgeDialog")[0].textContent = event.Alert.LabelAcknowledge;
		// $('#AcknowledgeDialog').button('option', 'label', event.Alert.LabelAcknowledge);
	} else {
		$("#AcknowledgeDialog")[0].textContent = strings.ACKNOWLEDGE;
		//$('#AcknowledgeDialog').button('option', 'label', strings.ACKNOWLEDGE);
	}
	if (event.Alert.LabelAcknowledgeAlt != null) {
		$("#AcknowledgeAltDialog")[0].textContent = event.Alert.LabelAcknowledgeAlt;
		//$('#AcknowledgeAltDialog').button('option', 'label', event.Alert.LabelAcknowledgeAlt);
	} else {
		$("#AcknowledgeAltDialog")[0].textContent = strings.ACKNOWLEDGE_ALT;
		//$('#AcknowledgeAltDialog').button('option', 'label', strings.ACKNOWLEDGE_ALT);
	}

	$("#AcknowledgeResolutionProcedure").text(event.Alert.ResolutionProcedure);

	openDialogPanel(
		domNodes.dialogs.acknowledgeAlert,
		strings.ACKNOWLEDGE_ALERT,
		asset,
		false,
		null,
		"asset",
		"acknowledge-alert",
		openAcknowledgeAlertDialog
	);
}

function populateAssetAlertIndex(asset, event) {
	var indexes = getAssetAlertIndex(asset, event.Id);
	$j("#alert-prev,#alert-next").removeClass("disabled");
	if (indexes.index === 1) {
		$j("#alert-prev").addClass("disabled");
	}
	if (indexes.index === indexes.total) {
		$j("#alert-next").addClass("disabled");
	}
	$j("#alert-index").text(strings.POSITION_INDEX.replace("{0}", indexes.index).replace("{1}", indexes.total));
	return indexes.index;
}

function getAssetAlertIndex(asset, eventId) {
	var assetAlerts = _.filter(trkData.alerts, function (evt) {
		return evt.AssetId === asset.Id;
	});
	var totalAlerts = assetAlerts.length;
	var alertIndex = _.findIndex(assetAlerts, { Id: parseInt(eventId) });
	//for (var i = 0; i < totalAlerts; i++) {
	//    if (eventId === assetAlerts[i].Id) {
	//        alertIndex = i + 1;
	//        break;
	//    }
	//}
	return { index: alertIndex + 1, total: totalAlerts };
}

function addAssetAlerts(events) {
	for (var i = 0; i < events.length; i++) {
		var item = events[i];

		if (item.Event != null) item = item.Event;
		if (events[i].Position != null) item.Position = events[i].Position;

		// only add an item if its not already in the list
		var skipItem = false;
		for (var j = 0; j < trkData.alerts.length; j++) {
			if (trkData.alerts[j].Id == item.Id) {
				skipItem = true;
				break;
			}
		}
		if (skipItem) {
			continue;
		}

		// added to global events
		trkData.alerts.push(item);

		var thisId = parseInt(item.Id);
		if (trkData.lastAlertId == null) {
			trkData.lastAlertId = thisId;
		}
		if (trkData.lastAlertId < thisId) {
			trkData.lastAlertId = thisId;
		}

		// add pop-up notification, if necessary
		var event = item;
		if (event.Alert.PopUpNotification) {
			var asset = findAssetById(event.AssetId);
			var name = "";
			if (event.Alert.Name != null) {
				name += event.Alert.Name + ": ";
			}
			name += event.Alert.Type;
			var description = null;
			if (event.Alert.Description != null) {
				description = [text(event.Alert.Description), el("br")];
			}
			var buttons = null;
			var positionElement = null;
			if (event.PositionId != null) {
				// show position button, will likely have to load from ajax or include lat/lng here
				positionElement = el(
					"button.notification-position.btn.btn-link.btn-sm",
					{ dataset: { assetId: asset.Id, positionId: event.PositionId } },
					strings.SHOW_POSITION
				);
			}
			var photo = "";
			if (event.Alert.PhotoType != null && event.Alert.PhotoType != "") {
				photo = "/uploads/images/alerts/" + event.Alert.Id + "_thumb." + event.Alert.PhotoType;
			}
			var msg = [el("span.time", event.Time), el("div.message", [name, el("br"), description])];
			buttons = _.compact([
				el("button.acknowledge.btn.btn-sm", { dataset: { eventId: event.Id } }, strings.ACKNOWLEDGE),
				positionElement,
			]);
			$j.jGrowl(msg, {
				header: event.TypeName + ": " + asset.Name,
				sticky: true,
				color: event.Alert.Color,
				theme: "alert-triggered",
				closer: false,
				mustConfirm: false,
				eventId: event.Id,
				assetId: event.AssetId,
				type: "alert",
				text: msg,
				photo: photo,
				buttons: buttons,
				appendTo: "#map-mode-container",
			});
		}
	}

	updateAlertsListing();
}

export function updateAcknowledgedEventData(event) {
	// the event could be in both live and history caches, so search both and replace if found
	var liveEvent = _.find(trkData.live.normalizedEventsByAssetId[event.AssetId], function (item) {
		return item.Event.Id === event.Id;
	});
	if (liveEvent !== undefined && event.Alert !== undefined) {
		liveEvent.Event.Alert = event.Alert;
	}
	var historyEvent = _.find(trkData.history.normalizedEventsByAssetId[event.AssetId], function (item) {
		return item.Event.Id === event.Id;
	});
	if (historyEvent !== undefined && event.Alert !== undefined) {
		historyEvent.Event.Alert = event.Alert;
	}

	if (trkData.eventsById[event.Id] !== undefined) {
		trkData.eventsById[event.Id].Alert = event.Alert;
	}

	// there may not be a position associated with the event - in the case of no GPS or something like a heartbeat alert
	if (event.PositionId !== undefined && event.PositionId !== null) {
		// find the positions and update the event for them
		var updatedPosition = null;
		var livePosition = _.find(trkData.live.positionsByAssetId[event.AssetId], function (item) {
			return item.Position !== null && item.Position.Id === event.PositionId;
		});
		if (livePosition !== undefined) {
			updatedPosition = livePosition.Position;
			updateEventForPosition(livePosition.Position, event);
		}
		if (
			trkData.live.latestPositionsByAssetId[event.AssetId] !== undefined &&
			trkData.live.latestPositionsByAssetId[event.AssetId].Position.Id === event.PositionId
		) {
			updatedPosition = trkData.live.latestPositionsByAssetId[event.AssetId];
			updateEventForPosition(trkData.live.latestPositionsByAssetId[event.AssetId], event);
		}

		var historyPositions = trkData.history.positionsByAssetId[event.AssetId];
		if (historyPositions !== undefined) {
			var historyPosition = _.find(historyPositions.Positions, { Id: event.PositionId });
			if (historyPosition !== undefined) {
				updatedPosition = historyPosition;
				updateEventForPosition(historyPosition, event);
			}
		}

		if (trkData.positionsById[event.PositionId] !== undefined) {
			updateEventForPosition(trkData.positionsById[event.PositionId].Position, event);
		}

		// also update any markers for the position
		if (updatedPosition !== null) {
			//var allMarkers = _.union(trkData.live.markers, trkData.history.markers);
			var allMarkers = trkData.live.markers.concat(trkData.history.markers);
			var markers = _.filter(allMarkers, function (marker) {
				return marker.data.location.Id === updatedPosition.Id;
			});
			_.each(markers, function (marker) {
				marker.data.location = updatedPosition;
			});
		}
	}

	// it may already be confirmed
	confirmAlert(event);
}

function updateEventForPosition(position, event) {
	var index = _.findIndex(position.Events, { Id: event.Id });
	if (index !== -1) {
		position.Events[index] = event;
	}
}
