import strings from "./strings.js";
import trkData from "./data.js";
import domNodes from "./domNodes.js";
import state from "./state.js";
import { createListing, defaultListItemSort, notificationLists } from "./item-listing.js";
import { openDialogPanel } from "./panel-nav.js";
import { checkForShareViewChange } from "./shared-view.js";
import { getGroupAssetStatus } from "./asset-group.js";
import { convertToLatLngPreference } from "./preferences.js";
import { findAssetById } from "./assets.js";
import { markerClick } from "./marker-click.js";
import { findTripById } from "./trips.js";
import { getAssetDataGroupForCurrentViewMode } from "./map-viewmode.js";
import { getMapMarkersForDataGroup } from "./marker.js";
import { getJourneyDataGroupForCurrentViewMode } from "./map-viewmode.js";
import { toggleAssetActive } from "./asset-select.js";
import { toggleTripActive } from "./trips.js";
import { viewModes, mapModes } from "./const.js";
import { wrapUrl } from "./wrapurl.js";
import { isItemIncluded } from "./polyfills.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import preferences from "./preferences.js";
import user from "./user.js";
import { updateAssetPositionLines } from "./asset-positions.js";

import $ from "jquery";
import _ from "lodash";
import { el } from "redom"; // https://redom.js.org/

export function openPositionsForAsset(asset) {
	var dataSource =
		state.activeMapMode === mapModes.LIVE
			? trkData.live.normalizedPositionsByAssetId
			: trkData.history.normalizedPositionsByAssetId;
	var positions = [];
	if (dataSource[asset.Id] !== undefined) {
		positions = _.sortBy(dataSource[asset.Id], defaultListItemSort).reverse();
	}
	createListing(positions, "positions");
	openDialogPanel(
		domNodes.dialogs.assetPositions,
		strings.POSITIONS,
		asset,
		false,
		null,
		"asset",
		"asset-positions",
		openPositionsForAsset
	);

	if (state.activeMapMode === mapModes.LIVE) {
		// TODO clear unread badges, but probably easier on render with storing unreadPosition/Message/Event IDs
	}
}

export function openPositionsForGroup(group) {
	var status = getGroupAssetStatus(group.Id);
	var dataSource =
		state.activeMapMode === mapModes.HISTORY
			? trkData.history.normalizedPositionsByAssetId
			: trkData.live.normalizedPositionsByAssetId;
	var positions = [];
	_.each(status.assetIds, function (assetId) {
		if (dataSource[assetId] !== undefined) {
			positions = positions.concat(dataSource[assetId]);
		}
	});
	positions = _.sortBy(positions, defaultListItemSort).reverse();
	createListing(positions, "positions");
	openDialogPanel(
		domNodes.dialogs.assetPositions,
		strings.POSITIONS,
		group,
		false,
		undefined,
		"group",
		"group-positions",
		openPositionsForGroup
	);
}

export function openPositionsForSharedView(sharedView) {
	var positions = _.sortBy(trkData.sharedView.normalizedPositions, defaultListItemSort).reverse();
	createListing(positions, "positions");
	openDialogPanel(
		domNodes.dialogs.assetPositions,
		strings.POSITIONS,
		sharedView,
		false,
		checkForShareViewChange(sharedView),
		"shared-view",
		"shared-view-positions",
		openPositionsForSharedView
	);
}

export function openPositionsForJourney(journey) {
	var positions = [];
	_.each(journey.Trips, function (trip) {
		if (trkData.trips.normalizedPositionsByTripId[trip.Id] !== undefined) {
			positions = positions.concat(trkData.trips.normalizedPositionsByTripId[trip.Id]);
		}
	});
	positions = _.sortBy(positions, defaultListItemSort).reverse();
	createListing(positions, "positions");
	openDialogPanel(
		domNodes.dialogs.assetPositions,
		strings.POSITIONS,
		journey,
		false,
		undefined,
		"journey",
		"journey-positions",
		openPositionsForJourney
	);
}

export function openPositionsForTrip(trip) {
	var positions = [];
	if (trkData.trips.normalizedPositionsByTripId[trip.Id] !== undefined) {
		positions = trkData.trips.normalizedPositionsByTripId[trip.Id];
	}
	positions = _.sortBy(positions, defaultListItemSort).reverse();
	createListing(positions, "positions");
	openDialogPanel(
		domNodes.dialogs.assetPositions,
		strings.POSITIONS,
		trip,
		false,
		undefined,
		"trip",
		"trip-positions",
		openPositionsForTrip
	);
}

export function getPositionLinkForEvent(item) {
	var asset = findAssetById(item.AssetId);
	var eventposition = null;
	var position = getPositionForEvent(item);
	if (position !== undefined) {
		eventposition = position.Address;
		if (eventposition == null || eventposition.trim() == "" || asset.HideAddress) {
			eventposition = convertToLatLngPreference(position.DisplayLat, position.DisplayLng, position.Grid);
		}
		eventposition = el(
			"a.location",
			{ href: "#", dataset: { marker: position.Id, time: item.Time, asset: item.AssetId } },
			eventposition
		);
	}
	return eventposition;
}

export function mapPositionForEvent(item, asset) {
	var eventPosition = {
		id: null,
		latLng: null,
		address: null,
	};

	if (asset === undefined || asset === null) {
		asset = findAssetById(item.AssetId);
	}
	var position = getPositionForEvent(item);
	if (position !== undefined && position !== null) {
		eventPosition.id = position.Id;
		if (!asset.HideAddress) {
			eventPosition.address = position.Address;
		}
		eventPosition.latLng = convertToLatLngPreference(position.DisplayLat, position.DisplayLng, position.Grid);
	}
	return eventPosition;
}

function getPositionForEvent(item) {
	if (item.Position !== undefined && item.Position !== null) {
		// live events have .Position defined, why don't historic? TODO remove .Position from live event
		return item.Position;
	}
	if (trkData.positionsById[item.PositionId] !== undefined) {
		return trkData.positionsById[item.PositionId].Position;
	}
	return undefined;
}

export function highlightPosition(positionId, message) {
	console.log("show position: " + positionId);
	var markers = getMapMarkersForDataGroup(getAssetDataGroupForCurrentViewMode());
	if (markers === undefined) {
		return false;
	}
	var marker = _.find(markers, function (marker) {
		return marker.data.location.Id === positionId;
	});

	// prioritize live/history markers over trip marker for same position
	// TODO doesn't take into account normal/shared view mode
	var isTripMarker = false;
	if (marker === undefined) {
		isTripMarker = true;
		var tripMarkers = getMapMarkersForDataGroup(getJourneyDataGroupForCurrentViewMode());
		if (tripMarkers === undefined) {
			return false;
		}
		var marker = _.find(tripMarkers, function (marker) {
			return marker.data.location.Id === positionId;
		});
	}

	if (marker !== undefined) {
		marker.data.message = message; // TODO what is this
		if (!isTripMarker) {
			var assetId = marker.data.assetId;
			toggleAssetActive(assetId, true, true);
		} else {
			var tripId = marker.data.tripId;
			var trip = findTripById(tripId);
			toggleTripActive(trip.JourneyId, tripId, true, true);
		}
		if (!marker.data.location.IsHidden) {
			addItemToMap(marker);
		}
		markerClick(marker, "position", null, false);
		//marker.fire('click');
		return true;
	}

	return false;
}

export function togglePositionVisibility(assetId, positionId, isNowHidden, sharedViewId) {
	var key = "position-visibility-" + positionId;
	toggleLoadingMessage(true, key);
	var data = {
		AssetId: assetId,
		PositionId: positionId,
		IsVisible: !isNowHidden,
		SharedViewId: sharedViewId,
	};
	return $.ajax({
		type: "POST",
		url: wrapUrl("/api/ui/updatepositionvisibility"),
		contentType: "application/json; charset=utf-8",
		data: JSON.stringify(data),
		dataType: "json",
		success: function (msg) {
			if (msg.success) {
				var asset = findAssetById(assetId);
				if (sharedViewId === null) {
					// if it was hidden, then will it have a marker?
					var liveMarker = trkData.live.markersByPositionId[positionId];
					var historyMarker = trkData.history.markersByPositionId[positionId];
					var tripMarkers = _.filter(trkData.trips.markers, function (item) {
						return item.data.location.Id === positionId;
					});

					var isAssetCurrentlyActive = !isItemIncluded(user.displayPreferences.hiddenAssets, assetId);
					if (liveMarker !== undefined) {
						liveMarker.data.location.IsHidden = isNowHidden;
						if (isAssetCurrentlyActive && state.activeMapMode === mapModes.LIVE) {
							if (isNowHidden) {
								removeItemFromMap(liveMarker);
							} else {
								addItemToMap(liveMarker);
							}
						}
					}

					if (historyMarker !== undefined) {
						historyMarker.data.location.IsHidden = isNowHidden;
						if (
							preferences.PREFERENCE_GROUP_POSITIONS &&
							trkData.history.markerClustersByAssetId[asset.Id] !== undefined
						) {
							// add/remove marker from asset's marker cluster
							if (isNowHidden) {
								trkData.history.markerClustersByAssetId[asset.Id].remove(historyMarker);
							} else {
								trkData.history.markerClustersByAssetId[asset.Id].add(historyMarker);
							}
						} else if (isAssetCurrentlyActive && state.activeMapMode !== mapModes.LIVE) {
							// markers added individually to map
							if (isNowHidden) {
								removeItemFromMap(historyMarker);
							} else {
								addItemToMap(historyMarker);
							}
						}
					}

					// position could be in multiple trips
					_.each(tripMarkers, function (tripMarker) {
						tripMarker.data.location.IsHidden = isNowHidden;
						// add/remove from map - trip may be hidden
						var tripId = tripMarker.data.tripId;
						var isTripCurrentlyActive = isItemIncluded(user.displayPreferences.visibleTrips, tripId);
						var trip = findTripById(tripId);
						if (preferences.PREFERENCE_GROUP_POSITIONS && trkData.trips.markerClustersByTripId[trip.Id] !== undefined) {
							if (isNowHidden) {
								trkData.trips.markerClustersByTripId[trip.Id].remove(tripMarker);
							} else {
								trkData.trips.markerClustersByTripId[trip.Id].add(tripMarker);
							}
						} else if (isTripCurrentlyActive) {
							// trip data active in both live and history mode
							if (isNowHidden) {
								removeItemFromMap(tripMarker);
							} else {
								addItemToMap(tripMarker);
							}
						}
					});

					updateAssetPositionLines(asset, true);
					clearCachedListingsRelatedToPositionId(positionId, isNowHidden, viewModes.NORMAL);
				} else {
					var positionMarkers = _.filter(trkData.sharedView.markers, function (item) {
						return item.data.location.Id === positionId;
					});
					_.each(positionMarkers, function (sharedViewMarker) {
						sharedViewMarker.data.location.IsHidden = isNowHidden;

						if (
							preferences.PREFERENCE_GROUP_POSITIONS &&
							trkData.sharedView.markerClustersByAssetId[assetId] !== undefined
						) {
							if (isNowHidden) {
								trkData.sharedView.markerClustersByAssetId[assetId].remove(sharedViewMarker);
							} else {
								trkData.sharedView.markerClustersByAssetId[assetId].add(sharedViewMarker);
							}
						} else {
							if (isNowHidden) {
								removeItemFromMap(sharedViewMarker, null, viewModes.SHARED_VIEW);
							} else {
								addItemToMap(sharedViewMarker, null, viewModes.SHARED_VIEW);
							}
						}
					});
					updateAssetPositionLines(asset, true, viewModes.SHARED_VIEW);
					clearCachedListingsRelatedToPositionId(positionId, isNowHidden, viewModes.SHARED_VIEW);
				}
				// any map-toggles with this positionId need updated for this view mode
				var viewSelector = "";
				if (sharedViewId !== null) {
					viewSelector = '#panel-secondary[data-item-type="shared-views"] ';
				}
				var icons = document.querySelectorAll(viewSelector + '.map-toggle[data-marker="' + positionId + '"]');
				_.each(icons, function (icon) {
					// update parent container as well, up two levels
					var container = icon.parentNode.parentNode;
					if (isNowHidden) {
						container.classList.add("is-hidden");
					} else {
						container.classList.remove("is-hidden");
					}

					icon.setAttribute("data-hidden", isNowHidden);
					icon
						.querySelector("use")
						.setAttributeNS(
							"http://www.w3.org/1999/xlink",
							"href",
							"/content/svg/tracking.svg?v=15#" + (isNowHidden ? "invisible" : "visible")
						);
					icon.title = isNowHidden ? strings.SHOW_ON_MAP : strings.HIDE_ON_MAP;
				});

				// any quick actions for this position need updated (position information dialog)
				var togglePositionActions = document.querySelectorAll(
					'[data-action="position-toggle-map"][data-position-id="' + positionId + '"]'
				);
				_.each(togglePositionActions, function (actionPositionVisibility) {
					actionPositionVisibility.setAttribute("data-hidden", isNowHidden);
					actionPositionVisibility
						.querySelector("use")
						.setAttributeNS(
							"http://www.w3.org/1999/xlink",
							"href",
							"/content/svg/tracking.svg?v=15#" + (isNowHidden ? "visible" : "invisible")
						);
					actionPositionVisibility.querySelector("span").innerText = isNowHidden
						? strings.SHOW_POSITION
						: strings.HIDE_POSITION;
					actionPositionVisibility.title = isNowHidden ? strings.SHOW_POSITION : strings.HIDE_POSITION;
				});
			} else {
				handleWebServiceError(msg.ErrorMessage);
			}
			toggleLoadingMessage(false, key);
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.ERROR_POSITION_VISIBILITY);
			toggleLoadingMessage(false, key);
		},
	});
}

function clearCachedListingsRelatedToPositionId(positionId, isNowHidden, viewMode) {
	// any listing caches need to be cleared, but only for this view mode
	var dataSource = viewMode === viewModes.SHARED_VIEW ? trkData.sharedView : trkData;
	var nodeSource = viewMode === viewModes.SHARED_VIEW ? domNodes.sharedView : domNodes;
	var corePosition = dataSource.positionsById[positionId];
	if (corePosition !== undefined) {
		delete domNodes.positionListingById[positionId];

		_.each(corePosition.Position.Events, function (positionEvent) {
			delete nodeSource.eventListingById[positionEvent.Id];
			delete nodeSource.eventListingById[positionEvent.Id];
		});

		var positionMessages = _.filter(trkData.messagesById, function (item) {
			return item.Position !== undefined && item.Position !== null && item.Position.Id === positionId;
		});
		_.each(positionMessages, function (item) {
			delete nodeSource.messageListingById[item.Id];
		});

		delete nodeSource.activityListingById[corePosition.Epoch + "-" + corePosition.AssetId];

		var lists = [
			notificationLists.activityList,
			notificationLists.alertsList,
			notificationLists.chatList,
			notificationLists.eventsList,
			notificationLists.messagesList,
			notificationLists.positionsList,
		];
		_.each(lists, function (list) {
			if (list === null) {
				return;
			}
			_.each(list.data.items, function (item) {
				if (item.Position !== undefined && item.Position !== null && item.PositionId === positionId) {
					item.Position.IsHidden = isNowHidden;
				}
			});
		});
	}
}
