import trkData from "./data.js";
import { addGroupToGroupListWithName } from "./group-list.js";
import { sortAssetGroups } from "./item-sorting.js";
import { devices } from "./devices.js";
import domNodes from "./domNodes.js";
import { findAssetById } from "./assets.js";

import $ from "jquery";
import _ from "lodash";
import { el, setChildren } from "redom"; // https://redom.js.org/

export function openActionDialog(
	buttonLabel,
	dialogTitle,
	callback,
	idOverride,
	showGroupSelection,
	satelliteOnly,
	forDeviceIds
) {
	var modal = document.getElementById("message-action-modal");
	var btn = document.getElementById("message-action-button");
	btn.textContent = buttonLabel;
	var title = document.getElementById("message-action-modal-title");
	title.textContent = dialogTitle;
	$(modal).data("message-action-callback", callback);

	$("input[name=IDPGateway]", modal).prop("disabled", false);
	if (satelliteOnly === true) {
		$("input[name=IDPGateway]", modal).prop("checked", false).prop("disabled", true);
		$("#IDPGatewaySatellite").prop("checked", true).prop("disabled", false);
	}

	// populate groups and assets
	var cont = $(".GroupsContainer", modal).empty();
	if (cont.length > 0) {
		var sortedGroups = sortAssetGroups();
		for (var i = 0; i < sortedGroups.length; i++) {
			var group = sortedGroups[i];
			addGroupToGroupListWithName(group, 0, cont[0], "IDPGatewayParametersGroups", null, false);
		}
	}

	// this seems obscure/fragile
	var assetId = $(domNodes.dialogs.idpSendCommand).data("assetId");
	if (idOverride != null) {
		assetId = idOverride;
	}

	var showGroups = true;
	if (showGroupSelection != null) {
		showGroups = showGroupSelection;
	}

	if (showGroups) {
		$("#message-action-additional").show();
	} else {
		$("#message-action-additional").hide();
	}

	if (forDeviceIds === undefined) {
		forDeviceIds = null;
	}

	// assets - filter on Configuration version or AVL config (ARC?)
	var primaryAsset = findAssetById(assetId);
	if (!satelliteOnly) {
		var defaultGateway = 3;
		if (_.indexOf(devices.SKYWAVE_IDP_CELL_ONLY, primaryAsset.DeviceId) !== -1) {
			defaultGateway = 2;
		} else if (primaryAsset.DefaultIDPGateway != null) {
			var defaultGateway = primaryAsset.DefaultIDPGateway;
		}
		$("input[name=IDPGateway][value=" + defaultGateway + "]").prop("checked", true);
	}
	$("select.AssetsIncluded", modal).empty();
	var notIncludedContainer = modal.querySelector("select.AssetsNotIncluded");
	var optionCount = 0;
	const options = [];
	for (var i = 0; i < trkData.assets.length; i++) {
		var asset = trkData.assets[i];
		if (asset.Id == assetId) {
			continue;
		}
		if (asset.IsOutOfService) {
			continue;
		}
		if (forDeviceIds != null) {
			if (_.indexOf(forDeviceIds, asset.DeviceId) === -1) {
				continue;
			}
			options.push(el("option", { value: asset.Id }, asset.Name + " (" + asset.UniqueId + ")"));
			optionCount++;
		} else {
			if (primaryAsset != null) {
				if (asset.Configuration == primaryAsset.Configuration) {
					options.push(el("option", { value: asset.Id }, asset.Name + " (" + asset.UniqueId + ")"));
					optionCount++;
				}
			} else {
				if (_.indexOf(trkData.avlConfigurations, asset.Configuration) !== -1) {
					options.push(el("option", { value: asset.Id }, asset.Name + " (" + asset.UniqueId + ")"));
					optionCount++;
				}
			}
		}
	}
	setChildren(notIncludedContainer, options);
	$("#ActionAssetsNotIncludedCount").text(optionCount);
	$("#ActionAssetsIncludedCount").text("0");

	$(modal).data("assetId", assetId);
	$(modal).modal("show");
}

export function changePrimaryButtonLabel(dialog, text) {
	var id = null;
	var btn =
		id !== null
			? document.getElementById(id)
			: dialog !== null
			? dialog.querySelector(".dialog-buttons .btn-primary")
			: null;
	if (btn === null) {
		return;
	}
	var span = btn.querySelector("span");
	if (span !== null) {
		span.textContent = text;
	} else {
		btn.textContent = text;
	}

	//var icn = btn.querySelector('use');
	//if (icn !== null && icon !== undefined && icon !== null) {
	//    icn.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '/content/svg/tracking.svg?v=15#' + icon);
	//}
}
