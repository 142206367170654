import user from "./user.js";

export function wrapUrl(url) {
	if (!user.isImpersonated) {
		return url;
	}
	if (url.indexOf("?") === -1) {
		url += "?";
	} else {
		url += "&";
	}
	url += "ishr=" + encodeURIComponent(user.id);
	return url;
}
