import trkData from "./data.js";
import { displayPreferencesRemove } from "./user.js";
import domNodes from "./domNodes.js";
import { updateGroupVisibilityStatus } from "./asset-group.js";
import { closeSecondaryPanel } from "./panel.js";
import { deleteTrip } from "./trips.js";

import _ from "lodash";

export function findJourneyById(id) {
	var item = _.find(trkData.journeys, { Id: parseInt(id) });
	return item === undefined ? null : item;
}

export function deleteJourney(journey) {
	var groupId = "journey-" + journey.Id;
	domNodes.groups[groupId].parentNode.removeChild(domNodes.groups[groupId]);
	var panel = domNodes.panels.secondary;
	if (
		panel.getAttribute("data-group-for") === "journeys" &&
		parseInt(panel.getAttribute("data-item-id")) === journey.Id
	) {
		closeSecondaryPanel();
	}

	for (var j = journey.Trips.length - 1; j >= 0; j--) {
		deleteTrip(journey.Trips[j]);
	}

	for (var i = 0; i < trkData.journeys.length; i++) {
		if (trkData.journeys[i].Id == journey.Id) {
			trkData.journeys.splice(i, 1);
			break;
		}
	}
	trkData.journeysById = _.keyBy(trkData.journeys, "Id");
	displayPreferencesRemove("expandedGroups", groupId);
	updateGroupVisibilityStatus(groupId);
}
