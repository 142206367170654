import { init } from "./legacy-init.js";
import { resizeApp } from "./window-layout.js";
import { map } from "./map-base.js";
import { normalGleoSymbols, sharedViewGleoSymbols } from "./map-gleo.js";
import { switchViewMode } from "./map-viewmode.js";
import { setLanguage } from "./const.js";

import { findAssetById } from "./assets.js";
import { findGroupById } from "./asset-group.js";

// import {eventTypes, gatewayAccounts, systemNotifications, avlConfigurations, arcConfigurations} from "data.js";
import trkData from "./data.js";

// import {utcOffset, utcDateOffset, tickOffset, dateFormat, dateWithStandardTimeFormat, isAnonymous, canEditAssets, canDeleteAssets, canSendCommands,  canEditGeofences, canEditPlaces} from "user.js"
import user, { overrideUser } from "./user.js";

// import { portalLoadedEpoch, bounds, isInLiveMode} from "state.js";
import state from "./state.js";

import strings, { overrideStrings } from "./strings.js";

import options from "./options.js";
import preferences from "./preferences.js";
import { overrideVersion } from "./version.js";

import _ from "lodash";

import "jquery-ui-timepicker-addon";
// "/scripts/jquery.upload-1.0.2.js",	// Not used anymore??

// TODO: Integrate /scripts/MapToolbar.js as a ES module. Ensure it's not
// called anymore from an inline `onclick` event handler.


export let productTitle = "";

function setOptions(overrides) {
	// Not really "setOptions", but rather "setOverrides".
	// Overrides *some* of the bits of data from options, preferences, strings, user, etc.

	// domain = overrides.domain;	// No longer used
	setLanguage(overrides.language);
	_.merge(options, overrides.options);
	_.merge(preferences, overrides.preferences);
	productTitle = overrides.productTitle;
	overrideStrings(overrides.strings);
	overrideUser(overrides.user);
	overrideVersion(overrides.version);
}

// NOTE: Only a subset of the exports are used outside of the bundle, and
// ideally the exports could be narrowed down. They're as follows:
/*
{
	setOptions: setOptions,
	data: {
		eventTypes: eventTypes,
		gatewayAccounts: gatewayAccounts,
		systemNotifications: systemNotifications,
		avlConfigurations: avlConfigurations,
		arcConfigurations: arcConfigurations,
	},

	user: {
		utcOffset: utcOffset,
		utcDateOffset: utcDateOffset,
		tickOffset: tickOffset,
		dateFormat: dateFormat,
		dateWithStandardTimeFormat: dateWithStandardTimeFormat,
		isAnonymous: isAnonymous,
		canEditAssets: canEditAssets,
		canDeleteAssets: canDeleteAssets,
		canSendCommands: canSendCommands,
		canEditGeofences: canEditGeofences,
		canEditPlaces: canEditPlaces,
	},

	state: {
		/// NOTE: This value is overwritten from outside tracking.js.
		/// This use case might require a setter here.
		portalLoadedEpoch: portalLoadedEpoch,

		bounds: bounds,
		isInLiveMode: isInLiveMode,
	},

	init: init,
	map: map,
	strings: {
		DELETE: strings.DELETE,
	},
	strings,
	findAssetById: findAssetById,
	findGroupById: findGroupById,
	switchView: switchViewMode,
	resizeApp: resizeApp,
};
*/

export {
	setOptions,
	trkData as data,
	user,
	state,
	init,
	map,
	strings,
	findAssetById,
	findGroupById,
	switchViewMode as switchView,
	resizeApp,
	normalGleoSymbols,
	sharedViewGleoSymbols
};
