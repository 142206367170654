import state from "./state.js";
import trkData from "./data.js";
import { viewModes, mapModes, trkDataGroups } from "./const.js";
import domNodes from "./domNodes.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import { getMapMarkersForDataGroup } from "./marker.js";
import { getCurrentMarkerForAsset } from "./assets.js";

import $ from "jquery";
import _ from "lodash";

export function hideMapMarkersForDataGroup(dataGroup) {
	if (dataGroup === undefined || dataGroup === null) {
		return;
	}
	var markers = getMapMarkersForDataGroup(dataGroup);
	if (markers === undefined) {
		return;
	}

	var $dialog = $(domNodes.infoDialogs.mapItemInformation);
	markers.forEach( function (marker) {
		if (state.selectedMarker !== null && state.selectedMarker == marker && $dialog.dialog("isOpen")) {
			$dialog.dialog("close");
		}
		removeItemFromMap(marker);
	});

	switch (dataGroup) {
		case trkDataGroups.NORMAL_LIVE:
			_.each(trkData.live.mapLinesByAssetId, function (item) {
				removeItemFromMap(item, null, viewModes.NORMAL);
			});
			break;
		case trkDataGroups.NORMAL_HISTORY:
			_.each(trkData.history.mapLinesByAssetId, function (item) {
				removeItemFromMap(item, null, viewModes.NORMAL);
			});
			_.each(trkData.history.markerClustersByAssetId, function (item) {
				removeItemFromMap(item, null, viewModes.NORMAL);
			});
			break;
		case trkDataGroups.JOURNEY_HISTORY:
			_.each(trkData.trips.mapLinesByTripId, function (item) {
				removeItemFromMap(item, null, viewModes.NORMAL);
			});
			_.each(trkData.trips.markerClustersByTripId, function (item) {
				removeItemFromMap(item, null, viewModes.NORMAL);
			});
			break;
		case trkDataGroups.SHARED_VIEW_HISTORY:
			_.each(trkData.sharedView.mapLinesByAssetId, function (item) {
				removeItemFromMap(item, null, viewModes.SHARED_VIEW);
			});
			_.each(trkData.sharedView.markerClustersByAssetId, function (item) {
				removeItemFromMap(item, null, viewModes.SHARED_VIEW);
			});
			break;
	}
}

export function showMapMarkersForDataGroup(dataGroup) {
	var markers = getMapMarkersForDataGroup(dataGroup);
	if (markers === undefined) {
		return;
	}

	// in sharedViews mode, no items are hidden based on visibility
	var visibleAssets = _.filter(trkData.assets, function (asset) {
		return _.indexOf(trkData.visible.assets, asset.Id) !== -1;
	});

	switch (dataGroup) {
		case trkDataGroups.NORMAL_LIVE:
			_.each(visibleAssets, function (asset) {
				if (trkData.live.mapLinesByAssetId[asset.Id] !== undefined) {
					addItemToMap(trkData.live.mapLinesByAssetId[asset.Id], null, viewModes.NORMAL);
				}
				// only show the latest
				var latestMarker = getCurrentMarkerForAsset(asset, mapModes.LIVE);
				if (latestMarker !== undefined && !latestMarker.data.location.IsHidden) {
					addItemToMap(latestMarker, null, viewModes.NORMAL);
				}
			});
			break;
		case trkDataGroups.NORMAL_HISTORY:
			_.each(visibleAssets, function (asset) {
				if (trkData.history.mapLinesByAssetId[asset.Id] !== undefined) {
					addItemToMap(trkData.history.mapLinesByAssetId[asset.Id], null, viewModes.NORMAL);
				}
				if (trkData.history.markerClustersByAssetId[asset.Id] !== undefined) {
					addItemToMap(trkData.history.markerClustersByAssetId[asset.Id], null, viewModes.NORMAL);
				} else {
					_.each(trkData.history.markersByAssetId[asset.Id], function (marker) {
						if (!marker.data.location.IsHidden) {
							addItemToMap(marker, null, viewModes.NORMAL);
						}
					});
				}
			});
			break;
		case trkDataGroups.JOURNEY_HISTORY:
			_.each(trkData.visible.trips, function (tripId) {
				if (trkData.trips.mapLinesByTripId[tripId] !== undefined) {
					addItemToMap(trkData.trips.mapLinesByTripId[tripId], null, viewModes.NORMAL);
				}
				if (trkData.trips.markerClustersByTripId[tripId] !== undefined) {
					addItemToMap(trkData.trips.markerClustersByTripId[tripId]), null, viewModes.NORMAL;
				} else {
					_.each(trkData.trips.markersByTripId[tripId], function (marker) {
						if (!marker.data.location.IsHidden) {
							addItemToMap(marker, null, viewModes.NORMAL);
						}
					});
				}
			});
			break;
		case trkDataGroups.SHARED_VIEW_HISTORY:
			_.each(trkData.sharedView.mapLinesByAssetId, function (item) {
				addItemToMap(item, null, viewModes.SHARED_VIEW);
			});

			_.each(trkData.sharedView.markerClustersByAssetId, function (item, itemId) {
				addItemToMap(item, null, viewModes.SHARED_VIEW);
			});
			// add individual markers when asset isn't clustered
			_.each(trkData.sharedView.markersByAssetId, function (markers, itemId) {
				if (trkData.sharedView.markerClustersByAssetId[itemId] === undefined) {
					_.each(markers, function (item) {
						addItemToMap(item, null, viewModes.SHARED_VIEW);
					});
				}
			});
			break;
	}
}
