import trkData from "./data.js";
import log from "./log.js";
import { includeRowIfNotNull, createAccordionCard } from "./dom-util.js";

import $j from "jquery";
import _ from "lodash";
import { el } from "redom"; // https://redom.js.org/

export function getAttributesForType(type, prefix) {
	return _.filter(trkData.attributes, { Type: type }).map(function (item) {
		return {
			Id: item.Id,
			Value: $j("#" + prefix + item.Id).val(),
		};
	});
}

export function populateCustomAttributes(itemAttributes, attributeType, parentId) {
	var attributeinformation = [];
	if (itemAttributes == null || itemAttributes.length == 0 || trkData.attributeGroups == null) {
		return attributeinformation;
	}

	for (var i = 0; i < trkData.attributeGroups.length; i++) {
		// attributes by group
		var group = trkData.attributeGroups[i];
		if (group.Type != attributeType) {
			continue;
		}
		var attributes = findAttributesForAttributeGroup(group, attributeType);
		if (attributes == null || attributes.length == 0) {
			continue;
		}
		var groupattributeinformation = [];
		// see if there's a matching value
		for (var k = 0; k < attributes.length; k++) {
			var attribute = attributes[k];
			for (var j = 0; j < itemAttributes.length; j++) {
				var itemAttribute = itemAttributes[j];
				if (attribute.Id == itemAttribute.Id) {
					groupattributeinformation.push(includeRowIfNotNull(attribute.Name, itemAttribute.Value));
				}
			}
		}
		groupattributeinformation = _.compact(groupattributeinformation);
		if (groupattributeinformation.length > 0) {
			attributeinformation.push(
				createAccordionCard(
					"attribute-" + group.Id,
					parentId,
					group.Name,
					el("table", el("tbody", groupattributeinformation))
				)
			);
		}
	}
	return attributeinformation;
}

function findAttributesForAttributeGroup(group, type) {
	return findAttributesForAttributeGroupId(group.Id, type);
}

function findAttributesForAttributeGroupId(id, type) {
	return _.filter(trkData.attributes, { GroupId: parseInt(id), Type: type });
}

export function populateCustomAttributesEditable(container, attributeType, prefix) {
	if (trkData.attributeGroups == null || trkData.attributes == null) {
		return;
	}
	for (var i = 0; i < trkData.attributeGroups.length; i++) {
		var group = trkData.attributeGroups[i];
		if (group.Type != attributeType) {
			continue;
		}
		var attributes = findAttributesForAttributeGroup(group, attributeType); // asset attributes
		if (attributes == null || attributes.length == 0) {
			continue;
		}

		log("Add Custom Attribute Group: " + group.Name);

		// add group and its attributes to accordion edit-asset-extra-accordion
		var contents = document.createDocumentFragment();
		for (var j = 0; j < attributes.length; j++) {
			var attribute = attributes[j];
			var item = el(".form-group", el("label", { for: prefix + attribute.Id }, attribute.Name));

			//TODO: Setup Regex, PermittedValues
			if (attribute.PermittedValues != null && attribute.PermittedValues != "") {
				var permittedValues = attribute.PermittedValues.split(",");
				// select box for restricted values, check the current
				const options = [el("option")];
				for (var k = 0; k < permittedValues.length; k++) {
					var permittedValue = permittedValues[k];
					options.push(el("option", { value: permittedValue }, permittedValue));
				}
				var select = el(
					"select#" + prefix + attribute.Id + ".attrib.form-control",
					{ "data-attribute-id": attribute.Id },
					options
				);
				item.appendChild(select);
			} else {
				switch (attribute.DataType) {
					case 0: // string
					//style = ' style="width: 20em;"';
					case 1: // number
						var itemOptions = { type: "text", "data-attribute-id": attribute.Id };
						if (attribute.MaxLength != null) {
							itemOptions.maxlength = attribute.MaxLength;
						}
						item.appendChild(el("input#" + prefix + attribute.Id + ".attrib.form-control", itemOptions));
						break;
					case 2: // file, TODO: File upload capability (mimic photo?)
						break;
				}
			}
			contents.appendChild(item);
		}

		// create accordion and add it
		container.appendChild(createAccordionCard("attribute-" + group.Id + "-edit", container.id, group.Name, contents));
	}
}
