import strings from "./strings.js";
import trkData from "./data.js";
import { devices } from "./devices.js";
import domNodes from "./domNodes.js";
import log from "./log.js";
import { findAssetById } from "./assets.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import { formShowErrorMessage, formShowSuccessMessage } from "./ajax.js";
import { getValueIfCheckboxSelected } from "./dom-util.js";
import { openActionDialog } from "./modal.js";
import { wrapUrl } from "./wrapurl.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";

export const dialogs = {
	gsatmicro: null,
	closeButton: null,
};

export function loadDialog(name, showLoadingMessage) {
	var dialog = dialogs[name];
	if (dialog === undefined) {
		console.error("Dialog " + name + " has not been defined.");
		return;
	}

	var dialogLoaded = $.Deferred();

	if (domNodes.dialogs[name] === undefined) {
		// load and initialize the dialog, returning a deferred/promise for when it is complete
		var dialogContainer = document.createElement("div");
		var dialogInitialize = $.Deferred();
		if (showLoadingMessage === true) {
			toggleLoadingMessage(true, "dialog-" + name);
		}
		$.when(
			$.get(dialog.url, function (data) {
				dialogContainer.innerHTML = data;
				var dialogNode = dialogContainer.childNodes[0];
				document.getElementById("track-root").appendChild(dialogNode);
				dialog.domNode = dialogNode;
				domNodes.dialogs[name] = dialogNode;
				dialog.initialize(dialogNode);
				dialog.isLoaded = true;
				log("Dialog " + name + " loaded & initialized.");
				dialogInitialize.resolve(true);
				$("label,input,select", dialogNode).bsTooltip();
			}),
			dialogInitialize
		).done(function (getRequestResult, initDialogResult) {
			dialogLoaded.resolve(true);
			if (showLoadingMessage === true) {
				toggleLoadingMessage(false, "dialog-" + name);
			}
		});
	} else {
		// dialog has already been loaded
		dialogLoaded.resolve(true);
	}
	return dialogLoaded;
}

dialogs.gsatmicro = {
	url: wrapUrl("track/dialogs/gsatmicro"),
	isLoaded: false,
	domNode: null,
	dialog: null,

	requestGSatMicroInformation: function (assetId) {
		var data = {
			assetId: assetId,
		};
		var status = document.getElementById("gsatmicro-query-status");
		toggleLoadingMessage(true, "micro-information");
		return $j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/GSatMicroGetConfiguration"),
			data: JSON.stringify(data),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						if (result.LastRetrievedOn == null) {
							$j("#GSatMicroConfigLastRetrieved").text(strings.NEVER);
						} else {
							$j("#GSatMicroConfigLastRetrieved").text(result.LastRetrievedOn);
						}
						if (result.LastQueriedOn == null) {
							$j("#GSatMicroConfigLastQueried").text(strings.NEVER);
						} else {
							$j("#GSatMicroConfigLastQueried").text(result.LastQueriedOn);
						}

						var config = result.Configuration;
						dialogs.gsatmicro.populateGSatMicroConfig(config, assetId);
					} else {
						// message failure, keep text field to allow retry
						formShowErrorMessage(status, strings.MSG_GET_DEVICE_INFORMATION_ERROR);
						if (result.ErrorMessage != null && result.ErrorMessage != "") {
							formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
						}
					}
				}
				toggleLoadingMessage(false, "micro-information");
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_GET_DEVICE_INFORMATION_ERROR);
				toggleLoadingMessage(false, "micro-information");
			},
		});
	},

	requestGSatMicroConfig: function (id) {
		var dialog = $j(dialogs.gsatmicro.domNode);
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
				groupIds: groupIds,
				assetIds: assetIds,
			};
			toggleLoadingMessage(true, "gsatmicro-get-config");
			var status = document.getElementById("gsatmicro-query-status");
			return $j.ajax({
				type: "POST",
				url: wrapUrl("/services/GPSService.asmx/GSatMicroSendGetConfigurationRequest"),
				data: JSON.stringify(data),
				contentType: "application/json; charset=utf-8",
				dataType: "json",
				success: function (msg) {
					var result = msg.d;
					if (result) {
						if (result.Success == true) {
							dialogs.gsatmicro.requestGSatMicroInformation(id);
							formShowSuccessMessage(status, strings.MSG_GET_PARAMETERS_SUCCESS);
						} else {
							// message failure, keep text field to allow retry
							formShowErrorMessage(status, strings.MSG_GET_PARAMETERS_ERROR);
							if (result.ErrorMessage != null && result.ErrorMessage != "") {
								formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
							}
						}
					}
					toggleLoadingMessage(false, "gsatmicro-get-config");
					$(domNodes.modals.messageAction).modal("hide");
				},
				error: function (xhr, status, error) {
					handleWebServiceError(strings.MSG_GET_PARAMETERS_ERROR);
					toggleLoadingMessage(false, "gsatmicro-get-config");
					$(domNodes.modals.messageAction).modal("hide");
				},
			});
		};
		var asset = findAssetById(id);
		var satelliteOnly = false;
		if (asset != null) {
			if (_.indexOf(devices.GSATMICRO_GSM, asset.DeviceId) === -1) {
				satelliteOnly = true;
			}
		}
		openActionDialog(
			strings.GET_PARAMETERS,
			strings.GET_PARAMETERS,
			callback,
			id,
			null,
			satelliteOnly,
			devices.GSAT_MICROS
		);
	},

	populateGSatMicroConfig: function (config, assetId) {
		if (config == null) {
			$j("#GSatMicroProtocolVersion").text(strings.UNKNOWN);
			$j("#GSatMicroSettingsVersion").text(strings.UNKNOWN);
			return;
		}

		var asset = findAssetById(assetId);
		var dialog = $j(dialogs.gsatmicro.domNode);
		dialog.find(".v2,.v3,.v4,.v5,.v6,.v7,.v8,.v9,.v10").hide();
		if (config.SettingsVersion != null) {
			for (var i = 2; i <= 10; i++) {
				if (config.SettingsVersion >= i) {
					dialog.find(".v" + i).show();
				}
			}
		}

		// protocol version
		if (config.Version == null || config.Version == "") {
			$j("#GSatMicroProtocolVersion").text(strings.UNKNOWN);
		} else {
			$j("#GSatMicroProtocolVersion").text(config.Version);
		}

		// settings/software version
		if (config.SettingsVersion == null || config.SettingsVersion == "") {
			$j("#GSatMicroSettingsVersion").text(strings.UNKNOWN);
		} else {
			$j("#GSatMicroSettingsVersion").text(config.SettingsVersion);
		}

		$j("#txtGSatMicroSleep").val(config.SleepInterval);
		$j("#txtGSatMicroSOSSleep").val(config.SOSSleepInterval);
		$j("#txtGSatMicroGPSTimeout").val(config.GPSTimeout);
		$j("#txtGSatMicroGPSHDOP").val(config.GPSHDOP);
		$j("#txtGSatMicroGPSSettle").val(config.GPSSettle);
		$j("#txtGSatMicroIridiumTransmitTimeout").val(config.IridiumTxTimeout);
		$j("#txtGSatMicroIridiumSignalTimeout").val(config.IridiumSignalTimeout);
		$j("#txtGSatMicroIridiumTransmitRetries").val(config.IridiumTxRetries);
		$j("input:radio[name=rbGSatMicroSleepWhenPowered][value=" + config.SleepWhenPowered + "]").prop("checked", true);
		$j("input:radio[name=rbGSatMicroAltitude][value=" + config.IncludeAltitude + "]").prop("checked", true);
		if (config.LEDMask != null) {
			$j("input:radio[name=rbGSatMicroLEDMaskGPS][value=" + config.LEDMask.GPS + "]").prop("checked", true);
			$j("input:radio[name=rbGSatMicroLEDMaskMessage][value=" + config.LEDMask.Message + "]").prop("checked", true);
			$j("input:radio[name=rbGSatMicroLEDMaskPower][value=" + config.LEDMask.Power + "]").prop("checked", true);
			$j("input:radio[name=rbGSatMicroLEDMaskSatellite][value=" + config.LEDMask.Satellite + "]").prop("checked", true);
			$j("input:radio[name=rbGSatMicroLEDMaskAlarm][value=" + config.LEDMask.Alarm + "]").prop("checked", true);
		}
		$j("input:radio[name=rbGSatMicroGPSHibernateSleep][value=" + config.GPSHibernateSleep + "]").prop("checked", true);
		$j("input:radio[name=rbGSatMicroCacheReports][value=" + config.CacheReports + "]").prop("checked", true);
		$j("input:radio[name=rbGSatMicroKeepRadioAwake][value=" + config.KeepRadioAwake + "]").prop("checked", true);
		$j("input:radio[name=rbGSatMicroGPSOnAlways][value=" + config.GPSOnAlways + "]").prop("checked", true);
		$j("input:radio[name=rbGSatMicroSleepWithBat][value=" + config.SleepWithBat + "]").prop("checked", true);
		$j("input:radio[name=rbGSatMicroRequireEncryptedMT][value=" + config.RequireEncryptedMT + "]").prop(
			"checked",
			true
		);
		$j("#txtGSatMicroMovingSleepInterval").val(config.MovingSleepInterval);
		$j("#txtGSatMicroMovingThreshSpeed").val(config.MovingThreshSpeed);
		$j("input:radio[name=rbGSatMicroIncludeSeconds][value=" + config.IncludeSeconds + "]").prop("checked", true);
		$j("input:radio[name=rbGSatMicroReportFormat][value=" + config.ReportFormat + "]").prop("checked", true);
		$j("#txtGSatMicroExternalPowerSleep").val(config.ExternalPowerSleep);
		$j("input:radio[name=rbGSatMicroExternalPowerAutoStart][value=" + config.ExternalPowerAutoStart + "]").prop(
			"checked",
			true
		);
		$j("input:radio[name=rbGSatMicroNMEA][value=" + config.NMEA + "]").prop("checked", true);
		$j("input:radio[name=rbGSatMicroBLEOff][value=" + config.BLEOff + "]").prop("checked", true);
		$j("#txtGSatMicroAccelThreshold").val(config.AccelerometerThreshold);
	},

	initialize: function (dialogNode) {
		// events
		bindParameterSelectionEvents(".parameters-form");

		$j(this.domNode).on("click", "#QueryGSatMicroConfig", function (e) {
			e.preventDefault();

			var id = $j(dialogs.gsatmicro.domNode).data("assetId");
			dialogs.gsatmicro.requestGSatMicroConfig(id);
		});

		$j(this.domNode).on("click", "#micro-set-message", function (e) {
			e.preventDefault();
			$j("#GSatMicroCommandText").val($j("#micro-message").val());
		});

		$j(this.domNode).on("click", "#GSatMicroSendCommand", function (e) {
			e.preventDefault();

			var dialog = $j(dialogs.gsatmicro.domNode);
			var status = document.getElementById("gsatmicro-query-status");
			var form = $j("#gsatmicro-commands-form");
			var isFormValid = form.valid();
			if (!isFormValid) return;
			var command = $j("#GSatMicroCommandText").val();

			var assetId = $j(dialog).data("assetId");
			var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
				var data = {
					assetId: assetId,
					command: command,
					gateway: gateway,
					gatewayTimeout: gatewayTimeout,
					gatewayRetries: gatewayRetries,
					groupIds: groupIds,
					assetIds: assetIds,
				};

				toggleLoadingMessage(true, "gsatmicro-command");
				$j.ajax({
					type: "POST",
					url: wrapUrl("/services/GPSService.asmx/GSatMicroSendCommandRequest"),
					data: JSON.stringify(data),
					contentType: "application/json; charset=utf-8",
					dataType: "json",
					success: function (msg) {
						var result = msg.d;
						if (result) {
							if (result.Success == true) {
								formShowSuccessMessage(status, strings.MSG_COMMAND_SUCCESS);
							} else {
								// message failure, keep text field to allow retry
								formShowErrorMessage(status, strings.MSG_COMMAND_ERROR);
								if (result.ErrorMessage != null && result.ErrorMessage != "") {
									formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
								}
							}
						}
						toggleLoadingMessage(false, "gsatmicro-command");
						$(domNodes.modals.messageAction).modal("hide");
					},
					error: function (xhr, status, error) {
						handleWebServiceError(strings.MSG_COMMAND_ERROR);
						toggleLoadingMessage(false, "gsatmicro-command");
						$(domNodes.modals.messageAction).modal("hide");
					},
				});
			};

			var asset = findAssetById(assetId);
			var satelliteOnly = false;
			if (asset != null) {
				if (_.indexOf(devices.GSATMICRO_GSM, asset.DeviceId) === -1) {
					satelliteOnly = true;
				}
			}

			openActionDialog(
				strings.SEND_COMMAND,
				strings.SEND_COMMAND,
				callback,
				assetId,
				null,
				satelliteOnly,
				devices.GSAT_MICROS
			);
		});

		$j(this.domNode).on("click", "#btnGSatMicroSetParameters", function (e) {
			e.preventDefault();

			var dialog = $j(dialogs.gsatmicro.domNode);
			var status = document.getElementById("gsatmicro-query-status");
			var form = $j("#gsatmicro-settings-form");
			var isFormValid = form.valid();
			if (!isFormValid) return;

			//var mask = getValueIfCheckboxSelected('#txtGSatMicroLEDMask');
			//if (mask != null) {
			//    mask = parseInt(mask);
			//}
			var LEDMask = null;
			if ($j("#chkGSatMicroLEDMask").is(":checked")) {
				LEDMask = {
					GPS: $j("input[name=rbGSatMicroLEDMaskGPS]:checked").val(),
					Message: $j("input[name=rbGSatMicroLEDMaskMessage]:checked").val(),
					Power: $j("input[name=rbGSatMicroLEDMaskPower]:checked").val(),
					Satellite: $j("input[name=rbGSatMicroLEDMaskSatellite]:checked").val(),
					Alarm: $j("input[name=rbGSatMicroLEDMaskAlarm]:checked").val(),
				};
			}
			var parameters = {
				GPSHDOP: getValueIfCheckboxSelected("#txtGSatMicroGPSHDOP"),
				GPSSettle: getValueIfCheckboxSelected("#txtGSatMicroGPSSettle"),
				GPSTimeout: getValueIfCheckboxSelected("#txtGSatMicroGPSTimeout"),
				IncludeAltitude: getValueIfCheckboxSelected("input[name=rbGSatMicroAltitude]:checked"),
				IridiumTxRetries: getValueIfCheckboxSelected("#txtGSatMicroIridiumTransmitRetries"),
				IridiumTxTimeout: getValueIfCheckboxSelected("#txtGSatMicroIridiumTransmitTimeout"),
				IridiumSignalTimeout: getValueIfCheckboxSelected("#txtGSatMicroIridiumSignalTimeout"),
				LEDMask: LEDMask,
				SleepInterval: getValueIfCheckboxSelected("#txtGSatMicroSleep"),
				SleepWhenPowered: getValueIfCheckboxSelected("input[name=rbGSatMicroSleepWhenPowered]:checked"),
				SOSSleepInterval: getValueIfCheckboxSelected("#txtGSatMicroSOSSleep"),
				KeepRadioAwake: getValueIfCheckboxSelected("input[name=rbGSatMicroKeepRadioAwake]:checked"),
				GPSHibernateSleep: getValueIfCheckboxSelected("input[name=rbGSatMicroGPSHibernateSleep]:checked"),
				CacheReports: getValueIfCheckboxSelected("input[name=rbGSatMicroCacheReports]:checked"),
				GPSOnAlways: getValueIfCheckboxSelected("input[name=rbGSatMicroGPSOnAlways]:checked"),
				SleepWithBat: getValueIfCheckboxSelected("input[name=rbGSatMicroSleepWithBat]:checked"),
				RequireEncryptedMT: getValueIfCheckboxSelected("input[name=rbGSatMicroRequireEncryptedMT]:checked"),
				MovingSleepInterval: getValueIfCheckboxSelected("#txtGSatMicroMovingSleepInterval"),
				MovingThreshSpeed: getValueIfCheckboxSelected("#txtGSatMicroMovingThreshSpeed"),
				IncludeSeconds: getValueIfCheckboxSelected("input[name=rbGSatMicroIncludeSeconds]:checked"),
				ReportFormat: getValueIfCheckboxSelected("input[name=rbGSatMicroReportFormat]:checked"),
				ExternalPowerSleep: getValueIfCheckboxSelected("#txtGSatMicroExternalPowerSleep"),
				ExternalPowerAutoStart: getValueIfCheckboxSelected("input[name=rbGSatMicroExternalPowerAutoStart]:checked"),
				NMEA: getValueIfCheckboxSelected("input[name=rbGSatMicroNMEA]:checked"),
				BLEOff: getValueIfCheckboxSelected("input[name=rbGSatMicroBLEOff]:checked"),
				AccelerometerThreshold: getValueIfCheckboxSelected("#txtGSatMicroAccelThreshold"),
			};

			var assetId = $j(dialog).data("assetId");
			var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
				var data = {
					assetId: assetId,
					parameters: parameters,
					gateway: gateway,
					gatewayTimeout: gatewayTimeout,
					gatewayRetries: gatewayRetries,
					groupIds: groupIds,
					assetIds: assetIds,
				};

				toggleLoadingMessage(true, "gsatmicro-parameters");
				$j.ajax({
					type: "POST",
					url: wrapUrl("/services/GPSService.asmx/GSatMicroSendSetParametersRequest"),
					data: JSON.stringify(data),
					contentType: "application/json; charset=utf-8",
					dataType: "json",
					success: function (msg) {
						var result = msg.d;
						if (result) {
							if (result.Success == true) {
								formShowSuccessMessage(status, strings.MSG_PARAMETERS_SUCCESS);
							} else {
								// message failure, keep text field to allow retry
								formShowErrorMessage(status, strings.MSG_PARAMETERS_ERROR);
								if (result.ErrorMessage != null && result.ErrorMessage != "") {
									formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
								}
							}
						}
						toggleLoadingMessage(false, "gsatmicro-parameters");
						$(domNodes.modals.messageAction).modal("hide");
					},
					error: function (xhr, status, error) {
						handleWebServiceError(strings.MSG_PARAMETERS_ERROR);
						toggleLoadingMessage(false, "gsatmicro-parameters");
						$(domNodes.modals.messageAction).modal("hide");
					},
				});
			};

			var asset = findAssetById(assetId);
			var satelliteOnly = false;
			if (asset !== null) {
				if (_.indexOf(devices.GSATMICRO_GSM, asset.DeviceId) === -1) {
					satelliteOnly = true;
				}
			}
			openActionDialog(
				strings.SET_PARAMETERS,
				strings.SET_PARAMETERS,
				callback,
				assetId,
				null,
				satelliteOnly,
				devices.GSAT_MICROS
			);
		});

		trkData.validation.gsatmicroSettings = $("#gsatmicro-settings-form").validate();
		trkData.validation.gsatmicroCommands = $("#gsatmicro-commands-form").validate();
		//this.dialog = $(dialogNode).dialog({
		//    autoOpen: false,
		//    modal: true,
		//    width: 850,
		//    buttons: [dialogs.closeButton],
		//    create: function (event, ui) {
		//        createAssetSelector($j(this));
		//        createDialogCloseButton(this);
		//        replaceDialogButtons(this, [dialogs.closeButton]);

		//    }
		//});
	},
};

function bindParameterSelectionEvents(selector) {
	$j(selector).on("click", "a.select-all", function (e) {
		e.preventDefault();
		var table = $j(this).closest("table");
		$j("input:checkbox", table)
			.prop("checked", true)
			.parents("td")
			.find("input,select,label")
			.not(":checkbox")
			.prop("disabled", false)
			.removeClass("disabled");
		$j("input:checkbox.next-cell", table)
			.parents("td")
			.next("td")
			.find("input,select,label")
			.not(":checkbox")
			.prop("disabled", false)
			.removeClass("disabled");
		$j("input:checkbox.next-rows", table)
			.parents("td")
			.parent()
			.nextUntil("tr.collapse")
			.find("input,select,label")
			.not(":checkbox")
			.prop("disabled", false)
			.removeClass("disabled");
	});
	$j(selector).on("click", "a.select-none", function (e) {
		e.preventDefault();
		var table = $j(this).closest("table");
		$j("input:checkbox", table)
			.prop("checked", false)
			.parents("td")
			.find("input,select,label")
			.not(":checkbox")
			.prop("disabled", true)
			.addClass("disabled");
		$j("input:checkbox.next-cell", table)
			.parents("td")
			.next("td")
			.find("input,select,label")
			.not(":checkbox")
			.prop("disabled", true)
			.addClass("disabled");
		$j("input:checkbox.next-rows", table)
			.parents("td")
			.parent()
			.nextUntil("tr.collapse")
			.find("input,select,label")
			.not(":checkbox")
			.prop("disabled", true)
			.addClass("disabled");
	});
	$j(selector).on("click", "label", function (e) {
		var cell = $j(this).parent().next("td");
		cell.find("input:checkbox").prop("checked", true);
		cell.find("input,select,label").not(":checkbox").prop("disabled", false).removeClass("disabled");
		if (cell.hasClass("next-cell")) {
			cell = cell
				.next("td")
				.find("input,select,label")
				.not(":checkbox")
				.prop("disabled", false)
				.removeClass("disabled");
		}
	});
	$j(selector).on("change", "input:checkbox", function (e) {
		var isDisabled = !$j(this).prop("checked");
		if (isDisabled) {
			$j(this)
				.parents("td")
				.find("input,select,label")
				.not(":checkbox")
				.prop("disabled", isDisabled)
				.addClass("disabled");
			if ($j(this).hasClass("next-cell")) {
				$j(this)
					.parents("td")
					.next("td")
					.find("input,select,label")
					.not(":checkbox")
					.prop("disabled", isDisabled)
					.addClass("disabled");
			}
		} else {
			$j(this)
				.parents("td")
				.find("input,select,label")
				.not(":checkbox")
				.prop("disabled", isDisabled)
				.removeClass("disabled");
			if ($j(this).hasClass("next-cell")) {
				$j(this)
					.parents("td")
					.next("td")
					.find("input,select,label")
					.not(":checkbox")
					.prop("disabled", isDisabled)
					.removeClass("disabled");
			}
		}
	});
}
