import options from "./options.js";
import { findDeviceById } from "./devices.js";
import { devices } from "./devices.js";
import trkData from "./data.js";
import { mapModes, viewModes } from "./const.js";
import { isSendCommandDisabledForDevice } from "./devices.js";
import state from "./state.js";

import $ from "jquery";
import _ from "lodash";

export function isSendCommandEnabledForAsset(asset) {
	if (options.enabledFeatures.indexOf("REMOTE_MANAGEMENT") === -1) {
		return false;
	}

	var device = findDeviceById(asset.DeviceId);
	return !isSendCommandDisabledForDevice(device);
}

export function isWaypointEnabledForAsset(asset) {
	if (options.enabledFeatures.indexOf("WAYPOINTS") === -1) {
		return false;
	}

	var device = findDeviceById(asset.DeviceId);
	if (!device.SupportsPositionUpdates) return false;
	if (
		$.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
		$.inArray(asset.DeviceId, devices.SKYWAVE_IDP) != -1
	) {
		// IDP devices must have the AVL service enabled
		//if (asset.IDP.AVL !== true)
		//    return false;
		if (asset.IDP.Garmin !== true) return false;
	}
	return true;
}

export function isMessagingEnabledForAssetGroup(group) {
	if (options.enabledFeatures.indexOf("TWO_WAY_MESSAGING") === -1) {
		return false;
	}

	if (group === undefined || group === null) {
		return false;
	}

	var groupAssetIds = findAssetIdsUnderGroup(group);
	var hasAssetWithMessagingEnabled = false;
	_.each(groupAssetIds, function (assetId) {
		if (trkData.assetsById[assetId] !== undefined) {
			if (isMessagingEnabledForAsset(trkData.assetsById[assetId])) {
				hasAssetWithMessagingEnabled = true;
				return;
			}
		}
	});
	return hasAssetWithMessagingEnabled;
}

export function isMessagingEnabledForAsset(asset) {
	if (options.enabledFeatures.indexOf("TWO_WAY_MESSAGING") === -1) {
		return false;
	}

	if (asset == null) return false;

	var device = findDeviceById(asset.DeviceId);
	if (!device.SupportsMessaging) return false;

	if (asset.DeviceId === devices.BIVY_STICK) {
		// Bivy Stick must be registered with Messaging enabled
		if (asset.Bivy === null || asset.Bivy.Messages !== true || asset.Bivy.Registered !== true) {
			return false;
		}
	}

	if (
		$.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
		$.inArray(asset.DeviceId, devices.SKYWAVE_IDP) != -1
	) {
		//// IDP devices must have the AVL and Garmin services enabled
		//if (asset.IDP.AVL !== true)
		//    return false;
		// IDP devices technically only need Garmin enabled
		if (asset.IDP.Garmin !== true) return false;
	}
	return true;
}

export function isOutputEnabledForAsset(asset) {
	if (options.enabledFeatures.indexOf("REMOTE_MANAGEMENT") === -1) {
		return false;
	}
	var device = findDeviceById(asset.DeviceId);
	if (device.OutputPinCount == 0) return false;
	if (
		$.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
		$.inArray(asset.DeviceId, devices.SKYWAVE_IDP) != -1
	) {
		// IDP devices must have the AVL or ARC service enabled
		if (asset.IDP.AVL !== true && asset.IDP.ARC !== true) return false;
	}
	return true;
}

export function isGarminFormsEnabledForAsset(asset) {
	if (options.enabledFeatures.indexOf("GARMIN_INTEGRATION") === -1) {
		return false;
	}
	return trkData.devicesById[asset.DeviceId].SupportsGarminForms;
}

export function isServiceMeterEnabledForAsset(asset) {
	if (options.enabledFeatures.indexOf("LOGS_SERVICE_METER") === -1) {
		return false;
	}
	if (
		$.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
		$.inArray(asset.DeviceId, devices.SKYWAVE_IDP) != -1
	) {
		// IDP devices must have the AVL service enabled
		if (asset.IDP.AVL !== true) return false;
	} else {
		return false;
	}
	return true;
}

export function getCurrentMarkerForAsset(asset, mode) {
	if (mode === undefined) {
		mode = state.activeMapMode;
	}
	if (mode === mapModes.LIVE) {
		var latestMarkers = _.filter(trkData.live.markersByAssetId[asset.Id], function (item) {
			return !item.data.location.IsHidden;
		});
		if (latestMarkers !== undefined && latestMarkers.length > 0) {
			return latestMarkers[latestMarkers.length - 1];
		}
	} else if (mode === mapModes.HISTORY) {
		var latestHistory = trkData.history.markersByAssetId[asset.Id];
		if (latestHistory !== undefined && latestHistory.length > 0) {
			return latestHistory[0];
		}
	}
	return undefined;
}

function getAssetIdsFromAssets(assets) {
	var assetIds = [];
	for (var i = 0, len = assets.length; i < len; i++) {
		assetIds.push(assets[i].Id);
	}
	return assetIds;
}

function findAssetsUnderGroup(group) {
	var groupAssets = [];
	if (group.Id === "all-assets") {
		return trkData.assets;
	}
	_.each(trkData.assets, function (asset, index, list) {
		if (_.indexOf(asset.GroupIds, group.Id) !== -1) {
			groupAssets.push(asset);
		}
	});

	var subGroups = _.filter(trkData.groups, { ParentGroupId: group.Id });
	_.each(subGroups, function (subGroup, index, list) {
		_.each(findAssetsUnderGroup(subGroup), function (asset) {
			groupAssets.push(asset);
		});
	});

	return _.uniq(groupAssets);
}

export function findAssetIdsUnderGroup(group) {
	return getAssetIdsFromAssets(findAssetsUnderGroup(group));
}

export function findAssetByUniqueId(uniqueId) {
	var item = _.find(trkData.assets, { UniqueId: uniqueId });
	return item === undefined ? null : item;
}

export function findAssetById(id) {
	var item = trkData.assetsById[parseInt(id)];
	return item === undefined ? null : item;
}

export function normalizeAssetData(assetId, type, item, parent) {
	// TODO normalize upon parsing of the data originally
	// and pass/store this version OR have the DTO match this better
	var normalized = {
		AssetId: assetId,
		Epoch: null,
	};
	switch (type) {
		case "position":
			normalized.Position = item;
			break;
		case "event":
			normalized.Event = item;
			break;
		//case 'chat':
		//    normalized.Chat = item;
		//    break;
		//case 'message':
		//    normalized.Message = item;
		//    break;
		case "message":
			if (trkData.MESSAGES_TEXT.indexOf(item.TypeId) !== -1) {
				normalized.Chat = item;
			} else {
				normalized.Message = item;
			}
			break;
	}

	if (item.Epoch !== undefined) {
		normalized.Epoch = item.Epoch;
	} else if (item.CreatedEpoch !== undefined) {
		normalized.Epoch = item.CreatedEpoch;
	}

	var dataSource = trkData;
	if (state.activeViewMode === viewModes.SHARED_VIEW) {
		dataSource = trkData.sharedView;
	}

	if (item.Position !== undefined && item.Position !== null) {
		if (dataSource.positionsById[item.Position.Id] === undefined) {
			dataSource.positionsById[item.Position.Id] = normalizeAssetData(assetId, "position", item.Position);
		}
		normalized.Position = dataSource.positionsById[item.Position.Id].Position;
	} else if (item.PositionId !== undefined && item.PositionId !== null) {
		if (dataSource.positionsById[item.PositionId] !== undefined) {
			normalized.Position = dataSource.positionsById[item.PositionId].Position;
		} else if (parent !== undefined && parent.Position !== undefined) {
			normalized.Position = parent.Position;
		}
	}

	if (item.Events !== undefined && item.Events !== null) {
		_.each(item.Events, function (itemEvent) {
			if (dataSource.eventsById[itemEvent.Id] === undefined) {
				dataSource.eventsById[itemEvent.Id] = normalizeAssetData(assetId, "event", itemEvent, normalized);
			}
		});
	}

	return normalized;
}
