import strings from "./strings.js";

import _ from "lodash";
import $ from "jquery";

//loadDialogButtons(domNodes.dialogs.placeRouting, placeRouteButtons);

export const closeButton = {
	icons: { primary: "ui-icon-close" },
	text: strings.CANCEL,
	click: function () {
		document.getElementById("panel-dialog-back").click();
	},
	buttonType: "secondary",
};

export function loadDialogButtons(dialog, buttons) {
	if (buttons === null || buttons.length === 0) {
		return;
	}
	if (dialog === null) {
		return;
	}
	var buttonPane = dialog.querySelector(".dialog-buttons");
	if (buttonPane === null) {
		return;
	}
	buttons.forEach(function (button, index, list) {
		var btn = document.createElement("button");
		btn.className = "btn";
		if (button.class !== undefined) {
			btn.className += " " + button.class;
		}
		btn.type = "button";
		if (button.id !== undefined) {
			btn.id = button.id;
		}
		if (button.svgIcon !== undefined) {
			var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
			var use = document.createElementNS("http://www.w3.org/2000/svg", "use");
			svg.appendChild(use);
			use.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#" + button.svgIcon);
			btn.appendChild(svg);
			btn.appendChild(document.createTextNode("\u00A0"));
		}
		if (button.buttonType !== undefined) {
			btn.classList.add("btn-" + button.buttonType);
		} else {
			// default to primary if left unspecified
			btn.classList.add("btn-primary");
		}
		if (button.disabled !== undefined && button.disabled === true) {
			btn.disabled = true;
		}
		var label = document.createElement("span");
		label.textContent = button.text;
		btn.appendChild(label);

		if (button.click !== undefined) {
			$(btn).on("click", button.click);
		}
		if (buttonPane.hasChildNodes()) {
			buttonPane.appendChild(document.createTextNode("\u00A0"));
		}
		buttonPane.appendChild(btn);
	});
}
