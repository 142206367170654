import { createMarkerPath } from "./marker-path.js";
import strings from "./strings.js";
import domNodes from "./domNodes.js";
import state from "./state.js";
import { viewModes } from "./const.js";
import FilteredList from "./filtered-list.js";
import trkData from "./data.js";
import { findAssetById } from "./assets.js";
import { createIconForAssetEvent } from "./asset-state.js";
import { formattedTextToDiv } from "./dom-util.js";
import { findPlaceByUniqueKey } from "./place.js";
import { findFenceById } from "./fence.js";
import {
	convertToLatLngPreference,
	convertSpeedToPreference,
	convertFromMetresToUserDistancePreference,
	convertAltitudeToPreference,
} from "./preferences.js";
import { getStatusTextForLocation } from "./location.js";
import user from "./user.js";
import { switchMapMode } from "./map-base.js";
import { queryActiveAssets } from "./assets-active.js";
import options from "./options.js";
import { mapModes } from "./const.js";
import { querySharedViewData } from "./shared-view.js";
import { renderListItem } from "./item-list-render.js";

import _ from "lodash";
import { el, text } from "redom"; // https://redom.js.org/
import moment from "moment"; // https://www.npmjs.com/package/moment

export function updateItemListingIcon(itemId, color, isActive, iconType, nodeList) {
	var alpha = isActive ? null : 50;
	var forceAlpha = isActive ? false : true;
	var iconPath = createMarkerPath(iconType, color, null, alpha, itemId, forceAlpha);
	var items = nodeList[itemId];
	if (!_.isArray(items)) {
		items = [items];
	}
	_.each(items, function (itemNode) {
		if (isActive) {
			itemNode.classList.remove("disabled");
			itemNode.classList.add("active");
		} else {
			itemNode.classList.add("disabled");
			itemNode.classList.remove("active");
		}
		var itemIcon = itemNode.querySelector(".item-header");
		itemIcon.style["background-image"] = "url(" + iconPath + ")";
	});
}

export function showResultLimitsIfApplicable(result, isDateFiltered) {
	console.log("showResultLimitsIfApplicable", isDateFiltered);
	// first page may not be limited, need additional property to know that?
	if (result.IsLimited === undefined || result.IncludesLimitedData === undefined) {
		return;
	}
	// only applies to history mode and shared views
	var excess = domNodes.mapMode.excessData;
	var cont = domNodes.mapMode.container;
	if (result.IsLimited || isDateFiltered) {
		if (isDateFiltered) {
			cont.classList.add("is-filtered");
		} else {
			cont.classList.remove("is-filtered");
		}
		//if (result.IsLimited) {
		excess.querySelector("p").textContent = strings.ERROR_TOO_MANY_RESULTS.replace("{0}", result.Limit);
		//}

		cont.classList.add("has-excess");
		if (isDateFiltered || (result.IsLimited && result.IncludesLimitedData)) {
			// user agreed to view the limited data
			cont.classList.add("is-limited");
			document.getElementById("map-mode-details-limited").querySelector("div").textContent =
				strings.RESULTS_LIMITED.replace("{0}", result.Limit);
		} else {
			cont.classList.remove("is-limited");
			// show history form with notices
			domNodes.mapMode.dateRange.classList.add("is-visible");
			document.getElementById("history-custom").classList.add("active");
		}
	} else {
		// also when mode is changed
		cont.classList.remove("is-limited");
		cont.classList.remove("has-excess");
		cont.classList.remove("is-filtered");
	}

	if (state.activeViewMode === viewModes.NORMAL) {
	} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
	}
}

export const notificationLists = {
	positions: null,
	events: null,
	chat: null,
	messages: null,
	alerts: null,
	status: null,
	activity: null,
	positionsList: null,
	eventsList: null,
	chatList: null,
	messagesList: null,
	alertsList: null,
	statusList: null,
	activityList: null,
};

export function createListing(items, grouping) {
	var container = document.getElementById("asset-" + grouping + "-container");
	var mapped = _.map(items, function (item) {
		return mapActivityItemToListItem(item);
	});

	// TODO pull filter from somewhere
	if (notificationLists[grouping + "List"] === null) {
		notificationLists[grouping + "List"] = new FilteredList(mapped, container, renderListItem, {
			paging: { currentPage: 1 },
			groupBy: grouping === "activity" ? "EpochGroup" : null,
		});
	} else {
		// change data and re-render
		var listing = notificationLists[grouping + "List"];
		listing.data.items = mapped;
		listing.reindex();
		listing.redraw();
	}

	// TODO deciding which item should be in meta
	var first = _.first(_.sortBy(mapped, "Epoch").reverse());
	renderMetaItem(first, grouping);
}

export function defaultListItemSort() {
	return function (item) {
		return item.Epoch;
	};
}

function renderMetaItem(item, grouping) {
	var meta = document.getElementById("panel-secondary-meta").querySelector(".meta-" + grouping);
	var metaDetails = meta.querySelector("table");
	if (item !== undefined) {
		metaDetails.classList.remove("toggle-content");
		if (item.IsChat !== undefined || item.MessageId !== undefined) {
			var itemType = meta.querySelector(".recent-item-type");
			if (itemType !== null) {
				itemType.querySelector(".meta-item").textContent = item.EventName;
			}
			var itemMessage = meta.querySelector(".recent-item-message");
			if (itemMessage !== null) {
				itemMessage.querySelector(".meta-item").textContent = item.Text;
			}
		} else if (item.EventId !== undefined) {
			var itemType = meta.querySelector(".recent-item-type");
			itemType.querySelector(".meta-item").textContent = item.EventName;
		} else if (item.PositionId !== undefined) {
		}

		// common to all
		var itemTime = meta.querySelector(".recent-item-time");
		if (itemTime !== null) {
			itemTime.querySelector(".meta-item").textContent = item.Time;
		}
		var itemName = meta.querySelector(".recent-item-name");
		if (itemName !== null) {
			itemName.querySelector(".meta-item").textContent = item.AssetName;
		}
		var itemAddress = meta.querySelector(".recent-item-address");
		if (itemAddress !== null) {
			if (item.Position !== undefined && item.Position !== null && item.Position.Address !== null) {
				itemAddress.querySelector(".meta-item").textContent = item.Position.Address;
				itemAddress.classList.remove("toggle-content");
			} else {
				itemAddress.classList.add("toggle-content");
			}
		}
		var itemLatLng = meta.querySelector(".recent-item-latlng");
		if (itemLatLng !== null) {
			if (item.Position !== undefined && item.Position !== null && item.Position.LatLng !== null) {
				itemLatLng.querySelector(".meta-item").textContent = item.Position.LatLng;
				itemLatLng.classList.remove("is-visible");
			} else {
				itemLatLng.classList.add("toggle-content");
			}
		}
	} else {
		metaDetails.classList.add("toggle-content");
	}
}

function mapActivityItemToListItem(item) {
	if (item.Chat !== undefined) {
		return mapMessageToListItem(item);
	} else if (item.Message !== undefined) {
		return mapMessageToListItem(item);
	} else if (item.Event !== undefined) {
		return mapEventToListItem(item);
	} else if (item.Position !== undefined) {
		return mapPositionToListItem(item);
	}
}

function mapMessageToListItem(item) {
	if ((item.Message === undefined || item.Message === null) && (item.Chat === undefined || item.Chat === null)) {
		return undefined;
	}
	var dataSource = state.activeViewMode === viewModes.SHARED_VIEW ? trkData.sharedView : trkData;
	var assetId = item.AssetId;
	var position = item.Position;
	var isChat = item.Message === undefined;
	var item = !isChat ? item.Message : item.Chat;

	var existing = dataSource.activityById["m-" + item.Id];
	if (existing !== undefined) {
		return existing;
	}

	var asset = findAssetById(assetId);
	var iconUrl = createMarkerPath(asset.Class, asset.Color, null, null, asset.Id, false);
	var positionId = undefined;
	position = mapPositionDetailsForListItem(asset, position);
	if (position !== undefined) {
		positionId = position.Id;
	}

	var mapped = {
		Id: "m-" + item.Id,
		MessageId: item.Id,
		PositionId: positionId,
		AssetId: asset.Id,
		IsFromMobile: item.IsFromMobile,
		Epoch: item.CreatedEpoch,
		EpochGroup: item.CreatedEpoch + "-" + asset.Id,
		IsError: item.IsError,
		IsAcknowledged: item.IsAcknowledged,
		IconUrl: iconUrl,
		IsChat: isChat,
		// to index
		AssetName: asset.Name,
		Method: item.IsFromMobile ? strings.FROM_MOBILE : strings.TO_MOBILE,
		EventName: item.Type,
		RawText: item.RawText,
		Source: item.Source,
		Time: item.CreatedOn,
		Response: item.Response,
		Details: text(item.Text),
		Status: !item.IsError && !item.IsSent ? strings.PENDING : item.Status,
		Position: position, //Position.Address, Position.LatLng
	};
	dataSource.activityById["m-" + item.Id] = mapped;
	return mapped;
}

function mapPositionToListItem(positionItem) {
	if (positionItem.Position === undefined || positionItem.Position === null) {
		return undefined;
	}

	var dataSource = state.activeViewMode === viewModes.SHARED_VIEW ? trkData.sharedView : trkData;

	var existing = dataSource.activityById["p-" + positionItem.Position.Id];
	if (existing !== undefined) {
		return existing;
	}

	var asset = findAssetById(positionItem.AssetId);
	var item = positionItem.Position;
	var position = mapPositionDetailsForListItem(asset, item);
	var iconUrl = createMarkerPath(asset.Class, asset.Color, null, null, asset.Id, false);

	//var address = null;
	//if (!asset.HideAddress) {
	//    address = item.Address;
	//}

	//var latLng = convertToLatLngPreference(item.DisplayLat, item.DisplayLng, item.Grid);

	var eventTypes = _.uniq(_.map(item.Events, "TypeName")).join(", ");
	var mapped = {
		Id: "p-" + item.Id,
		PositionId: item.Id,
		AssetId: asset.Id,
		IconUrl: iconUrl,
		Epoch: item.Epoch,
		EpochGroup: item.Epoch + "-" + asset.Id,
		IsHidden: item.IsHidden,
		// to index
		AssetName: asset.Name,
		Time: item.Time,
		EventTypes: eventTypes,
		Position: position,
	};
	dataSource.activityById["p-" + positionItem.Position.Id] = mapped;
	return mapped;
}

function mapEventToListItem(eventItem) {
	if (eventItem.Event === undefined || eventItem.Event === null) {
		return undefined;
	}

	var dataSource = state.activeViewMode === viewModes.SHARED_VIEW ? trkData.sharedView : trkData;

	var existing = dataSource.activityById["e-" + eventItem.Event.Id];
	if (existing !== undefined) {
		return existing;
	}

	var asset = findAssetById(eventItem.AssetId);
	var position = eventItem.Position;
	var item = eventItem.Event;

	var svgIcon = createIconForAssetEvent(asset, item.Type);
	var iconUrl = createMarkerPath(asset.Class, asset.Color, null, null, asset.Id, false, item.Type);
	var eventIcon = el("div.event-icon", { style: { backgroundImage: "url(" + iconUrl + ")" } });
	var detailsElement = formattedTextToDiv(item.Details);
	switch (item.Type) {
		case 127: // garmin form submitted
			if (item.Details != null) {
				var formDetails = item.Details.split("|");
				var formId = formDetails[0];
				var formName = strings.VIEW;
				if (formDetails.length > 1) {
					formName = formDetails[1];
				}
				detailsElement = el(
					"button.ViewGarminSubmission.command.details.btn.btn-sm.btn-secondary",
					{ dataset: { id: formId, assetId: asset.Id } },
					formName
				);
			}
			break;
		case 270: // beacon read
			if (item.Details != null) {
				detailsElement = null;
				var detailsItems = [];
				var beacons = item.Details.split("\n");
				for (var k = 0; k < beacons.length; k++) {
					var beacon = beacons[k].split("|");
					if (beacon.length > 1) {
						var beaconUniqueKey = beacon[0];
						var beaconRSSI = beacon[1];
						var beaconTxPower = beacon[2];
						if (beaconUniqueKey == null) {
							continue;
						}
						var beaconPlace = findPlaceByUniqueKey(beaconUniqueKey);
						if (beaconPlace != null) {
							detailsItems.push(
								el("a.beacon-place", { href: "#", dataset: { placeId: beaconPlace.Id } }, beaconPlace.Name)
							);
							detailsItems.push(text(" @ " + beaconRSSI + " RSSI"));
						} else {
							detailsItems.push(text(beaconUniqueKey + " @ " + beaconRSSI + " RSSI"));
						}
						if (beaconTxPower != "") {
							detailsItems.push(text(", " + beaconTxPower + " Tx"));
						}
						detailsItems.push(el("br"));
					}
				}
				if (detailsItems.length > 0) {
					detailsElement = el("div", detailsItems);
				}
			}
			break;
		case 272:
		case 273:
		case 274:
			// driver fatigue
			if (item.Details != null) {
				detailsElement = el(
					"button.ViewPhoto.command.details.btn.btn-sm.btn-secondary",
					{ title: strings.VIEW_PHOTO, dataset: { photo: item.Details } },
					strings.VIEW_PHOTO
				);
			}
			break;
		case 275:
			if (item.Details != null) {
				var parts = item.Details.split("|||");
				detailsElement = el(
					"button.ViewPhoto.command.details.btn.btn-sm.btn-secondary",
					{ title: strings.VIEW_PHOTO, dataset: { photo: parts[1] } },
					strings.VIEW_PHOTO
				);
			}
			break;
	}

	var fullName = item.TypeName;
	if (item.Alert !== undefined && item.Alert !== null) {
		var alertName = item.Alert.Type;
		if (item.Alert.Name !== null) {
			alertName = item.Alert.Name + " [ " + item.Alert.Type + " ]";
		}
		fullName += ", " + alertName;
	}

	var positionId = undefined;
	position = mapPositionDetailsForListItem(asset, position);
	if (position !== undefined) {
		positionId = position.Id;
	}

	var mapped = {
		Id: "e-" + item.Id,
		EventId: item.Id,
		PositionId: positionId,
		AssetId: asset.Id,
		Icon: eventIcon, // unused
		IconUrl: iconUrl,
		SvgIcon: svgIcon,
		IsAccurate: item.IsAcc,
		Type: item.Type,
		Epoch: item.Epoch,
		EpochGroup: item.Epoch + "-" + asset.Id,

		// to index
		AssetName: asset.Name,
		EventName: fullName,
		Position: position,
		Details: detailsElement,
		Time: item.Time,
		Alert: item.Alert,
	};
	dataSource.activityById["e-" + eventItem.Event.Id] = mapped;
	return mapped;
}

function mapPositionDetailsForListItem(asset, item) {
	if (item == undefined || item === null) {
		return undefined;
	}
	var address = null;
	if (!asset.HideAddress) {
		address = item.Address;
	}
	var latLng = convertToLatLngPreference(item.DisplayLat, item.DisplayLng, item.Grid);

	var course = undefined;
	if (!asset.HideCourse) {
		course = item.Course;
	}

	var status = getStatusTextForLocation(item);

	var altitude = null;
	if (!asset.HideAltitude && item.Altitude !== undefined && item.Altitude !== null) {
		altitude = convertAltitudeToPreference(item.Altitude);
	}

	var fences = null;
	if (item.InsideFences !== undefined && item.InsideFences !== null) {
		var fenceNames = [];
		_.each(item.InsideFences, function (fenceId) {
			var fence = findFenceById(fenceId);
			if (fence !== null) {
				fenceNames.push(fence.Name);
			}
		});
		fences = fenceNames.join(", ");
	}

	var speed = null;
	if (!asset.HideSpeed) {
		speed = convertSpeedToPreference(item.Speed);
	}
	var accuracy = null;
	if (!asset.HideAccuracy) {
		accuracy = item.Accuracy;
	}

	var odometer = null;
	if (item.Odometer !== undefined && item.Odometer !== null) {
		odometer = convertFromMetresToUserDistancePreference(item.Odometer);
	}

	// core properties
	var mapped = {
		Id: item.Id,
		LatLng: latLng,
		Address: address,
		Time: item.Time, // duplicated for consistency
		IsHidden: item.IsHidden,
		// extra details
		Source: item.Source,
		Odometer: odometer,
		Heading: course,
		Status: status,
		Altitude: altitude,
		InsideFences: fences,
		Speed: speed,
		IsSpeedEstimated: item.IsEst,
		IsTimeAccurate: item.IsAcc,
		Accuracy: accuracy,
	};
	return mapped;
}

export function filterDateClick(btn) {
	// TODO this is a mess and should be refactored
	var range = btn.id.substring(8);
	var fromDate = null;
	var historyCustom = document.getElementById("history-custom");
	var historyDateForm = domNodes.mapMode.dateRange;
	var doQuery = true;
	var historyButtons = document.getElementById("filter-history-range").querySelectorAll("button");
	// clicking custom button should not change any others
	if (range !== "custom") {
		_.each(historyButtons, function (button) {
			if (button.id === "history-live") {
				return;
			}
			button.classList.remove("active");
		});
	}
	switch (range) {
		case "live":
			historyDateForm.classList.remove("is-visible");
			switchMapMode(mapModes.LIVE);
			return;
		case "1h":
			fromDate = moment().subtract(user.tickOffset, "ms").subtract(1, "hours");
			historyDateForm.classList.remove("is-visible");
			historyCustom.classList.remove("btn-primary");
			break;
		case "24h":
			fromDate = moment().subtract(user.tickOffset, "ms").subtract(24, "hours");
			historyDateForm.classList.remove("is-visible");
			historyCustom.classList.remove("btn-primary");
			break;
		case "7d":
			fromDate = moment().subtract(user.tickOffset, "ms").subtract(7, "days");
			historyDateForm.classList.remove("is-visible");
			historyCustom.classList.remove("btn-primary");
			break;
		case "1m":
			fromDate = moment().subtract(user.tickOffset, "ms").subtract(1, "months");
			historyDateForm.classList.remove("is-visible");
			historyCustom.classList.remove("btn-primary");
			break;
		case "custom":
			if (historyDateForm.classList.contains("is-visible")) {
				historyDateForm.classList.remove("is-visible");
				if (!btn.classList.contains("btn-primary")) {
					btn.classList.remove("active");
				}
			} else {
				if (!(state.isFirstLoad && options.isCompact)) {
					historyDateForm.classList.add("is-visible");
				}
			}
			doQuery = false;
			break;
	}
	if (fromDate !== null) {
		var historyDateFrom = document.getElementById("txtDateFrom");
		historyDateFrom.value = fromDate.format(user.dateWithStandardTimeFormat);
		var historyDateTo = document.getElementById("txtDateTo");
		historyDateTo.value = "";
	}

	if (doQuery) {
		if (state.activeViewMode === viewModes.NORMAL) {
			if (state.activeMapMode === mapModes.LIVE) {
				switchMapMode(mapModes.HISTORY, null, true, true);
			} else {
				queryActiveAssets(null, true);
			}
		} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
			// requery shared view with dates entered in panel, limits apply
			var dateFilter = {
				from: document.getElementById("txtDateFrom").value,
				to: document.getElementById("txtDateTo").value,
			};
			if (trkData.sharedView.temp !== null) {
				querySharedViewData(trkData.sharedView.temp, dateFilter, false);
			} else if (trkData.sharedView.current !== null) {
				querySharedViewData(trkData.sharedView.current, dateFilter, false);
			}
		}
	}

	//if (range !== 'custom') {
	btn.classList.add("active");
	//}
}
