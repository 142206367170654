import trkData from "./data.js";
import strings from "./strings.js";
import domNodes from "./domNodes.js";
import {
	handleAjaxFormSubmission,
	formShowErrorMessage,
	handleAjaxFormSubmissionWithGatewaySelection,
} from "./ajax.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { requestAVLInformation } from "./avl.js";
import { handleWebServiceError } from "./ajax.js";
import { openActionDialog } from "./modal.js";
import { getValueIfCheckboxSelected } from "./dom-util.js";
import { requestIDPCommandLog, requestIDPCryptoInformation, resetIDPCounters, idpSendCommand } from "./idp-command.js";
import { findAssetById } from "./assets.js";
import { formShowSuccessMessage } from "./ajax.js";
import { loadDialogButtons, closeButton } from "./modal-dialog-buttons.js";
import { devices, findDeviceById } from "./devices.js";

import $ from "jquery";
import $j from "jquery";
import jQuery from "jquery";
import _ from "lodash";
import { el, svg, mount } from "redom"; // https://redom.js.org/

export function initIDPCommand() {
	$j(document).on("keydown", function (event) {
		// keys[event.which] = true;
		if (event.which === 13 && event.ctrlKey) {
			// ctrl+enter
			if (document.activeElement === document.getElementById("IDPCommand")) {
				// auto submit the command
				idpSendCommand();
			}
		}
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#RefreshIDPCommandLog", function (e) {
		e.preventDefault();

		var assetId = $(domNodes.dialogs.idpSendCommand).data("assetId");
		requestIDPCommandLog(assetId, false);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPFlushGeofenceSpeed", function (e) {
		e.preventDefault();

		var command = 'os.remove("data/svc/AVL/GeoSpeeds.dat")';

		var status = document.getElementById("idp-query-status");
		var btn = this;

		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				assetIds: assetIds,
				groupIds: groupIds,
				command: command,
				isLua: true,
				isJson: false,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
			};

			$.when(
				handleAjaxFormSubmission(
					"IDPSendExecuteCommandRequest",
					data,
					btn,
					status,
					strings.MSG_COMMAND_SUCCESS,
					strings.MSG_COMMAND_ERROR
				)
			).done(function () {
				requestIDPCommandLog(id, false);
				$j("#IDPCommand").val("");
				$(domNodes.modals.messageAction).modal("hide");
			});
		};

		openActionDialog(strings.FLUSH_GEOFENCE_SPEED, strings.FLUSH_GEOFENCE_SPEED, callback);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPFlushGeofenceDwell", function (e) {
		e.preventDefault();

		var command = 'os.remove("data/svc/AVL/GeoDwell.dat")';
		var status = document.getElementById("idp-query-status");
		var btn = this;

		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				assetIds: assetIds,
				groupIds: groupIds,
				command: command,
				isLua: true,
				isJson: false,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
			};

			$.when(
				handleAjaxFormSubmission(
					"IDPSendExecuteCommandRequest",
					data,
					btn,
					status,
					strings.MSG_COMMAND_SUCCESS,
					strings.MSG_COMMAND_ERROR
				)
			).done(function () {
				requestIDPCommandLog(id, false);
				$j("#IDPCommand").val("");
				$(domNodes.modals.messageAction).modal("hide");
			});
		};

		openActionDialog(strings.FLUSH_GEOFENCE_DWELL, strings.FLUSH_GEOFENCE_DWELL, callback);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPSendCommand", function (e) {
		e.preventDefault();

		idpSendCommand();
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPSetParameters", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.idpParameters.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var parameters = [];
		$j("#IDPParameters input[type=hidden]").each(function (index, elem) {
			parameters.push($j(this).data("parameter"));
		});

		if (parameters.length == 0) {
			return;
		}

		var data = { parameters: parameters };
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendSetParametersRequest",
			data,
			this,
			status,
			strings.MSG_IDP_PARAMETERS_SUCCESS,
			strings.MSG_IDP_PARAMETERS_ERROR,
			null,
			null,
			null,
			strings.SET_PARAMETERS,
			strings.SET_PARAMETERS
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#QueryIDPCoreProperties", function (e) {
		e.preventDefault();

		var btn = this;
		var status = document.getElementById("idp-query-status");
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
				groupIds: groupIds,
				assetIds: assetIds,
			};

			$.when(
				handleAjaxFormSubmission(
					"IDPSendGetCorePropertiesRequest",
					data,
					btn,
					status,
					strings.MSG_GET_IDP_PARAMETERS_SUCCESS,
					strings.MSG_GET_IDP_PARAMETERS_ERROR
				)
			).done(function () {
				requestAVLInformation(id);
				$(domNodes.modals.messageAction).modal("hide");
			});
		};

		openActionDialog(strings.BUTTON_IDP_GET_CORE_PARAMETERS, strings.BUTTON_IDP_GET_CORE_PARAMETERS, callback);
	});

	$(domNodes.modals.idpActivate).on("click", "#IDPActivateConfirm", function (e) {
		e.preventDefault();

		var btn = this;
		var statusError = document.getElementById("idp-activate-status");
		var statusSuccess = document.getElementById("edit-asset-status");
		var id = $j("#hfIDPActivateAssetId").val();
		var data = {
			assetId: id,
		};
		btn.disabled = true;
		toggleLoadingMessage(true, "idp-activate");
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/IDPActivateTerminal"),
			data: JSON.stringify(data),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				btn.disabled = false;
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						$(domNodes.modals.idpActivate).modal("hide");
						formShowSuccessMessage(statusSuccess, strings.MSG_IDP_ACTIVATE_SUCCESS);
					} else {
						formShowErrorMessage(statusError, strings.MSG_IDP_ACTIVATE_ERROR);
						if (result.ErrorMessage != null && result.ErrorMessage != "") {
							formShowErrorMessage(statusError, statusError.textContent + " " + result.ErrorMessage);
						}
					}
				}
				toggleLoadingMessage(false, "idp-activate");
			},
			error: function (xhr, status, error) {
				btn.disabled = false;
				handleWebServiceError(strings.MSG_IDP_ACTIVATE_ERROR);
				toggleLoadingMessage(false, "idp-activate");
			},
		});
	});
	$("#edit-asset-dialog").on("click", "#IDPActivate", function (e) {
		e.preventDefault();
		// open confirmation dialog
		var asset = findAssetById($j("#hfEditAssetId").val());
		$("#hfIDPActivateAssetId").val(asset.Id);
		// customize confirmation message
		var gateway = $j("#ddlEditAssetGatewayAccount option[value=" + asset.GatewayAccountId + "]").text();
		//var status = $j('#idp-activate-status').hide();
		$(".modal-body div.confirm", domNodes.modals.idpActivate).text(
			strings.MSG_IDP_ACTIVATE_CONFIRM.replace("{0}", asset.UniqueId).replace("{1}", gateway)
		);
		$(domNodes.modals.idpActivate).modal("show");
	});

	$(domNodes.modals.idpDisableEncryption).on("click", "#IDPEncryptionDisableConfirm", function (e) {
		e.preventDefault();

		var btn = this;
		var statusError = document.getElementById("idp-encryption-disable-status");
		var statusSuccess = document.getElementById("idp-query-status");
		var id = $(domNodes.dialogs.idpSendCommand).data("assetId");
		var data = {
			assetId: id,
		};
		btn.disabled = true;
		toggleLoadingMessage(true, "idp-encryption-disable");
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/IDPCryptoDisableRequirement"),
			data: JSON.stringify(data),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				btn.disabled = false;
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						$(domNodes.modals.idpDisableEncryption).modal("hide");
						formShowSuccessMessage(statusSuccess, strings.MSG_CRYPTO_DISABLE_SUCCESS);
						requestIDPCryptoInformation(id);
					} else {
						formShowErrorMessage(statusError, strings.MSG_CRYPTO_DISABLE_ERROR);
						if (result.ErrorMessage != null && result.ErrorMessage != "") {
							formShowErrorMessage(statusError, statusError.textContent + " " + result.ErrorMessage);
						}
					}
				}
				toggleLoadingMessage(false, "idp-encryption-disable");
			},
			error: function (xhr, status, error) {
				btn.disabled = false;
				handleWebServiceError(strings.MSG_CRYPTO_DISABLE_ERROR);
				toggleLoadingMessage(false, "idp-encryption-disable");
			},
		});
	});

	$(domNodes.modals.idpDeactivate).on("click", "#IDPDeactivateConfirm", function (e) {
		e.preventDefault();

		var btn = this;
		var statusError = document.getElementById("idp-deactivate-status");
		var statusSuccess = document.getElementById("edit-asset-status");
		var id = $j("#hfIDPDeactivateAssetId").val();
		var data = {
			assetId: id,
		};
		btn.disabled = true;
		toggleLoadingMessage(true, "idp-deactivate");
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/IDPDeactivateTerminal"),
			data: JSON.stringify(data),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				btn.disabled = false;
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						$(domNodes.modals.idpDeactivate).modal("hide");
						formShowSuccessMessage(statusSuccess, strings.MSG_IDP_DEACTIVATE_SUCCESS);
					} else {
						formShowErrorMessage(statusError, strings.MSG_IDP_DEACTIVATE_ERROR);
						if (result.ErrorMessage != null && result.ErrorMessage != "") {
							formShowErrorMessage(statusError, statusError.textContent + " " + result.ErrorMessage);
						}
					}
				}
				toggleLoadingMessage(false, "idp-deactivate");
			},
			error: function (xhr, status, error) {
				btn.disabled = false;
				handleWebServiceError(strings.MSG_IDP_DEACTIVATE_ERROR);
				toggleLoadingMessage(false, "idp-deactivate");
			},
		});
	});
	$("#edit-asset-dialog").on("click", "#IDPDeactivate", function (e) {
		e.preventDefault();
		// open confirmation dialog
		var asset = findAssetById($j("#hfEditAssetId").val());
		$("#hfIDPDeactivateAssetId").val(asset.Id);
		// customize confirmation message
		var gateway = $j("#ddlEditAssetGatewayAccount option[value=" + asset.GatewayAccountId + "]").text();
		//var status = $j('#idp-deactivate-status').hide();
		$(".modal-body div.confirm", domNodes.modals.idpDeactivate).text(
			strings.MSG_IDP_DEACTIVATE_CONFIRM.replace("{0}", asset.UniqueId).replace("{1}", gateway)
		);
		$(domNodes.modals.idpDeactivate).modal("show");
	});

	var idpOutputButtons = [
		{
			text: strings.BUTTON_SET_OUTPUT,
			click: function () {
				var status = $("#idp-output-status");
				var isFormValid = $(trkData.validation.idpOutput.currentForm).valid();
				if (!isFormValid) {
					trkData.validation.idpOutput.focusInvalid();
					return;
				}

				var id = $("#hfOutputAssetId").val();
				var asset = findAssetById(id);
				if (asset == null) {
					return;
				}
				var device = findDeviceById(asset.DeviceId);
				var btn = this;
				var status = document.getElementById("idp-output-status");

				// determine which form we should be submitting (IDP vs NAL or others)
				var activeForm = document.querySelector("#idp-output-dialog .output-fields.is-visible");
				if (activeForm.id === "idp-output-fields") {
					var pin = $("#ddlIDPOutputPin").val();
					var time = $("#txtIDPOutputTime").val();
					var vals = pin.split(",");

					var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
						var data = {
							assetId: id,
							assetIds: assetIds,
							groupIds: groupIds,
							pin: vals[0],
							value: vals[1],
							time: time,
							gateway: gateway,
							gatewayTimeout: gatewayTimeout,
							gatewayRetries: gatewayRetries,
						};

						$.when(
							handleAjaxFormSubmission(
								"SendOutputPinRequest",
								data,
								btn,
								status,
								strings.MSG_SET_OUTPUT_SUCCESS,
								strings.MSG_SET_OUTPUT_ERROR
							)
						).done(function () {
							$(domNodes.modals.messageAction).modal("hide");
						});
					};

					openActionDialog(strings.BUTTON_SET_OUTPUT, strings.BUTTON_SET_OUTPUT, callback, id);
				} else if (activeForm.id === "nal-output-fields") {
					var pins = {};
					for (var i = 1; i <= device.OutputPinCount; i++) {
						var pin = document.getElementById("nal-output-" + i + "-on");
						pins[i.toString()] = pin.checked;
					}

					var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
						var data = {
							assetId: id,
							assetIds: assetIds,
							groupIds: groupIds,
							pins: pins,
							gateway: gateway,
							gatewayTimeout: gatewayTimeout,
							gatewayRetries: gatewayRetries,
						};

						$.when(
							handleAjaxFormSubmission(
								"SetOutputPins",
								data,
								btn,
								status,
								strings.MSG_SET_OUTPUT_SUCCESS,
								strings.MSG_SET_OUTPUT_ERROR
							)
						).done(function () {
							$(domNodes.modals.messageAction).modal("hide");
						});
					};
					var satelliteOnly = false;
					if (_.indexOf(devices.NAL_GSM, asset.DeviceId) === -1) {
						satelliteOnly = true;
					}
					openActionDialog(
						strings.BUTTON_SET_OUTPUT,
						strings.BUTTON_SET_OUTPUT,
						callback,
						id,
						null,
						satelliteOnly,
						devices.NAL
					);
				}
			},
		},
		closeButton,
	];

	loadDialogButtons(domNodes.dialogs.idpOutput, idpOutputButtons);

	$(document).on("click", "#accordion-query-idp-diagnostics-head", function () {
		var table = $("#IDPSleepSchedules").dataTable();
		table.fnAdjustColumnSizing();
	});

	$j("#IDPParameters").on("click", "button.remove", function (e) {
		e.preventDefault();

		// remove row
		$j(this).parent().parent().remove();
	});

	loadDialogButtons(domNodes.dialogs.idpSendCommand, [closeButton]);
	$(domNodes.dialogs.idpSendCommand).on("change", "#ddlIDPImmobilize", function (e) {
		var immobilizerTime = document.getElementById("IDPImmobilizerTime");
		if (this.value === "true") {
			immobilizerTime.classList.add("is-visible");
		} else {
			immobilizerTime.classList.remove("is-visible");
		}
	});
	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPImmobilizerStatus", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");

		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPGetImmobilizerStatus",
			data,
			this,
			status,
			strings.MSG_GET_IMMOBILIZER_STATUS_SUCCESS,
			strings.MSG_GET_IMMOBILIZER_STATUS_ERROR,
			null,
			null,
			null,
			strings.GET_IMMOBILIZER_STATUS,
			strings.GET_IMMOBILIZER_STATUS
		);
	});
	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPDriverLoginStatus", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");

		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPGetDriverLoginState",
			data,
			this,
			status,
			strings.MSG_GET_DRIVER_LOGIN_STATE_SUCCESS,
			strings.MSG_GET_DRIVER_LOGIN_STATE_ERROR,
			null,
			null,
			null,
			strings.GET_DRIVER_LOGIN_STATE,
			strings.GET_DRIVER_LOGIN_STATE
		);
	});
	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPImmobilizerUpdate", function (e) {
		e.preventDefault();

		var enable = document.getElementById("ddlIDPImmobilize").value;
		var time = document.getElementById("txtIDPImmobilizerTime").value;
		var data = {
			enable: enable,
			time: time,
		};

		var status = document.getElementById("idp-query-status");

		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPUpdateImmobilizer",
			data,
			this,
			status,
			strings.MSG_UPDATE_IMMOBILIZER_SUCCESS,
			strings.MSG_UPDATE_IMMOBILIZER_ERROR,
			null,
			null,
			null,
			strings.UPDATE_IMMOBILIZER,
			strings.UPDATE_IMMOBILIZER
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "button.DisableGeofence", function (e) {
		e.preventDefault();
		var fenceId = $j(this).data("fenceId");
		var data = {
			fenceId: fenceId,
		};
		var status = document.getElementById("idp-query-status");

		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendDisableGeofenceRequest",
			data,
			this,
			status,
			strings.MSG_DISABLE_GEOFENCE_SUCCESS,
			strings.MSG_DISABLE_GEOFENCE_ERROR,
			null,
			null,
			null,
			strings.DISABLE_GEOFENCE,
			strings.DISABLE_GEOFENCE
		);
	});
	$(domNodes.dialogs.idpSendCommand).on("click", "button.AgentVersionQuery", function (e) {
		e.preventDefault();
		var serviceType = $j(this).data("for");
		var data = {
			serviceType: serviceType,
		};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendServiceInfoRequest",
			data,
			this,
			status,
			strings.MSG_SERVICE_INFO_SUCCESS,
			strings.MSG_SERVICE_INFO_ERROR,
			null,
			null,
			null,
			strings.REQUEST_SERVICE_INFO,
			strings.REQUEST_SERVICE_INFO
		);
	});
	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPProvisionServices", function (e) {
		e.preventDefault();

		var disable = $j("#IDPServiceListing input:radio:checked.disable");
		var enable = $j("#IDPServiceListing input:radio:checked.enable");
		var services = {
			EnableSINs: [],
			DisableSINs: [],
		};
		disable.each(function (index, elem) {
			services.DisableSINs.push($j(elem).data("sin"));
		});
		enable.each(function (index, elem) {
			services.EnableSINs.push($j(elem).data("sin"));
		});

		var status = document.getElementById("idp-query-status");
		var data = {
			services: services,
		};
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPToggleServices",
			data,
			this,
			status,
			strings.MSG_IDP_TOGGLE_SERVICE_SUCCESS,
			strings.MSG_IDP_TOGGLE_SERVICE_ERROR,
			null,
			null,
			null,
			strings.PROVISION_SERVICES,
			strings.PROVISION_SERVICES
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPAVLDiagnostics", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendGetAVLDiagnosticsRequest",
			data,
			this,
			status,
			strings.MSG_AVL_DIAGNOSTICS_SUCCESS,
			strings.MSG_AVL_DIAGNOSTICS_ERROR,
			null,
			null,
			null,
			strings.REQUEST_AVL_DIAGNOSTICS,
			strings.REQUEST_AVL_DIAGNOSTICS
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPSetServiceMeters", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.idpMetersSet.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var SM0Time = getValueIfCheckboxSelected("#txtIDPAVLSM0Time");
		var SM0Distance = getValueIfCheckboxSelected("#txtIDPAVLSM0Distance");
		var SM1Time = getValueIfCheckboxSelected("#txtIDPAVLSM1Time");
		var SM1Distance = getValueIfCheckboxSelected("#txtIDPAVLSM1Distance");
		var SM2Time = getValueIfCheckboxSelected("#txtIDPAVLSM2Time");
		var SM2Distance = getValueIfCheckboxSelected("#txtIDPAVLSM2Distance");
		var SM3Time = getValueIfCheckboxSelected("#txtIDPAVLSM3Time");
		var SM3Distance = getValueIfCheckboxSelected("#txtIDPAVLSM3Distance");
		var SM4Time = getValueIfCheckboxSelected("#txtIDPAVLSM4Time");
		var SM4Distance = getValueIfCheckboxSelected("#txtIDPAVLSM4Distance");
		var Odometer = getValueIfCheckboxSelected("#txtIDPServiceMeterOdometer");

		var properties = {
			SM0Time: SM0Time,
			SM0Distance: SM0Distance,
			SM1Time: SM1Time,
			SM1Distance: SM1Distance,
			SM2Time: SM2Time,
			SM2Distance: SM2Distance,
			SM3Time: SM3Time,
			SM3Distance: SM3Distance,
			SM4Time: SM4Time,
			SM4Distance: SM4Distance,
			Odometer: Odometer,
		};

		var data = { properties: properties };
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendSetServiceMeterRequest",
			data,
			this,
			status,
			strings.MSG_IDP_SET_SERVICE_METERS_SUCCESS,
			strings.MSG_IDP_SET_SERVICE_METERS_ERROR,
			null,
			null,
			null,
			strings.IDP_SET_SERVICE_METERS,
			strings.IDP_SET_SERVICE_METERS
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPGetServiceMeters", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendGetServiceMeterRequest",
			data,
			this,
			status,
			strings.MSG_IDP_GET_SERVICE_METERS_SUCCESS,
			strings.MSG_IDP_GET_SERVICE_METERS_ERROR,
			null,
			null,
			null,
			strings.IDP_GET_SERVICE_METERS,
			strings.IDP_GET_SERVICE_METERS
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPResetTerminal", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.idpReset.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var satelliteOnly = false;
		var resetType = $j("#IDPResetTerminalType").val();
		if (resetType <= 3) {
			satelliteOnly = true;
		}

		var status = document.getElementById("idp-query-status");
		var btn = this;

		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				assetIds: assetIds,
				resetType: resetType,
				groupIds: groupIds,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
			};

			$.when(
				handleAjaxFormSubmission(
					"IDPSendResetTerminalRequest",
					data,
					btn,
					status,
					strings.MSG_RESET_TERMINAL_SUCCESS,
					strings.MSG_RESET_TERMINAL_ERROR
				)
			).done(function () {
				$(domNodes.modals.messageAction).modal("hide");
			});
		};
		openActionDialog(strings.RESET_TERMINAL, strings.RESET_TERMINAL, callback, null, null, satelliteOnly);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPGarminInfo", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendGarminInfoRequest",
			data,
			this,
			status,
			strings.MSG_GARMIN_INFO_SUCCESS,
			strings.MSG_GARMIN_INFO_ERROR,
			null,
			null,
			null,
			strings.IDP_GET_GARMIN_INFO,
			strings.IDP_GET_GARMIN_INFO
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPServiceList", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendServiceListRequest",
			data,
			this,
			status,
			strings.MSG_SERVICE_LIST_SUCCESS,
			strings.MSG_SERVICE_LIST_ERROR,
			null,
			null,
			null,
			strings.REQUEST_SERVICE_LIST,
			strings.REQUEST_SERVICE_LIST
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPGetGeofenceStatus", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendGetGeofenceStatusRequest",
			data,
			this,
			status,
			strings.MSG_GEOFENCE_STATUS_SUCCESS,
			strings.MSG_GEOFENCE_STATUS_ERROR,
			null,
			null,
			null,
			strings.REQUEST_GEOFENCE_STATUS,
			strings.REQUEST_GEOFENCE_STATUS
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPGetUpdaterStatus", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPUpdaterGetState",
			data,
			this,
			status,
			strings.MSG_UPDATER_STATE_SUCCESS,
			strings.MSG_UPDATER_STATE_ERROR,
			null,
			null,
			null,
			strings.REQUEST_UPDATER_STATE,
			strings.REQUEST_UPDATER_STATE
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPGetUpdaterPackageVersion", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPUpdaterGetTerminalInfo",
			data,
			this,
			status,
			strings.MSG_UPDATER_TERMINAL_INFO_SUCCESS,
			strings.MSG_UPDATER_TERMINAL_INFO_ERROR,
			null,
			null,
			null,
			strings.REQUEST_UPDATER_TERMINAL_INFO,
			strings.REQUEST_UPDATER_TERMINAL_INFO
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPCryptoRekey", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendCryptoRekeyRequest",
			data,
			this,
			status,
			strings.MSG_CRYPTO_REKEY_SUCCESS,
			strings.MSG_CRYPTO_REKEY_ERROR,
			function () {
				// refresh crypto section
				var assetId = $(domNodes.dialogs.idpSendCommand).data("assetId");
				requestIDPCryptoInformation(assetId);
			},
			null,
			null,
			strings.REKEY_ASSET,
			strings.REKEY_ASSET
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPCryptoEnableRequirement", function (e) {
		e.preventDefault();

		var assetId = $(domNodes.dialogs.idpSendCommand).data("assetId");
		var data = { assetId: assetId };
		var btn = this;
		var status = document.getElementById("idp-query-status");
		$.when(
			handleAjaxFormSubmission(
				"IDPCryptoEnableRequirement",
				data,
				btn,
				status,
				strings.MSG_CRYPTO_ENABLE_SUCCESS,
				strings.MSG_CRYPTO_ENABLE_ERROR
			)
		).done(function () {
			requestIDPCryptoInformation(assetId);
		});
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPCryptoDisableRequirement", function (e) {
		e.preventDefault();

		// open confirmation dialog
		var assetId = $(domNodes.dialogs.idpSendCommand).data("assetId");
		$(domNodes.modals.idpDisableEncryption).modal("show");
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPSetSleepSchedule", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.idpSleepSchedule.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var wakeup = $j("#IDPSleepScheduleWakeup").val();
		var status = document.getElementById("idp-query-status");
		var btn = this;
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				assetIds: assetIds,
				wakeup: wakeup,
				groupIds: groupIds,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
			};

			$.when(
				handleAjaxFormSubmission(
					"IDPSendSetSleepScheduleRequest",
					data,
					btn,
					status,
					strings.MSG_SET_SLEEP_SCHEDULE_SUCCESS,
					strings.MSG_SET_SLEEP_SCHEDULE_ERROR
				)
			).done(function () {
				$(domNodes.modals.messageAction).modal("hide");
			});
		};

		openActionDialog(strings.SET_SLEEP_SCHEDULE, strings.SET_SLEEP_SCHEDULE, callback, null, null, true);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPSetTxMute", function (e) {
		e.preventDefault();
		var isFormValid = $(trkData.validation.idpModemRegistration.currentForm).valid();
		if (!isFormValid) return;

		var mute = $j("#IDPTxMute").val();
		var status = document.getElementById("idp-query-status");
		var btn = this;
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				assetIds: assetIds,
				mute: mute,
				groupIds: groupIds,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
			};

			$.when(
				handleAjaxFormSubmission(
					"IDPSendSetTxMuteRequest",
					data,
					btn,
					status,
					strings.MSG_SET_TX_MUTE_SUCCESS,
					strings.MSG_SET_TX_MUTE_ERROR
				)
			).done(function () {
				$(domNodes.modals.messageAction).modal("hide");
			});
		};

		openActionDialog(strings.SET_TX_MUTE, strings.SET_TX_MUTE, callback, null, null, true);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#IDPGSMHealthCheck", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendGSMHealthCheckRequest",
			data,
			this,
			status,
			strings.MSG_GSM_CHECK_SUCCESS,
			strings.MSG_GSM_CHECK_ERROR,
			null,
			null,
			null,
			strings.REQUEST_GSM_HEALTH_CHECK,
			strings.REQUEST_GSM_HEALTH_CHECK
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPEyeAlertImage", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.idpEyeAlertImageRequest.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var imageType = $j("#IDPEyeAlertImageType").val();
		var data = { imageType: imageType };
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendEyeAlertImageRequest",
			data,
			this,
			status,
			strings.MSG_IDP_REQUEST_IMAGE_SUCCESS,
			strings.MSG_IDP_REQUEST_IMAGE_ERROR,
			null,
			null,
			null,
			strings.REQUEST_IMAGE,
			strings.REQUEST_IMAGE
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPARCRequestHeartbeat", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendARCHeartbeatPoll",
			data,
			this,
			status,
			strings.MSG_IDP_REQUEST_HEARTBEAT_SUCCESS,
			strings.MSG_IDP_REQUEST_HEARTBEAT_ERROR,
			null,
			null,
			null,
			strings.REQUEST_HEARTBEAT,
			strings.REQUEST_HEARTBEAT
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#SynchronizeAssetGarmin", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"SynchronizeAssetGarmin",
			data,
			this,
			status,
			strings.MSG_IDP_SYNCHRONIZE_SUCCESS,
			strings.MSG_IDP_SYNCHRONIZE_ERROR,
			null,
			null,
			null,
			strings.SYNCHRONIZE_GARMIN,
			strings.SYNCHRONIZE_GARMIN
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#SynchronizeAssetGeofences", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"SynchronizeAssetGeofences",
			data,
			this,
			status,
			strings.MSG_IDP_SYNCHRONIZE_SUCCESS,
			strings.MSG_IDP_SYNCHRONIZE_ERROR,
			null,
			null,
			null,
			strings.SYNCHRONIZE_GEOFENCES,
			strings.SYNCHRONIZE_GEOFENCES
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#ResetGatewayCounters", function (e) {
		e.preventDefault();

		var assetId = $(domNodes.dialogs.idpSendCommand).data("assetId");
		resetIDPCounters(assetId);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPAddPIN", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.idpParameters.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var sin = $("#txtIDPSIN").val();
		var pin = $("#txtIDPPIN").val();
		var val = $("#txtIDPValue").val();
		var type = $("#ddlIDPType").val();

		if (sin == "" || pin == "" || val == "" || type == "") {
			return;
		}

		// add parameters to table, hidden fields
		var hidden = el("input", { type: "hidden" }); // used?
		hidden.parameter = { SIN: sin, PIN: pin, Value: val, Type: type };
		$(hidden).data("parameter", hidden.parameter);

		var body = document.getElementById("IDPParameters").querySelector("tbody");
		var lastRow = _.last(body.querySelectorAll("tr"));
		var row = el("tr", [
			el("td", sin),
			el("td", pin),
			el("td", val + " (" + type + ")"),
			el("td", [
				el(
					"button.remove.btn.btn-outline-danger",
					{ title: strings.DELETE },
					svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#trash-alt-light" } }))
				),
				hidden,
			]),
		]);
		mount(body, row, lastRow);

		$("#txtIDPPIN").val("");
		$("#txtIDPValue").val("");
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#QueryAVLGeofenceProperties", function (e) {
		e.preventDefault();

		var btn = this;
		var status = document.getElementById("idp-query-status");
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
				groupIds: groupIds,
				assetIds: assetIds,
			};

			$.when(
				handleAjaxFormSubmission(
					"IDPSendGetGeofencePropertiesRequest",
					data,
					btn,
					status,
					strings.MSG_GET_IDP_PARAMETERS_SUCCESS,
					strings.MSG_GET_IDP_PARAMETERS_ERROR
				)
			).done(function () {
				requestAVLInformation(id);
				$(domNodes.modals.messageAction).modal("hide");
			});
		};

		openActionDialog(strings.GET_GEOFENCE_PARAMETERS, strings.GET_GEOFENCE_PARAMETERS, callback);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#QueryIDPImmobilizerProperties", function (e) {
		e.preventDefault();

		var btn = this;
		var status = document.getElementById("idp-query-status");
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				assetIds: assetIds,
				groupIds: groupIds,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
			};

			$.when(
				handleAjaxFormSubmission(
					"IDPSendGetImmobilizerPropertiesRequest",
					data,
					btn,
					status,
					strings.MSG_GET_IMMOBILIZER_PARAMETERS_SUCCESS,
					strings.MSG_GET_IMMOBILIZER_PARAMETERS_ERROR
				)
			).done(function () {
				requestAVLInformation(id);
				$(domNodes.modals.messageAction).modal("hide");
			});
		};
		openActionDialog(strings.GET_IMMOBILIZER_PARAMETERS, strings.GET_IMMOBILIZER_PARAMETERS, callback);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#QueryIDPGarminProperties", function (e) {
		e.preventDefault();

		var btn = this;
		var status = document.getElementById("idp-query-status");
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				assetIds: assetIds,
				groupIds: groupIds,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
			};

			$.when(
				handleAjaxFormSubmission(
					"IDPSendGetGarminPropertiesRequest",
					data,
					btn,
					status,
					strings.MSG_GET_GARMIN_PARAMETERS_SUCCESS,
					strings.MSG_GET_GARMIN_PARAMETERS_ERROR
				)
			).done(function () {
				requestAVLInformation(id);
				$(domNodes.modals.messageAction).modal("hide");
			});
		};
		openActionDialog(strings.BUTTON_IDP_GET_GARMIN_PARAMETERS, strings.BUTTON_IDP_GET_GARMIN_PARAMETERS, callback);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#QueryIDPARCProperties", function (e) {
		e.preventDefault();

		var btn = this;
		var status = document.getElementById("idp-query-status");
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				assetIds: assetIds,
				groupIds: groupIds,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
			};

			$.when(
				handleAjaxFormSubmission(
					"IDPSendGetARCPropertiesRequest",
					data,
					btn,
					status,
					strings.MSG_GET_ARC_PROPERTIES_SUCCESS,
					strings.MSG_GET_ARC_PROPERTIES_ERROR
				)
			).done(function () {
				requestAVLInformation(id);
				$(domNodes.modals.messageAction).modal("hide");
			});
		};
		openActionDialog(strings.BUTTON_IDP_GET_ARC_PROPERTIES, strings.BUTTON_IDP_GET_ARC_PROPERTIES, callback);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#QueryIDPUtilityConfigurations", function (e) {
		e.preventDefault();

		var btn = this;
		var status = document.getElementById("idp-query-status");
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				assetIds: assetIds,
				groupIds: groupIds,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
			};

			$.when(
				handleAjaxFormSubmission(
					"IDPSendUtilityGetConfigurationsRequest",
					data,
					btn,
					status,
					strings.MSG_GET_UTILITY_CONFIGURATIONS_SUCCESS,
					strings.MSG_GET_UTILITY_CONFIGURATIONS_ERROR
				)
			).done(function () {
				requestAVLInformation(id);
				$(domNodes.modals.messageAction).modal("hide");
			});
		};
		openActionDialog(
			strings.BUTTON_IDP_GET_UTILITY_CONFIGURATIONS,
			strings.BUTTON_IDP_GET_UTILITY_CONFIGURATIONS,
			callback
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#QueryAVLProperties,#QueryAVLIOProperties", function (e) {
		e.preventDefault();

		var btn = this;
		var status = document.getElementById("idp-query-status");
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
				groupIds: groupIds,
				assetIds: assetIds,
			};

			$.when(
				handleAjaxFormSubmission(
					"IDPSendGetAVLPropertiesRequest",
					data,
					btn,
					status,
					strings.MSG_GET_IDP_PARAMETERS_SUCCESS,
					strings.MSG_GET_IDP_PARAMETERS_ERROR
				)
			).done(function () {
				requestAVLInformation(id);
				$(domNodes.modals.messageAction).modal("hide");
			});
		};

		openActionDialog(strings.BUTTON_AVL_GET_PARAMETERS, strings.BUTTON_AVL_GET_PARAMETERS, callback);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPSetIDPCoreParameters", function (e) {
		e.preventDefault();
		var isFormValid = $(trkData.validation.idpCoreParameters.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var SystemExecutionWatchdogTimeout = getValueIfCheckboxSelected("#txtIDPSystemExecutionWatchdogTimeout");
		var SystemAutoGCMemThreshold = getValueIfCheckboxSelected("#txtIDPSystemAutoGCMemThreshold");
		var SystemLedControl = getValueIfCheckboxSelected("#ddlIDPSystemLedControl");
		var PowerExtPowerPresentStateDetect = getValueIfCheckboxSelected("#ddlIDPPowerExtPowerPresentStateDetect");
		var MessageRetry = getValueIfCheckboxSelected("input[name=rbIDPMessageRetry]:checked");
		var MessageTransport1 = getValueIfCheckboxSelected("#txtIDPMessageTransport1");
		var MessageTransport2 = getValueIfCheckboxSelected("#txtIDPMessageTransport2");
		var MessageTimeout1 = getValueIfCheckboxSelected("#txtIDPMessageTimeout1");
		var MessageTimeout2 = getValueIfCheckboxSelected("#txtIDPMessageTimeout2");
		var MessageRetrvInterval = getValueIfCheckboxSelected("#txtIDPMessageRetrvInterval");
		var MessageRetrvMultiplier = getValueIfCheckboxSelected("#txtIDPMessageRetrvMultiplier");
		var PositionContinuous = getValueIfCheckboxSelected("#txtIDPPositionContinuous");
		var PositionMode = getValueIfCheckboxSelected("#ddlIDPPositionMode");
		var LogDataLogEnabled = getValueIfCheckboxSelected("input[name=rbIDPLogDataLogEnabled]:checked");
		var LogMaxDataLogSize = getValueIfCheckboxSelected("#txtIDPLogMaxDataLogSize");
		var LogMaxDataLogFiles = getValueIfCheckboxSelected("#txtIDPLogMaxDataLogFiles");
		var LogDebugLogEnabled = getValueIfCheckboxSelected("input[name=rbIDPLogDebugLogEnabled]:checked");
		var LogMaxDebugLogSize = getValueIfCheckboxSelected("#txtIDPLogMaxDebugLogSize");

		// EIO
		var EIOPort1Config = getValueIfCheckboxSelected("#ddlIDPEIOPort1Config");
		var EIOPort1AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEIOPort1AlarmMsg]:checked");
		var EIOPort1AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEIOPort1AlarmLog]:checked");
		var EIOPort1EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEIOPort1EdgeDetect");
		var EIOPort1EdgeSampleCount = getValueIfCheckboxSelected("#txtIDPEIOPort1EdgeDetect");
		var EIOPort1EdgeSampleError = getValueIfCheckboxSelected("#txtIDPEIOPort1EdgeSampleError");
		var EIOPort1AnalogSampleRate = getValueIfCheckboxSelected("#txtIDPEIOPort1AnalogSampleRate");
		var EIOPort1AnalogSampleFilter = getValueIfCheckboxSelected("#txtIDPEIOPort1AnalogSampleFilter");
		var EIOPort1AnalogLowThreshold = getValueIfCheckboxSelected("#txtIDPEIOPort1AnalogLowThreshold");
		var EIOPort1AnalogHighThreshold = getValueIfCheckboxSelected("#txtIDPEIOPort1AnalogHighThreshold");
		var EIOPort2Config = getValueIfCheckboxSelected("#ddlIDPEIOPort2Config");
		var EIOPort2AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEIOPort2AlarmMsg]:checked");
		var EIOPort2AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEIOPort2AlarmLog]:checked");
		var EIOPort2EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEIOPort2EdgeDetect");
		var EIOPort2EdgeSampleCount = getValueIfCheckboxSelected("#txtIDPEIOPort2EdgeDetect");
		var EIOPort2EdgeSampleError = getValueIfCheckboxSelected("#txtIDPEIOPort2EdgeSampleError");
		var EIOPort2AnalogSampleRate = getValueIfCheckboxSelected("#txtIDPEIOPort2AnalogSampleRate");
		var EIOPort2AnalogSampleFilter = getValueIfCheckboxSelected("#txtIDPEIOPort2AnalogSampleFilter");
		var EIOPort2AnalogLowThreshold = getValueIfCheckboxSelected("#txtIDPEIOPort2AnalogLowThreshold");
		var EIOPort2AnalogHighThreshold = getValueIfCheckboxSelected("#txtIDPEIOPort2AnalogHighThreshold");
		var EIOPort3Config = getValueIfCheckboxSelected("#ddlIDPEIOPort3Config");
		var EIOPort3AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEIOPort3AlarmMsg]:checked");
		var EIOPort3AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEIOPort3AlarmLog]:checked");
		var EIOPort3EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEIOPort3EdgeDetect");
		var EIOPort3EdgeSampleCount = getValueIfCheckboxSelected("#txtIDPEIOPort3EdgeDetect");
		var EIOPort3EdgeSampleError = getValueIfCheckboxSelected("#txtIDPEIOPort3EdgeSampleError");
		var EIOPort3AnalogSampleRate = getValueIfCheckboxSelected("#txtIDPEIOPort3AnalogSampleRate");
		var EIOPort3AnalogSampleFilter = getValueIfCheckboxSelected("#txtIDPEIOPort3AnalogSampleFilter");
		var EIOPort3AnalogLowThreshold = getValueIfCheckboxSelected("#txtIDPEIOPort3AnalogLowThreshold");
		var EIOPort3AnalogHighThreshold = getValueIfCheckboxSelected("#txtIDPEIOPort3AnalogHighThreshold");
		var EIOPort4Config = getValueIfCheckboxSelected("#ddlIDPEIOPort4Config");
		var EIOPort4AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEIOPort4AlarmMsg]:checked");
		var EIOPort4AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEIOPort4AlarmLog]:checked");
		var EIOPort4EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEIOPort4EdgeDetect");
		var EIOPort4EdgeSampleCount = getValueIfCheckboxSelected("#txtIDPEIOPort4EdgeDetect");
		var EIOPort4EdgeSampleError = getValueIfCheckboxSelected("#txtIDPEIOPort4EdgeSampleError");
		var EIOPort4AnalogSampleRate = getValueIfCheckboxSelected("#txtIDPEIOPort4AnalogSampleRate");
		var EIOPort4AnalogSampleFilter = getValueIfCheckboxSelected("#txtIDPEIOPort4AnalogSampleFilter");
		var EIOPort4AnalogLowThreshold = getValueIfCheckboxSelected("#txtIDPEIOPort4AnalogLowThreshold");
		var EIOPort4AnalogHighThreshold = getValueIfCheckboxSelected("#txtIDPEIOPort4AnalogHighThreshold");
		var EIOTemperatureAlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEIOTemperatureAlarmMsg]:checked");
		var EIOTemperatureAlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEIOTemperatureAlarmLog]:checked");
		var EIOTemperatureSampleRate = getValueIfCheckboxSelected("#txtIDPEIOTemperatureSampleRate");
		var EIOTemperatureSampleFilter = getValueIfCheckboxSelected("#txtIDPEIOTemperatureSampleFilter");
		var EIOTemperatureLowThreshold = getValueIfCheckboxSelected("#txtIDPEIOTemperatureLowThreshold");
		var EIOTemperatureHighThreshold = getValueIfCheckboxSelected("#txtIDPEIOTemperatureHighThreshold");
		var EIOPowerAlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEIOPowerAlarmMsg]:checked");
		var EIOPowerAlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEIOPowerAlarmLog]:checked");
		var EIOPowerSampleRate = getValueIfCheckboxSelected("#txtIDPEIOPowerSampleRate");
		var EIOPowerSampleFilter = getValueIfCheckboxSelected("#txtIDPEIOPowerSampleFilter");
		var EIOPowerLowThreshold = getValueIfCheckboxSelected("#txtIDPEIOPowerLowThreshold");
		var EIOPowerHighThreshold = getValueIfCheckboxSelected("#txtIDPEIOPowerHighThreshold");
		var EIOOutputSink5Default = getValueIfCheckboxSelected("#ddlIDPEIOOutputSink5Default");
		var EIOOutputSink6Default = getValueIfCheckboxSelected("#ddlIDPEIOOutputSink6Default");

		// GPRS
		var GPRSSIM1PIN = getValueIfCheckboxSelected("#txtIDPGPRSSIM1PIN");
		var GPRSSIM1APN = getValueIfCheckboxSelected("#txtIDPGPRSSIM1APN");
		var GPRSSIM1Username = getValueIfCheckboxSelected("#txtIDPGPRSSIM1Username");
		var GPRSSIM1Password = getValueIfCheckboxSelected("#txtIDPGPRSSIM1Password");
		var GPRSSIM1DNS1 = getValueIfCheckboxSelected("#txtIDPGPRSSIM1DNS1");
		var GPRSSIM1DNS2 = getValueIfCheckboxSelected("#txtIDPGPRSSIM1DNS2");
		var GPRSSIM2PIN = getValueIfCheckboxSelected("#txtIDPGPRSSIM2PIN");
		var GPRSSIM2APN = getValueIfCheckboxSelected("#txtIDPGPRSSIM2APN");
		var GPRSSIM2Username = getValueIfCheckboxSelected("#txtIDPGPRSSIM2Username");
		var GPRSSIM2Password = getValueIfCheckboxSelected("#txtIDPGPRSSIM2Password");
		var GPRSSIM2DNS1 = getValueIfCheckboxSelected("#txtIDPGPRSSIM2DNS1");
		var GPRSSIM2DNS2 = getValueIfCheckboxSelected("#txtIDPGPRSSIM2DNS2");
		var GPRSServer1 = getValueIfCheckboxSelected("#txtIDPGPRSServer1");
		var GPRSPort1 = getValueIfCheckboxSelected("#txtIDPGPRSPort1");
		var GPRSPollingInterval = getValueIfCheckboxSelected("#txtIDPGPRSPollingInterval");
		var GPRSActiveSIM = getValueIfCheckboxSelected("#ddlIDPGPRSActiveSIM");
		var GPRSTargetMode = getValueIfCheckboxSelected("#ddlIDPGPRSTargetMode");

		// EEIO
		var EEIOInput1Config = getValueIfCheckboxSelected("#ddlIDPEEIOInput1Config");
		var EEIOInput1EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEEIOInput1EdgeDetect");
		var EEIOInput1EdgeFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInput1EdgeFilterCount");
		var EEIOInput1AnalogSampleRate = getValueIfCheckboxSelected("#txtIDPEEIOInput1AnalogSampleRate");
		var EEIOInput1AnalogFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInput1AnalogFilterCount");
		var EEIOInput1AnalogLowThreshold = getValueIfCheckboxSelected("#txtIDPEEIOInput1AnalogLowThreshold");
		var EEIOInput1AnalogHighThreshold = getValueIfCheckboxSelected("#txtIDPEEIOInput1AnalogHighThreshold");
		var EEIOInput1AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOInput1AlarmMsg]:checked");
		var EEIOInput1AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOInput1AlarmLog]:checked");
		var EEIOInput2Config = getValueIfCheckboxSelected("#ddlIDPEEIOInput2Config");
		var EEIOInput2EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEEIOInput2EdgeDetect");
		var EEIOInput2EdgeFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInput2EdgeFilterCount");
		var EEIOInput2AnalogSampleRate = getValueIfCheckboxSelected("#txtIDPEEIOInput2AnalogSampleRate");
		var EEIOInput2AnalogFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInput2AnalogFilterCount");
		var EEIOInput2AnalogLowThreshold = getValueIfCheckboxSelected("#txtIDPEEIOInput2AnalogLowThreshold");
		var EEIOInput2AnalogHighThreshold = getValueIfCheckboxSelected("#txtIDPEEIOInput2AnalogHighThreshold");
		var EEIOInput2AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOInput2AlarmMsg]:checked");
		var EEIOInput2AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOInput2AlarmLog]:checked");
		var EEIOInput3Config = getValueIfCheckboxSelected("#ddlIDPEEIOInput3Config");
		var EEIOInput3EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEEIOInput3EdgeDetect");
		var EEIOInput3EdgeFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInput3EdgeFilterCount");
		var EEIOInput3AnalogSampleRate = getValueIfCheckboxSelected("#txtIDPEEIOInput3AnalogSampleRate");
		var EEIOInput3AnalogFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInput3AnalogFilterCount");
		var EEIOInput3AnalogLowThreshold = getValueIfCheckboxSelected("#txtIDPEEIOInput3AnalogLowThreshold");
		var EEIOInput3AnalogHighThreshold = getValueIfCheckboxSelected("#txtIDPEEIOInput3AnalogHighThreshold");
		var EEIOInput3AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOInput3AlarmMsg]:checked");
		var EEIOInput3AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOInput3AlarmLog]:checked");
		var EEIOInput4Config = getValueIfCheckboxSelected("#ddlIDPEEIOInput4Config");
		var EEIOInput4EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEEIOInput4EdgeDetect");
		var EEIOInput4EdgeFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInput4EdgeFilterCount");
		var EEIOInput4AnalogSampleRate = getValueIfCheckboxSelected("#txtIDPEEIOInput4AnalogSampleRate");
		var EEIOInput4AnalogFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInput4AnalogFilterCount");
		var EEIOInput4AnalogLowThreshold = getValueIfCheckboxSelected("#txtIDPEEIOInput4AnalogLowThreshold");
		var EEIOInput4AnalogHighThreshold = getValueIfCheckboxSelected("#txtIDPEEIOInput4AnalogHighThreshold");
		var EEIOInput4AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOInput4AlarmMsg]:checked");
		var EEIOInput4AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOInput4AlarmLog]:checked");
		var EEIOInputDigital5EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEEIOInputDigital5EdgeDetect");
		var EEIOInputDigital5EdgeFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInputDigital5EdgeFilterCount");
		var EEIOInputDigital5AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital5AlarmMsg]:checked");
		var EEIOInputDigital5AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital5AlarmLog]:checked");
		var EEIOInputDigital6EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEEIOInputDigital6EdgeDetect");
		var EEIOInputDigital6EdgeFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInputDigital6EdgeFilterCount");
		var EEIOInputDigital6AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital6AlarmMsg]:checked");
		var EEIOInputDigital6AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital6AlarmLog]:checked");
		var EEIOInputDigital7EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEEIOInputDigital7EdgeDetect");
		var EEIOInputDigital7EdgeFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInputDigital7EdgeFilterCount");
		var EEIOInputDigital7AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital7AlarmMsg]:checked");
		var EEIOInputDigital7AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital7AlarmLog]:checked");
		var EEIOInputDigital8EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEEIOInputDigital8EdgeDetect");
		var EEIOInputDigital8EdgeFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInputDigital8EdgeFilterCount");
		var EEIOInputDigital8AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital8AlarmMsg]:checked");
		var EEIOInputDigital8AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital8AlarmLog]:checked");
		var EEIOInputDigital9EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEEIOInputDigital9EdgeDetect");
		var EEIOInputDigital9EdgeFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInputDigital9EdgeFilterCount");
		var EEIOInputDigital9AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital9AlarmMsg]:checked");
		var EEIOInputDigital9AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital9AlarmLog]:checked");
		var EEIOInputDigital10EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEEIOInputDigital10EdgeDetect");
		var EEIOInputDigital10EdgeFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInputDigital10EdgeFilterCount");
		var EEIOInputDigital10AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital10AlarmMsg]:checked");
		var EEIOInputDigital10AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital10AlarmLog]:checked");
		var EEIOInputDigital11EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEEIOInputDigital11EdgeDetect");
		var EEIOInputDigital11EdgeFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInputDigital11EdgeFilterCount");
		var EEIOInputDigital11AlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital11AlarmMsg]:checked");
		var EEIOInputDigital11AlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOInputDigital11AlarmLog]:checked");
		var EEIOInputIgnition12EdgeDetect = getValueIfCheckboxSelected("#ddlIDPEEIOInputIgnition12EdgeDetect");
		var EEIOInputIgnition12EdgeFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOInputIgnition12EdgeFilterCount");
		var EEIOInputIgnition12AlarmMsg = getValueIfCheckboxSelected(
			"input[name=rbIDPEEIOInputIgnition12AlarmMsg]:checked"
		);
		var EEIOInputIgnition12AlarmLog = getValueIfCheckboxSelected(
			"input[name=rbIDPEEIOInputIgnition12AlarmLog]:checked"
		);
		var EEIOutputSink14Default = getValueIfCheckboxSelected("#ddlIDPEEIOutputSink14Default");
		var EEIOutputSink15Default = getValueIfCheckboxSelected("#ddlIDPEEIOutputSink15Default");
		var EEIOutputSink16Default = getValueIfCheckboxSelected("#ddlIDPEEIOutputSink16Default");
		var EEIOutputSink17Default = getValueIfCheckboxSelected("#ddlIDPEEIOutputSink17Default");
		var EEIOutputSink18Default = getValueIfCheckboxSelected("#ddlIDPEEIOutputSink18Default");
		var EEIOTemperatureSampleRate = getValueIfCheckboxSelected("#txtIDPEEIOTemperatureSampleRate");
		var EEIOTemperatureFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOTemperatureFilterCount");
		var EEIOTemperatureLowThreshold = getValueIfCheckboxSelected("#txtIDPEEIOTemperatureLowThreshold");
		var EEIOTemperatureHighThreshold = getValueIfCheckboxSelected("#txtIDPEEIOTemperatureHighThreshold");
		var EEIOTemperatureAlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOTemperatureAlarmMsg]:checked");
		var EEIOTemperatureAlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOTemperatureAlarmLog]:checked");
		var EEIOExternalPowerSampleRate = getValueIfCheckboxSelected("#txtIDPEEIOExternalPowerSampleRate");
		var EEIOExternalPowerFilterCount = getValueIfCheckboxSelected("#txtIDPEEIOExternalPowerFilterCount");
		var EEIOExternalPowerLowThreshold = getValueIfCheckboxSelected("#txtIDPEEIOExternalPowerLowThreshold");
		var EEIOExternalPowerHighThreshold = getValueIfCheckboxSelected("#txtIDPEEIOExternalPowerHighThreshold");
		var EEIOExternalPowerAlarmMsg = getValueIfCheckboxSelected("input[name=rbIDPEEIOExternalPowerAlarmMsg]:checked");
		var EEIOExternalPowerAlarmLog = getValueIfCheckboxSelected("input[name=rbIDPEEIOExternalPowerAlarmLog]:checked");

		// Accel
		var AccelSleepInterval = getValueIfCheckboxSelected("#txtIDPAccelSleepInterval");
		var AccelGRange = getValueIfCheckboxSelected("#txtIDPAccelGRange");
		var AccelMotionEventThreshold = getValueIfCheckboxSelected("#txtIDPAccelMotionEventThreshold");
		var AccelMotionEventXAxisEn = getValueIfCheckboxSelected("input[name=AccelMotionEventXAxisEn]:checked");
		var AccelMotionEventYAxisEn = getValueIfCheckboxSelected("input[name=AccelMotionEventYAxisEn]:checked");
		var AccelMotionEventZAxisEn = getValueIfCheckboxSelected("input[name=AccelMotionEventZAxisEn]:checked");

		// Report
		var Report1Enabled = getValueIfCheckboxSelected("input[name=rbIDPReport1Enabled]:checked");
		var Report1MIN = getValueIfCheckboxSelected("#ddlIDPReport1ReportType");
		var Report1Interval = getValueIfCheckboxSelected("#txtIDPReport1TimeInterval");

		var properties = {
			SystemExecutionWatchdogTimeout: SystemExecutionWatchdogTimeout,
			SystemAutoGCMemThreshold: SystemAutoGCMemThreshold,
			SystemLedControl: SystemLedControl,
			PowerExtPowerPresentStateDetect: PowerExtPowerPresentStateDetect,
			MessageRetry: MessageRetry,
			MessageTransport1: MessageTransport1,
			MessageTransport2: MessageTransport2,
			MessageTimeout1: MessageTimeout1,
			MessageTimeout2: MessageTimeout2,
			MessageRetrvInterval: MessageRetrvInterval,
			MessageRetrvMultiplier: MessageRetrvMultiplier,
			PositionContinuous: PositionContinuous,
			PositionMode: PositionMode,
			LogDataLogEnabled: LogDataLogEnabled,
			LogMaxDataLogSize: LogMaxDataLogSize,
			LogMaxDataLogFiles: LogMaxDataLogFiles,
			LogDebugLogEnabled: LogDebugLogEnabled,
			LogMaxDebugLogSize: LogMaxDebugLogSize,
			EIOPort1Config: EIOPort1Config,
			EIOPort1AlarmMsg: EIOPort1AlarmMsg,
			EIOPort1AlarmLog: EIOPort1AlarmLog,
			EIOPort1EdgeDetect: EIOPort1EdgeDetect,
			EIOPort1EdgeSampleCount: EIOPort1EdgeSampleCount,
			EIOPort1EdgeSampleError: EIOPort1EdgeSampleError,
			EIOPort1AnalogSampleRate: EIOPort1AnalogSampleRate,
			EIOPort1AnalogSampleFilter: EIOPort1AnalogSampleFilter,
			EIOPort1AnalogLowThreshold: EIOPort1AnalogLowThreshold,
			EIOPort1AnalogHighThreshold: EIOPort1AnalogHighThreshold,
			EIOPort2Config: EIOPort2Config,
			EIOPort2AlarmMsg: EIOPort2AlarmMsg,
			EIOPort2AlarmLog: EIOPort2AlarmLog,
			EIOPort2EdgeDetect: EIOPort2EdgeDetect,
			EIOPort2EdgeSampleCount: EIOPort2EdgeSampleCount,
			EIOPort2EdgeSampleError: EIOPort2EdgeSampleError,
			EIOPort2AnalogSampleRate: EIOPort2AnalogSampleRate,
			EIOPort2AnalogSampleFilter: EIOPort2AnalogSampleFilter,
			EIOPort2AnalogLowThreshold: EIOPort2AnalogLowThreshold,
			EIOPort2AnalogHighThreshold: EIOPort2AnalogHighThreshold,
			EIOPort3Config: EIOPort3Config,
			EIOPort3AlarmMsg: EIOPort3AlarmMsg,
			EIOPort3AlarmLog: EIOPort3AlarmLog,
			EIOPort3EdgeDetect: EIOPort3EdgeDetect,
			EIOPort3EdgeSampleCount: EIOPort3EdgeSampleCount,
			EIOPort3EdgeSampleError: EIOPort3EdgeSampleError,
			EIOPort3AnalogSampleRate: EIOPort3AnalogSampleRate,
			EIOPort3AnalogSampleFilter: EIOPort3AnalogSampleFilter,
			EIOPort3AnalogLowThreshold: EIOPort3AnalogLowThreshold,
			EIOPort3AnalogHighThreshold: EIOPort3AnalogHighThreshold,
			EIOPort4Config: EIOPort4Config,
			EIOPort4AlarmMsg: EIOPort4AlarmMsg,
			EIOPort4AlarmLog: EIOPort4AlarmLog,
			EIOPort4EdgeDetect: EIOPort4EdgeDetect,
			EIOPort4EdgeSampleCount: EIOPort4EdgeSampleCount,
			EIOPort4EdgeSampleError: EIOPort4EdgeSampleError,
			EIOPort4AnalogSampleRate: EIOPort4AnalogSampleRate,
			EIOPort4AnalogSampleFilter: EIOPort4AnalogSampleFilter,
			EIOPort4AnalogLowThreshold: EIOPort4AnalogLowThreshold,
			EIOPort4AnalogHighThreshold: EIOPort4AnalogHighThreshold,
			EIOTemperatureAlarmMsg: EIOTemperatureAlarmMsg,
			EIOTemperatureAlarmLog: EIOTemperatureAlarmLog,
			EIOTemperatureSampleRate: EIOTemperatureSampleRate,
			EIOTemperatureSampleFilter: EIOTemperatureSampleFilter,
			EIOTemperatureLowThreshold: EIOTemperatureLowThreshold,
			EIOTemperatureHighThreshold: EIOTemperatureHighThreshold,
			EIOPowerAlarmMsg: EIOPowerAlarmMsg,
			EIOPowerAlarmLog: EIOPowerAlarmLog,
			EIOPowerSampleRate: EIOPowerSampleRate,
			EIOPowerSampleFilter: EIOPowerSampleFilter,
			EIOPowerLowThreshold: EIOPowerLowThreshold,
			EIOPowerHighThreshold: EIOPowerHighThreshold,
			EIOOutputSink5Default: EIOOutputSink5Default,
			EIOOutputSink6Default: EIOOutputSink6Default,
			GPRSSIM1PIN: GPRSSIM1PIN,
			GPRSSIM1APN: GPRSSIM1APN,
			GPRSSIM1Username: GPRSSIM1Username,
			GPRSSIM1Password: GPRSSIM1Password,
			GPRSSIM1DNS1: GPRSSIM1DNS1,
			GPRSSIM1DNS2: GPRSSIM1DNS2,
			GPRSSIM2PIN: GPRSSIM2PIN,
			GPRSSIM2APN: GPRSSIM2APN,
			GPRSSIM2Username: GPRSSIM2Username,
			GPRSSIM2Password: GPRSSIM2Password,
			GPRSSIM2DNS1: GPRSSIM2DNS1,
			GPRSSIM2DNS2: GPRSSIM2DNS2,
			GPRSActiveSIM: GPRSActiveSIM,
			GPRSTargetMode: GPRSTargetMode,
			GPRSServer1: GPRSServer1,
			GPRSPort1: GPRSPort1,
			GPRSPollingInterval: GPRSPollingInterval,
			EEIOInput1Config: EEIOInput1Config,
			EEIOInput1EdgeDetect: EEIOInput1EdgeDetect,
			EEIOInput1EdgeFilterCount: EEIOInput1EdgeFilterCount,
			EEIOInput1AnalogSampleRate: EEIOInput1AnalogSampleRate,
			EEIOInput1AnalogFilterCount: EEIOInput1AnalogFilterCount,
			EEIOInput1AnalogLowThreshold: EEIOInput1AnalogLowThreshold,
			EEIOInput1AnalogHighThreshold: EEIOInput1AnalogHighThreshold,
			EEIOInput1AlarmMsg: EEIOInput1AlarmMsg,
			EEIOInput1AlarmLog: EEIOInput1AlarmLog,
			EEIOInput2Config: EEIOInput2Config,
			EEIOInput2EdgeDetect: EEIOInput2EdgeDetect,
			EEIOInput2EdgeFilterCount: EEIOInput2EdgeFilterCount,
			EEIOInput2AnalogSampleRate: EEIOInput2AnalogSampleRate,
			EEIOInput2AnalogFilterCount: EEIOInput2AnalogFilterCount,
			EEIOInput2AnalogLowThreshold: EEIOInput2AnalogLowThreshold,
			EEIOInput2AnalogHighThreshold: EEIOInput2AnalogHighThreshold,
			EEIOInput2AlarmMsg: EEIOInput2AlarmMsg,
			EEIOInput2AlarmLog: EEIOInput2AlarmLog,
			EEIOInput3Config: EEIOInput3Config,
			EEIOInput3EdgeDetect: EEIOInput3EdgeDetect,
			EEIOInput3EdgeFilterCount: EEIOInput3EdgeFilterCount,
			EEIOInput3AnalogSampleRate: EEIOInput3AnalogSampleRate,
			EEIOInput3AnalogFilterCount: EEIOInput3AnalogFilterCount,
			EEIOInput3AnalogLowThreshold: EEIOInput3AnalogLowThreshold,
			EEIOInput3AnalogHighThreshold: EEIOInput3AnalogHighThreshold,
			EEIOInput3AlarmMsg: EEIOInput3AlarmMsg,
			EEIOInput3AlarmLog: EEIOInput3AlarmLog,
			EEIOInput4Config: EEIOInput4Config,
			EEIOInput4EdgeDetect: EEIOInput4EdgeDetect,
			EEIOInput4EdgeFilterCount: EEIOInput4EdgeFilterCount,
			EEIOInput4AnalogSampleRate: EEIOInput4AnalogSampleRate,
			EEIOInput4AnalogFilterCount: EEIOInput4AnalogFilterCount,
			EEIOInput4AnalogLowThreshold: EEIOInput4AnalogLowThreshold,
			EEIOInput4AnalogHighThreshold: EEIOInput4AnalogHighThreshold,
			EEIOInput4AlarmMsg: EEIOInput4AlarmMsg,
			EEIOInput4AlarmLog: EEIOInput4AlarmLog,
			EEIOInputDigital5EdgeDetect: EEIOInputDigital5EdgeDetect,
			EEIOInputDigital5EdgeFilterCount: EEIOInputDigital5EdgeFilterCount,
			EEIOInputDigital5AlarmMsg: EEIOInputDigital5AlarmMsg,
			EEIOInputDigital5AlarmLog: EEIOInputDigital5AlarmLog,
			EEIOInputDigital6EdgeDetect: EEIOInputDigital6EdgeDetect,
			EEIOInputDigital6EdgeFilterCount: EEIOInputDigital6EdgeFilterCount,
			EEIOInputDigital6AlarmMsg: EEIOInputDigital6AlarmMsg,
			EEIOInputDigital6AlarmLog: EEIOInputDigital6AlarmLog,
			EEIOInputDigital7EdgeDetect: EEIOInputDigital7EdgeDetect,
			EEIOInputDigital7EdgeFilterCount: EEIOInputDigital7EdgeFilterCount,
			EEIOInputDigital7AlarmMsg: EEIOInputDigital7AlarmMsg,
			EEIOInputDigital7AlarmLog: EEIOInputDigital7AlarmLog,
			EEIOInputDigital8EdgeDetect: EEIOInputDigital8EdgeDetect,
			EEIOInputDigital8EdgeFilterCount: EEIOInputDigital8EdgeFilterCount,
			EEIOInputDigital8AlarmMsg: EEIOInputDigital8AlarmMsg,
			EEIOInputDigital8AlarmLog: EEIOInputDigital8AlarmLog,
			EEIOInputDigital9EdgeDetect: EEIOInputDigital9EdgeDetect,
			EEIOInputDigital9EdgeFilterCount: EEIOInputDigital9EdgeFilterCount,
			EEIOInputDigital9AlarmMsg: EEIOInputDigital9AlarmMsg,
			EEIOInputDigital9AlarmLog: EEIOInputDigital9AlarmLog,
			EEIOInputDigital10EdgeDetect: EEIOInputDigital10EdgeDetect,
			EEIOInputDigital10EdgeFilterCount: EEIOInputDigital10EdgeFilterCount,
			EEIOInputDigital10AlarmMsg: EEIOInputDigital10AlarmMsg,
			EEIOInputDigital10AlarmLog: EEIOInputDigital10AlarmLog,
			EEIOInputDigital11EdgeDetect: EEIOInputDigital11EdgeDetect,
			EEIOInputDigital11EdgeFilterCount: EEIOInputDigital11EdgeFilterCount,
			EEIOInputDigital11AlarmMsg: EEIOInputDigital11AlarmMsg,
			EEIOInputDigital11AlarmLog: EEIOInputDigital11AlarmLog,
			EEIOInputIgnition12EdgeDetect: EEIOInputIgnition12EdgeDetect,
			EEIOInputIgnition12EdgeFilterCount: EEIOInputIgnition12EdgeFilterCount,
			EEIOInputIgnition12AlarmMsg: EEIOInputIgnition12AlarmMsg,
			EEIOInputIgnition12AlarmLog: EEIOInputIgnition12AlarmLog,
			EEIOutputSink14Default: EEIOutputSink14Default,
			EEIOutputSink15Default: EEIOutputSink15Default,
			EEIOutputSink16Default: EEIOutputSink16Default,
			EEIOutputSink17Default: EEIOutputSink17Default,
			EEIOutputSink18Default: EEIOutputSink18Default,
			EEIOTemperatureSampleRate: EEIOTemperatureSampleRate,
			EEIOTemperatureFilterCount: EEIOTemperatureFilterCount,
			EEIOTemperatureLowThreshold: EEIOTemperatureLowThreshold,
			EEIOTemperatureHighThreshold: EEIOTemperatureHighThreshold,
			EEIOTemperatureAlarmMsg: EEIOTemperatureAlarmMsg,
			EEIOTemperatureAlarmLog: EEIOTemperatureAlarmLog,
			EEIOExternalPowerSampleRate: EEIOExternalPowerSampleRate,
			EEIOExternalPowerFilterCount: EEIOExternalPowerFilterCount,
			EEIOExternalPowerLowThreshold: EEIOExternalPowerLowThreshold,
			EEIOExternalPowerHighThreshold: EEIOExternalPowerHighThreshold,
			EEIOExternalPowerAlarmMsg: EEIOExternalPowerAlarmMsg,
			EEIOExternalPowerAlarmLog: EEIOExternalPowerAlarmLog,
			AccelSleepInterval: AccelSleepInterval,
			AccelGRange: AccelGRange,
			AccelMotionEventThreshold: AccelMotionEventThreshold,
			AccelMotionEventXAxisEn: AccelMotionEventXAxisEn,
			AccelMotionEventYAxisEn: AccelMotionEventYAxisEn,
			AccelMotionEventZAxisEn: AccelMotionEventZAxisEn,
			Report1Enabled: Report1Enabled,
			Report1MIN: Report1MIN,
			Report1Interval: Report1Interval,
		};

		var data = { properties: properties };
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendSetCorePropertiesRequest",
			data,
			this,
			status,
			strings.MSG_IDP_PARAMETERS_SUCCESS,
			strings.MSG_IDP_PARAMETERS_ERROR,
			null,
			null,
			null,
			strings.SET_CORE_PARAMETERS,
			strings.SET_CORE_PARAMETERS
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPSetAVLGeofenceParameters", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.idpAvlGeofence.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var Hysteresis = $("#txtIDPAVLGeofenceHysteresis").val();
		var Interval = $("#txtIDPAVLGeofenceInterval").val();
		var Enabled = $("input[name=rbIDPAVLGeofenceEnabled]:checked").val();
		var SendAlarm = $("input[name=rbIDPAVLGeofenceSendAlarm]:checked").val();
		var data = {
			Enabled: Enabled,
			Interval: Interval,
			Hysteresis: Hysteresis,
			SendAlarm: SendAlarm,
		};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendSetGeofencePropertiesRequest",
			data,
			this,
			status,
			strings.MSG_IDP_PARAMETERS_SUCCESS,
			strings.MSG_IDP_PARAMETERS_ERROR,
			null,
			null,
			null,
			strings.SET_GEOFENCE_PARAMETERS,
			strings.SET_GEOFENCE_PARAMETERS
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPARCSetProperties", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.idpArc.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var eioPorts = [];
		if (
			getValueIfCheckboxSelected("#ddlIDPARCEIO1EventType") != null ||
			getValueIfCheckboxSelected("#txtIDPARCEIO1TriggerDebounce") != null ||
			getValueIfCheckboxSelected("#txtIDPARCEIO1AnalogThresholds") != null
		) {
			var eio1Port = {
				Port: 1,
				EventType: getValueIfCheckboxSelected("#ddlIDPARCEIO1EventType"),
				TriggerDebounce: getValueIfCheckboxSelected("#txtIDPARCEIO1TriggerDebounce"),
				AnalogThresholds: getValueIfCheckboxSelected("#txtIDPARCEIO1AnalogThresholds"),
			};
			eioPorts.push(eio1Port);
		}
		if (
			getValueIfCheckboxSelected("#ddlIDPARCEIO2EventType") != null ||
			getValueIfCheckboxSelected("#txtIDPARCEIO2TriggerDebounce") != null ||
			getValueIfCheckboxSelected("#txtIDPARCEIO2AnalogThresholds") != null
		) {
			var eio2Port = {
				Port: 2,
				EventType: getValueIfCheckboxSelected("#ddlIDPARCEIO2EventType"),
				TriggerDebounce: getValueIfCheckboxSelected("#txtIDPARCEIO2TriggerDebounce"),
				AnalogThresholds: getValueIfCheckboxSelected("#txtIDPARCEIO2AnalogThresholds"),
			};
			eioPorts.push(eio2Port);
		}
		if (
			getValueIfCheckboxSelected("#ddlIDPARCEIO3EventType") != null ||
			getValueIfCheckboxSelected("#txtIDPARCEIO3TriggerDebounce") != null ||
			getValueIfCheckboxSelected("#txtIDPARCEIO3AnalogThresholds") != null
		) {
			var eio3Port = {
				Port: 3,
				EventType: getValueIfCheckboxSelected("#ddlIDPARCEIO3EventType"),
				TriggerDebounce: getValueIfCheckboxSelected("#txtIDPARCEIO3TriggerDebounce"),
				AnalogThresholds: getValueIfCheckboxSelected("#txtIDPARCEIO3AnalogThresholds"),
			};
			eioPorts.push(eio3Port);
		}
		if (
			getValueIfCheckboxSelected("#ddlIDPARCEIO4EventType") != null ||
			getValueIfCheckboxSelected("#txtIDPARCEIO4TriggerDebounce") != null ||
			getValueIfCheckboxSelected("#txtIDPARCEIO4AnalogThresholds") != null
		) {
			var eio4Port = {
				Port: 4,
				EventType: getValueIfCheckboxSelected("#ddlIDPARCEIO4EventType"),
				TriggerDebounce: getValueIfCheckboxSelected("#txtIDPARCEIO4TriggerDebounce"),
				AnalogThresholds: getValueIfCheckboxSelected("#txtIDPARCEIO4AnalogThresholds"),
			};
			eioPorts.push(eio4Port);
		}

		var properties = {
			EIOPorts: eioPorts,
			HeartbeatInterval: getValueIfCheckboxSelected("#txtIDPARCHeartbeatInterval"),
			IsSatQualityReported: getValueIfCheckboxSelected("input[name=rbIDPARCIsSatQualityReported]:checked"),
			ModemPowerMode: getValueIfCheckboxSelected("#ddlIDPARCModemPowerMode"),
			ModemWakeupInterval: getValueIfCheckboxSelected("#ddlIDPARCModemWakeUpInterval"),
			FlashLedOnTransmit: getValueIfCheckboxSelected("input[name=rbIDPARCFlashLedOnTransmit]:checked"),
			EnablePositionReporting: getValueIfCheckboxSelected("input[name=rbIDPARCEnablePositionReporting]:checked"),
			MotionTestInterval: getValueIfCheckboxSelected("#txtIDPARCMotionTestInterval"),
			DistanceThreshold: getValueIfCheckboxSelected("#txtIDPARCDistanceThreshold"),
			ContractHour: getValueIfCheckboxSelected("#txtIDPARCContractHour"),
			LedInstallFlashTime: getValueIfCheckboxSelected("#txtIDPARCLedInstallFlashTime"),
			MaxContractDispersion: getValueIfCheckboxSelected("#txtIDPARCDispersionOffsetSeconds"),
			SendContractDayValues: getValueIfCheckboxSelected("input[name=rbIDPARCSendContractDayValues]:checked"),
			SupplyVoltageThreshold: getValueIfCheckboxSelected("#txtIDPARCSupplyVoltageThreshold"),
			SupplyVoltageTriggerDebounce: getValueIfCheckboxSelected("#txtIDPARCSupplyVoltageTriggerDebounce"),
			IsDebugEnabled: getValueIfCheckboxSelected("input[name=rbIDPARCIsDebugEnabled]:checked"),
		};

		var data = {
			properties: properties,
		};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendSetARCPropertiesRequest",
			data,
			this,
			status,
			strings.MSG_SET_ARC_PROPERTIES_SUCCESS,
			strings.MSG_SET_ARC_PROPERTIES_ERROR,
			null,
			null,
			null,
			strings.BUTTON_IDP_SET_ARC_PROPERTIES,
			strings.BUTTON_IDP_SET_ARC_PROPERTIES
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPImmobilizerSetParameters", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.idpImmobilizer.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var properties = {
			NotifyOnlyWhenMoving: getValueIfCheckboxSelected("input[name=rbIDPImmobilizerNotifyOnlyWhenMoving]:checked"),
			DriverLoginCheckInterval: getValueIfCheckboxSelected("#txtIDPImmobilizerDriverLoginCheckInterval"),
		};
		var data = {
			properties: properties,
		};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendSetImmobilizerPropertiesRequest",
			data,
			this,
			status,
			strings.MSG_SET_IMMOBILIZER_PARAMETERS_SUCCESS,
			strings.MSG_SET_IMMOBILIZER_PARAMETERS_ERROR,
			null,
			null,
			null,
			strings.SET_IMMOBILIZER_PARAMETERS,
			strings.SET_IMMOBILIZER_PARAMETERS
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPGarminSetParameters", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.idpGarmin.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var properties = {
			FmiEnabled: getValueIfCheckboxSelected("input[name=rbIDPGarminFmiEnabled]:checked"),
			SafeModeEnabled: getValueIfCheckboxSelected("input[name=rbIDPGarminSafeModeEnabled]:checked"),
			DispatchIconText: getValueIfCheckboxSelected("#txtIDPGarminDispatchIconText"),
			DeleteGarminData: getValueIfCheckboxSelected("#ddlIDPGarminDeleteGarminData"),
			WelcomeMessage: getValueIfCheckboxSelected("#txtIDPGarminWelcomeMessage"),
			OpenTextMsgAllowed: getValueIfCheckboxSelected("input[name=rbIDPGarminOpenTextMsgAllowed]:checked"),
			TextingRequiresDriverId: getValueIfCheckboxSelected("input[name=rbIDPGarminTextingRequiresDriverId]:checked"),
			MultiDriverEnabled: getValueIfCheckboxSelected("input[name=rbIDPGarminMultiDriverEnabled]:checked"),
			DriverPwdEnabled: getValueIfCheckboxSelected("input[name=rbIDPGarminDriverPwdEnabled]:checked"),
			DriverIdCaseSensitive: getValueIfCheckboxSelected("input[name=rbIDPGarminDriverIdCaseSensitive]:checked"),
			PasswordCaseSensitive: getValueIfCheckboxSelected("input[name=rbIDPGarminPasswordCaseSensitive]:checked"),
			ReportFailedLogins: getValueIfCheckboxSelected("input[name=rbIDPGarminReportFailedLogins]:checked"),
			AutoArrivalTime: getValueIfCheckboxSelected("#txtIDPGarminAutoArrivalTime"),
			AutoArrivalDistance: getValueIfCheckboxSelected("#txtIDPGarminAutoArrivalDistance"),
			SpeedLimitMode: getValueIfCheckboxSelected("#ddlIDPGarminSpeedLimitMode"),
			SpeedLimitThreshold: getValueIfCheckboxSelected("#txtIDPGarminSpeedLimitThreshold"),
			SpeedLimitTimeOver: getValueIfCheckboxSelected("#txtIDPGarminSpeedLimitTimeOver"),
			SpeedLimitTimeUnder: getValueIfCheckboxSelected("#txtIDPGarminSpeedLimitTimeUnder"),
			SpeedLimitAlertUser: getValueIfCheckboxSelected("input[name=rbIDPGarminSpeedLimitAlertUser]:checked"),
			HosEnabled: getValueIfCheckboxSelected("input[name=rbIDPGarminHosEnabled]:checked"),
			SubmitFormAsFile: getValueIfCheckboxSelected("input[name=rbIDPGarminSubmitFormAsFile]:checked"),
		};
		var data = {
			properties: properties,
		};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendSetGarminPropertiesRequest",
			data,
			this,
			status,
			strings.MSG_GET_GARMIN_PARAMETERS_SUCCESS,
			strings.MSG_GET_GARMIN_PARAMETERS_ERROR,
			null,
			null,
			null,
			strings.BUTTON_IDP_SET_GARMIN_PARAMETERS,
			strings.BUTTON_IDP_SET_GARMIN_PARAMETERS
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPSetAVLParameters", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.idpAvlParameters.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		// AVL 1.7 Core properties
		var StationarySpeedThreshold = getValueIfCheckboxSelected("#txtIDPAVLStationarySpeedThld");
		var StationaryDebounceTime = getValueIfCheckboxSelected("#txtIDPAVLStationaryDebounceTime");
		var MovingDistanceThreshold = getValueIfCheckboxSelected("#txtIDPAVLMovingDistanceThld");
		var MovingDebounceTime = getValueIfCheckboxSelected("#txtIDPAVLMovingDebounceTime");
		var DefaultSpeedLimit = getValueIfCheckboxSelected("#txtIDPAVLDefaultSpeedLimit");
		var SpeedingTimeOver = getValueIfCheckboxSelected("#txtIDPAVLSpeedingTimeOver");
		var SpeedingTimeUnder = getValueIfCheckboxSelected("#txtIDPAVLSpeedingTimeUnder");
		var LoggingPositionsInterval = getValueIfCheckboxSelected("#txtIDPAVLLoggingPositionsInterval");
		var StationaryIntervalCell = getValueIfCheckboxSelected("#txtIDPAVLStationaryIntervalCell");
		var MovingIntervalCell = getValueIfCheckboxSelected("#txtIDPAVLMovingIntervalCell");
		var MovingIntervalSat = getValueIfCheckboxSelected("#txtIDPAVLMovingIntervalSat");
		var StationaryIntervalSat = getValueIfCheckboxSelected("#txtIDPAVLStationaryIntervalSat");
		var ShmReportingHour = getValueIfCheckboxSelected("#txtIDPAVLShmReportingHour");
		var OdometerDistanceIncrement = getValueIfCheckboxSelected("#txtIDPAVLOdometerDistanceIncrement");
		var Odometer = getValueIfCheckboxSelected("#txtIDPAVLOdometer");
		var TurnThreshold = getValueIfCheckboxSelected("#txtIDPAVLTurnThreshold");
		var TurnDebounceTime = getValueIfCheckboxSelected("#txtIDPAVLTurnDebounceTime");
		var DistanceCellThreshold = getValueIfCheckboxSelected("#txtIDPAVLDistanceCellThld");
		var DistanceSatThreshold = getValueIfCheckboxSelected("#txtIDPAVLDistanceSatThld");
		var MaxDrivingTime = getValueIfCheckboxSelected("#txtIDPAVLMaxDrivingTime");
		var MinRestTime = getValueIfCheckboxSelected("#txtIDPAVLMinRestTime");
		var AirBlockageTime = getValueIfCheckboxSelected("#txtIDPAVLAirBlockageTime");
		var MaxIdlingTime = getValueIfCheckboxSelected("#txtIDPAVLMaxIdlingTime");
		var IdleTimerAutoRestart = getValueIfCheckboxSelected("input[name=rbIDPAVLIdleTimerAutoRestart]:checked");
		var DefaultGeoDwellTime = getValueIfCheckboxSelected("#txtIDPAVLDefaultGeoDwellTime");
		var GeoDwellTimerAutoRestart = getValueIfCheckboxSelected("input[name=rbIDPAVLGeoDwellTimerAutoRestart]:checked");
		var ImmobilizeOnBlockage = getValueIfCheckboxSelected("#txtIDPAVLImmobilizeOnBlockage");
		var GpsJamDebounceTime = getValueIfCheckboxSelected("#txtIDPAVLGpsJamDebounceTime");
		var CellJamDebounceTime = getValueIfCheckboxSelected("#txtIDPAVLCellJamDebounceTime");
		//var LpmTrigger = getValueIfCheckboxSelected('#txtIDPAVLLpmTrigger');
		if ($j("#chkIDPAVLLpmTrigger").is(":checked")) {
			var LpmTrigger = {
				IgnitionOff: $j("input[name=rbIDPAVLLpmTriggerIgnitionOff]:checked").val(),
				BuiltInBattery: $j("input[name=rbIDPAVLLpmTriggerBuiltInBattery]:checked").val(),
			};
		}

		var LpmEntryDelay = getValueIfCheckboxSelected("#txtIDPAVLLpmEntryDelay");
		var LpmGeoInterval = getValueIfCheckboxSelected("#txtIDPAVLLpmGeoInterval");
		var LpmModemWakeupInterval = getValueIfCheckboxSelected("#ddlIDPAVLLpmModemWakeupInterval");
		var ExitLpmOnTowingMaxTime = getValueIfCheckboxSelected("#txtIDPAVLExitLpmOnTowingMaxTime");
		var TowStartCheckInterval = getValueIfCheckboxSelected("#txtIDPAVLTowStartCheckInterval");
		var TowStartDebCount = getValueIfCheckboxSelected("#txtIDPAVLTowStartDebCount");
		var TowStopCheckInterval = getValueIfCheckboxSelected("#txtIDPAVLTowStopCheckInterval");
		var TowStopDebCount = getValueIfCheckboxSelected("#txtIDPAVLTowStopDebCount");
		var TowInterval = getValueIfCheckboxSelected("#txtIDPAVLTowInterval");
		var PositionMsgInterval = getValueIfCheckboxSelected("#txtIDPAVLPositionMsgInterval");

		// AVL 2.0+ Core Properties

		// AVL 3.0+ Properties
		var HarshBrakingThld = getValueIfCheckboxSelected("#txtIDPAVLHarshBrakingThld");
		var MinHarshBrakingTime = getValueIfCheckboxSelected("#txtIDPAVLMinHarshBrakingTime");
		var ReArmHarshBrakingTime = getValueIfCheckboxSelected("#txtIDPAVLReArmHarshBrakingTime");
		var HarshAccelThld = getValueIfCheckboxSelected("#txtIDPAVLHarshAccelThld");
		var MinHarshAccelTime = getValueIfCheckboxSelected("#txtIDPAVLMinHarshAccelTime");
		var ReArmHarshAccelTime = getValueIfCheckboxSelected("#txtIDPAVLReArmHarshAccelTime");
		var AccidentThld = getValueIfCheckboxSelected("#txtIDPAVLAccidentThld");
		var MinAccidentTime = getValueIfCheckboxSelected("#txtIDPAVLMinAccidentTime");
		var SeatbeltDebounceTime = getValueIfCheckboxSelected("#txtIDPAVLSeatbeltDebounceTime");
		var DriverIdPort = getValueIfCheckboxSelected("#ddlIDPAVLDriverIdPort");
		var DriverIdPollingInterval = getValueIfCheckboxSelected("#txtIDPAVLDriverIdPollingInterval");
		var DriverIdAutoLogoutDelay = getValueIfCheckboxSelected("#txtIDPAVLDriverIdAutoLogoutDelay");
		var AccidentAccelDataCapture = getValueIfCheckboxSelected("input[name=rbIDPAVLAccidentAccelDataCapture]:checked");
		var AccidentGpsDataCapture = getValueIfCheckboxSelected("input[name=rbIDPAVLAccidentGpsDataCapture]:checked");
		var HarshTurnThld = getValueIfCheckboxSelected("#txtIDPAVLHarshTurnThld");
		var MinHarshTurnTime = getValueIfCheckboxSelected("#txtIDPAVLMinHarshTurnTime");
		var ReArmHarshTurnTime = getValueIfCheckboxSelected("#txtIDPAVLReArmHarshTurnTime");
		var avlProperties = {
			StationarySpeedThreshold: StationarySpeedThreshold,
			StationaryDebounceTime: StationaryDebounceTime,
			MovingDistanceThreshold: MovingDistanceThreshold,
			MovingDebounceTime: MovingDebounceTime,
			DefaultSpeedLimit: DefaultSpeedLimit,
			SpeedingTimeOver: SpeedingTimeOver,
			SpeedingTimeUnder: SpeedingTimeUnder,
			LoggingPositionsInterval: LoggingPositionsInterval,
			StationaryIntervalCell: StationaryIntervalCell,
			MovingIntervalCell: MovingIntervalCell,
			MovingIntervalSat: MovingIntervalSat,
			StationaryIntervalSat: StationaryIntervalSat,
			ShmReportingHour: ShmReportingHour,
			OdometerDistanceIncrement: OdometerDistanceIncrement,
			Odometer: Odometer,
			TurnThreshold: TurnThreshold,
			TurnDebounceTime: TurnDebounceTime,
			DistanceCellThreshold: DistanceCellThreshold,
			DistanceSatThreshold: DistanceSatThreshold,
			MaxDrivingTime: MaxDrivingTime,
			MinRestTime: MinRestTime,
			AirBlockageTime: AirBlockageTime,
			MaxIdlingTime: MaxIdlingTime,
			IdleTimerAutoRestart: IdleTimerAutoRestart === undefined ? null : IdleTimerAutoRestart,
			DefaultGeoDwellTime: DefaultGeoDwellTime,
			GeoDwellTimerAutoRestart: GeoDwellTimerAutoRestart === undefined ? null : GeoDwellTimerAutoRestart,
			ImmobilizeOnBlockage: ImmobilizeOnBlockage,
			GpsJamDebounceTime: GpsJamDebounceTime,
			CellJamDebounceTime: CellJamDebounceTime,
			LpmTrigger: LpmTrigger,
			LpmEntryDelay: LpmEntryDelay,
			LpmGeoInterval: LpmGeoInterval,
			LpmModemWakeupInterval: LpmModemWakeupInterval,
			ExitLpmOnTowingMaxTime: ExitLpmOnTowingMaxTime,
			TowStartCheckInterval: TowStartCheckInterval,
			TowStartDebCount: TowStartDebCount,
			TowStopCheckInterval: TowStopCheckInterval,
			TowStopDebCount: TowStopDebCount,
			TowInterval: TowInterval,
			DigStatesDefBitmap: null,
		};

		var avl2Properties = jQuery.extend({}, avlProperties);
		avl2Properties.SmReportingHour = avlProperties.ShmReportingHour;
		if ($j("#chkIDPAVLOptionalFieldsInMsgs").is(":checked")) {
			var OptionalFieldsDef = {
				Odometer: $j("input[name=rbIDPAVLOptionalFieldsInMsgsOdometer]:checked").val(),
				Sensor1: $j("input[name=rbIDPAVLOptionalFieldsInMsgsSensor1]:checked").val(),
				Sensor2: $j("input[name=rbIDPAVLOptionalFieldsInMsgsSensor2]:checked").val(),
				Sensor3: $j("input[name=rbIDPAVLOptionalFieldsInMsgsSensor3]:checked").val(),
				Sensor4: $j("input[name=rbIDPAVLOptionalFieldsInMsgsSensor4]:checked").val(),
				DriverId: $j("input[name=rbIDPAVLOptionalFieldsInMsgsDriverId]:checked").val(),
				DigitalPorts: $j("input[name=rbIDPAVLOptionalFieldsInMsgsDigitalPorts]:checked").val(),
				AvlStates: $j("input[name=rbIDPAVLOptionalFieldsInMsgsAvlStates]:checked").val(),
			};
		}
		avl2Properties.OptionalFieldsDef = OptionalFieldsDef;
		avl2Properties.PositionMsgInterval = PositionMsgInterval;
		delete avl2Properties.ShmReportingHour;
		delete avl2Properties.Odometer;
		delete avl2Properties.GeoDwellTimerAutoRestart;
		delete avl2Properties.IdleTimerAutoRestart;
		delete avl2Properties.MovingDistanceThreshold;
		delete avl2Properties.ImmobilizeOnBlockage;
		delete avl2Properties.ExitLpmOnTowingMaxTime;

		var avl3Properties = jQuery.extend({}, avl2Properties);
		avl3Properties.HarshBrakingThld = HarshBrakingThld;
		avl3Properties.MinHarshBrakingTime = MinHarshBrakingTime;
		avl3Properties.ReArmHarshBrakingTime = ReArmHarshBrakingTime;
		avl3Properties.HarshAccelThld = HarshAccelThld;
		avl3Properties.MinHarshAccelTime = MinHarshAccelTime;
		avl3Properties.ReArmHarshAccelTime = ReArmHarshAccelTime;
		avl3Properties.AccidentThld = AccidentThld;
		avl3Properties.MinAccidentTime = MinAccidentTime;
		avl3Properties.SeatbeltDebounceTime = SeatbeltDebounceTime;
		avl3Properties.DriverIdPort = DriverIdPort;
		avl3Properties.DriverIdPollingInterval = DriverIdPollingInterval;
		avl3Properties.DriverIdAutoLogoutDelay = DriverIdAutoLogoutDelay;
		avl3Properties.AccidentAccelDataCapture = AccidentAccelDataCapture;
		avl3Properties.AccidentGpsDataCapture = AccidentGpsDataCapture;
		avl3Properties.HarshTurnThld = HarshTurnThld;
		avl3Properties.MinHarshTurnTime = MinHarshTurnTime;
		avl3Properties.ReArmHarshTurnTime = ReArmHarshTurnTime;

		var data = {
			avlProperties: avlProperties,
			avl2Properties: avl2Properties,
			avl3Properties: avl3Properties,
		};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendSetAVLPropertiesRequest",
			data,
			this,
			status,
			strings.MSG_IDP_PARAMETERS_SUCCESS,
			strings.MSG_IDP_PARAMETERS_ERROR,
			null,
			null,
			null,
			strings.SET_AVL_PARAMETERS,
			strings.SET_AVL_PARAMETERS
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPSetAVLIOParameters", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.idpIoParameters.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		// I/O Parameters
		if ($j("#chkIDPAVLDigStatesDefBitmap").is(":checked")) {
			var DigStatesDef = {
				IgnitionOn: $j("input[name=rbIDPAVLDigStatesDefIgnitionOn]:checked").val(),
				SeatbeltBuckled: $j("input[name=rbIDPAVLDigStatesDefSeatbeltOff]:checked").val(),
				SM1Active: $j("input[name=rbIDPAVLDigStatesDefSM1Active]:checked").val(),
				SM2Active: $j("input[name=rbIDPAVLDigStatesDefSM2Active]:checked").val(),
				SM3Active: $j("input[name=rbIDPAVLDigStatesDefSM3Active]:checked").val(),
				SM4Active: $j("input[name=rbIDPAVLDigStatesDefSM4Active]:checked").val(),
			};
		}
		var FuncDigOut1 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigOut1");
		var FuncDigOut2 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigOut2");
		var FuncDigOut3 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigOut3");
		var FuncDigOut4 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigOut4");
		var FuncDigOut5 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigOut5");
		var FuncDigOut6 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigOut6");

		var FuncDigInp1 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigInp1");
		var FuncDigInp2 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigInp2");
		var FuncDigInp3 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigInp3");
		var FuncDigInp4 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigInp4");
		var FuncDigInp5 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigInp5");
		var FuncDigInp6 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigInp6");
		var FuncDigInp7 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigInp7");
		var FuncDigInp8 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigInp8");
		var FuncDigInp9 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigInp9");
		var FuncDigInp10 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigInp10");
		var FuncDigInp11 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigInp11");
		var FuncDigInp12 = getValueIfCheckboxSelected("#ddlIDPAVLFuncDigInp12");
		var SensorReportingInterval = getValueIfCheckboxSelected("#txtIDPAVLSensorReportingInterval");
		var Sensor1SourceSIN = getValueIfCheckboxSelected("#txtIDPAVLSensor1SourceSIN");
		var Sensor1SourcePIN = getValueIfCheckboxSelected("#txtIDPAVLSensor1SourcePIN");
		var Sensor1NormalSampleInterval = getValueIfCheckboxSelected("#txtIDPAVLSensor1NormalSampleInterval");
		var Sensor1LpmSampleInterval = getValueIfCheckboxSelected("#txtIDPAVLSensor1LpmSampleInterval");
		var Sensor1MaxReportInterval = getValueIfCheckboxSelected("#txtIDPAVLSensor1MaxReportInterval");
		var Sensor1ChangeThld = getValueIfCheckboxSelected("#txtIDPAVLSensor1ChangeThld");
		var Sensor1MinThld = getValueIfCheckboxSelected("#txtIDPAVLSensor1MinThld");
		var Sensor1MaxThld = getValueIfCheckboxSelected("#txtIDPAVLSensor1MaxThld");
		var Sensor2SourceSIN = getValueIfCheckboxSelected("#txtIDPAVLSensor2SourceSIN");
		var Sensor2SourcePIN = getValueIfCheckboxSelected("#txtIDPAVLSensor2SourcePIN");
		var Sensor2NormalSampleInterval = getValueIfCheckboxSelected("#txtIDPAVLSensor2NormalSampleInterval");
		var Sensor2LpmSampleInterval = getValueIfCheckboxSelected("#txtIDPAVLSensor2LpmSampleInterval");
		var Sensor2MaxReportInterval = getValueIfCheckboxSelected("#txtIDPAVLSensor2MaxReportInterval");
		var Sensor2ChangeThld = getValueIfCheckboxSelected("#txtIDPAVLSensor2ChangeThld");
		var Sensor2MinThld = getValueIfCheckboxSelected("#txtIDPAVLSensor2MinThld");
		var Sensor2MaxThld = getValueIfCheckboxSelected("#txtIDPAVLSensor2MaxThld");
		var Sensor3SourceSIN = getValueIfCheckboxSelected("#txtIDPAVLSensor3SourceSIN");
		var Sensor3SourcePIN = getValueIfCheckboxSelected("#txtIDPAVLSensor3SourcePIN");
		var Sensor3NormalSampleInterval = getValueIfCheckboxSelected("#txtIDPAVLSensor3NormalSampleInterval");
		var Sensor3LpmSampleInterval = getValueIfCheckboxSelected("#txtIDPAVLSensor3LpmSampleInterval");
		var Sensor3MaxReportInterval = getValueIfCheckboxSelected("#txtIDPAVLSensor3MaxReportInterval");
		var Sensor3ChangeThld = getValueIfCheckboxSelected("#txtIDPAVLSensor3ChangeThld");
		var Sensor3MinThld = getValueIfCheckboxSelected("#txtIDPAVLSensor3MinThld");
		var Sensor3MaxThld = getValueIfCheckboxSelected("#txtIDPAVLSensor3MaxThld");
		var Sensor4SourceSIN = getValueIfCheckboxSelected("#txtIDPAVLSensor4SourceSIN");
		var Sensor4SourcePIN = getValueIfCheckboxSelected("#txtIDPAVLSensor4SourcePIN");
		var Sensor4NormalSampleInterval = getValueIfCheckboxSelected("#txtIDPAVLSensor4NormalSampleInterval");
		var Sensor4LpmSampleInterval = getValueIfCheckboxSelected("#txtIDPAVLSensor4LpmSampleInterval");
		var Sensor4MaxReportInterval = getValueIfCheckboxSelected("#txtIDPAVLSensor4MaxReportInterval");
		var Sensor4ChangeThld = getValueIfCheckboxSelected("#txtIDPAVLSensor4ChangeThld");
		var Sensor4MinThld = getValueIfCheckboxSelected("#txtIDPAVLSensor4MinThld");
		var Sensor4MaxThld = getValueIfCheckboxSelected("#txtIDPAVLSensor4MaxThld");

		var ioProperties = {
			DigStatesDef: DigStatesDef,
			FuncDigInp1: FuncDigInp1,
			FuncDigInp2: FuncDigInp2,
			FuncDigInp3: FuncDigInp3,
			FuncDigInp4: FuncDigInp4,
			FuncDigInp5: FuncDigInp5,
			FuncDigInp6: FuncDigInp6,
			FuncDigInp7: FuncDigInp7,
			FuncDigInp8: FuncDigInp8,
			FuncDigInp9: FuncDigInp9,
			FuncDigInp10: FuncDigInp10,
			FuncDigInp11: FuncDigInp11,
			FuncDigInp12: FuncDigInp12,
			FuncDigOut1: FuncDigOut1,
			FuncDigOut2: FuncDigOut2,
			FuncDigOut3: FuncDigOut3,
			FuncDigOut4: FuncDigOut4,
			FuncDigOut5: FuncDigOut5,
			FuncDigOut6: FuncDigOut6,
			SensorReportingInterval: SensorReportingInterval,
			Sensor1SourceSIN: Sensor1SourceSIN,
			Sensor1SourcePIN: Sensor1SourcePIN,
			Sensor1NormalSampleInterval: Sensor1NormalSampleInterval,
			Sensor1LpmSampleInterval: Sensor1LpmSampleInterval,
			Sensor1MaxReportInterval: Sensor1MaxReportInterval,
			Sensor1ChangeThld: Sensor1ChangeThld,
			Sensor1MinThld: Sensor1MinThld,
			Sensor1MaxThld: Sensor1MaxThld,
			Sensor2SourceSIN: Sensor2SourceSIN,
			Sensor2SourcePIN: Sensor2SourcePIN,
			Sensor2NormalSampleInterval: Sensor2NormalSampleInterval,
			Sensor2LpmSampleInterval: Sensor2LpmSampleInterval,
			Sensor2MaxReportInterval: Sensor2MaxReportInterval,
			Sensor2ChangeThld: Sensor2ChangeThld,
			Sensor2MinThld: Sensor2MinThld,
			Sensor2MaxThld: Sensor2MaxThld,
			Sensor3SourceSIN: Sensor3SourceSIN,
			Sensor3SourcePIN: Sensor3SourcePIN,
			Sensor3NormalSampleInterval: Sensor3NormalSampleInterval,
			Sensor3LpmSampleInterval: Sensor3LpmSampleInterval,
			Sensor3MaxReportInterval: Sensor3MaxReportInterval,
			Sensor3ChangeThld: Sensor3ChangeThld,
			Sensor3MinThld: Sensor3MinThld,
			Sensor3MaxThld: Sensor3MaxThld,
			Sensor4SourceSIN: Sensor4SourceSIN,
			Sensor4SourcePIN: Sensor4SourcePIN,
			Sensor4NormalSampleInterval: Sensor4NormalSampleInterval,
			Sensor4LpmSampleInterval: Sensor4LpmSampleInterval,
			Sensor4MaxReportInterval: Sensor4MaxReportInterval,
			Sensor4ChangeThld: Sensor4ChangeThld,
			Sensor4MinThld: Sensor4MinThld,
			Sensor4MaxThld: Sensor4MaxThld,
			DigStatesDefBitmap: null,
		};

		var data = {
			properties: ioProperties,
		};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendSetIOPropertiesRequest",
			data,
			this,
			status,
			strings.MSG_IDP_PARAMETERS_SUCCESS,
			strings.MSG_IDP_PARAMETERS_ERROR,
			null,
			null,
			null,
			strings.SET_IO_PARAMETERS,
			strings.SET_IO_PARAMETERS
		);
	});

	$(domNodes.dialogs.idpSendCommand).on("click", "#btnIDPRequestLocation", function (e) {
		e.preventDefault();

		var data = {};
		var status = document.getElementById("idp-query-status");
		handleAjaxFormSubmissionWithGatewaySelection(
			"IDPSendLocationRequest",
			data,
			this,
			status,
			strings.MSG_LOCATION_REQUEST_SUCCESS,
			strings.MSG_LOCATION_REQUEST_ERROR,
			null,
			null,
			null,
			strings.BUTTON_REQUEST_LOCATION,
			strings.BUTTON_REQUEST_LOCATION
		);
	});
}
