import state from "./state.js";
import { map } from "./map-base.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import { createMarkerPath } from "./marker-path.js";
import { addPointToRuler } from "./ruler.js";
import { addPointToRouting } from "./routing.js";
import { reverseGeocode } from "./geocode.js";

import $ from "jquery";
import L from "leaflet";
import _ from "lodash";

export function startChoosingMapLocation(handler) {
	var handlerIndex = _.indexOf(state.mapClickQueue, handler);
	if (handlerIndex === -1) {
		state.mapClickQueue.push(handler);
		L.DomUtil.addClass(map._container, "crosshair-cursor-enabled");
	}
}

export function stopChoosingMapLocation(handler) {
	var handlerIndex = _.indexOf(state.mapClickQueue, handler);
	if (handlerIndex !== -1) {
		state.mapClickQueue.splice(handlerIndex);
	}
	if (state.mapClickQueue.length === 0) {
		if (state.chosenLocations[handler] !== null && state.chosenLocations[handler] !== undefined) {
			removeItemFromMap(state.chosenLocations[handler]);
		}
		L.DomUtil.removeClass(map._container, "crosshair-cursor-enabled");
	}
}

export function updateChosenLocation(latlng, handler) {
	if (!(latlng instanceof L.LatLng)) {
		latlng = L.latLng(latlng);
	}

	if (state.mapClickQueue.length === 0) {
		return;
	}

	var clickHandler = state.mapClickQueue[state.mapClickQueue.length - 1];
	if (handler === null || handler === undefined) {
		handler = clickHandler;
	}

	// place/move a marker icon at the spot for handlers adding/selecting some kind of marked location
	// we need to have a marker per handler so they don't interfere with each other
	// and the dragend callback needs to be aware of the handler associated with it
	if (
		handler === state.mapClickHandlers.POSITION ||
		handler === state.mapClickHandlers.POSITION_ADD ||
		handler === state.mapClickHandlers.PLACE
	) {
		if (state.chosenLocations[handler] === null || state.chosenLocations[handler] === undefined) {
			var locationIcon = L.icon({
				iconUrl: createMarkerPath("Generic", "red", null, null, null, false),
				iconSize: [36, 36],
				iconAnchor: [18, 18],
			});
			state.chosenLocations[handler] = L.marker(latlng, { icon: locationIcon, draggable: true });
			addItemToMap(state.chosenLocations[handler]);
			state.chosenLocations[handler].on("dragend", function (e) {
				updateChosenLocation(e.target.getLatLng(), handler);
			});
		} else {
			addItemToMap(state.chosenLocations[handler]);
			state.chosenLocations[handler].setLatLng(latlng);
		}
	}

	switch (handler) {
		case state.mapClickHandlers.POSITION:
			$("#SendPositionLatLng").text(latlng.lat.toFixed(6) + ", " + latlng.lng.toFixed(6));
			$("#hfPositionLat").val(latlng.lat);
			$("#hfPositionLng").val(latlng.lng);
			$("#send-position-search").removeClass("is-visible");
			$("#form-send-position-send").addClass("is-visible");
			reverseGeocode(latlng, function (success, address) {
				if (success && address !== "") {
					if ($("#SendPositionName").val() === "") {
						$("#SendPositionName").val(address);
					}
				}
			});
			break;
		case state.mapClickHandlers.POSITION_ADD:
			$("#txtAddPositionLat").val(latlng.lat.toFixed(6));
			$("#txtAddPositionLng").val(latlng.lng.toFixed(6));
			break;
		case state.mapClickHandlers.PLACE:
			$("#add-place-location").text(latlng.lat.toFixed(6) + ", " + latlng.lng.toFixed(6));
			$("#add-place-search").removeClass("is-visible");
			$("#form-add-place").addClass("is-visible");
			$("#hfPlaceLat").val(latlng.lat);
			$("#hfPlaceLng").val(latlng.lng);

			reverseGeocode(latlng, function (success, address) {
				if (success && address !== "") {
					if ($("#txtPlaceName").val() === "") {
						$("#txtPlaceName").val(address);
					}
				}
			});
			break;
		case state.mapClickHandlers.RULER:
			addPointToRuler(latlng);
			break;
		case state.mapClickHandlers.GEOFENCE:
			break;
		case state.mapClickHandlers.ROUTING:
			addPointToRouting(latlng);
			break;
	}
}
