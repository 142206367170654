import trkData from "./data.js";
import domNodes from "./domNodes.js";
import { viewModes, mapModes, trkDataGroups } from "./const.js";
import state from "./state.js";
import { updateActiveAssetInformation } from "./assets-active.js";
import { map } from "./map-base.js";
import { closeSecondaryPanel } from "./panel.js";
import { updateMapModePanel } from "./map-ui.js";
import { deselectSharedView } from "./shared-view.js";
import { moveOrOpenDialogRelativeTo } from "./modal-dialog.js";
import { changeMapType } from "./map-maptype.js";

import $ from "jquery";
import _ from "lodash";

import { normalGleoSymbols, sharedViewGleoSymbols } from "./map-gleo.js";

export async function switchViewMode(toViewMode) {
	if (state.activeViewMode === toViewMode) {
		return;
	}
	console.log("switchViewMode", toViewMode);

	var switchModePromises = [];

	closeSecondaryPanel();

	// change activeViewMode
	hideMapMarkersForViewMode(state.activeViewMode);

	// set attribute on root node which will handle hiding/showing map control elements
	// i.e. normal means <div id=track-root data-view-mode=0> ,
	// shared view means  <div id=track-root data-view-mode=1>
	document.getElementById("track-root").setAttribute("data-view-mode",
		toViewMode === viewModes.NORMAL ? 0 : 1
	);
	state.activeViewMode = toViewMode;
	showMapMarkersForViewMode(state.activeViewMode);

	// TODO disable/enable Live button on map mode...

	if (state.activeViewMode === viewModes.SHARED_VIEW) {
		trkData.sharedView.priorMapMode = state.activeMapMode;
		trkData.sharedView.priorMapType = state.currentMapType;
		trkData.sharedView.priorIsSatelliteLabelOverlayEnabled = trkData.isSatelliteLabelOverlayEnabled;
		var $dialog = $(domNodes.infoDialogs.sharedViewInformation);
		moveOrOpenDialogRelativeTo($dialog, map._container, "center center");
		$dialog.off("dialogclose");
	} else {
		document.getElementById("txtDateFrom").value = trkData.history.fromDate;
		document.getElementById("txtDateTo").value = trkData.history.toDate;
		if (trkData.sharedView.priorMapType !== state.currentMapType) {
			trkData.isSatelliteLabelOverlayEnabled = trkData.sharedView.priorIsSatelliteLabelOverlayEnabled;
			changeMapType(trkData.sharedView.priorMapType);
		}
	}

	updateMapModePanel();
	updateActiveAssetInformation(state.activeViewMode);
	return Promise.all(switchModePromises);
}

function hideMapMarkersForViewMode(viewMode) {
	if (viewMode === viewModes.NORMAL) {
		map.removeLayer(trkData.mapLayers.normal);
		map.platina.remove(normalGleoSymbols);
		//normalGleoSymbols.remove();

		$(domNodes.infoDialogs.mapItemInformation).dialog("close");
		$(domNodes.infoDialogs.positionHistory).dialog("close");
	} else {
		deselectSharedView();
		map.removeLayer(trkData.mapLayers.sharedView);
		map.platina.remove(sharedViewGleoSymbols);
		//sharedViewGleoSymbols.remove();

		$(domNodes.infoDialogs.sharedViewInformation).dialog("close");
		$(domNodes.infoDialogs.mapItemInformation).dialog("close");
		$(domNodes.infoDialogs.positionHistory).dialog("close");
	}
}

function showMapMarkersForViewMode(viewMode) {
	if (viewMode === viewModes.NORMAL) {
		map.addLayer(trkData.mapLayers.normal);
		normalGleoSymbols.addTo(map.platina);
	} else {
		map.addLayer(trkData.mapLayers.sharedView);
		sharedViewGleoSymbols.addTo(map.platina);
	}
}

export function getAssetDataGroupForViewAndMapModes(viewMode, mapMode) {
	if (viewMode === viewModes.NORMAL) {
		if (mapMode === mapModes.LIVE) {
			return trkDataGroups.NORMAL_LIVE;
		} else if (mapMode === mapModes.HISTORY) {
			return trkDataGroups.NORMAL_HISTORY;
		} else {
			// time slider doesn't have a asset data group
			return undefined;
		}
	} else if (viewMode === viewModes.SHARED_VIEW) {
		return trkDataGroups.SHARED_VIEW_HISTORY;
	}
}

export function getAssetDataGroupForCurrentViewMode() {
	// TODO store this in state instead whenever it would change?
	return getAssetDataGroupForViewAndMapModes(state.activeViewMode, state.activeMapMode);
}

export function getJourneyDataGroupForCurrentViewMode() {
	if (state.activeViewMode === viewModes.NORMAL) {
		return trkDataGroups.JOURNEY_HISTORY;
	} else {
		return trkDataGroups.SHARED_VIEW_JOURNEY;
	}
}
