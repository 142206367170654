import GleoSymbol from "../symbols/Symbol.mjs";
import AbstractSymbolGroup from "./AbstractSymbolGroup.mjs";
import Loader from "./Loader.mjs";

/**
 * @class SymbolGroup
 * @inherits AbstractSymbolGroup
 *
 * Akin to Leaflet's `LayerGroup`. Groups symbols together so that they can be
 * added to/removed  at once by adding/removing the symbol group. Symbols can be
 * added to/removed from the group as well.
 *
 * In addition to symbols, accepts nested `Loader`s.
 *
 * For grouping symbols relating to the same geographical feature, use `MultiSymbol`
 * instead.
 */

export default class SymbolGroup extends AbstractSymbolGroup {
	addTo(target) {
		super.addTo(target);
		this.target.multiAdd(this.symbols);
		return this;
	}

	// _addToPlatina(p) {
	// 	super._addToPlatina(p);
	// 	p.multiAdd(this.symbols);
	// }

	_addSymbols(symbols) {
		super._addSymbols(symbols);
		this.fire("symbolsadded", { symbols });
	}

	_removeSymbols(symbols) {
		super._removeSymbols(symbols);
		this.fire("symbolsremoved", { symbols });
	}

	empty() {
		this.fire("symbolsremoved", { symbols: this.symbols });

		return super.empty();
	}

	remove(s) {
		if (!s && this.target) {
			this.target.multiRemove(this.symbols);
		}
		return super.remove(s);
	}
}
