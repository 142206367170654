import strings from "./strings.js";

export function getStatusTextForLocation(location) {
	var status = "";
	if (location.State.IsSpeeding === true) {
		status = strings.SPEEDING;
	} else if (location.State.IsTowing === true) {
		status = strings.TOWING;
	} else if (location.State.IsMoving === true) {
		status = strings.MOVING;
	} else if (
		location.State.IsMoving === false ||
		(location.State.IsMoving == null && location.State.IsTowing == false)
	) {
		status = strings.STATIONARY;
	}

	var ignition = "";
	if (location.State.IsIdling === true) {
		ignition = strings.IDLING;
	} else if (location.State.IsIgnitionOn === true) {
		ignition = strings.IGNITION_ON;
	} else if (location.State.IsIgnitionOn === false) {
		ignition = strings.IGNITION_OFF;
	}

	if (status !== "" && ignition !== "") {
		status += ", " + ignition;
	}

	if (location.State.IsInLowPowerMode === true) {
		if (status !== "") {
			status += ", ";
		}
		status += strings.IN_LOW_POWER_MODE;
	}

	if (location.State.IsAntennaCut === true) {
		if (status !== "") {
			status += ", ";
		}
		status += strings.ANTENNA_CUT;
	}

	if (location.State.IsCellJammed === true) {
		if (status !== "") {
			status += ", ";
		}
		status += strings.CELL_JAMMED;
	}

	if (location.State.IsGpsJammed === true) {
		if (status !== "") {
			status += ", ";
		}
		status += strings.GPS_JAMMED;
	}

	if (location.State.IsOnBackupPower === true) {
		if (status !== "") {
			status += ", ";
		}
		status += strings.ON_BACKUP_POWER;
	}

	if (location.State.IsImmobilized === true) {
		if (status !== "") {
			status += ", ";
		}
		status += strings.IMMOBILIZED;
	}
	return status;
}
