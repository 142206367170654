// Route-finding functionality

import domNodes from "./domNodes.js";
import trkData from "./data.js";
import { closeSecondaryPanel } from "./panel.js";
import { directionsService } from "./map-base.js";
import { toggleLoadingMessage } from "./ajax.js";
import { reverseGeocode, addressSearch } from "./geocode.js";
import state from "./state.js";
import options from "./options.js";
import { mapModes } from "./const.js";
import { startChoosingMapLocation, stopChoosingMapLocation } from "./map-chooselocation.js";
import { handleWebServiceError } from "./ajax.js";
import strings from "./strings.js";
import user from "./user.js";
import { map } from "./map-base.js";
import {
	convertToLatLngPreference,
	convertSpeedToPreference,
	convertFromMetresToUserDistancePreference,
} from "./preferences.js";
import { throttles } from "./timers.js";
import { wrapUrl } from "./wrapurl.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import { openDialogPanel } from "./panel-nav.js";
import { editFenceSegments } from "./fence-edit.js";
import { findPlaceById } from "./place.js";
import { openGeofenceDialog } from "./fence.js";
import { closePlaceRouting } from "./place-routing.js";

import $ from "jquery";
import $j from "jquery";
import L from "leaflet";
import _ from "lodash";
import { el, svg, text, setChildren } from "redom";

//// Also possible: import L_Routing from "leaflet-routing-machine"
//import "leaflet-routing-machine/src/index.js";
import "../legacy/leaflet-routing-machine-all.js"; // Customized LRM

import { GeodesicLine } from "leaflet.geodesic/dist/leaflet.geodesic.esm.js";

export function initRouting() {
	// Must be called once the DOM nodes for the routing buttons have been created

	$("#map-functions").on("click", "#routing-start", function (e) {
		e.preventDefault();
		openRouting();
	});

	$("#routing-destinations").on("keyup", ".routing-destination input", _.debounce(routingAddressEntry, 500));
	$(domNodes.dialogs.routing).on("click", "#RoutingAddDestination", function (e) {
		e.preventDefault();

		routingAddDestinationOption();
		routingCheckForPendingDestination();
	});
	$(domNodes.dialogs.routing).on("click", "#RoutingCalculateRoute", function (e) {
		e.preventDefault();

		routingCalculateRoute();
	});
	$(domNodes.dialogs.routing).on("click", "#RoutingNewRoute", function (e) {
		e.preventDefault();
		closeSecondaryPanel();
		openRouting();
	});

	$(domNodes.dialogs.routing).on("click", "button.routing-map", function (e) {
		e.preventDefault();

		// enable clicking the map to add a location
		if (trkData.routing.isUsingMap) {
			this.classList.remove("active");
			trkData.routing.isUsingMap = false;
			stopChoosingMapLocation(state.mapClickHandlers.ROUTING);
			$j("button.routing-map").prop("disabled", false);
			trkData.routing.mapClickDestination = "waypoint";
		} else {
			trkData.routing.isUsingMap = true;
			startChoosingMapLocation(state.mapClickHandlers.ROUTING);
			trkData.routing.mapClickDestination = $(this.getAttribute("data-target"));
			$("button.routing-map").not(this).prop("disabled", true).removeClass("active");
			this.classList.add("active");
		}
	});
	$(domNodes.dialogs.routing).on("click", "svg.remove", function (e) {
		e.preventDefault();
		var destinationList = document.getElementById("routing-destinations");
		destinationList.removeChild(this.parentNode);
		routingCheckForPendingDestination();
	});
	$(domNodes.dialogs.routing).on("click", "#RoutingCancel", function (e) {
		e.preventDefault();
		closeSecondaryPanel();
	});
	//$(domNodes.dialogs.routing).

	$(domNodes.dialogs.routing).on("click", "#RoutingAddGeofence", function (e) {
		if (!user.canEditGeofences || options.enabledFeatures.indexOf("UI_GEOFENCE_FROM_ROUTE") === -1) {
			return;
		}
		$(domNodes.modals.geofenceBuffer).modal("show");
		state.isPreviewingBuffer = true;
		var buffer = $j("#RouteGeofenceBuffer").slider("value");
		$("#RouteGeofenceBufferValue").text(buffer);
		throttles.bufferGeofence(buffer);
	});
	$("#routing-destinations").sortable({
		axis: "y",
		items: ".routing-destination",
		handle: ".drag",
		update: routingCheckForPendingDestination,
	});

	$("#routing-address-results-list").on("click", "a.highlight-route-location", function (e) {
		e.preventDefault;

		if (trkData.routing.searchResults == null) {
			return;
		}

		var numRes = $j(this).data("resultNumber");
		if (trkData.routing.searchResults[numRes] === undefined || trkData.routing.searchResults[numRes] === null) {
			return;
		}

		var loc = trkData.routing.searchResults[numRes];
		var latLng = L.latLng(loc.address.lat, loc.address.lon);

		addPointToRoutingDestination(trkData.routing.searchDestination, latLng);
		trkData.routing.searchDestination.val(loc.formatted_address);
		routingCheckForPendingDestination();

		// hide search results
		var results = document.getElementById("routing-destination-results");
		results.classList.remove("is-visible");

		// calculate route (if valid)
	});

	domNodes.dialogs.placeRouting = $("#place-route-dialog")[0];
	$(domNodes.dialogs.placeRouting).on("click", "#PlaceRouteCalculate", function (e) {
		closePlaceRouting();
		var dialog = $j("#place-route-dialog");
		var placeId = $j("#PlaceRoutePlace").val();
		var assetId = $j("#PlaceRouteAsset").val();
		var place = findPlaceById(placeId);
		var via = $j("input[name=PlaceRouteVia]:checked", dialog).val();

		// take to latest position
		var latestPositionMarker = undefined;
		if (state.activeMapMode === mapModes.LIVE) {
			var latest = trkData.live.latestPositionsByAssetId[assetId];
			if (latest !== undefined && latest.Position !== undefined) {
				latestPositionMarker = trkData.live.markersByPositionId[latest.Position.Id];
			}
		} else {
			if (trkData.history.positionsByAssetId[assetId] !== undefined) {
				var firstPosition = _.first(trkData.history.positionsByAssetId[assetId].Positions);
				if (firstPosition !== undefined) {
					latestPositionMarker = trkData.history.markersByPositionId[firstPosition.Id];
				}
			}
		}

		if (latestPositionMarker === undefined || latestPositionMarker === null) {
			return;
		}

		var startingLocation = latestPositionMarker.getLatLng();

		var placePosition = null;
		for (var i = 0; i < trkData.placeMarkers.length; i++) {
			var placeId = trkData.placeMarkers[i].data.placeId;
			if (placeId == place.Id) {
				placePosition = trkData.placeMarkers[i];
				break;
			}
		}
		var endingLocation = placePosition.getLatLng();
		var bounds = L.latLngBounds(startingLocation, endingLocation);
		map.fitBounds(bounds, { padding: [10, 10] });
		switch (via) {
			case "Road":
				directionsService.on("routesfound", placeRoutesFound).on("routingerror", placeRoutesError);
				placeRoutesLoading();
				directionsService.setWaypoints([startingLocation, endingLocation]);
				$j("#place-route-directions").append(directionsService.control);
				// todo: loading screen here, it can take a while!
				break;
			default:
				// direct line of sight
				trkData.routeLine = new GeodesicLine([[startingLocation, endingLocation]], {
					weight: 4,
					color: "#cea843",
					wrap: false,
					steps: 50,
					opacity: 0.7,
				});
				addItemToMap(trkData.routeLine);
				if (options.enabledFeatures.indexOf("UI_GEOFENCE_FROM_ROUTE") !== -1) {
					$("#PlaceRouteGeofence,#RouteGeofence,#RouteGeofenceCreate").removeClass("disabled");
				}

				var distanceMeters = startingLocation.distanceTo(endingLocation);
				var distance = convertFromMetresToUserDistancePreference(distanceMeters);

				var speed = null;

				//var loc = $j(latestPosition).data('location');
				var loc = latestPositionMarker.data.location;
				if (loc != null) {
					speed = loc.Speed;
				}
				var time = null;
				if (speed != null && speed > 0) {
					time = distanceMeters / loc.Speed;
					time = secondsToHms(time);
				}
				placeRoutesFound();

				$j("#place-route-directions").append(el("span", distance));
				if (speed != null) {
					$j("#place-route-directions").append(el("span", " @ " + convertSpeedToPreference(speed)));
				}
				if (time != null) {
					$j("#place-route-directions").append(el("span", " - about " + time));
				}
				break;
		}
	});
	$(domNodes.dialogs.placeRouting).on("click", "#PlaceRouteCancel", function (e) {
		document.getElementById("panel-dialog-back").click();
	});
	domNodes.modals.geofenceBuffer = document.getElementById("geofence-buffer-modal");
	$(domNodes.modals.geofenceBuffer).on("hide.bs.modal", function (e) {
		state.isPreviewingBuffer = false;
		if (trkData.routePolygon !== null) {
			removeItemFromMap(trkData.routePolygon);
		}
	});
	$(domNodes.dialogs.placeRouting).on("click", "#PlaceRouteGeofence", function (e) {
		if (!user.canEditGeofences || options.enabledFeatures.indexOf("UI_GEOFENCE_FROM_ROUTE") === -1) {
			return;
		}
		$(domNodes.modals.geofenceBuffer).modal("show");
		state.isPreviewingBuffer = true;
		var buffer = $j("#RouteGeofenceBuffer").slider("value");
		$j("#RouteGeofenceBufferValue").text(buffer);
		throttles.bufferGeofence(buffer);
	});
	$(domNodes.modals.geofenceBuffer).on("click", "#RouteGeofenceCreate", function (e) {
		var btn = this;
		btn.disabled = true;
		if (options.enabledFeatures.indexOf("UI_GEOFENCE_FROM_ROUTE") === -1) {
			return;
		}

		var modal = $j(domNodes.modals.geofenceBuffer);
		var latlngs = [];
		if (trkData.routeLine != null) {
			trkData.routeLine.getLatLngs().forEach(function (elem, index) {
				latlngs.push({ Lat: elem.lat, Lng: elem.lng });
			});
		}
		var data = {
			encodedPolyline: trkData.routeLineEncoded,
			encodedPolylines: trkData.routeLinesEncoded,
			latlngs: latlngs,
			buffer: $j("#RouteGeofenceBuffer").slider("value"),
		};
		toggleLoadingMessage(false, "polyline-geofence");
		// submit encoded polyline to server, have it create and return a buffered polygon to populate add geofence
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/BufferPolyline"),
			data: JSON.stringify(data),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				btn.disabled = false;
				var result = msg.d;
				if (result) {
					if (result.Success != true) {
						handleWebServiceError(strings.MSG_ADD_GEOFENCE_ERROR);
					} else {
						modal.modal("hide");
						var points = result.Points;

						var fence = {
							Id: 1,
							Segments: [{ Points: points, Type: 1 }],
						};

						openGeofenceDialog(null);
						editFenceSegments(fence);
					}
				}
				toggleLoadingMessage(false, "polyline-geofence");
			},
			error: function (xhr, status, error) {
				btn.disabled = false;
				handleWebServiceError(strings.MSG_ADD_GEOFENCE_ERROR);
				toggleLoadingMessage(false, "polyline-geofence");
			},
		});
	});

	throttles.bufferGeofence = _.throttle(previewGeofenceBuffer, 1000);

	// var routeGeofenceButtons = [closeButton];
	// if (user.canEditGeofences) {
	// 	routeGeofenceButtons.unshift(routeAsGeofenceButton);
	// }

	$("#RouteGeofenceBuffer").slider({
		min: 25,
		max: 2000,
		value: 25,
		slide: function (event, ui) {
			$("#RouteGeofenceBufferValue").text(ui.value);
		},
		change: function (event, ui) {
			throttles.bufferGeofence(ui.value);
		},
	});
}

export function openRouting() {
	if (trkData.routing.isOpen) {
		return;
	}
	trkData.routing.isOpen = true;
	trkData.routing.start = null;
	trkData.routing.end = null;
	trkData.routing.isUsingMap = true;
	trkData.routing.points = [];
	if (options.enabledFeatures.indexOf("GET_ROUTE") !== -1) {
		trkData.routing.mapClickDestination = $(document.getElementById("RoutingDestination1"));
		startChoosingMapLocation(state.mapClickHandlers.ROUTING);
	}

	var results = document.getElementById("routing-results");
	results.classList.remove("is-visible");
	openDialogPanel(domNodes.dialogs.routing, strings.GET_ROUTE, null, false, closeRouting, "misc");
	var routeGeofence = document.getElementById("routing-geofence");
	if (user.canEditGeofences) {
		routeGeofence.classList.add("is-visible");
	}
	$("#RoutingWaypoints").find("li").remove();
}

function routingAddressEntry(e) {
	console.log("keyup!");
	// cancel the current route (if any)

	// cancel previous geocoding request (if any)

	// geocode the current text and populate the address results

	if (!options.enableGeocoding) {
		return;
	}
	var destination = this;
	var search = destination.value;
	$(this).removeData("location");

	if (state.routingSearch !== undefined && state.routingSearch !== null) {
		state.routingSearch.abort();
	}

	if (search === "") {
		return;
	}

	var results = document.getElementById("routing-destination-results");
	results.classList.add("is-visible");

	var loading = document.getElementById("routing-address-loading");
	loading.classList.add("is-visible");

	var status = $("#routing-address-status").hide();

	var searchResults = document.getElementById("routing-address-results");
	searchResults.classList.remove("is-visible");
	var noResults = document.getElementById("routing-address-no-results");
	noResults.classList.remove("is-visible");

	var resultList = document.getElementById("routing-address-results-list");
	setChildren(resultList, []);

	trkData.routingAddressResults = null;

	trkData.routing.searchDestination = $(destination);

	// search for address via external service
	state.routingSearch = addressSearch(search, function (success, resultData) {
		loading.classList.remove("is-visible");
		searchResults.classList.add("is-visible");
		if (!success) {
			status.text(strings.MSG_SEARCH_POSITION_ERROR).show();
		} else {
			if (resultData.length == 0) {
				noResults.classList.add("is-visible");
			} else {
				trkData.routing.searchResults = resultData;
				var RESULT_LIMIT = 5;
				var numResults = resultData.length > RESULT_LIMIT ? RESULT_LIMIT : resultData.length;
				var resultItems = [];
				for (var i = 0; i < numResults; i++) {
					resultItems.push(
						el(
							"li",
							el("a#HighlightRouteLocation" + i + ".highlight-route-location", { dataset: { resultNumber: i } }, [
								svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#map-marker-alt" } })),
								text(" " + resultData[i].formatted_address),
							])
						)
					);
				}
				setChildren(resultList, resultItems);
			}
		}
	});
}

function routingCalculateRoute() {
	if (options.enabledFeatures.indexOf("GET_ROUTE") === -1) {
		return;
	}
	var isFormValid = $(trkData.validation.routing.currentForm).valid();
	if (!isFormValid) {
		trkData.validation.routing.focusInvalid();
		return;
	}

	var dialog = domNodes.dialogs.routing;
	var destinations = $(".routing-destination input", dialog);
	var points = [];

	for (var i = 0; i < destinations.length; i++) {
		var $item = $(destinations[i]);
		var location = $item.data("location");
		if (location !== undefined && location !== null) {
			var waypoint = new L.Routing.Waypoint();
			waypoint.latLng = location.getLatLng();
			waypoint.options = { allowUTurn: false };
			points.push(waypoint);
		} else if ($item.val() !== "") {
			// address search may not have completed
			// how to determine if a user entered a lat/lng instead here?
			// this doesn't work, latLng is required
			//var waypoint = new L.Routing.Waypoint();
			//waypoint.name = $item.val();
			//points.push(waypoint);
		}
	}

	if (points.length < 2) {
		return;
	}

	//directionsService.getPlan().setWaypoints([]);
	//directionsService.remove();

	//directionsService.remove();
	//directionsService.control = directionsService.onAdd(map);
	directionsService
		//.on('routingstart', routesLoading)
		.on("routesfound", routesFound)
		.on("routingerror", routesError)
		.on("waypointschanged", routesWaypointsChanged);
	trkData.routing.calculatingRoute = true;
	routesLoading();
	directionsService.setWaypoints(points);

	// hide our duplicate icons
	var $destinations = $(".routing-destination input", domNodes.dialogs.routing);
	_.each($destinations, function (destination) {
		var location = $(destination).data("location");
		if (location !== undefined) {
			location.removeFrom(map);
		}
	});
	$j("#routing-directions").append(directionsService.control);
}

function secondsToHms(d) {
	d = Number(d);
	var h = Math.floor(d / 3600);
	var m = Math.floor((d % 3600) / 60);
	var s = Math.floor((d % 3600) % 60);
	return (
		(h > 0 ? h + " hrs " : "") +
		(m > 0 ? (h > 0 && m < 10 ? "0" : "") + m + " mins " : "") +
		(s < 10 ? "0" : "") +
		s +
		" secs"
	);
}

function updateRoutingLocation(destination, latlng) {
	destination.val(convertToLatLngPreference(latlng.lat, latlng.lng, null)).data("point", latlng);
	reverseGeocode(latlng, function (success, address) {
		if (success && address !== "") {
			destination.val(address);
		}
	});
}

function routingAddDestinationOption() {
	var destinationList = document.getElementById("routing-destinations");
	var $destinations = $(".routing-destination", destinationList);
	var $lastDestination = $destinations.last();
	var newDestination = $lastDestination[0].cloneNode(true);
	var use = $lastDestination[0].querySelector(".route-icon use");
	use.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#map-marker");
	var input = newDestination.querySelector("input");
	input.id = "RoutingDestination-" + Date.now().toString();
	input.value = "";
	$(input).removeData("location");
	destinationList.appendChild(newDestination);
}

function routingCheckForPendingDestination() {
	var hasPendingDestination = false;
	var destinations = $(".routing-destination input", domNodes.dialogs.routing);
	var addDestination = document.getElementById("routing-add-destination");
	for (var i = 0; i < destinations.length; i++) {
		var $item = $(destinations[i]);
		if ($item.val() === "") {
			hasPendingDestination = true;
			trkData.routing.mapClickDestination = $item;
			$item.prop("placeholder", strings.ENTER_DESTINATION_OR_USE_MAP);
			addDestination.classList.remove("is-visible");
			break;
		}
	}

	// move to the next needed destination if un-entered
	trkData.routing.isUsingMap = hasPendingDestination;
	if (!hasPendingDestination) {
		trkData.routing.mapClickDestination = "waypoint";
		stopChoosingMapLocation(state.mapClickHandlers.ROUTING);

		addDestination.classList.add("is-visible");
		// recalculate route
	} else {
		startChoosingMapLocation(state.mapClickHandlers.ROUTING);
	}

	// fix icons if they're out of order
	var destinationList = document.getElementById("routing-destinations");
	var $destinations = $(".routing-destination", destinationList);
	var disableRemoval = $destinations.length <= 2;
	for (var i = 0; i < $destinations.length; i++) {
		var remove = $destinations[i].querySelector(".remove");
		if (disableRemoval) {
			remove.classList.add("hidden");
		} else {
			remove.classList.remove("hidden");
		}
		var use = $destinations[i].querySelector(".route-icon use");
		if (i === $destinations.length - 1) {
			// last destination
			use.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#map-marker-alt");
		} else {
			use.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#map-marker");
		}
	}
}

function addPointToRoutingDestination($routingDestination, latlng) {
	// either create or move the marker associated with this location
	var existingLocation = $routingDestination.data("location");
	if (existingLocation === undefined) {
		//var locationIcon = L.icon({
		//    iconUrl: createMarkerPath('Generic', 'red', null, null, null, false),
		//    iconSize: [36, 36],
		//    iconAnchor: [18, 18]
		//});
		var location = L.marker(latlng, { draggable: true });
		addItemToMap(location);
		var destination = $routingDestination;
		location.on("dragend", function (e) {
			updateRoutingLocation(destination, e.target.getLatLng());
		});
		$routingDestination.data("location", location);
	} else {
		// move the location
		existingLocation.setLatLng(latlng);
	}
}

export function addPointToRouting(latlng) {
	if (!(latlng instanceof L.LatLng)) {
		latlng = L.latLng(latlng);
	}

	//if (!trkData.routing.isUsingMap) {
	//    return;
	//}

	if (trkData.routing.mapClickDestination == "waypoint") {
		//        trkData.routing.waypoints.push({
		//        	location: latlng.toString(),
		//point: latlng,
		//            stopover: true
		//        });
	} else {
		// todo: add marker denoting position, use .data to mark position
		addPointToRoutingDestination(trkData.routing.mapClickDestination, latlng);

		trkData.routing.mapClickDestination
			.val(convertToLatLngPreference(latlng.lat, latlng.lng, null))
			.data("point", latlng);
		var dest = trkData.routing.mapClickDestination;
		reverseGeocode(latlng, function (success, address) {
			if (success && address !== null && address !== "") {
				dest.val(address);
			}
		});

		dest.prop("placeholder", strings.ENTER_DESTINATION);
		routingCheckForPendingDestination();
		//$j('button.routing-map').prop('disabled', false).removeClass('active');
		//trkData.routing.mapClickDestination = 'waypoint';
	}
}

function routesLoading() {
	if (!trkData.routing.calculatingRoute) {
		return;
	}
	var btn = document.getElementById("RoutingCalculateRoute");
	btn.disabled = true;
	var loading = document.getElementById("routing-loading");
	var results = document.getElementById("routing-results");
	var status = document.getElementById("routing-status");
	loading.classList.add("is-visible");
	results.classList.remove("is-visible");
	status.classList.remove("is-visible");
}

function routesFound(e) {
	if (!trkData.routing.calculatingRoute) {
		return;
	}
	trkData.routing.calculatingRoute = false;
	var btn = document.getElementById("RoutingCalculateRoute");
	btn.disabled = false;
	var loading = document.getElementById("routing-loading");
	var results = document.getElementById("routing-results");
	var status = document.getElementById("routing-status");
	loading.classList.remove("is-visible");
	status.classList.remove("is-visible");
	results.classList.add("is-visible");

	// todo: recreate waypoints based on e.waypoints?
	// empty routing-destinations
	_.each(e.waypoints, function (waypoint) {
		// add .routing-destination
	});
}

function routesWaypointsChanged(e) {
	// ensure UI is synchronized with map
	console.log("waypoints changed");
	console.log(e);
	if (e.waypoints.length === 0) {
		return;
	}
	var destinations = $(".routing-destination input", domNodes.dialogs.routing);
	var newWaypoints = e.waypoints.length - destinations.length;
	var modifiedWaypoints = newWaypoints > 0;
	for (var i = 0; i < newWaypoints; i++) {
		routingAddDestinationOption();
	}

	destinations = $(".routing-destination input", domNodes.dialogs.routing);
	// synchronize with waypoints

	_.each(destinations, function (item, index) {
		if (e.waypoints[index] === undefined) {
			return;
		}
		var $item = $(item);
		var latlng = e.waypoints[index].latLng;
		if (latlng === null) {
			return;
		}
		var existingLatLng = $item.data("point");
		if (existingLatLng === undefined || existingLatLng !== latlng) {
			modifiedWaypoints = true;
			addPointToRoutingDestination($item, latlng);
			$item.val(convertToLatLngPreference(latlng.lat, latlng.lng, null)).data("point", latlng);
			reverseGeocode(latlng, function (success, address) {
				if (success && address !== null && address !== "") {
					$item.val(address);
				}
			});
		}
	});

	if (modifiedWaypoints) {
		routingCheckForPendingDestination();
	}
}

function routesError(e) {
	console.log("general routing error");
	console.log(e);
	if (!trkData.routing.calculatingRoute) {
		return;
	}
	trkData.routing.calculatingRoute = false;
	var btn = document.getElementById("RoutingCalculateRoute");
	btn.disabled = false;
	var loading = document.getElementById("routing-loading");
	var results = document.getElementById("routing-results");
	var status = document.getElementById("routing-status");
	loading.classList.remove("is-visible");
	status.classList.add("is-visible");
	status.classList.add("alert-danger");
	status.textContent = e.error.message.replace("HTTP request failed: ", "");
	if (e.error.target && e.error.target.responseText) {
		try {
			var osrmError = JSON.parse(e.error.target.responseText);
			if (osrmError.code && osrmError.code === "NoRoute") {
				status.textContent = strings.NO_ROUTE_FOUND;
			}
		} catch (e) {
			status.textContent = strings.ROUTING_FAILURE;
		}
	}
	results.classList.remove("is-visible");
}

function placeRoutesLoading() {
	var btn = document.getElementById("PlaceRouteCalculate");
	btn.disabled = true;
	var loading = document.getElementById("place-route-loading");
	var results = document.getElementById("place-route-results");
	var status = document.getElementById("place-route-status");
	loading.classList.add("is-visible");
	results.classList.remove("is-visible");
	status.classList.remove("is-visible");
}

export function placeRoutesFound() {
	var btn = document.getElementById("PlaceRouteCalculate");
	btn.disabled = false;
	var loading = document.getElementById("place-route-loading");
	var results = document.getElementById("place-route-results");
	var status = document.getElementById("place-route-status");
	loading.classList.remove("is-visible");
	status.classList.remove("is-visible");
	results.classList.add("is-visible");
}

export function placeRoutesError(e) {
	console.log("place route error");
	console.log(e);
	var btn = document.getElementById("PlaceRouteCalculate");
	btn.disabled = false;
	var loading = document.getElementById("place-route-loading");
	var results = document.getElementById("place-route-results");
	var status = document.getElementById("place-route-status");
	loading.classList.remove("is-visible");
	status.classList.add("is-visible");
	status.classList.add("alert-danger");
	status.textContent = e.error.message.replace("HTTP request failed: ", "");
	if (e.error.target && e.error.target.responseText) {
		try {
			var osrmError = JSON.parse(e.error.target.responseText);
			if (osrmError.code && osrmError.code === "NoRoute") {
				status.textContent = strings.NO_ROUTE_FOUND;
			}
		} catch (e) {
			status.textContent = strings.ROUTING_FAILURE;
		}
	}
	results.classList.remove("is-visible");
}

function closeRouting() {
	console.log("close routing");
	// remove directions from map and directions window
	if (!trkData.routing.isOpen) {
		return;
	}

	directionsService
		//.off('routingstart', placeRoutesLoading)
		.off("routesfound", routesFound)
		.off("routingerror", routesError)
		.off("waypointschanged", routesWaypointsChanged);
	//directionsService.remove();
	directionsService.getPlan().setWaypoints([]);

	// clear destinations and reset
	var $destinations = $(".routing-destination", domNodes.dialogs.routing);
	_.each($destinations, function (destination) {
		var $destinationInput = $("input", destination).val("");
		var location = $destinationInput.data("location");
		if (location !== undefined) {
			location.removeFrom(map);
			$destinationInput.removeData("location");
		}
	});
	if ($destinations.length > 2) {
		for (var i = 2; i < $destinations.length; i++) {
			var elem = $destinations[i];
			elem.parentNode.removeChild(elem);
		}
	}
	routingCheckForPendingDestination();

	trkData.routing.isOpen = false;
	trkData.routing.start = null;
	trkData.routing.end = null;
	trkData.routing.isUsingMap = false;
	trkData.routing.calculatingRoute = false;
	trkData.routing.mapClickDestination = "waypoint";
	trkData.routing.waypoints = [];
	stopChoosingMapLocation(state.mapClickHandlers.ROUTING);

	var btn = document.getElementById("RoutingCalculateRoute");
	btn.disabled = false;
	var loading = document.getElementById("routing-loading");
	var results = document.getElementById("routing-results");
	var status = document.getElementById("routing-status");
	loading.classList.remove("is-visible");
	status.classList.remove("is-visible");
	results.classList.remove("is-visible");
}

export function getOSRMLanguage(language) {
	if (language === "pt") {
		return "pt-BR";
	}
	return language;
}

function previewGeofenceBuffer(buffer) {
	var latlngs = [];
	if (trkData.routeLine != null) {
		var routeLocs = trkData.routeLine.getLatLngs();
		if (routeLocs.length > 0) {
			if (_.isArray(routeLocs[0])) {
				routeLocs = routeLocs[0];
			}
			_.each(routeLocs, function (elem) {
				latlngs.push({ Lat: elem.lat, Lng: elem.lng });
			});
		}
	}
	var data = {
		encodedPolyline: trkData.routeLineEncoded,
		encodedPolylines: trkData.routeLinesEncoded,
		latlngs: latlngs,
		buffer: buffer,
	};
	toggleLoadingMessage(false, "polyline-geofence-preview");
	$.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/BufferPolyline"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			var result = msg.d;
			if (result) {
				if (result.Success === true) {
					var points = result.Points;
					var latLngs = [];
					_.each(points, function (point) {
						latLngs.push(L.latLng(point.Lat, point.Lng));
					});
					if (latLngs.length > 1) {
						if (trkData.routePolygon === null) {
							trkData.routePolygon = L.polygon(latLngs, {
								weight: 2,
								color: "#ff0000",
								opacity: 0.35,
								fillOpacity: 0.15,
								dashArray: "4",
							});
						} else {
							trkData.routePolygon.setLatLngs(latLngs);
						}
						if (state.isPreviewingBuffer) {
							addItemToMap(trkData.routePolygon);
						}
					}
				}
			}
			toggleLoadingMessage(false, "polyline-geofence-preview");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_ADD_GEOFENCE_ERROR);
			toggleLoadingMessage(false, "polyline-geofence-preview");
		},
	});
}
