/// Map functionality for wheather radar (& IR, oil, maritime etc) basemap overlays.

import trkData from "./data.js";
import log from "./log.js";
import {
	map,
	layers,
	addOverlay,
	removeOverlay,
	addLayer,
	removeLayer,
	isOverlayActive,
	onTileLoaded,
} from "./map-base.js";
import options from "./options.js";
import { wrapUrl } from "./wrapurl.js";

import $ from "jquery";
import $j from "jquery";
import L from "leaflet";
const Cookies = window.Cookies; // from 'js.cookie.js', v2.2.0

var Conv = {
	toMercator: function (lat, lng) {
		var x = (lng * 20037508.34) / 180;
		var y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
		y = (y * 20037508.34) / 180;
		return L.point(x, y);
	},
	pointToLatLng: function (x, y) {
		var lng = (x / 20037508.34) * 180;
		var lat = (y / 20037508.34) * 180;
		lat = (180 / Math.PI) * (2 * Math.atan(Math.exp((lat * Math.PI) / 180)) - Math.PI / 2);
		return L.latLng(lat, lng);
	},
};

export function initMapWeather() {
	radarOverlay.on("tileload", onTileLoaded("radar"));
	maritimeOverlay.on("tileload", onTileLoaded("maritime"));
	oilOverlay.on("tileload", onTileLoaded("oil"));

	map.on("load", onMapLoaded);
}

const oilOverlay = L.tileLayer(
	"https://gis.boem.gov/arcgis/rest/services/BOEM_BSEE/MMC_Layers/MapServer/export?f=image&dpi=96&bboxSR=102100&imageSR=102100&format=png8&transparent=true&bbox={bbox}&size=256,256&layers=show:7,8,15,11,1,0",
	{
		bbox: function (tile) {
			var rect = TileToLocationRect(tile);
			var mbb1 = Conv.toMercator(rect.getSouth(), rect.getWest());
			var mbb2 = Conv.toMercator(rect.getNorth(), rect.getEast());
			return mbb1.x + "," + mbb1.y + "," + mbb2.x + "," + mbb2.y;
		},
		zIndex: 4,
	}
);

const maritimeOverlay = L.tileLayer(
	"//gis.charttools.noaa.gov/arcgis/rest/services/MCS/ENCOnline/MapServer/exts/MaritimeChartService/MapServer/export?dpi=96&transparent=true&format=png8&bbox={bbox}&bboxSR=3857&size=256%2C256&f=image&layers=show:2,3,4,5,6,7",
	{
		bbox: function (tile) {
			var rect = TileToLocationRect(tile);
			var mbb1 = Conv.toMercator(rect.getSouth(), rect.getWest());
			var mbb2 = Conv.toMercator(rect.getNorth(), rect.getEast());
			return mbb1.x + "," + mbb1.y + "," + mbb2.x + "," + mbb2.y;
		},
		zIndex: 4,
	}
);

const radarAustraliaOverlay = L.tileLayer("/services/layer.ashx?type=radar-aus&bbox={bbox}&rnd={time}", {
	time: function (tile) {
		return new Date().getTime();
	},
	base: function (tile) {
		return trkData.radarAustralia.basetime;
	},
	issue: function (tile) {
		return trkData.radarAustralia.issuetime;
	},
	bbox: function (tile) {
		var rect = TileToLocationRect(tile);
		//var mbb1 = Conv.toMercator(rect.getSouth(), rect.getWest());
		//var mbb2 = Conv.toMercator(rect.getNorth(), rect.getEast());
		return rect.getWest() + "," + rect.getSouth() + "," + rect.getEast() + "," + rect.getNorth();
	},
	bounds: L.latLngBounds([-7.740789, 108.318991], [-44.235258, 154.901023]),
	zIndex: 5,
	minZoom: 3,
});

const radarOverlay = L.tileLayer(
	"https://mesonet.agron.iastate.edu/cache/tile.py/1.0.0/nexrad-n0q-900913/{z}/{x}/{y}.png?{time}",
	{
		time: function (tile) {
			return new Date().getTime();
		},
		bounds: L.latLngBounds([71.48, -174.25], [17.0, -51.42]),
		zIndex: 5,
	}
);

const worldCloudOverlay = L.tileLayer(
	"https://earthlive.maptiles.arcgis.com/arcgis/rest/services/GOES/GOES31C/MapServer/tile/{z}/{y}/{x}?{time}",
	{
		time: function (tile) {
			return new Date().getTime();
		},
		zIndex: 4,
		opacity: 0.4,
	}
);

const seamapOverlay = L.tileLayer("https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png?type=google", {
	maxZoom: 18,
	attribution: "OpenSeaMap",
	zIndex: 4,
});

export function refreshWorldIR() {
	if (isOverlayActive(worldCloudOverlay)) {
		//$j('#radar .loading').show();
		log("World Cloud IR refreshing.");
		removeOverlay(worldCloudOverlay);
		addOverlay(worldCloudOverlay);
		trkData.worldIR.timer = setTimeout(refreshWorldIR, options.worldIRRefreshMinutes * 60 * 1000);
	} else {
		log("World cloud IR not active");
	}
}

export function refreshRadar() {
	// if the radar is enabled, refresh it
	if (isOverlayActive(radarOverlay)) {
		log("Radar refreshing.");
		removeOverlay(radarOverlay);
		addOverlay(radarOverlay);
		trkData.radar.timer = setTimeout(refreshRadar, options.radarRefreshMinutes * 60 * 1000);
	} else {
		trkData.radar.timer = null;
		log("radar not active");
	}
}

export function refreshRadarAustralia() {
	// if the radar is enabled, refresh it
	if (isOverlayActive(radarAustraliaOverlay)) {
		log("Australia Radar refreshing.");
		removeOverlay(radarAustraliaOverlay);
		addOverlay(radarAustraliaOverlay);
		trkData.radarAustralia.timer = setTimeout(refreshRadarAustralia, options.radarRefreshMinutes * 60 * 1000);
		//enableRadarAustralia(true, function () {
		//    removeOverlay(radarAustraliaOverlay);
		//    addOverlay(radarAustraliaOverlay);
		//    trkData.radarAustralia.timer = setTimeout(refreshRadarAustralia, options.radarRefreshMinutes * 60 * 1000);
		//});
	} else {
		trkData.radarAustralia.timer = null;
		log("Australia radar not active.");
	}
}

export function enableLayerWeather(requestData) {
	if (trkData.weather.isActive) return;
	trkData.weather.isActive = true;
	trkData.weather.layer = L.geoJSON(null, {
		style: function (feature) {},
		onEachFeature: function (feature, layer) {
			if (feature.properties != null) {
				if (feature.properties.city != null) {
					if (feature.properties.temperature != null) {
						//var tooltipIcon = '<img src="' + feature.properties.icon + '"><br>';
						var tooltip =
							"<strong>" +
							feature.properties.city +
							"</strong>" +
							"<br />" +
							feature.properties.temperature +
							"°C" +
							"<br />" +
							feature.properties.weather;
						layer.bindPopup(tooltip);
						layer.bindTooltip(tooltip);
					}
				}
			}
		},
		pointToLayer: function (feature, latlng) {
			if (feature.properties != undefined && feature.properties.icon != undefined) {
				var icon = L.icon({
					iconUrl: feature.properties.icon,
					iconSize: [50, 50],
					iconAnchor: [25, 25],
					tooltipAnchor: [25, 0],
					popupAnchor: [0, -25],
				});
				return L.marker(latlng, { icon: icon });
			}
			return L.marker(latlng);
		},
	});

	if (requestData) {
		checkWeatherForecast();
	}
	addLayer(trkData.weather.layer);
	$j("#map-layers-list .weather").addClass("active");
	Cookies.set("layer-weather", true, { expires: 365, path: "/", secure: true });
}

export function disableLayerWeather() {
	if (!trkData.weather.isActive) return;
	removeLayer(trkData.weather.layer);
	trkData.weather.isActive = false;
	$j("#map-layers-list .weather").removeClass("active");
	Cookies.remove("layer-weather");
}

export function disableLayerClouds() {
	if (!trkData.clouds.isActive) return;
	removeLayer(layers.clouds);
	trkData.clouds.isActive = false;
	$j("#map-layers-list .clouds").removeClass("active");
	Cookies.remove("layer-clouds");
}

export function enableLayerClouds() {
	if (trkData.clouds.isActive) return;
	addLayer(layers.clouds);
	trkData.clouds.isActive = true;
	$j("#map-layers-list .clouds").addClass("active");
	Cookies.set("layer-clouds", true, { expires: 365, path: "/", secure: true });
}

export function enableLayerOil() {
	if (trkData.oil.isActive) return;

	trkData.oil.isActive = true;
	addOverlay(oilOverlay);
	$j("#map-layers-list .oil").addClass("active");
	Cookies.set("layer-oil", true, { expires: 365, path: "/", secure: true });
}

export function disableLayerOil() {
	if (!trkData.oil.isActive) return;
	trkData.oil.isActive = false;
	removeOverlay(oilOverlay);
	$j("#map-layers-list .oil").removeClass("active");
	Cookies.remove("layer-oil");
}

export function enableLayerMaritime() {
	if (trkData.maritime.isActive) return;

	trkData.maritime.isActive = true;
	addOverlay(maritimeOverlay);
	$j("#map-layers-list .maritime").addClass("active");
	Cookies.set("layer-maritime", true, { expires: 365, path: "/", secure: true });
}

export function disableLayerMaritime() {
	if (!trkData.maritime.isActive) return;
	trkData.maritime.isActive = false;
	removeOverlay(maritimeOverlay);
	$j("#map-layers-list .maritime").removeClass("active");
	Cookies.remove("layer-maritime");
}

export function enableWorldIR() {
	if (trkData.worldIR.isActive) return;

	trkData.worldIR.isActive = true;
	addOverlay(worldCloudOverlay);
	trkData.worldIR.timer = setTimeout(refreshWorldIR, options.worldIRRefreshMinutes * 60 * 1000);
	$j("#map-layers-list .worldIR").addClass("active");
	Cookies.set("worldIR", true, { expires: 365, path: "/", secure: true });
}

export function disableWorldIR() {
	if (!trkData.worldIR.isActive) return;
	trkData.worldIR.isActive = false;
	clearTimeout(trkData.worldIR.timer);
	removeOverlay(worldCloudOverlay);

	Cookies.remove("worldIR");
	$j("#map-layers-list .worldIR").removeClass("active");
}

export function enableRadar() {
	if (trkData.radar.isActive) return;

	trkData.radar.isActive = true;
	addOverlay(radarOverlay);
	// use setInterval instead
	trkData.radar.timer = setTimeout(refreshRadar, options.radarRefreshMinutes * 60 * 1000);
	//$j('#radar a').addClass('active');
	$j("#map-layers-list .radar").addClass("active");
	$j("#legend_radar").show();
	Cookies.set("radar", true, { expires: 365, path: "/", secure: true });
}

export function disableRadar() {
	if (!trkData.radar.isActive) return;
	trkData.radar.isActive = false;
	clearTimeout(trkData.radar.timer);
	removeOverlay(radarOverlay);
	Cookies.remove("radar");
	$j("#legend_radar").hide();
	$j("#map-layers-list .radar").removeClass("active");
}

export function enableRadarAustraliaActive() {
	if (trkData.radarAustralia.isActive) return;
	trkData.radarAustralia.isActive = true;
	addOverlay(radarAustraliaOverlay);
	// use setInterval instead
	trkData.radarAustralia.timer = setTimeout(refreshRadarAustralia, options.radarRefreshMinutes * 60 * 1000);
	$j("#map-layers-list .radar-australia").addClass("active");
	$j("#legend_radar").show();
	Cookies.set("radar-australia", true, { expires: 365, path: "/", secure: true });
}

export function disableRadarAustralia() {
	if (!trkData.radarAustralia.isActive) {
		return;
	}
	trkData.radarAustralia.isActive = false;
	clearTimeout(trkData.radarAustralia.timer);
	removeOverlay(radarAustraliaOverlay);
	Cookies.remove("radar-australia");
	$("#legend_radar").hide();
	$("#map-layers-list .radar-australia").removeClass("active");
}

export function checkWeatherForecast() {
	if (!trkData.weather.isActive) return;
	if (trkData.weather.gettingData) {
		if (trkData.weather.request != null) {
			trkData.weather.request.abort();
		}
		trkData.weather.gettingData = false;
	}
	getWeatherForecast();
}

export function getWeatherForecast() {
	var bounds = map.getBounds();
	var NE = bounds.getNorthEast();
	var SW = bounds.getSouthWest();
	var northLat = NE.lat;
	var eastLng = NE.lng;
	var southLat = SW.lat;
	var westLng = SW.lng;
	trkData.weather.gettingData = true;
	var url =
		"https://api.openweathermap.org/data/2.5/box/city?bbox=" +
		westLng +
		"," +
		northLat +
		"," + //left top
		eastLng +
		"," +
		southLat +
		"," + //right bottom
		map.getZoom() +
		"&cluster=yes&format=json" +
		"&APPID=" +
		"551b97ca557560dfc7d8c49a81b37d89";
	trkData.weather.request = $j.ajax({
		type: "GET",
		url: wrapUrl(url),
		dataType: "json",
		contentType: false,
		success: function (results) {
			console.log(results);
			if (results == null) return;
			if (results.list == null) return;
			if (results.list.length > 0) {
				// reset data
				trkData.weather.geoJSON = {
					type: "FeatureCollection",
					features: [],
				};

				// clear the prior set of data
				trkData.weather.layer.clearLayers();

				for (var i = 0; i < results.list.length; i++) {
					trkData.weather.geoJSON.features.push(forecastJsonToGeoJson(results.list[i]));
				}
				trkData.weather.layer.addData(trkData.weather.geoJSON);
				trkData.weather.gettingData = false;
			}
		},
		error: function () {
			trkData.weather.gettingData = false;
		},
	});
}

export function forecastJsonToGeoJson(weatherItem) {
	var feature = {
		type: "Feature",
		properties: {
			city: weatherItem.name,
			weather: weatherItem.weather[0].main,
			temperature: weatherItem.main.temp,
			min: weatherItem.main.temp_min,
			max: weatherItem.main.temp_max,
			humidity: weatherItem.main.humidity,
			pressure: weatherItem.main.pressure,
			windSpeed: weatherItem.wind.speed,
			windDegrees: weatherItem.wind.deg,
			windGust: weatherItem.wind.gust,
			icon: "https://openweathermap.org/img/w/" + weatherItem.weather[0].icon + ".png",
			coordinates: [weatherItem.coord.Lon, weatherItem.coord.Lat],
		},
		geometry: {
			type: "Point",
			coordinates: [weatherItem.coord.Lon, weatherItem.coord.Lat],
		},
	};
	//// Set the custom marker icon
	//trkData.weather.layer.setStyle(function(feature) {
	//  return {
	//    icon: {
	//      url: wrapUrl(feature.getProperty('icon')),
	//      anchor: new google.maps.Point(25, 25)
	//    }
	//  };
	//});
	// returns object
	return feature;
}

function TileToLocationRect(tile) {
	var mapSize = Math.pow(2, tile.z);
	var west = (tile.x * 360) / mapSize - 180;
	var east = ((tile.x + 1) * 360) / mapSize - 180;

	var efactor = Math.exp((0.5 - tile.y / mapSize) * 4 * Math.PI);
	var north = Math.asin((efactor - 1) / (efactor + 1)) * (180 / Math.PI);

	efactor = Math.exp((0.5 - (tile.y + 1) / mapSize) * 4 * Math.PI);
	var south = Math.asin((efactor - 1) / (efactor + 1)) * (180 / Math.PI);
	var rect = L.latLngBounds(L.latLng(north, west), L.latLng(south, east));
	return rect;
}

function onMapLoaded() {
	if (Cookies.get("radar") !== undefined || $j.inArray("radar", options.enableLayers) !== -1) {
		enableRadar();
	}

	if (Cookies.get("radar-australia") !== undefined || $j.inArray("radar-australia", options.enableLayers) !== -1) {
		//enableRadarAustralia(false, enableRadarAustraliaActive);
		enableRadarAustraliaActive();
	}

	if (Cookies.get("worldIR") !== undefined || $j.inArray("worldIR", options.enableLayers) !== -1) {
		enableWorldIR();
	}

	if (Cookies.get("layer-oil") !== undefined || $j.inArray("oil", options.enableLayers) !== -1) {
		enableLayerOil();
	}

	if (Cookies.get("layer-maritime") !== undefined || $j.inArray("maritime", options.enableLayers) !== -1) {
		enableLayerMaritime();
	}

	if (Cookies.get("layer-weather") !== undefined || $j.inArray("weather", options.enableLayers) !== -1) {
		enableLayerWeather(false);
	}

	if (Cookies.get("layer-clouds") !== undefined || $j.inArray("clouds", options.enableLayers) !== -1) {
		enableLayerClouds();
	}
}
