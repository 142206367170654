import strings from "./strings.js";
import trkData from "./data.js";
import state from "./state.js";
import options from "./options.js";
import { map } from "./map-base.js";
import { addItemToMap } from "./map-items.js";
import { handleWebServiceError } from "./ajax.js";

import $ from "jquery";
import $j from "jquery";
import L from "leaflet";
import _ from "lodash";

export function userLocationErrorManual(error) {
	switch (error.code) {
		case error.PERMISSION_DENIED:
			$("#map-current-location").addClass("disabled");
			handleWebServiceError(strings.CURRENT_LOCATION_NO_PERMISSION);
			break;
		case error.POSITION_UNAVAILABLE:
			handleWebServiceError(strings.CURRENT_LOCATION_UNKNOWN);
			break;
		case error.TIMEOUT:
			handleWebServiceError(strings.CURRENT_LOCATION_UNKNOWN);
			break;
	}
}

export function userLocationUpdated(position) {
	if (map == null) {
		return;
	}
	if (position == null) {
		return;
	}

	if (state.userLocation.watch === null) {
		return;
	}

	var pos = L.latLng(position.coords.latitude, position.coords.longitude).wrap();

	state.userLocation.position = position;
	if (state.userLocation.marker === null) {
		var icon = L.icon({
			iconUrl: "/content/images/marker-my-location.png",
			iconSize: [14, 14],
			iconAnchor: [7, 7],
		});
		state.userLocation.marker = L.marker(pos, {
			icon: icon,
			title: strings.CURRENT_LOCATION_YOU,
		}).bindTooltip(strings.CURRENT_LOCATION_YOU);
		addItemToMap(state.userLocation.marker, trkData.mapLayers.other);
		state.userLocation.circle = L.circle(pos, {
			radius: position.coords.accuracy,
			color: "#3583ff",
			fillColor: "#3583ff",
			opacity: 1,
			fillOpacity: 0.15,
			weight: 0,
		}).bindTooltip(strings.CURRENT_LOCATION_YOU);
		addItemToMap(state.userLocation.circle, trkData.mapLayers.other);
	} else {
		state.userLocation.marker.setLatLng(pos);
		if (state.userLocation.circle !== null) {
			state.userLocation.circle.setLatLng(pos);
			state.userLocation.circle.setRadius(position.coords.accuracy);
		}
	}

	if (state.userLocation.zoomToPosition) {
		map.setView(pos, options.defaultZoom);
		state.userLocation.zoomToPosition = false;
	}
	$j("#map-current-location").removeClass("disabled");
}

export function zoomToUserLocation() {
	var position = state.userLocation.position;
	if (position === null) {
		return;
	}
	var pos = L.latLng(position.coords.latitude, position.coords.longitude).wrap();
	map.setView(pos, options.defaultZoom);
	state.userLocation.zoomToPosition = false;
}
