import L from "leaflet";

/*global MapToolbar */
// import MapToolbar from '../MapToolbar.js';

export function editFenceSegments(fence) {
	console.log(fence);
	for (var j = 0; j < fence.Segments.length; j++) {
		var segment = fence.Segments[j];
		switch (segment.Type) {
			case 0: // line
				var points = [];
				for (var k = 0; k < segment.Points.length; k++) {
					var point = segment.Points[k];
					var latlng = L.latLng(point.Lat, point.Lng);
					//MapToolbar.addPoint(latlng, MapToolbar.currentFeature);
					points.push(latlng);
				}
				MapToolbar.initFeature("line", segment.Id, points);

				break;
			case 1: // shape
				//trkData.drawing.manager.edit()
				var points = [];
				for (var k = 0; k < segment.Points.length - 1; k++) {
					// ignore the last point for a polygon as it's the same as the first
					var point = segment.Points[k];
					var latlng = L.latLng(point.Lat, point.Lng);
					points.push(latlng);
				}
				MapToolbar.initFeature("shape", segment.Id, points);
				break;
			case 2: // circle
				var center = L.latLng(segment.Center.Lat, segment.Center.Lng);
				var radius = L.latLng(segment.Radius.Lat, segment.Radius.Lng);
				//MapToolbar.addPoint(center, MapToolbar.currentFeature);
				//MapToolbar.addPoint(radius, MapToolbar.currentFeature);
				MapToolbar.initFeature("circle", segment.Id, [center, radius]);
				break;
		}
	}
}
