import user from "./user.js";
import options from "./options.js";
import trkData from "./data.js";
import _ from "lodash";

export function isSendCommandDisabledForDevice(device) {
	if (user.isAnonymous) {
		return true;
	}
	if (options.enabledFeatures.indexOf("REMOTE_MANAGEMENT") === -1) {
		return true;
	}
	if (user.canSendCommands === undefined || !user.canSendCommands) {
		return true;
	}
	// send command - this is dumb hardcoded
	// TODO property on the device
	var groupedDeviceIds = [
		devices.NAL,
		devices.QUAKE_AIC,
		devices.QUECLINK,
		devices.FLIGHTCELL,
		devices.GTTS,
		devices.HUGHES,
		devices.INMARSAT_C,
		devices.SKYWAVE_IDP,
		devices.SKYWAVE_IDP_DUAL_MODE,
		devices.CALAMP,
		devices.GSAT_MICROS,
		devices.INREACH,
		devices.GSE_FBB,
	];
	for (var i = 0; i < groupedDeviceIds.length; i++) {
		if (_.indexOf(groupedDeviceIds[i], device.Id) !== -1) {
			return false;
		}
	}

	var individualDeviceIds = [
		devices.IRIDIUM_EDGE,
		devices.LT100,
		devices.LT501,
		devices.EDGE_SOLAR,
		devices.DAN_TRACKER,
		devices.GEOPRO,
		devices.DPLUS,
		devices.IRIDIUM_EXTREME,
		devices.EDGE_SOLAR,
		devices.TM3000,
		devices.METOCEAN_ITRAC,
	];

	if (_.indexOf(individualDeviceIds, device.Id) !== -1) {
		return false;
	}

	return true;
}

export const devices = {
	DPLUS: 3, // ems satcom
	SKYWAVE_IDP: [26, 58, 60, 154, 157],
	SKYWAVE_IDP_DUAL_MODE: [49, 59, 70, 106, 367, 383],
	SKYWAVE_IDP_CELL_ONLY: [157],
	SKYWAVE_7XX: 59,
	SKYWAVE_782: 106,
	SKYWAVE_ST9100: 367,
	ORBCOMM_ST9101: 383,
	SKYWAVE_782_CELL: 157,
	SKYWAVE_SG7100: 70,
	MOBILE: [18, 45, 56], // iPhone, Android, WP8
	GSATMICRO: [34, 275],
	GSATMICRO_GSM: [118, 276],
	GSATSOLARS: [361, 362, 380],
	ISATMARKER: 163,
	GSAT_MICROS: [34, 118, 123, 163, 275, 276],
	NAL: [2, 76, 83, 84, 85, 124, 156, 164, 304, 305, 306],
	NAL_SHOUT_TS: 85,
	NAL_SHOUT_NANO: 84,
	NAL_GSM: [76, 164, 306],
	HUGHES: [166, 167, 168, 169, 170],
	QUAKE_AIC: [111],
	QUECLINK: [19, 73, 79, 80, 81, 82, 94, 95, 107, 130, 140, 142, 143, 153, 384, 391, 392, 396],
	FLIGHTCELL: [28, 261, 262],
	QUECLINK_GB100: 143,
	QUECLINK_GL200: 79,
	QUECLINK_GL300: 80,
	QUECLINK_GL300W: 130,
	QUECLINK_GL320MG: 391,
	QUECLINK_GL500: 81,
	QUECLINK_GL520: 142,
	QUECLINK_GL521MG: 392,
	QUECLINK_GV500: 82,
	QUECLINK_GV500MAP: 396,
	QUECLINK_GV200: 73,
	QUECLINK_GT300: 95,
	QUECLINK_GT301: 140,
	QUECLINK_GV300: 107,
	QUECLINK_GV600W: 153,
	QUECLINK_GV620MG: 384,
	GOTEK_PRIMELITE: 94,
	SPOT: [35, 151, 152, 159, 171, 172, 173, 174, 175, 176, 347, 351, 353, 354],
	INMARSAT_C: [41, 234, 235, 236],
	GTTS: [51, 100, 101],
	GTTS_2000BI: 51,
	GTTS_2000B: 101,
	GTTS_3000: 100,
	DAN_TRACKER: 125, // LTT10
	IRIDIUM_EDGE: 129,
	IRIDIUM_EXTREME: 16,
	TM3000: 13,
	CALAMP: [
		37, 232, 231, 230, 229, 228, 227, 226, 225, 224, 223, 222, 221, 220, 219, 218, 217, 216, 215, 214, 213, 212, 211,
		210, 209, 208, 207, 206, 205, 204, 203, 202, 201, 200, 199, 198, 197, 196,
	],
	INREACH: [46, 271, 272, 273, 274],
	GEOPRO: 57,
	//GARMIN_FLEET_590: 65,
	LT100: 135,
	LT501: 155,
	GSE_FBB: [9, 66],
	BEAM: 42,
	EDGE_SOLAR: 356,
	BIVY_STICK: 368,
	METOCEAN_ITRAC: 397,
};

export function findDeviceById(id) {
	var item = _.find(trkData.devices, { Id: parseInt(id) });
	return item === undefined ? null : item;
}
