
import { el, svg } from 'redom';
import $ from "jquery";
// import * as List from "list.js/dist/list.js"; // See https://github.com/javve/list.js
import List from "list.js/src/index.js"; // See https://github.com/javve/list.js

var ALIVE_TIME = 60000 * 5; // 5 minutes

function keepAlive() {
    $.post('/api/ui/ka');
    setTimeout(keepAlive, ALIVE_TIME);
}

export function handleWebServiceError(msg) {
    var items = [
        svg('svg', svg('use', { xlink: { href: '/content/svg/tracking.svg?v=15#exclamation-circle' } }))
    ];
    if (msg && (msg.length > 0)) {
        items.push(el('span', msg));
        $.jGrowl(items, { header: utilityStrings.MSG_ERROR, life: 10000, theme: 'growl-error' });
    } else {
        items.push(el('span', utilityStrings.MSG_ERROR_OCCURRED));
        $.jGrowl(items, { header: utilityStrings.MSG_ERROR, life: 10000, theme: 'growl-error' });
    }
}

export function createCheckboxFilterList(containerId, valueNames) {
    if (valueNames === undefined) {
        valueNames = ["custom-control-label"];
    }
    var container = document.getElementById(containerId);
    var itemList = new List(container, {
        listClass: "list",
        searchClass: "search",
        indexAsync: true,
        //page: 20,
        //pagination: true,
        valueNames: valueNames,
    }).on("searchComplete", function (list) {
        var helperAll = container.querySelector(".list-selection-all");
        var helperFiltered = container.querySelector(".list-selection-filtered");

        helperAll.classList.remove("is-visible");
        helperFiltered.classList.remove("is-visible");
        if (list.searched && list.matchingItems.length > 0) {
            helperFiltered.classList.add("is-visible");
        }

        if (!list.searched && list.items.length > 0) {
            helperAll.classList.add("is-visible");
        }

        // todo: perhaps also show the number of results for the search?
        //var results = container.querySelector('.filter-results');
        //if (!list.searched) {
        //    results.classList.remove('is-visible');
        //} else {
        //    results.classList.add('is-visible');
        //    var text = results.querySelector('span');
        //    text.textContent = list.matchingItems.length + ' / ' + list.items.length;
        //}
    });

    $(container).on("click", "a.list-select-all", function (e) {
        e.preventDefault();

        var list = this.getAttribute("data-visible") !== null ? itemList.visibleItems : itemList.items;
        _.each(list, function (item) {
            var checkbox = item.elm.querySelector(".custom-control-input");
            if (checkbox !== null) {
                checkbox.checked = true;
                $(checkbox).trigger("change");
                //var link = container.querySelector('#' + checkbox.id + '-hidden');
                //if (link !== null) {
                //    link.checked = true;
                //    $(link).trigger('change');
                //}

                //if (checkbox.dataset.enables !== undefined) {
                //    var linked = container.querySelector(checkbox.dataset.enables);
                //    if (linked !== null) {
                //        linked.disabled = !checkbox.checked;
                //        if (linked.disabled) {
                //            linked.checked = false;
                //        }
                //    }
                //}
            }
        });
    });

    $(container).on("click", "a.list-select-none", function (e) {
        e.preventDefault();

        var list = this.getAttribute("data-visible") !== null ? itemList.visibleItems : itemList.items;
        _.each(list, function (item) {
            var checkbox = item.elm.querySelector(".custom-control-input");
            if (checkbox !== null) {
                checkbox.checked = false;
                $(checkbox).trigger("change");
                //var link = container.querySelector('#' + checkbox.id + '-hidden');
                //if (link !== null) {
                //    link.checked = false;
                //    $(link).trigger('change');
                //}
                //if (checkbox.dataset.enables !== undefined) {
                //    var linked = container.querySelector(checkbox.dataset.enables);
                //    if (linked !== null) {
                //        linked.disabled = !checkbox.checked;
                //        if (linked.disabled) {
                //            linked.checked = false;
                //        }
                //    }
                //}
            }
        });
    });

    $(container).on("change", "input.custom-control-input", function () {
        var link = container.querySelector("#" + this.id + "-hidden");
        if (link !== null) {
            link.checked = this.checked;
            $(link).trigger("change");
        }

        if (this.dataset.enables !== undefined) {
            var linked = container.querySelector(this.dataset.enables);
            if (linked !== null) {
                linked.disabled = !this.checked;
                if (linked.disabled) {
                    linked.checked = false;
                }
            }
        }
    });
    return itemList;
}

function toggleHeader() {
    $('#toggle-header a').toggleClass('up');
    $('#head').slideToggle(400, function () {
        // resize tracking app if available
        if (typeof tracking === 'object') {
            tracking.resizeApp();
        }
    });
}

//function sizeScrollingSelector() {
//    if ($(window).width() <= 768) {
//        ht = Math.round($(window).height() * 0.4);
//        $('#scrolling-selector-list').css('max-height', ht + 'px');
//    }
//    else {
//        $('#scrolling-selector-list').css('max-height', '20em');
//    }
//}

//window.onresize = function (event) {
//    sizeScrollingSelector();
//}

function preventDefaults(e) {
    e.preventDefault()
    e.stopPropagation()
}

function highlightDropZone(e) {
    this.classList.add('highlight');
}

function unhighlightDropZone(e) {
    this.classList.remove('highlight');
}

$(document).ready(function () {
    //sizeScrollingSelector();
    setTimeout(keepAlive, ALIVE_TIME);
    //if (typeof ($().button) === 'function') {
    //    $('button.command.edit').button({ text: null, icons: { primary: 'ui-icon-pencil' } });
    //    $('button.command.delete').button({ text: null, icons: { primary: 'ui-icon-trash' } });
    //    $('button.command.add').button({ icons: { primary: 'ui-icon-plusthick' } });
    //    $('button.command.upload').button({ icons: { primary: 'ui-icon-circle-arrow-n' } });
    //    $('button.command.download').button({ icons: { primary: 'ui-icon-circle-arrow-s' } });
    //    $('button.command.preview').button({ icons: { primary: 'ui-icon-circle-zoomin' } });
    //    $('button.command.up').button({ text: null, icons: { primary: 'ui-icon-circle-arrow-n' } });
    //    $('button.command.down').button({ text: null, icons: { primary: 'ui-icon-circle-arrow-s' } });
    //    $('button.command.test').button({ icons: { primary: 'ui-icon-mail-closed' } });
    //    $('button.command.disable').button({ icons: { primary: 'ui-icon-closethick' } });
    //    $('button.command.enable').button({ icons: { primary: 'ui-icon-check' } });
    //    $('button.command.view').button({ icons: { primary: 'ui-icon-note' } });

    //    $('button.command').button();
    //    $('#PrintPage').button({
    //        icons: {
    //            primary: 'ui-icon-print'
    //        }
    //    });
    //    $('#ExportExcel').button({
    //        icons: {
    //            primary: 'ui-icon-excel'
    //        }
    //    });
    //    $('#ExportPDF').button({
    //        icons: {
    //            primary: 'ui-icon-pdf'
    //        }
    //    });
    //}

    // header toggle for anonymous users
    $('#root').on('click', '#toggle-header a', function (e) {
        e.preventDefault();
        toggleHeader();
    });

    $('#PrintPage').on('click', this, function (e) {
        window.print();
    });

    // asset selection events, relative and duplicatable
    $('body').on('click', 'button.AddItem', function (e) {
        e.preventDefault();

        // move selected items from right to left
        var cont = $(this).parents('table');
        var selects = cont.find('select');
        var left = $(selects[0]);
        var right = $(selects[1]);
        right.children().each(function () {
            if ($(this).prop('selected') === true) {
                var clone = $(this).clone(true);
                $(this).remove();
                left.append(clone);
            }
        });
    });

    $('body').on('click', 'button.RemoveItem', function (e) {
        e.preventDefault();

        // move selected items from left to left
        var cont = $(this).parents('table');
        var selects = cont.find('select');
        var left = $(selects[0]);
        var right = $(selects[1]);
        left.children().each(function () {
            if ($(this).prop('selected') === true) {
                var clone = $(this).clone(true);
                $(this).remove();
                right.append(clone);
            }
        });
    });

    $('body').on('click', 'a.SelectAllItems', function (e) {
        e.preventDefault();

        var cont = $(this).parents('table');
        var selects = cont.find('select');
        var right = $(selects[1]);
        right.find('option').prop('selected', true);
    });

    $('body').on('click', 'a.SelectAllCurrentItems', function (e) {
        e.preventDefault();

        var cont = $(this).parents('table');
        var selects = cont.find('select');
        var left = $(selects[0]);
        left.find('option').prop('selected', true);
    });

    $('body').on('click', 'button.AddAllItems', function (e) {
        e.preventDefault();

        // move selected items from right to left
        var cont = $(this).parents('table');
        var selects = cont.find('select');
        var left = $(selects[0]);
        var right = $(selects[1]);
        right.children().each(function () {
            var clone = $(this).clone(true);
            $(this).remove();
            left.append(clone);
        });
    });

    $('body').on('click', 'button.RemoveAllItems', function (e) {
        e.preventDefault();

        // move selected items from right to left
        var cont = $(this).parents('table');
        var selects = cont.find('select');
        var left = $(selects[0]);
        var right = $(selects[1]);
        left.children().each(function () {
            var clone = $(this).clone(true);
            $(this).remove();
            right.append(clone);
        });
    });

    $('body').on('click', 'button.select-all', function (e) {
        e.preventDefault();
        $(document.getElementById(this.getAttribute('data-select-source'))).find('option').prop('selected', true);
    });
    $('body').on('click', 'button.move-items', function (e) {
        e.preventDefault();
        var from = $(document.getElementById(this.getAttribute('data-move-from')));
        var to = $(document.getElementById(this.getAttribute('data-move-to')));
        var moveAll = this.getAttribute('data-move-all');
        if (moveAll === 'true') {
            // move all items regardless of selection
            from.children().each(function () {
                var clone = $(this).clone(true);
                $(this).remove();
                to.append(clone);
            });
        } else {
            // move selected items only
            from.children().each(function () {
                if ($(this).prop('selected') === true) {
                    var clone = $(this).clone(true);
                    $(this).remove();
                    to.append(clone);
                }
            });
        }
        // update counts, if there
        var fromCount = from[0].getAttribute('data-item-count');
        if (fromCount !== null) {
            var count = document.getElementById(fromCount);
            if (count !== null) {
                count.textContent = from[0].querySelectorAll('option').length;
            }
        }
        var toCount = to[0].getAttribute('data-item-count');
        if (toCount !== null) {
            var count = document.getElementById(toCount);
            if (count !== null) {
                count.textContent = to[0].querySelectorAll('option').length;
            }
        }
    });
    $('body').on('click', '.show-upgrade', function (e) {
        e.preventDefault();
        $('#upgrade-tracking-modal').modal('show');
    });

    if (document.body.classList.contains('feature-unavailable')) {
        var pageContent = document.getElementById('page-content');
        if (pageContent === null) {
            return;
        }
        var items = pageContent.querySelectorAll('button,a,input,select,form,.card,label');
        _.each(items, function (item) {
            if (!item.classList.contains('custom-file-input')) {
                item.classList.add('disabled');
            }
            item.disabled = true;
        });
    }

    var unavailableSections = document.querySelectorAll('.feature-unavailable-section');
    var excludeIds = ['RoutingCancel'];
    _.each(unavailableSections, function (section) {
        var items = section.querySelectorAll('button,a,input,select,form,.card,label');
        _.each(items, function (item) {
            if (excludeIds.indexOf(item.id) !== -1) {
                return;
            }
            if (!item.classList.contains('custom-file-input')) {
                item.classList.add('disabled');
            }
            item.disabled = true;
        });
    });

    var fileInputs = document.querySelectorAll('input[type=file].custom-file-input');
    _.each(fileInputs, function (fileInput) {
        var label = document.querySelector('label[for=' + fileInput.id + '].custom-file-label');
        if (label !== null) {
            label.dataset.originalLabel = label.textContent;
            fileInput.addEventListener('change', function () {
                // update photo label
                var filename = this.value.replace('C:\\fakepath\\', '');
                if (filename == '' && label.dataset.originalLabel !== undefined) {
                    filename = label.dataset.originalLabel;
                }
                document.querySelector('label[for=' + fileInput.id + '].custom-file-label').textContent = filename;
            });
        }
    });

    var dropZones = document.querySelectorAll('.upload-zone');
    var dragEvents = ['dragenter', 'dragover', 'dragleave', 'drop'];
    var highlightEvents = ['dragenter', 'dragover'];
    var unhighlightEvents = ['dragleave', 'drop'];
    _.each(dropZones, function (dropZone) {
        _.each(dragEvents, function (dragEvent) {
            dropZone.addEventListener(dragEvent, preventDefaults, false);
        });
        _.each(highlightEvents, function (highlightEvent) {
            dropZone.addEventListener(highlightEvent, highlightDropZone, false);
        });
        _.each(unhighlightEvents, function (unhighlightEvent) {
            dropZone.addEventListener(unhighlightEvent, unhighlightDropZone, false);
        });
    });
});

$(window).on('unload', function () {
    if (typeof GUnload === 'function') {
        GUnload();
    }
});