/**
* maxChar jQuery plugin
* @author Mitch Wilson
* @version 0.3.0
* @requires jQuery 1.3.2 (only version tested)
* @see http://mitchwilson.net/2009/08/03/new-jquery-plugin-maxchar/
* @param {Boolean}	debug				Specify whether to send message updates to the Firebug console. Default is false.
* @param {String}	indicator 			Specify alternate indicator element by id. Default is indicator created dynamically.
* @param {String}	label				Specify a default label displayed when input element is not in focus. Default is blank.
* @param {String}	pluralMessage 		Set the plural form of the remaining count message. Default is ' remaining'.
* @param {Number}	rate 				Set the update rate in milliseconds. Default is 200.
* @param {String}	singularMessage 	Set the singular form of the remaining count message. Default is ' remaining'.
* @param {String}	spaceBeforeMessage 	Set spacing in front of (to the left of) the indicator message. Default is ' '.
* @param {Boolean}	truncate			Truncate submitted value down to limit on form submit. Default is true.
* @description Enforces max character limit on any input or textarea HTML element and provides user feedback and many options. 
* New features added in 0.3.0 are: 
* 1) Feature change: Displays negative characters when past limit rather than truncating characters in form input.
* 2) New option: truncate - If true, on form submit truncates submitted value down specified by limit. Does not change (respects) user text in form field. Default is true.
* 3) Bug fixes: Fixed serveral issues related to removing over-the-limit characters in the form field.
*/

(function ($) {
    var methods = {
        init: function (limit, options) {
            // Start the timer that updates indicator.
            function start() {
                data.timer = setInterval(function () { update(data.limit) }, settings.rate);
            }

            // Stop the timer that updates the indicator.
            function stop() {
                if (data.timer != null) {
                    clearInterval(data.timer);
                    data.timer = null;
                }
            }

            // Update the indicator.
            function update(limit) {
                let currentMessage;
                if ((limit == null) || (limit == 0)) {
                    currentMessage = '';
                } else {
                    var remaining = limit - target.val().length;
                    // Update remaining count and message.
                    if (remaining == 1) {
                        currentMessage = remaining + settings.singularMessage;
                    } else {
                        currentMessage = remaining + settings.pluralMessage;
                    }
                }
                // Update indicator.
                data.indicator.text(currentMessage);
                log(currentMessage);
            }

            function handleFocus() {
                if (data.timer == null) {
                    if (settings.label) {
                        data.indicator.fadeOut(function () { data.indicator.text('') }).fadeIn(function () { start() });
                    } else {
                        start();
                    }
                }
            }

            function handleBlur() {
                // Stop timer that updates count and the indicator message.
                stop();
                // Update view.
                if (settings.label) {
                    data.indicator.fadeOut(function () { data.indicator.text(settings.label) }).fadeIn();
                }
            }

            function truncateFormSubmit() {
                if ((data.limit != null) && (data.limit > 0)) {
                    target.val(target.val().slice(0, data.limit));
                }
            }

            function getIndicator(id) {
                // Get indicator element in the dom.
                var indicator = jQuery('#' + id);
                // If indicator element does not already exist in the dom, create it.
                if (indicator.length == 0) {
                    target.after(spaceBeforeMessage + '<span id="' + id + '"></span>');
                    indicator = jQuery('#' + id)
                }
                // Return reference to indicator element.
                return indicator;
            }

            // Create helper functions.
            function log(message) {
                // Display 
                if (settings.debug) {
                    try {
                        if (console) {
                            console.log(message);
                        }
                    } catch (e) {
                        // Do nothing on error.
                    }
                }
            }

            // Define default settings and override w/ options.	
            let settings = jQuery.extend({
                debug: false,
                indicator: 'indicator',
                label: '',
                pluralMessage: ' remaining',
                rate: 200,
                singularMessage: ' remaining',
                spaceBeforeMessage: ' ',
                truncate: true
            }, options);

            // Get maxChar target element.

            var target = jQuery(this); // Must get target first, since it is used in setting other local variables.

            var data = {
                settings: settings,
                currentMessage: '',
                indicator: getIndicator(settings.indicator),
                limit: limit,
                remaining: limit,
                timer: null
            }
            target.data('maxChar', data);

            if (settings.label) {
                data.indicator.text(settings.label);
            } else {
                update(data.limit);
            }

            // When user focuses on the target element, do the following.
            target.bind('focus.maxchar', handleFocus);

            // When user removes focus from the target element, do the following.
            target.bind('blur.maxchar', handleBlur);

            // Truncate submitted value down to limit on form submit.
            if (settings.truncate) {
                var form_id = '#' + jQuery(this).closest("form").attr("id");
                jQuery(form_id).bind('submit.maxchar', truncateFormSubmit);
            }
        },
        destroy: function () {
            // unbind all events
            jQuery(this).unbind('.maxchar');
            var data = jQuery(this).data('maxChar');
            if (data != null) {
                clearInterval(data.timer);
                data.indicator.text('');
            }
        }
    };

    $.extend($.fn, {
        maxChar: function (method) {
            // Method calling logic
            if (methods[method]) {
                return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
            } else if (typeof method === 'object' || !method) {
                return methods.init.apply(this, arguments);
            } else {
                $.error('Method ' + method + ' does not exist on jQuery.maxChar');
            }
        }
    });

})(jQuery);