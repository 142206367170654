import { sortModes, sortDirections } from "./const.js";
import trkData from "./data.js";

import _ from "lodash";
import $ from "jquery";

// Monolithic data structure to hold application state.
// See also state.js and data.js

const user = {
	isAdmin: false,
	isAnonymous: false,
	showWalkthrough: false,
	canEditAssets: false,
	canAddAssets: false,
	canDeleteAssets: false,
	canEditAssetGroups: false,
	canEditAlerts: false,
	canEditGeofences: false,
	canEditPlaces: false,
	canSendCommands: false,
	canEditJourneys: false,
	canEditSharedViews: false,
	dateFormat: "M/D/YYYY h:mm:ss A",
	hiddenPreferences: {
		fences: [],
		groups: [],
		assets: [],
		places: [],
	},
	displayPreferences: {
		version: 2,
		hideAllAssets: false, // special snowflake
		expandedGroups: [],
		hiddenFences: [],
		hiddenAssets: [],
		hiddenPlaces: [],
		visibleTrips: [],
		customSort: {},
		sortMode: {
			assets: sortModes.ALPHABETICAL,
			places: sortModes.ALPHABETICAL,
			fences: sortModes.ALPHABETICAL,
			journeys: sortModes.ALPHABETICAL,
			sharedViews: sortModes.ALPHABETICAL,
		},
		sortDirection: {
			assets: sortDirections.ASC,
			places: sortDirections.ASC,
			fences: sortDirections.ASC,
			journeys: sortDirections.ASC,
			sharedViews: sortDirections.ASC,
		},
	},
	tickOffset: 0,
};

// Adds/replaces the overrides to the `user` structure. Called from outside tracking.js
// when the permissions of the user are known.
export function overrideUser(overrides) {
	_.merge(user, overrides);
}

export default user;

export function loadDisplayPreferences() {
	if (isStorageAvailable("localStorage")) {
		if (
			localStorage.getItem("displayPreferences") === null &&
			localStorage.getItem("displayPreferences-" + user.id) === null
		) {
			// we may need to convert from cookies
			var hiddenFences = $.cookie("hiddenFences");
			var hiddenAssets = $.cookie("hiddenAssets");
			var hiddenPlaces = $.cookie("hiddenPlaces");
			var hiddenGroups = $.cookie("hiddenGroups");

			// remove old cookies
			if (hiddenFences != null) {
				$.cookie("hiddenFences", null);
			}
			if (hiddenAssets != null) {
				$.cookie("hiddenAssets", null);
			}
			if (hiddenPlaces != null) {
				$.cookie("hiddenPlaces", null);
			}
			if (hiddenGroups != null) {
				$.cookie("hiddenGroups", null);
			}

			if (hiddenFences == null || hiddenFences == "") hiddenFences = [];
			else hiddenFences = hiddenFences.split(",");

			if (hiddenAssets == null || hiddenAssets == "") hiddenAssets = [];
			else hiddenAssets = hiddenAssets.split(",");

			if (hiddenGroups == null || hiddenGroups == "") {
				hiddenGroups = [];
			} else hiddenGroups = hiddenGroups.split(",");

			if (hiddenPlaces == null || hiddenPlaces == "") hiddenPlaces = [];
			else hiddenPlaces = hiddenPlaces.split(",");

			// load new preferences
			user.displayPreferences.hiddenPlaces = hiddenPlaces;
			user.displayPreferences.hiddenFences = hiddenFences;
			user.displayPreferences.hiddenAssets = hiddenAssets;
			user.displayPreferences.visibleTrips = [];
			user.displayPreferences.hideAllAssets = trkData.assets.length >= 200 ? true : false;
			user.displayPreferences.customSort = {};
			user.displayPreferences.sortMode = {
				assets: sortModes.ALPHABETICAL,
				fences: sortModes.ALPHABETICAL,
				places: sortModes.ALPHABETICAL,
				journeys: sortModes.ALPHABETICAL,
				sharedViews: sortModes.ALPHABETICAL,
			};
			user.displayPreferences.sortDirection = {
				assets: sortDirections.ASC,
				fences: sortDirections.ASC,
				places: sortDirections.ASC,
				journeys: sortDirections.ASC,
				sharedViews: sortDirections.ASC,
			};
			user.displayPreferences.warnings = {
				addresses: true,
				speed: true,
			};
			saveDisplayPreferences();
		} else {
			if (localStorage.getItem("displayPreferences-" + user.id) !== null) {
				user.displayPreferences = JSON.parse(localStorage.getItem("displayPreferences-" + user.id));
			} else {
				user.displayPreferences = JSON.parse(localStorage.getItem("displayPreferences"));
			}

			if (user.displayPreferences.hiddenPlaces === undefined) {
				user.displayPreferences.hiddenPlaces = [];
			}
			if (user.displayPreferences.hiddenFences === undefined) {
				user.displayPreferences.hiddenFences = [];
			}
			if (user.displayPreferences.hiddenAssets === undefined) {
				user.displayPreferences.hiddenAssets = [];
			}
			if (user.displayPreferences.expandedGroups === undefined) {
				user.displayPreferences.expandedGroups = [];
			}
			if (user.displayPreferences.hideAllAssets === undefined) {
				user.displayPreferences.hideAllAssets = trkData.assets.length >= 200 ? true : false;
			}
			if (user.displayPreferences.visibleTrips === undefined) {
				user.displayPreferences.visibleTrips = [];
			}
			if (user.displayPreferences.customSort === undefined) {
				user.displayPreferences.customSort = {};
			}
			if (user.displayPreferences.sortMode === undefined) {
				user.displayPreferences.sortMode = {
					assets: sortModes.ALPHABETICAL,
					fences: sortModes.ALPHABETICAL,
					places: sortModes.ALPHABETICAL,
					journeys: sortModes.ALPHABETICAL,
					sharedViews: sortModes.ALPHABETICAL,
				};
			}
			if (user.displayPreferences.sortDirection === undefined) {
				user.displayPreferences.sortDirection = {
					assets: sortDirections.ASC,
					fences: sortDirections.ASC,
					places: sortDirections.ASC,
					journeys: sortDirections.ASC,
					sharedViews: sortDirections.ASC,
				};
			}
			if (user.displayPreferences.warnings === undefined) {
				user.displayPreferences.warnings = {
					addresses: true,
					speed: true,
				};
			}
		}
	}
}

function getDisplayPreferences(grouping) {
	switch (grouping) {
		case "hiddenFences":
			return user.displayPreferences.hiddenFences;
		case "expandedGroups":
			return user.displayPreferences.expandedGroups;
		case "hiddenAssets":
			return user.displayPreferences.hiddenAssets;
		case "hiddenPlaces":
			return user.displayPreferences.hiddenPlaces;
		case "visibleTrips":
			return user.displayPreferences.visibleTrips;
	}
	return null;
}

function setDisplayPreferences(grouping, value) {
	switch (grouping) {
		case "hiddenFences":
			user.displayPreferences.hiddenFences = value;
			break;
		case "expandedGroups":
			user.displayPreferences.expandedGroups = value;
			break;
		case "hiddenAssets":
			user.displayPreferences.hiddenAssets = value;
			break;
		case "hiddenPlaces":
			user.displayPreferences.hiddenPlaces = value;
			break;
		case "visibleTrips":
			user.displayPreferences.visibleTrips = value;
			break;
	}
	return;
}

export function displayPreferencesRemove(grouping, id) {
	//log('Remove hidden preference: ' + grouping + ' -> ' + id);
	var grp = getDisplayPreferences(grouping);

	if (grp == null) return;
	grp = _.without(grp, id.toString());
	setDisplayPreferences(grouping, grp);
	saveDisplayPreferences();
}

export function displayPreferencesAdd(grouping, id) {
	id = id.toString();
	//log('Add hidden preference: ' + grouping + ' -> ' + id);

	var grp = getDisplayPreferences(grouping);

	if (grp == null) return;

	var isFound = grp.indexOf(id) !== -1;
	if (!isFound) {
		grp.push(id);
	}
	setDisplayPreferences(grouping, grp);
	saveDisplayPreferences();
}

export function saveDisplayPreferences() {
	if (isStorageAvailable("localStorage")) {
		localStorage.removeItem("displayPreferences");
		localStorage.setItem("displayPreferences-" + user.id, JSON.stringify(user.displayPreferences));
	}
}

//function saveDisplayPreferences() {
//    $j.cookie('hiddenFences', user.displayPreferences.hiddenFences.join(','), { expires: 365, path: '/', secure: true });
//    $j.cookie('hiddenAssets', user.displayPreferences.hiddenAssets.join(','), { expires: 365, path: '/', secure: true });
//    $j.cookie('hiddenGroups', user.displayPreferences.expandedGroups.join(','), { expires: 365, path: '/', secure: true });
//    $j.cookie('hiddenPlaces', user.displayPreferences.hiddenPlaces.join(','), { expires: 365, path: '/', secure: true });
//}

function isStorageAvailable(type) {
	try {
		var storage = window[type],
			x = "__storage_test__";
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	} catch (e) {
		return (
			e instanceof DOMException &&
			// everything except Firefox
			(e.code === 22 ||
				// Firefox
				e.code === 1014 ||
				// test name field too, because code might not be present
				// everything except Firefox
				e.name === "QuotaExceededError" ||
				// Firefox
				e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
			// acknowledge QuotaExceededError only if there's something already stored
			storage.length !== 0
		);
	}
}
