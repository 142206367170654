<li class="assets-item group-item asset-{{id}} {{serviceClass}} {{visibilityClass}}" data-asset-id="{{id}}">
    <div class="item-header d-flex flex-row align-items-center" style="background-image: url({{icon}});">
        {{#if isVisible}}
        <svg class="list-item-action showhide active" data-asset-id="{{id}}"><use xlink:href="/content/svg/tracking.svg?v=15#visible"></use></svg>
        {{else}}
        <svg class="list-item-action showhide" data-asset-id="{{id}}"><use xlink:href="/content/svg/tracking.svg?v=15#invisible"></use></svg>
        {{/if}}
        <div class="item-info expand mr-auto">
            <div class="asset-name">{{name}}</div>
            <div class="asset-indicators d-flex align-items-center">
                <svg class="notifications" title="@ViewRes.HomeStrings.Positions"><use xlink:href="/content/svg/notifications.svg?v=2#{{notifications.symbol}}"></use></svg>
            </div>
        </div>
        <svg class="list-item-action item-drag"><use xlink:href="/content/svg/tracking.svg?v=15#sort"></use></svg>
        <svg class="list-item-action item-settings"><use xlink:href="/content/svg/tracking.svg?v=15#settings"></use></svg>
    </div>
    <div class="item-details toggle-content">
    </div>
</li>
