import { updateGroupVisibilityStatus } from "./asset-group.js";
import trkData from "./data.js";
import { map } from "./map-base.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import user, { displayPreferencesAdd, displayPreferencesRemove } from "./user.js";
import { updateItemListingIcon } from "./item-listing.js";
import domNodes from "./domNodes.js";
import options from "./options.js";
import { convertHexToSortable, getItemHexColor } from "./color.js";
import { findGroupById } from "./asset-group.js";
import { createMarkerPath } from "./marker-path.js";
import { showMouseTooltip, hideMouseTooltip } from "./mouse-tooltip.js";
import strings from "./strings.js";
import state from "./state.js";
import { devices } from "./devices.js";
import { isItemIncluded } from "./polyfills.js";
import { editFenceSegments } from "./fence-edit.js";
import { sortModes } from "./const.js";
import { markerClick } from "./marker-click.js";
import { includeRowIfNotNull, createAccordionCard } from "./dom-util.js";
import { sortItemsByMode, resortItemGroup, sortByName, toggleItemSorting } from "./item-sorting.js";
import { openDialogPanel } from "./panel-nav.js";
import { getMapLeftOffset } from "./window-layout.js";
import { changePrimaryButtonLabel } from "./modal.js";
import templates from "./templates.js";
import log from "./log.js";
import { closeSecondaryPanel } from "./panel.js";
import { populateCustomAttributes } from "./attributes.js";
import { stopChoosingMapLocation } from "./map-chooselocation.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import L from "leaflet";
import { el, text, setChildren } from "redom"; // https://redom.js.org/

/*global JsSearch, MapToolbar */
// import JsSearch from '../js-search.js';
// import MapToolbar from '../MapToolbar.js';

export function findFenceById(id) {
	var item = _.find(trkData.fences, { Id: id });
	return item === undefined ? null : item;
}

export function toggleFenceActive(fenceId, makeFenceActive, updateUI) {
	var isCurrentlyActive = !isItemIncluded(user.displayPreferences.hiddenFences, fenceId);
	if (makeFenceActive == null) {
		makeFenceActive = !isCurrentlyActive;
	}

	if (isCurrentlyActive === makeFenceActive) {
		return false;
	}

	var fence = findFenceById(fenceId);

	// show/hide fence and its segments on the map
	if (trkData.fenceMarkers != null) {
		for (var i = 0; i < trkData.fenceMarkers.length; i++) {
			var markerFenceId = trkData.fenceMarkers[i].data.fenceId;
			if (markerFenceId == fenceId) {
				if (makeFenceActive) {
					addItemToMap(trkData.fenceMarkers[i]);
				} else {
					removeItemFromMap(trkData.fenceMarkers[i]);
				}
			}
		}
	}

	// change icon and set preferences
	if (makeFenceActive) {
		updateFenceListingIcon(fence, true);
		displayPreferencesRemove("hiddenFences", fenceId);
	} else {
		updateFenceListingIcon(fence, false);
		displayPreferencesAdd("hiddenFences", fenceId);
	}

	// ensure checkbox is proper
	updateFenceVisibilityStatus(fenceId, makeFenceActive);
	if (updateUI) {
		updateGroupVisibilityStatus("all-fences");
	}
}

function updateFenceListingIcon(fence, isActive) {
	var color = getFenceColor(fence);
	updateItemListingIcon(fence.Id, color, isActive, "Fence", domNodes.fences);
}

export function getFenceColor(fence) {
	if (options.useFenceDocuments && fence.IsValid != null && fence.IsAboutToExpire != null) {
		if (!fence.IsValid) {
			return "d10000";
		} else if (fence.IsAboutToExpire) {
			return "eea000";
		} else {
			return "01d125";
		}
	}

	return getItemHexColor(fence);
}

function addFence(fence, groupId) {
	var group = findGroupById(groupId);
	if (group == null) {
		groupId = "all-fences";
	}

	var itemNode = createFenceNode(fence);
	domNodes.fences[fence.Id] = itemNode;
	var groupContentsNode = domNodes.groupContents[groupId].querySelector("ul.group-list-list");

	var groupFences = trkData.fences;
	//if (group != null) {
	//    groupFences = _.filter(groupFences, function (fence) { return _.indexOf(fence.GroupIds, groupId) !== -1; });
	//}
	groupFences = sortItemsByMode("fences", groupFences, groupId, "fences");

	// add it where it belongs instead of at the end
	if (groupFences.length > 1) {
		var itemIndex = _.indexOf(groupFences, fence);
		var subgroups = group !== null ? group.GroupIds.length : 0;
		groupContentsNode.insertBefore(itemNode, groupContentsNode.children[itemIndex + subgroups + 1]); // +1 due to the no-fences first node
	} else {
		groupContentsNode.appendChild(itemNode);
	}
	toggleItemSorting("fences", user.displayPreferences.sortMode.fences === sortModes.CUSTOM);

	if (trkData.fences.length == 0) {
		$("#no-all-fences").addClass("is-visible");
		document.getElementById("filter-fences").querySelector(".filter-box").classList.remove("is-visible");
	} else {
		$("#no-all-fences").removeClass("is-visible");
		document.getElementById("filter-fences").querySelector(".filter-box").classList.add("is-visible");
	}
}

export function updateFence(fence) {
	if (trkData.fences == null) {
		return;
	}

	var oldFence = findFenceById(fence.Id);
	removeFencePaths(fence.Id);
	// replace fence in trkData.fences
	for (var i = 0; i < trkData.fences.length; i++) {
		if (trkData.fences[i].Id == fence.Id) {
			trkData.fences.splice(i, 1, fence);
		}
	}

	trkData.fences = trkData.fences.sort(sortByName);

	var li = domNodes.fences[fence.Id];
	if (li !== undefined) {
		var name = li.querySelector(".fence-name");
		name.textContent = fence.Name;
		var description = li.querySelector(".fence-description");
		description.textContent = fence.Description;
	}

	if (getFenceColor(oldFence) != getFenceColor(fence)) {
		// update color and paths
		fence.ColorSorted = convertHexToSortable(fence.Color);
		var isActive = !isItemIncluded(user.displayPreferences.hiddenFences, fence.Id);
		updateFenceListingIcon(fence, isActive);
	}
	resortItemGroup("all-fences");
	indexFencesForSearch();
}

export function processNewFence(fence) {
	if (trkData.fences == null) {
		return;
	}

	fence.ColorSorted = convertHexToSortable(fence.Color);
	trkData.fences.push(fence);
	// add fence to UI
	addFence(fence);
	addFencePath(fence);
	//addFenceContextMenus(fence.Id);
	toggleFenceActive(fence.Id, true, true);
	updateGroupVisibilityStatus("all-fences");

	$j("#no-all-fences").removeClass("is-visible");
	document.getElementById("filter-fences").querySelector(".filter-box").classList.add("is-visible");
}

function addFencePath(fence) {
	if (fence == null) {
		return;
	}
	var addToMap = !isItemIncluded(user.displayPreferences.hiddenFences, fence.Id);
	var fenceColor = "#" + getFenceColor(fence);
	for (var j = 0; j < fence.Segments.length; j++) {
		var segment = fence.Segments[j];
		switch (segment.Type) {
			case 0: // line
			case "Line":
				var path = [];
				// point order is important
				for (var k = 0; k < segment.Points.length; k++) {
					var point = segment.Points[k];
					var latlng = L.latLng(point.Lat, point.Lng);
					path.push(latlng);
				}
				var line = L.polyline(path, {
					color: fenceColor,
					weight: 4,
				});
				line.data = {
					fenceId: null,
				};
				line.on("mouseover", function (e) {
					//var evt = e.originalEvent;
					//evt.preventDefault = true;
					//$j('#fence-tooltip').tooltip('option', 'position', { of: evt, my: 'left+15 bottom+15', at: 'right center', }).tooltip('option', 'content', fence.Name).tooltip('open');
					showFenceTooltip(fence);
				});
				line.on("mouseout", function (e) {
					//$j('#fence-tooltip').tooltip('close');
					hideMouseTooltip();
				});
				line.on("click", function (e) {
					//map.fire('click', e);
					//fenceClick(fence, e.latlng);
					L.DomEvent.stopPropagation(e);
					markerClick(line, "fence", e.latlng, true);
				});
				if (addToMap) {
					addItemToMap(line);
				}
				line.data.fenceId = fence.Id;
				trkData.fenceMarkers.push(line);
				break;
			case 1: // shape
			case "Shape":
				var path = [];
				for (var k = 0; k < segment.Points.length - 1; k++) {
					// ignore the last point for a polygon as it's the first
					var point = segment.Points[k];
					var latlng = L.latLng(point.Lat, point.Lng);
					path.push(latlng);
				}
				var shape = L.polygon(path, {
					weight: 2,
					color: fenceColor,
					opacity: 0.35,
					fillOpacity: 0.15,
					dashArray: "4",
					pane: "back-lines",
				});
				shape.data = {
					fenceId: null,
				};
				if (addToMap) {
					addItemToMap(shape);
				}
				shape.on("mouseover", function (e) {
					//var evt = e.originalEvent;
					//evt.preventDefault = true;
					//$j('#fence-tooltip').tooltip('option', 'position', { of: evt, my: 'left+15 bottom+15', at: 'right center', }).tooltip('option', 'content', fence.Name).tooltip('open');
					showFenceTooltip(fence);
				});
				shape.on("mouseout", function (e) {
					//$j('#fence-tooltip').tooltip('close');
					hideMouseTooltip();
				});
				//shape.on('mousemove', function (e) {
				//    console.log('shape move');
				//});
				shape.on("click", function (e) {
					//fenceClick(fence, e.latlng);
					L.DomEvent.stopPropagation(e);
					markerClick(shape, "fence", e.latlng, true);
					//markerClick(shape);
				});

				shape.data.fenceId = fence.Id;
				trkData.fenceMarkers.push(shape);
				break;
			case 2: // circle
			case "Circle":
				var center = L.latLng(segment.Center.Lat, segment.Center.Lng);
				var radius = L.latLng(segment.Radius.Lat, segment.Radius.Lng);
				var radiusMeters = center.distanceTo(radius);
				var circle = L.circle(center, radiusMeters, {
					weight: 2,
					color: fenceColor,
					dashArray: "4",
					opacity: 0.35,
					fillOpacity: 0.15,
					interactive: true,
					pane: "back-lines",
				});
				circle.data = {
					fenceId: null,
				};
				circle.on("mouseover", function (e) {
					//  	var evt = e.originalEvent;
					//  	evt.preventDefault = true;
					//$j('#fence-tooltip').tooltip('option', 'position', { of: evt, my: 'left+15 bottom+15', at: 'right center', }).tooltip('option', 'content', fence.Name).tooltip('open');
					showFenceTooltip(fence);
				});
				circle.on("mouseout", function (e) {
					//$j('#fence-tooltip').tooltip('close');
					hideMouseTooltip();
				});
				circle.on("click", function (e) {
					//findAssetIdsInGeofence(fence);
					//fenceClick(fence, e.latlng);
					//e.originalEvent.stopPropagation();
					L.DomEvent.stopPropagation(e);
					markerClick(circle, "fence", e.latlng, true);
				});
				if (addToMap) {
					addItemToMap(circle);
				}
				circle.data.fenceId = fence.Id;
				trkData.fenceMarkers.push(circle);
				break;
			default:
				console.warn("Unknown fence segment type", segment.Type);
				break;
		}
	}
}

function getFenceBounds(fenceId) {
	var fenceBounds = L.latLngBounds();
	var fenceMarkers = _.filter(trkData.fenceMarkers, function (item) {
		return item.data.fenceId === fenceId;
	});
	_.each(fenceMarkers, function (poly) {
		fenceBounds.extend(poly.getBounds());
	});

	if (fenceBounds.isValid()) {
		return fenceBounds;
	}
	return null;
}

export function centerOnFence(fenceId) {
	// calculate bounds from all fence segments
	// center on first fence segment shape (for infobox) instead of center of all
	var fencePolys = [];
	var fenceBounds = L.latLngBounds();
	var singlePolygonCenter = null;
	var fenceMarkers = _.filter(trkData.fenceMarkers, function (item) {
		return item.data.fenceId === fenceId;
	});
	_.each(fenceMarkers, function (poly) {
		fencePolys.push(poly);
		fenceBounds.extend(poly.getBounds());

		if (singlePolygonCenter == null) {
			singlePolygonCenter = poly.getBounds().getCenter();
		}
	});

	if (fenceBounds.isValid()) {
		map.fitBounds(fenceBounds, { padding: [10, 10] });
	}
	//return singlePolygonCenter;
	return { markers: fencePolys, center: singlePolygonCenter };
}

export function addFencePaths() {
	if (trkData.fences == null) {
		return;
	}
	_.each(trkData.fences, function (fence) {
		addFencePath(fence);
	});
}

function removeFencePaths(fenceId) {
	if (trkData.fences == null) {
		return;
	}
	var fence = findFenceById(fenceId);
	if (fence == null) {
		return;
	}
	var after = [];
	for (var i = 0; i < trkData.fenceMarkers.length; i++) {
		var poly = trkData.fenceMarkers[i];
		var polyFenceId = poly.data.fenceId;
		if (polyFenceId == fenceId) {
			// remove poly
			removeItemFromMap(poly);
			poly = null;
		} else {
			after.push(poly);
		}
	}
	trkData.fenceMarkers = after;
}

export function showFenceTooltip(fence) {
	var fenceBounds = getFenceBounds(fence.Id);
	if (fenceBounds !== null) {
		var latLng = L.latLng(fenceBounds.getCenter().lat, fenceBounds.getEast());
		var tooltipPoint = map.latLngToContainerPoint(latLng);
		showMouseTooltip(tooltipPoint.x + getMapLeftOffset(), tooltipPoint.y, fence.Name);
	}
}

export function createFenceNode(fence) {
	if (fence == null) {
		return null;
	}

	var isActive = !isItemIncluded(user.displayPreferences.hiddenFences, fence.Id);
	if (isActive) {
		trkData.visible.fences.push(fence.Id);
	}
	var color = getFenceColor(fence);
	var alpha = isActive ? null : 50;
	var imagePath = createMarkerPath("Fence", color, null, alpha, null, true);

	var fenceDto = {
		id: fence.Id,
		name: fence.Name,
		color: fence.Color,
		description: fence.Description,
		icon: imagePath,
		isVisible: !isItemIncluded(user.displayPreferences.hiddenFences, fence.Id),
		visibilityClass: !isItemIncluded(user.displayPreferences.hiddenFences, fence.Id) ? "active" : "disabled",
	};

	var fragment = templates.geofence(fenceDto);
	return fragment.childNodes[0];

	// var fenceLi = document.createElement("li");
	// fenceLi.className = "fences-item group-item" + (isActive ? " active" : " disabled");
	// fenceLi.setAttribute("data-fence-id", fence.Id);
	// fenceLi.style.cssText = "background-image: url(" + imagePath + ");"; // background-position: 10px -6px; background-repeat: no-repeat;';
	// var fenceShowHide = document.createElement("input");
	// fenceShowHide.setAttribute("type", "checkbox");
	// fenceShowHide.className = "showhide";
	// fenceShowHide.setAttribute("data-fence-id", fence.Id);
	// fenceShowHide.setAttribute("id", "fence-" + fence.Id);
	// if (isActive) {
	// 	fenceShowHide.setAttribute("checked", "checked");
	// }
	// var fenceName = document.createElement("span");
	// fenceName.className = "fence-name";
	// fenceName.setAttribute("for", "fence-" + fence.Id);
	// fenceName.setAttribute("href", "#");
	// fenceName.textContent = fence.Name;
	// //var fenceEdit = document.createElement('div');
	// //fenceEdit.className = 'edit-fence';
	// var fenceEditLink = document.createElement("a");
	// fenceEditLink.setAttribute("href", "#");
	// fenceEditLink.className = "edit-fence t-icon t-icon-context";
	// //var fenceEditIcon = document.createElement('span');
	// //fenceEditIcon.className = 't-icon t-icon-context';
	// //fenceEditLink.appendChild(fenceEditIcon);
	// //fenceEdit.appendChild(fenceEditLink);
	//
	// fenceLi.appendChild(fenceShowHide);
	// fenceLi.appendChild(fenceName);
	// //fenceLi.appendChild(fenceEdit);
	// fenceLi.appendChild(fenceEditLink);
	//
	// return fenceLi;
}

function updateFenceVisibilityStatus(fenceId, makeActive) {
	var index = _.indexOf(trkData.visible.fences, fenceId);
	var fenceNode = domNodes.fences[fenceId];
	var visibilityIcon = fenceNode.querySelector(".showhide");
	if (makeActive) {
		visibilityIcon
			.querySelector("use")
			.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#visible");
		visibilityIcon.classList.add("active");
		if (index === -1) {
			trkData.visible.fences.push(fenceId);
		}
	} else {
		visibilityIcon
			.querySelector("use")
			.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#invisible");
		visibilityIcon.classList.remove("active");
		if (index !== -1) {
			trkData.visible.fences.splice(index, 1);
		}
	}
}

export function openGeofenceDialog(fence) {
	if (fence === null && options.enabledFeatures.indexOf("GEOFENCING") === -1) {
		openUpgradeDialog();
		return;
	}

	var dialog = $(domNodes.dialogs.geofence);
	console.log("opening geofence dialog");
	trkData.validation.geofence.resetForm();
	trkData.validation.geofence.currentForm.reset();

	var serverSideOption = document.getElementById("edit-fence-server-side");
	if (
		_.find(trkData.assets, function (asset) {
			return devices.SKYWAVE_IDP.indexOf(asset.DeviceId) !== -1;
		}) !== undefined
	) {
		serverSideOption.classList.add("is-visible");
	}

	var dialogTitle = fence !== null ? strings.UPDATE_GEOFENCE : strings.ADD_GEOFENCE;
	openDialogPanel(
		domNodes.dialogs.geofence,
		dialogTitle,
		fence,
		false,
		function () {
			// going to call this after crap above
			console.log("closing geofence dialog");
			if (state.isEditingGeofence) {
				// add back the fence paths
				var id = $j("#hfEditFenceId", dialog).val();
				var editedFence = findFenceById(id);
				addFencePath(editedFence);
			}
			cleanupFenceEditing();
		},
		"fence",
		fence !== null ? "edit-fence" : null,
		fence !== null ? openGeofenceDialog : null
	);

	state.isEditingGeofence = fence !== null;

	$j("#add-document-status").text("").hide();
	$j("#edit-geofence-photo").hide();

	var buttonText = state.isEditingGeofence ? strings.SAVE_CHANGES : strings.CREATE_GEOFENCE;
	changePrimaryButtonLabel(domNodes.dialogs.geofence, buttonText);

	populateGeofenceDocuments(fence);

	$("#edit-fence-accordion .primary-card button").prop("disabled", false);

	if (user.isAdmin) {
		var cont = dialog.find(".user-list")[0];

		var inputs = [];
		for (var k = 0; k < trkData.users.length; k++) {
			const user = trkData.users[k];
			var isChecked = false;
			if (fence != null) {
				for (var i = 0; i < trkData.fenceUsers.length; i++) {
					if (trkData.fenceUsers[i].FenceId != fence.Id) continue;

					for (var j = 0; j < trkData.fenceUsers[i].UserIds.length; j++) {
						if (trkData.fenceUsers[i].UserIds[j] == user.Id) {
							isChecked = true;
							break;
						}
					}
					break;
				}
			}
			var checkbox = el("input.custom-control-input", {
				type: "checkbox",
				id: "EditFenceUser" + k,
				name: "EditFenceUserIds",
				value: user.Id,
				checked: isChecked,
			});
			if (isChecked) {
				checkbox.setAttribute("checked", "checked");
			}
			inputs.push(
				el("div.custom-control.custom-checkbox", [
					checkbox,
					text(" "),
					el("label.custom-control-label", { for: "EditFenceUser" + k }, user.Name + " (" + user.Username + ")"),
				])
			);
		}
		setChildren(cont, inputs);
	} else {
		$("#accordion-edit-fence-users-head button").prop("disabled", true);
		$("#accordion-edit-fence-documents-head button").prop("disabled", true);
	}

	$("#edit-geofence-remove-photo").val("false");
	if (!state.isEditingGeofence) {
		$("#accordion-edit-fence-main-content").collapse("show");
		$("#accordion-edit-fence-documents-head button").prop("disabled", true);
	} else {
		// add hidden id
		$j(dialog).data("fenceId", fence.Id);
		$j("#hfEditFenceId").val(fence.Id);
		$j("#txtGeofenceName").val(fence.Name);
		$j("#txtGeofenceColor").val(fence.Color);
		$j("#txtGeofenceDescription").val(fence.Description);
		$j("#ddlFenceContactId").val(fence.ContactId);
		$j("#chkGeofenceServerSide").prop("checked", fence.IsServerSideOnly);
		$j("#txtGeofenceColor").css("background-color", fence.Color);
		$j("#txtGeofenceColor").next("span").css("background-color", fence.Color);

		if (fence.PhotoType != null && fence.PhotoType != "") {
			$j("#edit-geofence-photo img").attr(
				"src",
				"/uploads/images/fences/" + fence.Id + "_thumb." + fence.PhotoType + "?rnd=" + new Date().getTime()
			);
			$j("#edit-geofence-photo a").attr("href", "/uploads/images/fences/" + fence.Id + "." + fence.PhotoType);
			$j("#edit-geofence-photo").show();
		}
		// attribute fields
		if (fence.Attributes != null) {
			for (var i = 0; i < fence.Attributes.length; i++) {
				var attr = fence.Attributes[i];
				$j("#EditGeofenceAttribute" + attr.Id).val(attr.Value);
				// todo: special case for file/photo upload?
			}
		}

		// hide current fence
		editFenceSegments(fence); // populate segments
		MapToolbar.select(null); // clear edit selection
		centerOnFence(fence.Id);
		removeFencePaths(fence.Id);
	}

	document.getElementById("txtGeofenceName").focus();
}

export function populateGeofenceInformation(geofence) {
	var info = [];
	if (geofence.Description !== null && geofence.Description !== "") {
		info.push(el(".map-item-description", geofence.Description));
	}

	var itemPhoto = null;
	if (geofence.PhotoType != null && geofence.PhotoType != "") {
		itemPhoto = el(
			"a",
			{ href: "/uploads/images/fences/" + geofence.Id + "." + geofence.PhotoType, target: "_blank" },
			el("img", {
				src: "/uploads/images/fences/" + geofence.Id + "_thumb." + geofence.PhotoType,
			})
		);
	}

	var fenceInfo = [];
	var generalinformation = includeRowIfNotNull(strings.PHOTO, itemPhoto);
	if (generalinformation != null) {
		fenceInfo.push(
			createAccordionCard(
				"general",
				"geofence-information-accordion",
				strings.GENERAL_FIELDS,
				el("table", el("tbody", generalinformation))
			)
		);
	}
	fenceInfo = _.compact(
		fenceInfo.concat(populateCustomAttributes(geofence.Attributes, 1, "geofence-information-accordion"))
	);
	if (fenceInfo.length > 0) {
		info.push(el("#geofence-information-accordion.position-accordion.mt-1", fenceInfo));
	}
	return el(".markercontent", info);
}

export function findAssetIdsInGeofence(fence) {
	var assets = [];

	for (var i = 0, len = trkData.fenceMarkers.length; i < len; i++) {
		var marker = trkData.fenceMarkers[i];
		if (marker.data.fenceId == fence.Id) {
			assets = assets.concat(findAssetsInsidePolygon(marker));
		}
	}
	return assets;
}

export function deleteFence(fence) {
	// close dialog/settings for fence if currently open
	var panel = domNodes.panels.secondary;
	if (panel.getAttribute("data-group-for") === "fences" && panel.getAttribute("data-item-id") === fence.Id) {
		closeSecondaryPanel();
	}

	if (
		domNodes.infoDialogs.mapItemInformation.data !== undefined &&
		domNodes.infoDialogs.mapItemInformation.data !== null &&
		domNodes.infoDialogs.mapItemInformation.data.fenceId === fence.Id &&
		$(domNodes.infoDialogs.mapItemInformation).dialog("isOpen")
	) {
		$(domNodes.infoDialogs.mapItemInformation).dialog("close");
	}

	// remove fence from UI - currently not grouped
	var fenceNode = domNodes.fences[fence.Id];
	fenceNode.parentNode.removeChild(fenceNode);

	removeFencePaths(fence.Id);

	if (trkData.fenceUsers != null) {
		for (var i = trkData.fenceUsers.length - 1; i >= 0; i -= 1) {
			if (trkData.fenceUsers[i].FenceId == fence.Id) {
				trkData.fenceUsers.splice(i, 1);
			}
		}
	}

	// remove from trkData.fences
	for (var i = 0; i < trkData.fences.length; i++) {
		if (trkData.fences[i].Id == fence.Id) trkData.fences.splice(i, 1);
	}

	displayPreferencesRemove("hiddenFences", fence.Id);
	updateGroupVisibilityStatus("all-fences");

	// if last fence, show no fences
	if (trkData.fences.length == 0) {
		$j("#no-all-fences").addClass("is-visible");
		document.getElementById("filter-fences").querySelector(".filter-box").classList.remove("is-visible");
	}

	indexFencesForSearch();
}

export function indexFencesForSearch() {
	trkData.search.fences = new JsSearch.Search("Id");
	trkData.search.fences.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
	var attributes = ["Name", "Description", "UniqueKey", ["Attributes", "Value"]];
	// todo: location?
	_.each(attributes, function (attribute) {
		trkData.search.fences.addIndex(attribute);
	});
	trkData.search.fences.addDocuments(trkData.fences);
	log("Geofences indexed for search filtering.");
}

function openUpgradeDialog() {
	$j("#upgrade-tracking-modal").modal("show");
}

export function cleanupFenceEditing() {
	// clear out the MapToolbar items
	MapToolbar.removeAllFeatures();
	state.isEditingGeofence = false;
	stopChoosingMapLocation(state.mapClickHandlers.GEOFENCE);
}

export function populateGeofenceDocuments(fence) {
	var docs = $j("#geofence-documents");
	$j("tbody tr", docs).not(".noresults").remove();
	var results = $j("tr.noresults", docs).show();

	if (fence != null) {
		if (fence.Documents != null && fence.Documents.length > 0) {
			results.hide();
			for (var i = 0; i < fence.Documents.length; i++) {
				var doc = fence.Documents[i];
				var row = $j("<tr>")
					.append(
						$j('<td class="center">').append(
							$j("<span>").addClass(doc.IsExpired ? "expired" : doc.IsAboutToExpire ? "abouttoexpire" : "valid")
						)
					)
					.append(
						$j("<td>").append(
							$j('<a target="_blank">')
								.text(doc.Name)
								.attr("href", "/uploads/fences/" + fence.Id + "/" + doc.Filename)
						)
					)
					.append($j("<td>").text(doc.StartDate))
					.append($j("<td>").text(doc.EndDate))
					.append(
						$j('<td class="center">').append(
							$j('<button class="command delete btn btn-outline-danger">')
								.data("docId", doc.Id)
								.data("fenceId", fence.Id)
								.text(strings.DELETE)
						)
					);
				$j("tbody", docs).append(row);
			}
		}
	}
}

function findAssetsInsidePolygon(polygon) {
	var assets = [];
	if (trkData.live.latestPositions == null) {
		return assets;
	}
	var isCircle = polygon instanceof L.Circle;
	var radius = null;
	var circleCenter = null;
	var latLngs = [];
	if (isCircle) {
		circleCenter = polygon.getLatLng();
		radius = polygon.getRadius();
	} else {
		latLngs = polygon.getLatLngs();
	}
	_.each(trkData.live.latestPositions, function (pos) {
		if (pos.Position != null) {
			var latLng = L.latLng(pos.Position.Lat, pos.Position.Lng);
			if (isCircle) {
				// circles check if latlng is within circle radius
				if (Math.abs(circleCenter.distanceTo(latLng)) <= radius) {
					assets.push(pos.AssetId);
				}
			} else {
				// have to use ray casting algorithm for polygons
				if (isLatLngInsidePolygon(latLngs[0], latLng)) {
					assets.push(pos.AssetId);
				}
			}
		}
	});
	return assets;
}

function isLatLngInsidePolygon(bounds, latLng) {
	//https://rosettacode.org/wiki/Ray-casting_algorithm
	var count = 0;
	for (var b = 0; b < bounds.length; b++) {
		var vertex1 = bounds[b];
		var vertex2 = bounds[(b + 1) % bounds.length];
		if (west(vertex1, vertex2, latLng.lng, latLng.lat)) ++count;
	}
	return count % 2;

	/**
	 * @return {boolean} true if (x,y) is west of the line segment connecting A and B
	 */
	function west(A, B, x, y) {
		if (A.lat <= B.lat) {
			if (y <= A.lat || y > B.lat || (x >= A.lng && x >= B.lng)) {
				return false;
			} else if (x < A.lng && x < B.lng) {
				return true;
			} else {
				return (y - A.lat) / (x - A.lng) > (B.lat - A.lat) / (B.lng - A.lng);
			}
		} else {
			return west(B, A, x, y);
		}
	}
}
