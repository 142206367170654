import strings from "./strings.js";
import trkData from "./data.js";
import domNodes from "./domNodes.js";
import state from "./state.js";
import { findAssetById } from "./assets.js";
import { getDisplayFilterForEventType } from "./display-filter.js";
import user, { displayPreferencesAdd, displayPreferencesRemove, loadDisplayPreferences } from "./user.js";
import log from "./log.js";
import { isItemIncluded } from "./polyfills.js";
import { openDialogPanel } from "./panel-nav.js";
import { mapModes } from "./const.js";
import { createListing, defaultListItemSort } from "./item-listing.js";
import { createMarkerPath } from "./marker-path.js";
import options from "./options.js";
import templates from "./templates.js";
import { populateCheckboxList } from "./dom-util.js";
import { changePrimaryButtonLabel } from "./modal.js";
import { populateGroupList } from "./group-list.js";
import { sortItemsByMode, sortByName } from "./item-sorting.js";
import { openAssetGroupSettingsPanel } from "./panel-settings.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import { getDbg } from "./log.js";
import { getBackgroundColorAsHex, convertHexToSortable, convertNamedColorToHex } from "./color.js";
import { getSharedViewLink } from "./shared-view.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";

/*global JsSearch */
// import JsSearch from '../js-search.js';

export function findGroupById(id) {
	var item = _.find(trkData.groups, { Id: id });
	return item === undefined ? null : item;
}

export function getGroupAssetStatus(groupId) {
	var result = {
		items: 0,
		active: 0,
		assets: 0,
		assetIds: [],
		fences: 0,
		fenceIds: [],
		places: 0,
		placeIds: [],
		trips: 0,
		tripIds: [],
		sharedViews: 0,
		sharedViewIds: [],
	};
	var groupContents = domNodes.groupContents[groupId];
	if (groupContents === undefined) {
		return result;
	}

	result.items = groupContents.getElementsByClassName("group-item").length;
	result.active = groupContents.getElementsByClassName("group-item active").length;
	var assets = groupContents.getElementsByClassName("assets-item");
	_.each(assets, function (elem) {
		result.assetIds.push(parseInt(elem.getAttribute("data-asset-id")));
	});
	result.assets = assets.length;
	var places = groupContents.getElementsByClassName("places-item");
	_.each(places, function (elem) {
		result.placeIds.push(parseInt(elem.getAttribute("data-place-id")));
	});
	result.places = places.length;
	var fences = groupContents.getElementsByClassName("fences-item");
	_.each(fences, function (elem) {
		result.fenceIds.push(elem.getAttribute("data-fence-id"));
	});
	result.fences = fences.length;
	var trips = groupContents.getElementsByClassName("journeys-item");
	_.each(trips, function (elem) {
		result.tripIds.push(parseInt(elem.getAttribute("data-trip-id")));
	});
	result.trips = trips.length;
	var sharedViews = groupContents.getElementsByClassName("shared-views-item");
	_.each(sharedViews, function (elem) {
		result.sharedViewIds.push(parseInt(elem.getAttribute("data-shared-view-id")));
	});
	result.sharedViews = sharedViews.length;

	var group = findGroupById(groupId);

	if (group !== null) {
		if (group.GroupIds !== undefined) {
			_.each(group.GroupIds, function (subGroupId) {
				var groupCounts = getGroupAssetStatus(subGroupId);
				result.items += groupCounts.items;
				result.active += groupCounts.active;
				result.assets += groupCounts.assets;
				result.assetIds = _.union(result.assetIds, groupCounts.assetIds);
				result.places += groupCounts.places;
				result.placeIds = _.union(result.placeIds, groupCounts.placeIds);
				result.fences += groupCounts.fences;
				result.fenceIds = _.union(result.fenceIds, groupCounts.fenceIds);
				result.trips += groupCounts.trips;
				result.tripIds = _.union(result.tripIds, groupCounts.tripIds);
				result.sharedViews += groupCounts.sharedViews;
				result.sharedViewIds = _.union(result.sharedViewIds, groupCounts.sharedViewIds);
			});
		}
		group.AssetIds = _.map(result.assetIds, function (assetId) {
			return parseInt(assetId);
		});
	}

	_.each(result.assetIds, function (id) {
		var assetId = parseInt(id);
		var asset = findAssetById(assetId);
		if (asset.ParentGroupIds === undefined) {
			asset.ParentGroupIds = [];
		}
		if (asset.ParentGroupIds.indexOf(groupId) === -1) {
			asset.ParentGroupIds.push(groupId);
		}
	});
	return result;
}

export function updateGroupVisibilityStatus(groupId) {
	var group = findGroupById(groupId);
	var groupNode = document.getElementById("group-" + groupId);
	if (groupNode === null) {
		groupNode = domNodes.groups[groupId];
		if (groupNode === undefined) {
			return;
		}
	}
	var visibilityIcon = groupNode.querySelector(".showhide");
	var assetCounts = getGroupAssetStatus(groupId);
	if (assetCounts.active > 0) {
		if (assetCounts.items === assetCounts.active) {
			visibilityIcon
				.querySelector("use")
				.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#visible");
			visibilityIcon.classList.add("active");
			visibilityIcon.classList.remove("indeterminate");
		} else {
			visibilityIcon
				.querySelector("use")
				.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#indeterminate");
			visibilityIcon.classList.add("indeterminate");
			visibilityIcon.classList.remove("active");
		}
	} else {
		visibilityIcon
			.querySelector("use")
			.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#invisible");
		visibilityIcon.classList.remove("active");
		visibilityIcon.classList.remove("indeterminate");
	}
	var groupCount = groupNode.querySelector(".item-count");
	var groupCountNumber = 0;
	if (groupCount !== null) {
		switch (groupId) {
			case "all-fences":
				groupCountNumber = assetCounts.fenceIds.length;
				break;
			case "all-places":
				groupCountNumber = assetCounts.placeIds.length;
				break;
			case "all-shared-views":
				groupCountNumber = assetCounts.sharedViewIds.length;
				break;
			default:
				if (groupId.indexOf("journey-") !== -1) {
					groupCountNumber = assetCounts.tripIds.length;
				} else {
					groupCountNumber = assetCounts.assetIds.length;
				}
				break;
		}
		groupCount.textContent = groupCountNumber;
		if (groupCountNumber >= 1000) {
			groupCount.classList.add("large");
		} else {
			groupCount.classList.remove("large");
		}
	}
	if (group == null) {
		return;
	}
	if (group.ParentGroupId != null) {
		updateGroupVisibilityStatus(group.ParentGroupId);
	}
}

export function openEventsForGroup(group) {
	var events = getFilteredEventsForGroup(group.Id, getDisplayFilterForEventType("events"));
	createListing(events, "events");
	openDialogPanel(
		domNodes.dialogs.assetEvents,
		strings.EVENTS,
		group,
		false,
		null,
		"group",
		"group-events",
		openEventsForGroup
	);
}

export function openStatusForGroup(group) {
	var events = getFilteredEventsForGroup(group.Id, getDisplayFilterForEventType("status"));
	createListing(events, "status");
	openDialogPanel(
		domNodes.dialogs.assetStatus,
		strings.STATUS,
		group,
		false,
		null,
		"group",
		"group-status",
		openStatusForGroup
	);
}

export function openAlertsForGroup(group) {
	if (user.isAnonymous) {
		return;
	}
	var events = getFilteredEventsForGroup(group.Id, getDisplayFilterForEventType("alerts"));
	createListing(events, "alerts");
	openDialogPanel(
		domNodes.dialogs.assetAlerts,
		strings.ALERTS,
		group,
		false,
		null,
		"group",
		"group-alerts",
		openAlertsForGroup
	);
}

export function addAssetToGroup(asset, groupId) {
	var group = findGroupById(groupId);
	if (group == null) {
		groupId = "all-assets";
	}

	var groupContentsNode = domNodes.groupContents[groupId].querySelector("ul.group-list-list");

	// assetNode may not be created
	var groupAssetNode;
	if (domNodes.assets[asset.Id] === undefined) {
		groupAssetNode = createAssetNode(asset);
		domNodes.assets[asset.Id] = [];
	} else {
		groupAssetNode = domNodes.assets[asset.Id][0].cloneNode(true);
	}
	domNodes.assets[asset.Id].push(groupAssetNode);

	// find the appropriate point in the groups asset list to add the item
	var groupAssets = trkData.assets;
	if (group != null) {
		groupAssets = _.filter(groupAssets, function (asset) {
			return _.indexOf(asset.GroupIds, groupId) !== -1;
		});
	}
	groupAssets = sortItemsByMode("assets", groupAssets, groupId, "assets");
	if (groupAssets.length > 1) {
		var assetIndex = _.indexOf(groupAssets, asset);
		var subgroups = group !== null ? group.GroupIds.length : 0;
		groupContentsNode.insertBefore(groupAssetNode, groupContentsNode.children[assetIndex + subgroups]);
	} else {
		groupContentsNode.appendChild(groupAssetNode);
	}
	updateGroupVisibilityStatus(groupId);

	document.getElementById("filter-assets").querySelector(".filter-box").classList.add("is-visible");
}

export function removeAssetFromGroup(asset, groupId) {
	// remove assetNode from group contents and cache
	var groupContents = domNodes.groupContents[groupId];
	if (groupContents === undefined) {
		return;
	}

	groupContents = groupContents.querySelector("ul.group-list-list");
	if (groupContents === null) {
		return;
	}

	var foundIndex = -1;
	_.each(domNodes.assets[asset.Id], function (assetNode, index) {
		foundIndex = _.indexOf(groupContents.childNodes, assetNode);
		if (foundIndex !== -1) {
			groupContents.removeChild(assetNode);
			assetNode = null;
			return;
		}
	});

	if (foundIndex !== -1) {
		domNodes.assets[asset.Id].splice(foundIndex, 1);
	}

	updateGroupVisibilityStatus(groupId);
}

function expandGroup(groupId) {
	var groupContainer = domNodes.groups[groupId];
	// render the group contents
	if (groupId === "all-assets") {
		user.displayPreferences.hideAllAssets = false;
	}
	displayPreferencesAdd("expandedGroups", groupId);
	groupContainer.appendChild(domNodes.groupContents[groupId]);
	if (groupId === "all-fences" || groupId === "all-places") {
		if (domNodes.groupContents[groupId].childNodes.length === 0) {
			var noExtraItems = groupContainer.querySelector("#no-" + groupId);
			noExtraItems.classList.add("is-visible");
		}
	}
	toggleGroupExpandedIcon(groupId, true);
}

function collapseGroup(groupId) {
	var groupContainer = domNodes.groups[groupId];
	// remove the group contents
	if (groupId === "all-assets") {
		user.displayPreferences.hideAllAssets = true;
	}
	displayPreferencesRemove("expandedGroups", groupId);
	groupContainer.removeChild(domNodes.groupContents[groupId]);
	if (groupId === "all-fences" || groupId === "all-places") {
		var noItems = groupContainer.querySelector("#no-" + groupId);
		if (noItems !== null) {
			noItems.classList.remove("is-visible");
		}
	}
	toggleGroupExpandedIcon(groupId, false);
}

export function toggleGroupExpanded(groupId) {
	if (isItemIncluded(user.displayPreferences.expandedGroups, groupId)) {
		collapseGroup(groupId);
	} else {
		expandGroup(groupId);
	}
}

export function toggleGroupExpandedIcon(groupId, isVisible) {
	var group = domNodes.groups[groupId];
	if (group === undefined) {
		return;
	}
	const toggleIcon = group.querySelector(".group-toggle");
	if (toggleIcon === null) {
		return;
	}
	if (isVisible) {
		group.classList.add("is-expanded");
		toggleIcon
			.querySelector("use")
			.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#folder-open-solid");
		toggleIcon.classList.add("active");
	} else {
		group.classList.remove("is-expanded");
		toggleIcon
			.querySelector("use")
			.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#folder-solid");
		toggleIcon.classList.remove("active");
	}
}

export function indexAssetGroupsForSearch() {
	trkData.search.assetGroups = new JsSearch.Search("Id");
	trkData.search.assetGroups.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
	var attributes = ["Name", "DestinationId"];
	_.each(attributes, function (attribute) {
		trkData.search.assetGroups.addIndex(attribute);
	});
	trkData.search.assetGroups.addDocuments(trkData.groups);
	log("Asset Groups indexed for search filtering.");
}

function getFilteredEventsForGroup(groupId, filter) {
	var status = getGroupAssetStatus(groupId);
	var events = [];
	var eventsSource =
		state.activeMapMode === mapModes.HISTORY
			? trkData.history.normalizedEventsByAssetId
			: trkData.live.normalizedEventsByAssetId;
	_.each(status.assetIds, function (assetId) {
		events = events.concat(_.filter(eventsSource[assetId], filter));
	});
	events = _.sortBy(events, defaultListItemSort).reverse();
	return events;
}

export function createAssetNode(asset) {
	if (asset == null) return null;
	const isActive = !isItemIncluded(user.displayPreferences.hiddenAssets, asset.Id);
	if (isActive) {
		if (_.indexOf(trkData.visible.assets, asset.Id) === -1) {
			trkData.visible.assets.push(asset.Id);
		}
	}
	const alpha = isActive ? null : 50;

	const assetDto = {
		id: asset.Id,
		name: asset.Name,
		color: asset.Color,
		icon: createMarkerPath(asset.Class, asset.Color, null, alpha, asset.Id, true),
		isVisible: !isItemIncluded(user.displayPreferences.hiddenAssets, asset.Id),
		visibilityClass: !isItemIncluded(user.displayPreferences.hiddenAssets, asset.Id) ? "active" : "disabled",
		serviceClass: asset.IsOutOfService ? "noservice" : "",
		location: {
			address: null,
			latitude: null,
			longitude: null,
			time: null,
			speed: null,
		},
		notifications: {
			symbol: user.isAnonymous
				? options.allowAnonymousMessaging
					? "notifications-sh"
					: "notifications-sh-nc"
				: "notifications",
			alerts: 0,
			events: 0,
			status: 0,
			messages: 0,
		},
	};

	const fragment = templates.asset(assetDto);
	return fragment.childNodes[0];

	// var device = trkData.devicesById[asset.DeviceId];
	//
	// var assetDto = {
	// 	Id: asset.Id,
	// 	Name: asset.Name,
	// 	ServiceClass: asset.IsOutOfService ? " noservice" : "",
	// 	SupportsMessaging: device.SupportsMessaging && !user.isAnonymous,
	// 	ImagePath: createMarkerPath(asset.Class, asset.Color, null, alpha, asset.Id, true),
	// 	IsActive: isActive,
	// };
	//
	// var mailbox = document.createDocumentFragment();
	// if (assetDto.SupportsMessaging) {
	// 	//<div class="mailbox"><a href="#"><span class="t-icon t-icon-mail"></span></a></div>
	// 	//mailbox = document.createElement('div');
	// 	//mailbox.className = 'mailbox';
	// 	var box = document.createElement("a");
	// 	box.setAttribute("href", "#");
	// 	box.className = "mailbox t-icon t-icon-mail";
	// 	//var icon = document.createElement('span');
	// 	//icon.className = 't-icon t-icon-mail';
	// 	//link.appendChild(icon);
	// 	mailbox.appendChild(box);
	// }
	// var serviceClass = "";
	// if (asset.IsOutOfService) {
	// 	serviceClass = " noservice";
	// }
	//
	// var assetLi = document.createElement("li");
	// assetLi.className =
	// 	"assets-item group-item asset-" +
	// 	assetDto.Id +
	// 	assetDto.ServiceClass +
	// 	(!assetDto.IsActive ? " disabled" : " active");
	// assetLi.setAttribute("data-asset-id", assetDto.Id);
	// assetLi.style.cssText = "background-image: url(" + assetDto.ImagePath + ");";
	// var assetShowHide = document.createElement("input");
	// assetShowHide.setAttribute("type", "checkbox");
	// assetShowHide.className = "showhide";
	// assetShowHide.setAttribute("data-asset-id", assetDto.Id);
	// if (assetDto.IsActive) {
	// 	assetShowHide.setAttribute("checked", "checked");
	// }
	// var assetName = document.createElement("a");
	// assetName.className = "asset-name";
	// assetName.setAttribute("for", "asset-" + assetDto.Id);
	// assetName.setAttribute("href", "#");
	// assetName.textContent = assetDto.Name;
	// //var assetTreeControl = document.createElement('div');
	// //assetTreeControl.className = 'tree-control toggle-content is-visible';
	// var assetTreeControlLink = document.createElement("a");
	// assetTreeControlLink.setAttribute("href", "#");
	// assetTreeControlLink.className = "tree-toggle show recent-positions-no toggle-content";
	// //var assetEdit = document.createElement('div');
	// //assetEdit.className = 'edit';
	// var assetEditLink = document.createElement("a");
	// assetEditLink.setAttribute("href", "#");
	// assetEditLink.className = "edit t-icon t-icon-context";
	// //var assetEditIcon = document.createElement('span');
	// //assetEditIcon.className = 't-icon t-icon-context';
	// var assetIndicators = document.createElement("div");
	// assetIndicators.className = "indicators";
	//
	// //assetEditLink.appendChild(assetEditIcon);
	// //assetEdit.appendChild(assetEditLink);
	//
	// //assetTreeControl.appendChild(assetTreeControlLink);
	// assetIndicators.appendChild(mailbox);
	//
	// assetLi.appendChild(assetShowHide);
	// assetLi.appendChild(assetName);
	// //assetLi.appendChild(assetTreeControl);
	// assetLi.appendChild(assetTreeControlLink);
	// //assetLi.appendChild(assetEdit);
	// assetLi.appendChild(assetEditLink);
	// assetLi.appendChild(assetIndicators);
	//
	// return assetLi;
}

export function populateAssetGroupDialog(assetGroup) {
	// populate fields with assetGroup information
	$("#txtGroupName").val(assetGroup.Name);
	$("#txtGroupDestinationId").val(assetGroup.DestinationId);
	$("#txtColor").val(assetGroup.Color);
	$("#txtColor").css("background-color", assetGroup.Color);
	$("#txtColor").next("span").css("background-color", assetGroup.Color);
	$("#EditAssetGroupAllowChat").prop("checked", assetGroup.IsChatEnabled);
	$("#EditAssetGroupAllowLocationSharing").prop("checked", assetGroup.IsLocationSharingEnabled);
}

export function openAssetGroupDialog(assetGroup) {
	if (options.enabledFeatures.indexOf("UI_GROUPS") === -1) {
		return;
	}

	var dialog = domNodes.dialogs.editGroup;
	state.isEditingAssetGroup = assetGroup !== null;

	var buttonText = state.isEditingAssetGroup ? strings.SAVE_CHANGES : strings.CREATE_GROUP;
	changePrimaryButtonLabel(domNodes.dialogs.editGroup, buttonText);

	if (!state.isEditingAssetGroup) {
		$("#accordion-edit-asset-group-main-content").collapse("show");
	}

	trkData.validation.addGroup.resetForm();
	trkData.validation.addGroup.currentForm.reset();

	populateGroupList();
	var dialog = $j(domNodes.dialogs.editGroup);
	$("#edit-asset-group-accordion .primary-card button").removeClass("disabled");
	var dialogTitle = strings.ADD_ASSET_GROUP;
	if (state.isEditingAssetGroup) {
		$j("#asset-group-parent").hide();
		dialogTitle = strings.EDIT_ASSET_GROUP;

		$j(dialog).data("groupId", assetGroup.Id);
	} else {
		$j("#asset-group-parent").show();
	}

	if (!user.isAdmin) {
		$("#accordion-edit-asset-group-users-head button").addClass("disabled");
	}

	// checkbox lists...
	var userList = user.isAdmin ? trkData.users : [];
	populateCheckboxList(
		"edit-asset-group-users-list",
		userList,
		"EditAssetGroupUserIds",
		function (item) {
			if (assetGroup === null) {
				return false;
			}
			var itemUsers = _.find(trkData.assetGroupUsers, { AssetGroupId: assetGroup.Id });
			if (itemUsers === undefined) {
				return false;
			}
			return _.indexOf(itemUsers.UserIds, item.Id) !== -1;
		},
		function (item) {
			return item.Name;
		},
		"users",
		function (item) {
			return item.Username;
		}
	);
	populateCheckboxList(
		"edit-asset-group-assets-list",
		trkData.assets,
		"EditAssetGroupAssetIds",
		function (item) {
			if (assetGroup === null) {
				return false;
			}
			return _.indexOf(item.GroupIds, assetGroup.Id) !== -1;
		},
		function (item) {
			return item.Name;
		},
		"assets",
		function (item) {
			return item.UniqueId;
		}
	);

	if (assetGroup !== null) {
		populateAssetGroupDialog(assetGroup);
	}

	openDialogPanel(
		domNodes.dialogs.editGroup,
		dialogTitle,
		assetGroup,
		false,
		null,
		"group",
		assetGroup !== null ? "edit-group" : null,
		assetGroup !== null ? openAssetGroupDialog : null
	);
	document.getElementById("txtGroupName").focus();
}

export function createGroupNode(group, type) {
	var groupDto = {
		id: group.Id,
		isFavorite: false,
		isVisible: isItemIncluded(user.displayPreferences.expandedGroups, group.Id),
		color: group.Color,
		name: group.Name,
		description: "",
		type: type,
		notifications: {
			symbol: user.isAnonymous
				? options.allowAnonymousMessaging
					? "notifications-sh"
					: "notifications-sh-nc"
				: "notifications",
		},
	};
	var fragment = templates.assetGroup(groupDto);
	var item = fragment.childNodes[0];
	var noSettingsGroups = ["all-fences", "all-places", "all-shared-views"];
	if (_.indexOf(noSettingsGroups, group.Id) !== -1) {
		var groupSettings = item.querySelector(".item-settings");
		groupSettings.parentNode.removeChild(groupSettings);
	}
	if (type !== "assets" && type !== "journeys") {
		var groupIndicators = item.querySelector(".asset-indicators");
		groupIndicators.parentNode.removeChild(groupIndicators);
	}
	return item;
}

export function addAssetGroup(group, groupAssetIds) {
	var groupNode = createGroupNode(group, "assets");
	var groupContentsNode = groupNode.querySelector(".group-contents");
	var groupContentsItems = groupContentsNode.querySelector("ul.group-list-list");

	// add assets for group
	var groupAssets = _.filter(trkData.assets, function (asset) {
		return _.indexOf(groupAssetIds, asset.Id) !== -1;
	}).sort(sortByName);
	_.each(groupAssets, function (asset) {
		asset.GroupIds.push(group.Id);
		var groupAssetNode = domNodes.assets[asset.Id][0].cloneNode(true);
		domNodes.assets[asset.Id].push(groupAssetNode);
		groupContentsItems.appendChild(groupAssetNode);
	});

	// cache group content nodes so they can be detached/reattached to the dom
	// based on whether the group is expanded/visible
	domNodes.groups[group.Id] = groupNode;
	domNodes.groupContents[group.Id] = groupContentsNode;

	// add group to appropriate place
	var isGroupAdded = false;
	if (group.ParentGroupId != null) {
		var parentGroup = findGroupById(group.ParentGroupId);
		if (parentGroup !== null) {
			var parentGroupContents = domNodes.groupContents[parentGroup.Id];
			// place in appropriate order within group's subgroups
			parentGroup.GroupIds.push(group.Id);
			if (parentGroup.GroupIds.length == 1) {
				parentGroupContents.insertBefore(groupNode, parentGroupContents.firstChild);
			} else {
				var subGroups = _.filter(trkData.groups, function (item) {
					return _.indexOf(parentGroup.GroupIds, item.Id) !== -1;
				}).sort(sortByName);
				var groupIndex = _.indexOf(subGroups, group);
				parentGroupContents.insertBefore(groupNode, parentGroupContents.children[groupIndex]);
			}
		}
	} else {
		// add to root groups listing
		var assetGroupsContainer = document.getElementById("assets-all"); //.querySelector('ul.group-list');
		var subGroups = _.filter(trkData.groups, function (item) {
			return item.ParentGroupId == null;
		}).sort(sortByName);
		if (subGroups.length > 1) {
			var groupIndex = _.indexOf(subGroups, group);
			assetGroupsContainer.insertBefore(groupNode, assetGroupsContainer.children[groupIndex + 1]);
		} else {
			assetGroupsContainer.insertBefore(groupNode, assetGroupsContainer.children[1]);
		}
	}
	domNodes.groupColors[group.Id] = group.Color;
	updateGroupVisibilityStatus(group.Id);
	createGroupColorStyles();
	//if (group == null)
	//    return;
	//var li = createAssetGroupNode(group);
	//if (group.ParentGroupId == null) {
	//    // root group
	//    var geofences = document.getElementById('all-fences');
	//    geofences.parentNode.insertBefore(li, geofences);
	//} else {
	//    // find parent and place under
	//    var parent = document.getElementById('group-' + group.ParentGroupId).querySelector('ul');
	//    parent.appendChild(li);
	//}

	//domNodes.groups[group.Id] = li;

	//// sub groups
	//for (var j = 0; j < group.Groups.length; j++) {
	//    renderGroup(group.Groups[j]);
	//}
}

export function deleteAssetGroup(deleted) {
	// remove group from UI
	var li = $j("#group-" + deleted.Id);
	li.remove();

	// remove group from trkData.groups
	for (var i = 0; i < trkData.groups.length; i++) {
		if (trkData.groups[i].Id == deleted.Id) {
			trkData.groups.splice(i, 1);
			break;
		}
	}
	trkData.groupsById = _.keyBy(trkData.groups, "Id");

	var modifiedGroupIds = [];
	_.each(trkData.groups, function (group) {
		if (_.indexOf(group.GroupIds, deleted.Id)) {
			group.GroupIds = _.without(group.GroupIds, deleted.Id);
			modifiedGroupIds.push(group.Id);
		}
	});

	if (trkData.assetGroupUsers != null) {
		for (var i = trkData.assetGroupUsers.length - 1; i >= 0; i -= 1) {
			if (trkData.assetGroupUsers[i].AssetGroupId == deleted.Id) {
				trkData.assetGroupUsers.splice(i, 1);
			}
		}
	}

	// remove group id from all asset's groupIds listings
	for (var i = 0; i < trkData.assets.length; i++) {
		var asset = trkData.assets[i];
		for (var j = 0; j < asset.GroupIds.length; j++) {
			if (asset.GroupIds[j] == deleted.Id) {
				asset.GroupIds.splice(j, 1);
				break;
			}
		}
	}

	// remove group expanded preference
	displayPreferencesRemove("expandedGroups", deleted.Id);

	// update group status for parent groups
	_.each(modifiedGroupIds, function (groupId) {
		updateGroupVisibilityStatus(groupId);
	});
}

export function updateAssetGroup(updated) {
	// if name/color updated, then update the live values
	// replacing all references for asset's groups
	for (var i = 0; i < trkData.groups.length; i++) {
		if (trkData.groups[i].Id === updated.Id) {
			var group = trkData.groups[i];
			group.Name = updated.Name;
			group.Color = updated.Color;
			group.ColorSorted = convertHexToSortable(group.Color);
		}
	}

	// find li for group and update name/color
	// group could be hidden so search domNodes
	domNodes.groupColors[updated.Id] = updated.Color;

	// may also be open in secondaryPanel, so refresh in case name/color was updated
	if (
		domNodes.panels.secondary.getAttribute("data-group-for") === "groups" &&
		domNodes.panels.secondary.getAttribute("data-item-id") === updated.Id
	) {
		openAssetGroupSettingsPanel(updated);
	}

	var li = domNodes.groups[updated.Id];
	if (li !== undefined) {
		li.style.borderColor = updated.Color;
		var name = li.querySelector(".group-name");
		name.textContent = updated.Name;
	}

	createGroupColorStyles();
	//var li = $j('#group-' + updated.Id).eq(0);
	//if (li != null) {
	//    //li.css('background-color', updated.Color);
	//    li.css('border-color', updated.Color);
	//    $j('a.group span', li).text(updated.Name);
	//}
}

export function createGroupColorStyles() {
	var existingStyle = document.getElementById("group-colors-style");
	var colors = "";
	_.each(domNodes.groupColors, function (elem, index, list) {
		//colors += '#group-' + index + ' .group-color, #group-' + index + ' > .list-item-action.active { color: ' + elem + '; border-color: ' + elem + '; }' + "\n";
		//colors += '#group-' + index + ' > .group-header, #group-' + index + ' > .group-header > .list-item-action.active { color: ' + elem + '; border-color: ' + elem + '; }' + "\n";
		//colors += '#group-' + index + ' .group-color { color: ' + elem + '; border-color: ' + elem + '; }' + "\n";
		//colors += '#group-' + index + ' > .group-header { color: ' + elem + '; border-color: ' + elem + '; }' + "\n";
		colors += "#group-" + index + " > .group-header { border-color: " + elem + "; }" + "\n";
		//colors += '#group-' + index + ' .list-item-action.showhide.active { color: ' + elem + '; }' + "\n";
	});
	if (existingStyle === null) {
		var groupColors = document.createElement("style");
		groupColors.id = "group-colors-style";
		groupColors.type = "text/css";
		groupColors.innerHTML = colors;
		document.getElementsByTagName("head")[0].appendChild(groupColors);
	} else {
		existingStyle.innerHTML = colors;
	}
}

export function indexCustomAttributes(attributes) {
	return _.map(attributes, function (attribute) {
		return attribute.Value;
	});
}

export function queryGroupsAndAssets() {
	toggleLoadingMessage(true, "assetGroups");
	var data = {
		dbg: getDbg(),
	};
	return $.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetGroupsAndAssetsReq"),
		data: JSON.stringify(data),
		contentType: "application/json",
		dataType: "json",
	})
		.done(function (msg) {
			if (!msg.d) {
				return;
			}
			toggleLoadingMessage(false, "assetGroups");
			var result = msg.d;
			trkData.devices = result.Devices;
			trkData.devicesById = _.keyBy(trkData.devices, "Id");
			trkData.assets = result.Assets;
			_.each(trkData.assets, function (asset) {
				asset.ColorSorted = convertHexToSortable(convertNamedColorToHex(asset.Color));
				asset.Tags = indexCustomAttributes(asset.Attributes);
			});
			trkData.assetsById = _.keyBy(trkData.assets, "Id");
			trkData.fences = result.Fences;
			_.each(trkData.fences, function (fence) {
				fence.ColorSorted = convertHexToSortable(fence.Color);
			});
			trkData.fencesById = _.keyBy(trkData.fences, "Id");
			trkData.sharedViews = result.SharedViews;
			_.each(trkData.sharedViews, function (sharedView) {
				sharedView.ColorSorted = convertHexToSortable(sharedView.Color);
				sharedView.Link = getSharedViewLink(sharedView); // TODO remove this
			});
			trkData.sharedViewsById = _.keyBy(trkData.sharedViews, "Id");
			trkData.places = result.Places;
			_.each(trkData.places, function (place) {
				place.ColorSorted = convertHexToSortable(convertNamedColorToHex(place.Color));
			});
			trkData.placesById = _.keyBy(trkData.places, "Id");
			trkData.journeys = result.Journeys;
			_.each(trkData.journeys, function (journey) {
				_.each(journey.Trips, function (trip) {
					trip.JourneyId = journey.Id;
				});
			});
			trkData.journeysById = _.keyBy(trkData.journeys, "Id");
			trkData.waypoints = result.Waypoints;
			trkData.driverStatuses = result.DriverStatuses;
			trkData.attributes = result.Attributes;
			trkData.attributeGroups = result.AttributeGroups;
			_.each(result.Groups, function (group) {
				group.Groups = [];
				group.GroupIds = [];
				group.Type = "assets";
			});
			trkData.groups = result.Groups;
			var allAssetsGroup = {
				Id: "all-assets",
				Name: strings.ALL_ASSETS,
				Color: getBackgroundColorAsHex(document.getElementById("nav-toggle")), // todo: pass default colors in here, or extract from CSS value? window.getComputedStyle(document.getElementById('group-all-assets)).borderLeftColor // RBG
				ParentGroupId: null,
				GroupIds: [],
				Groups: [],
				AssetIds: [],
				DestinationId: "",
				IsDefault: true,
				Type: "assets",
			};
			trkData.groups.splice(0, 0, allAssetsGroup);
			_.each(trkData.groups, function (group) {
				group.ColorSorted = convertHexToSortable(group.Color);
			});
			trkData.groupsById = _.keyBy(trkData.groups, "Id");

			// load preferences for asset/group/fence UI show/hide
			loadDisplayPreferences();
		})
		.fail(function (xhr, status, error) {
			handleWebServiceError(strings.MSG_QUERY_GROUPS_ERROR);
			toggleLoadingMessage(false, "assetGroups");
		});
}
