import domNodes from "./domNodes.js";
import trkData from "./data.js";
import { map } from "./map-base.js";

import $ from "jquery";

export function getMapLeftOffset() {
	if (domNodes.content.base.classList.contains("is-collapsed")) {
		return 770;
	} else if (domNodes.content.base.classList.contains("is-expanded")) {
		return 50;
	} else {
		return 370;
	}
}

export function resizeApp(isMapUpdated) {
	var height = $(window).height();
	//var topBarHeight = $j('#topbar').height();
	//var topOffset = 60 + topBarHeight;
	if ($("#nav-header").is(":visible")) {
		height -= 82;
	}
	if ($("#event-panel-container").is(":visible")) {
		height -= $("#event-panel-container").height() + 4; // 4 = padding not accounted for in .height() for some reason
	}

	var resizeHeight = height;

	// only resize the rest of the app if the height has actually changed
	if (resizeHeight === trkData.currentHeight) {
		if (isMapUpdated === true) {
			map.invalidateSize();
		}
		return;
	}

	//if ((user.isAnonymous) || (options.disableHeader)) {
	//    // if we are hiding the header, then re-adjust the height accordingly
	//    if ($j('#head').is(':hidden')) {
	//        resizeHeight += 60;
	//    }
	//}

	//#panel, #spsizer, #map

	// BUG: if collapsed in mobile, then we have nav-utility and nav-header above the map
	$("div.set-height").height(resizeHeight);
	$(domNodes.infoDialogs.mapItemInformation).dialog("option", "maxHeight", resizeHeight - 80);
	$(domNodes.infoDialogs.positionHistory).dialog("option", "maxHeight", resizeHeight - 80);
	$(domNodes.infoDialogs.sharedViewInformation).dialog("option", "maxHeight", resizeHeight - 80);

	trkData.currentHeight = resizeHeight;

	if (isMapUpdated) {
		map.invalidateSize();
	}
}
