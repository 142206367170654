import { handleWebServiceError } from "./ajax.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import strings from "./strings.js";
import trkData from "./data.js";
import { wrapUrl } from "./wrapurl.js";
import { intervals } from "./timers.js";

import $j from "jquery";
const Cookies = window.Cookies; // from 'js.cookie.js', v2.2.0
import L from "leaflet";

function wrapLat(lat) {
	if (lat > 90) {
		lat = -90 + (lat - 90);
	}
	if (lat < -90) {
		lat = 90 - (-90 - lat);
	}
	return lat;
}

function wrapLng(lng) {
	if (lng > 180) {
		lng = -180 + (lng - 180);
	}
	if (lng < -180) {
		lng = 180 - (-180 - lng);
	}
	return lng;
}

export function enableLayerIridiumNext() {
	if (trkData.iridiumnext.isActive) return;
	trkData.iridiumnext.isActive = true;
	startSatelliteOverlay("IridiumNext");
	$j("#map-layers-list .iridiumnext").addClass("active");
	Cookies.set("layer-iridiumnext", true, { expires: 365, path: "/", secure: true });
}

export function enableLayerGlobalstar() {
	if (trkData.globalstar.isActive) return;
	trkData.globalstar.isActive = true;
	startSatelliteOverlay("Globalstar");
	$j("#map-layers-list .globalstar").addClass("active");
	Cookies.set("layer-globalstar", true, { expires: 365, path: "/", secure: true });
}

export function enableLayerInmarsat() {
	if (trkData.inmarsat.isActive) return;
	trkData.inmarsat.isActive = true;
	startSatelliteOverlay("Inmarsat");
	$j("#map-layers-list .inmarsat").addClass("active");
	Cookies.set("layer-inmarsat", true, { expires: 365, path: "/", secure: true });
}

export function enableLayerOrbcomm() {
	if (trkData.orbcomm.isActive) return;
	trkData.orbcomm.isActive = true;
	startSatelliteOverlay("Orbcomm");
	$j("#map-layers-list .orbcomm").addClass("active");
	Cookies.set("layer-orbcomm", true, { expires: 365, path: "/", secure: true });
}

export function enableLayerGeostationary() {
	if (trkData.geostationary.isActive) return;
	trkData.geostationary.isActive = true;
	startSatelliteOverlay("Geostationary");
	$j("#map-layers-list .geostationary").addClass("active");
	Cookies.set("layer-geostationary", true, { expires: 365, path: "/", secure: true });
}

export function disableLayerIridiumNext() {
	if (!trkData.iridiumnext.isActive) return;
	trkData.iridiumnext.isActive = false;
	stopSatelliteOverlay("IridiumNext");
	$j("#map-layers-list .iridiumnext").removeClass("active");
	Cookies.remove("layer-iridiumnext");
}

export function disableLayerGlobalstar() {
	if (!trkData.globalstar.isActive) return;
	trkData.globalstar.isActive = false;
	stopSatelliteOverlay("Globalstar");
	$j("#map-layers-list .globalstar").removeClass("active");
	Cookies.remove("layer-globalstar");
}

export function disableLayerInmarsat() {
	if (!trkData.inmarsat.isActive) return;
	trkData.inmarsat.isActive = false;
	stopSatelliteOverlay("Inmarsat");
	$j("#map-layers-list .inmarsat").removeClass("active");
	Cookies.remove("layer-inmarsat");
}

export function disableLayerOrbcomm() {
	if (!trkData.orbcomm.isActive) return;
	trkData.orbcomm.isActive = false;
	stopSatelliteOverlay("Orbcomm");
	$j("#map-layers-list .orbcomm").removeClass("active");
	Cookies.remove("layer-orbcomm");
}

export function disableLayerGeostationary() {
	if (!trkData.geostationary.isActive) return;
	trkData.geostationary.isActive = false;
	stopSatelliteOverlay("Geostationary");
	$j("#map-layers-list .geostationary").removeClass("active");
	Cookies.remove("layer-geostationary");
}

function startSatelliteOverlay(constellation) {
	// map current positions
	var dataPost = { constellation: constellation };
	$j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetSatelliteTelemetry"),
		data: JSON.stringify(dataPost),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg.d) {
				var result = msg.d;
				if (result) {
					if (result.Success === false) {
						handleWebServiceError(strings.MSG_SATELLITE_ORBIT_ERROR + " " + result.ErrorMessage);
						return;
					}
					trkData.satelliteMarkers[constellation] = [];
					trkData.satelliteOrbits[constellation] = result.Satellites;
					trkData.satelliteIndex[constellation] = 1;
					// map the current positions
					for (var i = 0; i < result.Satellites.length; i++) {
						var sat = result.Satellites[i];
						if (sat.Positions == null) {
							continue;
						}
						if (sat.Positions.length == 0) {
							continue;
						}
						var pos = sat.Positions[0];
						// wrap locations
						var lat = wrapLat(pos.Lat);
						var lng = wrapLng(pos.Lng);
						var latlng = L.latLng(lat, lng);

						var icon = L.icon({
							iconUrl: "/content/images/marker-satellite-small.png",
							iconSize: [20, 20],
							iconAnchor: [10, 10],
						});
						var tooltip = sat.Name;
						if (sat.Description != null) {
							tooltip += " - " + sat.Description;
						}
						var satelliteMarker = L.marker(latlng, {
							icon: icon,
							//title: sat.Name
						}).bindTooltip(tooltip);
						addItemToMap(satelliteMarker);
						sat.Marker = satelliteMarker;
						trkData.satelliteMarkers[constellation].push(satelliteMarker);
					}

					// set timeout to show the next
					intervals.satelliteInterval[constellation] = setInterval(function () {
						nextTelemetryPosition(constellation);
					}, 10000);
				}
			}
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_SATELLITE_ORBIT_ERROR);
		},
	});
}

function stopSatelliteOverlay(constellation) {
	if (trkData.satelliteMarkers[constellation] != null) {
		for (var j = 0; j < trkData.satelliteMarkers[constellation].length; j++) {
			var marker = trkData.satelliteMarkers[constellation][j];
			removeItemFromMap(marker);
		}
	}
	clearInterval(intervals.satelliteInterval[constellation]);
	trkData.satelliteIndex[constellation] = 0;
}

function nextTelemetryPosition(constellation) {
	if (trkData.satelliteIndex[constellation] >= 60) {
		// it's time to requery
		stopSatelliteOverlay(constellation);
		startSatelliteOverlay(constellation);
		return;
	}

	for (var i = 0; i < trkData.satelliteOrbits[constellation].length; i++) {
		var sat = trkData.satelliteOrbits[constellation][i];
		var pos = sat.Positions[trkData.satelliteIndex[constellation]];
		if (pos == null) {
			continue;
		}
		var lat = wrapLat(pos.Lat);
		var lng = wrapLng(pos.Lng);
		var latlng = L.latLng(lat, lng);
		sat.Marker.setLatLng(latlng);
	}
	trkData.satelliteIndex[constellation]++;
}
