import strings from "./strings.js";
import trkData from "./data.js";
import { wrapUrl } from "./wrapurl.js";
import { findAssetById } from "./assets.js";
import { disabledCheckboxEl } from "./dom-util.js";
import { renderDomElement } from "./datatables.js";
import { renderIDPCommandLog } from "./datatables.js";
import { devices } from "./devices.js";
import { handleWebServiceError } from "./ajax.js";
import { toggleLoadingMessage, formShowErrorMessage } from "./ajax.js";
import { populateIDPCryptoInformation, populateIDPUpdaterInformation } from "./idp-command.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import { el, setChildren } from "redom"; // https://redom.js.org/

export function hasAVLAssets() {
	for (var i = 0; i < trkData.assets.length; i++) {
		if ($j.inArray(trkData.assets[i].Configuration, trkData.avlConfigurations) !== -1) {
			return true;
		}
	}
	return false;
}

export function requestAVLInformation(assetId) {
	var data = {
		assetId: assetId,
	};
	var status = document.getElementById("idp-query-status");
	toggleLoadingMessage(true, "idp-information");
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetAVLInformationForAsset"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			var result = msg.d;
			if (result) {
				if (result.Success == true) {
					onAVLInformation(result, assetId);
				}
			} else {
				// message failure, keep text field to allow retry
				formShowErrorMessage(status, strings.MSG_IDP_INFORMATION_ERROR);
				if (result.ErrorMessage != null && result.ErrorMessage != "") {
					formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
				}
			}
			toggleLoadingMessage(false, "idp-information");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_IDP_INFORMATION_ERROR);
			toggleLoadingMessage(false, "idp-information");
		},
	});
}

function onAVLInformation(result, assetId) {
	var asset = findAssetById(assetId);
	if (result.IDP != null) {
		asset.IDP = result.IDP;
	}

	// service meter
	var meter = result.ServiceMeter;
	if (meter.LastRetrievedOn == null) {
		$j("#IDPServiceMetersLastRetrieved").text(strings.NEVER);
	} else {
		$j("#IDPServiceMetersLastRetrieved").text(meter.LastRetrievedOn);
	}
	if (meter.LastQueriedOn == null) {
		$j("#IDPServiceMetersLastQueried").text(strings.NEVER);
	} else {
		$j("#IDPServiceMetersLastQueried").text(meter.LastQueriedOn);
	}
	var props = meter.ServiceMeter;
	if (props != null) {
		$j("#txtIDPAVLSM0Time").val(props.SM0Time);
		$j("#txtIDPAVLSM0Distance").val(props.SM0Distance);
		$j("#txtIDPAVLSM1Time").val(props.SM1Time);
		$j("#txtIDPAVLSM1Distance").val(props.SM1Distance);
		$j("#txtIDPAVLSM2Time").val(props.SM2Time);
		$j("#txtIDPAVLSM2Distance").val(props.SM2Distance);
		$j("#txtIDPAVLSM3Time").val(props.SM3Time);
		$j("#txtIDPAVLSM3Distance").val(props.SM3Distance);
		$j("#txtIDPAVLSM4Time").val(props.SM4Time);
		$j("#txtIDPAVLSM4Distance").val(props.SM4Distance);
		$j("#txtIDPServiceMeterOdometer").val(props.Odometer);
	}

	// core parameters
	var core = result.CoreProperties;
	var props = core.CoreProperties;

	if (core.LastRetrievedOn == null) {
		$j(".CoreParametersLastRetrieved").text(strings.NEVER);
	} else {
		$j(".CoreParametersLastRetrieved").text(core.LastRetrievedOn);
	}
	if (core.LastQueriedOn == null) {
		$j(".CoreParametersLastQueried").text(strings.NEVER);
	} else {
		$j(".CoreParametersLastQueried").text(core.LastQueriedOn);
	}
	if (props != null) {
		// apply updated asset IDP information

		if (props.System != null) {
			$j("#txtIDPSystemExecutionWatchdogTimeout").val(props.System.ExecutionWatchdogTimeout);
			$j("#txtIDPSystemAutoGCMemThreshold").val(props.System.AutoGCMemThreshold);
			$j("#ddlIDPSystemLedControl").val(props.System.LedControl);
		}
		if (props.Power != null) {
			$j("#ddlIDPPowerExtPowerPresentStateDetect").val(props.Power.ExtPowerPresentStateDetect);
		}
		if (props.Report != null) {
			$j("input:radio[name=rbIDPReport1Enabled][value=" + props.Report.Report1Enabled + "]").prop("checked", true);
			$j("#ddlIDPReport1ReportType").val(props.Report.Report1MIN);
			$j("#txtIDPReport1TimeInterval").val(props.Report.Report1Interval);
		}
		if (props.Message != null) {
			$j("input:radio[name=rbIDPMessageRetry][value=" + props.Message.Retry + "]").prop("checked", true);
			$j("#txtIDPMessageTransport1").val(props.Message.Transport1);
			$j("#txtIDPMessageTransport2").val(props.Message.Transport2);
			$j("#txtIDPMessageTimeout1").val(props.Message.Timeout1);
			$j("#txtIDPMessageTimeout2").val(props.Message.Timeout2);
			$j("#txtIDPMessageRetrvInterval").val(props.Message.RetrvInterval);
			$j("#txtIDPMessageRetrvMultiplier").val(props.Message.RetrvMultiplier);
		}
		if (props.Position != null) {
			$j("#txtIDPPositionContinuous").val(props.Position.Continuous);
		}
		if (props.Log != null) {
			$j("input:radio[name=rbIDPLogDataLogEnabled][value=" + props.Log.DataLogEnabled + "]").prop("checked", true);
			$j("#txtIDPLogMaxDataLogSize").val(props.Log.MaxDataLogSize);
			$j("#txtIDPLogMaxDataLogFiles").val(props.Log.MaxDataLogFiles);
			$j("input:radio[name=rbIDPLogDebugLogEnabled][value=" + props.Log.DebugLogEnabled + "]").prop("checked", true);
			$j("#txtIDPLogMaxDebugLogSize").val(props.Log.MaxDebugLogSize);
		}
		if (props.EIO != null) {
			$j("#ddlIDPEIOPort1Config").val(props.EIO.Port1Config);
			//$j('input[name=rbIDPEIOPort1AlarmMsg]:checked').val(props.EIO.Port1AlarmMsg);
			//$j('input[name=rbIDPEIOPort1AlarmLog]:checked').val(props.EIO.Port1AlarmLog);
			$j("input:radio[name=rbIDPEIOPort1AlarmMsg][value=" + props.EIO.Port1AlarmMsg + "]").prop("checked", true);
			$j("input:radio[name=rbIDPEIOPort1AlarmLog][value=" + props.EIO.Port1AlarmLog + "]").prop("checked", true);
			$j("#ddlIDPEIOPort1EdgeDetect").val(props.EIO.Port1EdgeDetect);
			$j("#txtIDPEIOPort1EdgeSampleCount").val(props.EIO.Port1EdgeSampleCount);
			$j("#txtIDPEIOPort1EdgeSampleError").val(props.EIO.Port1EdgeSampleError);
			$j("#txtIDPEIOPort1AnalogSampleRate").val(props.EIO.Port1AnalogSampleRate);
			$j("#txtIDPEIOPort1AnalogSampleFilter").val(props.EIO.Port1AnalogSampleFilter);
			$j("#txtIDPEIOPort1AnalogLowThreshold").val(props.EIO.Port1AnalogLowThreshold);
			$j("#txtIDPEIOPort1AnalogHighThreshold").val(props.EIO.Port1AnalogHighThreshold);
			$j("#ddlIDPEIOPort2Config").val(props.EIO.Port2Config);
			$j("input:radio[name=rbIDPEIOPort2AlarmMsg][value=" + props.EIO.Port2AlarmMsg + "]").prop("checked", true);
			$j("input:radio[name=rbIDPEIOPort2AlarmLog][value=" + props.EIO.Port2AlarmLog + "]").prop("checked", true);
			$j("#ddlIDPEIOPort2EdgeDetect").val(props.EIO.Port2EdgeDetect);
			$j("#txtIDPEIOPort2EdgeSampleCount").val(props.EIO.Port2EdgeSampleCount);
			$j("#txtIDPEIOPort2EdgeSampleError").val(props.EIO.Port2EdgeSampleError);
			$j("#txtIDPEIOPort2AnalogSampleRate").val(props.EIO.Port2AnalogSampleRate);
			$j("#txtIDPEIOPort2AnalogSampleFilter").val(props.EIO.Port2AnalogSampleFilter);
			$j("#txtIDPEIOPort2AnalogLowThreshold").val(props.EIO.Port2AnalogLowThreshold);
			$j("#txtIDPEIOPort2AnalogHighThreshold").val(props.EIO.Port2AnalogHighThreshold);
			$j("#ddlIDPEIOPort3Config").val(props.EIO.Port3Config);
			$j("input:radio[name=rbIDPEIOPort3AlarmMsg][value=" + props.EIO.Port3AlarmMsg + "]").prop("checked", true);
			$j("input:radio[name=rbIDPEIOPort3AlarmLog][value=" + props.EIO.Port3AlarmLog + "]").prop("checked", true);
			$j("#ddlIDPEIOPort3EdgeDetect").val(props.EIO.Port3EdgeDetect);
			$j("#txtIDPEIOPort3EdgeSampleCount").val(props.EIO.Port3EdgeSampleCount);
			$j("#txtIDPEIOPort3EdgeSampleError").val(props.EIO.Port3EdgeSampleError);
			$j("#txtIDPEIOPort3AnalogSampleRate").val(props.EIO.Port3AnalogSampleRate);
			$j("#txtIDPEIOPort3AnalogSampleFilter").val(props.EIO.Port3AnalogSampleFilter);
			$j("#txtIDPEIOPort3AnalogLowThreshold").val(props.EIO.Port3AnalogLowThreshold);
			$j("#txtIDPEIOPort3AnalogHighThreshold").val(props.EIO.Port3AnalogHighThreshold);
			$j("#ddlIDPEIOPort4Config").val(props.EIO.Port4Config);
			$j("input:radio[name=rbIDPEIOPort4AlarmMsg][value=" + props.EIO.Port4AlarmMsg + "]").prop("checked", true);
			$j("input:radio[name=rbIDPEIOPort4AlarmLog][value=" + props.EIO.Port4AlarmLog + "]").prop("checked", true);
			$j("#ddlIDPEIOPort4EdgeDetect").val(props.EIO.Port4EdgeDetect);
			$j("#txtIDPEIOPort4EdgeSampleCount").val(props.EIO.Port4EdgeSampleCount);
			$j("#txtIDPEIOPort4EdgeSampleError").val(props.EIO.Port4EdgeSampleError);
			$j("#txtIDPEIOPort4AnalogSampleRate").val(props.EIO.Port4AnalogSampleRate);
			$j("#txtIDPEIOPort4AnalogSampleFilter").val(props.EIO.Port4AnalogSampleFilter);
			$j("#txtIDPEIOPort4AnalogLowThreshold").val(props.EIO.Port4AnalogLowThreshold);
			$j("#txtIDPEIOPort4AnalogHighThreshold").val(props.EIO.Port4AnalogHighThreshold);
			$j("input:radio[name=rbIDPEIOTemperatureAlarmMsg][value=" + props.EIO.TemperatureAlarmMsg + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPEIOTemperatureAlarmLog][value=" + props.EIO.TemperatureAlarmLog + "]").prop(
				"checked",
				true
			);
			$j("#txtIDPEIOTemperatureSampleRate").val(props.EIO.TemperatureSampleRate);
			$j("#txtIDPEIOTemperatureSampleFilter").val(props.EIO.TemperatureSampleFilter);
			$j("#txtIDPEIOTemperatureLowThreshold").val(props.EIO.TemperatureLowThreshold);
			$j("#txtIDPEIOTemperatureHighThreshold").val(props.EIO.TemperatureHighThreshold);
			$j("input:radio[name=rbIDPEIOPowerAlarmMsg][value=" + props.EIO.PowerAlarmMsg + "]").prop("checked", true);
			$j("input:radio[name=rbIDPEIOPowerAlarmLog][value=" + props.EIO.PowerAlarmLog + "]").prop("checked", true);
			$j("#txtIDPEIOPowerSampleRate").val(props.EIO.PowerSampleRate);
			$j("#txtIDPEIOPowerSampleFilter").val(props.EIO.PowerSampleFilter);
			$j("#txtIDPEIOPowerLowThreshold").val(props.EIO.PowerLowThreshold);
			$j("#txtIDPEIOPowerHighThreshold").val(props.EIO.PowerHighThreshold);
			$j("#ddlIDPEIOOutputSink5Default").val(props.EIO.OutputSink5Default);
			$j("#ddlIDPEIOOutputSink6Default").val(props.EIO.OutputSink6Default);
		}
		if (props.GPRS != null) {
			$j("#txtIDPGPRSSIM1PIN").val(props.GPRS.SIM1PIN);
			$j("#txtIDPGPRSSIM1APN").val(props.GPRS.SIM1APN);
			$j("#txtIDPGPRSSIM1Username").val(props.GPRS.SIM1Username);
			$j("#txtIDPGPRSSIM1Password").val(props.GPRS.SIM1Password);
			$j("#txtIDPGPRSSIM1DNS1").val(props.GPRS.SIM1DNS1);
			$j("#txtIDPGPRSSIM1DNS2").val(props.GPRS.SIM1DNS2);
			$j("#txtIDPGPRSSIM2PIN").val(props.GPRS.SIM2PIN);
			$j("#txtIDPGPRSSIM2APN").val(props.GPRS.SIM2APN);
			$j("#txtIDPGPRSSIM2Username").val(props.GPRS.SIM2Username);
			$j("#txtIDPGPRSSIM2Password").val(props.GPRS.SIM2Password);
			$j("#txtIDPGPRSSIM2DNS1").val(props.GPRS.SIM2DNS1);
			$j("#txtIDPGPRSSIM2DNS2").val(props.GPRS.SIM2DNS2);
			$j("#txtIDPGPRSServer1").val(props.GPRS.Server1);
			$j("#txtIDPGPRSPort1").val(props.GPRS.Port1);
			$j("#txtIDPGPRSPollingInterval").val(props.GPRS.PollingInterval);
			$j("#ddlIDPGPRSActiveSIM").val(props.GPRS.ActiveSIM);
			$j("#ddlIDPGPRSTargetMode").val(props.GPRS.TargetMode);
		}
		if (props.EEIO != null) {
			$j("#ddlIDPEEIOInput1Config").val(props.EEIO.Input1Config);
			$j("#ddlIDPEEIOInput1EdgeDetect").val(props.EEIO.Input1EdgeDetect);
			$j("#txtIDPEEIOInput1EdgeFilterCount").val(props.EEIO.Input1EdgeFilterCount);
			$j("#txtIDPEEIOInput1AnalogSampleRate").val(props.EEIO.Input1AnalogSampleRate);
			$j("#txtIDPEEIOInput1AnalogFilterCount").val(props.EEIO.Input1AnalogFilterCount);
			$j("#txtIDPEEIOInput1AnalogLowThreshold").val(props.EEIO.Input1AnalogLowThreshold);
			$j("#txtIDPEEIOInput1AnalogHighThreshold").val(props.EEIO.Input1AnalogHighThreshold);
			$j("input:radio[name=rbIDPEEIOInput1AlarmMsg][value=" + props.EEIO.Input1AlarmMsg + "]").prop("checked", true);
			$j("input:radio[name=rbIDPEEIOInput1AlarmLog][value=" + props.EEIO.Input1AlarmLog + "]").prop("checked", true);
			$j("#ddlIDPEEIOInput2Config").val(props.EEIO.Input2Config);
			$j("#ddlIDPEEIOInput2EdgeDetect").val(props.EEIO.Input2EdgeDetect);
			$j("#txtIDPEEIOInput2EdgeFilterCount").val(props.EEIO.Input2EdgeFilterCount);
			$j("#txtIDPEEIOInput2AnalogSampleRate").val(props.EEIO.Input2AnalogSampleRate);
			$j("#txtIDPEEIOInput2AnalogFilterCount").val(props.EEIO.Input2AnalogFilterCount);
			$j("#txtIDPEEIOInput2AnalogLowThreshold").val(props.EEIO.Input2AnalogLowThreshold);
			$j("#txtIDPEEIOInput2AnalogHighThreshold").val(props.EEIO.Input2AnalogHighThreshold);
			$j("input:radio[name=rbIDPEEIOInput2AlarmMsg][value=" + props.EEIO.Input2AlarmMsg + "]").prop("checked", true);
			$j("input:radio[name=rbIDPEEIOInput2AlarmLog][value=" + props.EEIO.Input2AlarmLog + "]").prop("checked", true);
			$j("#ddlIDPEEIOInput3Config").val(props.EEIO.Input3Config);
			$j("#ddlIDPEEIOInput3EdgeDetect").val(props.EEIO.Input3EdgeDetect);
			$j("#txtIDPEEIOInput3EdgeFilterCount").val(props.EEIO.Input3EdgeFilterCount);
			$j("#txtIDPEEIOInput3AnalogSampleRate").val(props.EEIO.Input3AnalogSampleRate);
			$j("#txtIDPEEIOInput3AnalogFilterCount").val(props.EEIO.Input3AnalogFilterCount);
			$j("#txtIDPEEIOInput3AnalogLowThreshold").val(props.EEIO.Input3AnalogLowThreshold);
			$j("#txtIDPEEIOInput3AnalogHighThreshold").val(props.EEIO.Input3AnalogHighThreshold);
			$j("input:radio[name=rbIDPEEIOInput3AlarmMsg][value=" + props.EEIO.Input3AlarmMsg + "]").prop("checked", true);
			$j("input:radio[name=rbIDPEEIOInput3AlarmLog][value=" + props.EEIO.Input3AlarmLog + "]").prop("checked", true);
			$j("#ddlIDPEEIOInput4Config").val(props.EEIO.Input4Config);
			$j("#ddlIDPEEIOInput4EdgeDetect").val(props.EEIO.Input4EdgeDetect);
			$j("#txtIDPEEIOInput4EdgeFilterCount").val(props.EEIO.Input4EdgeFilterCount);
			$j("#txtIDPEEIOInput4AnalogSampleRate").val(props.EEIO.Input4AnalogSampleRate);
			$j("#txtIDPEEIOInput4AnalogFilterCount").val(props.EEIO.Input4AnalogFilterCount);
			$j("#txtIDPEEIOInput4AnalogLowThreshold").val(props.EEIO.Input4AnalogLowThreshold);
			$j("#txtIDPEEIOInput4AnalogHighThreshold").val(props.EEIO.Input4AnalogHighThreshold);
			$j("input:radio[name=rbIDPEEIOInput4AlarmMsg][value=" + props.EEIO.Input4AlarmMsg + "]").prop("checked", true);
			$j("input:radio[name=rbIDPEEIOInput4AlarmLog][value=" + props.EEIO.Input4AlarmLog + "]").prop("checked", true);
			$j("#ddlIDPEEIOInputDigital5EdgeDetect").val(props.EEIO.InputDigital5EdgeDetect);
			$j("#txtIDPEEIOInputDigital5EdgeFilterCount").val(props.EEIO.InputDigital5EdgeFilterCount);
			$j("input:radio[name=rbIDPEEIOInputDigital5AlarmMsg][value=" + props.EEIO.InputDigital5AlarmMsg + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPEEIOInputDigital5AlarmLog][value=" + props.EEIO.InputDigital5AlarmLog + "]").prop(
				"checked",
				true
			);
			$j("#ddlIDPEEIOInputDigital6EdgeDetect").val(props.EEIO.InputDigital6EdgeDetect);
			$j("#txtIDPEEIOInputDigital6EdgeFilterCount").val(props.EEIO.InputDigital6EdgeFilterCount);
			$j("input:radio[name=rbIDPEEIOInputDigital6AlarmMsg][value=" + props.EEIO.InputDigital6AlarmMsg + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPEEIOInputDigital6AlarmLog][value=" + props.EEIO.InputDigital6AlarmLog + "]").prop(
				"checked",
				true
			);
			$j("#ddlIDPEEIOInputDigital7EdgeDetect").val(props.EEIO.InputDigital7EdgeDetect);
			$j("#txtIDPEEIOInputDigital7EdgeFilterCount").val(props.EEIO.InputDigital7EdgeFilterCount);
			$j("input:radio[name=rbIDPEEIOInputDigital7AlarmMsg][value=" + props.EEIO.InputDigital7AlarmMsg + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPEEIOInputDigital7AlarmLog][value=" + props.EEIO.InputDigital7AlarmLog + "]").prop(
				"checked",
				true
			);
			$j("#ddlIDPEEIOInputDigital8EdgeDetect").val(props.EEIO.InputDigital8EdgeDetect);
			$j("#txtIDPEEIOInputDigital8EdgeFilterCount").val(props.EEIO.InputDigital8EdgeFilterCount);
			$j("input:radio[name=rbIDPEEIOInputDigital8AlarmMsg][value=" + props.EEIO.InputDigital8AlarmMsg + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPEEIOInputDigital8AlarmLog][value=" + props.EEIO.InputDigital8AlarmLog + "]").prop(
				"checked",
				true
			);
			$j("#ddlIDPEEIOInputDigital9EdgeDetect").val(props.EEIO.InputDigital9EdgeDetect);
			$j("#txtIDPEEIOInputDigital9EdgeFilterCount").val(props.EEIO.InputDigital9EdgeFilterCount);
			$j("input:radio[name=rbIDPEEIOInputDigital9AlarmMsg][value=" + props.EEIO.InputDigital9AlarmMsg + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPEEIOInputDigital9AlarmLog][value=" + props.EEIO.InputDigital9AlarmLog + "]").prop(
				"checked",
				true
			);
			$j("#ddlIDPEEIOInputDigital10EdgeDetect").val(props.EEIO.InputDigital10EdgeDetect);
			$j("#txtIDPEEIOInputDigital10EdgeFilterCount").val(props.EEIO.InputDigital10EdgeFilterCount);
			$j("input:radio[name=rbIDPEEIOInputDigital10AlarmMsg][value=" + props.EEIO.InputDigital10AlarmMsg + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPEEIOInputDigital10AlarmLog][value=" + props.EEIO.InputDigital10AlarmLog + "]").prop(
				"checked",
				true
			);
			$j("#ddlIDPEEIOInputDigital11EdgeDetect").val(props.EEIO.InputDigital11EdgeDetect);
			$j("#txtIDPEEIOInputDigital11EdgeFilterCount").val(props.EEIO.InputDigital11EdgeFilterCount);
			$j("input:radio[name=rbIDPEEIOInputDigital11AlarmMsg][value=" + props.EEIO.InputDigital11AlarmMsg + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPEEIOInputDigital11AlarmLog][value=" + props.EEIO.InputDigital11AlarmLog + "]").prop(
				"checked",
				true
			);
			$j("#ddlIDPEEIOInputIgnition12EdgeDetect").val(props.EEIO.InputIgnition12EdgeDetect);
			$j("#txtIDPEEIOInputIgnition12EdgeFilterCount").val(props.EEIO.InputIgnition12EdgeFilterCount);
			$j("input:radio[name=rbIDPEEIOInputIgnition12AlarmMsg][value=" + props.EEIO.InputIgnition12AlarmMsg + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPEEIOInputIgnition12AlarmLog][value=" + props.EEIO.InputIgnition12AlarmLog + "]").prop(
				"checked",
				true
			);
			$j("#ddlIDPEEIOutputSink14Default").val(props.EEIO.OutputSink14Default);
			$j("#ddlIDPEEIOutputSink15Default").val(props.EEIO.OutputSink15Default);
			$j("#ddlIDPEEIOutputSink16Default").val(props.EEIO.OutputSink16Default);
			$j("#ddlIDPEEIOutputSink17Default").val(props.EEIO.OutputSink17Default);
			$j("#ddlIDPEEIOutputSink18Default").val(props.EEIO.OutputSink18Default);
			$j("#txtIDPEEIOTemperatureSampleRate").val(props.EEIO.TemperatureSampleRate);
			$j("#txtIDPEEIOTemperatureFilterCount").val(props.EEIO.TemperatureFilterCount);
			$j("#txtIDPEEIOTemperatureLowThreshold").val(props.EEIO.TemperatureLowThreshold);
			$j("#txtIDPEEIOTemperatureHighThreshold").val(props.EEIO.TemperatureHighThreshold);
			$j("input:radio[name=rbIDPEEIOTemperatureAlarmMsg][value=" + props.EEIO.TemperatureAlarmMsg + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPEEIOTemperatureAlarmLog][value=" + props.EEIO.TemperatureAlarmLog + "]").prop(
				"checked",
				true
			);
			$j("#txtIDPEEIOExternalPowerSampleRate").val(props.EEIO.ExternalPowerSampleRate);
			$j("#txtIDPEEIOExternalPowerFilterCount").val(props.EEIO.ExternalPowerFilterCount);
			$j("#txtIDPEEIOExternalPowerLowThreshold").val(props.EEIO.ExternalPowerLowThreshold);
			$j("#txtIDPEEIOExternalPowerHighThreshold").val(props.EEIO.ExternalPowerHighThreshold);
			$j("input:radio[name=rbIDPEEIOExternalPowerAlarmMsg][value=" + props.EEIO.ExternalPowerAlarmMsg + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPEEIOExternalPowerAlarmLog][value=" + props.EEIO.ExternalPowerAlarmLog + "]").prop(
				"checked",
				true
			);
		}
		if (props.Accel != null) {
			$j("#txtIDPAccelSleepInterval").val(props.Accel.SleepInterval);
			$j("#txtIDPAccelGRange").val(props.Accel.GRange);
			$j("#txtIDPAccelMotionEventThreshold").val(props.Accel.MotionEventThreshold);
			$j("input:radio[name=rbIDPAccelMotionEventXAxisEn][value=" + props.Accel.MotionEventXAxisEn + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAccelMotionEventYAxisEn][value=" + props.Accel.MotionEventYAxisEn + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAccelMotionEventZAxisEn][value=" + props.Accel.MotionEventZAxisEn + "]").prop(
				"checked",
				true
			);
		}
	}

	// geofence parameters
	var geofence = result.GeofenceParameters;
	var props = geofence.GeofenceParameters;

	if (geofence.LastRetrievedOn == null) {
		$j("#AVLGeofenceParametersLastRetrieved").text(strings.NEVER);
	} else {
		$j("#AVLGeofenceParametersLastRetrieved").text(geofence.LastRetrievedOn);
	}
	if (geofence.LastQueriedOn == null) {
		$j("#AVLGeofenceParametersLastQueried").text(strings.NEVER);
	} else {
		$j("#AVLGeofenceParametersLastQueried").text(geofence.LastQueriedOn);
	}
	if (props != null) {
		$j("input:radio[name=rbIDPAVLGeofenceEnabled][value=" + props.Enabled + "]").prop("checked", true);
		$j("#txtIDPAVLGeofenceInterval").val(props.Interval);
		$j("#txtIDPAVLGeofenceHysteresis").val(props.Hysteresis);
		$j("input:radio[name=rbIDPAVLGeofenceSendAlarm][value=" + props.SendAlarm + "]").prop("checked", true);
	}

	// avl parameters
	var avl = result.AVLParameters;
	var props = avl.ServiceProperties;
	if (avl.LastRetrievedOn == null) {
		$j(".AVLParametersLastRetrieved").text(strings.NEVER);
	} else {
		$j(".AVLParametersLastRetrieved").text(avl.LastRetrievedOn);
	}
	if (avl.LastQueriedOn == null) {
		$j(".AVLParametersLastQueried").text(strings.NEVER);
	} else {
		$j(".AVLParametersLastQueried").text(avl.LastQueriedOn);
	}
	if (props != null) {
		// populate
		$j("#txtIDPAVLStationarySpeedThld").val(props.StationarySpeedThreshold);
		$j("#txtIDPAVLStationaryDebounceTime").val(props.StationaryDebounceTime);
		$j("#txtIDPAVLMovingDebounceTime").val(props.MovingDebounceTime);
		$j("#txtIDPAVLMovingDistanceThld").val(props.MovingDistanceThreshold);
		$j("#txtIDPAVLDefaultSpeedLimit").val(props.DefaultSpeedLimit);
		$j("#txtIDPAVLSpeedingTimeOver").val(props.SpeedingTimeOver);
		$j("#txtIDPAVLSpeedingTimeUnder").val(props.SpeedingTimeUnder);
		$j("#txtIDPAVLLoggingPositionsInterval").val(props.LoggingPositionsInterval);
		$j("#txtIDPAVLStationaryIntervalCell").val(props.StationaryIntervalCell);
		$j("#txtIDPAVLMovingIntervalCell").val(props.MovingIntervalCell);
		$j("#txtIDPAVLStationaryIntervalSat").val(props.StationaryIntervalSat);
		$j("#txtIDPAVLMovingIntervalSat").val(props.MovingIntervalSat);
		$j("#txtIDPAVLShmReportingHour").val(props.ShmReportingHour);
		$j("#txtIDPAVLOdometerDistanceIncrement").val(props.OdometerDistanceIncrement);
		$j("#txtIDPAVLOdometer").val(props.Odometer);
		$j("#txtIDPAVLTurnThreshold").val(props.TurnThreshold);
		$j("#txtIDPAVLTurnDebounceTime").val(props.TurnDebounceTime);
		$j("#txtIDPAVLDistanceCellThld").val(props.DistanceCellThreshold);
		$j("#txtIDPAVLDistanceSatThld").val(props.DistanceSatThreshold);
		$j("#txtIDPAVLMaxDrivingTime").val(props.MaxDrivingTime);
		$j("#txtIDPAVLMinRestTime").val(props.MinRestTime);
		$j("#txtIDPAVLAirBlockageTime").val(props.AirBlockageTime);
		$j("#txtIDPAVLMaxIdlingTime").val(props.MaxIdlingTime);
		$j("input:radio[name=rbIDPAVLIdleTimerAutoRestart][value=" + props.IdleTimerAutoRestart + "]").prop(
			"checked",
			true
		);
		$j("#txtIDPAVLDefaultGeoDwellTime").val(props.DefaultGeoDwellTime);
		$j("input:radio[name=rbIDPAVLGeoDwellTimerAutoRestart][value=" + props.GeoDwellTimerAutoRestart + "]").prop(
			"checked",
			true
		);
		$j("#txtIDPAVLImmobilizeOnBlockage").val(props.ImmobilizeOnBlockage);
		$j("#txtIDPAVLGpsJamDebounceTime").val(props.GpsJamDebounceTime);
		$j("#txtIDPAVLCellJamDebounceTime").val(props.CellJamDebounceTime);
		// todo: Version
		//$j('#txtIDPAVLLpmTrigger').val(props.LpmTrigger);
		if (props.LpmTriggerDef != null) {
			$j("input:radio[name=rbIDPAVLLpmTriggerIgnitionOff][value=" + props.LpmTriggerDef.IgnitionOff + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAVLLpmTriggerBuiltInBattery][value=" + props.LpmTriggerDef.BuiltInBattery + "]").prop(
				"checked",
				true
			);
		}
		$j("#txtIDPAVLLpmEntryDelay").val(props.LpmEntryDelay);
		$j("#txtIDPAVLLpmGeoInterval").val(props.LpmGeoInterval);
		$j("#ddlIDPAVLLpmModemWakeupInterval").val(props.LpmModemWakeupInterval);
		$j("#txtIDPAVLExitLpmOnTowingMaxTime").val(props.ExitLpmOnTowingMaxTime);
		$j("#txtIDPAVLTowStartCheckInterval").val(props.TowStartCheckInterval);
		$j("#txtIDPAVLTowStartDebCount").val(props.TowStartDebCount);
		$j("#txtIDPAVLTowStopCheckInterval").val(props.TowStopCheckInterval);
		$j("#txtIDPAVLTowStopDebCount").val(props.TowStopDebCount);
		$j("#txtIDPAVLTowInterval").val(props.TowInterval);

		$j("#txtIDPAVLHarshBrakingThld").val(props.HarshBrakingThld);
		$j("#txtIDPAVLMinHarshBrakingTime").val(props.MinHarshBrakingTime);
		$j("#txtIDPAVLReArmHarshBrakingTime").val(props.ReArmHarshBrakingTime);
		$j("#txtIDPAVLHarshAccelThld").val(props.HarshAccelThld);
		$j("#txtIDPAVLMinHarshAccelTime").val(props.MinHarshAccelTime);
		$j("#txtIDPAVLReArmHarshAccelTime").val(props.ReArmHarshAccelTime);
		$j("#txtIDPAVLAccidentThld").val(props.AccidentThld);
		$j("#txtIDPAVLMinAccidentTime").val(props.MinAccidentTime);
		$j("#txtIDPAVLSeatbeltDebounceTime").val(props.SeatbeltDebounceTime);
		$j("#ddlIDPAVLDriverIdPort").val(props.DriverIdPort);
		$j("#txtIDPAVLDriverIdPollingInterval").val(props.DriverIdPollingInterval);
		$j("#txtIDPAVLDriverIdAutoLogoutDelay").val(props.DriverIdAutoLogoutDelay);
		$j("input:radio[name=rbIDPAVLAccidentAccelDataCapture][value=" + props.AccidentAccelDataCapture + "]").prop(
			"checked",
			true
		);
		$j("input:radio[name=rbIDPAVLAccidentGpsDataCapture][value=" + props.AccidentGpsDataCapture + "]").prop(
			"checked",
			true
		);
		$j("#txtIDPAVLHarshTurnThld").val(props.HarshTurnThld);
		$j("#txtIDPAVLMinHarshTurnTime").val(props.MinHarshTurnTime);
		$j("#txtIDPAVLReArmHarshTurnTime").val(props.ReArmHarshTurnTime);

		if (props.OptionalFieldsDef != null) {
			$j("input:radio[name=rbIDPAVLOptionalFieldsInMsgsOdometer][value=" + props.OptionalFieldsDef.Odometer + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAVLOptionalFieldsInMsgsSensor1][value=" + props.OptionalFieldsDef.Sensor1 + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAVLOptionalFieldsInMsgsSensor2][value=" + props.OptionalFieldsDef.Sensor2 + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAVLOptionalFieldsInMsgsSensor3][value=" + props.OptionalFieldsDef.Sensor3 + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAVLOptionalFieldsInMsgsSensor4][value=" + props.OptionalFieldsDef.Sensor4 + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAVLOptionalFieldsInMsgsDriverId][value=" + props.OptionalFieldsDef.DriverId + "]").prop(
				"checked",
				true
			);
			$j(
				"input:radio[name=rbIDPAVLOptionalFieldsInMsgsAvlStates][value=" + props.OptionalFieldsDef.AvlStates + "]"
			).prop("checked", true);
			$j(
				"input:radio[name=rbIDPAVLOptionalFieldsInMsgsDigitalPorts][value=" + props.OptionalFieldsDef.DigitalPorts + "]"
			).prop("checked", true);
		}

		$j("#txtIDPAVLPositionMsgInterval").val(props.PositionMsgInterval);

		// i/o properties
		$j("#ddlIDPAVLFuncDigOut1").val(props.FuncDigOut1);
		$j("#ddlIDPAVLFuncDigOut2").val(props.FuncDigOut2);
		$j("#ddlIDPAVLFuncDigOut3").val(props.FuncDigOut3);
		$j("#ddlIDPAVLFuncDigOut4").val(props.FuncDigOut4);
		$j("#ddlIDPAVLFuncDigOut5").val(props.FuncDigOut5);
		$j("#ddlIDPAVLFuncDigOut6").val(props.FuncDigOut6);

		$j("#ddlIDPAVLFuncDigInp1").val(props.FuncDigInp1);
		$j("#ddlIDPAVLFuncDigInp2").val(props.FuncDigInp2);
		$j("#ddlIDPAVLFuncDigInp3").val(props.FuncDigInp3);
		$j("#ddlIDPAVLFuncDigInp4").val(props.FuncDigInp4);
		$j("#ddlIDPAVLFuncDigInp5").val(props.FuncDigInp5);
		$j("#ddlIDPAVLFuncDigInp6").val(props.FuncDigInp6);
		$j("#ddlIDPAVLFuncDigInp7").val(props.FuncDigInp7);
		$j("#ddlIDPAVLFuncDigInp8").val(props.FuncDigInp8);
		$j("#ddlIDPAVLFuncDigInp9").val(props.FuncDigInp9);
		$j("#ddlIDPAVLFuncDigInp10").val(props.FuncDigInp10);
		$j("#ddlIDPAVLFuncDigInp11").val(props.FuncDigInp11);
		$j("#ddlIDPAVLFuncDigInp12").val(props.FuncDigInp12);
		$j("#ddlIDPAVLFuncDigInp13").val(props.FuncDigInp13);
		$j("#txtIDPAVLSensorReportingInterval").val(props.SensorReportingInterval);
		if (props.DigStatesDef != null) {
			$j("input:radio[name=rbIDPAVLDigStatesDefIgnitionOn][value=" + props.DigStatesDef.IgnitionOn + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAVLDigStatesDefSeatbeltOff][value=" + props.DigStatesDef.SeatbeltBuckled + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAVLDigStatesDefSM1Active][value=" + props.DigStatesDef.SM1Active + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAVLDigStatesDefSM2Active][value=" + props.DigStatesDef.SM2Active + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAVLDigStatesDefSM3Active][value=" + props.DigStatesDef.SM3Active + "]").prop(
				"checked",
				true
			);
			$j("input:radio[name=rbIDPAVLDigStatesDefSM4Active][value=" + props.DigStatesDef.SM4Active + "]").prop(
				"checked",
				true
			);
		}
		if (props.Sensor1Properties != null) {
			$j("#txtIDPAVLSensor1SourceSIN").val(props.Sensor1Properties.SourceSIN);
			$j("#txtIDPAVLSensor1SourcePIN").val(props.Sensor1Properties.SourcePIN);
			$j("#txtIDPAVLSensor1NormalSampleInterval").val(props.Sensor1Properties.NormalSampleInterval);
			$j("#txtIDPAVLSensor1LpmSampleInterval").val(props.Sensor1Properties.LpmSampleInterval);
			$j("#txtIDPAVLSensor1MaxReportInterval").val(props.Sensor1Properties.MaxReportInterval);
			$j("#txtIDPAVLSensor1ChangeThld").val(props.Sensor1Properties.ChangeThld);
			$j("#txtIDPAVLSensor1MinThld").val(props.Sensor1Properties.MinThld);
			$j("#txtIDPAVLSensor1MaxThld").val(props.Sensor1Properties.MaxThld);
		}
		if (props.Sensor2Properties != null) {
			$j("#txtIDPAVLSensor2SourceSIN").val(props.Sensor2Properties.SourceSIN);
			$j("#txtIDPAVLSensor2SourcePIN").val(props.Sensor2Properties.SourcePIN);
			$j("#txtIDPAVLSensor2NormalSampleInterval").val(props.Sensor2Properties.NormalSampleInterval);
			$j("#txtIDPAVLSensor2LpmSampleInterval").val(props.Sensor2Properties.LpmSampleInterval);
			$j("#txtIDPAVLSensor2MaxReportInterval").val(props.Sensor2Properties.MaxReportInterval);
			$j("#txtIDPAVLSensor2ChangeThld").val(props.Sensor2Properties.ChangeThld);
			$j("#txtIDPAVLSensor2MinThld").val(props.Sensor2Properties.MinThld);
			$j("#txtIDPAVLSensor2MaxThld").val(props.Sensor2Properties.MaxThld);
		}
		if (props.Sensor3Properties != null) {
			$j("#txtIDPAVLSensor3SourceSIN").val(props.Sensor3Properties.SourceSIN);
			$j("#txtIDPAVLSensor3SourcePIN").val(props.Sensor3Properties.SourcePIN);
			$j("#txtIDPAVLSensor3NormalSampleInterval").val(props.Sensor3Properties.NormalSampleInterval);
			$j("#txtIDPAVLSensor3LpmSampleInterval").val(props.Sensor3Properties.LpmSampleInterval);
			$j("#txtIDPAVLSensor3MaxReportInterval").val(props.Sensor3Properties.MaxReportInterval);
			$j("#txtIDPAVLSensor3ChangeThld").val(props.Sensor3Properties.ChangeThld);
			$j("#txtIDPAVLSensor3MinThld").val(props.Sensor3Properties.MinThld);
			$j("#txtIDPAVLSensor3MaxThld").val(props.Sensor3Properties.MaxThld);
		}
		if (props.Sensor4Properties != null) {
			$j("#txtIDPAVLSensor4SourceSIN").val(props.Sensor4Properties.SourceSIN);
			$j("#txtIDPAVLSensor4SourcePIN").val(props.Sensor4Properties.SourcePIN);
			$j("#txtIDPAVLSensor4NormalSampleInterval").val(props.Sensor4Properties.NormalSampleInterval);
			$j("#txtIDPAVLSensor4LpmSampleInterval").val(props.Sensor4Properties.LpmSampleInterval);
			$j("#txtIDPAVLSensor4MaxReportInterval").val(props.Sensor4Properties.MaxReportInterval);
			$j("#txtIDPAVLSensor4ChangeThld").val(props.Sensor4Properties.ChangeThld);
			$j("#txtIDPAVLSensor4MinThld").val(props.Sensor4Properties.MinThld);
			$j("#txtIDPAVLSensor4MaxThld").val(props.Sensor4Properties.MaxThld);
		}
	}

	var utility = result.UtilityConfigurations;
	var props = utility.UtilityConfigurations;
	if (utility.LastRetrievedOn == null) {
		$j("#IDPUtilityConfigurationsLastRetrieved").text(strings.NEVER);
	} else {
		$j("#IDPUtilityConfigurationsLastRetrieved").text(utility.LastRetrievedOn);
	}
	if (utility.LastQueriedOn == null) {
		$j("#IDPUtilityConfigurationsLastQueried").text(strings.NEVER);
	} else {
		$j("#IDPUtilityConfigurationsLastQueried").text(utility.LastQueriedOn);
	}
	if (props != null) {
		// todo: utility configuration properties
	}

	var arc = result.ARCProperties;
	var props = arc.ARCProperties;
	if (arc.LastRetrievedOn == null) {
		$j("#IDPARCPropertiesLastRetrieved").text(strings.NEVER);
	} else {
		$j("#IDPARCPropertiesLastRetrieved").text(arc.LastRetrievedOn);
	}
	if (arc.LastQueriedOn == null) {
		$j("#IDPARCPropertiesLastQueried").text(strings.NEVER);
	} else {
		$j("#IDPARCPropertiesLastQueried").text(arc.LastQueriedOn);
	}
	if (props != null) {
		if (props.EIO1 != null) {
			$j("#ddlIDPARCEIO1EventType").val(props.EIO1.EventType);
			$j("#txtIDPARCEIO1TriggerDebounce").val(props.EIO1.TriggerDebounce);
			$j("#txtIDPARCEIO1AnalogThresholds").val(props.EIO1.AnalogThresholds);
			$j("#txtIDPARCEIO1Value").val(props.EIO1.Value);
		}
		if (props.EIO2 != null) {
			$j("#ddlIDPARCEIO2EventType").val(props.EIO2.EventType);
			$j("#txtIDPARCEIO2TriggerDebounce").val(props.EIO2.TriggerDebounce);
			$j("#txtIDPARCEIO2AnalogThresholds").val(props.EIO2.AnalogThresholds);
			$j("#txtIDPARCEIO2Value").val(props.EIO2.Value);
		}
		if (props.EIO3 != null) {
			$j("#ddlIDPARCEIO3EventType").val(props.EIO3.EventType);
			$j("#txtIDPARCEIO3TriggerDebounce").val(props.EIO3.TriggerDebounce);
			$j("#txtIDPARCEIO3AnalogThresholds").val(props.EIO3.AnalogThresholds);
			$j("#txtIDPARCEIO3Value").val(props.EIO3.Value);
		}
		if (props.EIO4 != null) {
			$j("#ddlIDPARCEIO4EventType").val(props.EIO4.EventType);
			$j("#txtIDPARCEIO4TriggerDebounce").val(props.EIO4.TriggerDebounce);
			$j("#txtIDPARCEIO4AnalogThresholds").val(props.EIO4.AnalogThresholds);
			$j("#txtIDPARCEIO4Value").val(props.EIO4.Value);
		}
		$j("#txtIDPARCHeartbeatInterval").val(props.HeartbeatInterval);
		$j("input:radio[name=rbIDPARCIsSatQualityReported][value=" + props.IsSatQualityReported + "]").prop(
			"checked",
			true
		);
		$j("#ddlIDPARCModemPowerMode").val(props.ModemPowerMode);
		$j("#ddlIDPARCModemWakeUpInterval").val(props.ModemWakeUpInterval);
		$j("input:radio[name=rbIDPARCFlashLedOnTransmit][value=" + props.FlashLedOnTransmit + "]").prop("checked", true);
		$j("input:radio[name=rbIDPARCEnablePositionReporting][value=" + props.EnablePositionReporting + "]").prop(
			"checked",
			true
		);
		$j("#txtIDPARCMotionTestInterval").val(props.MotionTestInterval);
		$j("#txtIDPARCDistanceThreshold").val(props.DistanceThreshold);
		$j("#txtIDPARCLedInstallFlashTime").val(props.LedInstallFlashTime);
		$j("#txtIDPARCContractHour").val(props.ContractHour);
		$j("#txtIDPARCMaxContractDispersion").val(props.MaxContractDispersion);
		$j("#txtIDPARCDispersionOffsetSeconds").val(props.DispersionOffsetSeconds);
		$j("input:radio[name=rbIDPARCSendContractDayValues][value=" + props.SendContractDayValues + "]").prop(
			"checked",
			true
		);
		$j("#txtIDPARCSupplyVoltageThreshold").val(props.SupplyVoltageThreshold);
		$j("#txtIDPARCSupplyVoltageTriggerDebounce").val(props.SupplyVoltageTriggerDebounce);
		$j("#txtIDPARCSupplyVoltage").val(props.SupplyVoltage);
		$j("input:radio[name=rbIDPARCIsDebugEnabled][value=" + props.IsDebugEnabled + "]").prop("checked", true);
		$j("#txtIDPARCVersion").val(props.Version);
	}

	// garmin parameters
	var garmin = result.GarminProperties;
	var props = garmin.GarminProperties;

	if (garmin.LastRetrievedOn == null) {
		$j("#IDPGarminParametersLastRetrieved").text(strings.NEVER);
	} else {
		$j("#IDPGarminParametersLastRetrieved").text(garmin.LastRetrievedOn);
	}
	if (garmin.LastQueriedOn == null) {
		$j("#IDPGarminParametersLastQueried").text(strings.NEVER);
	} else {
		$j("#IDPGarminParametersLastQueried").text(garmin.LastQueriedOn);
	}
	if (props != null) {
		// populate
		$j("input:radio[name=rbIDPGarminFmiEnabled][value=" + props.FmiEnabled + "]").prop("checked", true);
		$j("input:radio[name=rbIDPGarminSafeModeEnabled][value=" + props.SafeModeEnabled + "]").prop("checked", true);
		$j("#txtIDPGarminDispatchIconText").val(props.DispatchIconText);
		$j("#ddlIDPGarminDeleteGarminData").val(props.DeleteGarminData);
		$j("#txtIDPGarminWelcomeMessage").val(props.WelcomeMessage);
		$j("input:radio[name=rbIDPGarminOpenTextMsgAllowed][value=" + props.OpenTextMsgAllowed + "]").prop("checked", true);
		$j("input:radio[name=rbIDPGarminTextingRequiresDriverId][value=" + props.TextingRequiresDriverId + "]").prop(
			"checked",
			true
		);
		$j("input:radio[name=rbIDPGarminMultiDriverEnabled][value=" + props.MultiDriverEnabled + "]").prop("checked", true);
		$j("input:radio[name=rbIDPGarminDriverPwdEnabled][value=" + props.DriverPwdEnabled + "]").prop("checked", true);
		$j("input:radio[name=rbIDPGarminDriverIdCaseSensitive][value=" + props.DriverIdCaseSensitive + "]").prop(
			"checked",
			true
		);
		$j("input:radio[name=rbIDPGarminPasswordCaseSensitive][value=" + props.PasswordCaseSensitive + "]").prop(
			"checked",
			true
		);
		$j("input:radio[name=rbIDPGarminReportFailedLogins][value=" + props.ReportFailedLogins + "]").prop("checked", true);
		$j("#txtIDPGarminAutoArrivalTime").val(props.AutoArrivalTime);
		$j("#txtIDPGarminAutoArrivalDistance").val(props.AutoArrivalDistance);
		$j("#ddlIDPGarminSpeedLimitMode").val(props.SpeedLimitMode);
		$j("#txtIDPGarminSpeedLimitThreshold").val(props.SpeedLimitThreshold);
		$j("#txtIDPGarminSpeedLimitTimeOver").val(props.SpeedLimitTimeOver);
		$j("#txtIDPGarminSpeedLimitTimeUnder").val(props.SpeedLimitTimeUnder);
		$j("input:radio[name=rbIDPGarminSpeedLimitAlertUser][value=" + props.SpeedLimitAlertUser + "]").prop(
			"checked",
			true
		);
		$j("input:radio[name=rbIDPGarminHosEnabled][value=" + props.HosEnabled + "]").prop("checked", true);
		$j("input:radio[name=rbIDPGarminSubmitFormAsFile][value=" + props.SubmitFormAsFile + "]").prop("checked", true);
	}

	// immobilizer/driver admin
	var immobilizer = result.ImmobilizerProperties;
	var props = immobilizer.Properties;
	if (immobilizer.LastRetrievedOn == null) {
		$("#IDPImmobilizerParametersLastRetrieved").text(strings.NEVER);
	} else {
		$("#IDPImmobilizerParametersLastRetrieved").text(immobilizer.LastRetrievedOn);
	}
	if (immobilizer.LastQueriedOn == null) {
		$("#IDPImmobilizerParametersLastQueried").text(strings.NEVER);
	} else {
		$("#IDPImmobilizerParametersLastQueried").text(immobilizer.LastQueriedOn);
	}
	if (props != null) {
		$("#txtIDPImmobilizerDriverLoginCheckInterval").val(props.DriverLoginCheckInterval);
		$("input:radio[name=rbIDPImmobilizerNotifyOnlyWhenMoving][value=" + props.NotifyOnlyWhenMoving + "]").prop(
			"checked",
			true
		);
	}

	// updater
	populateIDPUpdaterInformation(result.UpdaterInfo);

	// crypto
	populateIDPCryptoInformation(result.CryptoInfo);

	// gateway counters
	var counters = result.GatewayCounters;
	$j("#CounterSatellite").text(counters.CounterSatellite);
	$j("#CounterSatelliteBytes").text(counters.CounterSatelliteBytes);
	$j("#CounterGPRS").text(counters.CounterGPRS);
	$j("#CounterGPRSBytes").text(counters.CounterGPRSBytes);
	if (counters.CountersResetOn == null) {
		$j("#AVLCountersLastReset").text(strings.NEVER);
	} else {
		$j("#AVLCountersLastReset").text(counters.CountersResetOn);
	}

	// garmin info
	var garminInfos = result.GarminInfo;
	var infoData = [];
	for (var i = 0; i < garminInfos.Responses.length; i++) {
		var item = garminInfos.Responses[i];
		if (item.Info != null) {
			var protocols = item.Info.SupportedProtocols;
			if (protocols != null) {
				protocols = protocols.split(",").join(", ");
			}
			infoData.push([
				item.Info.Latitude,
				item.Info.Longitude,
				protocols,
				item.Info.UnitId,
				item.Info.ProductId,
				item.Info.SwVer,
				item.CreatedOn,
			]);
		}
	}
	$("#IDPGarminInfos").dataTable({
		data: infoData,
		destroy: true,
		filter: false,
		info: false,
		jQueryUI: true,
		autoWidth: false,
		lengthChange: false,
		paging: true,
		pageLength: 3,
		deferRender: true,
		order: [[6, "desc"]],
		columnDefs: [
			{
				targets: "_all",
				render: $.fn.dataTable.render.text(),
			},
		],
		columns: [{}, {}, {}, {}, {}, {}, {}],
		language: strings.DATATABLE,
	});

	// diagnostics
	var diagnostics = result.AVLDiagnostics;
	var diagData = [];

	for (var i = 0; i < diagnostics.Responses.length; i++) {
		var item = diagnostics.Responses[i];
		if (item.Diagnostics != null) {
			diagData.push([
				item.Diagnostics.SatCnr / 100,
				item.Diagnostics.CellRssi,
				item.Diagnostics.PowerUpTime,
				item.Diagnostics.BattChargerState,
				item.Diagnostics.BattVoltage != null ? item.Diagnostics.BattVoltage + "mV" : "",
				item.Diagnostics.ExtVoltage != null ? item.Diagnostics.ExtVoltage + "mV" : "",
				item.Diagnostics.Temperature != null ? item.Diagnostics.Temperature + "°C" : "",
				item.Diagnostics.DigitalPorts.Line1 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line2 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line3 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line4 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line5 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line6 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line7 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line8 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line9 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line10 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line11 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line12 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line13 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line14 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line15 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line16 ? strings.ON : strings.OFF,
				item.Diagnostics.DigitalPorts.Line17 ? strings.ON : strings.OFF,
				disabledCheckboxEl(item.Diagnostics.AvlStates.InLPM),
				disabledCheckboxEl(item.Diagnostics.AvlStates.OnMainPower),
				disabledCheckboxEl(item.Diagnostics.AvlStates.Speeding),
				disabledCheckboxEl(item.Diagnostics.AvlStates.Moving),
				disabledCheckboxEl(item.Diagnostics.AvlStates.Towing),
				disabledCheckboxEl(item.Diagnostics.AvlStates.GPSJammed),
				disabledCheckboxEl(item.Diagnostics.AvlStates.CellJammed),
				disabledCheckboxEl(item.Diagnostics.AvlStates.Tamper),
				disabledCheckboxEl(item.Diagnostics.AvlStates.Blocked),
				//disabledCheckbox(item.Diagnostics.AvlStates.AccelerometerEnabled),
				disabledCheckboxEl(item.Diagnostics.AvlStates.SeatbeltViolation),
				disabledCheckboxEl(item.Diagnostics.AvlStates.IgnitionOn),
				disabledCheckboxEl(item.Diagnostics.AvlStates.EngineIdling),
				disabledCheckboxEl(item.Diagnostics.AvlStates.SM1Active),
				disabledCheckboxEl(item.Diagnostics.AvlStates.SM2Active),
				disabledCheckboxEl(item.Diagnostics.AvlStates.SM3Active),
				disabledCheckboxEl(item.Diagnostics.AvlStates.SM4Active),
				disabledCheckboxEl(item.Diagnostics.AvlStates.GeoDwelling),
				disabledCheckboxEl(item.Diagnostics.DigStatesDefMap.IgnitionOn),
				disabledCheckboxEl(item.Diagnostics.DigStatesDefMap.SeatbeltBuckled),
				disabledCheckboxEl(item.Diagnostics.DigStatesDefMap.SM1Active),
				disabledCheckboxEl(item.Diagnostics.DigStatesDefMap.SM2Active),
				disabledCheckboxEl(item.Diagnostics.DigStatesDefMap.SM3Active),
				disabledCheckboxEl(item.Diagnostics.DigStatesDefMap.SM4Active),
				item.CreatedOn,
			]);
		}
	}

	$("#AVLDiagnostics").dataTable({
		data: diagData,
		scrollX: "100%",
		//"sScrollXInner": "100%",
		scrollCollapse: false,
		destroy: true,
		filter: false,
		info: false,
		jQueryUI: true,
		autoWidth: true,
		lengthChange: false,
		paging: true,
		pageLength: 3,
		deferRender: true,
		order: [[47, "desc"]],
		columnDefs: [
			{
				targets: "_all",
				render: $.fn.dataTable.render.text(),
			},
		],
		columns: [
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px" },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px", render: renderDomElement },
			{ sWidth: "50px" },
		],
		language: strings.DATATABLE,
	});

	var commandLog = result.CommandLog;
	renderIDPCommandLog(commandLog.Commands);

	console.log(result);
	var serviceLists = result.ServiceLists;
	var serviceVersions = serviceLists.Versions;
	var serviceListData = [];
	var activeServices = null;

	for (var i = 0; i < serviceLists.Responses.length; i++) {
		var item = serviceLists.Responses[i];
		if (item.ServiceList != null) {
			if (activeServices == null) {
				activeServices = {};
				if (item.ServiceList.Report == true) {
					activeServices.Report = serviceVersions.Report;
				}
				if (item.ServiceList.Position == true) {
					activeServices.Position = serviceVersions.Position;
				}
				if (item.ServiceList.AVL == true) {
					activeServices.AVL = serviceVersions.AVL;
				}
				if (item.ServiceList.GarminFMI == true) {
					activeServices.GarminFMI = serviceVersions.GarminFMI;
				}
				if (item.ServiceList.GarminDispatch == true) {
					activeServices.GarminDispatch = serviceVersions.GarminDispatch;
				}
				if (item.ServiceList.GarminExt == true) {
					activeServices.GarminExt = serviceVersions.GarminExt;
				}
				if (item.ServiceList.J1939 == true) {
					activeServices.J1939 = serviceVersions.J1939;
				}
				if (item.ServiceList.Analytics == true) {
					activeServices.Analytics = serviceVersions.Analytics;
				}
				if (item.ServiceList.ARC == true) {
					activeServices.ARC = serviceVersions.ARC;
				}
				if (item.ServiceList.Utility == true) {
					activeServices.Utility = serviceVersions.Utility;
				}
				if (item.ServiceList.PackageVersion != null) {
					activeServices.PackageVersion = item.ServiceList.PackageVersion;
				}
				if (item.ServiceList.FLS == true) {
					activeServices.FLS = serviceVersions.FLS;
				}
				if (item.ServiceList.VMS == true) {
					activeServices.VMS = serviceVersions.VMS;
				}
				if (item.ServiceList.Unibox == true) {
					activeServices.Unibox = serviceVersions.Unibox;
				}
				if (item.ServiceList.Updater == true) {
					activeServices.Updater = serviceVersions.Updater;
				}
				if (item.ServiceList.Crypto == true) {
					activeServices.Crypto = serviceVersions.Crypto;
				}
			}
			serviceListData.push([
				disabledCheckboxEl(item.ServiceList.Report),
				disabledCheckboxEl(item.ServiceList.Position),
				disabledCheckboxEl(item.ServiceList.AVL),
				disabledCheckboxEl(item.ServiceList.GarminFMI),
				disabledCheckboxEl(item.ServiceList.GarminDispatch),
				disabledCheckboxEl(item.ServiceList.GarminExt),
				disabledCheckboxEl(item.ServiceList.J1939),
				disabledCheckboxEl(item.ServiceList.Analytics),
				disabledCheckboxEl(item.ServiceList.Utility),
				disabledCheckboxEl(item.ServiceList.ARC),
				disabledCheckboxEl(item.ServiceList.FLS),
				disabledCheckboxEl(item.ServiceList.VMS),
				disabledCheckboxEl(item.ServiceList.Unibox),
				disabledCheckboxEl(item.ServiceList.EyeAlert),
				disabledCheckboxEl(item.ServiceList.Immobilizer),
				disabledCheckboxEl(item.ServiceList.Updater),
				disabledCheckboxEl(item.ServiceList.Crypto),
				item.CreatedOn,
			]);
		}
	}
	// get latest Service List (if any)
	if (serviceLists.Responses.length > 0) {
		var latestServiceList = serviceLists.Responses[0].ServiceList;
		var body = document.getElementById("IDPServiceListing").querySelector("tbody");
		var sins = [];
		var serviceListNames = [];
		serviceListNames[19] = "Report";
		serviceListNames[20] = "Position";
		serviceListNames[65] = "ARC";
		serviceListNames[115] = "VMS";
		serviceListNames[121] = "J1939";
		serviceListNames[122] = "Analytics";
		serviceListNames[124] = "GarminDispatch";
		serviceListNames[125] = "GarminFMI";
		serviceListNames[126] = "AVL";
		serviceListNames[127] = "Utility";
		serviceListNames[128] = "GarminExt";
		serviceListNames[130] = "FLS";
		serviceListNames[136] = "GSE_CustomFields";
		serviceListNames[148] = "GSE_EyeAlert";
		serviceListNames[165] = "Unibox";
		serviceListNames[160] = "GSE_Immobilizer";
		serviceListNames[199] = "CellMonitor";
		serviceListNames[224] = "GSE_Garmin";
		serviceListNames[226] = "GSE_AAT";
		serviceListNames[228] = "GSE_Updater";
		serviceListNames[255] = "GSE_Crypto";

		for (var sin in serviceListNames) {
			if (latestServiceList.EnabledSINs.indexOf(parseInt(sin)) != -1) {
				if (sins.indexOf(parseInt(sin)) == -1) {
					sins.push(parseInt(sin));
				}
			} else if (latestServiceList.DisabledSINs.indexOf(parseInt(sin)) != -1) {
				if (sins.indexOf(sin) == -1) {
					sins.push(parseInt(sin));
				}
			}
		}
		// add user custom services (SIN > 130)
		for (var i = 0; i < latestServiceList.EnabledSINs.length; i++) {
			if (latestServiceList.EnabledSINs[i] > 130) {
				if (sins.indexOf(parseInt(latestServiceList.EnabledSINs[i])) == -1) {
					sins.push(parseInt(latestServiceList.EnabledSINs[i]));
				}
			}
		}
		for (var i = 0; i < latestServiceList.DisabledSINs.length; i++) {
			if (latestServiceList.DisabledSINs[i] > 130) {
				if (sins.indexOf(parseInt(latestServiceList.DisabledSINs[i])) == -1) {
					sins.push(parseInt(latestServiceList.DisabledSINs[i]));
				}
			}
		}

		var rows = [];
		for (var i = 0; i < sins.length; i++) {
			var sin = sins[i];
			var disabled = latestServiceList.DisabledSINs.indexOf(parseInt(sin)) !== -1;
			var name = serviceListNames[sin];
			rows.push(
				el("tr", [
					el("td"),
					el("td", sin),
					el("td", name),
					el("td", disabledCheckboxEl(!disabled)),
					el("td", [
						el("div.form-check", [
							el("input#SIN" + sin + "Enable.form-check-input.enable", {
								type: "radio",
								name: "SIN" + sin,
								value: "1",
								dataset: { sin: sin },
							}),
							el("label.form-check-label", { for: "SIN" + sin + "Enable" }, strings.ENABLE),
						]),
						el("div.form-check", [
							el("input#SIN" + sin + "Disable.form-check-input.disable", {
								type: "radio",
								name: "SIN" + sin,
								value: "0",
								dataset: { sin: sin },
							}),
							el("label.form-check-label", { for: "SIN" + sin + "Disable" }, strings.DISABLE),
						]),
					]),
				])
			);
		}
		setChildren(body, rows);
	}

	$j("#IDPAgentVersions li").hide();
	$j("#IDPAgentPackageVersion").hide();
	if (activeServices != null) {
		console.log(activeServices);
		if (typeof activeServices.Report != "undefined") {
			$j("#IDPAgentVersionReport")
				.show()
				.find("span.version")
				.text(activeServices.Report == null ? strings.UNKNOWN : activeServices.Report);
		}
		if (typeof activeServices.Position != "undefined") {
			$j("#IDPAgentVersionPosition")
				.show()
				.find("span.version")
				.text(activeServices.Position == null ? strings.UNKNOWN : activeServices.Position);
		}
		if (typeof activeServices.FLS != "undefined") {
			$j("#IDPAgentVersionFLS")
				.show()
				.find("span.version")
				.text(activeServices.FLS == null ? strings.UNKNOWN : activeServices.FLS);
		}
		if (typeof activeServices.AVL != "undefined") {
			$j("#IDPAgentVersionAVL")
				.show()
				.find("span.version")
				.text(activeServices.AVL == null ? strings.UNKNOWN : activeServices.AVL);
		}
		if (typeof activeServices.GarminDispatch != "undefined") {
			$j("#IDPAgentVersionGarminDispatch")
				.show()
				.find("span.version")
				.text(activeServices.GarminDispatch == null ? strings.UNKNOWN : activeServices.GarminDispatch);
		}
		if (typeof activeServices.ARC != "undefined") {
			$j("#IDPAgentVersionARC")
				.show()
				.find("span.version")
				.text(activeServices.ARC == null ? strings.UNKNOWN : activeServices.ARC);
		}
		if (typeof activeServices.GarminFMI != "undefined") {
			$j("#IDPAgentVersionGarminFMI")
				.show()
				.find("span.version")
				.text(activeServices.GarminFMI == null ? strings.UNKNOWN : activeServices.GarminFMI);
		}
		if (typeof activeServices.GarminExt != "undefined") {
			$j("#IDPAgentVersionGarminExt")
				.show()
				.find("span.version")
				.text(activeServices.GarminExt == null ? strings.UNKNOWN : activeServices.GarminExt);
		}
		if (typeof activeServices.J1939 != "undefined") {
			$j("#IDPAgentVersionJ1939")
				.show()
				.find("span.version")
				.text(activeServices.J1939 == null ? strings.UNKNOWN : activeServices.J1939);
		}
		if (typeof activeServices.Analytics != "undefined") {
			$j("#IDPAgentVersionAnalytics")
				.show()
				.find("span.version")
				.text(activeServices.Analytics == null ? strings.UNKNOWN : activeServices.Analytics);
		}
		if (typeof activeServices.Utility != "undefined") {
			$j("#IDPAgentVersionUtility")
				.show()
				.find("span.version")
				.text(activeServices.Utility == null ? strings.UNKNOWN : activeServices.Utility);
		}
		if (typeof activeServices.VMS != "undefined") {
			$j("#IDPAgentVersionVMS")
				.show()
				.find("span.version")
				.text(activeServices.VMS == null ? strings.UNKNOWN : activeServices.VMS);
		}
		if (typeof activeServices.Unibox != "undefined") {
			$j("#IDPAgentVersionUnibox")
				.show()
				.find("span.version")
				.text(activeServices.Unibox == null ? strings.UNKNOWN : activeServices.Unibox);
		}
		if (typeof activeServices.Updater != "undefined") {
			$j("#IDPAgentVersionUpdater")
				.show()
				.find("span.version")
				.text(activeServices.Updater == null ? strings.UNKNOWN : activeServices.Updater);
		}
		if (typeof activeServices.Crypto != "undefined") {
			$j("#IDPAgentVersionCrypto")
				.show()
				.find("span.version")
				.text(activeServices.Crypto == null ? strings.UNKNOWN : activeServices.Crypto);
		}
		if (typeof activeServices.PackageVersion != "undefined") {
			var version = activeServices.PackageVersion == null ? strings.UNKNOWN : activeServices.PackageVersion;
			$j("#IDPAgentPackageVersion").show().find("span").text(version);
		}
	}

	$("#IDPServiceLists").dataTable({
		data: serviceListData,
		scrollX: "100%",
		scrollCollapse: false,
		destroy: true,
		filter: false,
		info: false,
		jQueryUI: true,
		autoWidth: false,
		lengthChange: false,
		paging: true,
		pageLength: 3,
		deferRender: true,
		order: [[17, "desc"]],
		columnDefs: [
			{
				targets: "_all",
				render: renderDomElement,
			},
		],
		columns: [
			{},
			{},
			{},
			{},
			{},
			{},
			{},
			{},
			{},
			{},
			{},
			{},
			{},
			{},
			{},
			{},
			{},
			{ render: $.fn.dataTable.render.text() },
		],
		language: strings.DATATABLE,
	});

	var geofenceStatus = result.GeofenceStatus;
	if (geofenceStatus.Status != null) {
		$j("#IDPGeofenceStatusDeviceLastRetrieved").text(geofenceStatus.Status.CreatedOn);
	}
	if (geofenceStatus.Combined != null) {
		var geoStatusData = [];
		for (var i = 0; i < geofenceStatus.Combined.length; i++) {
			var item = geofenceStatus.Combined[i];
			var enabled = null;
			if (item.Enabled) {
				enabled = el("button.DisableGeofence", { dataset: { fenceId: item.DeviceId } }, strings.DISABLE);
			}
			geoStatusData.push([
				item.DeviceId,
				disabledCheckboxEl(item.Enabled),
				disabledCheckboxEl(item.Inside),
				disabledCheckboxEl(item.Outside),
				item.PortalId,
				item.Fence,
				item.Alert,
				item.Filters,
				enabled,
			]);
		}
		$("#IDPGeofenceStatus").dataTable({
			data: geoStatusData,
			destroy: true,
			filter: false,
			info: false,
			jQueryUI: true,
			autoWidth: false,
			lengthChange: false,
			paging: false,
			pageLength: 10,
			deferRender: true,
			columnDefs: [
				{
					targets: "_all",
					render: $.fn.dataTable.render.text(),
				},
			],
			columns: [
				{},
				{ render: renderDomElement },
				{ render: renderDomElement },
				{ render: renderDomElement },
				{},
				{},
				{},
				{},
				{ render: renderDomElement },
			],
			language: strings.DATATABLE,
			drawCallback: function (oSettings) {},
		});
	}

	// sleep schedules
	var modemRegistrations = result.ModemRegistrations;
	var modemData = [];

	for (var i = 0; i < modemRegistrations.Responses.length; i++) {
		var item = modemRegistrations.Responses[i];
		if (item.ModemRegistration != null) {
			modemData.push([
				item.ModemRegistration.HardwareMajorVersion,
				item.ModemRegistration.HardwareMinorVersion,
				item.ModemRegistration.SoftwareMajorVersion,
				item.ModemRegistration.SoftwareMinorVersion,
				item.ModemRegistration.Product,
				item.ModemRegistration.WakeupPeriod,
				item.ModemRegistration.LastResetReason,
				item.ModemRegistration.VirtualCarrier,
				item.ModemRegistration.Beam,
				item.ModemRegistration.Vain,
				item.ModemRegistration.OperatorTxState,
				item.ModemRegistration.UserTxState,
				item.ModemRegistration.BroadcastIDCount,
				item.CreatedOn,
			]);
		}
	}

	$("#IDPModemRegistrations").dataTable({
		data: modemData,
		scrollX: "100%",
		scrollCollapse: false,
		destroy: true,
		filter: false,
		info: false,
		jQueryUI: true,
		autoWidth: false,
		lengthChange: false,
		paging: true,
		pageLength: 3,
		deferRender: true,
		order: [[13, "desc"]],
		columnDefs: [
			{
				targets: "_all",
				render: $.fn.dataTable.render.text(),
			},
		],
		columns: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
		language: strings.DATATABLE,
	});

	// modem registrations
	var sleepSchedules = result.SleepSchedules;
	var sleepData = [];

	for (var i = 0; i < sleepSchedules.Responses.length; i++) {
		var item = sleepSchedules.Responses[i];
		if (item.SleepSchedule != null) {
			sleepData.push([
				item.SleepSchedule.WakeupPeriod,
				disabledCheckboxEl(item.SleepSchedule.MobileInitiated),
				item.SleepSchedule.MessageReference,
				item.CreatedOn,
			]);
		}
	}

	$("#IDPSleepSchedules").dataTable({
		data: sleepData,
		scrollX: "100%",
		scrollCollapse: false,
		destroy: true,
		filter: false,
		info: false,
		jQueryUI: true,
		autoWidth: false,
		lengthChange: false,
		paging: true,
		pageLength: 3,
		deferRender: true,
		order: [[3, "desc"]],
		columnDefs: [
			{
				targets: "_all",
				render: $.fn.dataTable.render.text(),
			},
		],
		columns: [{}, { render: renderDomElement }, {}, {}],
		language: strings.DATATABLE,
	});

	// gsm health checks
	var healthChecks = result.GSMHealthChecks;
	var itemData = [];

	for (var i = 0; i < healthChecks.Responses.length; i++) {
		var item = healthChecks.Responses[i];
		if (item.HealthCheck != null) {
			itemData.push([
				item.HealthCheck.ActiveSIM,
				disabledCheckboxEl(item.HealthCheck.NetworkAllowed),
				item.HealthCheck.LinkStability,
				item.HealthCheck.RSSI,
				disabledCheckboxEl(item.HealthCheck.PowerOnStatus),
				disabledCheckboxEl(item.HealthCheck.CoverOpen),
				item.HealthCheck.SIMStatus,
				item.HealthCheck.NetworkStatus,
				disabledCheckboxEl(item.HealthCheck.APNConnected),
				disabledCheckboxEl(item.HealthCheck.ServerConnected),
				item.HealthCheck.CurrentMode,
				disabledCheckboxEl(item.HealthCheck.SIM1Present),
				disabledCheckboxEl(item.HealthCheck.SIM2Present),
				item.CreatedOn, //,
				//item.Source
			]);
		}
	}

	$("#GSMHealthChecks").dataTable({
		data: itemData,
		scrollX: "100%",
		scrollCollapse: false,
		destroy: true,
		filter: false,
		info: false,
		jQueryUI: true,
		autoWidth: false,
		lengthChange: false,
		paging: true,
		pageLength: 3,
		deferRender: true,
		order: [[12, "desc"]],
		columnDefs: [
			{
				targets: "_all",
				render: $.fn.dataTable.render.text(),
			},
		],
		columns: [
			{},
			{ render: renderDomElement },
			{},
			{},
			{ render: renderDomElement },
			{ render: renderDomElement },
			{},
			{},
			{ render: renderDomElement },
			{ render: renderDomElement },
			{},
			{
				visible:
					asset.DeviceId == devices.SKYWAVE_782 ||
					asset.DeviceId == devices.SKYWAVE_782_CELL ||
					asset.DeviceId == devices.SKYWAVE_ST9100 ||
					asset.DeviceId == devices.ORBCOMM_ST9101,
				render: renderDomElement,
			}, // 782-only
			{
				visible:
					asset.DeviceId == devices.SKYWAVE_782 ||
					asset.DeviceId == devices.SKYWAVE_782_CELL ||
					asset.DeviceId == devices.SKYWAVE_ST9100 ||
					asset.DeviceId == devices.ORBCOMM_ST9101,
				render: renderDomElement,
			}, // 782-only
			{}, //,
			//{}
		],
		language: strings.DATATABLE,
	});
}
