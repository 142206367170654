import { ScalarField } from "../fields/Field.mjs";
/**
 * @namespace intensify
 * @inherits Symbol Decorator
 * @relationship drawnOn ScalarField
 *
 * Takes a symbol that is normally rendered as a single solid colour (including
 * `Fill`, `Stroke`, `Circle`, etc), and makes it render into a `ScalarField`.
 * The symbol loses its `colour` option but gains an `intensity` option.
 *
 * The symbol will work similar to `HeatPoint` or `HeatStroke` in the sense
 * that it adds its intensity to the scalar field that is given colour later
 * (e.g. `HeatMapAcetate`); the symbol must be added to such a scalar field
 * instead of being added directly to the map. Unlike `HeatPoint` or `HeatStroke`
 * (which apply a linear fall-off to the intensity), the intensity an
 * intensified symbol adds to the field is constant in all its pixels.
 *
 * Does **not** work on symbols with more than a colour (e.g. `Pie`, `Halo`) nor or those
 * depending on a image/texture (e.g. `Sprite`, `TextLabel`)
 */

export default function intensify(base) {
	if (!("_parseColour" in base)) {
		throw new Error(
			`The symbol class to be intensified (${base.constructor.name}) doesn't seem to be one with a single solid colour`
		);
	}

	class IntensifiedAcetate extends base.Acetate {
		static get PostAcetate() {
			return ScalarField;
		}

		constructor(target, opts) {
			super(target, opts);

			// This uses the same trick as AcetateHeatStroke: modify the main
			// attribute storage (which should be an InterleavedAttributes)
			// so that the colour, which *should* always be in the 1st slot,
			// stops being a `vec4`+`Uint8Array` and becomes a `float`+`Float32Array`

			const fields = this._attrs._fields;
			fields[0] = {
				glslType: "float",
				type: Float32Array,
			};
			this._attrs = new this.glii.InterleavedAttributes(
				{
					size: 1,
					growFactor: 1.2,
					usage: this.glii.STATIC_DRAW,
				},
				fields
			);
		}

		glProgramDefinition() {
			const opts = super.glProgramDefinition();

			const search = /vColour\s*=\s*aColour\s*;/g;
			const replacement = "vColour = vec4(aColour, 0., 0., 1.);";

			return {
				...opts,
				attributes: {
					...opts.attributes,
					aColour: this._attrs?.getBindableAttribute(0),
				},
				varyings: {
					...opts.varyings,
					vColour: "vec4",
					// vColour: "float"
				},
				target: this._inAcetate.framebuffer,
				blend: {
					// See notes about blend mode in AcetateHeatStroke

					/// TODO: Some option to turn on alpha on the blend equation.
					/// Normally an intensified symbol will just dump the colour
					/// into gl_FragColor, but some might benefit from enabling
					/// alpha blending (multiply intensity by alpha) if they've
					/// got a shader that manages alpha.

					equationRGB: this.glii.FUNC_ADD,
					equationAlpha: this.glii.FUNC_ADD,
					srcRGB: this.glii.ONE,
					srcAlpha: this.glii.ZERO,
					dstRGB: this.glii.ONE,
					dstAlpha: this.glii.ZERO,
				},
				vertexShaderMain: opts.vertexShaderMain.replace(search, replacement),
				// fragmentShaderSource: "void main() { gl_FragColor.r = 100.; }"
			};
		}
		resize(x, y) {
			super.resize(x, y);
			this._program._target = this._inAcetate.framebuffer;
		}
	}

	/**
	 * @miniclass IntensifiedSymbol (intensify)
	 *
	 * An "intensified" symbol accepts these additional constructor options:
	 */
	class IntensifiedSymbol extends base {
		static Acetate = IntensifiedAcetate;

		constructor(
			geom,
			{
				/**
				 * @option intensity: Number = 1
				 * Intensity applied to the scalar field on all pixels corresponding
				 * to this symbol.
				 */
				intensity = 1,
				...opts
			}
		) {
			super(geom, { ...opts, colour: intensity });
		}

		static _parseColour(intensity) {
			return [intensity];
		}
	}

	return IntensifiedSymbol;
}
