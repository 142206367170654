import { updateBadgesWithCounts } from "./badges.js";
import domNodes from "./domNodes";
import { trkDataGroups } from "./const.js";
import trkData from "./data.js";
import { getGroupAssetStatus } from "./asset-group.js";
import state from "./state.js";
import { mapModes } from "./const.js";
import { findAssetById } from "./assets.js";
import { findGroupById } from "./asset-group.js";

import _ from "lodash";

export function updateNotificationsInSecondaryPanel(type, itemId, counts) {
	var panel = domNodes.panels.secondary;
	if (panel.getAttribute("data-item-type") !== type) {
		return;
	}
	if (panel.getAttribute("data-item-id") !== itemId.toString()) {
		return;
	}

	// update notification counts for tabs in secondary panel
	var tabs = document.getElementById("panel-secondary-nav-tabs");
	var badgePositions = tabs.querySelector("#nav-positions-tab .badge");
	var badgeAlerts = tabs.querySelector("#nav-alerts-tab .badge");
	var badgeEvents = tabs.querySelector("#nav-events-tab .badge");
	var badgeStatus = tabs.querySelector("#nav-status-tab .badge");
	var badgeChat = tabs.querySelector("#nav-chat-tab .badge");
	var badgeMessages = tabs.querySelector("#nav-messages-tab .badge");

	updateBadgesWithCounts(badgePositions, badgeAlerts, badgeEvents, badgeStatus, badgeChat, badgeMessages, counts);
}

export function getNotificationCountsForGroup(dataGroup, groupId) {
	//console.log('getNotificationCountsForGroup', groupId, dataGroup);
	// get all unique assets under group, including subgroups
	var itemIds = [];
	if (groupId === "all-assets") {
		itemIds = _.map(trkData.assets, "Id");
	} else {
		if (dataGroup === trkDataGroups.JOURNEY_HISTORY) {
			groupId = "journey-" + groupId;
		} else if (dataGroup === trkDataGroups.SHARED_VIEW_HISTORY) {
			groupId = "shared-view-" + groupId;
		}
		var status = getGroupAssetStatus(groupId);
		if (dataGroup == trkDataGroups.JOURNEY_HISTORY) {
			itemIds = status.tripIds;
		} else if (dataGroup === trkDataGroups.SHARED_VIEW_HISTORY) {
			itemIds = status.sharedViewIds;
		} else {
			itemIds = status.assetIds;
		}
	}
	var sum = {
		positions: 0,
		events: 0,
		alerts: 0,
		status: 0,
		messages: 0,
		messagesChat: 0,
		messagesDevice: 0,
		hasHistoryResult: false,
		hasEmergency: false,
	};

	itemIds.forEach(function (itemId) {
		var counts = getNotificationCounts(dataGroup, itemId);
		sum.positions += counts.positions;
		sum.events += counts.events;
		sum.alerts += counts.alerts;
		sum.status += counts.status;
		sum.messages += counts.messages;
		sum.messagesChat += counts.messagesChat;
		sum.messagesDevice += counts.messagesDevice;
		if (counts.hasHistoryResult) {
			sum.hasHistoryResult = true;
		}
		if (counts.hasEmergency) {
			sum.hasEmergency = true;
		}
	});
	return sum;
	// get notification counts for each asset in this mode
	// sum them up for the group
}

export function getNotificationCounts(dataGroup, itemId) {
	//console.log('getNotificationCounts', dataGroup, itemId);
	var positions = 0;
	var alerts = 0;
	var events = 0;
	var status = 0;
	var messages = 0;
	var messagesChat = 0;
	var messagesDevice = 0;

	var assetEvents = [];
	var hasHistoryResult = true;
	if (dataGroup === trkDataGroups.NORMAL_LIVE) {
		// todo: this should return unread counts only, not the sum of events that are recorded
		if (trkData.live.normalizedPositionsByAssetId[itemId] !== undefined) {
			positions = trkData.live.normalizedPositionsByAssetId[itemId].length;
		}
		if (trkData.live.normalizedEventsByAssetId[itemId] !== undefined) {
			assetEvents = trkData.live.normalizedEventsByAssetId[itemId];
		}
		if (trkData.live.messageCountsByAssetId[itemId] !== undefined) {
			messages =
				trkData.live.messageCountsByAssetId[itemId].FromMobile + trkData.live.messageCountsByAssetId[itemId].ToMobile;
			messagesChat =
				trkData.live.messageCountsByAssetId[itemId].FromMobileChats +
				trkData.live.messageCountsByAssetId[itemId].ToMobileChats;
			messagesDevice =
				trkData.live.messageCountsByAssetId[itemId].FromMobileDevice +
				trkData.live.messageCountsByAssetId[itemId].ToMobileDevice;
		}
	} else if (dataGroup === trkDataGroups.NORMAL_HISTORY) {
		if (trkData.history.normalizedPositionsByAssetId[itemId] !== undefined) {
			positions = trkData.history.normalizedPositionsByAssetId[itemId].length;
			hasHistoryResult = trkData.history.assetIdsWithResults[itemId] !== undefined;
		}
		if (trkData.history.normalizedEventsByAssetId[itemId] !== undefined) {
			assetEvents = trkData.history.normalizedEventsByAssetId[itemId];
		}
		if (trkData.history.messageCountsByAssetId[itemId] !== undefined) {
			messages =
				trkData.history.messageCountsByAssetId[itemId].FromMobile +
				trkData.history.messageCountsByAssetId[itemId].ToMobile;
			messagesChat =
				trkData.history.messageCountsByAssetId[itemId].FromMobileChats +
				trkData.history.messageCountsByAssetId[itemId].ToMobileChats;
			messagesDevice =
				trkData.history.messageCountsByAssetId[itemId].FromMobileDevice +
				trkData.history.messageCountsByAssetId[itemId].ToMobileDevice;
		}
	} else if (dataGroup === trkDataGroups.JOURNEY_HISTORY) {
		if (trkData.trips.normalizedPositionsByTripId[itemId] !== undefined) {
			positions = trkData.trips.normalizedPositionsByTripId[itemId].length;
			hasHistoryResult = trkData.trips.tripIdsWithResults[itemId] !== undefined;
		}
		if (trkData.trips.normalizedEventsByTripId[itemId] !== undefined) {
			assetEvents = trkData.trips.normalizedEventsByTripId[itemId];
		}
		if (trkData.trips.messageCountsByTripId[itemId] !== undefined) {
			messages =
				trkData.trips.messageCountsByTripId[itemId].FromMobile + trkData.trips.messageCountsByTripId[itemId].ToMobile;
			messagesChat =
				trkData.trips.messageCountsByTripId[itemId].FromMobileChats +
				trkData.trips.messageCountsByTripId[itemId].ToMobileChats;
			messagesDevice =
				trkData.trips.messageCountsByTripId[itemId].FromMobileDevice +
				trkData.trips.messageCountsByTripId[itemId].ToMobileDevice;
		}
	} else if (dataGroup === trkDataGroups.SHARED_VIEW_HISTORY) {
		positions = trkData.sharedView.normalizedPositions.length;
		assetEvents = trkData.sharedView.normalizedEvents;
		messages = trkData.sharedView.messageCounts.FromMobile + trkData.sharedView.messageCounts.ToMobile;
		messagesChat = trkData.sharedView.messageCounts.FromMobileChats + trkData.sharedView.messageCounts.ToMobileChats;
		messagesDevice =
			trkData.sharedView.messageCounts.FromMobileDevice + trkData.sharedView.messageCounts.ToMobileDevice;
	}

	assetEvents = _.filter(assetEvents, function (event) {
		return !event.Event.Hide;
	});
	events = assetEvents.length;
	// alerts
	var textEvents = _.filter(assetEvents, function (event) {
		return !event.Event.Hide && trkData.EVENTS_TEXT_MESSAGE.indexOf(event.Event.Type) !== -1;
	});
	var alertEvents = _.filter(assetEvents, function (event) {
		return !event.Event.Hide && trkData.EVENTS_ALERT.indexOf(event.Event.Type) !== -1;
	});
	var emergencyEvents = _.filter(assetEvents, function (event) {
		return !event.Event.Hide && trkData.EVENTS_EMERGENCY.indexOf(event.Event.Type) !== -1;
	});
	var hasEmergency = emergencyEvents.length > 0;
	alerts = alertEvents.length + emergencyEvents.length;
	// status - really a change in position .State
	// but we'll just go with specific event types for now as the event may not have an associated position
	var statusEvents = _.filter(assetEvents, function (event) {
		return !event.Event.Hide && trkData.EVENTS_STATUS.indexOf(event.Event.Type) !== -1;
	});
	status = statusEvents.length;

	events = events - alerts - status - textEvents.length;

	var result = {
		positions: positions,
		events: events,
		alerts: alerts,
		status: status,
		messages: messages,
		messagesChat: messagesChat,
		messagesDevice: messagesDevice,
		hasHistoryResult: hasHistoryResult,
		hasEmergency: hasEmergency,
	};
	return result;
}

export const NOTIFICATION_TYPES = ["positions", "events", "alerts", "messages", "status"];
export const NOTIFICATION_PREFIXES = {
	positions: {
		prefix: "op",
		classes: ["op-10", "op-20", "op-30", "op-40", "op-50", "op-60", "op-70", "op-80", "op-90", "op-100"],
	},
	alerts: {
		prefix: "oa",
		classes: ["oa-10", "oa-20", "oa-30", "oa-40", "oa-50", "oa-60", "oa-70", "oa-80", "oa-90", "oa-100"],
	},
	events: {
		prefix: "oe",
		classes: ["oe-10", "oe-20", "oe-30", "oe-40", "oe-50", "oe-60", "oe-70", "oe-80", "oe-90", "oe-100"],
	},
	messages: {
		prefix: "om",
		classes: ["om-10", "om-20", "om-30", "om-40", "om-50", "om-60", "om-70", "om-80", "om-90", "om-100"],
	},
	status: {
		prefix: "os",
		classes: ["os-10", "os-20", "os-30", "os-40", "os-50", "os-60", "os-70", "os-80", "os-90", "os-100"],
	},
};

export function getNotificationOpacityForTime(time) {
	if (time === null) {
		return null;
	}
	//var serverTime = new Date().getTime() - user.tickOffset;
	var currentTime = new Date().getTime();
	var difference = Math.abs(currentTime - time);
	if (difference > 3600000) {
		return null;
	}

	var percent = 100 - Math.floor((difference / 3600000) * 100);
	var opacity = Math.round(percent / 10) * 10; // round to nearest 10
	return opacity;
}

export function updateTimeBasedNotificationIndicatorsForNode(item, opacity, type) {
	var recentType = "recent-" + type;

	//var classesToRemove = _.union([recentType], NOTIFICATION_PREFIXES[type].classes);
	var classesToRemove = [recentType].concat(NOTIFICATION_PREFIXES[type].classes);
	var classNames = [recentType];
	if (opacity !== null) {
		classNames.push(NOTIFICATION_PREFIXES[type].prefix + "-" + opacity);
	}

	if (opacity === null) {
		// remove all indicators for type, if any
		if (!item.classList.contains(recentType + "-no")) {
			classesToRemove.forEach(function (cls) {
				if (item.classList.contains(cls)) {
					item.classList.remove(cls);
				}
			});
			item.classList.add(recentType + "-no");
		}

		// bugfix here, remove this by ensuring recent-type-no is not added when recent-type is active
		if (item.classList.contains(recentType)) {
			item.classList.remove(recentType);
		}
	} else {
		// ensure classNames are added and others removed
		var isRemoved = false;
		classesToRemove = _.difference(classesToRemove, classNames);
		classNames.forEach(function (className) {
			if (!item.classList.contains(className)) {
				if (!isRemoved) {
					if (!item.classList.contains(recentType + "-no")) {
						classesToRemove.forEach(function (cls) {
							if (item.classList.contains(cls)) {
								item.classList.remove(cls);
							}
						});
					} else {
						item.classList.remove(recentType + "-no");
					}
					isRemoved = true;
				}
				item.classList.add(className);
			}
		});
	}
}

export function updateTimeBasedNotificationIndicatorsForGroup(groupId) {
	//console.log('update time notification indicator for ' + groupId);
	// get max times for all assets within the group
	var node = domNodes.groups[groupId];
	if (node === undefined) {
		return;
	}

	let item = node.querySelector(".notifications");

	var assetIds = [];
	var assets = [];
	var group = null;
	if (groupId === "all-assets") {
		assetIds = _.map(trkData.assets, "Id");
	} else {
		group = findGroupById(groupId);
		assetIds = group.AssetIds;
	}

	assetIds.forEach(function (assetId) {
		assets.push(findAssetById(assetId));
	});

	var notificationTypes = NOTIFICATION_TYPES;

	notificationTypes.forEach(function (itemType) {
		var recentType = "recent-" + itemType;

		if (state.activeMapMode !== mapModes.LIVE) {
			// history mode does not support fading icons
			// remove any opacity classes from history mode
			if (!item.classList.contains(recentType + "-no")) {
				NOTIFICATION_PREFIXES[itemType].classes.forEach(function (cls) {
					if (item.classList.contains(cls)) {
						item.classList.remove(cls);
					}
				});
				item.classList.add(recentType + "-no");
			}
			return;
		}

		// add/upate indicators for LIVE mode
		var opacity = null;

		// check times for all assets within this group
		assets.forEach(function (asset) {
			if (trkData.live.notificationTimesByAssetId[asset.Id] !== undefined) {
				var assetOpacity = getNotificationOpacityForTime(trkData.live.notificationTimesByAssetId[asset.Id][itemType]);
				if (assetOpacity !== null && (opacity === null || assetOpacity > opacity)) {
					opacity = assetOpacity;
				}
			}
		});

		updateTimeBasedNotificationIndicatorsForNode(item, opacity, itemType);
	});
}
