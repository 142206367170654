<li class="shared-views-item group-item shared-view-{{id}} {{visibilityClass}}" data-shared-view-id="{{id}}">
    <div class="item-header d-flex flex-row align-items-center">
        <svg class="list-item-icon" style="color: {{color}}"><use xlink:href="/content/svg/tracking.svg?v=15#share-alt-square"></use></svg>
        <div class="item-info expand mr-auto">
            <div class="shared-view-name list-item-name">{{name}}</div>
            <div class="shared-view-description list-item-description">{{description}}</div>
        </div>
        <svg class="list-item-action item-drag"><use xlink:href="/content/svg/tracking.svg?v=15#sort"></use></svg>
        <svg class="list-item-action item-settings" data-shared-view-id="{{id}}"><use xlink:href="/content/svg/tracking.svg?v=15#settings"></use></svg>
    </div>
</li>
