import trkData from "./data.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import { createPositionLinesForAsset, createPositionLinesForTrip } from "./geometry-create.js";
import strings from "./strings.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import state from "./state.js";
import { viewModes, mapModes, trkDataGroups } from "./const.js";
import { findAssetById } from "./assets.js";
import { highlightPosition } from "./positions.js";
import { addPositionMarkerToPoint } from "./marker.js";
import { updateActiveAssetInformation } from "./assets-active.js";
import log from "./log.js";
import { updateTimeBasedNotificationIndicators } from "./asset-notification.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import L from "leaflet";

export function updateAssetPositionLines(asset, clearExisting, viewMode) {
	if (viewMode === undefined || viewMode === null) {
		viewMode = viewModes.NORMAL;
	}

	var assetJourneys = _.filter(trkData.journeys, function (item) {
		return item.AssetId === asset.Id;
	});

	// asset preference for drawn lines may have been updated
	if (!asset.DrawLinesBetweenPositions || clearExisting) {
		// clear any lines drawn for the asset
		if (viewMode === viewModes.NORMAL) {
			if (trkData.live.mapLinesByAssetId[asset.Id] !== undefined) {
				removeItemFromMap(trkData.live.mapLinesByAssetId[asset.Id]);
				delete trkData.live.mapLinesByAssetId[asset.Id];
			}
			if (trkData.history.mapLinesByAssetId[asset.Id] !== undefined) {
				removeItemFromMap(trkData.history.mapLinesByAssetId[asset.Id]);
				delete trkData.history.mapLinesByAssetId[asset.Id];
			}
			_.each(assetJourneys, function (journey) {
				_.each(journey.Trips, function (trip) {
					if (trkData.trips.mapLinesByTripId[trip.Id] !== undefined) {
						removeItemFromMap(trkData.trips.mapLinesByTripId[trip.Id]);
						delete trkData.trips.mapLinesByTripId[trip.Id];
					}
				});
			});
		} else if (viewMode === viewModes.SHARED_VIEW && trkData.sharedView.mapLinesByAssetId[asset.Id] !== undefined) {
			removeItemFromMap(trkData.sharedView.mapLinesByAssetId[asset.Id], null, viewModes.SHARED_VIEW);
			delete trkData.sharedView.mapLinesByAssetId[asset.Id];
		}
	}

	if (asset.DrawLinesBetweenPositions) {
		// draw new lines if preference has been enabled
		if (viewMode === viewModes.NORMAL) {
			if (trkData.history.mapLinesByAssetId[asset.Id] === undefined && trkData.history.positionsByAssetId[asset.Id] !== undefined) {
				var assetVisiblePositions = _.filter(trkData.history.positionsByAssetId[asset.Id].Positions, function (item) {
					return !item.IsHidden;
				});
				createPositionLinesForAsset(asset, assetVisiblePositions, viewModes.NORMAL);
			}

			_.each(assetJourneys, function (journey) {
				_.each(journey.Trips, function (trip) {
					if (
						trkData.trips.mapLinesByTripId[trip.Id] === undefined &&
						trkData.trips.positionsByTripId[trip.Id] !== undefined
					) {
						var tripVisiblePositions = _.filter(trkData.trips.positionsByTripId[trip.Id].Positions, function (item) {
							return !item.IsHidden;
						});
						createPositionLinesForTrip(trip, asset, tripVisiblePositions);
					}
				});
			});
		} else if (viewMode === viewModes.SHARED_VIEW && trkData.sharedView.positionsByAssetId[asset.Id] !== undefined) {
			var assetVisiblePositions = _.filter(trkData.sharedView.positionsByAssetId[asset.Id].Positions, function (item) {
				return !item.IsHidden;
			});
			createPositionLinesForAsset(asset, assetVisiblePositions, viewModes.SHARED_VIEW);
		}
	}
}

export function updateLatestPositionTimeForSharedView() {
	var latest = _.maxBy([trkData.live.latestPosition, trkData.history.latestPosition], function (item) {
		return item == null ? 0 : item.Epoch;
	});
	if (latest != null) {
		$("#shared-view-last-event").text(latest.Time);
	} else {
		$("#shared-view-last-event").text(strings.NEVER);
	}
}

export function showOrLoadAssetPosition(positionId, assetId, message) {
	if (state.activeMapMode === mapModes.LIVE) {
		// check if the position is within our stored history, or is the current live position
		if (highlightPosition(positionId, message)) {
			return;
		}

		var asset = findAssetById(assetId);

		// position not in live markers, check asset live history TODO replace asset.Positions with trkData.live.positionsByAssetId[asset.Id]
		var livePosition = _.find(asset.Positions, function (item) {
			return item.Id === positionId;
		});
		if (livePosition !== undefined) {
			addTemporaryPositionMarker(livePosition, asset, message);
			return;
		}

		// position still not found, query it
		console.log("querying position");
		toggleLoadingMessage(true, "event-position");
		var data = { assetId: assetId, positionId: positionId };
		return $.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/GetPositionById"),
			data: JSON.stringify(data),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				if (msg.d) {
					var position = msg.d;
					if (position == null) {
						return;
					}
					// TODO is asset.Positions a duplicate of trkData.live.positionsByAssetId[asset.Id]?
					if (asset.Positions != null) {
						asset.Positions.push(position);
					} else {
						asset.Positions = [position];
					}

					addTemporaryPositionMarker(position, asset, message);
				}
				toggleLoadingMessage(false, "event-position");
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_LOAD_POSITION_ERROR);
				toggleLoadingMessage(false, "event-position");
			},
		});
	} else {
		// should currently be on the map
		highlightPosition(positionId, message);
	}
}

function addTemporaryPositionMarker(position, asset, message) {
	var positionMarker = trkData.live.markersByPositionId[position.Id];
	var isMarkerAdded = false;
	if (positionMarker === undefined) {
		// create the marker
		positionMarker = addPositionMarkerToPoint(
			[position.Lat, position.Lng],
			false,
			position,
			asset,
			255,
			null,
			null,
			null,
			trkDataGroups.NORMAL_LIVE
		);
		isMarkerAdded = true;
	}
	positionMarker.data.hide = true;
	positionMarker.data.message = message;
	if (!positionMarker.data.location.IsHidden) {
		addItemToMap(positionMarker, null, viewModes.NORMAL);
	}
	positionMarker.fire("click");
	if (isMarkerAdded) {
		trkData.live.markersByAssetId = _.groupBy(trkData.live.markers, function (marker) {
			return marker.data.assetId;
		});
		trkData.live.markersByPositionId = _.keyBy(trkData.live.markers, function (marker) {
			return marker.data.location.Id;
		});
	}
}

export function clearAssetPositions(asset) {
	// remove ui elements
	var li = $j("#assets-all").find("li.asset-" + asset.Id);
	$j("div.locations", li).remove();

	//remove where trkData.history.positions[i].Id == id
	if (trkData.history.positions != null) {
		for (var i = trkData.history.positions.length - 1; i >= 0; i -= 1) {
			if (trkData.history.positions[i].Id == asset.Id) trkData.history.positions.splice(i, 1);
		}
	}

	trkData.history.normalizedPositions = _.reject(trkData.history.normalizedPositions, function (item) {
		return item.AssetId === asset.Id;
	});
	delete trkData.history.assetIdsWithResults[asset.Id];

	//remove where trkData.live.positions[i].AssetId == id
	if (trkData.live.positions != null) {
		for (var i = trkData.live.positions.length - 1; i >= 0; i -= 1) {
			if (trkData.live.positions[i].AssetId == asset.Id) trkData.live.positions.splice(i, 1);
		}
	}

	trkData.live.normalizedPositions = _.reject(trkData.live.normalizedPositions, function (item) {
		return item.AssetId === asset.Id;
	});

	if (trkData.live.latestPositions != null) {
		for (var i = trkData.live.latestPositions.length - 1; i >= 0; i -= 1) {
			if (trkData.live.latestPositions[i].AssetId == asset.Id) trkData.live.latestPositions.splice(i, 1);
		}
	}

	//remove where trkData.markers.assetId == id ... setMap(null)
	if (trkData.live.markers !== null) {
		for (var i = trkData.live.markers.length - 1; i >= 0; i -= 1) {
			var assetId = trkData.live.markers[i].data.assetId;
			if (assetId == asset.Id) {
				removeItemFromMap(trkData.live.markers[i]);
				trkData.live.markers.splice(i, 1);
			}
		}
	}
	if (trkData.history.markers !== null) {
		for (var i = trkData.history.markers.length - 1; i >= 0; i -= 1) {
			var assetId = trkData.history.markers[i].data.assetId;
			if (assetId == asset.Id) {
				removeItemFromMap(trkData.history.markers[i]);
				trkData.history.markers.splice(i, 1);
			}
		}
	}
	if (trkData.live.latestPositionsByAssetId[asset.Id] !== undefined) {
		delete trkData.live.latestPositionsByAssetId[asset.Id];
	}
	if (trkData.live.notificationTimesByAssetId[asset.Id] !== undefined) {
		delete trkData.live.notificationTimesByAssetId[asset.Id];
	}
	trkData.live.positionsByAssetId = _.groupBy(trkData.live.positions, "AssetId");
	trkData.live.normalizedPositionsByAssetId = _.groupBy(trkData.live.normalizedPositions, "AssetId");
	trkData.live.markersByAssetId = _.groupBy(trkData.live.markers, function (marker) {
		return marker.data.assetId;
	});
	trkData.live.markersByPositionId = _.keyBy(trkData.live.markers, function (marker) {
		return marker.data.location.Id;
	});
	trkData.history.positionsByAssetId = _.groupBy(trkData.history.positions, "Id");
	trkData.history.normalizedPositionsByAssetId = _.groupBy(trkData.history.normalizedPositions, "AssetId");
	trkData.history.markersByAssetId = _.groupBy(trkData.history.markers, function (marker) {
		return marker.data.assetId;
	});
	trkData.history.markersByPositionId = _.keyBy(trkData.history.markers, function (marker) {
		return marker.data.location.Id;
	});
	// TODO trips for asset should be deleted here as well

	updateActiveAssetInformation(viewModes.NORMAL);
}

export function updatePositionStatus() {
	log("Update position indicators.");

	//// update message status
	//_.each(domNodes.assets, function (assetNodes, key) {
	//    var asset = findAssetById(key);
	//    updateMessageStatusForAsset(asset);
	//});

	updateTimeBasedNotificationIndicators();
	updateLatestPositionTimeForSharedView();
}
