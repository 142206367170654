function convertHexToRgb(hex) {
	if (hex.indexOf("#") === 0) {
		hex = hex.substring(1);
	}
	var r = parseInt(hex.substr(0, 2), 16);
	var g = parseInt(hex.substr(2, 2), 16);
	var b = parseInt(hex.substr(4, 2), 16);
	return { r: r, g: g, b: b };
}

export function getBackgroundColorAsHex(elem) {
	var bg = null;
	if (elem.currentStyle) {
		bg = elem.currentStyle["backgroundColor"];
	} else if (window.getComputedStyle) {
		bg = document.defaultView.getComputedStyle(elem, null).getPropertyValue("background-color");
	}
	if (bg.search("rgb") == -1) {
		return bg;
	} else {
		return rgbToHex(bg);
	}
}

function rgbToHex(rgb) {
	rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
	function hex(x) {
		return ("0" + parseInt(x).toString(16)).slice(-2);
	}
	return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
}

export function convertHexToSortable(hex) {
	var rgb = convertHexToRgb(hex);
	var lum = Math.sqrt(0.241 * rgb.r + 0.691 * rgb.g + 0.068 * rgb.b);
	var hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
	return { h: parseInt(hsl.h), lum: parseInt(lum * 8), l: parseInt(hsl.l * 8) };
}

// Returns a CSS colour string, used for trajectory lines per asset.
export function convertNamedColorToHex(color) {
	switch (color) {
		case "blue":
			return "#5781fc";
		case "green":
			return "#00e13c";
		case "turquoise":
			return "#57dbdb";
		case "orange":
			return "#fdbf67";
		case "pink":
			return "#e14f9e";
		case "purple":
			return "#7e55fc";
		case "yellow":
			return "#fcf357";
		case "white":
			return "#ffffff";
		case "gold":
			return "#e1c400";
		case "darkred":
			return "#940000";
		case "darkgreen":
			return "#559501";
		case "grey":
			return "#d4d4d4";
		case "darkgrey":
			return "#535353";
		case "brown":
			return "#43312a";
		case "magenta":
			return "#bf00f2";
		case "darkorange":
			return "#c64102";
		case "darkblue":
			return "#034c8b";
		case "red":
		default:
			return "#fd7567";
	}
}


// Returns a array of the form [r, g, b, a], used for the tints of the Gleo TintedSprites.
export function convertNamedColorToArray(color) {
	switch (color) {
		case "blue":
			return [87, 129, 252,255];
		case "green":
			return [0, 225, 60 ,255];
		case "turquoise":
			return [87, 219, 219,255];
		case "orange":
			return [253, 191, 103,255];
		case "pink":
			return [225, 79, 158,255];
		case "purple":
			return [126, 85, 252,255];
		case "yellow":
			return  [252, 243, 87,255];
		case "white":
			return  [255, 255, 255,255];
		case "gold":
			return [225, 196, 0,255];
		case "darkred":
			return [148, 0, 0,255];
		case "darkgreen":
			return  [85, 149, 1,255];
		case "grey":
			return [212, 212, 212,255];
		case "darkgrey":
			return  [83, 83, 83 ,255];
		case "brown":
			return  [67, 49, 42,255];
		case "magenta":
			return [191, 0, 242,255];
		case "darkorange":
			return [198, 65, 2 ,255];
		case "darkblue":
			return [3, 76, 139,255];
		case "red":
			return [253, 117, 103, 255];
		default:
			return color;
	}
}

function rgbToHsl(r, g, b) {
	var min,
		max,
		i,
		l,
		s,
		maxcolor,
		h,
		rgb = [];
	rgb[0] = r / 255;
	rgb[1] = g / 255;
	rgb[2] = b / 255;
	min = rgb[0];
	max = rgb[0];
	maxcolor = 0;
	for (i = 0; i < rgb.length - 1; i++) {
		if (rgb[i + 1] <= min) {
			min = rgb[i + 1];
		}
		if (rgb[i + 1] >= max) {
			max = rgb[i + 1];
			maxcolor = i + 1;
		}
	}
	if (maxcolor == 0) {
		h = (rgb[1] - rgb[2]) / (max - min);
	}
	if (maxcolor == 1) {
		h = 2 + (rgb[2] - rgb[0]) / (max - min);
	}
	if (maxcolor == 2) {
		h = 4 + (rgb[0] - rgb[1]) / (max - min);
	}
	if (isNaN(h)) {
		h = 0;
	}
	h = h * 60;
	if (h < 0) {
		h = h + 360;
	}
	l = (min + max) / 2;
	if (min == max) {
		s = 0;
	} else {
		if (l < 0.5) {
			s = (max - min) / (max + min);
		} else {
			s = (max - min) / (2 - max - min);
		}
	}
	s = s;
	return { h: h.toFixed(0), s: s.toFixed(2), l: l.toFixed(2) };
}

export function getItemHexColor(item) {
	if (item.Color === undefined || item.Color === null || item.Color.length < 7) {
		return "ff0000";
	}

	return item.Color.substring(1);
}
