import strings from "./strings.js";
import trkData from "./data.js";

import $j from "jquery";

export function hash1(imei) {
	var res = 0;
	for (var i = 0; i < imei.length; i++) {
		res ^= ((res << 5) + (res >> 2) + imei.charCodeAt(i)) & 0xffff;
	}
	return zeroFill(res, 5);
}

export function hash2(imei) {
	var res = hash1(imei);
	for (var i = imei.length; i > 0; i--) {
		res ^= ((res << 5) + (res >> 2) + imei.charCodeAt(i - 1)) & 0xffff;
	}

	return zeroFill(res, 5);
	// hash2 must be padded with zeroes to a length of 5
	/*res = res.toString();
    var hashlength = res.length;
    var zeroes = 5 - hashlength;
    for (var i = 0; i < zeroes; i++) {
        res = '0' + res;
    }
    return res;*/
}

/// TODO: replace with String.prototype.padStart().
function zeroFill(number, width) {
	width -= number.toString().length;
	if (width > 0) {
		return new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number;
	}
	return number + ""; // always return a string
}

export function validateIMEIVerificationCode(elem) {
	// imei has to be valid
	var imei = $j("#txtEditAssetUniqueId");
	if (!validateIMEI(imei)) return false;

	var input = $j(elem);
	var val = input.val();
	if (val == null || val == "") return false;
	$j("#IMEIVerificationError").hide();
	if (val.length == 5) {
		var isValid = trkData.validation.editAsset.element("#txtEditAssetIMEIVerificationCode");
		if (isValid != true) return false;
		isValid = val == hash1(imei.val());
		if (isValid != true) {
			$j("#IMEIVerificationError").show();
			return false;
		}
		// gen hash2
		$j("#txtEditAssetPortalRegistrationCode").val(hash2(imei.val()));
		return true;
	}
}

export function validateIMEI(elem) {
	var input = $j(elem);
	if (!input.hasClass("imei")) return false;
	var val = input.val();

	// set to default message
	$j("#txtEditAssetPortalRegistrationCode").val(strings.MSG_VALID_IMEI);

	if (val == null || val == "") return false;

	val = val.trim();
	// validate input as 15 digit only
	if (val.length === 15) {
		var isValid = trkData.validation.editAsset.element("#txtEditAssetUniqueId");
		if (isValid !== true) return false;
		// if Iridium 9575, load Portal Registration Code message via ajax
		if ($j("#ddlEditAssetDevice").val() === "16") {
			//validateIMEIVerificationCode($j('#txtEditAssetIMEIVerificationCode'));
			// load portal code 2 by default now
			$j("#txtEditAssetPortalRegistrationCode").val(hash2(val));
		}
		return true;
	}
	return false;
}
