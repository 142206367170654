import { map } from "./map-base.js";
import trkData from "./data.js";
import state from "./state.js";
import options from "./options.js";
import { findAssetById } from "./assets.js";
import preferences from "./preferences.js";
import { viewModes, mapModes } from "./const.js";
import { findTripById } from "./trips.js";

import { normalGleoSymbols, sharedViewGleoSymbols } from "./map-gleo.js";

import RawGeometry from "./gleo/src/geometry/RawGeometry.mjs";

import _ from "lodash";
import L from "leaflet";

export function setMapBounds() {
	console.log("setMapBounds()");
	if (map == null) {
		return;
	}
	// recreate bounds based on visible positions
	clearBounds();
	if (state.activeViewMode === viewModes.NORMAL) {
		if (state.activeMapMode === mapModes.LIVE) {
			var positionMarkers = _.keyBy(trkData.live.markers, function (item) {
				return item.data.location.Id;
			});
			_.each(trkData.live.latestPositions, function (position) {
				var asset = findAssetById(position.AssetId);
				if (asset == null) {
					return;
				}
				var position = position.Position;
				var positionMarker = positionMarkers[position.Id];
				if (positionMarker === undefined) {
					return;
				}
				if (!normalGleoSymbols.has(positionMarker)) {
					return;
				}
				extendBounds(positionMarker.geometry.asLatLng());
			});
		} else if (state.activeMapMode === mapModes.HISTORY) {
			if (preferences.PREFERENCE_GROUP_POSITIONS) {
				// set bounds based on markercluster
				for (var i = 0; i < trkData.assets.length; i++) {
					var cluster = trkData.history.markerClustersByAssetId[trkData.assets[i].Id];
					if (cluster == undefined) {
						continue;
					}
					if (!normalGleoSymbols.has(cluster)) {
						continue;
					}

					if (!cluster.platina) {
						continue;
					}

					const bbox = new RawGeometry(cluster.platina.crs, cluster.bbox).asLatLng();
					if (bbox.every(n=>isFinite(n))) {

						const clusterBounds = L.latLngBounds([
							[bbox[0],bbox[1]],
							[bbox[2],bbox[3]],
						]);

						if (clusterBounds.isValid()) {
							extendBounds(clusterBounds);
						}
					}
				}
			} else {
				_.each(trkData.history.markers, function (positionMarker) {
					if (!normalGleoSymbols.has(positionMarker)) {
						return;
					}
					extendBounds(positionMarker.getLatLng());
				});
			}
		}

		// active trip bounds
		_.each(trkData.visible.trips, function (tripId) {
			var trip = findTripById(tripId);
			if (trip !== null && trkData.trips.positionsByTripId[tripId] !== undefined) {
				var visiblePositions = _.filter(trkData.trips.positionsByTripId[tripId].Positions, function (item) {
					return !item.IsHidden;
				});
				_.each(visiblePositions, function (position) {
					extendBounds(L.latLng(position.Lat, position.Lng));
				});
			}
		});
	} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
		if (
			(trkData.sharedView.temp !== null && trkData.sharedView.temp.Preferences.PositionConsolidation) ||
			(trkData.sharedView.temp === null &&
				trkData.sharedView.current !== null &&
				trkData.sharedView.current.Preferences.PositionConsolidation)
		) {
			// set bounds based on markercluster
			_.each(trkData.sharedView.markerClustersByAssetId, function (cluster) {
				if (!sharedViewGleoSymbols.has(cluster)) {
					return;
				}

				const clusterBounds = L.latLngBounds(new RawGeometry(cluster.platina.crs, cluster.bbox).asLatLng());
				if (clusterBounds.isValid()) {
					extendBounds(clusterBounds);
				}
			});
		} else {
			_.each(trkData.sharedView.markers, function (positionMarker) {
				if (!sharedViewGleoSymbols.has(positionMarker)) {
					return;
				}
				extendBounds(positionMarker.getLatLng());
			});
		}
	}
	if (state.bounds == null || !state.bounds.isValid()) {
		// set the map center to be global center
		map.setView(L.latLng(0, 0), 2);
	} else {
		map.fitBounds(state.bounds, { padding: [10, 10], maxZoom: options.maximumZoom });
	}
}

export function clearBounds() {
	state.bounds = L.latLngBounds();
	state.boundsItems = [];
}

export function extendBounds(location) {
	if (state.bounds === null) {
		return;
	}
	state.boundsItems.push(location);
	if (state.bounds.isValid()) {
		if (state.bounds.contains(location)) {
			return;
		}
	}
	state.bounds.extend(location);
}
