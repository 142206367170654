
import moment from "moment";


export const fragment = document.createDocumentFragment();

const div = document.createElement("div");

div.innerHTML = `
<div>
<span id='min-datetime' style='float:left'></span>
<span id='max-datetime' style='float:right'></span>
<div>
<input type=range style='width: 100%'/>
<div id='current-datetime' style='position: relative'></div>
`;

div.style.position = "absolute";
div.style.zIndex = 20;
div.style.bottom = 0;
div.style.left = "30em";
div.style.right = "5em";
div.style.background = "black";
div.style.color = "white";


fragment.appendChild(div);

const minLabel = fragment.querySelector('#min-datetime');
const maxLabel = fragment.querySelector('#max-datetime');
const currentLabel = fragment.querySelector('#current-datetime');
const slider = fragment.querySelector('input');

let baseTimestamp = 0;

export function setMinMax(min, max, base) {
	if (min == NaN) {
		minLabel.innerText = "Loading..."
		maxLabel.innerText = "Loading..."
		currentLabel.innerText = "Loading..."
		slider.disabled = true;
	} else {
		minLabel.innerText = moment((base + min) * 1000).toISOString();
		maxLabel.innerText = moment((base + max) * 1000).toISOString();
		slider.min = min;
		slider.max = max;
		slider.disabled = false;
		slider.value = min;
		baseTimestamp = base;
	}
}

export function hookSliderInput(onInputFn) {
	slider.addEventListener('input', onInputFn);
	slider.addEventListener('input', (ev => {
		currentLabel.innerText = moment((baseTimestamp + Number(slider.value)) * 1000).toISOString();

		currentLabel.style.left = (100 * (slider.value - slider.min) / (slider.max - slider.min)) + "%";
	}));
}