import strings from "./strings.js";
import trkData from "./data.js";
import state from "./state.js";
import options from "./options.js";
import domNodes from "./domNodes.js";
import user from "./user.js";
import { mapModes } from "./const.js";
import { openDialogPanel } from "./panel-nav.js";
import { createListing, defaultListItemSort } from "./item-listing.js";
import { getGroupAssetStatus } from "./asset-group.js";
import { checkForShareViewChange } from "./shared-view.js";
import { loadLiveMessagesForAssets } from "./messages.js";
import {
	loadHistoryMessagesForTrip,
	loadHistoryMessagesForAssets,
	loadHistoryMessagesForSharedView,
} from "./messages-history.js";
import { getDisplayFilterForEventType } from "./display-filter.js";

import $ from "jquery";
import _ from "lodash";

export function openActivityForSharedView(sharedView) {
	var includeChatMessages = sharedView.IsMessagingEnabled;
	var deferreds = [];

	var positions = trkData.sharedView.normalizedPositions;
	var events = trkData.sharedView.normalizedEvents;
	var messages = [];
	if (includeChatMessages) {
		deferreds.push(loadHistoryMessagesForSharedView(sharedView.Id));
	}

	$.when.apply($, deferreds).done(function () {
		messages = trkData.sharedView.normalizedMessages;
		var others = events.concat(messages);
		positions = filterPositionsAlreadyIncluded(positions, others);
		let items = others.concat(positions);
		items = _.sortBy(_.filter(items, getDisplayFilterForEventType("activity")), defaultListItemSort).reverse();
		createListing(items, "activity");
		openDialogPanel(
			domNodes.dialogs.assetActivity,
			strings.ACTIVITY_LOG,
			sharedView,
			false,
			checkForShareViewChange(sharedView),
			"shared-view",
			"shared-view-activity",
			openActivityForSharedView
		);
	});
}

export function openActivityForAsset(asset) {
	var includeChatMessages = !(user.isAnonymous && !options.allowAnonymousMessaging);
	var includeDeviceMessages = !user.isAnonymous;
	var items = [];
	var deferreds = [];
	var dataSource = state.activeMapMode === mapModes.LIVE ? trkData.live : trkData.history;

	var positions = [];
	if (dataSource.normalizedPositionsByAssetId[asset.Id] !== undefined) {
		positions = dataSource.normalizedPositionsByAssetId[asset.Id];
	}

	var events = [];
	if (dataSource.normalizedEventsByAssetId[asset.Id] !== undefined) {
		events = dataSource.normalizedEventsByAssetId[asset.Id];
	}

	var messages = [];
	if (includeChatMessages || includeDeviceMessages) {
		var loadMessages = state.activeMapMode === mapModes.LIVE ? loadLiveMessagesForAssets : loadHistoryMessagesForAssets;
		deferreds.push(loadMessages([asset.Id]));
	}

	$.when.apply($, deferreds).done(function () {
		if (dataSource.normalizedMessagesByAssetId[asset.Id] !== undefined) {
			messages = dataSource.normalizedMessagesByAssetId[asset.Id];
		}
		var others = events.concat(messages);
		positions = filterPositionsAlreadyIncluded(positions, others);
		items = others.concat(positions);
		var filteredItems = _.filter(items, getDisplayFilterForEventType("activity"));
		console.log(filteredItems);
		items = _.sortBy(filteredItems, defaultListItemSort).reverse();
		createListing(items, "activity");
		console.log(
			_.sortBy(filteredItems, function (item) {
				return item.Epoch;
			}).reverse()
		);
		openDialogPanel(
			domNodes.dialogs.assetActivity,
			strings.ACTIVITY_LOG,
			asset,
			false,
			null,
			"asset",
			"asset-activity",
			openActivityForAsset
		);
	});
}

export function openActivityForGroup(group) {
	var status = getGroupAssetStatus(group.Id);
	var includeChatMessages = !(user.isAnonymous && !options.allowAnonymousMessaging);
	var includeDeviceMessages = !user.isAnonymous;
	var items = [];
	var deferreds = [];
	var dataSource = state.activeMapMode === mapModes.LIVE ? trkData.live : trkData.history;

	var positions = [];
	var events = [];
	_.each(status.assetIds, function (assetId) {
		if (dataSource.normalizedPositionsByAssetId[assetId] !== undefined) {
			positions = positions.concat(dataSource.normalizedPositionsByAssetId[assetId]);
		}

		if (dataSource.normalizedEventsByAssetId[assetId] !== undefined) {
			events = events.concat(dataSource.normalizedEventsByAssetId[assetId]);
		}
	});

	var messages = [];
	if (includeChatMessages || includeDeviceMessages) {
		var loadMessages = state.activeMapMode === mapModes.LIVE ? loadLiveMessagesForAssets : loadHistoryMessagesForAssets;
		deferreds.push(loadMessages(status.assetIds));
	}

	$.when.apply($, deferreds).done(function () {
		_.each(status.assetIds, function (assetId) {
			if (dataSource.normalizedMessagesByAssetId[assetId] !== undefined) {
				messages = messages.concat(dataSource.normalizedMessagesByAssetId[assetId]);
			}
		});
		var others = events.concat(messages);
		positions = filterPositionsAlreadyIncluded(positions, others);
		items = others.concat(positions);
		items = _.sortBy(_.filter(items, getDisplayFilterForEventType("activity")), defaultListItemSort).reverse();
		createListing(items, "activity");
		openDialogPanel(
			domNodes.dialogs.assetActivity,
			strings.ACTIVITY_LOG,
			group,
			false,
			null,
			"group",
			"group-activity",
			openActivityForGroup
		);
	});
}

export function openActivityForTrip(trip) {
	var includeChatMessages = !(user.isAnonymous && !options.allowAnonymousMessaging);
	var includeDeviceMessages = !user.isAnonymous;
	var items = [];
	var deferreds = [];

	var positions = [];
	if (trkData.trips.normalizedPositionsByTripId[trip.Id] !== undefined) {
		positions = trkData.trips.normalizedPositionsByTripId[trip.Id];
	}

	var events = [];
	if (trkData.trips.normalizedEventsByTripId[trip.Id] !== undefined) {
		events = trkData.trips.normalizedEventsByTripId[trip.Id];
	}

	var messages = [];
	if (includeChatMessages || includeDeviceMessages) {
		deferreds.push(loadHistoryMessagesForTrip(trip.Id));
	}

	$.when.apply($, deferreds).done(function () {
		if (trkData.trips.normalizedMessagesByTripId[trip.Id] !== undefined) {
			messages = trkData.trips.normalizedMessagesByTripId[trip.Id];
		}
		var others = events.concat(messages);
		positions = filterPositionsAlreadyIncluded(positions, others);
		items = others.concat(positions);
		items = _.sortBy(_.filter(items, getDisplayFilterForEventType("activity")), defaultListItemSort).reverse();
		createListing(items, "activity");
		openDialogPanel(
			domNodes.dialogs.assetActivity,
			strings.ACTIVITY_LOG,
			trip,
			false,
			null,
			"trip",
			"trip-activity",
			openActivityForTrip
		);
	});
}

export function openActivityForJourney(journey) {
	var includeChatMessages = !(user.isAnonymous && !options.allowAnonymousMessaging);
	var includeDeviceMessages = !user.isAnonymous;
	var items = [];
	var deferreds = [];

	var positions = [];
	var events = [];
	var messages = [];

	_.each(journey.Trips, function (trip) {
		if (trkData.trips.normalizedPositionsByTripId[trip.Id] !== undefined) {
			positions = positions.concat(trkData.trips.normalizedPositionsByTripId[trip.Id]);
		}
		if (trkData.trips.normalizedEventsByTripId[trip.Id] !== undefined) {
			events = events.concat(trkData.trips.normalizedEventsByTripId[trip.Id]);
		}
		if (includeChatMessages || includeDeviceMessages) {
			deferreds.push(loadHistoryMessagesForTrip(trip.Id));
		}
	});

	$.when.apply($, deferreds).done(function () {
		_.each(journey.Trips, function (trip) {
			if (trkData.trips.normalizedMessagesByTripId[trip.Id] !== undefined) {
				messages = trkData.trips.normalizedMessagesByTripId[trip.Id];
			}
		});
		var others = events.concat(messages);
		positions = filterPositionsAlreadyIncluded(positions, others);
		items = others.concat(positions);
		items = _.sortBy(_.filter(items, getDisplayFilterForEventType("activity")), defaultListItemSort).reverse();
		createListing(items, "activity");
		openDialogPanel(
			domNodes.dialogs.assetActivity,
			strings.ACTIVITY_LOG,
			journey,
			false,
			null,
			"journey",
			"journey-activity",
			openActivityForJourney
		);
	});
}

export function filterPositionsAlreadyIncluded(positions, others) {
	var positionIds = _.filter(
		_.map(others, function (item) {
			if (item.Event !== undefined && item.Position !== undefined) {
				return item.Position.Id;
			} else if (item.Message !== undefined && item.Position !== undefined) {
				return item.Position.Id;
			} else if (item.Chat !== undefined && item.Position !== undefined) {
				return item.Position.Id;
			}
		}),
		function (item) {
			return item !== undefined;
		}
	);
	var positionIdLookup = {};
	_.each(positionIds, function (id) {
		positionIdLookup[id] = true;
	});
	return _.filter(positions, function (item) {
		return positionIdLookup[item.Position.Id] === undefined;
	});
}
