import state from "./state.js";
import trkData from "./data.js";
import log from "./log.js";
import domNodes from "./domNodes.js";
import { mapModes } from "./const.js";
import { wrapUrl } from "./wrapurl.js";
import { findAssetById } from "./assets.js";
import { throttles } from "./timers.js";
import { version } from "./version.js";
import { updateAssetState } from "./asset-state.js";
import user from "./user.js";
import strings from "./strings.js";
import { handleWebServiceError } from "./ajax.js";
import { toggleLoadingMessage } from "./ajax.js";
import { requestIDPCommandLog, requestIDPCryptoInformation, requestIDPUpdaterInformation } from "./idp-command.js";
import { requestAVLInformation } from "./avl.js";
import { checkVersion } from "./version.js";
import { updateAcknowledgedEventData, queryAlertsRequiringAcknowledgement } from "./alert.js";
import { addSystemNotification } from "./notifications-system.js";
import { updateWaypoint, addOrUpdateWaypoint, updateWaypointListing } from "./waypoint.js";
import { addMessageToAssetMessageCounts, openMessagesForAsset, openChatForAsset } from "./messages.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import { el, text } from "redom";
import signalR from "signalr"; // https://www.npmjs.com/package/signalr

let notificationConnection = null;

const notification = {
	isConnected: false,
	isReconnecting: false,
	reconnectInterval: null,
	reconnectSeconds: 10,
	reconnectLeft: 10,
};

export function populateDiagnostics() {
	$j("#DiagnosticsBrowser").text(navigator.userAgent);
	$j("#DiagnosticsUIVersion").text(version);
	if (notificationConnection.transport != null) {
		$j("#DiagnosticsTransport").text(notificationConnection.transport.name);
	} else {
		$j("#DiagnosticsTransport").text("NONE");
	}
}

export function setupSignalR() {
	// SignalR notification setup
	log("SignalR Setup.");

	var notifyUrl = "/notify";
	if (user.isImpersonated) {
		notifyUrl += "?ishr=" + user.id;
	}

	notificationConnection = new signalR.HubConnectionBuilder()
		.withUrl(notifyUrl)
		.withAutomaticReconnect({
			nextRetryDelayInMilliseconds: function (context) {
				if (notification.isReconnecting) {
					notification.reconnectLeft = notification.reconnectSeconds;
				}

				var retryDelay = notification.reconnectSeconds * 1000;
				if (notification.reconnectSeconds < 600) {
					notification.reconnectSeconds *= 2;
				}
				return retryDelay;
			},
		})
		.configureLogging(signalR.LogLevel.Information)
		.build();

	notificationConnection.onreconnecting(function (error) {
		log("SignalR: Connection lost. Attempting reconnect.");
		notification.isConnected = false;
		notification.isReconnecting = true;
		notification.reconnectInterval = setInterval(reconnectCountdown, 1000);
		reconnectCountdown();
		$("#disconnected").show();
		// start a random timer to check for the current portal
		// version as an update may have been applied which has caused the disconnect
		setTimeout(checkVersion, Math.round(Math.random() * 30000));
	});

	notificationConnection.onreconnected(function (connectionId) {
		log("SignalR: Connection re-established: " + connectionId);
		$("#disconnected").hide();
		notification.isConnected = true;
		notification.isReconnecting = false;
		notification.reconnectSeconds = 10;
		notification.reconnectLeft = 10;
		clearTimeout(notification.reconnectInterval);

		// requery some stuff that is probably stale
		setTimeout(function () {
			checkVersion();
			throttles.queryLatestNotifications();
			if (state.activeMapMode === mapModes.LIVE) {
				throttles.updateLiveAssets();
				throttles.queryLatestEvents();
			}
		}, Math.round(Math.random() * 10000));
	});

	var startConnection = function () {
		notificationConnection
			.start()
			.then(function () {
				log("SignalR: Started.");
				$("#disconnected").hide();
				notification.isConnected = true;
				notification.isReconnecting = false;
				notification.reconnectSeconds = 10;
				notification.reconnectLeft = 10;
				clearTimeout(notification.reconnectInterval);
			})
			.catch(function (err) {
				$("#disconnected").show();
				log("SignalR: ERROR");
				console.log(err);
				setTimeout(startConnection, 5000);
			});
	};

	startConnection();

	notificationConnection.onclose(function (error) {
		// permanent.. restart, though this shouldn't actually be called
		$("#disconnected").show();
		log("SignalR: Closed.");
		console.log(error);
		startConnection();
	});

	//notificationConnection = $j.connection.hub;
	//notificationService = $j.connection.notify;

	notificationConnection.on("idpCapabilitiesUpdate", function (assetId, capabilities) {
		log("SignalR IDP Capabilities Update: " + assetId);
		console.log(capabilities);
		var asset = findAssetById(assetId);
		if (asset != null) {
			asset.IDP = capabilities;
		}

		if (
			domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
			parseInt(domNodes.panels.secondary.getAttribute("data-item-id")) === assetId
		) {
			// crypto service information may have updated
			var activeDialog = document.getElementById("dialog-functions").querySelector(".dialog");
			if (activeDialog === domNodes.dialogs.idpSendCommand) {
				requestIDPCryptoInformation(assetId);
			}
		}
	});

	notificationConnection.on("newEvent", function (event) {
		log("SignalR Event: " + event);
		// query for the event
		throttles.queryLatestEvents(event);
	});

	notificationConnection.on("newAlert", function (event) {
		log("SignalR Alert: " + event);
		// query for the alert
		queryAlertsRequiringAcknowledgement(event);
	});

	notificationConnection.on("systemNotification", function (id, subject, text, from, to) {
		log("SignalR System Notification: " + id + ", " + subject + ", " + text + ", " + from + ", " + to);
		var notification = {
			id: id.toString(),
			text: text,
			from: from,
			to: to,
			subject: subject,
		};
		addSystemNotification(notification);
	});

	notificationConnection.on("newAssetState", function (assetId, state) {
		var asset = findAssetById(assetId);
		console.log(state);
		updateAssetState(asset, state);
	});

	notificationConnection.on("waypointUpdate", function (waypointId, status) {
		if (!user.isAnonymous) {
			log("SignalR Waypoint Update: " + waypointId + " (" + status + ")");
			updateWaypoint(waypointId);
		}
	});

	notificationConnection.on("waypointDeleted", function (waypointId) {
		if (!user.isAnonymous) {
			log("SignalR Waypoint Deleted: " + waypointId);
			addOrUpdateWaypoint({ Id: waypointId, Status: "Deleted" });
			updateWaypointListing();
		}
	});

	notificationConnection.on("driverUpdate", function (assetId, drivers) {
		log("SignalR Driver Status Update for Asset " + assetId);
		console.log(drivers);
		var asset = findAssetById(assetId);
		if (asset != null) {
			asset.Drivers = drivers;
		}
	});

	notificationConnection.on("bivyStatusUpdate", function (assetId, status) {
		log("SignalR Bivy Status Update for Asset " + assetId);
		log(status);

		var asset = findAssetById(assetId);
		if (asset != null) {
			asset.Bivy = status;
		}
	});

	notificationConnection.on("messageStatusUpdate", function (messageId, assetId, status, lastIncoming) {
		log(
			"SignalR Message Status Updated: " + messageId + " (" + status + ") for Asset " + assetId + " at " + lastIncoming
		);
		var asset = findAssetById(assetId);
		if (asset == null) {
			return;
		}

		if (asset.MessageStatuses == null) {
			asset.MessageStatuses = [];
		}

		// a message may change statuses
		var isFound = false;
		for (var i = 0; i < asset.MessageStatuses.length; i++) {
			var item = asset.MessageStatuses[i];
			if (item.Id == messageId) {
				// replace this
				isFound = true;
				item.Status = status;
				item.Time = lastIncoming;
			}
		}
		if (!isFound) {
			asset.MessageStatuses.push({
				Id: messageId,
				Status: status,
				Time: lastIncoming,
			});
		}

		throttles.updatePositionStatus();
	});

	notificationConnection.on(
		"newMessage",
		function (messageId, assetId, lastIncoming, isPopup, status, deliveryType, messageTypeId) {
			// TODO shared view mode support
			// query for new message notifications
			log(
				"SignalR Message: " +
					messageId +
					" of type " +
					messageTypeId +
					" for Asset " +
					assetId +
					" at " +
					lastIncoming +
					" (" +
					isPopup +
					") of delivery type " +
					deliveryType
			);

			// download the message and add it to live.messages and live.messagesByAssetId
			// and then update any badge indicators
			// one issue here is that it's going to pop even for messages the current user has created
			// so we may need to create/pass a HasRead property on the message which would automatically be true
			// for the user who creates the message, though we don't currently capture who creates an outgoing message
			// we'd also have to have a separate ReadMessages in MessageCounts (e.g. FromMobileRead or FromMobileUnread)
			// the alternative is to no pop any to-mobile messages

			// if we don't download the message
			// if(type == 1) {
			//  add it to live.messageCountsByAssetId[assetId]
			//  update visible badges
			//  update message listing (if currently opened)
			// }
			// badge should only be if FromMobile > 0 in live mode?
			assetId = parseInt(assetId);
			messageId = parseInt(messageId);
			deliveryType = parseInt(deliveryType);
			messageTypeId = parseInt(messageTypeId);
			var isForChat = trkData.MESSAGES_TEXT.indexOf(messageTypeId) !== -1;
			addMessageToAssetMessageCounts(assetId, messageId, deliveryType, isForChat);

			// when messages is clicked in live mode, query server for any messages since state.portalLoadedEpoch (using moment?)
			// and invalidate cache when MessageCounts are updated...

			var asset = findAssetById(assetId);
			if (asset == null) {
				return;
			}
			if (deliveryType === 0) {
				// record new outgoing message status
				if (asset.MessageStatuses == null) {
					asset.MessageStatuses = [];
				}

				asset.MessageStatuses.push({
					Id: messageId,
					Status: status,
					Time: lastIncoming,
				});
			} else {
				// for now we're going to invalidate the asset's message cache
				// a better strategy is to cache per asset based on the message's epoch
				// or even accept the full message here to insert into the cache
				trkData.live.messages = _.reject(trkData.live.messages, function (msg) {
					return msg.AssetId === assetId;
				});
				trkData.live.messagesByAssetId = _.groupBy(trkData.live.messages, "AssetId");
				if (isPopup) {
					if (asset.UnconfirmedMessages == null) {
						asset.UnconfirmedMessages = [messageId];
					} else {
						asset.UnconfirmedMessages.push(messageId);
					}

					throttles.queryLatestNotifications();
				} else {
					asset.LastIncomingMessage = lastIncoming;
				}

				if (
					domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
					parseInt(domNodes.panels.secondary.getAttribute("data-item-id")) === assetId
				) {
					var activeDialog = document.getElementById("dialog-functions").querySelector(".dialog");

					if (
						activeDialog === domNodes.dialogs.idpSendCommand &&
						messageTypeId === trkData.MESSAGE_IDP_COMMAND_RESPONSE
					) {
						requestIDPCommandLog(assetId, true);
					}

					if (
						activeDialog === domNodes.dialogs.idpSendCommand &&
						(messageTypeId === trkData.MSG_IDP_UPDATER_TERMINAL_INFO_RESPONSE ||
							messageTypeId === trkData.MSG_IDP_UPDATER_STATE_RESPONSE)
					) {
						requestIDPUpdaterInformation(assetId);
					}

					var IDP_COMMAND_RESPONSES = [38, 42, 45, 51, 72, 113, 127, 151]; // Core, AVL, Geofence, Geofence Status, Immobilizer, Garmin, ARC, Utility property responses
					if (
						activeDialog === domNodes.dialogs.idpSendCommand &&
						_.indexOf(IDP_COMMAND_RESPONSES, messageTypeId) !== -1
					) {
						// TODO: separate refreshes for each message type
						requestAVLInformation(assetId);
					}

					// refresh the messages or chat dialogs if it is currently opened
					if (state.activeMapMode === mapModes.LIVE) {
						if (!isForChat && activeDialog === domNodes.dialogs.assetMessages) {
							// todo: only append to the existing listing, don't recreate
							openMessagesForAsset(asset);
						}

						if (isForChat && activeDialog === domNodes.dialogs.assetChat) {
							// todo: only append to the existing listing, don't recreate
							openChatForAsset(asset);
						}
					}
				}
			}

			throttles.updatePositionStatus();
		}
	);

	notificationConnection.on("messageConfirmed", function (positionId, messageId, acknowledgedOn, acknowledgedBy) {
		log("SignalR Message Confirmed: " + positionId + ", " + messageId + ", " + acknowledgedOn + ", " + acknowledgedBy);
		var confirmedBy = "";
		if (acknowledgedBy != null) {
			if (trkData.users !== undefined && trkData.users !== null) {
				for (var k = 0; k < trkData.users.length; k++) {
					if (trkData.users[k].Id == acknowledgedBy) {
						confirmedBy = trkData.users[k].Username;
						break;
					}
				}
			}
		}

		var notification = $j("#jGrowl div[data-notification-id=" + messageId + "]");
		if (confirmedBy != "") {
			confirmedBy = strings.CONFIRMED_BY.replace("{0}", confirmedBy);
		} else {
			confirmedBy = strings.CONFIRMED;
		}
		notification.find("button").prop("disabled", true);
		notification
			.parent()
			.parent()
			.append(el("div.jGrowl-acknowledge", [el("span.ui-icon.ui-icon-circle-check.acknowledge"), text(confirmedBy)]));
		setTimeout(function () {
			notification.trigger("jGrowl.close");
		}, 5000);
	});

	notificationConnection.on("alertAcknowledged", function (positionId, eventId) {
		// todo: retrieve the event information to replace it
		log("SignalR Alert Acknowledged: " + positionId + ", " + eventId);
		toggleLoadingMessage(true, "get-event");
		var dataPost = {
			eventId: eventId,
		};
		$j.ajax({
			type: "POST",
			url: wrapUrl("/Services/GPSService.asmx/GetAssetEventById"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				toggleLoadingMessage(false, "get-event");
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						console.log(result);
						updateAcknowledgedEventData(result.AssetEvent.Event);
					} else {
						handleWebServiceError(strings.MSG_GET_EVENT_ERROR);
					}
				}
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_GET_EVENT_ERROR);
				toggleLoadingMessage(false, "get-event");
			},
		});
	});

	notificationConnection.on("reloadPage", function () {
		log("Reloading page based on server request.");
		location.reload(true);
	});

	notificationConnection.on("checkVersion", function () {
		log("Checking page version based on server request.");
		checkVersion();
	});

	notificationConnection.on("newPosition", function (message) {
		// query for new position
		log("SignalR Position: " + message);
		throttles.queryLatestNotifications();
		throttles.updateLiveAssets();
	});
}

function reconnectCountdown() {
	if (notification.reconnectLeft > 0) {
		notification.reconnectLeft -= 1;
	}
	$("#ConnectionRetry").text(strings.CONNECTION_RETRY.replace("{0}", notification.reconnectLeft));
}
