import { convertNamedColorToHex } from "./color.js";
import { findAssetById } from "./assets.js";
import { findTripById } from "./trips.js";
import { createPositionHistorySummary } from "./position-history.js";
import trkData from "./data.js";
import options from "./options.js";
import { snapLinePathsToRoads } from "./geometry-snap.js";
import { addItemToMap } from "./map-items.js";
import { viewModes } from "./const.js";

import L from "leaflet";
import $j from "jquery";

export function createPolylineFromLinePaths(linePath, asset, trip, dashArray) {
	var lineColor = convertNamedColorToHex(asset.Color);
	var sequentialLatLngs = [];
	for (var i = 0; i < linePath.length; i++) {
		var sequentialSection = [];
		for (var j = 0; j < linePath[i].length; j++) {
			sequentialSection.push(linePath[i][j].latLng);
		}
		sequentialLatLngs.push(sequentialSection);
	}

	var line = L.polyline(sequentialLatLngs, {
		weight: 3,
		color: lineColor,
		opacity: 0.75,
		dashArray: dashArray,
		pane: "back-lines"
	});
	line.assetId = asset.Id;
	line.tripId = trip !== undefined && trip !== null ? trip.Id : undefined;
	line.on("click", function (e) {
		var asset = findAssetById(this.assetId);
		if (asset == null) {
			return;
		}
		var trip = findTripById(this.tripId);
		createPositionHistorySummary(asset, trip, undefined, undefined, e);
	});
	return line;
}

export function createLinePathsFromPositions(positions) {
	var linePath = [];
	for (var j = 0; j < positions.length; j++) {
		// add position to map and coposition filters
		var position = positions[j];
		if (position.IsHidden === true) {
			continue;
		}
		var point = {
			id: position.Id,
			latLng: L.latLng(position.Lat, position.Lng),
			timestamp: position.Epoch,
			accuracy: position.Accuracy,
		};
		linePath.push(point);
	}

	// create line connecting positions
	// split the line across the antimeridian so that lines don't "cross" the globe and look wrong
	linePath = splitPathAcrossAntiMeridian(linePath);
	return linePath;
}

function splitPathAcrossAntiMeridian(latLons) {
	// should return one array if not split, or more if yes split
	// will be split everytime the path crosses the antimeridian
	var normalizeIndexes = []; // indexes of points where the anti meridian is crossed
	for (var i = 0; i < latLons.length; i++) {
		var latLon = latLons[i].latLng;
		var previous = latLons[i - 1];
		if (previous != null) {
			// compare longitudes to determine if antimerdian has been crossed
			if (Math.abs(latLon.lng - previous.latLng.lng) > 180) {
				normalizeIndexes.push(i);
			}
		}
	}
	var normalizeIndexesRef = normalizeIndexes.slice(0); // duplicate normalize indexes array
	for (var i = 0; i < normalizeIndexes.length; i++) {
		var flipDirection = latLons[normalizeIndexesRef[i]].latLng.lng < 0 ? -1 : 1;
		// calculate latitude of break point instead of relying on chosen latitude
		var latLng0 = latLons[normalizeIndexesRef[i] - 1].latLng;
		var latLng1 = latLons[normalizeIndexesRef[i]].latLng;
		var lng1 = latLng1.lng < 0 ? latLng1.lng + 360 : latLng1.lng;
		var lng0 = latLng0.lng < 0 ? latLng0.lng + 360 : latLng0.lng;
		var slope = (latLng1.lat - latLng0.lat) / (lng1 - lng0);
		var intercept = latLng0.lat - slope * lng0;
		var newLatitude = slope * 180 + intercept;
		// using basic 2D geometry (not worth the spherical calculations I don't think)
		// todo: position ids need to be populated at least as well
		latLons.splice(normalizeIndexesRef[i], 0, { latLng: L.latLng(newLatitude, flipDirection * -179.99999) });
		latLons.splice(normalizeIndexesRef[i] + 1, 0, { latLng: L.latLng(newLatitude, flipDirection * 179.99999) });
		const normalizeIndexesUpdate = [];
		for (var j = 0; j < normalizeIndexesRef.length; j++) {
			normalizeIndexesUpdate[j] = normalizeIndexesRef[j] + 2;
		}
		normalizeIndexesRef = normalizeIndexesUpdate;
	}
	var sections = splitAtEvery(latLons);
	return sections;
}

function shouldSplit(array, i, val) {
	if (array.length <= i + 1) return false;
	return Math.abs(val.latLng.lng) == 179.99999 && Math.abs(array[i + 1].latLng.lng) == 179.99999;
}

function splitAtEvery(latLons) {
	var sections = [];
	var arrayClone = latLons.slice(0);
	$j.each(arrayClone, function (i, item) {
		var sectionsLength = 0;
		for (var j = 0; j < sections.length; j++) {
			var section = sections[j];
			sectionsLength += section.length;
		}
		if (shouldSplit(latLons, i, item) == true) {
			sections.push(arrayClone.slice(0, i + 1 - sectionsLength));
			arrayClone = arrayClone.slice(i + 1 - sectionsLength, arrayClone.length);
		}
	});
	sections.push(arrayClone);
	return sections;
}

export function createPositionLinesForTrip(trip, asset, positions) {
	// still draw the lines even if the preference is not set if we're only including start/end positions
	if (!asset.DrawLinesBetweenPositions && trip.IncludeAllPositions) {
		return;
	}

	var linePaths = createLinePathsFromPositions(positions);
	var line = createPolylineFromLinePaths(linePaths, asset, trip, "4 8");
	trkData.trips.mapLinesByTripId[trip.Id] = line;
	if (asset.SnapLinesToRoads && options.enabledFeatures.indexOf("UI_SNAP_LINES_TO_ROADS") !== -1) {
		// kind of hacky to set the first position of the line this way
		// as the first position may not be here?
		trkData.trips.mapLinesByTripId[trip.Id].setLatLngs(linePaths[0][0].latLng); // what problem is this hack fixing?
		snapLinePathsToRoads(trkData.trips.mapLinesByTripId[trip.Id], linePaths);
	}
	addItemToMap(line);
}

export function createPositionLinesForAsset(asset, positions, viewMode) {
	if (!asset.DrawLinesBetweenPositions) {
		return;
	}

	console.log("createPositionLinesForAsset");
	var dataSource = trkData.history;
	if (viewMode === viewModes.SHARED_VIEW) {
		dataSource = trkData.sharedView;
	} else {
		viewMode = viewModes.NORMAL;
	}

	var linePaths = createLinePathsFromPositions(positions);
	var line = createPolylineFromLinePaths(linePaths, asset);
	dataSource.mapLinesByAssetId[asset.Id] = line;

	if (asset.SnapLinesToRoads && options.enabledFeatures.indexOf("UI_SNAP_LINES_TO_ROADS") !== -1) {
		// kind of hacky to set the first position of the line this way
		// as the first position may not be here?
		if (linePaths.length > 0 && linePaths[0].length > 0) {
			dataSource.mapLinesByAssetId[asset.Id].setLatLngs(linePaths[0][0].latLng); // what problem is this hack fixing?
			snapLinePathsToRoads(dataSource.mapLinesByAssetId[asset.Id], linePaths);
		} else {
			dataSource.mapLinesByAssetId[asset.Id].setLatLngs([]);
		}
	}
	addItemToMap(line, null, viewMode);
}

//function createPositionLinesForAsset(asset, positions) {
//    if (!asset.DrawLinesBetweenPositions) {
//        return;
//    }

//    var linePaths = createLinePathsFromPositions(positions);
//    var line = createPolylineFromLinePaths(linePaths, asset);
//    trkData.history.mapLinesByAssetId[asset.Id] = line;

//    if (asset.SnapLinesToRoads && options.enabledFeatures.indexOf('UI_SNAP_LINES_TO_ROADS') !== -1) {
//        // kind of hacky to set the first position of the line this way
//        // as the first position may not be here?
//        if (linePaths.length > 0 && linePaths[0].length > 0) {
//            trkData.history.mapLinesByAssetId[asset.Id].setLatLngs(linePaths[0][0].latLng); // what problem is this hack fixing?
//            snapLinePathsToRoads(trkData.history.mapLinesByAssetId[asset.Id], linePaths);
//        } else {
//            trkData.history.mapLinesByAssetId[asset.Id].setLatLngs([]);
//        }
//    }
//    if (state.activeMapMode === mapModes.HISTORY) {
//        addItemToMap(line);
//    }
//}

//function createPositionLinesForSharedViewAsset(asset, positions) {
//    if (!asset.DrawLinesBetweenPositions) {
//        return;
//    }

//    var linePaths = createLinePathsFromPositions(positions);
//    var line = createPolylineFromLinePaths(linePaths, asset);
//    trkData.sharedView.mapLinesByAssetId[asset.Id] = line;

//    if (asset.SnapLinesToRoads && options.enabledFeatures.indexOf('UI_SNAP_LINES_TO_ROADS') !== -1) {
//        // kind of hacky to set the first position of the line this way
//        // as the first position may not be here?
//        if (linePaths.length > 0 && linePaths[0].length > 0) {
//            trkData.sharedView.mapLinesByAssetId[asset.Id].setLatLngs(linePaths[0][0].latLng); // what problem is this hack fixing?
//            snapLinePathsToRoads(trkData.sharedView.mapLinesByAssetId[asset.Id], linePaths);
//        } else {
//            trkData.sharedView.mapLinesByAssetId[asset.Id].setLatLngs([]);
//        }
//    }
//    addItemToMap(line, null, viewModes.SHARED_VIEW);
//}
