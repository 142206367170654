const domNodes = {
	assets: {},
	assetNotificationMenu: null,
	tripNotificationMenu: null,
	groups: {},
	groupContents: {},
	groupColors: {},
	assetsLive: {},
	assetsHistory: {},
	places: {},
	journeys: {},
	sharedViews: {},
	trips: {},
	fences: {},
	dialogs: {},
	infoDialogs: {},
	modals: {},
	map: null,
	content: {
		base: null,
	},
	mapTools: {
		container: null,
		currentTime: null,
		visibleSummary: null,
	},
	mapMode: {
		container: null,
		current: null,
		from: null,
		to: null,
	},
	nav: {
		primary: null,
		toggle: null,
		utility: null,
	},
	panels: {
		primary: null,
		secondary: null,
	},
	simpleBars: {
		primary: null,
		secondary: null,
	},
	filter: {
		assetResults: null,
		fenceResults: null,
		placeResults: null,
		journeyResults: null,
		sharedViewResults: null,
	},
	mouseTooltip: {
		content: null,
		title: null,
		show: false,
		position: { x: 0, y: 0 },
		reference: null,
	},
	eventListingById: {},
	positionListingById: {},
	messageListingById: {},
	activityListingById: {},
	sharedView: {
		eventListingById: {},
		positionListingById: {},
		messageListingById: {},
		activityListingById: {},
	},
};

export default domNodes;
