import $j from "jquery";
import options from "./options.js";
import { wrapUrl } from "./wrapurl.js";

export function reverseGeocode(latlng, callback) {
	if (options.enabledFeatures.indexOf("UI_GEOCODING") === -1) {
		return;
	}
	var data = { Lat: latlng.lat, Lng: latlng.lng };
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/ReverseGeocode"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			var result = msg.d;
			if (result) {
				console.log(result);
				if (result.Success == true) {
					callback(true, result.Address);
				} else {
					callback(false);
				}
			}
		},
		error: function (xhr, status, error) {
			callback(false);
		},
	});
}

export function addressSearch(address, callback) {
	var data = { address: address };
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/AddressSearch"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			console.log("address search success");
			var result = msg.d;
			if (result) {
				console.log(result);
				if (result.Success === true) {
					callback(true, result.Result);
				} else {
					callback(false);
				}
			}
		},
		error: function (xhr, status, error) {
			callback(false);
		},
	});
}
