import trkData from "./data.js";
import state from "./state.js";
import strings from "./strings.js";
import options from "./options.js";
import log from "./log.js";
import { mapModes, trkDataGroups } from "./const.js";
import { wrapUrl } from "./wrapurl.js";
import { handleWebServiceError } from "./ajax.js";
import { convertNamedColorToHex } from "./color.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import { markerClick } from "./marker-click.js";
import { isItemIncluded } from "./polyfills.js";
import user from "./user.js";
import { throttles } from "./timers.js";
import { addPositionMarkerToPoint } from "./marker.js";
import domNodes from "./domNodes.js";
import { setMapBounds } from "./map-bounds.js";
import { findGroupById } from "./asset-group.js";
import { getDbg } from "./log.js";
import { updateAssetState } from "./asset-state.js";
import { map } from "./map-base.js";
import { getCurrentMarkerForAsset, findAssetByUniqueId, findAssetById, normalizeAssetData } from "./assets.js";
import { initLiveAssetsFollow, openMostRecentLiveFollowPosition, liveFollowAsset } from "./asset-live-follow.js";
import { toggleAssetActive } from "./asset-select.js";
import { openPositionsForAsset, openPositionsForGroup } from "./positions.js";
import { updateGroupFunctionBadges, updateAssetFunctionBadges } from "./badges.js";
import { getAssetDataGroupForCurrentViewMode } from "./map-viewmode.js";
import { updateAssetNotificationTime, updateTimeBasedNotificationIndicatorsForAsset } from "./asset-notification.js";
import { updateTimeBasedNotificationIndicatorsForGroup } from "./notifications.js";

import $ from "jquery";
import _ from "lodash";
import L from "leaflet";
import moment from "moment"; // https://www.npmjs.com/package/moment

export function initLiveAssets() {
	initLiveAssetsFollow();
}

export async function updateLiveAssets() {
	if (trkData.pending.live) {
		return;
	}

	trkData.pending.live = true;
	// if previous query hasn't returned, don't send another
	log("Updating Live Assets");
	var data = {
		lang: user.dateCulture,
		dbg: getDbg(),
	};

	try {
		const req = await fetch(wrapUrl("/services/GPSService.asmx/GetLatestPositionsForAssetsReq"), {
			method: "POST",
			body: JSON.stringify(data),
			headers: new Headers({
				"Content-Type": "application/json; charset=utf-8",
			}),
		});
		const msg = await req.json();
		trkData.pending.live = false;

		if (!msg.d) {
			return;
		}

		var newPositions = msg.d;
		var updatePositions = false;
		var isMarkerActive = false;
		var isNewFollowPosition = false;
		for (var i = 0; i < newPositions.length; i++) {
			// asset/position
			var latestPosition = newPositions[i];
			var assetId = latestPosition.AssetId;
			var asset = findAssetById(assetId);
			if (asset == null) {
				// unknown asset update
				continue;
			}
			var newPosition = latestPosition.Position;
			var isActive =
				state.activeMapMode === mapModes.LIVE && !isItemIncluded(user.displayPreferences.hiddenAssets, assetId);

			if (trkData.live.latestPosition == null || trkData.live.latestPosition.Epoch < newPosition.Epoch) {
				trkData.live.latestPosition = newPosition;
			}

			// if the position has changed (newer), update it
			var isNewPosition = false;
			var priorPosition = trkData.live.latestPositionsByAssetId[assetId];
			if (priorPosition !== undefined) {
				priorPosition = priorPosition.Position;
				if (priorPosition.Id !== newPosition.Id) {
					// replace old position with new
					log("New position for " + asset.Name + " at " + newPosition.Time + ". Updating.");

					var priorPositionMarker = trkData.live.markersByPositionId[priorPosition.Id];

					isMarkerActive = priorPositionMarker !== undefined && priorPositionMarker.data.selected;

					// push old position into a history list to support persisted event position information
					if (asset.Positions == null) {
						asset.Positions = [priorPosition];
					} else {
						asset.Positions.push(priorPosition);
						// this needs to be limited otherwise we will have issues with memory
						// for long-running portal users
						if (asset.Positions.length > 50) {
							asset.Positions.splice(0, 1);
						}
					}

					// push old marker into live history list for asset (max 10 spots)
					// and update the history line -- same color as asset
					if (!newPosition.IsHidden) {
						if (asset.DrawLinesBetweenPositions) {
							if (trkData.live.mapLinesByAssetId[asset.Id] === undefined) {
								// init line
								var livePath = [];
								livePath.push(L.latLng(priorPosition.Lat, priorPosition.Lng));
								livePath.push(L.latLng(newPosition.Lat, newPosition.Lng));
								var color = convertNamedColorToHex(asset.Color);

								trkData.live.mapLinesByAssetId[asset.Id] = L.polyline(livePath, {
									weight: 4,
									color: color,
									opacity: 0.75,
									pane: "back-lines",
								});
								if (isActive) {
									addItemToMap(trkData.live.mapLinesByAssetId[asset.Id]);
								}
							} else {
								// add *current* position to live path
								// pop oldest position off if more than 10
								var currentLivePath = trkData.live.mapLinesByAssetId[asset.Id].getLatLngs();
								if (currentLivePath.length >= options.maxLiveTrailPositions) {
									currentLivePath.splice(0, 1);
								}
								currentLivePath.push(L.latLng(newPosition.Lat, newPosition.Lng));
								trkData.live.mapLinesByAssetId[asset.Id].setLatLngs(currentLivePath);
							}
						}
					}

					// remove old marker
					if (priorPositionMarker !== undefined) {
						$(priorPositionMarker.getElement()).bsTooltip("dispose");
						removeItemFromMap(priorPositionMarker);
						priorPositionMarker.data.hide = true;
					}

					isNewPosition = true;
				}
			} else {
				// new position for asset which doens't have a previous position
				log("New position for asset #" + assetId + "... No previous.");
				isNewPosition = true;
			}

			if (isNewPosition) {
				updatePositions = true;
				// add new marker
				// var point = L.latLng(newPosition.Lat, newPosition.Lng);
				var geom = [newPosition.Lat, newPosition.Lng];
				var newPositionMarker = addPositionMarkerToPoint(
					geom,
					false,
					newPosition,
					asset,
					255,
					null,
					null,
					null,
					trkDataGroups.NORMAL_LIVE
				);

				// toggle visibility
				if (isActive && !newPosition.IsHidden) {
					addItemToMap(newPositionMarker);
				} else {
					removeItemFromMap(newPositionMarker);
				}

				if (priorPosition !== undefined) {
					var existingIndex = _.findIndex(trkData.live.latestPositions, function (item) {
						return item.Position.Id === priorPosition.Id;
					});
					if (existingIndex !== -1) {
						trkData.live.latestPositions.splice(existingIndex, 1);
					}
				}
				// normalized position
				if (trkData.positionsById[newPosition.Id] === undefined) {
					trkData.positionsById[newPosition.Id] = normalizeAssetData(asset.Id, "position", latestPosition);
				}

				trkData.live.latestPositions.push(trkData.positionsById[newPosition.Id]);
				trkData.live.latestPositionsByAssetId[assetId] = trkData.positionsById[newPosition.Id];
				updateAssetNotificationTime(assetId, "positions", latestPosition.Position.Epoch);
				trkData.live.positions.push(latestPosition);

				trkData.live.normalizedPositions.push(trkData.positionsById[newPosition.Id]);
				if (trkData.live.normalizedPositionsByAssetId[assetId] === undefined) {
					trkData.live.normalizedPositionsByAssetId[assetId] = [];
				}
				trkData.live.normalizedPositionsByAssetId[assetId].push(trkData.positionsById[newPosition.Id]);

				if (trkData.live.positionsByAssetId[assetId] === undefined) {
					trkData.live.positionsByAssetId[assetId] = [];
				}
				trkData.live.positionsByAssetId[assetId].push(latestPosition);
				if (trkData.live.markersByAssetId[assetId] === undefined) {
					trkData.live.markersByAssetId[assetId] = [];
				}
				trkData.live.markersByAssetId[assetId].push(newPositionMarker);
				trkData.live.markersByPositionId[newPosition.Id] = newPositionMarker;

				if (isMarkerActive) {
					// this position was open with live already
					// act like it was just clicked
					markerClick(newPositionMarker, "position", null, false);
				}

				// update position history for this asset
				updateLiveDataForAsset(asset, false, newPosition);

				// if tracking this asset, automatically highlight this new position
				if (!isNewFollowPosition && state.liveFollow.isActive && $.inArray(asset, state.liveFollow.assets) !== -1) {
					isNewFollowPosition = true;
				}

				// check for bouncy event
				checkMarkerBounce(newPositionMarker);
			}
		}

		if (isNewFollowPosition) {
			openMostRecentLiveFollowPosition();
		}

		if (updatePositions) {
			throttles.updatePositionStatus();
		}
	} catch (ex) {
		trkData.pending.live = false;
		handleWebServiceError("Error querying live assets.");
	}
}

export function checkMarkerBounce(marker) {
	if (marker == null) {
		return;
	}
	var location = marker.data.location;
	if (location == null) {
		return;
	}
	if (location.Events == null) {
		return;
	}
	var bouncyEvents = options.bounceOnEvents;
	for (var i = 0; i < location.Events.length; i++) {
		var evt = location.Events[i];
		if (bouncyEvents.indexOf(evt.Type) !== -1) {
			if (!marker.isBouncing()) {
				marker
					.setBouncingOptions({
						bounceHeight: 15,
						bounceSpeed: 150,
						exclusive: false,
					})
					.toggleBouncing();
			}
			if (marker.bounceTimeout != null) {
				clearTimeout(marker.bounceTimeout);
			}
			marker.bounceTimeout = setTimeout(function () {
				if (marker.isBouncing()) {
					marker.stopBouncing();
				}
			}, options.bounceForDuration);
			return;
		}
	}
}

export async function queryLiveAssets() {
	log("Querying live assets.");
	var data = {
		lang: user.dateCulture,
		dbg: getDbg(),
	};

	try {
		const req = await fetch(wrapUrl("/services/GPSService.asmx/GetLatestPositionsForAssetsReq"), {
			method: "POST",
			body: JSON.stringify(data),
			headers: new Headers({
				"Content-Type": "application/json; charset=utf-8",
			}),
		});
		const msg = await req.json();

		if (!msg.d) {
			return;
		}

		state.hasQueriedLive = true;
		log("Live assets results received.");
		trkData.live.positions = msg.d;
		trkData.live.normalizedPositions = [];
		trkData.live.positionsByAssetId = _.groupBy(trkData.live.positions, "AssetId");

		trkData.live.messageCounts = [];
		trkData.live.messageCountsByAssetId = {};

		const markers = [];

		// only returns assets with at least one position -- if an asset has no positions it will not be included
		var isNewFollowPosition = false;
		for (var i = 0; i < trkData.live.positions.length; i++) {
			// asset/position
			var assetId = trkData.live.positions[i].AssetId;
			var asset = findAssetById(assetId);
			if (asset === undefined || asset === null) {
				continue;
			}
			var position = trkData.live.positions[i].Position;

			if (trkData.positionsById[position.Id] === undefined) {
				trkData.positionsById[position.Id] = normalizeAssetData(
					asset.Id,
					"position",
					trkData.live.positions[i].Position
				);
			}
			trkData.live.normalizedPositions.push(trkData.positionsById[position.Id]);

			var isActive =
				state.activeMapMode === mapModes.LIVE && !isItemIncluded(user.displayPreferences.hiddenAssets, asset.Id);

			// add position to map
			var positionMarker = addPositionMarkerToPoint(
				[position.Lat, position.Lng],
				false,
				position,
				asset,
				255,
				null,
				null,
				null,
				trkDataGroups.NORMAL_LIVE
			);

			// toggle visibility
			if (isActive && !position.IsHidden) {
				addItemToMap(positionMarker);
			} else {
				removeItemFromMap(positionMarker);
			}

			if (!isNewFollowPosition && state.liveFollow.isActive && _.indexOf(state.liveFollow.assets, asset) !== -1) {
				isNewFollowPosition = true;
			}

			markers.push(positionMarker);
		}

		trkData.live.latestPositions = trkData.live.normalizedPositions.slice(); // copy array
		trkData.live.latestPositionsByAssetId = _.keyBy(trkData.live.latestPositions, "AssetId");
		_.each(trkData.live.latestPositionsByAssetId, function (latestPosition) {
			updateAssetNotificationTime(latestPosition.AssetId, "positions", latestPosition.Epoch);
		});

		trkData.live.normalizedPositionsByAssetId = _.groupBy(trkData.live.normalizedPositions, "AssetId");
		trkData.live.markersByAssetId = _.groupBy(markers, function (marker) {
			return marker.data.assetId;
		});
		trkData.live.markersByPositionId = _.keyBy(markers, function (marker) {
			return marker.data.location.Id;
		});

		// show active locations in history area
		_.each(trkData.assets, function (asset) {
			updateLiveDataForAsset(asset, true, trkData.live.latestPositionsByAssetId[asset.Id]);
		});
		//createLivePositionResults();

		getLiveAssetsShown();

		if (isNewFollowPosition) {
			openMostRecentLiveFollowPosition();
		}

		// check for default asset highlight
		if (!showDefaultAsset()) {
			// set bounds
			setMapBounds();
		}

		throttles.updatePositionStatus();
		trkData.live.isInitialized = true;
		domNodes.mapMode.liveLoaded.textContent = moment()
			.subtract(user.tickOffset, "ms")
			.format(user.dateFormat.substring(0, user.dateFormat.indexOf(" ")) + " HH:mm");
	} catch (ex) {
		handleWebServiceError("Error querying live assets.");
	}
}

function updateLiveDataForAsset(asset, ignoreState, position) {
	if (asset === undefined || asset === null) {
		return;
	}
	var latestPosition = trkData.live.latestPositionsByAssetId[asset.Id];
	if (latestPosition === undefined) {
		return;
	}

	var nodes = domNodes.assets[asset.Id];
	if (nodes === undefined) {
		return;
	}

	var position = latestPosition.Position;
	if (ignoreState === undefined || ignoreState === false) {
		updateAssetState(asset, position.State);
	} else {
		updateAssetState(asset, asset.State);
	}

	updateAssetFunctionBadges(getAssetDataGroupForCurrentViewMode(), asset.Id);
	updateGroupFunctionBadges(getAssetDataGroupForCurrentViewMode(), [asset.Id], "asset");
	updateTimeBasedNotificationIndicatorsForAsset(asset);
	_.each(asset.ParentGroupIds, function (groupId) {
		updateTimeBasedNotificationIndicatorsForGroup(groupId);
	});

	// refresh the positions dialog if it is currently opened for a related asset or group
	if (state.activeMapMode === mapModes.LIVE) {
		if (position !== undefined && position !== null) {
			if (
				domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
				domNodes.panels.secondary.getAttribute("data-item-type") === "assets" &&
				document.getElementById("dialog-functions").querySelector(".dialog") === domNodes.dialogs.assetPositions
			) {
				// todo: only append to the existing listing, don't recreate
				var assetId = parseInt(domNodes.panels.secondary.getAttribute("data-item-id"));
				if (asset.Id === assetId) {
					openPositionsForAsset(asset);
				}
			} else if (
				domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
				domNodes.panels.secondary.getAttribute("data-item-type") === "groups" &&
				document.getElementById("dialog-functions").querySelector(".dialog") === domNodes.dialogs.assetPositions
			) {
				// todo: only append to the existing listing, don't recreate
				var groupId = domNodes.panels.secondary.getAttribute("data-item-id");
				if (asset.ParentGroupIds.indexOf(groupId) !== -1) {
					openPositionsForGroup(findGroupById(groupId));
				}
			}
		}
	}
}

function showDefaultAsset() {
	if (state.activeMapMode !== mapModes.LIVE) {
		return;
	}

	if (!state.isFirstLoad) {
		return false;
	}

	if (options.showAssetId == "" && options.showAssetUniqueId == "") {
		return false;
	}

	var asset = null;
	if (options.showAssetUniqueId != "") {
		asset = findAssetByUniqueId(options.showAssetUniqueId);
	}
	if (options.showAssetId != "") {
		asset = findAssetById(options.showAssetId);
	}

	if (asset == null) {
		return false;
	}

	openAssetLatestPosition(asset);

	if (user.isAnonymous) {
		liveFollowAsset(asset);
	}
	return true;
}

export function openAssetLatestPosition(asset) {
	var matchedMarker = getCurrentMarkerForAsset(asset);
	if (matchedMarker === undefined || matchedMarker === null) {
		return;
	}

	toggleAssetActive(asset.Id, true, true);

	if (!options.disablePositionPopup) {
		//matchedMarker.fire('click');
		markerClick(matchedMarker, "position", null, false);
	} else {
		map.setView(matchedMarker.getLatLng(), options.defaultZoom);
	}
}

export function getLiveAssetsShown() {
	var visibleAssetsWithPositions = _.filter(trkData.live.latestPositionsByAssetId, function (item) {
		return _.includes(trkData.visible.assets, item.AssetId);
	});

	document.getElementById("history-noresults").classList.remove("is-visible");
	var noResults = document.getElementById("live-noresults");
	if (visibleAssetsWithPositions.length > 0) {
		var msg_liveAssets = strings.MSG_ASSETS_SHOWN_LIVE;
		msg_liveAssets = msg_liveAssets.replace("{0}", visibleAssetsWithPositions.length); // num assets
		msg_liveAssets = msg_liveAssets.replace("{1}", visibleAssetsWithPositions.length > 1 ? "s" : ""); // assets plural
		domNodes.mapTools.visibleSummary.textContent = msg_liveAssets;
		domNodes.mapMode.visibleAssetsLive.textContent = visibleAssetsWithPositions.length;
		noResults.classList.remove("is-visible");
	} else {
		domNodes.mapTools.visibleSummary.textContent = "";
		domNodes.mapMode.visibleAssetsLive.textContent = strings.GROUP_NONE;
		if (state.hasQueriedLive && trkData.visible.assets.length > 0) {
			noResults.classList.add("is-visible");
		}
	}
}