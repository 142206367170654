export const intervals = {
	history: null,
	positionStatus: null,
	playbackInterval: null,
	mapLayers: null,
	satelliteInterval: [],
	notification: null,
	notificationNum: 0,
	emergency: null,
	version: null,
	snapToRoadsInterval: null,
	currentTime: null,
	cleanupExpiredData: null,
};

export const throttles = {
	updateLiveAssets: null,
	queryLatestNotifications: null,
	queryLatestEvents: null,
	updatePositionStatus: null,
	emergencyAlert: null,
};
