"use strict";

import strings from "./strings.js";
import trkData from "./data.js";
import state from "./state.js";
import options from "./options.js";
import user from "./user.js";
import domNodes from "./domNodes.js";
import { mapModes, trkDataGroups } from "./const.js";
import { closeSecondaryPanel } from "./panel.js";
import { openDialogPanel } from "./panel-nav.js";
import { findAssetById } from "./assets.js";
import { addItemToMap } from "./map-items.js";
import { getNotificationCounts, getNotificationCountsForGroup } from "./notifications.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import { findTripById } from "./trips.js";
import { throttles } from "./timers.js";
import { resizeApp } from "./window-layout.js";
import { findJourneyById } from "./journey.js";
import { getAssetDataGroupForCurrentViewMode } from "./map-viewmode.js";
import { getDisplayFilterForEventType } from "./display-filter.js";
import { createListing, defaultListItemSort } from "./item-listing.js";
import { getDbg } from "./log.js";
import {
	openActivityForAsset,
	openActivityForTrip,
	openActivityForJourney,
	openActivityForGroup,
} from "./activities.js";
import {
	openChatForAsset,
	openChatForTrip,
	openChatForJourney,
	openChatForGroup,
	openMessagesForAsset,
	openMessagesForTrip,
	openMessagesForJourney,
	openMessagesForGroup,
	openChatForSharedView,
} from "./messages.js";
import {
	findGroupById,
	openEventsForGroup,
	openStatusForGroup,
	openAlertsForGroup,
	updateGroupVisibilityStatus,
} from "./asset-group.js";
import {
	openPositionsForAsset,
	openPositionsForGroup,
	openPositionsForTrip,
	openPositionsForJourney,
} from "./positions.js";
import { sortItemGroups } from "./item-sorting.js";
import { openStatusForAsset } from "./asset-state.js";
import { openAlertsForAsset } from "./asset-alerts.js";
import { openEventsForTrip } from "./trips.js";
import { openEventsForAsset } from "./asset-events.js";
import { openAssetSettingsPanel, openAssetGroupSettingsPanel, openSharedViewSettingsPanel } from "./panel-settings.js";
import { openPositionsForSharedView } from "./positions.js";
import { openActivityForSharedView } from "./activities.js";
import { openTripSettingsPanel, openJourneySettingsPanel } from "./panel-settings.js";
import {
	checkForShareViewChange,
	selectSharedView,
	indexSharedViewsForSearch,
	findSharedViewById,
} from "./shared-view.js";

import $ from "jquery";
import $j from "jquery";
import jQuery from "jquery";
import _ from "lodash";
import moment from "moment"; // https://www.npmjs.com/package/moment
const Cookies = window.Cookies; // from 'js.cookie.js', v2.2.0

/*global JsSearch, MapToolbar */
// import JsSearch from '../js-search.js';
// import MapToolbar from '../MapToolbar.js';

// public variables
const keys = {};

// end public variables

// private variables

throttles.tilesLoaded = _.throttle(
	function () {
		if (trkData.tileLoadedQueue.length == 0) {
			return;
		}
		var data = {
			Uid: user.id,
			Loads: trkData.tileLoadedQueue,
		};
		trkData.tileLoadedQueue = [];
		$.ajax({
			type: "POST",
			url: wrapUrl("/api/ui/tls"),
			contentType: "application/json; charset=utf-8",
			data: JSON.stringify(data),
			dataType: "json",
		});
	},
	3000,
	{ leading: false }
);

export function handleNotificationItemAction(action, actionGroup, id) {
	switch (actionGroup) {
		case "assets":
			var asset = findAssetById(id);
			switch (action) {
				case "asset-positions":
				case "positions":
					openPositionsForAsset(asset);
					break;
				case "asset-alerts":
				case "alerts":
					openAlertsForAsset(asset);
					break;
				case "asset-events":
				case "events":
					openEventsForAsset(asset);
					break;
				case "asset-status":
				case "status":
					openStatusForAsset(asset);
					break;
				case "asset-messages":
				case "messages":
					openMessagesForAsset(asset);
					break;
				case "asset-chat":
				case "chat":
					openChatForAsset(asset);
					break;
				case "options":
					openAssetSettingsPanel(asset);
					break;
				case "asset-activity":
				case "activity":
					openActivityForAsset(asset);
					break;
				default:
					console.warn("No notification handler for: " + actionGroup + ", " + action);
					break;
			}
			break;
		case "groups":
			var group = findGroupById(id);
			switch (action) {
				case "group-positions":
				case "positions":
					openPositionsForGroup(group);
					break;
				case "group-alerts":
				case "alerts":
					openAlertsForGroup(group);
					break;
				case "group-events":
				case "events":
					openEventsForGroup(group);
					break;
				case "group-status":
				case "status":
					openStatusForGroup(group);
					break;
				case "group-messages":
				case "messages":
					openMessagesForGroup(group);
					break;
				case "group-chat":
				case "chat":
					openChatForGroup(group);
					break;
				case "options":
					openAssetGroupSettingsPanel(group);
					break;
				case "group-activity":
				case "activity":
					openActivityForGroup(group);
					break;
				default:
					console.warn("No notification handler for: " + actionGroup + ", " + action);
					break;
			}
			break;
		case "trips":
			var trip = findTripById(id);
			switch (action) {
				case "asset-positions":
				case "positions":
					openPositionsForTrip(trip);
					break;
				case "asset-alerts":
				case "alerts":
					openAlertsForTrip(trip);
					break;
				case "asset-events":
				case "events":
					openEventsForTrip(trip);
					break;
				case "asset-status":
				case "status":
					openStatusForTrip(trip);
					break;
				case "asset-messages":
				case "messages":
					openMessagesForTrip(trip);
					break;
				case "asset-chat":
				case "chat":
					openChatForTrip(trip);
					break;
				case "options":
					openTripSettingsPanel(trip);
					break;
				case "activity":
				case "asset-activity":
					openActivityForTrip(trip);
					break;
				default:
					console.warn("No notification handler for: " + actionGroup + ", " + action);
					break;
			}
			break;
		case "journeys":
			var journey = findJourneyById(id);
			switch (action) {
				case "asset-positions":
				case "positions":
					openPositionsForJourney(journey);
					break;
				case "asset-alerts":
				case "alerts":
					openAlertsForJourney(journey);
					break;
				case "asset-events":
				case "events":
					openEventsForJourney(journey);
					break;
				case "asset-status":
				case "status":
					openStatusForJourney(journey);
					break;
				case "asset-messages":
				case "messages":
					openMessagesForJourney(journey);
					break;
				case "asset-chat":
				case "chat":
					openChatForJourney(journey);
					break;
				case "options":
					openJourneySettingsPanel(journey);
					break;
				case "asset-activity":
				case "activity":
					openActivityForJourney(journey);
					break;
				default:
					console.warn("No notification handler for: " + actionGroup + ", " + action);
					break;
			}
			break;
		case "shared-views":
			var sharedView = findSharedViewById(id);
			switch (action) {
				case "positions":
					openPositionsForSharedView(sharedView);
					break;
				//case 'alerts':
				//    openAlertsForSharedView(sharedView);
				//    break;
				case "options":
					openSharedViewSettingsPanel(sharedView);
					break;
				case "status":
					openStatusForSharedView(sharedView);
					break;
				case "events":
					openEventsForSharedView(sharedView);
					break;
				// alerts
				// messages
				case "chat":
					openChatForSharedView(sharedView);
					break;
				case "activity":
					openActivityForSharedView(sharedView);
					break;
				default:
					console.warn("No notification handler for: " + actionGroup + ", " + action);
					break;
			}
			break;
		default:
			console.warn("No notification handler for: " + actionGroup + ", " + action);
			break;
	}
}

export function toggleEventContainer(isForAlerts) {
	$j("#event-panel-container").toggle();
	if ($j("#event-panel-container").is(":visible")) {
		if (isForAlerts) {
			$("#event-panel-tab-alerts").tab("show");
		} else {
			$("#event-panel-tab-events").tab("show");
		}
		Cookies.set("eventsPanel", true, { expires: 365, path: "/", secure: true });
		$j("#map_panels").hide();
	} else {
		Cookies.remove("eventsPanel");
		$j("#map_panels").show();
	}
	resizeApp(true);
}

// private, helper functions

export function initializeFormValidation() {
	var defaultBootstrapOptions = {
		errorClass: "is-invalid",
		validClass: "is-valid",
		focusInvalid: true,
		ignoreTitle: true,
		wrapper: "div",
		errorPlacement: function (error, element) {
			error.addClass("invalid-feedback");
			error.insertAfter(element);
		},
	};
	jQuery.validator.setDefaults(defaultBootstrapOptions);
	trkData.validation.placeFind = $("#form-add-place-find").validate();
	trkData.validation.placeSearch = $("#form-add-place-search").validate();
	trkData.validation.placeAdd = $("#form-add-place").validate();
	trkData.validation.routing = $("#form-routing").validate();

	trkData.validation.sendPositionFind = $("#form-send-position-find").validate();
	trkData.validation.sendPositionSearch = $("#form-send-position-search").validate();
	trkData.validation.sendPositionShow = $("#form-send-position-show").validate();

	trkData.validation.idpAvlGeofence = $("#idp-avl-geofence-form").validate();
	trkData.validation.idpAvlParameters = $("#idp-avl-parameters-form").validate();
	trkData.validation.idpOutput = $("#idp-output-form").validate();
	trkData.validation.idpCoreParameters = $("#idp-core-parameters-form").validate();
	trkData.validation.idpIoParameters = $("#idp-avl-io-parameters-form").validate();
	trkData.validation.idpMetersSet = $("#idp-meters-set-form").validate();
	trkData.validation.idpReset = $("#idp-reset-form").validate();
	trkData.validation.idpSleepSchedule = $("#idp-sleep-schedule-form").validate();
	trkData.validation.idpModemRegistration = $("#idp-modem-registrations-form").validate();
	trkData.validation.idpEyeAlertImageRequest = $("#idp-eyealert-form").validate();
	trkData.validation.idpParameters = $("#idp-parameters-form").validate();
	trkData.validation.idpCommandLog = $("#idp-command-log-form").validate();
	trkData.validation.idpArc = $("#idp-arc-form").validate();
	trkData.validation.idpGarmin = $("#idp-garmin-form").validate();
	trkData.validation.idpImmobilizer = $("#idp-immobilizer-parameters-form").validate();
	trkData.validation.acknowledgeAlert = $("#acknowledge-form").validate();
	trkData.validation.inmarsatC = $("#inmarsatc-location-form").validate();
	trkData.validation.inmarsatCSchedule = $("#inmarsatc-schedule-form").validate();
	trkData.validation.inmarsatCDelete = $("#inmarsatc-dnid-delete-form").validate();
	trkData.validation.iridiumEdge = $("#edge-location-form").validate();
	trkData.validation.tm3000 = $("#tm3000-form").validate();
	trkData.validation.calampApn = $("#calamp-apn-form").validate();
	trkData.validation.calampOutput = $("#calamp-output-form").validate();
	trkData.validation.extremeInterval = $("#extreme-form").validate();
	trkData.validation.extremeEmergencyDestination = $("#extreme-emergency-destination-form").validate();
	trkData.validation.extremeEmergencyRecipient = $("#extreme-emergency-recipient-form").validate();
	trkData.validation.inreachInterval = $("#inreach-interval-form").validate();
	trkData.validation.hughesDownloadFile = $("#hughes-download-file-form").validate();
	trkData.validation.hughesCommands = $("#hughes-commands-form").validate();
	trkData.validation.queclinkApn = $("#queclink-apn-form").validate();
	trkData.validation.queclinkSettings = $("#queclink-settings-form").validate();
	trkData.validation.queclinkCommands = $("#queclink-commands-form").validate();
	trkData.validation.lt100Periodic = $("#lt100-periodic-form").validate();
	trkData.validation.lt100Motion = $("#lt100-motion-form").validate();
	trkData.validation.lt100Vibrate = $("#lt100-vibrate-form").validate();
	trkData.validation.lt501Periodic = $("#lt501-periodic-form").validate();
	trkData.validation.lt501Motion = $("#lt501-motion-form").validate();
	trkData.validation.lt501Location = $("#lt501-location-form").validate();
	trkData.validation.quake = $("#quake-settings-form").validate();
	trkData.validation.fbbInterval = $("#fbb-interval-form").validate();
	trkData.validation.acknowledgeAlert = $("#acknowledge-form").validate();
	trkData.validation.nal = $("#nal-properties-form").validate();
	trkData.validation.geoproInterval = $("#geopro-interval-form").validate();
	trkData.validation.gttsSettings = $("#gtts-settings-form").validate();
	trkData.validation.flightcellPayload = $("#flightcell-payload-form").validate();
	trkData.validation.edgeSolarSettings = $("#edge-solar-settings-form").validate();
	trkData.validation.itracCommands = $("#itrac-interval-form").validate();

	trkData.validation.currentDriver = $("#current-driver-login-form").validate();

	trkData.validation.addPosition = $("#form-add-position").validate();
	//trkData.validation.sendPositionSearch = $('#form-send-position-search').validate();
	trkData.validation.sendPositionSend = $("#form-send-position-send").validate();
	trkData.validation.sendMessage = $("#form-send-message").validate();

	trkData.validation.refuelAdd = $("#form-add-refuel").validate();

	// edit-asset validation
	trkData.validation.addGroup = $("#form-add-group").validate();
	trkData.validation.editAsset = $("#form-edit-asset").validate();
	$.validator.addMethod(
		"regex",
		function (value, element, regexp) {
			var re = new RegExp(regexp);
			return this.optional(element) || re.test(value);
		},
		"Invalid ID."
	);
	$.validator.addMethod(
		"imei-code",
		function (value) {
			return /^\d{5}$/.test(value);
		},
		"Invalid Portal Registration Code #1."
	);
	$.validator.addMethod(
		"shared-view-shareone",
		function (value, element) {
			var totalItemsShared = 0;
			if (document.getElementById("SharedViewPermissionsGroups").checked) {
				totalItemsShared += document.querySelectorAll('input[name="SharedViewAssetGroupIds"]:checked').length;
			}
			if (document.getElementById("SharedViewPermissionsAssets").checked) {
				totalItemsShared += document.querySelectorAll('input[name="SharedViewAssetIds"]:checked').length;
			}
			if (document.getElementById("SharedViewPermissionsGeofences").checked) {
				totalItemsShared += document.querySelectorAll('input[name="SharedViewFenceIds"]:checked').length;
			}
			if (document.getElementById("SharedViewPermissionsPlaces").checked) {
				totalItemsShared += document.querySelectorAll('input[name="SharedViewPlaceIds"]:checked').length;
			}

			return totalItemsShared > 0;
		},
		strings.MSG_ERROR_SHARE_SOMETHING
	);
	trkData.validation.editGroup = $("#form-edit-group").validate();
	trkData.validation.geofence = $("#form-add-geofence").validate();
	trkData.validation.dPlusQuery = $("#dplus-query-form").validate();
	trkData.validation.dPlusInterval = $("#dplus-interval-form").validate();
	trkData.validation.runReport = $("#run-report-form").validate();

	trkData.validation.sharedViewShare = $("#form-shared-view-share").validate();

	$.validator.addMethod(
		"ranges",
		function (value, element, ranges) {
			var noUpperBound = false;
			var valid = false;
			for (var i = 0; i < ranges.length; i++) {
				if (ranges[i].length === 1) {
					noUpperBound = true;
				}
				if (value >= ranges[i][0] && (value <= ranges[i][1] || noUpperBound)) {
					valid = true;
					break;
				}
			}

			return this.optional(element) || valid;
		},
		"Invalid value."
	);
	$("#txtIDPAVLMovingIntervalSat").rules("add", { ranges: [[0, 0], [60]], messages: { ranges: "Min 60s" } }); // either 0 to disable or 60+
	$("#txtIDPAVLStationaryIntervalSat").rules("add", { ranges: [[0, 0], [60]], messages: { ranges: "Min 60s" } }); // either 0 to disable or 60+
}

function notificationClick(evt) {
	var rootNode = this.parentNode.parentNode.parentNode.parentNode;
	var assetId = rootNode.getAttribute("data-asset-id");
	var tripId = rootNode.getAttribute("data-trip-id");
	var groupId = rootNode.getAttribute("data-group-id");
	var asset = null;
	var trip = null;
	var group = null;
	var journey = null;
	if (assetId !== null) {
		asset = findAssetById(assetId);
		if (asset === undefined || asset === null) {
			return;
		}
	}
	if (tripId !== null) {
		trip = findTripById(tripId);
		if (trip === undefined || trip === null) {
			return;
		}
	}
	if (groupId !== null && groupId.indexOf("journey-") === 0) {
		journey = findJourneyById(groupId.substring(8));
		if (journey === undefined || journey === null) {
			return;
		}
	} else if (groupId !== null) {
		group = findGroupById(groupId);
		if (group === undefined || group === null) {
			return;
		}
	}

	var actions = ["positions"];
	if (!user.isAnonymous) {
		actions.push("alerts");
	}
	actions.push("events");
	actions.push("status");
	if (!user.isAnonymous || options.allowAnonymousMessaging) {
		actions.push("messages");
	}

	var action = "positions";

	if (evt.originalEvent !== undefined && evt.originalEvent.offsetX !== undefined) {
		var offsetX = evt.originalEvent.offsetX;
		if (offsetX < 15 && actions.length > 0) {
			action = actions[0];
		} else if (offsetX < 30 && actions.length > 1) {
			action = actions[1];
		} else if (offsetX < 45 && actions.length > 2) {
			action = actions[2];
		} else if (offsetX < 60 && actions.length > 3) {
			action = actions[3];
		} else if (actions.length > 4) {
			action = actions[4];
		}

		switch (action) {
			case "positions":
				if (asset !== null) {
					openPositionsForAsset(asset);
				} else if (trip !== null) {
					openPositionsForTrip(trip);
				} else if (journey !== null) {
					openPositionsForJourney(journey);
				} else if (group !== null) {
					openPositionsForGroup(group);
				}
				break;
			case "events":
				if (asset !== null) {
					openEventsForAsset(asset);
				} else if (trip !== null) {
					openEventsForTrip(trip);
				} else if (journey !== null) {
					openEventsForJourney(journey);
				} else if (group !== null) {
					openEventsForGroup(group);
				}
				break;
			case "alerts":
				if (asset !== null) {
					openAlertsForAsset(asset);
				} else if (trip !== null) {
					openAlertsForTrip(trip);
				} else if (journey !== null) {
					openAlertsForJourney(journey);
				} else if (group !== null) {
					openAlertsForGroup(group);
				}
				break;
			case "status":
				if (asset !== null) {
					openStatusForAsset(asset);
				} else if (trip !== null) {
					openStatusForTrip(trip);
				} else if (journey !== null) {
					openStatusForJourney(journey);
				} else if (group !== null) {
					openStatusForGroup(group);
				}
				break;
			case "activity":
				if (asset !== null) {
					openActivityForAsset(asset);
				} else if (trip !== null) {
					openActivityForTrip(trip);
				} else if (journey !== null) {
					openActivityForJourney(journey);
				} else if (group !== null) {
					openActivityForGroup(group);
				}
				break;
			case "messages":
				// determine whether to open chat or messages
				var counts;
				if (asset !== null) {
					counts = getNotificationCounts(getAssetDataGroupForCurrentViewMode(), assetId);
				} else if (trip !== null) {
					counts = getNotificationCounts(trkDataGroups.JOURNEY_HISTORY, tripId);
				} else if (journey !== null) {
					counts = getNotificationCountsForGroup(trkDataGroups.JOURNEY_HISTORY, journey.Id);
				} else {
					counts = getNotificationCountsForGroup(getAssetDataGroupForCurrentViewMode(), groupId);
				}

				if (user.isAnonymous || counts.messages === 0 || counts.messagesChat > 0) {
					if (asset !== null) {
						openChatForAsset(asset);
					} else if (trip !== null) {
						openChatForTrip(trip);
					} else if (journey !== null) {
						openChatForJourney(journey);
					} else if (group !== null) {
						openChatForGroup(group);
					}
				} else {
					if (asset !== null) {
						openMessagesForAsset(asset);
					} else if (trip !== null) {
						openMessagesForTrip(trip);
					} else if (journey !== null) {
						openMessagesForJourney(journey);
					} else if (group !== null) {
						openMessagesForGroup(group);
					}
				}
				break;
		}
	}
	evt.preventDefault();
	evt.stopPropagation();
}

function notificationChangeTitle(evt) {
	// because this is throttled, we may have already had a mouseout
	if (this.doUpdateTooltip === undefined) {
		return;
	}
	var rootNode = this.parentNode.parentNode.parentNode.parentNode;
	var assetId = rootNode.getAttribute("data-asset-id");
	var tripId = rootNode.getAttribute("data-trip-id");
	var groupId = rootNode.getAttribute("data-group-id");
	var asset = null;
	var trip = null;
	var group = null;
	var journey = null;
	if (assetId !== null) {
		asset = findAssetById(assetId);
		if (asset === undefined || asset === null) {
			return;
		}
	}
	if (tripId !== null) {
		trip = findTripById(tripId);
		if (trip === undefined || trip === null) {
			return;
		}
	}
	if (groupId !== null && groupId.indexOf("journey-") === 0) {
		journey = findJourneyById(groupId.substring(8));
		if (journey === undefined || journey === null) {
			return;
		}
	} else if (groupId !== null && groupId !== "all-assets") {
		group = findGroupById(groupId);
		if (group === undefined || group === null) {
			return;
		}
	}

	if (evt.originalEvent !== undefined && evt.originalEvent.offsetX !== undefined) {
		var counts;
		if (asset !== null) {
			counts = getNotificationCounts(getAssetDataGroupForCurrentViewMode(), assetId);
		} else if (trip !== null) {
			counts = getNotificationCounts(trkDataGroups.JOURNEY_HISTORY, tripId);
		} else if (journey !== null) {
			counts = getNotificationCountsForGroup(trkDataGroups.JOURNEY_HISTORY, journey.Id);
		} else {
			counts = getNotificationCountsForGroup(getAssetDataGroupForCurrentViewMode(), groupId);
		}

		var offsetX = evt.originalEvent.offsetX;
		if (offsetX === 0) {
			// sometimes offsetX and offsetY are presented as 0 in FF
			offsetX = evt.originalEvent.clientX - 117;
		}
		var titles = [strings.POSITIONS + " - " + counts.positions];
		if (!user.isAnonymous) {
			titles.push(strings.ALERTS + " - " + counts.alerts);
		}
		titles.push(strings.EVENTS + " - " + counts.events);
		titles.push(strings.STATUS + " - " + counts.status);
		if (!user.isAnonymous || options.allowAnonymousMessaging) {
			var title = strings.MESSAGES + " - " + counts.messagesChat;
			if (!user.isAnonymous) {
				title += " / " + counts.messagesDevice;
			}
			titles.push(title);
		}
		if (offsetX < 15 && titles.length > 0) {
			this.setAttribute("data-original-title", titles[0]);
		} else if (offsetX < 30 && titles.length > 1) {
			this.setAttribute("data-original-title", titles[1]);
		} else if (offsetX < 45 && titles.length > 2) {
			this.setAttribute("data-original-title", titles[2]);
		} else if (offsetX < 60 && titles.length > 3) {
			this.setAttribute("data-original-title", titles[3]);
		} else if (titles.length > 4) {
			this.setAttribute("data-original-title", titles[4]);
		}
		$(this).bsTooltip("show");
	}
	evt.preventDefault();
	evt.stopPropagation();
}

$("#panel-content-assets,#panel-content-journeys").on("click", ".notifications", notificationClick);
$("#panel-content-assets,#panel-content-journeys").on(
	"mousemove",
	".notifications",
	_.throttle(notificationChangeTitle, 150)
);
// mouseover was not consistently handled between Edge, Chrome and FF to use as a box hover event
//$('#panel-content-assets').on('mouseover', '.notifications', notificationChangeTitle);
$("#panel-content-assets,#panel-content-journeys").on("mouseover", ".notifications", function (evt) {
	this.doUpdateTooltip = true;
});
$("#panel-content-assets,#panel-content-journeys").on("mouseout", ".notifications", function (evt) {
	delete this.doUpdateTooltip;
	var that = this;
	setTimeout(function () {
		if (that.doUpdateTooltip === undefined) {
			$(that).bsTooltip("hide");
		}
	}, 175);
});

export function queryUsersForAssets() {
	toggleLoadingMessage(true, "assetUsers");
	var data = {
		dbg: getDbg(),
	};
	return $j
		.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/GetUsersForAssetsReq"),
			data: JSON.stringify(data),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
		})
		.done(function (msg) {
			if (!msg.d) {
				return;
			}

			toggleLoadingMessage(false, "assetUsers");
			var result = msg.d;
			trkData.users = result.Users;
			trkData.assetUsers = result.AssetUsers;
			trkData.assetForms = result.AssetForms;
			trkData.drivers = result.Drivers;
			trkData.assetDrivers = result.AssetDrivers;
			trkData.driverGroups = _.each(result.DriverGroups, function (driverGroup) {
				driverGroup.Groups = [];
				driverGroup.GroupIds = [];
			});
			trkData.driverGroupsById = _.keyBy(trkData.driverGroups, "Id");
			_.each(trkData.driverGroups, function (driverGroup) {
				if (driverGroup.ParentGroupId !== null && trkData.driverGroupsById[driverGroup.ParentGroupId] !== undefined) {
					trkData.driverGroupsById[driverGroup.ParentGroupId].GroupIds.push(driverGroup.Id);
				}
			});
			sortItemGroups(trkData.driverGroups);
			trkData.assetDriverGroups = result.AssetDriverGroups;
			trkData.fenceUsers = result.FenceUsers;
			trkData.placeUsers = result.PlaceUsers;
			trkData.assetGroupUsers = result.AssetGroupUsers;
		})
		.fail(function (xhr, status, error) {
			handleWebServiceError(strings.MSG_QUERY_USERS_ERROR);
			toggleLoadingMessage(false, "assetUsers");
		});
}

function getFilteredEventsForJourney(journeyId, filter) {
	var journey = findJourneyById(journeyId);
	var events = [];
	_.each(journey.Trips, function (trip) {
		events = events.concat(_.filter(trkData.trips.normalizedEventsByTripId[trip.Id], filter));
	});
	events = _.sortBy(events, function (item) {
		return item.Epoch;
	}).reverse();
	return events;
}

function openEventsForSharedView(sharedView) {
	var events = _.sortBy(
		_.filter(trkData.sharedView.normalizedEvents, getDisplayFilterForEventType("events")),
		defaultListItemSort
	).reverse();
	createListing(events, "events");
	openDialogPanel(
		domNodes.dialogs.assetEvents,
		strings.EVENTS,
		sharedView,
		false,
		checkForShareViewChange(sharedView),
		"shared-view",
		"shared-view-events",
		openEventsForSharedView
	);
}

function openEventsForJourney(journey) {
	var events = getFilteredEventsForJourney(journey.Id, getDisplayFilterForEventType("events"));
	createListing(events, "events");
	openDialogPanel(
		domNodes.dialogs.assetEvents,
		strings.EVENTS,
		journey,
		false,
		null,
		"journey",
		"journey-events",
		openEventsForJourney
	);
}

function openAlertsForTrip(trip) {
	if (user.isAnonymous) {
		return;
	}
	var alerts = _.filter(trkData.trips.normalizedEventsByTripId[trip.Id], getDisplayFilterForEventType("alerts"));
	createListing(alerts, "alerts");
	openDialogPanel(
		domNodes.dialogs.assetAlerts,
		strings.ALERTS,
		trip,
		false,
		undefined,
		"trip",
		"trip-alerts",
		openAlertsForTrip
	);
}

function openAlertsForJourney(journey) {
	if (user.isAnonymous) {
		return;
	}
	var events = getFilteredEventsForJourney(journey.Id, getDisplayFilterForEventType("alerts"));
	createListing(events, "alerts");
	openDialogPanel(
		domNodes.dialogs.assetAlerts,
		strings.ALERTS,
		journey,
		false,
		null,
		"journey",
		"journey-alerts",
		openAlertsForJourney
	);
}

function openStatusForSharedView(sharedView) {
	var events = _.sortBy(
		_.filter(trkData.sharedView.normalizedEvents, getDisplayFilterForEventType("status")),
		defaultListItemSort
	).reverse();
	createListing(events, "status");
	openDialogPanel(
		domNodes.dialogs.assetStatus,
		strings.STATUS,
		sharedView,
		false,
		checkForShareViewChange(sharedView),
		"shared-view",
		"shared-view-status",
		openStatusForSharedView
	);
}

function openStatusForTrip(trip) {
	var events = _.filter(trkData.trips.normalizedEventsByTripId[trip.Id], getDisplayFilterForEventType("status"));
	createListing(events, "status");
	openDialogPanel(
		domNodes.dialogs.assetStatus,
		strings.STATUS,
		trip,
		false,
		undefined,
		"trip",
		"trip-status",
		openStatusForTrip
	);
}

function openStatusForJourney(journey) {
	var events = getFilteredEventsForJourney(journey.Id, getDisplayFilterForEventType("status"));
	createListing(events, "status");
	openDialogPanel(
		domNodes.dialogs.assetStatus,
		strings.STATUS,
		journey,
		false,
		null,
		"journey",
		"journey-status",
		openStatusForJourney
	);
}

$.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
	if (settings.sInstance === "event-data") {
		// filter active assets via a hidden column
		var assetId = parseInt(data[5]);
		if (_.indexOf(trkData.visible.assets, assetId) === -1) {
			return false;
		}

		var eventFilters = trkData.live.eventFilters;
		if (state.activeMapMode === mapModes.HISTORY) {
			eventFilters = trkData.history.eventFilters;
		}

		if (eventFilters.length === 0 || _.indexOf(eventFilters, "all") !== -1) {
			return true;
		}

		var eventTypeId = parseInt(data[6]);
		if (_.indexOf(eventFilters, eventTypeId) === -1) {
			return false;
		}
		return true;
	}
	return true;
});

export function cleanupExpiredLiveData() {
	if (state.activeMapMode !== mapModes.LIVE) {
		return;
	}
	var expirationEpoch = moment().subtract(4, "hours").toDate().getTime() / 1000;
	console.log(expirationEpoch);
	var oldEvents = _.filter(trkData.live.events, function (item) {
		return item.Epoch !== undefined && item.Epoch < expirationEpoch;
	});

	var oldPositions = _.filter(trkData.live.positions, function (item) {
		return item.Position.Epoch !== undefined && item.Position.Epoch < expirationEpoch;
	});

	var expiredEventsById = _.groupBy(oldEvents, function (item) {
		return item.Id;
	});
	var expiredPositionsById = _.groupBy(oldPositions, function (item) {
		return item.Position.Id;
	});
	for (var i = trkData.live.positions.length - 1; i >= 0; i--) {
		var position = trkData.live.positions[i];
		var latestPositionForAsset = trkData.live.latestPositionsByAssetId[position.AssetId];
		if (latestPositionForAsset !== undefined && latestPositionForAsset.Position.Id === position.Position.Id) {
			// don't remove the most recent position for an asset
			continue;
		}
		if (expiredPositionsById[position.Position.Id] !== undefined) {
			trkData.live.positions.splice(i, 1);
		}
		if (domNodes.positionListingById[position.Position.Id] !== undefined) {
			delete domNodes.positionListingById[position.Position.Id];
		}
		if (domNodes.sharedView.positionListingById[position.Position.Id] !== undefined) {
			delete domNodes.sharedView.positionListingById[position.Position.Id];
		}
	}

	trkData.live.normalizedPositions = _.reject(trkData.live.normalizedPositions, function (item) {
		var latestPositionForAsset = trkData.live.latestPositionsByAssetId[item.AssetId];
		return (
			latestPositionForAsset !== undefined &&
			latestPositionForAsset.Position.Id !== item.Position.Id &&
			expiredPositionsById[item.Position.Id] !== undefined
		);
	});

	for (var i = trkData.live.events.length - 1; i >= 0; i--) {
		var event = trkData.live.events[i];
		if (expiredEventsById[event.Id] !== undefined) {
			trkData.live.events.splice(i, 1);
		}
		if (domNodes.eventListingById[event.Id] !== undefined) {
			delete domNodes.eventListingById[event.Id];
		}
		if (domNodes.sharedView.eventListingById[event.Id] !== undefined) {
			delete domNodes.sharedView.eventListingById[event.Id];
		}
	}

	trkData.live.normalizedEvents = _.reject(trkData.live.normalizedEvents, function (item) {
		var isRelatedToLatestPosition = false;
		if (item.Position !== undefined && item.Position !== null) {
			var latestPositionForAsset = trkData.live.latestPositionsByAssetId[item.AssetId];
			if (latestPositionForAsset !== undefined && latestPositionForAsset.Position.Id === item.Position.Id) {
				isRelatedToLatestPosition = true;
			}
		}
		return !isRelatedToLatestPosition && expiredEventsById[item.Event.Id] !== undefined;
	});

	trkData.live.positionsByAssetId = _.groupBy(trkData.live.positions, "AssetId");
	trkData.live.normalizedPositionsByAssetId = _.groupBy(trkData.live.normalizedPositions, "AssetId");
	trkData.live.normalizedEventsByAssetId = _.groupBy(trkData.live.normalizedEvents, "AssetId");

	// todo: messages

	// todo: remove from actual data listings
	// trkData.live.events (should always keep latest for asset)
	//  .Epoch
	// trkData.live.positions (should always keep latest for asset = trkData.live.latestPositionsByAssetId)
	//  .Position.Epoch
	// regen trkData.live.positionsByAssetId
	// domNodes.eventListingById
	// domNodes.positionListingById
	// domNodes.messageListingById
}

export function findFenceUsersByFenceId(id) {
	if (trkData.fenceUsers == null) return null;
	for (var i = 0, len = trkData.fenceUsers.length; i < len; i++) {
		if (trkData.fenceUsers[i].FenceId == id) {
			return trkData.fenceUsers[i].UserIds;
		}
	}
}

export function addUserToFence(userId, fenceId) {
	if (trkData.fenceUsers == null) return;
	for (var i = 0; i < trkData.fenceUsers.length; i++) {
		if (trkData.fenceUsers[i].FenceId == fenceId) {
			trkData.fenceUsers[i].UserIds.push(userId);
			break;
		}
	}
}

export function removeUserFromFence(userId, fenceId) {
	if (trkData.fenceUsers == null) return;
	for (var i = 0; i < trkData.fenceUsers.length; i++) {
		if (trkData.fenceUsers[i].FenceId == fenceId) {
			for (var j = 0; j < trkData.fenceUsers[i].UserIds.length; j++) {
				if (userId == trkData.fenceUsers[i].UserIds[j]) {
					trkData.fenceUsers[i].UserIds.splice(j, 1);
					break;
				}
			}
			break;
		}
	}
}

export function findAssetGroupUsersByAssetGroupId(id) {
	if (trkData.assetGroupUsers == null) return null;
	for (var i = 0, len = trkData.assetGroupUsers.length; i < len; i++) {
		if (trkData.assetGroupUsers[i].AssetGroupId == id) {
			return trkData.assetGroupUsers[i].UserIds;
		}
	}
}

export function addUserToAssetGroup(userId, assetGroupId) {
	if (trkData.assetGroupUsers == null) return;
	for (var i = 0; i < trkData.assetGroupUsers.length; i++) {
		if (trkData.assetGroupUsers[i].AssetGroupId == assetGroupId) {
			trkData.assetGroupUsers[i].UserIds.push(userId);
			break;
		}
	}
}

export function removeUserFromAssetGroup(userId, assetGroupId) {
	if (trkData.assetGroupUsers == null) return;
	for (var i = 0; i < trkData.assetGroupUsers.length; i++) {
		if (trkData.assetGroupUsers[i].AssetGroupId == assetGroupId) {
			for (var j = 0; j < trkData.assetGroupUsers[i].UserIds.length; j++) {
				if (userId == trkData.assetGroupUsers[i].UserIds[j]) {
					trkData.assetGroupUsers[i].UserIds.splice(j, 1);
					break;
				}
			}
			break;
		}
	}
}

export function deleteSharedView(sharedView) {
	domNodes.sharedViews[sharedView.Id].parentNode.removeChild(domNodes.sharedViews[sharedView.Id]);
	var panel = domNodes.panels.secondary;
	if (
		panel.getAttribute("data-group-for") === "shared-views" &&
		parseInt(panel.getAttribute("data-item-id")) === sharedView.Id
	) {
		closeSecondaryPanel(); // no longer closes out the shared view
	}

	if (trkData.sharedView.current === sharedView) {
		selectSharedView(null);
	}

	// remove from trkData.sharedViews
	for (var i = 0; i < trkData.sharedViews.length; i++) {
		if (trkData.sharedViews[i].Id == sharedView.Id) trkData.sharedViews.splice(i, 1);
	}

	// if last item, show no shared views
	if (trkData.sharedViews.length === 0) {
		document.getElementById("no-shared-views").classList.add("is-visible");
		document.getElementById("shared-views-all").classList.remove("is-visible");
		document.getElementById("filter-shared-views").querySelector(".filter-box").classList.remove("is-visible");
	}
	updateGroupVisibilityStatus("all-shared-views");
	indexSharedViewsForSearch();
}

//function animateFence(timestamp, markers) {
//    var af = function () { animateFence(timestamp, markers); };
//    if ((timestamp - state.fenceAnimationTime) < 500) {
//        state.fenceAnimationRequestId = requestAnimationFrame(af);
//        return;
//    }
//    markers.setStyle({ dashArray: options[i % 3] });
//    if (i === 1000) {
//        i = 0;
//    }
//    i++;

//    state.fenceAnimationTime = timestamp;
//    state.fenceAnimationRequestId = requestAnimationFrame(af);
//}

//function animateFenceBorder(fenceMarkers) {
//    var i = 0;
//    state.fenceAnimationTime = 0;
//    requestAnimateFrame(animateFence)
//}

function getCurrentUserTimeString(includeOffset) {
	var time = moment(new Date().getTime() - user.tickOffset).format("HH:mm");
	if (includeOffset) {
		time += " " + user.utcDateOffset;
	}
	return time;
}

export function updateUserTime() {
	domNodes.mapTools.currentTimeCompact.textContent = getCurrentUserTimeString(false);
	domNodes.mapTools.currentTime.textContent = getCurrentUserTimeString(true);
}

function openAssetPositionsPanel(asset, positions) {
	// for listing an asset's positions in either live or history mode
	// in live mode, we're collecting positions as they arrive
}
