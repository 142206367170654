import $ from "jquery";
import _ from "lodash";

/*global JsSearch */
// import JsSearch from '../js-search.js';

/// TODO: convert into an ES5 `class`
var FilteredList = function (items, container, renderer, options) {
	this.renderer = renderer;
	this.initialized = false;

	this.options = {
		paging: { currentPage: 1, pageSize: 20 },
		sorting: {
			sortBy: null,
			sortDirection: null,
		},
		filtering: {
			search: "",
		},
		groupBy: null,
	};

	this.domNodes = {
		listContainer: container,
		listItemsContainer: undefined,
		list: undefined,
		listItems: [],
		pagination: [],
		noItems: undefined,
		filterResults: undefined,
		searchBox: undefined,
		filterContainer: undefined,
	};

	this.data = {
		items: [],
	};

	var self = this;

	function createDataIndex() {
		self.search = new JsSearch.Search("Id");
		self.search.searchIndex = new JsSearch.UnorderedSearchIndex();
		self.search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
		self.search.addIndex("EventName");
		self.search.addIndex("AssetName");
		self.search.addIndex("Time");
		self.search.addIndex("Details");
		self.search.addIndex(["Position", "Time"]);
		self.search.addIndex(["Position", "LatLng"]);
		self.search.addIndex(["Position", "Address"]);
		self.search.addIndex("Method");
		self.search.addIndex("Status");
		self.search.addIndex("EventTypes");
		self.search.addIndex("Text");
		self.search.addDocuments(self.data.items);
	}

	function init(items, options) {
		$.extend(true, self.options, options);
		self.initialized = true;
		self.data.items = items;
		createDataIndex();

		var container = self.domNodes.listContainer;
		self.domNodes.pagination = container.querySelectorAll(".pagination");
		self.domNodes.noItems = container.querySelector(".list-none");
		self.domNodes.listItemsContainer = container.querySelector(".list-container");
		self.domNodes.list = container.querySelector(".list");
		self.domNodes.filterResults = container.querySelector(".filter-results");
		self.domNodes.filterContainer = container.querySelector(".filter-box");

		self.domNodes.searchBox = container.querySelector(".list-filter");

		if (!self.domNodes.filterContainer.classList.contains("disabled-feature")) {
			self.domNodes.filterContainer.classList.add("is-visible");
		} else {
			self.domNodes.filterContainer.classList.remove("is-visible");
		}

		var $container = $(container);
		$container.on("click", ".page-link", function (e) {
			e.preventDefault();

			self.changePage(this.getAttribute("data-page"));
		});

		$(container).on("click", ".panel-options-list a.dropdown-item", function (e) {
			e.preventDefault();
			switch (this.getAttribute("data-option-type")) {
				//case 'sorting':
				//    changeItemSort(this.getAttribute('data-sort-group'), parseInt(this.getAttribute('data-sort')));
				//    break;
				case "list-sort":
					// for asset notifications, since the sorting options are outside of the list containers
					var sortBy = this.getAttribute("data-sort");
					var isActive = this.classList.contains("active");
					var sortDirection = this.classList.contains("desc") ? "asc" : "desc"; // toggle sort direction if active
					this.classList.remove("desc");
					this.classList.remove("asc");
					this.classList.add(sortDirection);
					this.classList.add("active");
					// todo: toggle other active options in this group (when we have more than 1 option!)
					if (!isActive) {
						sortDirection = "asc";
					}
					this.setAttribute("data-sort-dir", sortDirection);
					self.sort(sortBy, sortDirection);
					break;
			}
		});

		if (self.domNodes.searchBox !== undefined && self.domNodes.searchBox !== null) {
			$(self.domNodes.searchBox).on("keyup", function (e) {
				var target = e.target || e.srcElement;
				self.options.filtering.search = target.value;
				self.redraw();
			});
		}

		self.redraw();
	}

	function isFilteringData() {
		var filtering = self.options.filtering;
		var isFiltering = false;
		if (filtering !== undefined && filtering !== null) {
			// are we applying a filter?
			// apply any pre-search filters first

			if (filtering.search !== undefined && filtering.search !== null && filtering.search !== "") {
				// apply filtering.search to filteredItems
				isFiltering = true;
			}
		}
		return isFiltering;
	}

	function filterData() {
		var filteredItems = self.data.items.slice(0); // work off a copy
		var filtering = self.options.filtering;

		// filter
		var isFiltering = isFilteringData();
		if (filtering !== undefined && filtering !== null) {
			// are we applying a filter?
			// apply any pre-search filters first

			if (filtering.search !== undefined && filtering.search !== null && filtering.search !== "") {
				// apply filtering.search to filteredItems
				filteredItems = self.search.search(filtering.search);
			}
		}
		self.data.filtered = filteredItems;

		// group items
		if (self.options.groupBy !== undefined && self.options.groupBy !== null) {
			// TODO generic grouping somehow
			var filteredGroups = [];
			var filteredGroupLookup = {};
			_.each(self.data.filtered, function (item) {
				if (filteredGroupLookup[item[self.options.groupBy]] === undefined) {
					filteredGroupLookup[item[self.options.groupBy]] = { Epoch: item.Epoch, AssetId: item.AssetId, Items: [] };
					filteredGroupLookup[item[self.options.groupBy]][self.options.groupBy] = item[self.options.groupBy];
					filteredGroups.push(filteredGroupLookup[item[self.options.groupBy]]);
				}
				filteredGroupLookup[item[self.options.groupBy]].Items.push(item);
			});
			self.data.filtered = filteredGroups;
		}

		if (isFiltering) {
			self.domNodes.filterResults.classList.add("is-visible");
			self.domNodes.filterResults.querySelector("span").textContent =
				self.data.filtered.length + "/" + self.data.items.length;
		} else {
			self.domNodes.filterResults.classList.remove("is-visible");
		}
	}

	function sortData() {
		var sorting = self.options.sorting;
		if (sorting !== undefined && sorting !== null) {
			var sortBy = sorting.sortBy;
			var sortDirection = sorting.sortDirection;

			if (sortBy === undefined || sortBy === null) {
				var activeSort = self.domNodes.listContainer.querySelector(".sort-options.active");
				if (activeSort !== null) {
					sortBy = activeSort.getAttribute("data-sort");
					sortDirection = activeSort.getAttribute("data-sort-dir");
				}
			}

			if (sortBy !== undefined && sortBy !== null && sortBy !== "") {
				// will likely have to replace this with specific sorting functions
				// at some point to support multi-sort (e.g. Asset Name asc, Epoch desc)
				self.data.filtered = _.sortBy(self.data.filtered, sortBy);
				if (sortDirection === "desc") {
					self.data.filtered = self.data.filtered.reverse();
				}
			}
			sorting.sortBy = sortBy;
			sorting.sortDirection = sortDirection;
		}
	}

	function pageData() {
		var paging = self.options.paging;

		// page
		var currentPage = 1;
		var pageSize = 20;
		if (paging !== undefined && paging !== null) {
			// paging.currentPage?
			if (paging.currentPage !== undefined) {
				currentPage = paging.currentPage;
			}
			// paging.pageSize
			if (paging.pageSize !== undefined) {
				pageSize = paging.pageSize;
			}
		}
		var filteredItemCount = self.data.filtered.length;

		// apply paging
		var totalPages = Math.ceil(filteredItemCount / pageSize);
		if (totalPages === 0) {
			totalPages = 1;
		}
		if (currentPage < 1) {
			currentPage = 1;
		} else if (currentPage > totalPages) {
			currentPage = totalPages;
		}
		paging.currentPage = currentPage;
		paging.totalPages = totalPages;
		var startIndex = pageSize * (currentPage - 1);
		var endIndex = startIndex + pageSize - 1;
		if (endIndex > filteredItemCount - 1) {
			endIndex = filteredItemCount - 1;
		}
		var currentItems = endIndex - startIndex;
		var visibleItems = self.data.filtered.slice(startIndex, endIndex + 1);
		self.data.visible = visibleItems;
		self.data.visibleStartIndex = startIndex;
		self.data.visibleEndIndex = endIndex;
	}

	function renderVisibleItems() {
		if (self.renderer === undefined) {
			console.error("No renderer defined for FilteredList.");
			return;
		}

		// remove existing items from list
		var listNode = self.domNodes.list;
		while (listNode.firstChild) {
			listNode.removeChild(listNode.firstChild);
		}

		// render paged items
		var listNodes = [];
		var fragmentNode = document.createDocumentFragment();
		var renderIndex = self.data.visibleStartIndex;
		_.each(self.data.visible, function (item) {
			// render list item
			var li = self.renderer(item, renderIndex);
			if (li !== undefined) {
				listNodes.push(li);
				fragmentNode.appendChild(li);
				renderIndex++;
			} else {
				console.warn("Could not render list item:");
				console.warn(item);
			}
		});
		self.domNodes.listItems = listNodes;

		self.domNodes.list.appendChild(fragmentNode);

		// check for pagination nodes
		if (self.domNodes.pagination.length > 0) {
			var showPagination = self.data.filtered.length > self.options.paging.pageSize;
			var pageNodeFragment = createPageNodes();
			for (var i = self.domNodes.pagination.length - 1; i >= 0; i--) {
				var paginationContainer = self.domNodes.pagination[i];
				if (showPagination) {
					paginationContainer.classList.add("is-visible");
				} else {
					paginationContainer.classList.remove("is-visible");
				}

				// empty existing pages
				while (paginationContainer.firstChild) {
					paginationContainer.removeChild(paginationContainer.firstChild);
				}

				var pageNodes = pageNodeFragment;
				if (i > 0) {
					pageNodes = pageNodes.cloneNode(true);
				}
				paginationContainer.appendChild(pageNodes);
			}
		}

		// if no filtered items are visible, hide the list if we're not searching for anything
		if (self.data.filtered.length > 0) {
			self.domNodes.noItems.classList.remove("is-visible");
			self.domNodes.listItemsContainer.classList.add("is-visible");
			if (!self.domNodes.filterContainer.classList.contains("disabled-feature")) {
				self.domNodes.filterContainer.classList.add("is-visible");
			}
		} else {
			self.domNodes.noItems.classList.add("is-visible");
			self.domNodes.listItemsContainer.classList.remove("is-visible");
			if (!isFilteringData()) {
				self.domNodes.filterContainer.classList.remove("is-visible");
			}
		}

		if (self.options.renderCallback !== undefined) {
			self.options.renderCallback(self);
		}
	}

	function createPageNodes() {
		// add paging
		var pages = document.createDocumentFragment();

		var paging = self.options.paging;

		// paging prev,1,2,3 ... last,next
		var hasPrev = paging.currentPage > 1;
		var hasNext = paging.currentPage < paging.totalPages;
		var pagePrev = document.createElement("li");
		pagePrev.className = "page-item" + (hasPrev ? "" : " disabled");
		var pagePrevLink = document.createElement("a");
		pagePrevLink.className = "page page-link";
		//pagePrevLink.textContent = strings.POSITION_PREV;
		pagePrevLink.href = "#";
		pagePrevLink.setAttribute("data-page", paging.currentPage - 1);
		var pagePrevIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		var pagePrevIconContents = document.createElementNS("http://www.w3.org/2000/svg", "use");
		pagePrevIconContents.setAttributeNS(
			"http://www.w3.org/1999/xlink",
			"href",
			"/content/svg/tracking.svg?v=15#caret-left-solid"
		);
		pagePrevIcon.appendChild(pagePrevIconContents);
		pagePrevLink.appendChild(pagePrevIcon);
		pagePrev.appendChild(pagePrevLink);
		pages.appendChild(pagePrev);

		// always have first and last page
		var windowSize = 2;
		var startPage = paging.currentPage - windowSize;
		var endPage = paging.currentPage + windowSize;
		var addedFirstPage = false;
		var addedLastPage = false;
		if (startPage <= 1) {
			startPage = 1;
			addedFirstPage = true;
		}
		if (endPage >= paging.totalPages) {
			endPage = paging.totalPages;
			addedLastPage = true;
		}
		var morePagesBeforeFirst = startPage > 2;
		var morePagesBeforeLast = paging.totalPages - endPage > 1;
		if (!addedFirstPage) {
			// add first and ellipsis
			var pageItem = document.createElement("li");
			pageItem.className = "page-item" + (1 === paging.currentPage ? " active" : "");
			var pageItemLink = document.createElement("a");
			pageItemLink.className = "page page-link";
			pageItemLink.textContent = "1";
			pageItemLink.href = "#";
			pageItemLink.setAttribute("data-page", 1);
			pageItem.appendChild(pageItemLink);
			pages.appendChild(pageItem);

			if (morePagesBeforeFirst) {
				var pageItem = document.createElement("li");
				pageItem.className = "page-item disabled";
				var pageItemLink = document.createElement("a");
				pageItemLink.className = "page page-link";
				pageItemLink.textContent = "...";
				pageItemLink.href = "#";
				pageItem.appendChild(pageItemLink);
				pages.appendChild(pageItem);
			}
		}

		for (var i = startPage; i <= endPage; i++) {
			var pageItem = document.createElement("li");
			pageItem.className = "page-item" + (i === paging.currentPage ? " active" : "");
			var pageItemLink = document.createElement("a");
			pageItemLink.className = "page page-link";
			pageItemLink.textContent = i;
			pageItemLink.href = "#";
			pageItemLink.setAttribute("data-page", i);
			pageItem.appendChild(pageItemLink);
			pages.appendChild(pageItem);
		}

		if (!addedLastPage) {
			// add last and ellipsis
			if (morePagesBeforeLast) {
				var pageItem = document.createElement("li");
				pageItem.className = "page-item disabled";
				var pageItemLink = document.createElement("a");
				pageItemLink.className = "page page-link";
				pageItemLink.textContent = "...";
				pageItemLink.href = "#";
				pageItem.appendChild(pageItemLink);
				pages.appendChild(pageItem);
			}

			var pageItem = document.createElement("li");
			pageItem.className = "page-item" + (paging.totalPages === paging.currentPage ? " active" : "");
			var pageItemLink = document.createElement("a");
			pageItemLink.className = "page page-link";
			pageItemLink.textContent = paging.totalPages;
			pageItemLink.href = "#";
			pageItemLink.setAttribute("data-page", paging.totalPages);
			pageItem.appendChild(pageItemLink);
			pages.appendChild(pageItem);
		}

		var pageNext = document.createElement("li");
		pageNext.className = "page-item" + (hasNext ? "" : " disabled");
		var pageNextLink = document.createElement("a");
		pageNextLink.className = "page page-link";
		//pageNextLink.textContent = strings.POSITION_NEXT;
		pageNextLink.href = "#";
		pageNextLink.setAttribute("data-page", paging.currentPage + 1);
		var pageNextIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		var pageNextIconContents = document.createElementNS("http://www.w3.org/2000/svg", "use");
		pageNextIconContents.setAttributeNS(
			"http://www.w3.org/1999/xlink",
			"href",
			"/content/svg/tracking.svg?v=15#caret-right-solid"
		);
		pageNextIcon.appendChild(pageNextIconContents);
		pageNextLink.appendChild(pageNextIcon);
		pageNext.appendChild(pageNextLink);
		pages.appendChild(pageNext);

		return pages;
	}

	this.reindex = createDataIndex;

	this.addData = function (items) {
		if (self.search !== undefined && self.search !== null) {
			self.search.addDocuments(items);
		}
		self.data.items = self.data.items.concat(items);
		self.redraw();
	};

	// TODO is there any benefit to knowing that we've modified data instead of replaced all?
	// I think just set list.data

	this.redraw = function () {
		filterData();
		sortData();
		pageData();
		renderVisibleItems();
	};

	this.sort = function (sortBy, sortDirection) {
		if (self.options.sorting === undefined) {
			self.options.sorting = { sortBy: sortBy, sortDirection: sortDirection };
		} else {
			self.options.sorting.sortBy = sortBy;
			self.options.sorting.sortDirection = sortDirection;
		}

		sortData();
		pageData();
		renderVisibleItems();
	};

	this.changePage = function (pageNum) {
		var toPage = parseInt(pageNum);
		if (toPage === self.options.paging.currentPage) {
			return;
		}

		self.options.paging.currentPage = parseInt(pageNum);
		pageData();

		// TODO paging should simply update and not be redrawn
		renderVisibleItems();
	};

	//this.render = function () { // used?
	//    renderVisibleItems();
	//};

	init(items, options);
};

export default FilteredList;
