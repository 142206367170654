import trkData from "./data.js";
import state from "./state.js";
import strings from "./strings.js";
import { viewModes, mapModes } from "./const.js";
import user from "./user.js";
import { clearBounds, setMapBounds } from "./map-bounds.js";
import domNodes from "./domNodes.js";
import { wrapUrl } from "./wrapurl.js";
import { handleWebServiceError } from "./ajax.js";
import { resizeApp } from "./window-layout.js";
import { updateSecondaryPanelNotificationContentForNewMode } from "./panel.js";
import { toggleLoadingMessage } from "./ajax.js";
import { createHistoryPositionResults, processAssetHistoryPositionsResult } from "./position-history.js";
import { addAssetEvents } from "./asset-events.js";
import { showResultLimitsIfApplicable } from "./item-listing.js";
import { getLiveAssetsShown } from "./asset-live.js";
import { updateMapModeDateRange } from "./map-ui.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import { findAssetById } from "./assets.js";
import options from "./options.js";
import { highlightPosition } from "./positions.js";
import { handleLimitedDataResult } from "./limited-data.js";
import { updateLatestPositionTimeForSharedView } from "./asset-positions.js";

import _ from "lodash";
import moment from "moment"; // https://www.npmjs.com/package/moment

// Returns a the post data to be sent to the GetAssetPositionsForDateRange
// endpoint. Parses the date range and formats it into the structure expected
// by the endpoint.
export function getPostDataForAssetPositions(sensitivity, isPredefinedDateRange, loadLimitedData, dateFilter) {
	sensitivity = typeof sensitivity !== "undefined" ? sensitivity : null;
	isPredefinedDateRange = typeof isPredefinedDateRange !== "undefined" ? isPredefinedDateRange : false;

	// reset bounds
	clearBounds();

	var fromDate = null;
	var toDate = null;
	var fromDateUtc = null;
	var toDateUtc = null;
	var fromDateEpoch = null;
	var toDateEpoch = null;
	var fromDateRaw = document.getElementById("txtDateFrom").value;
	var toDateRaw = document.getElementById("txtDateTo").value;

	// override shared view's date ranges to show data that otherwise would exceed display limits
	if (dateFilter !== undefined && dateFilter !== null) {
		fromDateEpoch = dateFilter.fromEpoch;
		toDateEpoch = dateFilter.toEpoch;
	} else {
		trkData.history.limitedData = null;
		fromDateEpoch = moment(fromDateRaw, user.dateWithStandardTimeFormat);
		if (fromDateEpoch.isValid()) {
			fromDateEpoch = moment.utc(fromDateEpoch.valueOf() + user.tickOffset).valueOf(); // TODO proper timezone conversion support
		} else {
			fromDateEpoch = null;
		}
		toDateEpoch = moment(toDateRaw, user.dateWithStandardTimeFormat);
		if (toDateEpoch.isValid()) {
			toDateEpoch = moment.utc(toDateEpoch.valueOf() + user.tickOffset).valueOf(); // TODO proper timezone conversion support
		} else {
			toDateEpoch = null;
		}
	}

	var fromDateFull = null;
	if (fromDateEpoch !== undefined && fromDateEpoch !== null) {
		fromDate = moment(fromDateEpoch - user.tickOffset).format(user.dateWithStandardTimeFormat);
		fromDateUtc = moment.utc(fromDateEpoch).format(user.dateWithStandardTimeFormat);
		fromDateFull = moment(fromDateEpoch - user.tickOffset).format(user.dateFormat);
	}
	if (toDateEpoch !== undefined && toDateEpoch !== null) {
		toDate = moment(toDateEpoch - user.tickOffset).format(user.dateWithStandardTimeFormat);
		toDateUtc = moment.utc(toDateEpoch).format(user.dateWithStandardTimeFormat);
	}

	trkData.history.fromDate = fromDate;
	trkData.history.fromDateFull = fromDateFull;
	trkData.history.toDate = toDate;
	trkData.history.fromDateUtc = fromDateUtc;
	trkData.history.toDateUtc = toDateUtc;
	trkData.history.fromDateEpoch = fromDateEpoch;
	trkData.history.toDateEpoch = toDateEpoch;

	var assetIds = getVisibleAssetIds().join(",");
	if (assetIds == "") {
		console.log("No assets selected!");
		//populateEvents([]);
		createHistoryPositionResults();
		return;
	}

	if (loadLimitedData === undefined || loadLimitedData === null) {
		loadLimitedData = false;
	}

	return {
		fromDate: fromDate,
		toDate: toDate,
		assetIds: assetIds,
		zoomLevel: null,
		sensitivity: sensitivity,
		format: user.dateFormat,
		lang: user.dateCulture,
		loadLimitedData: loadLimitedData,
		isMobile: state.isMobile,
		fromDateRaw: fromDateRaw,
		toDateRaw: toDateRaw,
		tickOffset: user.tickOffset,
		formatDateTime: user.dateWithStandardTimeFormat,
	};
}

// Queries historic positions for active assets (not live positions)
export async function queryActiveAssets(sensitivity, isPredefinedDateRange, loadLimitedData, dateFilter) {

	// disable show button and show loading message
	domNodes.mapMode.show.disabled = true;
	toggleLoadingMessage(true, "assetPositions");
	// todo: start data loading message here

	try {
		const postData = getPostDataForAssetPositions(sensitivity, isPredefinedDateRange, loadLimitedData, dateFilter);
		const req = await fetch(
			wrapUrl("/services/GPSService.asmx/GetAssetPositionsForDateRange"),
			{
				method: "POST",
				body: JSON.stringify(postData),
				headers: new Headers({
					"Content-Type": "application/json; charset=utf-8",
				}),
			}
		);

		const msg = await req.json();

		state.hasQueriedHistory = true;
		domNodes.mapMode.show.disabled = false;
		toggleLoadingMessage(false, "assetPositions");

		// if we've switched out of history mode before the query completed, discard the results
		if (state.activeMapMode === mapModes.LIVE) {
			return;
		}
		if (msg.d) {
			var result = msg.d;

			// if it's a limited result that has errored out, don't clear the current view, discard the results
			if (result.IsLimited === true && result.IncludesLimitedData === false) {
				showResultLimitsIfApplicable(result, false);
				return;
			}

			clearHistoryFromMap();
			trkData.history.isLimited = result.IsLimited;
			trkData.history.isLoadedLimitedData = result.IncludesLimitedData;
			trkData.history.isDateFiltered = !!dateFilter;

			trkData.history.positions = [];
			trkData.history.normalizedPositions = [];
			trkData.history.events = [];
			trkData.history.normalizedEvents = [];
			trkData.history.normalizedEventIds = {};
			trkData.history.normalizedEventsByAssetId = {};
			trkData.history.positionsByAssetId = {};
			trkData.history.normalizedPositionsByAssetId = {};
			trkData.history.messageCounts = [];
			trkData.history.messageCountsByAssetId = {};
			trkData.history.messages = [];
			trkData.history.messagesByAssetId = {};
			trkData.history.normalizedMessages = [];
			trkData.history.normalizedMessagesByAssetId = {};
			trkData.history.assetIdsWithResults = {};
			result.Assets.forEach(function (assetResult) {
				// also handles message counts for some reason
				processAssetHistoryPositionsResult(assetResult);
			});

			if (result.Events !== null) {
				addAssetEvents(result.Events, mapModes.HISTORY);
			}

			createHistoryPositionResults();
			// handle result.Events - populate events data table #event-panel, .panel-content
			//populateEvents(trkData.history.events); // todo: this will be dupe called
			//populateEvents(result.Events);

			// are we loading a specific position
			// hack: using setTimeout always feels like a hack
			// as we are waiting for the map to render or resize?
			// it should be determined what specifically prevents the highlight position
			// so we can add an event trigger on that specific event instead
			// of having a general timeout which may be too short or too long
			setTimeout(highlightInitialPosition, 500);
			resizeApp(true);
			setMapBounds();
			updateLatestPositionTimeForSharedView();
			updateSecondaryPanelNotificationContentForNewMode();

			showResultLimitsIfApplicable(result, !!dateFilter);
			//domNodes.mapMode.from.textContent = fromDate;
			//if (toDate === '') {
			//    domNodes.mapMode.to.textContent = moment().subtract(user.tickOffset, 'ms').format(user.dateFormat.substring(0, user.dateFormat.indexOf(' ')) + ' HH:mm');
			//} else {
			//    domNodes.mapMode.to.textContent = toDate;
			//}

			if (!isPredefinedDateRange) {
				// TODO this is a mess - move to proper spot
				var historyCustom = document.getElementById("history-custom");
				var historyButtons = document.getElementById("filter-history-range").querySelectorAll("button");
				_.each(historyButtons, function (button) {
					button.classList.remove("active");
				});
				historyCustom.classList.add("active");
				historyCustom.classList.add("btn-primary");
			}

			if (
				(trkData.history.isLimited && trkData.history.isLoadedLimitedData) ||
				trkData.history.limitedData !== null
			) {
				handleLimitedDataResult(viewModes.NORMAL, trkData.history.fromDateUtc, trkData.history.toDateUtc, result.Counts, result.Limit);
			} else {
				trkData.history.limitedData = null;
			}
			updateActiveAssetInformation(viewModes.NORMAL);
			updateMapModeDateRange();
		}
	} catch (ex) {
		handleWebServiceError(strings.MSG_ASSET_QUERY_ERROR);
		// re-enable show button and clear loading message
		domNodes.mapMode.show.disabled = false;
		toggleLoadingMessage(false, "assetPositions");
	};
}

export function updateActiveAssetInformation(viewMode) {
	viewMode = viewMode !== undefined ? viewMode : viewModes.NORMAL;
	// todo: if trips are visible, how do they interact with the asset information?
	if (viewMode !== state.activeViewMode) {
		return;
	}
	if (viewMode === viewModes.NORMAL) {
		if (state.activeMapMode === mapModes.LIVE) {
			getLiveAssetsShown();
		} else {
			getHistoryAssetsAndPositionsShown(viewMode);
		}
	} else if (viewMode === viewModes.SHARED_VIEW) {
		getHistoryAssetsAndPositionsShown(viewMode);
	}
}

function getVisibleAssetIds(viewMode) {
	viewMode = viewMode !== undefined ? viewMode : viewModes.NORMAL;
	if (viewMode === viewModes.NORMAL) {
		return trkData.visible.assets;
	} else if (viewMode === viewModes.SHARED_VIEW) {
		if (trkData.sharedView.temp !== null) {
			return trkData.sharedView.temp.AssetIds;
		} else if (trkData.sharedView.current !== null) {
			return trkData.sharedView.current.AssetIds;
		} else {
			return [];
		}
	}
}

function getHistoryAssetsAndPositionsShown(viewMode) {
	viewMode = viewMode !== undefined ? viewMode : viewModes.NORMAL;
	var visibleAssetIds = getVisibleAssetIds(viewMode);
	var visiblePositions = 0;
	var dataSource = viewMode === viewModes.SHARED_VIEW ? trkData.sharedView : trkData.history;
	var visibleAssetHistory = _.filter(dataSource.normalizedPositionsByAssetId, function (item, id) {
		return _.includes(visibleAssetIds, parseInt(id));
	});
	//var visibleAssetHistory = _.filter(dataSource.normalizedPositions, function (assetPositions, index, list) {
	//    return _.includes(visibleAssetIds, assetPositions.Id);
	//});

	var visibleAssetsWithPositions = visibleAssetHistory.length;
	_.each(visibleAssetHistory, function (assetHistory) {
		//visiblePositions += assetHistory.Positions.length;
		visiblePositions += assetHistory.length;
	});

	document.getElementById("live-noresults").classList.remove("is-visible");
	var noResults = document.getElementById("history-noresults");
	if (visibleAssetsWithPositions > 0 && visiblePositions > 0) {
		var msg_assetsPositions = strings.MSG_ASSETS_AND_POSITIONS;
		msg_assetsPositions = msg_assetsPositions.replace("{0}", visibleAssetsWithPositions); // num assets
		msg_assetsPositions = msg_assetsPositions.replace("{2}", visibleAssetsWithPositions > 1 ? "s" : ""); // assets plural
		msg_assetsPositions = msg_assetsPositions.replace("{1}", visiblePositions); // num positions
		msg_assetsPositions = msg_assetsPositions.replace("{3}", visiblePositions > 1 ? "s" : ""); // positions plural
		domNodes.mapTools.visibleSummary.textContent = msg_assetsPositions;

		domNodes.mapMode.visibleAssetsHistory.textContent = visibleAssetsWithPositions;
		var visibleText = visiblePositions;
		if (dataSource.limitedData !== null) {
			visibleText += " / " + dataSource.limitedData.counts.Positions;
		}
		domNodes.mapMode.visiblePositionsHistory.textContent = visibleText;

		noResults.classList.remove("is-visible");
	} else {
		domNodes.mapTools.visibleSummary.textContent = "";
		domNodes.mapMode.visibleAssetsHistory.textContent = strings.GROUP_NONE;
		domNodes.mapMode.visiblePositionsHistory.textContent = strings.GROUP_NONE;
		if (state.hasQueriedHistory) {
			noResults.classList.add("is-visible");
		}
	}
}

function clearHistoryFromMap() {
	// clear the prior history before running a new one
	//var markers = _.union(trkData.live.markers, trkData.history.markers);
	//var markers = trkData.live.markers.concat(trkData.history.markers);
	var markers = [];
	Array.prototype.push.apply(markers, trkData.live.markers);
	Array.prototype.push.apply(markers, trkData.history.markers);

	_.each(markers, function (marker) {
		removeItemFromMap(marker);
	});

	_.each(trkData.history.markers, function (marker) {
		removeItemFromMap(marker);
	});
	trkData.history.markers = [];
	trkData.history.markersByAssetId = {};
	trkData.history.markersByPositionId = {};

	// clear lines connecting positions
	_.each(trkData.history.positions, function (position) {
		var prAsset = findAssetById(position.Id);
		if (trkData.history.mapLinesByAssetId[prAsset.Id] !== undefined) {
			// remove polyline
			removeItemFromMap(trkData.history.mapLinesByAssetId[prAsset.Id]);
			delete trkData.history.mapLinesByAssetId[prAsset.Id];
		}
	});
	trkData.history.mapLinesByAssetId = {};

	// clear marker clusters
	// _.each(trkData.assets, function (asset) {
	// 	if (trkData.history.markerClustersByAssetId[asset.Id] !== undefined) {
	// 		trkData.history.markerClustersByAssetId[asset.Id].clearLayers();
	// 		// TODO delete them as well?
	// 	}
	// });
	//trkData.history.markerClustersByAssetId = {};

	if (state.openWindow !== null) {
		state.openWindow.remove();
	}

	clearPositionResults();
}

function highlightInitialPosition() {
	if (options.showPositionId != "") {
		highlightPosition(options.showPositionId, null);
		options.showPositionId = "";
	}
}

function clearPositionResults() {
	_.each(domNodes.assets, function (assetNodes) {
		_.each(assetNodes, function (assetNode) {
			//var toggle = assetNode.querySelector('.item-toggle');
			var details = assetNode.querySelector(".item-details");
			var location = assetNode.querySelector(".locations");
			var status = assetNode.querySelectorAll(".status");
			//toggle.classList.remove('active');
			//toggle.querySelector('use').setAttributeNS('http://www.w3.org/1999/xlink', 'href', '/content/svg/tracking.svg?v=15#expand');
			details.classList.remove("is-visible");
			if (location !== null) {
				location.parentNode.removeChild(location);
			}
			_.each(status, function (item) {
				item.parentNode.removeChild(item);
			});
		});
	});
}