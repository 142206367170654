import LeafletGleo from "./leaflet.gleo/LeafletGleo.js";

// import {MultiSymbol} from './gleo/src/symbols/MultiSymbol.mjs';
import SymbolGroup from "./gleo/src/loaders/SymbolGroup.mjs";
import { setFactory } from "./gleo/src/geometry/DefaultGeometry.mjs";
import LatLng from "./gleo/src/geometry/LatLng.mjs";
import TintedSprite from "./gleo/src/symbols/TintedSprite.mjs";

// Akin to the Leaflet LayerGroups in trkData.mapLayers.normal &
//  trkData.mapLayers.sharedView
export const normalGleoSymbols = new SymbolGroup();
export const sharedViewGleoSymbols = new SymbolGroup();

// Spawns a Gleo `platina` inside an instance of a Leaflet map. Stores a reference
// to such platina in the `.platina` property of the map.
export function initGleo(leafletMap) {
	const platina = new LeafletGleo(leafletMap, {
		/* platina options */
	});

	new TintedSprite.Acetate(platina, {
		// Set a lot of extra tolerance for clicking on sprites.
		// It'd be better to have a lower extra pointer tolerance, but less
		// transparent pixels around the actual asset icons (i.e. clip the
		// 32x32 images).
		pointerTolerance: 16,

		// Disable texture linear interpolation lookup - the sprites are from small
		// images and benefit from nearest-neighbour texture lookups.
		interpolate: false
	});

	setFactory(function latLngize(coords, opts) {
		return new LatLng(coords, opts);
	});

	leafletMap.platina = platina;

	normalGleoSymbols.addTo(platina);

	return platina;
}
