import trkData from "./data.js";
import strings from "./strings.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import domNodes from "./domNodes.js";
import { openDialogPanel } from "./panel-nav.js";
import { renderDomElement } from "./datatables.js";
import { handleAjaxFormSubmission, formShowErrorMessage, formShowSuccessMessage } from "./ajax.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import { el } from "redom"; // https://redom.js.org/

export function getDescriptionForDriver(driver) {
	var description = "";
	if (driver == null) {
		return description;
	}
	if (driver.IButtonId !== undefined && driver.IButtonId !== null && driver.IButtonId !== '') {
		description = driver.IButtonId;
	}
	if (driver.GarminId !== null && driver.GarminId !== undefined) {
		if (description !== "") {
			description += " / ";
		}
		description += driver.GarminId;
	}
	return description;
}

export function addDriverToAsset(driverId, assetId) {
	if (trkData.assetDrivers == null) return;
	for (var i = 0; i < trkData.assetDrivers.length; i++) {
		if (trkData.assetDrivers[i].AssetId == assetId) {
			trkData.assetDrivers[i].DriverIds.push(driverId);
			break;
		}
	}
}

export function findAssetDriverGroupIdsByAssetId(id) {
	var item = _.find(trkData.assetDriverGroups, { AssetId: parseInt(id) });
	return item === undefined ? null : item.DriverGroupIds;
}

export function findAssetDriversByAssetId(id) {
	var item = _.find(trkData.assetDrivers, { AssetId: parseInt(id) });
	return item === undefined ? null : item.DriverIds;
}

function calculateGroupDriverIdsForAsset(assetId) {
	var assetDriverGroup = _.find(trkData.assetDriverGroups, { AssetId: parseInt(assetId) });
	if (assetDriverGroup === undefined) {
		return;
	}

	var groupDriverIds = [];
	_.each(assetDriverGroup.DriverGroupIds, function (groupId) {
		var driverGroup = _.find(trkData.driverGroups, { Id: groupId });
		if (driverGroup === undefined) {
			return;
		}
		groupDriverIds = groupDriverIds.concat(driverGroup.ItemIds);
	});

	var assetDrivers = _.find(trkData.assetDrivers, { AssetId: parseInt(assetId) });
	if (assetDrivers === undefined) {
		trkData.assetDrivers.push({ AssetId: parseInt(assetId), DriverIds: [], GroupDriverIds: groupDriverIds });
	} else {
		assetDrivers.GroupDriverIds = groupDriverIds;
	}
}

export function addDriverGroupToAsset(driverGroupId, assetId) {
	var assetDriverGroup = _.find(trkData.assetDriverGroups, { AssetId: parseInt(assetId) });
	if (assetDriverGroup === undefined) {
		trkData.assetDriverGroups.push({ AssetId: parseInt(assetId), DriverGroupIds: [driverGroupId] });
	} else {
		if (_.indexOf(assetDriverGroup.DriverGroupIds, driverGroupId) === -1) {
			assetDriverGroup.DriverGroupIds.push(driverGroupId);
		}
	}

	// recalculate GroupDriverIds for the asset
	calculateGroupDriverIdsForAsset(assetId);
}

export function removeDriverGroupFromAsset(driverGroupId, assetId) {
	var assetDriverGroup = _.find(trkData.assetDriverGroups, { AssetId: parseInt(assetId) });
	if (assetDriverGroup === undefined) {
		return;
	}
	var groupIndex = _.indexOf(assetDriverGroup.DriverGroupIds, driverGroupId);
	if (groupIndex !== -1) {
		// remove group
		assetDriverGroup.DriverGroupIds.splice(groupIndex, 1);
	}

	// recalculate GroupDriverIds for the asset based on remaining group(s) assigned
	calculateGroupDriverIdsForAsset(assetId);
}

export function removeDriverFromAsset(driverId, assetId) {
	if (trkData.assetDrivers == null) return;
	for (var i = 0; i < trkData.assetDrivers.length; i++) {
		if (trkData.assetDrivers[i].AssetId == assetId) {
			for (var j = 0; j < trkData.assetDrivers[i].DriverIds.length; j++) {
				if (driverId == trkData.assetDrivers[i].DriverIds[j]) {
					trkData.assetDrivers[i].DriverIds.splice(j, 1);
					break;
				}
			}
			break;
		}
	}
}

export function findDriverById(id) {
	var item = _.find(trkData.drivers, { Id: parseInt(id) });
	return item === undefined ? null : item;
}

export function findAssetDriverIdsByAssetIdSorted(id) {
	// trkData.drivers is sorted
	if (trkData.assetDrivers == null) {
		return null;
	}
	var ids = null;
	var assetDrivers = _.find(trkData.assetDrivers, { AssetId: parseInt(id) });
	if (assetDrivers !== undefined) {
		ids = assetDrivers.DriverIds.concat(assetDrivers.GroupDriverIds);
	}
	if (ids != null) {
		var sortedIds = [];
		for (var i = 0, len = trkData.drivers.length; i < len; i++) {
			var driverId = trkData.drivers[i].Id;
			for (var j = 0; j < ids.length; j++) {
				var includedId = ids[j];
				if (driverId == includedId) {
					sortedIds.push(includedId);
					break;
				}
			}
		}
		return sortedIds;
	}

	return null;
}

export function updateAssetDriverStatus(asset, driverId, statusId, btn, notes) {
	var status = document.getElementById("current-drivers-status");
	var data = { assetId: asset.Id, driverId: driverId, statusId: statusId, notes: notes };
	handleAjaxFormSubmission(
		"AssetDriverUpdateStatus",
		data,
		btn,
		status,
		strings.MSG_UPDATE_DRIVER_SUCCESS,
		strings.MSG_UPDATE_DRIVER_ERROR,
		function () {
			loadAssetDrivers(asset);
		}
	);

	//   if(btn != null)
	//    btn.prop('disabled', true);
	//toggleLoadingMessage(true, 'asset-driver-update');
	//$j.ajax({
	//    type: 'POST',
	//    url: wrapUrl('/services/GPSService.asmx/AssetDriverUpdateStatus'),
	//    data: JSON.stringify(data),
	//    contentType: 'application/json; charset=utf-8',
	//    dataType: 'json',
	//    success: function (msg) {
	//        if (btn != null)
	//            btn.prop('disabled', false);
	//        if (msg.d) {
	//            var result = msg.d;
	//            if (result.Success == true) {
	//                loadAssetDrivers(asset);
	//                   trkData.validation.currentDriver.resetForm();
	//                   trkData.validation.currentDriver.currentForm.reset();
	//            } else {
	//                handleWebServiceError(strings.MSG_UPDATE_DRIVER_ERROR);
	//            }
	//        }
	//        toggleLoadingMessage(false, 'asset-driver-update');
	//    },
	//    error: function (xhr, status, error) {
	//        handleWebServiceError(strings.MSG_UPDATE_DRIVER_ERROR);
	//        toggleLoadingMessage(false, 'asset-driver-update');
	//        if (btn != null)
	//            btn.prop('disabled', false);
	//    }
	//});
}

export function loginAssetDriver(asset, driverId, notes, btn, statusId) {
	var status = document.getElementById("current-drivers-status");
	var data = { assetId: asset.Id, driverId: driverId, notes: notes, statusId: statusId };
	handleAjaxFormSubmission(
		"AssetDriverLogin",
		data,
		btn,
		status,
		strings.MSG_LOGIN_DRIVER_SUCCESS,
		strings.MSG_LOGIN_DRIVER_ERROR,
		function () {
			trkData.validation.currentDriver.resetForm();
			trkData.validation.currentDriver.currentForm.reset();

			loadAssetDrivers(asset);
		}
	);

	//var btn = $j('#LoginDriver')[0];

	//   btn.disabled = true;
	//toggleLoadingMessage(true, 'asset-driver-login');
	//$j.ajax({
	//    type: 'POST',
	//    url: wrapUrl('/services/GPSService.asmx/AssetDriverLogin'),
	//    data: JSON.stringify(data),
	//    contentType: 'application/json; charset=utf-8',
	//    dataType: 'json',
	//    success: function (msg) {
	//           btn.disabled = false;
	//        if (msg.d) {
	//            var result = msg.d;
	//            if (result.Success == true) {
	//                loadAssetDrivers(asset);
	//            } else {
	//                handleWebServiceError(strings.MSG_LOGIN_DRIVER_ERROR);
	//            }
	//        }
	//        toggleLoadingMessage(false, 'asset-driver-login');
	//    },
	//    error: function (xhr, status, error) {
	//        handleWebServiceError(strings.MSG_LOGIN_DRIVER_ERROR);
	//        toggleLoadingMessage(false, 'asset-driver-login');
	//           btn.disabled = false;
	//    }
	//});
}

export function loadAssetDrivers(asset) {
	var btn = $j("#RefreshAssetDrivers");
	var data = { assetId: asset.Id };
	btn.prop("disabled", true);
	toggleLoadingMessage(true, "asset-drivers");
	$j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetAssetDriverInformation"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			btn.prop("disabled", false);
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					var statuses = $j("#AssetStatuses").empty();
					statuses.append($j("<option>"));
					var logoffStatus = $j("<option>").val(-1).text(strings.LOGOUT);
					var loginStatus = $j("<option>").val(0).text(strings.LOGIN);

					if (result.Statuses != null) {
						// statuses defined for this asset
						for (var i = 0; i < result.Statuses.length; i++) {
							var status = result.Statuses[i];
							if (status.IsLogOff) logoffStatus = $j("<option>").val(-1).text(status.Status);
							else statuses.append($j("<option>").val(status.Id).text(status.Status));
						}
					}
					statuses.append(logoffStatus);

					// clear current drivers listing
					var drivers = $j("#AssetDrivers tbody").empty();
					var driverIds = [];
					if (result.Drivers != null) {
						for (var i = 0; i < result.Drivers.length; i++) {
							var driver = result.Drivers[i];
							var status = driver.Status;
							let statusId;
							if (status == null) {
								statusId = "";
							} else {
								statusId = status.Id;
							}
							var statusOptions = statuses
								.clone(false)
								.removeAttr("id")
								.val(statusId)
								.data("driverId", driver.Driver.Id)
								.show();
							var driverDescription = getDescriptionForDriver(driver.Driver);
							if (driverDescription !== "") {
								driverDescription = " (" + driverDescription + ")";
							}
							drivers.append(
								$j("<tr>")
									.append($j("<td>").text(driver.Driver.DriverId + driverDescription))
									.append($j("<td>").append(statusOptions))
									.append(
										$j("<td>").append(
											$j("<button>").addClass("update-driver btn btn-primary").text(strings.UPDATE_STATUS)
										)
									)
							);
							driverIds.push(driver.Driver.Id);
						}
					}

					var login = $j("#LoginDrivers").empty();
					var assetDrivers = findAssetDriverIdsByAssetIdSorted(asset.Id);

					if (assetDrivers != null) {
						for (var i = 0; i < assetDrivers.length; i++) {
							var driverId = assetDrivers[i];
							if ($j.inArray(driverId, driverIds) == -1) {
								var driver = findDriverById(driverId);
								if (driver == null) continue;
								var driverDescription = getDescriptionForDriver(driver);
								if (driverDescription !== "") {
									driverDescription = " (" + driverDescription + ")";
								}
								login.append(
									$j("<option>")
										.val(driverId)
										.text(driver.DriverId + driverDescription)
								);
							}
						}
					}

					var stats = $j("#LoginDriversStatus").empty();
					if (result.Statuses == null || result.Statuses.length == 0) {
						stats.append(loginStatus);
					} else {
						// statuses defined for this asset
						for (var i = 0; i < result.Statuses.length; i++) {
							var status = result.Statuses[i];
							if (!status.IsLogOff) stats.append($j("<option>").val(status.Id).text(status.Status));
						}
					}
				} else {
					handleWebServiceError(strings.MSG_GET_DRIVERS_ERROR);
				}
			}
			toggleLoadingMessage(false, "asset-drivers");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_GET_DRIVERS_ERROR);
			toggleLoadingMessage(false, "asset-drivers");
			btn.prop("disabled", false);
		},
	});
}

export function openCurrentDriversDialog(asset) {
	trkData.validation.currentDriver.resetForm();
	trkData.validation.currentDriver.currentForm.reset();

	trkData.validation.addPosition.resetForm();
	trkData.validation.addPosition.currentForm.reset();
	openDialogPanel(
		domNodes.dialogs.currentDrivers,
		strings.SET_DRIVER_STATUS,
		asset,
		false,
		null,
		"asset",
		"set-driver",
		openCurrentDriversDialog
	);
	loadAssetDrivers(asset);

	$(domNodes.dialogs.currentDrivers).data("assetId", asset.Id);
}

export function loadDriverHistoryDialog(asset) {
	$(domNodes.dialogs.driverHistory).data("assetId", asset.Id);
	openDialogPanel(
		domNodes.dialogs.driverHistory,
		strings.DRIVER_LOG,
		asset,
		true,
		null,
		"asset",
		"view-logs-driver",
		loadDriverHistoryDialog
	);

	loadDriverHistory(asset);
}

export function loadDriverHistory(asset) {
	var btn = document.getElementById("RefreshDrivers");
	var status = document.getElementById("drivers-status");
	var data = { assetId: asset.Id };
	handleAjaxFormSubmission(
		"GetDriverHistoryForAsset",
		data,
		btn,
		status,
		null,
		strings.MSG_DRIVER_HISTORY_ERROR,
		function (result) {
			if (result.Success == true) {
				var itemData = [];
				if (result.Items != null) {
					for (var i = 0; i < result.Items.length; i++) {
						var item = result.Items[i];
						var source = item.Source;
						if (item.CreatedBy != null) {
							source = item.CreatedBy;
						}
						var history = el(
							"a.history",
							{ href: "#", dataset: { from: item.LoginOn, to: item.LogoutOn } },
							strings.REPLAY_TRIP
						);
						itemData.push([
							//item.Id,
							item.Driver.DriverId,
							item.LoginStatus,
							item.LoginOn,
							item.LogoutStatus,
							item.LogoutOn,
							item.Duration,
							history,
							item.Notes,
							source,
							//status,
							//position,
							//item.Source,
							//item.CreatedOn
						]);
					}
				}

				$("#DriverLogs").dataTable({
					data: itemData,
					destroy: true,
					filter: false,
					info: false,
					jQueryUI: false,
					autoWidth: false,
					lengthChange: false,
					paging: true,
					pageLength: 10,
					deferRender: true,
					order: [[2, "desc"]],
					columnDefs: [
						{
							targets: "_all",
							render: $.fn.dataTable.render.text(),
						},
					],
					columns: [
						{}, // driver
						{}, // login
						{ width: "75px" }, // login on
						{}, // logoff
						{ width: "75px" }, // logoff on
						{}, // duration
						{ render: renderDomElement }, // history replay
						{}, // notes
						{}, // source
					],
					language: strings.DATATABLE,
				});

				$j("#DriverLogs").removeAttr("style");
			} else {
				formShowErrorMessage(status, strings.MSG_DRIVER_HISTORY_ERROR);
			}
		},
		null
	);
}

export function getGroupNamesForDriver(driver) {
	var groupNames = [];
	_.each(trkData.driverGroups, function (driverGroup) {
		if (_.indexOf(driverGroup.ItemIds, driver.Id) !== -1) {
			groupNames.push(driverGroup.Name);
		}
	});
	return groupNames.join(', ');
}