import L from "leaflet";

const options = {
	isDemo: false,
	useStaticSubdomains: true,
	disablePositionPopup: false,
	disableMapTypeSelection: false,
	disablePanels: false,
	disableLayerSelection: false,
	disableHeader: false,
	isCompact: false,
	showAssetId: "",
	showAssetUniqueId: "",
	defaultMode: "",
	defaultMapType: "",
	defaultZoom: 14,
	maximumZoom: 18,
	minimumZoom: 3,
	maxBounds: L.latLngBounds([
		[90, -360],
		[-90, 360],
	]),
	showPositionId: "",
	showSharedViewId: null,
	maxLiveTrailPositions: 10,
	radarRefreshMinutes: 3,
	worldIRRefreshMinutes: 30,
	showSidePanel: false,
	useFenceDocuments: false,
	placeAssetPhotosInPositionDialog: false,
	assetMenuExclude: "",
	driverFieldsExclude: "",
	hideAlertTriggeredEvents: false,
	hideEmergencyEvents: false,
	keys: {
		weatherUnderground: "",
		thunderForest: "",
		mapQuest: "",
	},
	enableGeocoding: true,
	baseLayers: {
		road: {
			enabled: true,
			url: "",
			minZoom: 1,
			maxZoom: 22,
		},
		roadlight: {
			enabled: false,
			url: "",
			minZoom: 1,
			maxZoom: 22,
		},
		roaddark: {
			enabled: false,
			url: "",
			minZoom: 1,
			maxZoom: 22,
		},
		satellitelabels: {
			enabled: false,
			url: "",
			minZoom: 3,
			maxZoom: 22,
		},
		satellitenolabels: {
			enabled: false,
			url: "",
			minZoom: 3,
			maxZoom: 22,
		},
		satellitealt: {
			enabled: false,
		},
		terrain: {
			enabled: false,
			url: "",
			minZoom: 1,
			maxZoom: 22,
			subdomains: ["a", "b", "c"],
		},
		openstreetmap: {
			enabled: false,
			url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
		},
	},
	bounceOnEvents: [292, 293],
	bounceForDuration: 10000,
	enableShoutConfigOta: false,
	defaultColors: {
		//assets: '#8dc63f',
		//fences: '#8dc63f',
		//places: '#8dc63f'
	},
	allowAnonymousMessaging: false,
	dateRangeMin: null,
	dateRangeMax: null,
	defaultIconSet: "default",
};

export default options;
