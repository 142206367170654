<li class="journeys-item group-item trip-{{id}} {{serviceClass}} {{visibilityClass}}" data-trip-id="{{id}}" data-journey-id="{{journeyId}}">
    <div class="item-header d-flex flex-row align-items-center" style="background-image: url({{icon}});">
        {{#if isVisible}}
        <svg class="list-item-action showhide active" data-trip-id="{{id}}" data-journey-id="{{journeyId}}"><use xlink:href="/content/svg/tracking.svg?v=15#visible"></use></svg>
        {{else}}
        <svg class="list-item-action showhide" data-trip-id="{{id}}" data-journey-id="{{journeyId}}"><use xlink:href="/content/svg/tracking.svg?v=15#invisible"></use></svg>
        {{/if}}
        <div class="item-info expand mr-auto">
            <div class="trip-name">{{name}}</div>
            <div class="trip-description">{{description}}</div>
            <div class="trip-indicators d-flex align-items-center">
                <svg class="notifications" title="@ViewRes.HomeStrings.Positions"><use xlink:href="/content/svg/notifications.svg?v=2#{{notifications.symbol}}"></use></svg>
            </div>
        </div>
        <svg class="list-item-action item-settings"><use xlink:href="/content/svg/tracking.svg?v=15#settings"></use></svg>
    </div>
    <div class="item-details toggle-content">
    </div>
</li>
