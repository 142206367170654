import strings from "./strings.js";
import options from "./options.js";
import preferences from "./preferences.js";
import user from "./user.js";
import trkData from "./data.js";
import { wrapUrl } from "./wrapurl.js";
import { handleWebServiceError } from "./ajax.js";
import { intervals, throttles } from "./timers.js";
import { findAssetById } from "./assets.js";
import { findDeviceById } from "./devices.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import { el } from "redom"; // https://redom.js.org/
const Cookies = window.Cookies; // from 'js.cookie.js', v2.2.0
import { Howl } from "howler";

const audio = {
	emergency: null,
	isEmergencyPlaying: false,
	text: null,
	isTextPlaying: false,
};

export function addSystemNotification(notification) {
	trkData.systemNotifications.push(notification);
	showSystemNotification(notification);
}

export function showSystemNotification(notification) {
	if (Cookies.get("notif") !== undefined && Cookies.get("notif") !== null) {
		var confirmedNotifications = Cookies.get("notif").split(",");
		console.log(confirmedNotifications);
		if (confirmedNotifications.indexOf(notification.id) !== -1) return;
	}
	var subject = strings.SYSTEM_NOTIFICATION;
	if (notification.subject != null && notification.subject != "") {
		subject = notification.subject;
	}
	$j.jGrowl(el("div.message", notification.text), {
		header: subject,
		sticky: true,
		theme: "system-notification",
		closer: false,
		mustConfirm: true,
		notificationId: "notify-" + notification.id,
		from: notification.from,
		to: notification.to,
		text: notification.text,
		type: "notification",
		buttons: [
			el(
				"button.confirm.btn.btn-sm.btn-secondary",
				{ dataset: { notificationId: "notify-" + notification.id } },
				strings.CONFIRM
			),
		],
		afterOpen: function (e, m, o) {
			if (preferences.PREFERENCE_EMERGENCY_AUDIO) {
				alertUserOfSystemNotification();
			}
		},
		appendTo: "#map-mode-container",
	});
}

function alertUserOfSystemNotification() {
	if (!preferences.PREFERENCE_EMERGENCY_AUDIO) return;

	$j("#jGrowl .system-notification").effect("highlight", {}, 1000);

	audio.text.play();
}

export function initEmergencyAudio() {
	var played = 0;
	audio.emergency = new Howl({
		src: [
			"/content/audio/emergency-2.ogg",
			"/content/audio/emergency-2.mp3",
			"/content/audio/emergency-2.m4a",
			"/content/audio/emergency-2.wav",
		],
		html5: true,
		loop: false,
		onplayerror: function () {
			audio.emergency.once("unlock", function () {
				audio.isEmergencyPlaying = true;
				audio.emergency.play();
			});
		},
		onend: function () {
			// we want to play this sound twice in a row
			played++;
			if (played > 1) {
				audio.emergency.stop();
				audio.isEmergencyPlaying = false;
				played = 0;
			} else {
				audio.emergency.play();
			}
		},
	});
	audio.text = new Howl({
		src: [
			"/content/audio/text-notification.ogg",
			"/content/audio/text-notification.mp3",
			"/content/audio/text-notification.m4a",
			"/content/audio/text-notification.wav",
		],
		html5: true,
		loop: false,
		onplayerror: function () {
			audio.text.once("unlock", function () {
				audio.text.play();
			});
		},
	});
}

function alertUserOfTextMessage() {
	if (!preferences.PREFERENCE_EMERGENCY_AUDIO) return;

	$j("#jGrowl .text-message").effect("highlight", {}, 1000);

	audio.text.play();
	clearInterval(intervals.text);
	intervals.text = setInterval(checkTextNotifications, 1000 * 60 * 1);
}

export function alertUserOfEmergency() {
	if (!preferences.PREFERENCE_EMERGENCY_AUDIO) {
		return;
	}

	if (user.isAnonymous || options.hideEmergencyEvents) {
		return;
	}

	// shake the emergency notification area and user is not hovering notifications
	$j("#jGrowl").effect("shake", { times: 2, direction: "down", distance: 10 }, 300);

	if (audio.isEmergencyPlaying !== true) {
		audio.isEmergencyPlaying = true;
		audio.emergency.play();
	}

	// reset the interval so there is always a consistent gap before replaying
	clearInterval(intervals.emergency);
	intervals.emergency = setInterval(checkEmergencyNotifications, 1000 * 10 * 1);
}

export function checkEmergencyNotifications() {
	if (!preferences.PREFERENCE_EMERGENCY_AUDIO) return;

	if (user.isAnonymous || options.hideEmergencyEvents) return;

	var activeNotifications = $j(".jGrowl-notification.emergency-on").length;
	if (activeNotifications > 0) {
		throttles.emergencyAlert();
	}
}

export function checkTextNotifications() {
	if (!preferences.PREFERENCE_EMERGENCY_AUDIO) return;

	var activeNotifications = $j(".jGrowl-notification.text-message").length;
	if (activeNotifications > 0) {
		alertUserOfTextMessage();
	}
}

export function queryLatestNotifications() {
	if (trkData.queryingNotifications) {
		return;
	}

	if (user.isAnonymous && !options.allowAnonymousMessaging) {
		return;
	}

	trkData.queryingNotifications = true;
	var dataPost = { lastMessageId: trkData.lastNotificationId };
	$.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetLatestNotificationsForUser"),
		data: JSON.stringify(dataPost),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg.d) {
				var notifications = msg.d;
				for (var i = 0; i < notifications.length; i++) {
					var notify = notifications[i];
					var asset = findAssetById(notify.AssetId);
					if (asset == null) {
						continue;
					}
					var positionElement = null;
					if (notify.PositionId != null) {
						// show position button, will likely have to load from ajax or include lat/lng here
						positionElement = el(
							"button.notification-position.btn.btn-link.btn-sm",
							{ dataset: { assetId: asset.Id, positionId: notify.PositionId } },
							strings.SHOW_POSITION
						);
					}
					var replyElement = el(
						"a.reply-to-text.btn.btn-link.btn-sm",
						{ href: "#", dataset: { assetId: asset.Id } },
						strings.REPLY
					);
					var confirmElement = el(
						"button.confirm.btn.btn-sm",
						{ dataset: { assetId: asset.Id, notificationId: notify.Id } },
						strings.CONFIRM
					);
					var device = findDeviceById(asset.DeviceId);
					if (!device.SupportsMessaging) {
						replyElement = null;
					}
					var notifyText = notify.Text === null ? "" : notify.Text;
					switch (notify.IsEmergency) {
						case false: // text message
							// todo: determine if the asset is valid and can be replied to? (right now asset will only be the asset that "received" the message)
							var msg = [el("span.time", notify.CreatedOn), el("div.message", notifyText)];
							var buttons = _.compact([confirmElement, positionElement, replyElement]);
							$.jGrowl(msg, {
								header: notify.Type + ": " + asset.Name,
								sticky: true,
								theme: "text-message",
								closer: false,
								mustConfirm: true,
								notificationId: notify.Id,
								assetId: asset.Id,
								text: notify.Text,
								type: "text",
								buttons: buttons,
								afterOpen: function (e, m, o) {
									if (preferences.PREFERENCE_EMERGENCY_AUDIO) {
										alertUserOfTextMessage();
									}
								},
								appendTo: "#map-mode-container",
							});
							break;
						case true: // emergency/sos
							if (user.isAnonymous || options.hideEmergencyEvents) {
								continue;
							}
							// group emergency messages where we kill emergency messages for the same asset
							// that don't have a related text message -- only keep the latest
							var msg = [el("span.time", notify.CreatedOn), el("div.message", notifyText)];
							var buttons = _.compact([confirmElement, positionElement, replyElement]);
							$.jGrowl(msg, {
								header: notify.Type + ": " + asset.Name,
								sticky: true,
								theme: "emergency-on",
								closer: false,
								mustConfirm: true,
								notificationId: notify.Id,
								assetId: asset.Id,
								text: notify.Text,
								type: "emergency",
								buttons: buttons,
								afterOpen: function (e, m, o) {
									if (preferences.PREFERENCE_EMERGENCY_AUDIO) {
										throttles.emergencyAlert();
									}
								},
								appendTo: "#map-mode-container",
							});
							break;
					}
					var thisId = parseInt(notify.Id);
					if (trkData.lastNotificationId == null) {
						trkData.lastNotificationId = thisId;
					}
					if (trkData.lastNotificationId < thisId) {
						trkData.lastNotificationId = thisId;
					}
				}
				trkData.queryingNotifications = false;
			}
		},
		error: function (xhr, status, error) {
			trkData.queryingNotifications = false;
			handleWebServiceError("Could not retrieve message notifications.");
			console.log(error);
			console.log(status);
			console.log(xhr);
		},
	});
}
