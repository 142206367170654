import options from "./options.js";
import user from "./user.js";
import state from "./state.js";

export default function log(msg) {
	var m;
	if (typeof console === "undefined") {
		return;
	}
	m = "[" + new Date().toISOString() + "] Track: " + msg;
	if (console.debug) {
		console.debug(m);
	} else if (console.log) {
		console.log(m);
	}
}

export function getDbg() {
	return {
		u: user.id,
		d: options.isDemo,
		a: user.isAnonymous,
		i: user.isImpersonated,
		m: state.isMobile,
		s: state.isSafari,
	};
}
