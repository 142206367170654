import domNodes from "./domNodes.js";

import Popper from "popper.js"; // From ../popper.js . See https://github.com/floating-ui/floating-ui

/// TODO: Refactor domNodes.mouseTooltip into a module variable.

export function initializeMouseTooltip() {
	var tip = document.getElementById("mouse-tooltip");
	//domNodes.map.querySelector('.leaflet-marker-pane').appendChild(tip);
	domNodes.mouseTooltip.content = tip;
	domNodes.mouseTooltip.title = tip.querySelector(".tooltip-inner");
	domNodes.mouseTooltip.show = false;
	domNodes.mouseTooltip.position = { x: 0, y: 0 };
	domNodes.mouseTooltip.reference = {
		getBoundingClientRect: function () {
			return {
				top: domNodes.mouseTooltip.position.y,
				right: domNodes.mouseTooltip.position.x,
				bottom: domNodes.mouseTooltip.position.y,
				left: domNodes.mouseTooltip.position.x,
				width: 0,
				height: 0,
			};
		},
		clientWidth: 0,
		clientHeight: 0,
	};
	var arrow = domNodes.mouseTooltip.content.querySelector(".arrow");
	domNodes.mouseTooltip.popper = new Popper(domNodes.mouseTooltip.reference, domNodes.mouseTooltip.content, {
		modifiers: {
			offset: {},
			arrow: {
				element: arrow,
			},
		},
		placement: "right",
	});
}

export function showMouseTooltip(x, y, title) {
	domNodes.mouseTooltip.position = { x: x, y: y };
	domNodes.mouseTooltip.show = true;
	domNodes.mouseTooltip.title.textContent = title;
	domNodes.mouseTooltip.content.classList.add("show");
	domNodes.mouseTooltip.content.classList.remove("hide");
	domNodes.mouseTooltip.popper.scheduleUpdate();
	// two calls required as popper has incorrect starting arrow on very first show... maybe due to css transition?
	domNodes.mouseTooltip.popper.scheduleUpdate();
}

export function hideMouseTooltip() {
	domNodes.mouseTooltip.show = false;
	domNodes.mouseTooltip.content.classList.remove("show");
	domNodes.mouseTooltip.content.classList.add("hide");
}
