// Function that handles clicking on *any* map marker

import strings from "./strings.js";
import trkData from "./data.js";
import state from "./state.js";
import options from "./options.js";
import domNodes from "./domNodes.js";
import log from "./log.js";
import { viewModes, mapModes } from "./const.js";
import { hidePrimaryPanel } from "./panel.js";
import { findAssetById } from "./assets.js";
import { findFenceById, getFenceColor, centerOnFence, populateGeofenceInformation } from "./fence.js";
import { map } from "./map-base.js";
import { removeItemFromMap } from "./map-items.js";
import { addItemToMap } from "./map-items.js";
import { createMarkerPath } from "./marker-path.js";
import { findPlaceById } from "./place.js";
import { findWaypointById } from "./waypoint.js";
import { updateChosenLocation } from "./map-chooselocation.js";
import { findTripById } from "./trips.js";
import { createDialogTitleFragment } from "./dom-util.js";
import { moveOrOpenDialogRelativeTo } from "./modal-dialog.js";
import { getHighestPriorityEventType } from "./marker.js";
import { populatePositionInformation } from "./position-information.js";
import { playbackEnd } from "./playback.js";
import {
	createQuickActionsForGeofence,
	createQuickActionsForPlace,
	createQuickActionsForPosition,
	createQuickActionsForWaypoint,
} from "./quick-actions.js";
import { loadGarminFormSubmission } from "./garmin.js";
import { populatePlaceInformation } from "./place.js";
import { populateWaypointInformation } from "./waypoint.js";
import {convertNamedColorToArray} from "./color.js";

import $ from "jquery";
import L from "leaflet";
import _ from "lodash";
import { el, text } from "redom"; // https://redom.js.org/

import GleoSymbol from "./gleo/src/symbols/Symbol.mjs";
import parseColour from "./gleo/src/3rd-party/css-colour-parser.mjs";

function zoomToShowClusterSymbol(clusterer, marker, latLng) {
	const scale = clusterer.getUnclusterScaleFor(marker);

	if (scale > 0) {
		// asset marker can be shown unclustered
		const zoomLevel = map.platina.scaleToZoomLevel(scale);
		map.setView(latLng, zoomLevel);

	} else {
		// asset marker is part of a spider
		const scale = clusterer.scaleLimit;
		const zoomLevel = map.platina.scaleToZoomLevel(scale);
		map.setView(latLng, zoomLevel);

		const spider = clusterer.getParent(marker, scale);
		spider.expand();
	}
}

// Event handler for clicking on a "marker". In reality, a "marker" can be either:
// - A Leaflet L.Marker
// - A Leaflet L.Path (for geofences)
// - A GleoSymbol
export function markerClick(marker, type, latLng, isMapClick) {
	if (marker instanceof L.Marker || marker instanceof L.Path) {
		if (latLng === null || latLng === undefined) {
			latLng = marker.getLatLng();
		}
	} else if (marker instanceof GleoSymbol) {
		if (latLng === null || latLng === undefined) {
			const lngLat = marker.geometry.toCRS("EPSG:4326").coords;
			latLng = [lngLat[1], lngLat[0]];
		}
	} else {
		throw new Error("Clicked on something which is neither a Leaflet Marker nor a Gleo Symbol");
	}

	if (isMapClick && state.mapClickQueue.length !== 0) {
		updateChosenLocation(latLng);
		return;
	}

	// this now handles assets, places, waypoints, fences, history?
	var asset = null;
	if (marker.data.assetId !== undefined && marker.data.assetId !== null) {
		asset = findAssetById(marker.data.assetId);
	}
	var place = null;
	if (marker.data.placeId !== undefined && marker.data.placeId !== null) {
		place = findPlaceById(marker.data.placeId);
	}
	var fence = null;
	if (marker.data.fenceId !== undefined && marker.data.fenceId !== null) {
		fence = findFenceById(marker.data.fenceId);
	}
	var waypoint = null;
	if (marker.data.waypointId !== undefined && marker.data.waypointId !== null) {
		waypoint = findWaypointById(marker.data.waypointId);
	}

	if (state.selectedMarker != null && state.selectedMarker != marker) {
		// reclicked the same marker already selected
		selectedMarkerChanging();
	}

	if (type !== "fence") {
		// select this marker
		marker.data.selected = true;
		markerUnhover(marker);
		state.selectedMarker = marker;

		// no map interaction if marker is hidden
		if (!marker.data.location.IsHidden) {
			if (
				asset !== null &&
				trkData.history.markerClustersByAssetId[asset.Id] !== undefined && (
					state.activeMapMode === mapModes.HISTORY &&
					state.activeViewMode === viewModes.NORMAL
				)
			) {

				// Replicate the behaviour of gleo Clusterer.zoomToShowSymbol(),
				// but tweaked for leaflet-gleo
				const clusterer = trkData.history.markerClustersByAssetId[asset.Id];
				zoomToShowClusterSymbol(clusterer, marker, latLng);
			} else if (asset !== null &&
				trkData.sharedView.markerClustersByAssetId[asset.Id] !== undefined &&
				state.activeViewMode === viewModes.SHARED_VIEW) {
				const clusterer = trkData.sharedView.markerClustersByAssetId[asset.Id];
				zoomToShowClusterSymbol(clusterer, marker, latLng);
			}

			// bounce marker
			bounceMarker(marker);

			if (!isMapClick) {
				if (!state.isInPlaybackMode && map.getZoom() < options.defaultZoom) {
					map.setView(latLng, options.defaultZoom);
				} else {
					map.panTo(latLng);
				}
			}
		}
	} else {
		if (!isMapClick) {
			centerOnFence(fence.Id);
		}
		state.selectedMarker = null;
	}

	if (!isMapClick) {
		// pan/zoom to marker
		// just pan to marker
	}

	// when to pan?
	//  as part of playback: yes
	//  as part of map click: no
	//  as part of UI click: yes
	// when to zoom?
	//  when marker is clustered and hidden? maybe
	//  as part of UI click: yes
	//  as part of playback: no
	//  as part of map click: no
	// when to bounce?
	//  fence: no
	//  hidden clustered position: yes for icon and visible clustered icon
	//  others: yes

	let isZoomHandled = false;
	if (type !== 'fence') {
	   if (!state.isInPlaybackMode) {
	       // if ((asset !== null) && (asset.MarkerCluster !== undefined) && (state.activeMapMode === mapModes.HISTORY)) {
	       //     // handle clustering for asset markers in history mode
	       //     var visibleParent = asset.MarkerCluster.getVisibleParent(marker);
        //
	       //     // marker clusters don't currently bounce
	       //     // we should probably check whether it should on cluster creation (i.e. if a marker inside the cluster is bouncing, then it should also bounce)
	       //     var isCurrentlyBouncing = false;
	       //     if (visibleParent != null) {
	       //         isCurrentlyBouncing = visibleParent.isBouncing();
	       //     }
	       //     console.log('Is clustered icon bouncing? ' + isCurrentlyBouncing);
        //
	       //     // zoom low enough to show the individual marker inside the cluster
	       //     asset.MarkerCluster.zoomToShowLayer(marker, function () {
	       //         map.panTo(latLng);
	       //         bounceMarker(marker);
	       //     });
	       //     isZoomHandled = true;
	       // }
	       // if (!isZoomHandled) {
	       //     if (map.getZoom() < options.defaultZoom) {
	       //         map.setView(latLng, options.defaultZoom);
	       //     } else {
	       //         map.panTo(latLng);
	       //     }
	       // }
	   } else {
	       map.panTo(latLng);
	   }
	} else {
	   centerOnFence(fence.Id);
	   isZoomHandled = true;
	}

	// in mobile mode, show the map
	if (window.screen.width <= 768) {
		hidePrimaryPanel();
	}

	var dialogPanel = document.getElementById("dialog-map-item-information");

	// hide prior quick actions
	var actionOptions = dialogPanel.querySelectorAll(".dialog-titlebar .dialog-quick-actions");
	_.each(actionOptions, function (actionOption) {
		actionOption.classList.remove("is-visible");
	});

	// populate quick actions and dialog content
	var dialog = domNodes.infoDialogs.mapItemInformation;
	var $dialog = $(dialog);

	// clear previous data values - check about close callback first?
	$dialog.removeData("location"); // used?
	$dialog.removeData("assetId"); // used?
	dialog.removeAttribute("data-asset-id");
	dialog.removeAttribute("data-position-id");
	dialog.removeAttribute("data-fence-id");
	dialog.removeAttribute("data-waypoint-id");
	dialog.removeAttribute("data-shared-view-id");
	dialog.data = {};

	var location = marker.data.location;
	if (location !== undefined && location !== null) {
		if (location.Id !== undefined) {
			dialog.setAttribute("data-position-id", location.Id);
		}
		$dialog.data("location", location);
		dialog.data.location = location;
	}

	var markerIcon = marker._icon ?? marker.getElement();
	var content = "";
	var titleIcon = "";
	var contentCreatedCallback = null;
	var closeCallback = null;

	var titleFragment = document.createDocumentFragment();

	if (asset !== null) {
		if (!marker.data.location.IsHidden) {
			// show accuracy of location, if available
			var accuracy = marker.data.accuracyCircle;
			if (accuracy != null) {
				addItemToMap(accuracy);
			} else if (asset !== null) {
				if (location.Accuracy != null && location.Accuracy != "") {
					accuracy = L.circle(latLng, {
						radius: location.Accuracy,
						color: asset.Color,
						fillColor: asset.Color,
						opacity: 1,
						fillOpacity: 0.15,
						weight: 2,
					}).bindTooltip(asset.Name);
					addItemToMap(accuracy);
					marker.data.accuracyCircle = accuracy;
				}
			}
		}

		createQuickActionsForPosition(asset, location);

		dialog.setAttribute("data-asset-id", asset.Id);
		dialog.data.assetId = asset.Id;
		$dialog.data("assetId", asset.Id);

		if (
			markerIcon === undefined &&
			trkData.history.markerClustersByAssetId[asset.Id] !== undefined &&
			state.activeMapMode === mapModes.HISTORY &&
			state.activeViewMode === viewModes.NORMAL
		) {
			var visibleMarker = trkData.history.markerClustersByAssetId[asset.Id].getParent(marker);
			if (visibleMarker !== null) {
				markerIcon = visibleMarker._icon ?? visibleMarker.getElement();
			}
		} else if (
			markerIcon === undefined &&
			trkData.sharedView.markerClustersByAssetId[asset.Id] !== undefined &&
			state.activeViewMode === viewModes.SHARED_VIEW
		) {
			var visibleMarker = trkData.sharedView.markerClustersByAssetId[asset.Id].getParent(marker);
			if (visibleMarker !== null) {
				markerIcon = visibleMarker._icon ?? visibleMarker.getElement();
			}
		}
		titleFragment = createDialogTitleFragment(asset.Name, options.hideDeviceName ? null : asset.DeviceName);
		titleIcon = createMarkerPath(asset.Class, asset.Color, null, null, asset.Id, false, null, false, false);
		content = populatePositionInformation(asset, marker);
		contentCreatedCallback = function () {
			$("#asset-information-accordion,.alert-information-accordion").on("show.bs.collapse", function (e) {
				e.target.previousElementSibling
					.querySelector("use")
					.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#angle-up");
				if (e.target.id === "accordion-garmin-content") {
					var data = $(e.target.querySelector(".GarminSubmissionItems"));
					var formId = data.data("formId");
					var isLoaded = data.data("formLoaded") === true;
					if (!isLoaded) {
						loadGarminFormSubmission(asset.Id, formId, e.target);
					}
					data.data("formLoaded", true);
				}
			});
			$("#asset-information-accordion,.alert-information-accordion").on("hide.bs.collapse", function (e) {
				e.target.previousElementSibling
					.querySelector("use")
					.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#angle-down");
			});
		};
	} else if (place !== null) {
		createQuickActionsForPlace(place);

		dialog.setAttribute("data-place-id", place.Id);
		dialog.data.placeId = place.Id;

		titleFragment = createDialogTitleFragment(place.Name, strings.PLACE);
		titleIcon = createMarkerPath("Generic", place.Color, null, null, null, false, null, false, false);
		content = populatePlaceInformation(place);
	} else if (fence !== null) {
		createQuickActionsForGeofence(fence);
		dialog.setAttribute("data-fence-id", fence.Id);
		dialog.data.fenceId = fence.Id;
		titleFragment = createDialogTitleFragment(fence.Name, strings.GEOFENCE);
		titleIcon = createMarkerPath("Fence", getFenceColor(fence), null, null, null, false, null, false, false);
		content = populateGeofenceInformation(fence);
	} else if (waypoint !== null) {
		var waypointAsset = findAssetById(waypoint.AssetId);
		createQuickActionsForWaypoint(waypoint, waypointAsset);

		dialog.setAttribute("data-waypoint-id", waypoint.Id);
		dialog.data.waypointId = waypoint.Id;
		titleFragment = createDialogTitleFragment(waypoint.Name, strings.WAYPOINT);
		titleIcon = createMarkerPath("Waypoint", waypointAsset.Color, null, null, null, false, null, false, false);
		content = populateWaypointInformation(waypoint, waypointAsset);
		contentCreatedCallback = function () {
			if (waypoint.route != null && waypoint.routingService != null) {
				waypoint.routingService.route();
				$("#WaypointRoutePanel").append(waypoint.routingService.control);
			}
		};
	}

	$dialog.empty();
	$dialog.append(content);
	if (contentCreatedCallback !== null) {
		contentCreatedCallback();
	}

	$dialog.dialog("option", "title", titleFragment);
	var dialogTitleBar = dialogPanel.querySelector("div.ui-dialog-titlebar");
	//var dialogTitle = dialogPanel.querySelector('.dialog-titlebar');
	dialogTitleBar.classList.remove("has-svg-icon");
	if (titleIcon != "") {
		dialogTitleBar.style.backgroundImage = "url(" + titleIcon + ")";
	} else {
		dialogTitleBar.style.backgroundImage = null;
	}

	//if (isHover && !isMarkerSelected) {
	//    $(dialog).closest('.ui-dialog').addClass('is-hover');
	//} else {
	//    $(dialog).closest('.ui-dialog').removeClass('is-hover');
	//}

	// position the dialog
	var relativePlacement = "right center";
	var referencePoint = markerIcon;
	//if (type === 'fence' && fence !== null) {
	//    var fenceBounds = getFenceBounds(fence.Id);
	//    if (fenceBounds !== null) {
	//        var latLng = L.latLng(fenceBounds.getCenter().lat, fenceBounds.getEast());
	//        var tooltipPoint = map.latLngToContainerPoint(latLng);
	//        tooltipPoint.x += getMapLeftOffset();
	//    }
	//    referencePoint =  { pageX: tooltipPoint.x, pageY: tooltipPoint.y};
	//}
	if (referencePoint === undefined || referencePoint === null) {
		// if there's no icon for this location (such as a live position that no longer is mapped), then center it on the map
		// todo: place it at the appropriate location on the map instead of in the center by showing its marker
		referencePoint = map._container;
		relativePlacement = "center center";
	}

	// only move the dialog if its not already open
	moveOrOpenDialogRelativeTo($dialog, referencePoint, relativePlacement);

	$dialog.off("dialogclose");

	$dialog.on("dialogclose", function (event, ui) {
		log("dialog close");
		selectedMarkerChanging();

		if (closeCallback !== null) {
			closeCallback();
		}
		state.selectedMarker = null;
		playbackEnd();
		$(document).off("keydown.map-item-information-dialog");
	});

	//// bounce selected marker via animation
	//if (!isZoomHandled) {
	//    bounceMarker(marker);
	//}
}

function bounceMarker(marker) {
	if (marker == null) {
		return;
	}
	var isSelected = marker.data.selected;
	var assetId = marker.data.assetId;
	var tripId = marker.data.tripId;
	var isTrip = tripId !== undefined && tripId !== null;

	// trips and history mode can have clustered markers
	// if (assetId != null) {
	// 	var asset = findAssetById(assetId);
	// 	if (asset !== null) {
	// 		if (isTrip) {
	// 			var trip = findTripById(tripId);
	// 			if (trip !== null && trkData.trips.markerClustersByTripId[trip.Id] !== undefined) {
	// 				marker = trkData.trips.markerClustersByTripId[trip.Id].getVisibleParent(marker);
	// 			}
	// 		} else if (
	// 			state.activeMapMode !== mapModes.LIVE &&
	// 			trkData.history.markerClustersByAssetId[asset.Id] !== undefined
	// 		) {
	// 			marker = trkData.history.markerClustersByAssetId[asset.Id].getVisibleParent(marker);
	// 		}
	// 	}
	// }
	if (marker == null) {
		return;
	}

	if (isSelected) {
		// bounce me
		if (!marker.isBouncing()) {
			marker
				.setBouncingOptions({
					bounceHeight: 15,
					bounceSpeed: 150,
					exclusive: true,
				})
				.toggleBouncing();
		} else {
			// console.log("already bouncing");
		}
	} else {
		// stop bouncing, don't rebounce
		marker.stopBouncing();
	}
}

export function markerUnhover(marker) {
	// changeMarkerIcon(marker, null, marker.data.alpha, false);
	changeMarkerIcon(marker, marker.data.tint ?? null, marker.data.alpha, false);
	hideTemporaryMarker(marker);

	$(marker.getElement()).bsTooltip("dispose");
}

function hideTemporaryMarker(marker) {
	if (marker.data.selected == true) {
		return;
	}
	// markers that were loaded outside the scope of the view
	// i.e. a marker from a historic event in live view
	if (marker.data.hide == true) {
		removeItemFromMap(marker);
	}
}

function selectedMarkerChanging() {
	if (state.selectedMarker === null) {
		return;
	}
	state.selectedMarker.data.selected = false;
	markerUnhover(state.selectedMarker);
	// log("stop bouncing - previously selected");
	state.selectedMarker.stopBouncing();

	var accuracy = state.selectedMarker.data.accuracyCircle;
	if (accuracy != null) {
		removeItemFromMap(accuracy);
	}
}

export function changeMarkerIcon(marker, overrideColor, overrideAlpha, isForHover) {
	if (isForHover == null) {
		isForHover = false;
	}
	var color = "";
	var type = "";
	var course = null;
	var alpha = 255;
	var assetId = null;
	var evtType = null;
	var isFirst = false;
	var isLast = false;
	var forceAlpha = false;
	var location = marker.data.location;

	var asset = null;
	if (marker.data.assetId !== undefined && marker.data.assetId !== null) {
		asset = findAssetById(marker.data.assetId);
	}
	var place = null;
	if (marker.data.placeId !== undefined && marker.data.placeId !== null) {
		place = findPlaceById(marker.data.placeId);
	}
	var fence = null;
	if (marker.data.fenceId !== undefined && marker.data.fenceId !== null) {
		fence = findFenceById(marker.data.fenceId);
	}
	var waypoint = null;
	if (marker.data.waypointId !== undefined && marker.data.waypointId !== null) {
		waypoint = findWaypointById(marker.data.waypointId);
	}

	if (asset !== null) {
		assetId = asset.Id;
		isFirst = marker.data.isFirst;
		isLast = marker.data.isLast;
		color = asset.Color != null ? asset.Color : "";
		type = asset.Class != null ? asset.Class : "";
		course = location.Course != null ? location.Course : "";
		evtType = getHighestPriorityEventType(location.Events);
	} else if (place !== null) {
		type = "Generic";
		color = place.Color;
	} else if (waypoint !== null) {
		type = "Waypoint";
		var waypointAsset = findAssetById(waypoint.AssetId);
		color = waypointAsset.Color;
	} else if (fence !== null) {
		type = "Fence";
		color = fence.Color;
	}

	if (overrideColor != null && overrideColor != "") {
		color = overrideColor;
	}
	if (overrideAlpha != null && overrideAlpha != "") {
		alpha = overrideAlpha;
	}

	// assuming 100% alpha for hover
	if (marker instanceof L.Marker) {
		var imagePath = createMarkerPath(type, color, course, alpha, assetId, false, evtType, isFirst, isLast);
		if (
			!map.hasLayer(marker) &&
			asset !== null &&
			state.activeMapMode !== mapModes.LIVE &&
			trkData.history.markerClustersByAssetId[asset.Id] !== undefined &&
			trkData.history.markerClustersByAssetId[asset.Id].getParent(marker) != null
		) {
			// FIXME is this dead code?

			// marker currently hidden, perhaps within a cluster?
			var parent = trkData.history.markerClustersByAssetId[asset.Id].getParent(marker);
			if (isForHover) {
				parent.imagePath = imagePath;
			} else {
				parent.imagePath = null;
			}
			if (!parent.isBouncing()) {
				marker.refreshIconOptions({ iconUrl: imagePath }, true);
			}
		} else {
			marker.setIcon(L.icon({ iconUrl: imagePath, iconSize: [36, 36], iconAnchor: [18, 18] }));
		}
	} else {
		// FIXME clustering not taken into consideration here, uploads?
		//console.log('set tint instead of replace icon');
		//const colour = parseColour(convertNamedColorToArray(color));
		//colour[3] = alpha;
		//marker.tint = colour;
		marker.replaceIcon(type, color, alpha);
	}
}

export function markerHover(marker) {
	if (marker.data.location.IsHidden) {
		return;
	}
	var isSelected = marker.data.selected;
	if (isSelected == null || !isSelected) {
		var titleElement = null;
		var asset = null;
		if (marker.data.assetId !== undefined && marker.data.assetId !== null) {
			var location = marker.data.location;
			var timeElement = null;
			if (location !== null && location !== undefined) {
				if (location.IsAcc != null && location.IsAcc === false) {
					timeElement = el("div", [
						el("span.inaccurate", location.Time),
						el("sup", { title: strings.TIME_INACCURATE }, "*"),
					]);
				}
				timeElement = el("div.position-time", location.Time);
			}
			asset = findAssetById(marker.data.assetId);
			titleElement = el("div", [text(asset.Name), timeElement]);
		}
		var place = null;
		if (marker.data.placeId !== undefined && marker.data.placeId !== null) {
			place = findPlaceById(marker.data.placeId);
			titleElement = el("div", place.Name);
		}
		var fence = null;
		if (marker.data.fenceId !== undefined && marker.data.fenceId !== null) {
			fence = findFenceById(marker.data.fenceId);
			titleElement = el("div", fence.Name);
		}
		var waypoint = null;
		if (marker.data.waypointId !== undefined && marker.data.waypointId !== null) {
			waypoint = findWaypointById(marker.data.waypointId);
			titleElement = el("div", waypoint.Name);
		}
		// change to yellow
		changeMarkerIcon(marker, "yellow", null, true);

		// show tooltip
		// todo: don't show tooltip if marker has tags assigned to it
		$(marker.getElement())
			.bsTooltip({
				title: titleElement,
				html: true,
				placement: "left",
				trigger: "manual",
				container: ".leaflet-pane.leaflet-marker-pane",
				offset: "0, -5px",
			})
			.bsTooltip("show");
	}

	//showMarkerInformation(marker, true);
}
