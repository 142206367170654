import { wrapUrl } from "./wrapurl.js";
import domNodes from "./domNodes.js";
import { openActionDialog } from "./modal.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import { el, svg } from "redom";

export async function handleAjaxFormSubmission(
	endpoint,
	data,
	button,
	statusAlert,
	msgSuccess,
	msgError,
	callbackSuccess,
	callbackFailure
) {
	toggleLoadingMessage(true, endpoint);
	var buttons = [];
	if (button !== null) {
		if (Array.isArray(button)) {
			buttons = button;
		} else {
			buttons = [button];
		}
	}
	buttons.forEach(b => b.disabled = true);
	if (statusAlert !== undefined && statusAlert !== null) {
		statusAlert.classList.remove("is-visible");
		statusAlert.textContent = "";
	}
	try {
		const req = await fetch(wrapUrl("/services/GPSService.asmx/" + endpoint), {
			method: "POST",
			body: JSON.stringify(data),
			headers: new Headers({
				"Content-Type": "application/json; charset=utf-8",
			}),
		});

		if (!req.ok) {
			// TODO: 400, 401 and 404 are api errors... 400 may have modelstate
			// information leave this generic error for the 500
			// test with invalid assetid or similar
			if (msgError && statusAlert) {
				buttons.forEach(b => b.disabled = false);
				toggleLoadingMessage(false, endpoint);
				formShowErrorMessage(statusAlert, msgError);
				return;
			}
		}

		const msg = await req.json();

		buttons.forEach(b => b.disabled = false);

		toggleLoadingMessage(false, endpoint);
		var result = msg.d;
		if (result) {
			if (result.Success == true) {
				if (msgSuccess !== null && statusAlert !== undefined && statusAlert !== null) {
					formShowSuccessMessage(statusAlert, msgSuccess);
				}
				if (callbackSuccess !== undefined && callbackSuccess !== null) {
					callbackSuccess(result);
				}
			} else {
				if (msgError !== null && statusAlert !== undefined && statusAlert !== null) {
					formShowErrorMessage(statusAlert, msgError);
					if (result.ErrorMessage != null && result.ErrorMessage !== "") {
						formShowErrorMessage(statusAlert, statusAlert.textContent + " " + result.ErrorMessage);
					}
				}
				if (callbackFailure !== undefined && callbackFailure !== null) {
					callbackFailure(result);
				}
			}
		}
	} catch (ex) {
		buttons.forEach(b => b.disabled = false);
		toggleLoadingMessage(false, endpoint);
		handleWebServiceError(ex);
	}
}

export function handleAjaxFormSubmissionWithGatewaySelection(
	endpoint,
	data,
	button,
	statusAlert,
	msgSuccess,
	msgError,
	callbackSuccess,
	callbackFailure,
	callbackDone,
	buttonLabel,
	dialogTitle
) {
	var actiondialog = $(domNodes.modals.messageAction);
	var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
		var coreData = {
			assetId: id,
			groupIds: groupIds,
			assetIds: assetIds,
			gateway: gateway,
			gatewayTimeout: gatewayTimeout,
			gatewayRetries: gatewayRetries,
		};

		var mergedData = _.extend(coreData, data);

		handleAjaxFormSubmission(
			endpoint,
			mergedData,
			button,
			statusAlert,
			msgSuccess,
			msgError,
			callbackSuccess,
			callbackFailure
		).then(function () {
			actiondialog.modal("hide");
		});
	};

	openActionDialog(buttonLabel, dialogTitle, callback);
}

/// TODO: Most functions calling either/both formShowSuccessMessage / formShowErrorMessage
/// should be refactored into a handleAjaxFormSubmission call.

export function formShowSuccessMessage(elem, message, noScroll) {
	elem.textContent = message;
	elem.classList.add("alert-success");
	elem.classList.add("is-visible");
	elem.classList.remove("alert-danger");
	if (noScroll === undefined) {
		elem.scrollIntoView(false);
	}
	//elem.text(message).addClass('alert-success is-visible').removeClass('alert-danger');
}

export function formShowErrorMessage(elem, message, noScroll) {
	elem.textContent = message;
	elem.classList.add("alert-danger");
	elem.classList.add("is-visible");
	elem.classList.remove("alert-success");
	if (noScroll === undefined) {
		elem.scrollIntoView(false);
	}
	//elem.text(message).addClass('alert-danger is-visible').removeClass('alert-success');
}

const loading = [];

export function toggleLoadingMessage(show, key) {
	// if key is supplied, tie the show/hide to that key
	if (key != null) {
		if (show) {
			loading.push(key);
		} else {
			for (var i = 0; i < loading.length; i++) {
				if (loading[i] == key) {
					loading.splice(i, 1);
					break;
				}
			}
		}
	}
	if (show) {
		$j("#loading").show();
	} else {
		// don't hide until all keys are done loading
		if (loading.length == 0) $j("#loading").hide();
	}
}

export function uploadFile(id, url, data, callback) {
	var file = document.getElementById(id).files[0];
	if (file === undefined) {
		return false;
	}
	var formData = new FormData();
	formData.append(id, file);
	_.each(data, function (value, key, obj) {
		formData.append(key, value);
	});

	$.ajax({
		url: wrapUrl(url),
		type: "POST",
		data: formData,
		cache: false,
		contentType: false,
		processData: false,
		success: callback,
	});
	return true;
}

// The global `utilityStrings` is defined elsewhere by the C# code.
/*global utilityStrings */

/// NOTE: This function is duplicated from `../utility.js'.
/// Ideally it should be de-duplicated.
export function handleWebServiceError(msg) {
	var items = [
		svg(
			"svg",
			svg("use", {
				xlink: { href: "/content/svg/tracking.svg?v=15#exclamation-circle" },
			})
		),
	];
	if (msg && msg.length > 0) {
		items.push(el("span", msg));
		$.jGrowl(items, {
			header: utilityStrings.MSG_ERROR,
			life: 10000,
			theme: "growl-error",
		});
	} else {
		items.push(el("span", utilityStrings.MSG_ERROR_OCURRED));
		$.jGrowl(items, {
			header: utilityStrings.MSG_ERROR,
			life: 10000,
			theme: "growl-error",
		});
	}
}