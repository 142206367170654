import strings from "./strings.js";
import trkData from "./data.js";
import state from "./state.js";
import options from "./options.js";
import user from "./user.js";
import domNodes from "./domNodes.js";
import { mapModes, trkDataGroups } from "./const.js";
import { openDialogPanel } from "./panel-nav.js";
import { findAssetById, normalizeAssetData } from "./assets.js";
import { getGroupAssetStatus, findGroupById } from "./asset-group.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import { checkForShareViewChange } from "./shared-view.js";
import { createListing } from "./item-listing.js";
import { getDisplayFilterForEventType } from "./display-filter.js";
import { updateAssetNotificationTime } from "./asset-notification.js";
import { updateGroupFunctionBadges, updateAssetFunctionBadges } from "./badges.js";
import { getAssetDataGroupForCurrentViewMode } from "./map-viewmode.js";
import {
	loadHistoryMessagesForAssets,
	loadHistoryMessagesForSharedView,
	loadHistoryMessagesForTrip,
} from "./messages-history.js";
import { devices, findDeviceById } from "./devices.js";
import { findAssetIdsInGeofence } from "./fence.js";
import { sortByName } from "./item-sorting.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import moment from "moment"; // https://www.npmjs.com/package/moment

import "../legacy/jquery.maxchar.js"

export function openMessagesForAsset(asset) {
	if (user.isAnonymous) {
		return;
	}

	var loadMessageCallback = function (dataSource) {
		return function () {
			var messages = _.filter(dataSource[asset.Id], getDisplayFilterForEventType("messages"));
			createListing(messages, "messages");
		};
	};

	var dataSource = trkData.history.normalizedMessagesByAssetId;
	if (state.activeMapMode === mapModes.HISTORY) {
		if (state.hasQueriedHistory) {
			$.when(loadHistoryMessagesForAssets([asset.Id])).done(loadMessageCallback(dataSource));
		} else {
			createListing([], "messages");
		}
	} else if (state.activeMapMode === mapModes.LIVE) {
		dataSource = trkData.live.normalizedMessagesByAssetId;
		$.when(loadLiveMessagesForAssets([asset.Id])).done(loadMessageCallback(dataSource));
	}

	openDialogPanel(
		domNodes.dialogs.assetMessages,
		strings.MESSAGES,
		asset,
		false,
		null,
		"asset",
		"asset-messages",
		openMessagesForAsset
	);
}

export function openMessagesForGroup(group) {
	if (user.isAnonymous) {
		return;
	}

	var status = getGroupAssetStatus(group.Id);

	var isLive = state.activeMapMode === mapModes.LIVE;
	var messages = [];
	var isDataLoaded = $.Deferred();
	if (!isLive && !state.hasQueriedHistory) {
		// can't populate history when it hasn't been queried
		isDataLoaded.resolve(true);
	} else {
		// TODO only load visible assets...?
		var dataLoader = isLive
			? loadLiveMessagesForAssets(status.assetIds)
			: loadHistoryMessagesForAssets(status.assetIds);
		var dataSource = isLive ? trkData.live.normalizedMessagesByAssetId : trkData.history.normalizedMessagesByAssetId;
		$.when(dataLoader).done(function () {
			_.each(status.assetIds, function (assetId) {
				var deviceMessages = _.filter(dataSource[assetId], getDisplayFilterForEventType("messages"));
				messages = messages.concat(deviceMessages);
			});
			isDataLoaded.resolve(true);
		});
	}

	$.when(isDataLoaded).done(function () {
		createListing(messages, "messages");
		openDialogPanel(
			domNodes.dialogs.assetMessages,
			strings.MESSAGES,
			group,
			false,
			null,
			"group",
			"group-messages",
			openMessagesForGroup
		);
	});
}

export function openMessagesForTrip(trip) {
	if (user.isAnonymous) {
		return;
	}

	var loadMessagesCallback = function () {
		var messages = _.filter(
			trkData.trips.normalizedMessagesByTripId[trip.Id],
			getDisplayFilterForEventType("messages")
		);
		createListing(messages, "messages");
		openDialogPanel(
			domNodes.dialogs.assetMessages,
			strings.MESSAGES,
			trip,
			false,
			undefined,
			"trip",
			"trip-messages",
			openMessagesForTrip
		);
	};

	$.when(loadHistoryMessagesForTrip(trip.Id)).done(loadMessagesCallback);
}

export function openMessagesForJourney(journey) {
	if (user.isAnonymous) {
		return;
	}

	var messages = [];
	var deferreds = [];
	var tripIds = [];

	_.each(journey.Trips, function (trip) {
		tripIds.push(trip.Id);
	});
	_.each(tripIds, function (tripId) {
		deferreds.push(loadHistoryMessagesForTrip(tripId));
	});

	$.when.apply($, deferreds).done(function () {
		_.each(tripIds, function (tripId) {
			var deviceMessages = _.filter(
				trkData.trips.normalizedMessagesByTripId[tripId],
				getDisplayFilterForEventType("messages")
			);
			messages = messages.concat(deviceMessages);
		});
		createListing(messages, "messages");
		openDialogPanel(
			domNodes.dialogs.assetMessages,
			strings.MESSAGES,
			journey,
			false,
			null,
			"journey",
			"journey-messages",
			openMessagesForJourney
		);
	});
}

export function openChatForAsset(asset) {
	if (user.isAnonymous && !options.allowAnonymousMessaging) {
		return;
	}

	var dataSource = trkData.history.normalizedMessagesByAssetId;
	var loadMessageCallback = function (dataSource) {
		return function () {
			var chatMessages = _.filter(dataSource[asset.Id], getDisplayFilterForEventType("chat"));
			createListing(chatMessages, "chat");
			openDialogPanel(
				domNodes.dialogs.assetChat,
				strings.CHAT,
				asset,
				false,
				null,
				"asset",
				"asset-chat",
				openChatForAsset
			);
		};
	};

	if (state.activeMapMode === mapModes.HISTORY) {
		if (state.hasQueriedHistory) {
			$.when(loadHistoryMessagesForAssets([asset.Id])).done(loadMessageCallback(dataSource));
		} else {
			createListing([], "chat");
		}
	} else if (state.activeMapMode === mapModes.LIVE) {
		dataSource = trkData.live.normalizedMessagesByAssetId;
		$.when(loadLiveMessagesForAssets([asset.Id])).done(loadMessageCallback(dataSource));
	}
}

export function openChatForGroup(group) {
	if (user.isAnonymous && !options.allowAnonymousMessaging) {
		return;
	}
	var status = getGroupAssetStatus(group.Id);

	var isLive = state.activeMapMode === mapModes.LIVE;
	var messages = [];
	var isDataLoaded = $.Deferred();
	if (!isLive && !state.hasQueriedHistory) {
		// can't populate history when it hasn't been queried
		isDataLoaded.resolve(true);
	} else {
		var dataLoader = isLive
			? loadLiveMessagesForAssets(status.assetIds)
			: loadHistoryMessagesForAssets(status.assetIds);
		var dataSource = isLive ? trkData.live.normalizedMessagesByAssetId : trkData.history.normalizedMessagesByAssetId;
		$.when(dataLoader).done(function () {
			_.each(status.assetIds, function (assetId) {
				var chatMessages = _.filter(dataSource[assetId], getDisplayFilterForEventType("chat"));
				messages = messages.concat(chatMessages);
			});
			isDataLoaded.resolve(true);
		});
	}

	$.when(isDataLoaded).done(function () {
		createListing(messages, "chat");
		openDialogPanel(
			domNodes.dialogs.assetChat,
			strings.CHAT,
			group,
			false,
			null,
			"group",
			"group-chat",
			openChatForGroup
		);
	});
}

export function openChatForSharedView(sharedView) {
	var loadMessagesCallback = function () {
		var messages = _.filter(trkData.sharedView.normalizedMessages, getDisplayFilterForEventType("chat"));
		createListing(messages, "chat");
		openDialogPanel(
			domNodes.dialogs.assetChat,
			strings.CHAT,
			sharedView,
			false,
			checkForShareViewChange(sharedView),
			"shared-view",
			"shared-view-chat",
			openChatForSharedView
		);
	};

	if (!sharedView.IsMessagingEnabled) {
		// TODO show notice
		loadMessagesCallback();
		return;
	}

	$.when(loadHistoryMessagesForSharedView(sharedView.Id)).done(loadMessagesCallback);
}

export function openChatForTrip(trip) {
	if (user.isAnonymous && !options.allowAnonymousMessaging) {
		return;
	}

	var loadMessagesCallback = function () {
		var messages = _.filter(trkData.trips.normalizedMessagesByTripId[trip.Id], getDisplayFilterForEventType("chat"));
		createListing(messages, "chat");
		openDialogPanel(
			domNodes.dialogs.assetChat,
			strings.CHAT,
			trip,
			false,
			undefined,
			"trip",
			"trip-chat",
			openChatForTrip
		);
	};

	$.when(loadHistoryMessagesForTrip(trip.Id)).done(loadMessagesCallback);
}

export function openChatForJourney(journey) {
	if (user.isAnonymous && !options.allowAnonymousMessaging) {
		return;
	}
	var messages = [];
	var deferreds = [];
	var tripIds = [];

	_.each(journey.Trips, function (trip) {
		tripIds.push(trip.Id);
	});
	_.each(tripIds, function (tripId) {
		deferreds.push(loadHistoryMessagesForTrip(tripId));
	});

	$.when.apply($, deferreds).done(function () {
		_.each(tripIds, function (tripId) {
			var chat = _.filter(trkData.trips.normalizedMessagesByTripId[tripId], getDisplayFilterForEventType("chat"));
			messages = messages.concat(chat);
		});
		createListing(messages, "chat");
		openDialogPanel(
			domNodes.dialogs.assetChat,
			strings.CHAT,
			journey,
			false,
			null,
			"journey",
			"journey-chat",
			openChatForJourney
		);
	});
}

export function loadLiveMessagesForAssets(assetIds) {
	if (state.portalLoadedEpoch === null) {
		return;
	}
	var queryAssetIds = [];
	_.each(assetIds, function (assetId) {
		var assetMessages = trkData.live.messagesByAssetId[assetId];
		if (assetMessages === undefined || assetMessages === null) {
			queryAssetIds.push(assetId);
		}
	});

	var isLoaded = $.Deferred();
	if (queryAssetIds.length === 0) {
		isLoaded.resolve(true);
		return isLoaded;
	}

	// query for the assets' messages for this date range
	var data = {
		assetIds: queryAssetIds,
		fromDate: moment.utc(state.portalLoadedEpoch).format(user.dateFormat),
		toDate: null,
		isUtc: true,
		format: user.dateFormat,
		lang: user.dateCulture,
	};
	var loadingKey = "message-live-" + queryAssetIds[0];
	toggleLoadingMessage(true, loadingKey);
	$.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetMessageHistoryForAssetsByDateRange"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					_.each(result.Assets, function (assetResult) {
						addAssetMessages(assetResult, trkDataGroups.NORMAL_LIVE);
					});
				}
			}
			toggleLoadingMessage(false, loadingKey);
			isLoaded.resolve(true);
		},
		error: function (xhr, status, error) {
			isLoaded.reject(false);
			handleWebServiceError(strings.MSG_MESSAGE_HISTORY_ERROR);
			toggleLoadingMessage(false, loadingKey);
		},
	});
	return isLoaded;
}

export function addAssetMessages(assetResult, forDataGroup, trip) {
	var fromMobileMessages = _.map(assetResult.Inbox, function (item) {
		return mapMessageToCommonFormat(item, true);
	});
	var toMobileMessages = _.map(assetResult.Outbox, function (item) {
		return mapMessageToCommonFormat(item, false);
	});
	var allAssetMessages = fromMobileMessages.concat(toMobileMessages);
	var normalizedMessages = [];

	var dataSource = trkData;
	if (forDataGroup === trkDataGroups.SHARED_VIEW_HISTORY) {
		dataSource = trkData.sharedView;
	}

	_.each(allAssetMessages, function (message) {
		if (dataSource.messagesById[message.Id] === undefined) {
			dataSource.messagesById[message.Id] = normalizeAssetData(message.AssetId, "message", message);
		}
		normalizedMessages.push(dataSource.messagesById[message.Id]);
	});

	if (forDataGroup === trkDataGroups.NORMAL_LIVE) {
		trkData.live.messages.push(assetResult);
		trkData.live.messagesByAssetId[assetResult.AssetId] = assetResult;
		if (normalizedMessages.length > 0) {
			_.each(normalizedMessages, function (item) {
				trkData.live.normalizedMessages.push(item);
			});
			trkData.live.normalizedMessagesByAssetId[assetResult.AssetId] = normalizedMessages;
		}
	} else if (forDataGroup === trkDataGroups.NORMAL_HISTORY) {
		trkData.history.messages.push(assetResult);
		trkData.history.messagesByAssetId[assetResult.AssetId] = assetResult;
		if (normalizedMessages.length > 0) {
			_.each(normalizedMessages, function (item) {
				trkData.history.normalizedMessages.push(item);
			});
			trkData.history.normalizedMessagesByAssetId[assetResult.AssetId] = normalizedMessages;
		}
	} else if (forDataGroup === trkDataGroups.JOURNEY_HISTORY && trip !== undefined) {
		trkData.trips.messages.push(assetResult);
		trkData.trips.messagesByTripId[trip.Id] = assetResult;
		if (normalizedMessages.length > 0) {
			_.each(normalizedMessages, function (item) {
				trkData.trips.normalizedMessages.push(item);
			});
			trkData.trips.normalizedMessagesByTripId[trip.Id] = normalizedMessages;
		}
	} else if (forDataGroup === trkDataGroups.SHARED_VIEW_HISTORY) {
		//trkData.sharedView.messages.push(assetResult);
		trkData.sharedView.normalizedMessages = trkData.sharedView.normalizedMessages.concat(normalizedMessages);
	}
}

function mapMessageToCommonFormat(message, isFromMobile) {
	var item = {
		Id: message.Id,
		AssetId: message.AssetId,
		IsFromMobile: isFromMobile,
		CreatedOn: message.CreatedOn,
		CreatedEpoch: message.CreatedEpoch,
		Type: message.Type,
		TypeId: message.TypeId,
		Text: message.Text,
		Source: message.Source,
		Priority: message.Priority,
		Position: message.Position,
	};

	// TODO .Position a reference to positionsById instead?
	if (isFromMobile) {
		item.IsAcknowledged = message.IsAcknowledged;
		item.AcknowledgedOn = message.AcknowledgedOn;
	} else {
		item.RawText = message.RawText;
		item.SentOn = message.SentOn;
		item.IsSent = message.IsSent;
		item.IsError = message.IsError;
		item.Status = message.Status;
		item.Response = message.Response;
	}
	return item;
}

export function addMessageToAssetMessageCounts(assetId, messageId, deliveryType, isForChat) {
	// TODO support for viewMode - though this method appears to apply to normal_live mode only
	// calling function needs to take shared views into consideration
	console.log("addMessageToAssetMessageCounts: " + assetId + ", " + messageId + ", " + deliveryType + ", " + isForChat);
	assetId = parseInt(assetId);
	messageId = parseInt(messageId);
	if (trkData.live.messageCountsByAssetId[assetId] === undefined) {
		var messageCount = {
			Id: assetId,
			FromMobile: deliveryType === 1 ? 1 : 0,
			FromMobileChats: deliveryType === 1 && isForChat ? 1 : 0,
			FromMobileDevice: deliveryType === 1 && !isForChat ? 1 : 0,
			ToMobile: deliveryType === 0 ? 1 : 0,
			ToMobileChats: deliveryType === 0 && isForChat ? 1 : 0,
			ToMobileDevice: deliveryType === 0 && !isForChat ? 1 : 0,
			FromMobileUnread: deliveryType === 1 ? [messageId] : [],
			ToMobileUnread: deliveryType === 0 ? [messageId] : [],
		};
		trkData.live.messageCounts.push(messageCount);
		trkData.live.messageCountsByAssetId[assetId] = messageCount;
	} else {
		var counts = trkData.live.messageCountsByAssetId[assetId];
		if (deliveryType === 1) {
			counts.FromMobile += 1;
			if (isForChat) {
				counts.FromMobileChats += 1;
			} else {
				counts.FromMobileDevice += 1;
			}
			if (counts.FromMobileUnread === undefined) {
				counts.FromMobileUnread = [messageId];
			} else {
				counts.FromMobileUnread.push(messageId);
			}
		} else if (deliveryType === 0) {
			counts.ToMobile += 1;
			if (isForChat) {
				counts.ToMobileChats += 1;
			} else {
				counts.ToMobileDevice += 1;
			}
			if (counts.ToMobileUnread === undefined) {
				counts.ToMobileUnread = [messageId];
			} else {
				counts.ToMobileUnread.push(messageId);
			}
		}
	}
	updateAssetNotificationTime(assetId, "messages", new Date().getTime());
	updateAssetFunctionBadges(getAssetDataGroupForCurrentViewMode(), assetId);
	updateGroupFunctionBadges(getAssetDataGroupForCurrentViewMode(), [assetId], "asset");
}

function openSendMessageToFenceDialog(fence) {
	openSendMessageDialog(null, null, fence);
}

function openSendMessageToAssetGroupDialog(group) {
	openSendMessageDialog(null, group);
}

export function openSendMessageToAssetDialog(asset) {
	openSendMessageDialog(asset);
}

export function openSendMessageDialog(asset, group, fence) {
	if (asset == null && group == null && fence == null) {
		return;
	}
	trkData.validation.sendMessage.resetForm();
	trkData.validation.sendMessage.currentForm.reset();

	var dialog = $j(domNodes.dialogs.sendMessage);
	$j("#send-message-assets").hide();
	$j("#send-message-assets-container").empty();
	$j("#sendMessageStatus").text("").hide();
	$j("#send-message-beam-templates").hide();
	$j("#send-message-quick").hide();
	$j("#quick-messages").empty();
	$j("#message-text-label").text(strings.TEXT + ":");
	$j("#txtMessageText").addClass("required");
	$j("div.idp-only", dialog).hide();
	$j("#chkMessageNotifyOnRead").prop("checked", false);
	$j(dialog).removeData("assetId");
	$j(dialog).removeData("groupId");
	$j(dialog).removeData("fenceId");
	if (asset != null) {
		var device = findDeviceById(asset.DeviceId);
		if (!device.SupportsMessaging) {
			return;
		}

		$j("#txtMessageDestination").text(asset.Name + " (" + asset.UniqueId + ")");

		// message length indicator based on device of asset
		// todo: standardsize messagemaxsize to be characters... (byte limits aren't much use in the UI)
		// clear previous maxchars
		$j("#txtMessageText").val("").maxChar("destroy");
		if (device.MessageMaxSize > 0 && device.MessageMaxSize < 1500) {
			$j("#txtMessageText").maxChar("init", device.MessageMaxSize, { indicator: "txtMessageTextIndicator" });
		}

		if (asset.DeviceId === devices.BEAM) {
			$j("#send-message-beam-templates").show().find("option:selected").prop("selected", false);
		}

		if (device.SupportsQuickMessages) {
			if (asset.QuickMessageToMobileTemplateId != "") {
				$j("#send-message-quick").show().find("#quick-messages").append($j("<option>").val("").text(""));
				loadQuickMessageTemplate(asset.QuickMessageToMobileTemplateId);
			}
		}

		if (
			$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
			$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP) != -1
		) {
			$j("div.idp-only", dialog).show();
		}
		$j(dialog).data("assetId", asset.Id);

		openDialogPanel(
			domNodes.dialogs.sendMessage,
			strings.SEND_MESSAGE,
			asset,
			false,
			null,
			"asset",
			"send-message",
			openSendMessageToAssetDialog
		);
	} else if (group != null) {
		$j("#txtMessageDestination").text(group.Name);
		$j(dialog).data("groupId", group.Id);
		openDialogPanel(
			domNodes.dialogs.sendMessage,
			strings.SEND_MESSAGE,
			group,
			false,
			null,
			"group",
			"send-message",
			openSendMessageToAssetGroupDialog
		);
	} else if (fence != null) {
		$j("#txtMessageDestination").text(fence.Name);
		$j(dialog).data("fenceId", fence.Id);
		// populate assets
		var assetIds = findAssetIdsInGeofence(fence);
		var cont = $j("#send-message-assets-container");
		var assets = new Array();
		for (var i = 0; i < assetIds.length; i++) {
			var asset = findAssetById(assetIds[i]);
			if (asset == null) continue;
			assets.push(asset);
		}
		assets.sort(sortByName);
		for (var i = 0; i < assets.length; i++) {
			var asset = assets[i];
			var input = $j(
				'<input class="custom-control-input" type="checkbox" name="SendMessageAssetIds" id="SendMessageAssetId' +
					asset.Id +
					'"/>'
			)
				.val(asset.Id)
				.prop("checked", true);
			var label = $j('<label class="custom-control-label" for="SendMessageAssetId' + asset.Id + '"/>').text(asset.Name);
			cont.append($('<div class="custom-control custom-checkbox">').append(input).append(label));
		}
		$j("#send-message-assets").show();

		openDialogPanel(
			domNodes.dialogs.sendMessage,
			strings.SEND_MESSAGE,
			fence,
			false,
			null,
			"fence",
			"send-message",
			openSendMessageToFenceDialog
		);
	}

	document.getElementById("txtMessageText").focus();
}

function loadQuickMessageTemplate(templateId) {
	if (trkData.quickMessages[templateId] != null) {
		renderQuickMessageTemplate(templateId);
	} else {
		toggleLoadingMessage(true, "quick-messages");
		var data = { id: templateId };
		return $j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/GetQuickMessageToMobileTemplate"),
			data: JSON.stringify(data),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				if (msg.d) {
					var result = msg.d;
					if (result.Success == true) {
						trkData.quickMessages[templateId] = result.Messages;
						renderQuickMessageTemplate(templateId);
					} else {
						handleWebServiceError("");
					}
				}
				toggleLoadingMessage(false, "quick-messages");
			},
			error: function (xhr, status, error) {
				handleWebServiceError("");
				toggleLoadingMessage(false, "quick-messages");
				// btn.prop("disabled", false);
			},
		});
	}
}

function renderQuickMessageTemplate(templateId) {
	var messages = trkData.quickMessages[templateId];
	if (messages == null) return;
	var cont = $j("#quick-messages");
	for (var i = 0; i < messages.length; i++) {
		var message = messages[i];
		cont.append($j("<option>").val(message.Id).text(message.Text));
	}
}
