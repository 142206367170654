// See also notifications.js

import trkData from "./data.js";
import { NOTIFICATION_TYPES, NOTIFICATION_PREFIXES } from "./notifications.js";
import domNodes from "./domNodes";
import state from "./state.js";
import { mapModes } from "./const.js";
import {
	getNotificationOpacityForTime,
	updateTimeBasedNotificationIndicatorsForNode,
	updateTimeBasedNotificationIndicatorsForGroup,
} from "./notifications.js";

import _ from "lodash";

export function updateAssetNotificationTime(assetId, type, value) {
	if (trkData.live.notificationTimesByAssetId[assetId] === undefined) {
		trkData.live.notificationTimesByAssetId[assetId] = {
			positions: null,
			events: null,
			alerts: null,
			status: null,
			messages: null,
		};
	}

	if (type !== "messages") {
		value *= 1000; // epoch seconds to epoch millis for consistency
	}

	var currentValue = trkData.live.notificationTimesByAssetId[assetId][type];
	if (currentValue === null || value > currentValue) {
		trkData.live.notificationTimesByAssetId[assetId][type] = value;
		// todo: update groups here as well, but make sure the above is not in a loop
	}
}

export function updateTimeBasedNotificationIndicatorsForAsset(asset) {
	if (!domNodes.assets[asset.Id]) {
		return;
	}

	var notificationTypes = NOTIFICATION_TYPES;
	var indicators = [];
	domNodes.assets[asset.Id].forEach(function (assetNode) {
		indicators.push(assetNode.querySelector(".notifications"));
	});

	if (indicators.length === 0) {
		return;
	}

	notificationTypes.forEach(function (itemType) {
		var recentType = "recent-" + itemType;

		if (state.activeMapMode !== mapModes.LIVE) {
			// history mode does not support fading icons
			indicators.forEach(function (item) {
				// remove any opacity classes from history mode
				if (!item.classList.contains(recentType + "-no")) {
					NOTIFICATION_PREFIXES[itemType].classes.forEach(function (cls) {
						if (item.classList.contains(cls)) {
							item.classList.remove(cls);
						}
					});
					item.classList.add(recentType + "-no");
				}
			});
			return;
		}

		// add/upate indicators for LIVE mode
		var opacity = null;
		if (trkData.live.notificationTimesByAssetId[asset.Id] !== undefined) {
			opacity = getNotificationOpacityForTime(trkData.live.notificationTimesByAssetId[asset.Id][itemType]);
		}
		//var classNames = [recentType];

		//if (opacity !== null) {
		//    classNames.push(NOTIFICATION_PREFIXES[type].prefix + '-' + opacity);
		//}

		indicators.forEach(function (item) {
			updateTimeBasedNotificationIndicatorsForNode(item, opacity, itemType);
		});
	});
}

export function updateTimeBasedNotificationIndicators() {
	if (state.activeMapMode !== mapModes.LIVE) {
		return;
	}

	// todo: profile having individual setIntervals on active
	// assets instead of this global query/update
	// each interval would be responsible for caching its dom element
	// and switching views should clear all intervals
	// the intervals should be attached to assets
	// the interval would be cleared after the indicator is no longer relevant

	trkData.assets.forEach(function (asset) {
		//updatePositionTimeIndicatorForAsset(asset);
		updateTimeBasedNotificationIndicatorsForAsset(asset);
	});
	var groupIds = _.map(trkData.groups, "Id");
	groupIds.push("all-assets");
	groupIds.forEach(function (groupId) {
		updateTimeBasedNotificationIndicatorsForGroup(groupId);
	});
}
