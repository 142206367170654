import trkData from "./data.js";
import { viewModes } from "./const.js";
import { normalGleoSymbols, sharedViewGleoSymbols } from "./map-gleo.js";

import L from "leaflet";

import GleoSymbol from "./gleo/src/symbols/Symbol.mjs";
import Loader from "./gleo/src/loaders/Loader.mjs";
import SymbolGroup from "./gleo/src/loaders/SymbolGroup.mjs";

// Adds the given `item` to the map.
// If `layer` is a L.LayerGroup (e.g. `trkData.mapLayers.other`), adds the item
// to that group.
// Possible groups (as defined in trkData.mapLayers) are 'positions', 'fences',
// 'places', 'other', 'waypoints'.
// Otherwise, adds the item to the `normal` L.LayerGroup or to the `sharedView`
// layergroup, depending on the `viewMode` parameter (defaults to `normal` if
// not given).

export function addItemToMap(item, layer, viewMode) {
	if (item === undefined || item === null) {
		return;
	}

	if (item instanceof L.Layer) {
		if (layer === undefined || layer === null) {
			const baseLayer = viewMode === viewModes.SHARED_VIEW ? trkData.mapLayers.sharedView : trkData.mapLayers.normal;

			if (!baseLayer.hasLayer(item)) {
				baseLayer.addLayer(item);
			}
		} else {
			if (!layer.hasLayer(item)) {
				layer.addLayer(item);
			}
		}
	} else if (item instanceof GleoSymbol || item instanceof Loader) {
		// console.log('add gleosymbol', layer);

		if (layer === undefined || layer === null || !layer instanceof SymbolGroup) {
			const baseLayer = viewMode === viewModes.SHARED_VIEW ? sharedViewGleoSymbols : normalGleoSymbols;

			if (!baseLayer.has(item)) {
				baseLayer.add(item);
			}
		} else {
			if (!layer.symbols.includes(item)) {
				layer.add(item);
			}
		}
	} else {
		throw new Error("Item must be either a Leaflet Layer or a Gleo Symbol");
	}
}


// As the singular `addItemToMap`, but adds several items at the same time.
// Assumes items in the input array are either all L.Layers or GleoSymbols
// (i.e. not mixed Leaflet layers and Gleo symbols in the same call)
export function addItemsToMap(items, layer, viewMode) {
	if (items === undefined || items === null|| items.length == 0) {
		return;
	}

	if (items[0] instanceof L.Layer) {
		items.forEach(item=> addItemToMap(item));
	} else if (items[0] instanceof GleoSymbol || items[0] instanceof Loader) {
		if (layer === undefined || layer === null || !layer instanceof SymbolGroup) {
			const baseLayer = viewMode === viewModes.SHARED_VIEW ? sharedViewGleoSymbols : normalGleoSymbols;
			baseLayer.multiAdd(items);
		} else {
			layer.multiAdd(items);
		}
	} else {
		throw new Error("Item must be either a Leaflet Layer or a Gleo Symbol");
	}
}

export function removeItemFromMap(item, layer, viewMode) {
	if (item === undefined || item === null) {
		return;
	}

	if (item instanceof L.Layer) {
		if (layer === undefined || layer === null) {
			const baseLayer = viewMode === viewModes.SHARED_VIEW ? trkData.mapLayers.sharedView : trkData.mapLayers.normal;

			baseLayer.removeLayer(item);
		} else {
			layer.removeLayer(item);
		}
	} else if (item instanceof GleoSymbol || item instanceof Loader) {
		// console.log('remove gleosymbol', layer);

		if (layer === undefined || layer === null || !layer instanceof SymbolGroup) {
			const baseLayer = viewMode === viewModes.SHARED_VIEW ? sharedViewGleoSymbols : normalGleoSymbols;

			if (baseLayer.has(item)) {
				baseLayer.remove(item);
			}
		} else {
			if (layer.has(item)) {
				layer.remove(item);
			}
		}
	} else {
		throw new Error("Item must be either a Leaflet Layer or a Gleo Symbol");
	}
}
