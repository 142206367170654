import strings from "./strings.js";
import options from "./options.js";
import user from "./user.js";
import domNodes from "./domNodes.js";
import { panels, mapModes } from "./const.js";
import { hidePrimaryPanel, closeSecondaryPanel, openPrimaryPanel, changeActivePanel } from "./panel.js";
import { findGroupById } from "./asset-group.js";
import { wrapUrl } from "./wrapurl.js";
import { switchMapMode } from "./map-base.js";
import { openPositionsForGroup } from "./positions.js";
import { openAssetGroupSettingsPanel } from "./panel-settings.js";

import $ from "jquery";
import _ from "lodash";
import introJs from "intro.js"; // from ../intro.min.js, see https://introjs.com/

const walkthrough = {
	isLoaded: false,
	url: wrapUrl("track/dialogs/walkthrough"),
	intro: null,
	currentStep: null,
	previousStep: null,

	initialize: function () {
		walkthrough.intro = introJs();
		var introOptions = {
			tooltipPosition: "auto",
			showStepNumbers: false,
			positionPrecedence: ["right", "left", "bottom", "top"],
		};

		var steps = [
			{
				intro: formatIntro(strings.WT_INTRO_HEADER, strings.WT_INTRO_CONTENT),
				onbeforechange: function () {
					changeActivePanel(panels.ASSETS);
				},
			},
			{
				element: "#menu-help-button",
				intro: formatIntro(strings.WT_REPLAY_HEADER, strings.WT_REPLAY_CONTENT, {
					icon: '<svg><use xlink:href="/content/svg/tracking.svg?v=15#question-circle-solid"></use></svg>',
				}),
			},
			{
				intro: formatIntro(strings.WT_BEGIN_HEADER, strings.WT_BEGIN_CONTENT),
			},
			{
				element: "#nav-utility",
				intro: formatIntro(strings.WT_NAVIGATION_HEADER, strings.WT_MAIN_NAVIGATION_CONTENT, {
					icon: '<svg><use xlink:href="/content/svg/tracking.svg?v=15#bars"></use></svg>',
				}),
				onleavestep: function () {},
			},
			{
				element: "#menu-help-button",
				intro: formatIntro(strings.WT_HELP_HEADER, strings.WT_HELP_CONTENT, {
					icon: '<svg><use xlink:href="/content/svg/tracking.svg?v=15#question-circle-solid"></use></svg>',
				}),
				onbeforechange: function () {
					$("#menu-help-button").dropdown("show");
				},
				onleavestep: function () {
					$("#menu-help-button").dropdown("hide");
				},
			},
			{
				element: "#menu-user-button",
				intro: formatIntro(strings.WT_USER_HEADER, strings.WT_USER_CONTENT, {
					icon: '<svg><use xlink:href="/content/svg/tracking.svg?v=15#user-alt-solid"></use></svg>',
				}),
				onbeforechange: function () {
					$("#menu-user-button").dropdown("show");
				},
				onleavestep: function () {
					$("#menu-user-button").dropdown("hide");
				},
			},
			{
				element: "#nav-primary-container",
				intro: formatIntro(strings.WT_NAVIGATION_HEADER, strings.WT_NAVIGATION_CONTENT),
				onbeforechange: function () {
					// ensure nav menu is open
					if (!domNodes.nav.toggle.classList.contains("is-active")) {
						openPrimaryPanel();
						openPrimaryNavigation();
					}
				},
			},
			{
				element: "#panel-primary",
				intro: formatIntro(strings.WT_LIST_PANEL_HEADER, strings.WT_LIST_PANEL_CONTENT),
				onbeforechange: function () {
					openPrimaryPanel();
					switchMapMode(mapModes.LIVE);
				},
			},
			{
				element: "#asset-filter-options",
				intro: formatIntro(strings.WT_QUICK_ACTIONS_HEADER, strings.WT_QUICK_ACTIONS_CONTENT, {
					icon: '<svg><use xlink:href="/content/svg/tracking.svg?v=15#ellipsis-v"></use></svg>',
				}),
				onbeforechange: function () {
					openPrimaryPanel();
				},
			},
			{
				// requires searching/filtering feature
				element: "#filter-assets",
				intro: formatIntro(strings.WT_SEARCH_BAR_HEADER, strings.WT_SEARCH_BAR_CONTENT),
				onbeforechange: function () {
					openPrimaryPanel();
				},
			},
			//{
			//    element: '#filter-history-range',
			//    intro: formatIntro(strings.WT_DATE_RANGE_HEADER, strings.WT_DATE_RANGE_CONTENT),
			//    onbeforechange: function () {
			//        openPrimaryPanel();
			//        switchMapMode(false);
			//    }
			//},
			{
				element: "#group-all-assets .group-header",
				intro: formatIntro(strings.WT_GROUPS_HEADER, strings.WT_GROUPS_CONTENT),
				onbeforechange: function () {
					switchMapMode(mapModes.LIVE);
					openPrimaryPanel();
				},
			},
			{
				element: "#group-all-assets .showhide",
				intro: formatIntro(strings.WT_VISIBILITY_HEADER, strings.WT_VISIBILITY_CONTENT, {
					icon: '<svg><use xlink:href="/content/svg/tracking.svg?v=15#visible"></use></svg>',
				}),
				onbeforechange: function () {
					openPrimaryPanel();
				},
			},
			{
				element: "#group-all-assets .notifications",
				intro: formatIntro(strings.WT_NOTIFICATION_HEADER, strings.WT_NOTIFICATION_CONTENT),
				onbeforechange: function () {
					openPrimaryPanel();
					document
						.getElementById("group-all-assets")
						.querySelector(".group-info .notifications")
						.classList.add("light-up");
				},
				onleavestep: function () {
					document
						.getElementById("group-all-assets")
						.querySelector(".group-info .notifications")
						.classList.remove("light-up");
				},
			},
			{
				element: "#panel-secondary",
				intro: formatIntro(strings.WT_DETAILS_PANEL_HEADER, strings.WT_DETAILS_PANEL_CONTENT),
				onbeforechange: function () {
					var group = findGroupById("all-assets");
					openAssetGroupSettingsPanel(group);
				},
			},
			{
				element: "#panel-secondary-item-options",
				intro: formatIntro(strings.WT_JUMP_NAVIGATION_HEADER, strings.WT_JUMP_NAVIGATION_CONTENT),
				onbeforechange: function () {
					var group = findGroupById("all-assets");
					openAssetGroupSettingsPanel(group);
				},
			},
			{
				element: "#nav-options-tab",
				intro: formatIntro(strings.WT_OPTIONS_TAB_HEADER, strings.WT_OPTIONS_TAB_CONTENT, {
					icon: '<svg><use xlink:href="/content/svg/tracking.svg?v=15#cogs"></use></svg>',
				}),
				onbeforechange: function () {
					var group = findGroupById("all-assets");
					openAssetGroupSettingsPanel(group);
				},
			},
			{
				element: "#nav-positions-tab",
				intro: formatIntro(strings.WT_NOTIFICATION_TABS_HEADER, strings.WT_NOTIFICATION_TABS_CONTENT),
				onbeforechange: function () {
					var group = findGroupById("all-assets");
					openPositionsForGroup(group);
				},
			},
			{
				//element: '#main_map',
				intro: formatIntro(strings.WT_MAP_VIEW_HEADER, strings.WT_MAP_VIEW_CONTENT),
				onbeforechange: function () {
					closeSecondaryPanel();
					if (window.screen.width <= 768) {
						hidePrimaryPanel();
					}
				},
			},
			{
				element: "#map-mode",
				intro: formatIntro(strings.WT_MAP_MODE_HEADER, strings.WT_MAP_MODE_CONTENT),
				onbeforechange: function () {
					// ensure map is visible
					if (window.screen.width <= 768) {
						hidePrimaryPanel();
					}
				},
			},
			{
				element: "#map-mode",
				intro: formatIntro(strings.WT_LIVE_MODE_HEADER, strings.WT_LIVE_MODE_CONTENT),
				onbeforechange: function () {
					// ensure nav menu is open
					closeSecondaryPanel();
					switchMapMode(mapModes.LIVE);
					if (!domNodes.nav.toggle.classList.contains("is-active")) {
						openPrimaryPanel();
						openPrimaryNavigation();
					}
				},
			},
			{
				element: "#map-mode",
				intro: formatIntro(strings.WT_HISTORY_MODE_HEADER, strings.WT_HISTORY_MODE_CONTENT),
				onbeforechange: function () {
					// ensure nav menu is open
					closeSecondaryPanel();
					switchMapMode(mapModes.HISTORY);
					if (!domNodes.nav.toggle.classList.contains("is-active")) {
						openPrimaryPanel();
						openPrimaryNavigation();
					}
				},
				onleavestep: function () {
					closePrimaryNavigation();
				},
			},
			{
				element: "#map-tools",
				intro: formatIntro(strings.WT_MAP_TOOLS_HEADER, strings.WT_MAP_TOOLS_CONTENT),
				onbeforechange: function () {
					// ensure map is visible
					if (window.screen.width <= 768) {
						hidePrimaryPanel();
					}
				},
			},
			{
				intro: formatIntro(strings.WT_START_TRACKING_HEADER, strings.WT_START_TRACKING_CONTENT, {
					icon: '<svg><use xlink:href="/content/svg/tracking.svg?v=15#question-circle-solid"></use></svg>',
				}),
				onbeforechange: function () {
					if (options.defaultMode === "live") {
						switchMapMode(mapModes.LIVE);
					} else {
						switchMapMode(mapModes.HISTORY);
					}
				},
			},
		];

		// remove unnecessary steps in reverse order to not change the ordering
		if (options.enabledFeatures.indexOf("UI_FILTERING_SEARCHING_SORTING") === -1) {
			steps.splice(9, 1); // search bar
		}

		if (user.isAnonymous) {
			steps.splice(5, 1); // user menu
		}

		walkthrough.intro.setOption("steps", steps);
		walkthrough.intro.setOptions(introOptions);

		// todo: remove steps based on user permissions, availability of panels...

		_.each(["onchange", "onbeforechange"], function (event) {
			// add some per-step event handler support
			walkthrough.intro[event](function () {
				var steps = this._options.steps;
				var currentStep = this._currentStep;
				if (event === "onchange") {
					walkthrough.currentStep = currentStep;
				} else if (event === "onbeforechange" && walkthrough.previousStep !== null) {
					if (_.isFunction(steps[walkthrough.previousStep]["onleavestep"])) {
						steps[walkthrough.previousStep]["onleavestep"](this);
					}
				}
				if (_.isFunction(steps[currentStep][event])) {
					steps[currentStep][event](this);
				}
				if (event === "onchange") {
					if (walkthrough.currentStep !== null) {
						walkthrough.previousStep = currentStep;
					}
				}
			});
		});
		walkthrough.intro.onexit(function () {
			var steps = this._options.steps;
			if (steps[walkthrough.currentStep] !== undefined) {
				if (_.isFunction(steps[walkthrough.currentStep]["onleavestep"])) {
					steps[walkthrough.currentStep]["onleavestep"](this);
				}
			}
			walkthrough.previousStep = null;
			walkthrough.currentStep = null;

			if (user.showWalkthrough) {
				$.ajax({
					type: "POST",
					url: wrapUrl("/services/GPSService.asmx/ShownWalkthrough"),
					data: JSON.stringify({}),
					contentType: "application/json; charset=utf-8",
					dataType: "json",
				});
				user.showWalkthrough = false;
			}
			if (options.defaultMode === "live") {
				switchMapMode(mapModes.LIVE);
			} else {
				switchMapMode(mapModes.HISTORY);
			}
		});

		$(document).on("click", "#walkthrough-start", function (e) {
			e.preventDefault();
			walkthrough.intro.start();
		});
	},
};

export default walkthrough;

function formatIntro(header, content, subs) {
	for (var sub in subs) {
		var placeholder = "{" + sub + "}";
		header = header.replace(placeholder, subs[sub]);
		content = content.replace(placeholder, subs[sub]);
	}
	return "<h6>" + header + "</h6><p>" + content + "</p>";
}

function openPrimaryNavigation() {
	domNodes.nav.toggle.classList.add("is-active");
	domNodes.nav.primary.classList.remove("is-closed"); // expands it to full
}

export function closePrimaryNavigation() {
	domNodes.nav.toggle.classList.remove("is-active");
	domNodes.nav.primary.classList.add("is-closed");
}
