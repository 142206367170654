import strings from "./strings.js";
import trkData from "./data.js";
import state from "./state.js";
import options from "./options.js";
import domNodes from "./domNodes.js";
import log from "./log.js";
import user from "./user.js";
import { mapModes } from "./const.js";
import { findAssetById } from "./assets.js";
import { wrapUrl } from "./wrapurl.js";
import { handleWebServiceError } from "./ajax.js";
import { findGroupById, openEventsForGroup, openStatusForGroup, openAlertsForGroup } from "./asset-group.js";
import { checkMarkerBounce } from "./asset-live.js";
import { normalizeAssetData } from "./assets.js";
import { updateGroupFunctionBadges, updateAssetFunctionBadges } from "./badges.js";
import { hasAVLAssets } from "./avl.js";
import { getAssetDataGroupForCurrentViewMode } from "./map-viewmode.js";
import { openDialogPanel } from "./panel-nav.js";
import { createListing, defaultListItemSort } from "./item-listing.js";
import { openStatusForAsset } from "./asset-state.js";
import { openAlertsForAsset } from "./asset-alerts.js";
import { createMarkerPath } from "./marker-path.js";
import { getPositionLinkForEvent, mapPositionForEvent } from "./positions.js";
import { getGroupAssetStatus } from "./asset-group.js";
import { htmlEscape } from "./dom-util.js";
import { createIconForAssetEvent } from "./asset-state.js";
import { findPlaceByUniqueKey } from "./place.js";
import { openActivityForAsset, openActivityForGroup } from "./activities.js";
import { getDisplayFilterForEventType } from "./display-filter.js";
import { updateAssetNotificationTime } from "./asset-notification.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";

export function addAssetEvents(events, forMapMode) {
	var addedEvents = [];

	for (var i = 0; i < events.length; i++) {
		var item = events[i];

		if (item.Event != null) {
			item = item.Event;
		}

		if (events[i].Position != null) {
			// what
			item.Position = events[i].Position;
		}

		// bounce the existing position when a new bounce event comes in without a position attached
		if (
			state.activeMapMode === mapModes.LIVE &&
			options.bounceOnEvents.indexOf(item.Type) !== -1 &&
			item.Position == null
		) {
			var asset = findAssetById(item.AssetId);
			var assetLivePosition = trkData.live.latestPositionsByAssetId[asset.Id];
			if (assetLivePosition !== undefined && assetLivePosition.Position != null) {
				var positionMarker = trkData.live.markersByPositionId[assetLivePosition.Position.Id];
				if (positionMarker !== undefined) {
					checkMarkerBounce(positionMarker);
				}
			}
		}

		if (trkData.eventsById[item.Id] === undefined) {
			trkData.eventsById[item.Id] = normalizeAssetData(item.AssetId, "event", item);
		}

		// only add an item if its not already in the list
		if (forMapMode === mapModes.LIVE) {
			if (trkData.live.normalizedEventIds[item.Id] !== undefined) {
				continue;
			}
			trkData.live.normalizedEventIds[item.Id] = true;
			trkData.live.events.push(item);
			trkData.live.eventIds.push(item.Id);
			trkData.live.normalizedEvents.push(trkData.eventsById[item.Id]);

			var thisId = parseInt(item.Id);
			if (trkData.lastEventId === null) {
				trkData.lastEventId = thisId;
			}
			if (trkData.lastEventId < thisId) {
				trkData.lastEventId = thisId;
			}

			updateAssetEventNotificationTime(item);
		} else if (forMapMode === mapModes.HISTORY) {
			if (trkData.history.normalizedEventIds[item.Id] !== undefined) {
				continue;
			}
			trkData.history.events.push(item);
			trkData.history.normalizedEvents.push(trkData.eventsById[item.Id]);
			trkData.history.normalizedEventIds[item.Id] = true;
		}

		addedEvents.push(item);
	}

	if (addedEvents.length > 0) {
		if (forMapMode === mapModes.LIVE) {
			trkData.live.normalizedEventsByAssetId = _.groupBy(trkData.live.normalizedEvents, "AssetId");
		} else if (forMapMode === mapModes.HISTORY) {
			trkData.history.normalizedEventsByAssetId = _.groupBy(trkData.history.normalizedEvents, "AssetId");
		}
	}

	var assetIdsUpdated = _.chain(addedEvents).map("AssetId").uniq().value();
	var assetsUpdated = [];
	if (state.activeMapMode === mapModes.LIVE) {
		_.each(assetIdsUpdated, function (assetId) {
			updateAssetFunctionBadges(getAssetDataGroupForCurrentViewMode(), assetId);
			assetsUpdated.push(findAssetById(assetId));
		});
		updateGroupFunctionBadges(getAssetDataGroupForCurrentViewMode(), assetIdsUpdated, "asset");
		log("Update event/status/alert notifications for assets: " + assetIdsUpdated.join(", "));
	}

	// refresh the events, alerts, or status dialogs if it is currently opened for a related asset or group
	if (
		domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
		domNodes.panels.secondary.getAttribute("data-item-type") === "assets" &&
		document.getElementById("dialog-functions").querySelector(".dialog") === domNodes.dialogs.assetEvents
	) {
		// todo: only append to the existing listing, don't recreate
		var assetId = parseInt(domNodes.panels.secondary.getAttribute("data-item-id"));
		if (assetIdsUpdated.indexOf(assetId) !== -1) {
			var updatedAsset = findAssetById(assetId);
			openEventsForAsset(updatedAsset);
		}
	} else if (
		domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
		domNodes.panels.secondary.getAttribute("data-item-type") === "groups" &&
		document.getElementById("dialog-functions").querySelector(".dialog") === domNodes.dialogs.assetEvents
	) {
		// todo: only append to the existing listing, don't recreate
		var groupId = domNodes.panels.secondary.getAttribute("data-item-id");
		var assetInGroup = _.find(assetsUpdated, function (asset) {
			return asset.ParentGroupIds.indexOf(groupId) !== -1;
		});
		if (assetInGroup !== undefined) {
			openEventsForGroup(findGroupById(groupId));
		}
	} else if (
		domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
		domNodes.panels.secondary.getAttribute("data-item-type") === "assets" &&
		document.getElementById("dialog-functions").querySelector(".dialog") === domNodes.dialogs.assetStatus
	) {
		// todo: only append to the existing listing, don't recreate
		var assetId = parseInt(domNodes.panels.secondary.getAttribute("data-item-id"));
		if (assetIdsUpdated.indexOf(assetId) !== -1) {
			var updatedAsset = findAssetById(assetId);
			openStatusForAsset(updatedAsset);
		}
	} else if (
		domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
		domNodes.panels.secondary.getAttribute("data-item-type") === "groups" &&
		document.getElementById("dialog-functions").querySelector(".dialog") === domNodes.dialogs.assetStatus
	) {
		// todo: only append to the existing listing, don't recreate
		var groupId = domNodes.panels.secondary.getAttribute("data-item-id");
		var assetInGroup = _.find(assetsUpdated, function (asset) {
			return asset.ParentGroupIds.indexOf(groupId) !== -1;
		});
		if (assetInGroup !== undefined) {
			openStatusForGroup(findGroupById(groupId));
		}
	} else if (
		domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
		domNodes.panels.secondary.getAttribute("data-item-type") === "assets" &&
		document.getElementById("dialog-functions").querySelector(".dialog") === domNodes.dialogs.assetAlerts
	) {
		// todo: only append to the existing listing, don't recreate
		var assetId = parseInt(domNodes.panels.secondary.getAttribute("data-item-id"));
		if (assetIdsUpdated.indexOf(assetId) !== -1) {
			var updatedAsset = findAssetById(assetId);
			openAlertsForAsset(updatedAsset);
		}
	} else if (
		domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
		domNodes.panels.secondary.getAttribute("data-item-type") === "groups" &&
		document.getElementById("dialog-functions").querySelector(".dialog") === domNodes.dialogs.assetAlerts
	) {
		// todo: only append to the existing listing, don't recreate
		var groupId = domNodes.panels.secondary.getAttribute("data-item-id");
		var assetInGroup = _.find(assetsUpdated, function (asset) {
			return asset.ParentGroupIds.indexOf(groupId) !== -1;
		});
		if (assetInGroup !== undefined) {
			openAlertsForGroup(findGroupById(groupId));
		}
	} else if (
		domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
		domNodes.panels.secondary.getAttribute("data-item-type") === "groups" &&
		document.getElementById("dialog-functions").querySelector(".dialog") === domNodes.dialogs.assetActivity
	) {
		// todo: only append to the existing listing, don't recreate
		var groupId = domNodes.panels.secondary.getAttribute("data-item-id");
		var assetInGroup = _.find(assetsUpdated, function (asset) {
			return asset.ParentGroupIds.indexOf(groupId) !== -1;
		});
		if (assetInGroup !== undefined) {
			openActivityForGroup(findGroupById(groupId));
		}
	} else if (
		domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
		domNodes.panels.secondary.getAttribute("data-item-type") === "assets" &&
		document.getElementById("dialog-functions").querySelector(".dialog") === domNodes.dialogs.assetActivity
	) {
		// todo: only append to the existing listing, don't recreate
		var assetId = parseInt(domNodes.panels.secondary.getAttribute("data-item-id"));
		if (assetIdsUpdated.indexOf(assetId) !== -1) {
			var updatedAsset = findAssetById(assetId);
			openActivityForAsset(updatedAsset);
		}
	}

	if (state.activeMapMode === forMapMode) {
		addEventsToActiveNotificationList(addedEvents); // todo: seems unused
		//addAssetEventsToPanelListing(addedEvents);
	}
}

///
function addAssetEventsToPanelListing(events) {
	// unused
	if ($j("#event-data").data("init") !== true) {
		return;
	}

	// todo: only update the table if the shown events have actually changed
	var eventTable = $("#event-data").DataTable();

	var tableData = [];
	_.each(events, function (item) {
		var row = mapAssetEventToTableRow(item, false);
		if (row !== undefined && row !== null) {
			tableData.push(row);
		}
	});

	eventTable.rows.add(tableData).draw();
	trkData.pendingEvents = [];
}

export function queryLatestEvents(defaultEvent) {
	if (trkData.pending.events) {
		return;
	}

	let eventId;

	if (trkData.lastEventId === null) {
		if (defaultEvent === undefined || defaultEvent === null) {
			return;
		}
		eventId = defaultEvent;
	} else {
		eventId = trkData.lastEventId;
	}

	trkData.pending.events = true;
	var dataPost = { eventId: eventId };
	$j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetLatestEvents"),
		data: JSON.stringify(dataPost),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			trkData.pending.events = false;
			if (msg.d) {
				var events = msg.d;
				var eventsBeforeCount = trkData.live.events.length;
				if (events.length > 0) {
					log(events.length + " events received.");
				}
				addAssetEvents(events, mapModes.LIVE);
				//if (trkData.live.events.length !== eventsBeforeCount) {
				//    // flash update notification to indicate new events added
				//    var currBg = $j('#event-panel-tab-events').css('background-color');
				//    $j('#event-panel-tab-events').stop().css('background-color', '#ffff9c').animate({ backgroundColor: currBg }, 3000, function () {
				//        $j('#event-panel-tab-events').css('background-color', '');
				//    });
				//    $j('#event-control-btn').stop().css('background-color', '#ffff9c').animate({ backgroundColor: '#ffffff' }, 3000);
				//}
			}
		},
		error: function (xhr, status, error) {
			trkData.pending.events = false;
			handleWebServiceError(strings.MSG_EVENTS_ERROR);
		},
	});
}

export function populateEvents(events) {
	$("#event-data").DataTable({
		destroy: true,
		filter: true,
		info: true,
		jQueryUI: false,
		autoWidth: false,
		lengthChange: false,
		paging: true,
		pagingType: "full_numbers",
		deferRender: true,
		processing: false,
		order: [[4, "desc"]],
		columns: [
			{ sortable: true }, // Asset
			{ sortable: true }, // Event Type
			{ sortable: false }, // Position/lat/lng
			{ sortable: false, className: "break-text" }, // Details/link
			{ width: "75px" }, // Time
			{ visible: false }, // AssetId (hidden)
			{ visible: false }, // Event Type Id
			{ visible: false }, // Is Time Accurate
			{ visible: false }, // Event Id
		],
		dom: '<"H"lfr>t<"F"ip>',
		language: strings.DATATABLE,
		initComplete: function (oSettings, json) {
			console.log("init complete for populateEvents");
			var api = this.api();
			$j("#event-data").data("init", true);
			api.clear();

			addAssetEventsToPanelListing(events);
			//addAssetEvents(events);

			// move clear events button to toolbar
			var filterDiv = $j("#event-data_filter").parent().eq(0);
			var btn = $j('<button class="btn btn-secondary btn-sm" />')
				.attr("id", "ClearEvents")
				.text(strings.BUTTON_CLEAR_EVENTS);
			if (state.activeMapMode !== mapModes.LIVE) {
				btn.attr("disabled", true);
			}
			btn.appendTo(filterDiv);

			// create filter events link
			var filter = $j("<a />")
				.attr("id", "FilterEvents")
				.attr("href", "#")
				.text(strings.EVENTS_FILTER)
				.appendTo(filterDiv);
			$j("#event-filter-types").appendTo(filter);

			createFilteredEventsList();
		},
		rowCallback: function (row, data, index) {
			if (data[7] != null && data[7] == false) {
				$j("td:eq(4)", row).addClass("inaccurate");
			}
		},
		drawCallback: function (oSettings) {
			var api = this.api();
			var rowCount = api.rows({ search: "applied" }).eq(0).length;
			if (rowCount > 0) {
				$j("#event-control-btn a .badge,#event-panel-tab-events .badge")
					.text(rowCount)
					.addClass("active")
					.removeClass("inactive");
			} else {
				$j("#event-control-btn a .badge,#event-panel-tab-events .badge")
					.text(rowCount)
					.removeClass("active")
					.addClass("inactive");
			}
		},
	});
}

export function createFilteredEventsList() {
	// in live mode, you can filter all events
	// in history mode, you can only filter events that were submitted

	// update filtered list based on event data (this is good for the history view but not the live view)
	var includedEventIds = [];
	if (state.activeMapMode === mapModes.LIVE) {
		includedEventIds = _.uniq(
			_.map(trkData.live.normalizedEvents, function (item) {
				return item.Event.Type;
			})
		);
	} else if (state.activeMapMode === mapModes.HISTORY) {
		includedEventIds = _.uniq(
			_.map(trkData.history.normalizedEvents, function (item) {
				return item.Event.Type;
			})
		);
	}

	var cont = document.getElementById("event-filter-items");
	// empty it first!
	cont.innerHTML = "";
	if (state.activeMapMode === mapModes.LIVE) {
		cont.classList.add("multiple");
	} else {
		cont.classList.remove("multiple");
	}
	var eventFilters = trkData.live.eventFilters;
	if (state.activeMapMode === mapModes.HISTORY) {
		eventFilters = trkData.history.eventFilters;
	}
	var isAllChecked = _.includes(eventFilters, "all");
	var filterOptions = document.createDocumentFragment();
	var filterAll = document.createElement("div");
	filterAll.className = "col-md-4";
	var filterAllDiv = document.createElement("div");
	filterAllDiv.className = "custom-control custom-checkbox";
	var filterAllCheckbox = document.createElement("input");
	filterAllCheckbox.className = "custom-control-input";
	filterAllCheckbox.setAttribute("type", "checkbox");
	filterAllCheckbox.setAttribute("id", "event-filter-all");
	filterAllCheckbox.setAttribute("name", "event-filter");
	if (isAllChecked) {
		filterAllCheckbox.setAttribute("checked", "checked");
	}
	var filterAllLabel = document.createElement("label");
	filterAllLabel.className = "custom-control-label";
	filterAllLabel.setAttribute("for", "event-filter-all");
	filterAllLabel.textContent = strings.EVENTS_ALL;
	filterAllDiv.appendChild(filterAllCheckbox);
	filterAllDiv.appendChild(filterAllLabel);
	filterAll.appendChild(filterAllDiv);
	filterOptions.appendChild(filterAll);
	var isAVL = hasAVLAssets();

	var standardEvents = _.sortBy(
		_.filter(trkData.eventTypes, function (item) {
			return item.Id <= 16;
		}),
		"Text"
	);
	var avlEvents = _.sortBy(
		_.filter(trkData.eventTypes, function (item) {
			return item.Id > 16;
		}),
		"Text"
	);

	// output standard events
	_.each(standardEvents, function (evt) {
		// in history mode, only have options for actual events recorded
		if (state.activeMapMode !== mapModes.LIVE && !_.includes(includedEventIds, evt.Id)) {
			return;
		}
		var filterLi = createEventFilterNode(evt, isAllChecked);
		filterOptions.appendChild(filterLi);
	});

	if (isAVL) {
		var hr = document.createElement("div");
		hr.className = "w-100 py-2";
		//hr.style.cssText = 'float: none; width: auto; padding: 5px;';

		var border = document.createElement("div");
		border.style.cssText = "border-bottom: 2px solid #ccc;";
		hr.appendChild(border);

		filterOptions.appendChild(hr);

		_.each(avlEvents, function (evt) {
			if (state.activeMapMode !== mapModes.LIVE && !_.includes(includedEventIds, evt.Id)) {
				return;
			}
			var filterLi = createEventFilterNode(evt, isAllChecked);
			filterOptions.appendChild(filterLi);
		});
	}
	cont.appendChild(filterOptions);
}

export function openEventsForAsset(asset) {
	var dataSource = state.activeMapMode === mapModes.LIVE ? trkData.live : trkData.history;
	var events = _.sortBy(
		_.filter(dataSource.normalizedEventsByAssetId[asset.Id], getDisplayFilterForEventType("events")),
		defaultListItemSort
	).reverse();
	createListing(events, "events");
	openDialogPanel(
		domNodes.dialogs.assetEvents,
		strings.EVENTS,
		asset,
		false,
		null,
		"asset",
		"asset-events",
		openEventsForAsset
	);
}

function createEventFilterNode(evt, isAllChecked) {
	var eventFilters = trkData.live.eventFilters;
	if (state.activeMapMode === mapModes.HISTORY) {
		eventFilters = trkData.history.eventFilters;
	}
	var isChecked = _.includes(eventFilters, evt.Id) || isAllChecked;
	var isDisabled = isAllChecked;
	var filterLi = document.createElement("div");
	filterLi.className = "col-md-4";
	var filterDiv = document.createElement("div");
	filterDiv.className = "custom-control custom-checkbox";
	var filterInput = document.createElement("input");
	filterInput.className = "custom-control-input";
	filterInput.setAttribute("type", "checkbox");
	filterInput.setAttribute("id", "event-filter-" + evt.Id);
	filterInput.setAttribute("name", "event-filter");
	filterInput.setAttribute("value", evt.Id);
	if (isChecked) {
		filterInput.setAttribute("checked", "checked");
	}
	if (isDisabled) {
		filterInput.setAttribute("disabled", "disabled");
	}
	var filterLabel = document.createElement("label");
	filterLabel.className = "custom-control-label";
	filterLabel.setAttribute("for", "event-filter-" + evt.Id);
	filterLabel.textContent = evt.Text;
	filterDiv.appendChild(filterInput);
	filterDiv.appendChild(filterLabel);
	filterLi.appendChild(filterDiv);
	return filterLi;
}

function addEventsToActiveNotificationList(addedEvents) {
	// check dialog type and filter based on that?
	if (state.activeMapMode !== mapModes.LIVE) {
		return;
	}

	var filteredEvents = [];

	// filter for dialog type
	var openDialog = document.getElementById("dialog-functions").querySelector(".dialog");
	if (openDialog === domNodes.dialogs.assetEvents) {
		filteredEvents = _.filter(addedEvents, function (status) {
			return (
				!status.Hide &&
				trkData.EVENTS_STATUS.indexOf(status.Type) === -1 &&
				trkData.EVENTS_ALERT.indexOf(status.Type) === -1 &&
				trkData.EVENTS_EMERGENCY.indexOf(status.Type) === -1 &&
				trkData.EVENTS_TEXT_MESSAGE.indexOf(status.Type) === -1
			);
		});
	} else if (openDialog === domNodes.dialogs.assetStatus) {
		filteredEvents = _.filter(addedEvents, function (status) {
			return !status.Hide && trkData.EVENTS_STATUS.indexOf(status.Type) !== -1;
		});
	} else if (openDialog === domNodes.dialogs.assetAlerts) {
		filteredEvents = _.filter(addedEvents, function (status) {
			return (
				!status.Hide &&
				(trkData.EVENTS_ALERT.indexOf(status.Type) !== -1 || trkData.EVENTS_EMERGENCY.indexOf(status.Type) !== -1)
			);
		});
	} else if (openDialog === domNodes.dialogs.assetActivity) {
	} else {
		return;
	}

	if (filteredEvents.length === 0) {
		return;
	}

	// filter for assets/groups
	var assetId = null;
	var groupId = null;
	if (
		domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
		domNodes.panels.secondary.getAttribute("data-item-type") === "assets"
	) {
		assetId = parseInt(domNodes.panels.secondary.getAttribute("data-item-id"));
	} else if (
		domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
		domNodes.panels.secondary.getAttribute("data-item-type") === "groups"
	) {
		groupId = domNodes.panels.secondary.getAttribute("data-item-id");
	}
	if (assetId !== null) {
		filteredEvents = _.filter(addedEvents, function (item) {
			return item.AssetId === assetId;
		});
	} else {
		var status = getGroupAssetStatus(groupId);
		_.each(addedEvents, function (item) {
			if (status.assetIds.indexOf(item.AssetId) !== -1) {
				filteredEvents.push(item);
			}
		});
	}

	if (filteredEvents.length === 0) {
		return;
	}

	// include asset info for groups
	var eventData = [];
	_.each(filteredEvents, function (event) {
		var eventListing = mapAssetEventToListing(event);
		if (eventListing !== null) {
			eventData.push(eventListing);
		}
	});

	// add filtered events to active list
	//console.log('events to add');
	//console.log(filteredEvents);
}

function mapAssetEventToTableRow(item, isAlert) {
	// unused
	var asset = findAssetById(item.AssetId);
	var itemData = null;

	if (isAlert) {
		var eventicon = "";
		if (asset != null) {
			eventicon =
				'<div class="event-icon" style="background-image: url(' +
				createMarkerPath(asset.Class, asset.Color, null, null, asset.Id, false, item.Type) +
				');"></div>';
		}
		var eventposition = getPositionLinkForEvent(item);

		if (item.Alert.Hide) {
			return;
		}
		var type = item.Alert.Type;
		if (item.Alert.Name != null) {
			type = item.Alert.Name + ": " + type;
		}
		itemData = [
			"",
			item.Alert.Priority,
			asset != null ? asset.Name : "",
			type,
			item.Alert.Description != null ? item.Alert.Description.replace(/\r?\n/g, "<br />") : "",
			item.Alert.ResolutionProcedure != null ? item.Alert.ResolutionProcedure.replace(/\r?\n/g, "<br />") : "",
			eventposition, // Position
			//(item.Details != null) ? item.Details.replace(/\r?\n/g, '<br />') : '',
			item.Time,
			'<button class="AlertAcknowledge btn btn-primary btn-sm" data-alert="' +
				item.Id +
				'">' +
				strings.ACKNOWLEDGE +
				"</button>", // ack button
			asset.Id,
			item.Type,
			item.Alert.Color,
		];
	} else {
		var item = mapAssetEventToListing(item);
		if (item === null) {
			return null;
		}

		itemData = [
			asset != null ? asset.Name : "",
			item.icon + ' <span class="event-type">' + item.name + "</span>",
			"", //item.position,
			item.details,
			item.time,
			asset.Id,
			item.type,
			item.isAccurate,
			item.id,
		];
	}
	return itemData;
}

function updateAssetEventNotificationTime(event) {
	if (trkData.EVENTS_ALERT.indexOf(event.Type) !== -1 || trkData.EVENTS_EMERGENCY.indexOf(event.Type) !== -1) {
		updateAssetNotificationTime(event.AssetId, "alerts", event.Epoch);
	} else if (trkData.EVENTS_STATUS.indexOf(event.Type) !== -1) {
		updateAssetNotificationTime(event.AssetId, "status", event.Epoch);
	} else if (trkData.EVENTS_TEXT_MESSAGE.indexOf(event.Type) !== -1) {
		// messages handled by .messagesByAssetId
	} else {
		updateAssetNotificationTime(event.AssetId, "events", event.Epoch);
	}
}

function mapAssetEventToListing(item) {
	// unused
	// TODO move this out of rendering
	if (options.hideAlertTriggeredEvents || item.Hide || user.isAnonymous) {
		if (trkData.EVENTS_ALERT.indexOf(item.Type) !== -1) {
			return null;
		}
	}
	if (options.hideEmergencyEvents || user.isAnonymous) {
		if (trkData.EVENTS_EMERGENCY.indexOf(item.Type) !== -1) {
			return null;
		}
	}

	if (item.Type === 268) {
		// SL Summary
		return null;
	}

	var asset = findAssetById(item.AssetId);
	var svgIcon = createIconForAssetEvent(asset, item.Type);
	var eventIcon =
		'<div class="event-icon" style="background-image: url(' +
		createMarkerPath(asset.Class, asset.Color, null, null, asset.Id, false, item.Type) +
		');"></div>';
	var details = item.Details != null ? htmlEscape(item.Details).replace(/\r?\n/g, "<br />") : "";
	switch (item.Type) {
		case 127: // garmin form submitted
			if (item.Details != null) {
				var formDetails = item.Details.split("|");
				var formId = formDetails[0];
				var formName = strings.VIEW;
				if (formDetails.length > 1) {
					formName = formDetails[1];
				}
				details =
					'<button class="ViewGarminSubmission command details btn btn-sm btn-secondary" data-id="' +
					formId +
					'" data-asset-id="' +
					asset.Id +
					'">' +
					formName +
					"</button>";
			}
			break;
		case 270: // beacon read
			if (item.Details != null) {
				details = "";
				var beacons = item.Details.split("\n");
				for (var k = 0; k < beacons.length; k++) {
					var beacon = beacons[k].split("|");
					if (beacon.length > 1) {
						var beaconUniqueKey = beacon[0];
						var beaconRSSI = beacon[1];
						var beaconTxPower = beacon[2];
						if (beaconUniqueKey == null) {
							continue;
						}
						var beaconPlace = findPlaceByUniqueKey(beaconUniqueKey);
						if (beaconPlace != null) {
							details +=
								'<a class="beacon-place" data-place-id="' +
								beaconPlace.Id +
								'" href="#">' +
								beaconPlace.Name +
								"</a> @ " +
								beaconRSSI +
								" RSSI";
						} else {
							details += beaconUniqueKey + " @ " + beaconRSSI + " RSSI";
						}
						if (beaconTxPower != "") {
							details += ", " + beaconTxPower + " Tx";
						}
						details += "<br />";
					}
				}
			}
			break;
		case 272:
		case 273:
		case 274:
			// driver fatigue
			if (item.Details != null) {
				details =
					'<button class="ViewPhoto command details btn btn-sm btn-secondary" data-photo="' +
					item.Details +
					'" title="' +
					strings.VIEW_PHOTO +
					'">' +
					strings.VIEW_PHOTO +
					"</button>";
			}
			break;
		case 275:
			if (item.Details != null) {
				var parts = item.Details.split("|||");
				details =
					'<button class="ViewPhoto command details btn btn-sm btn-secondary" data-photo="' +
					parts[1] +
					'" title="' +
					strings.VIEW_PHOTO +
					'">' +
					strings.VIEW_PHOTO +
					"</button>";
			}
			break;
	}

	var fullName = item.TypeName;
	if (item.Alert !== undefined && item.Alert !== null) {
		var alertName = item.Alert.Type;
		if (item.Alert.Name !== null) {
			alertName = item.Alert.Name + " [ " + item.Alert.Type + " ]";
		}
		fullName += ", " + alertName;
	}

	return {
		id: item.Id,
		icon: eventIcon,
		svgIcon: svgIcon,
		name: item.TypeName,
		fullName: fullName,
		position: mapPositionForEvent(item, asset),
		details: details,
		time: item.Time,
		epoch: item.Epoch,
		type: item.Type,
		isAccurate: item.IsAcc,
		alert: item.Alert,
		assetId: item.AssetId,
	};
}
