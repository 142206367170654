import strings from "./strings.js";
import trkData from "./data.js";
import user from "./user.js";
import domNodes from "./domNodes.js";
import { findGroupById, createAssetNode } from "./asset-group.js";
import { isItemIncluded } from "./polyfills.js";
import { toggleGroupExpandedIcon } from "./asset-group.js";
import { sortItemsByMode } from "./item-sorting.js";
import { updateGroupVisibilityStatus, createGroupNode } from "./asset-group.js";
import { findAssetById } from "./assets.js";
import { createFenceNode } from "./fence.js";
import { saveItemCustomSortOrder } from "./item-sorting.js";
import { createSharedViewNode } from "./shared-view.js";
import { createPlaceNode } from "./place.js";
import { createTripNode } from "./trips.js";
import { resizeApp } from "./window-layout.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";

export function populateSidePanel() {
	var assetNodes = {};
	var groupNodes = {};

	var groupAssets = {
		"all-assets": {
			groups: [],
			assets: [],
		},
	};

	// initialize groups
	_.each(trkData.groups, function (group) {
		groupAssets[group.Id] = { groups: [], assets: [] };
	});

	// assign subgroups to groups
	_.each(trkData.groups, function (group) {
		if (group.ParentGroupId !== null && groupAssets[group.ParentGroupId] !== undefined) {
			groupAssets[group.ParentGroupId].groups.push(group);
			var parentGroup = findGroupById(group.ParentGroupId);
			if (parentGroup !== null) {
				parentGroup.GroupIds.push(group.Id);
			}
		}

		// render baseline group for dom
		groupNodes[group.Id] = createGroupNode(group, group.Type);
	});
	// render the All Assets group - now an actual inserted group
	//groupNodes['all-assets'] = createGroupNode({ Id: 'all-assets', Name: strings.ALL_ASSETS, Color: options.defaultColors.assets }, 'assets');

	// initialize group nodes cache and set colors for stylesheet
	_.each(groupNodes, function (group, key, list) {
		domNodes.groups[key] = groupNodes[key];
		var actualGroup = findGroupById(key);
		if (actualGroup !== null) {
			domNodes.groupColors[key] = actualGroup.Color;
		}

		// initialize sorting for group
		var groupContentsNode = groupNodes[key].querySelector(".group-contents");
		var groupContentsItems = groupContentsNode.querySelector("ul.group-list-list");
		$(groupContentsItems).sortable({
			axis: "y",
			items: "> li.group",
			handle: ".group-drag",
			update: function (event, ui) {
				saveItemCustomSortOrder();
			},
		});
		$(groupContentsNode).sortable({
			axis: "y",
			items: "> .group-list-list > li.group-item",
			handle: ".item-drag",
			update: function (event, ui) {
				saveItemCustomSortOrder();
			},
		});
	});

	var sortedAssets = sortItemsByMode("assets", trkData.assets, "all-assets", "assets");
	var primaryGroups = sortItemsByMode(
		"assets",
		_.filter(trkData.groups, function (group) {
			return group.ParentGroupId === null || groupAssets[group.ParentGroupId] === undefined;
		}),
		"assets-root",
		"groups"
	);

	// assign assets to groups
	groupAssets["all-assets"].assets = sortedAssets;
	_.each(trkData.assets, function (asset, index, list) {
		_.each(asset.GroupIds, function (groupId, ind, li) {
			if (groupAssets[groupId] !== undefined) {
				groupAssets[groupId].assets.push(asset);
			}
		});

		// render baseline asset for dom
		assetNodes[asset.Id] = createAssetNode(asset);
	});

	// sort assets and subgroups within groups
	_.each(groupAssets, function (value, key, list) {
		list[key].assets = sortItemsByMode("assets", value.assets, key, "assets");
		list[key].groups = sortItemsByMode("assets", value.groups, key, "groups");
	});

	//console.log(groupAssets);

	_.each(groupAssets, function (group, key, list) {
		var groupContentsNode = groupNodes[key].querySelector(".group-contents");
		var groupContentsItems = groupContentsNode.querySelector("ul.group-list-list");
		var groupData = groupAssets[key];

		// add subgroup nodes to group dom
		_.each(groupData.groups, function (subGroup, index, list) {
			// groups can only be assigned to one parent group, so do not clone their nodes
			groupContentsItems.appendChild(groupNodes[subGroup.Id]);
		});

		// add asset nodes to group dom
		_.each(groupData.assets, function (asset, index, list) {
			// assets can be added to multiple groups, so they must be cloned
			var groupAsset = assetNodes[asset.Id].cloneNode(true);

			groupContentsItems.appendChild(groupAsset);
			if (domNodes.assets[asset.Id] === undefined) {
				domNodes.assets[asset.Id] = [];
			}
			domNodes.assets[asset.Id].push(groupAsset);
		});

		// cache group content nodes so they can be detached/reattached to the dom
		// based on whether the group is expanded/visible
		domNodes.groupContents[key] = groupContentsNode;

		if (key === "all-assets") {
			if (user.displayPreferences.hideAllAssets === true) {
				groupContentsNode.parentNode.removeChild(groupContentsNode);
				toggleGroupExpandedIcon(key, false);
			} else {
				toggleGroupExpandedIcon(key, true);
			}
		} else if (!isItemIncluded(user.displayPreferences.expandedGroups, key)) {
			// only render group contents when the group is visible (expanded)
			groupContentsNode.parentNode.removeChild(groupContentsNode);
			toggleGroupExpandedIcon(key, false);
		} else {
			toggleGroupExpandedIcon(key, true);
		}
	});

	// toggle active groups within fragment
	// by default groups are inactive unless they contain at least one active asset
	// this isn't done on creation as it would be pretty tricky and not worth the effort
	_.each(groupAssets, function (group, key, list) {
		updateGroupVisibilityStatus(key);
	});

	var assetsGroupsFragment = document.createDocumentFragment();
	assetsGroupsFragment.appendChild(groupNodes["all-assets"]);
	_.each(primaryGroups, function (value, key, list) {
		assetsGroupsFragment.appendChild(groupNodes[value.Id]);
	});

	// shared views - just like asset groups
	if (trkData.sharedViews.length === 0) {
		// no shared views
	}

	if (trkData.assets.length === 0) {
		document.getElementById("filter-assets").querySelector(".filter-box").classList.remove("is-visible");
	} else {
		document.getElementById("filter-assets").querySelector(".filter-box").classList.add("is-visible");
	}

	// journeys - just like asset groups
	if (trkData.journeys.length === 0) {
		// no journeys, add to journeys-all?
		var noExtrasNode = document.createElement("li");
		noExtrasNode.className = "toggle-content no-items is-visible";
		noExtrasNode.setAttribute("id", "no-journeys");
		var noExtrasText = document.createElement("span");
		noExtrasText.textContent = strings.NO_JOURNEYS;
		noExtrasNode.appendChild(noExtrasText);
		document.getElementById("journeys-all").appendChild(noExtrasNode);
		//document.getElementById('filter-journeys').querySelector('.filter-box').classList.remove('is-visible');
	}
	_.each(trkData.journeys, function (item) {
		var journeyAsset = findAssetById(item.AssetId);
		if (journeyAsset === null) {
			return;
		}
		var groupId = "journey-" + item.Id;
		var extraGroupNode = createGroupNode({ Id: groupId, Name: item.Name, Color: item.Color }, "journeys");
		domNodes.groups[groupId] = extraGroupNode;

		var extraGroupContentsNode = extraGroupNode.querySelector(".group-contents");
		var extraGroupContentsItems = extraGroupContentsNode.querySelector("ul.group-list-list");
		var noExtrasNode = document.createElement("li");
		noExtrasNode.className = "toggle-content no-items";
		if (item.Trips.length === 0) {
			noExtrasNode.className += " is-visible";
			//document.getElementById('filter-journeys').querySelector('.filter-box').classList.remove('is-visible');
		}
		noExtrasNode.setAttribute("id", "no-" + groupId);
		var noExtrasText = document.createElement("span");
		noExtrasText.textContent = strings.NO_TRIPS;
		noExtrasNode.appendChild(noExtrasText);
		extraGroupContentsItems.appendChild(noExtrasNode);

		var journey = item;
		_.each(item.Trips, function (item, index, list) {
			var extraNode = createTripNode(journey, item, journeyAsset);
			domNodes.trips[item.Id] = extraNode;
			extraGroupContentsItems.appendChild(extraNode);
		});

		var extraAllItems = extraGroupNode.getElementsByClassName("journeys-item").length;
		var extraActiveItems = extraGroupNode.getElementsByClassName("journeys-item active").length;
		if (extraActiveItems > 0) {
			var extraVisibility = extraGroupNode.querySelector(".showhide");

			if (extraAllItems !== extraActiveItems) {
				extraVisibility
					.querySelector("use")
					.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#indeterminate");
				extraVisibility.classList.add("indeterminate");
			} else {
				extraVisibility
					.querySelector("use")
					.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#visible");
				extraVisibility.classList.add("active");
			}
			extraGroupNode.classList.add("active");
		}
		domNodes.groupContents[groupId] = extraGroupContentsNode;
		if (item.Color !== undefined && item.Color !== null) {
			domNodes.groupColors[groupId] = item.Color;
		}
		if (!isItemIncluded(user.displayPreferences.expandedGroups, groupId)) {
			// only render group contents when the group is visible (expanded)
			extraGroupContentsNode.parentNode.removeChild(extraGroupContentsNode);
			toggleGroupExpandedIcon(groupId, false);
		} else {
			toggleGroupExpandedIcon(groupId, true);
		}

		updateGroupVisibilityStatus(groupId);

		var allJourneys = document.getElementById("journeys-all");
		allJourneys.appendChild(extraGroupNode);
	});

	var extraGroups = [
		{
			type: "fences",
			createNodeFunction: createFenceNode,
			name: strings.ALL_GEOFENCES,
			color: null,
			emptyText: strings.NO_FENCES,
			items: sortItemsByMode("fences", trkData.fences, "all-fences", "fences"),
			nodeList: domNodes.fences,
		},
		{
			type: "places",
			createNodeFunction: createPlaceNode,
			name: strings.ALL_PLACES,
			color: null,
			emptyText: strings.NO_PLACES,
			items: sortItemsByMode("places", trkData.places, "all-places", "places"),
			nodeList: domNodes.places,
		},
		{
			type: "shared-views",
			createNodeFunction: createSharedViewNode,
			name: strings.ALL_SHARED_VIEWS,
			color: null,
			emptyText: strings.NO_SHARED_VIEWS,
			noItemsText: strings.NO_SHARED_VIEWS_HINT,
			items: sortItemsByMode("shared-views", trkData.sharedViews, "all-shared-views", "shared-views"),
			nodeList: domNodes.sharedViews,
		},
	];

	_.each(extraGroups, function (group, index, list) {
		var groupId = "all-" + group.type;
		var extraGroupNode = createGroupNode({ Id: groupId, Name: group.name, Color: group.color }, group.type);
		domNodes.groups[groupId] = extraGroupNode;
		var extraGroupContentsNode = extraGroupNode.querySelector(".group-contents");
		var extraGroupContentsItems = extraGroupContentsNode.querySelector("ul.group-list-list");
		var noExtrasNode = document.createElement("li");
		noExtrasNode.className = "toggle-content no-items";
		if (group.items.length === 0) {
			noExtrasNode.className += " is-visible";
			document
				.getElementById("filter-" + group.type)
				.querySelector(".filter-box")
				.classList.remove("is-visible");
		} else {
			document
				.getElementById("filter-" + group.type)
				.querySelector(".filter-box")
				.classList.add("is-visible");
		}
		noExtrasNode.setAttribute("id", "no-" + groupId);

		if (group.type == "shared-views" && group.items.length === 0) {
			document.getElementById("no-shared-views").classList.add("is-visible");
			document.getElementById("shared-views-all").classList.remove("is-visible");
			//document.getElementById('filter-shared-views').classList.remove('is-visible');
		}

		var noExtrasText = document.createElement("span");
		noExtrasText.textContent = group.emptyText;
		noExtrasNode.appendChild(noExtrasText);
		extraGroupContentsItems.appendChild(noExtrasNode);

		_.each(group.items, function (item, index, list) {
			var extraNode = group.createNodeFunction(item);
			group.nodeList[item.Id] = extraNode;
			extraGroupContentsItems.appendChild(extraNode);
		});

		var extraAllItems = extraGroupNode.getElementsByClassName(group.type + "-item").length;
		var extraActiveItems = extraGroupNode.getElementsByClassName(group.type + "-item active").length;
		if (extraActiveItems > 0) {
			var extraVisibility = extraGroupNode.querySelector(".showhide");

			if (extraAllItems !== extraActiveItems) {
				extraVisibility
					.querySelector("use")
					.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#indeterminate");
				extraVisibility.classList.add("indeterminate");
			} else {
				extraVisibility
					.querySelector("use")
					.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#visible");
				extraVisibility.classList.add("active");
			}
			extraGroupNode.classList.add("active");
		}
		domNodes.groupContents[groupId] = extraGroupContentsNode;
		if (group.color !== undefined && group.color !== null) {
			domNodes.groupColors[groupId] = group.color;
		}
		if (!isItemIncluded(user.displayPreferences.expandedGroups, "all-" + group.type)) {
			// only render group contents when the group is visible (expanded)
			extraGroupContentsNode.parentNode.removeChild(extraGroupContentsNode);
			toggleGroupExpandedIcon(groupId, false);
		} else {
			toggleGroupExpandedIcon(groupId, true);
		}

		updateGroupVisibilityStatus(groupId);

		// insert into separate panel/container
		var container = document.getElementById(group.type + "-all");
		container.appendChild(extraGroupNode);

		$(extraGroupContentsNode).sortable({
			axis: "y",
			items: "> .group-list-list > li.group-item",
			handle: ".item-drag",
			update: function (event, ui) {
				saveItemCustomSortOrder();
			},
		});
	});

	var assetGroupsContainer = document.getElementById("assets-all");
	assetGroupsContainer.appendChild(assetsGroupsFragment);
	$(assetGroupsContainer).sortable({
		axis: "y",
		items: "> li.group",
		handle: ".group-drag",
		update: function (event, ui) {
			saveItemCustomSortOrder();
		},
	});
}

export function hideSidePanel() {
	$j("#topbar-left").css("left", "-25em");
	$j("#topbar-right").css("margin-left", "0");
	$j("#panel").hide();
	$j("#map").css("left", 0).css("margin-right", 0);
	$j("#map_tools,#map_tools_bottom,#map_panels").css("margin-left", 0);
	$j("#panel-expand").show();
	$j("#ds-v .shade, #event-panel,#event-panel-container").css("left", "0");
	$j("#panel-contract").hide();
	resizeApp(true);
}

export function showSidePanel() {
	$j("#topbar-left").css("left", "0");
	$j("#topbar-right").css("margin-left", "25em");
	$j("#panel").show();
	$j("#map").css("left", "25em").css("margin-right", "25em");
	$j("#map_tools,#map_tools_bottom,#map_panels").css("margin-left", "25em");
	$j("#panel-contract").show();
	$j("#ds-v .shade, #event-panel,#event-panel-container").css("left", "25em");
	$j("#panel-expand").hide();
	resizeApp(true);
}
