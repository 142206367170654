import trkData from "./data.js";
import state from "./state.js";
import strings from "./strings.js";
import { mapModes } from "./const.js";
import domNodes from "./domNodes.js";
import { switchMapMode } from "./map-base.js";
import { findAssetById } from "./assets.js";
import { openAssetLatestPosition } from "./asset-live.js";
import user from "./user.js";
import { map } from "./map-base.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import L from "leaflet";
import moment from "moment"; // https://www.npmjs.com/package/moment

export function initLiveAssetsFollow() {
	$j("#live-follow-status").on("click", "#live-follow-close", function (e) {
		e.preventDefault();
		stopLiveFollowing();
	});

	$j("#live-follow-status").on("click", "#live-follow-assets", function (e) {
		e.preventDefault();
		if (state.liveFollow.asset == null) {
			return;
		}

		if (state.liveFollow.assets.length == 1) {
			openAssetLatestPosition(state.liveFollow.assets[0]);
		} else {
			// pan/zoom to include all assets latest positions
			var bounds = L.latLngBounds();
			for (var i = 0; i < state.liveFollow.assets.length; i++) {
				var assetId = state.liveFollow.assets[i].Id;
				if (trkData.live.latestPositionsByAssetId[assetId] !== undefined) {
					bounds.extend(
						L.latLng(
							trkData.live.latestPositionsByAssetId[assetId].Position.Lat,
							trkData.live.latestPositionsByAssetId[assetId].Position.Lng
						)
					);
				}
			}
			if (bounds.isValid()) {
				map.fitBounds(bounds, { padding: [10, 10] });
			}
		}
	});
}

export function updateLiveFollowStatus() {
	var assetsFollowed = state.liveFollow.assets.length;
	if (assetsFollowed === 0) {
		domNodes.followLiveStatus.classList.remove("is-visible");
		return;
	}

	if (assetsFollowed === 1) {
		$("#live-follow-assets").text(state.liveFollow.assets[0].Name).prop("title", state.liveFollow.assets[0].Name);
	} else {
		var title = "";
		for (var i = 0; i < assetsFollowed; i++) {
			if (i > 0) {
				title += ", ";
			}
			title += state.liveFollow.assets[i].Name;
		}
		$("#live-follow-assets")
			.text(strings.MULTIPLE_ASSETS.replace("{0}", state.liveFollow.assets.length))
			.prop("title", title);
	}
	domNodes.followLiveStatus.classList.add("is-visible");

	if (state.activeMapMode !== mapModes.LIVE) {
		// can only follow in live mode
		switchMapMode(mapModes.LIVE, null, false);
	} else {
		openMostRecentLiveFollowPosition();
	}
}

export function toggleFollowAsset(asset) {
	var index = $.inArray(asset, state.liveFollow.assets);
	if (index == -1) {
		liveFollowAsset(asset);
	} else {
		liveUnfollowAsset(asset);
	}

	updateLiveFollowStatus();
}

export function toggleFollowGroup(group) {
	var groupAssets = [];
	// should this get all assets under the group and its subgroup or just the immediately assigned assets?
	if (group.Id === "all-assets") {
		groupAssets = trkData.assets;
	}
	for (var i = 0; i < trkData.assets.length; i++) {
		var asset = trkData.assets[i];
		if ($.inArray(group.Id, asset.GroupIds) != -1) {
			groupAssets.push(asset);
		}
	}

	if (isFollowingGroup(group)) {
		// all assets being followed, so un follow them
		for (var i = 0; i < groupAssets.length; i++) {
			liveUnfollowAsset(groupAssets[i]);
		}
	} else {
		// not all assets being followed, follow ones that are not
		for (var i = 0; i < groupAssets.length; i++) {
			liveFollowAsset(groupAssets[i]);
		}
	}

	updateLiveFollowStatus();
}

export function liveFollowAsset(asset) {
	state.liveFollow.isActive = true;
	state.liveFollow.asset = asset;
	var index = $.inArray(asset, state.liveFollow.assets);
	if (index == -1) {
		state.liveFollow.assets.push(asset);
	}
}

function liveUnfollowAsset(asset) {
	var index = $.inArray(asset, state.liveFollow.assets);
	if (index !== -1) {
		state.liveFollow.assets.splice(index, 1);
	}
	if (state.liveFollow.assets.length === 0) {
		stopLiveFollowing();
	}
}

export function stopLiveFollowing() {
	domNodes.followLiveStatus.classList.remove("is-visible");
	$j("#live-follow-updated-time").data("time", null);
	state.liveFollow.isActive = false;
	state.liveFollow.asset = null;
	state.liveFollow.assets = [];
	state.liveFollow.groups = [];
}

export function isFollowingGroup(group) {
	var groupAssets = [];
	for (var i = 0; i < trkData.assets.length; i++) {
		var asset = trkData.assets[i];
		if ($j.inArray(group.Id, asset.GroupIds) != -1) {
			groupAssets.push(asset);
		}
	}
	if (groupAssets.length == 0) {
		return false;
	}
	for (var i = 0; i < groupAssets.length; i++) {
		var asset = groupAssets[i];
		if ($j.inArray(asset, state.liveFollow.assets) == -1) {
			return false;
		}
	}
	return true;
}

export function openMostRecentLiveFollowPosition() {
	if (!state.liveFollow.isActive) {
		return;
	}

	// todo: pan/zoom to include all followed assets instead?
	var latest = null;
	_.each(state.liveFollow.assets, function (asset) {
		var assetLatest = trkData.live.latestPositionsByAssetId[asset.Id];
		if (assetLatest === undefined) {
			return;
		}
		if (latest === null || latest.Position.Epoch < assetLatest.Position.Epoch) {
			latest = assetLatest;
		}
	});

	if (latest !== null) {
		openAssetLatestPosition(findAssetById(latest.AssetId));

		var loc = latest.Position;
		var locationTime = moment(loc.Time, user.dateFormat);
		var priorTime = $("#live-follow-updated-time").data("time");
		if (priorTime == null) {
			$("#live-follow-updated-time").text(loc.Time);
			$("#live-follow-updated-time").data("time", locationTime);
		} else {
			if (locationTime.isAfter(priorTime)) {
				$("#live-follow-updated-time").data("time", locationTime);
				$("#live-follow-updated-time").text(loc.Time);
			}
		}
	} else {
		$("#live-follow-updated-time").text(strings.NEVER);
	}
}
