import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import { devices } from "./devices.js";
import trkData from "./data.js";

import _ from "lodash";
import $j from "jquery";

export function loadIDPAssetIOConfiguration(asset) {
	if (asset == null) return;
	if (
		$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) == -1 &&
		$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP) == -1
	)
		return;

	var data = { assetId: asset.Id };
	toggleLoadingMessage(true, "asset-io-configuration");
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/IDPGetLatestCoreProperties"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			toggleLoadingMessage(false, "asset-io-configuration");
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					if (result.CoreProperties == null) return;
					if (result.CoreProperties.EIO != null) {
						$j("#EditAssetInputLabel1").parent().parent().find("select.eio").val(result.CoreProperties.EIO.Port1Config);
						$j("#EditAssetInputLabel2").parent().parent().find("select.eio").val(result.CoreProperties.EIO.Port2Config);
						$j("#EditAssetInputLabel3").parent().parent().find("select.eio").val(result.CoreProperties.EIO.Port3Config);
						$j("#EditAssetInputLabel4").parent().parent().find("select.eio").val(result.CoreProperties.EIO.Port4Config);
					} else if (result.CoreProperties.EEIO != null) {
						$j("#EditAssetInputLabel1")
							.parent()
							.parent()
							.find("select.eeio")
							.val(result.CoreProperties.EEIO.Input1Config);
						$j("#EditAssetInputLabel2")
							.parent()
							.parent()
							.find("select.eeio")
							.val(result.CoreProperties.EEIO.Input2Config);
						$j("#EditAssetInputLabel3")
							.parent()
							.parent()
							.find("select.eeio")
							.val(result.CoreProperties.EEIO.Input3Config);
						$j("#EditAssetInputLabel4")
							.parent()
							.parent()
							.find("select.eeio")
							.val(result.CoreProperties.EEIO.Input4Config);
					}
				} else {
					handleWebServiceError(null);
				}
			}
		},
		error: function (xhr, status, error) {
			handleWebServiceError(null);
			toggleLoadingMessage(false, "asset-io-configuration");
		},
	});
}

export function loadIDPAVLAssetIOConfiguration(asset) {
	if (asset == null) return;

	if ($j.inArray(asset.Configuration, trkData.avlConfigurations) == -1) return;

	if (
		$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) == -1 &&
		$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP) == -1
	)
		return;

	var data = { assetId: asset.Id };
	toggleLoadingMessage(true, "asset-io-avl-configuration");
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/IDPAVLGetLatestProperties"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			toggleLoadingMessage(false, "asset-io-avl-configuration");
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					if (result.ServiceProperties == null) return;
					var digitalInputs = $j("#InputLabelsDigital tbody tr");
					for (var i = 0; i < digitalInputs.length; i++) {
						var elem = digitalInputs.eq(i).find("select.avl");
						switch (i) {
							case 0:
								elem.val(result.ServiceProperties.FuncDigInp1);
								break;
							case 1:
								elem.val(result.ServiceProperties.FuncDigInp2);
								break;
							case 2:
								elem.val(result.ServiceProperties.FuncDigInp3);
								break;
							case 3:
								elem.val(result.ServiceProperties.FuncDigInp4);
								break;
							case 4:
								elem.val(result.ServiceProperties.FuncDigInp5);
								break;
							case 5:
								elem.val(result.ServiceProperties.FuncDigInp6);
								break;
							case 6:
								elem.val(result.ServiceProperties.FuncDigInp7);
								break;
							case 7:
								elem.val(result.ServiceProperties.FuncDigInp8);
								break;
							case 8:
								elem.val(result.ServiceProperties.FuncDigInp9);
								break;
							case 9:
								elem.val(result.ServiceProperties.FuncDigInp10);
								break;
							case 10:
								elem.val(result.ServiceProperties.FuncDigInp11);
								break;
							case 11:
								elem.val(result.ServiceProperties.FuncDigInp12);
								break;
						}
					}
					var analogInputs = $j("#InputLabelsAnalog tbody tr");
					for (var i = 0; i < analogInputs.length; i++) {
						var elem = analogInputs.eq(i).find("span.sensor");
						elem.text("");
						switch (i) {
							case 0:
								if (result.ServiceProperties.Sensor1Properties != null) {
									elem.text(
										result.ServiceProperties.Sensor1Properties.SourceSIN +
											":" +
											result.ServiceProperties.Sensor1Properties.SourcePIN
									);
								}
								break;
							case 1:
								if (result.ServiceProperties.Sensor2Properties != null) {
									elem.text(
										result.ServiceProperties.Sensor2Properties.SourceSIN +
											":" +
											result.ServiceProperties.Sensor2Properties.SourcePIN
									);
								}
								break;
							case 2:
								if (result.ServiceProperties.Sensor3Properties != null) {
									elem.text(
										result.ServiceProperties.Sensor3Properties.SourceSIN +
											":" +
											result.ServiceProperties.Sensor3Properties.SourcePIN
									);
								}
								break;
							case 3:
								if (result.ServiceProperties.Sensor4Properties != null) {
									elem.text(
										result.ServiceProperties.Sensor4Properties.SourceSIN +
											":" +
											result.ServiceProperties.Sensor4Properties.SourcePIN
									);
								}
								break;
						}
					}
				} else {
					handleWebServiceError(null);
				}
			}
		},
		error: function (xhr, status, error) {
			handleWebServiceError(null);
			toggleLoadingMessage(false, "asset-io-avl-configuration");
		},
	});
}

export function loadIDPARCAssetIOConfiguration(asset) {
	if (asset == null) return;

	if ($j.inArray(asset.Configuration, trkData.arcConfigurations) == -1) return;

	if (
		$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) == -1 &&
		$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP) == -1
	)
		return;

	var data = { assetId: asset.Id };
	toggleLoadingMessage(true, "asset-io-arc-configuration");
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/IDPARCGetLatestProperties"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			toggleLoadingMessage(false, "asset-io-arc-configuration");
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					if (result.ARCTwoProperties == null) return;
					var digitalInputs = $j("#InputLabelsDigital tbody tr");
					var analogInputs = $j("#InputLabelsAnalog tbody tr");
					var outputs = $j("#OutputLabels tbody tr");
					analogInputs.find("select.arc").val("-1"); // not configured
					digitalInputs.find("select.arc").val("-1"); // not configured
					outputs.find("select.arc").val("-1"); // not configured
					selectARCConfiguration(result.ARCTwoProperties.IO1.EventType, 0, analogInputs, digitalInputs, outputs);
					selectARCConfiguration(result.ARCTwoProperties.IO2.EventType, 1, analogInputs, digitalInputs, outputs);
					selectARCConfiguration(result.ARCTwoProperties.IO3.EventType, 2, analogInputs, digitalInputs, outputs);
					selectARCConfiguration(result.ARCTwoProperties.IO4.EventType, 3, analogInputs, digitalInputs, outputs);
				} else {
					handleWebServiceError(null);
				}
			}
		},
		error: function (xhr, status, error) {
			handleWebServiceError(null);
			toggleLoadingMessage(false, "asset-io-arc-configuration");
		},
	});
}

function selectARCConfiguration(value, num, analogInputs, digitalInputs, outputs) {
	switch (value) {
		case 1:
		case 2:
		case 3:
		case 9:
			// digital
			digitalInputs.eq(num).find("select.arc").val(value);
			break;
		case 4:
		case 6:
		case 7:
		case 8:
		case 10: // analog
		case 11:
			analogInputs.eq(num).find("select.arc").val(value);
			break;
		case 5:
		case 12:
			outputs.eq(num).find("select.arc").val(value);
			break;
	}
}
