import strings from "./strings.js";
import { devices } from "./devices.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import { formShowErrorMessage } from "./ajax.js";
import { findAssetById } from "./assets.js";

import $j from "jquery";
import _ from "lodash";

// start Queclink

export function requestQueclinkInformation(assetId) {
	var data = {
		assetId: assetId,
	};
	var status = document.getElementById("queclink-query-status");
	toggleLoadingMessage(true, "queclink-information");
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/QueclinkGetConfiguration"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			var result = msg.d;
			if (result) {
				if (result.Success == true) {
					if (result.LastRetrievedOn == null) {
						$j("#QueclinkConfigLastRetrieved").text(strings.NEVER);
					} else {
						$j("#QueclinkConfigLastRetrieved").text(result.LastRetrievedOn);
					}
					if (result.LastQueriedOn == null) {
						$j("#QueclinkConfigLastQueried").text(strings.NEVER);
					} else {
						$j("#QueclinkConfigLastQueried").text(result.LastQueriedOn);
					}

					var config = result.Configuration;
					populateQueclinkConfig(config, assetId);
				} else {
					// message failure, keep text field to allow retry
					formShowErrorMessage(status, strings.MSG_GET_DEVICE_INFORMATION_ERROR);
					if (result.ErrorMessage != null && result.ErrorMessage != "") {
						formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
					}
				}
			}
			toggleLoadingMessage(false, "queclink-information");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_GET_DEVICE_INFORMATION_ERROR);
			toggleLoadingMessage(false, "queclink-information");
		},
	});
}

function populateQueclinkConfig(config, assetId) {
	if (config == null) return;

	var asset = findAssetById(assetId);
	if (config.Version == null || asset.Version == "") {
		$j("#QueclinkProtocolVersion").text(strings.UNKNOWN);
	} else {
		$j("#QueclinkProtocolVersion").text(config.ProtocolVersion);
	}

	// GL200/300
	if (config.FRI != null) {
		$j("#ddlQueclinkReportMode").val(config.FRI.ReportMode);
		$j("#txtQueclinkGPSCheckInterval").val(config.FRI.CheckInterval);
		$j("#txtQueclinkSendInterval").val(config.FRI.SendInterval);
		$j("#txtQueclinkIgnitionOnGPSCheckInterval").val(config.FRI.IgnitionCheckInterval);
		$j("#txtQueclinkIgnitionOnSendInterval").val(config.FRI.IgnitionSendInterval);
		$j("#txtQueclinkStartTime").val(config.FRI.BeginTime);
		$j("#txtQueclinkEndTime").val(config.FRI.EndTime);
		$j("#txtQueclinkDistance").val(config.FRI.Distance);
		$j("#txtQueclinkMileage").val(config.FRI.Mileage);

		// nullables
		//if (config.FRI.MovementDetectMode != null) {
		$j("input:radio[name=rbQueclinkMovementDetectMode][value=" + config.FRI.MovementDetectMode + "]").prop(
			"checked",
			true
		);
		//}
		$j("#txtQueclinkMovementSpeed").val(config.FRI.MovementSpeed);
		$j("#txtQueclinkMovementDistance").val(config.FRI.MovementDistance);
		$j("#txtQueclinkCorner").val(config.FRI.Corner);
		$j("input:radio[name=rbQueclinkDiscardNoFix][value=" + config.FRI.DiscardNoFix + "]").prop("checked", true);
		var reportInclude = config.FRI.ReportInclude;
		$j("#chkQueclinkReportIncludeSpeed").prop("checked", reportInclude.Speed);
		$j("#chkQueclinkReportIncludeAltitude").prop("checked", reportInclude.Altitude);
		$j("#chkQueclinkReportIncludeAzimuth").prop("checked", reportInclude.Azimuth);
		$j("#chkQueclinkReportIncludeGSMTowerData").prop("checked", reportInclude.GSMTowerData);
		$j("#chkQueclinkReportIncludeSendTime").prop("checked", reportInclude.SendTime);
	}
}
// end Queclink

function queclinkGetPassword(asset) {
	// asset.Password will be 'placeholder' for security reasons...
	if (asset.Password == null || asset.Password == "") {
		// use default
		switch (asset.DeviceId) {
			case devices.QUECLINK_GV200:
				return "gv200";
			case devices.QUECLINK_GV300:
				return "gv300";
			case devices.QUECLINK_GV600W:
				return "gv600w";
			case devices.QUECLINK_GV620MG:
				return "gv620mg";
			case devices.QUECLINK_GL200:
				return "gl200";
			case devices.QUECLINK_GL300:
				return "gl300";
			case devices.QUECLINK_GL300W:
				return "gl300w";
			case devices.QUECLINK_GL320MG:
				return "gl320m";
			case devices.QUECLINK_GL500:
				return "gl500";
			case devices.QUECLINK_GL520:
				return "gl520";
			case devices.QUECLINK_GL521MG:
				return "gl521m";
			case devices.QUECLINK_GV500:
			case devices.QUECLINK_GV500MAP:
				return "gv500";
			case devices.GOTEK_PRIMELITE:
				return "AIR11";
			case devices.QUECLINK_GT300:
				return "gt300";
			case devices.QUECLINK_GT301:
				return "gt301";
			case devices.QUECLINK_GB100:
				return "gb100";
			default:
				return asset.Password;
		}
	}
	return asset.Password;
}

export function queclinkCreateAPNCommand(assetId) {
	var asset = findAssetById(assetId);
	var apn = $j("#txtQueclinkAPN").val();
	var username = $j("#txtQueclinkAPNUsername").val();
	var password = $j("#txtQueclinkAPNPassword").val();

	var devicePassword = queclinkGetPassword(asset);
	var command = "";
	// todo: pull domain from config somehow
	switch (asset.DeviceId) {
		case devices.QUECLINK_GL200:
		case devices.QUECLINK_GL300:
		case devices.QUECLINK_GL300W:
		case devices.QUECLINK_GL320MG:
			command = "AT+GTQSS={pwd},{apn},{username},{password},4,,2,protocolparser.com,4753,0.0.0.0,0,,0,0,,,0000$";
			break;
		case devices.QUECLINK_GL500:
		case devices.QUECLINK_GL520:
		case devices.GOTEK_PRIMELITE:
			command = "AT+GTQSS={pwd},{apn},{username},{password},4,,0,protocolparser.com,4753,0.0.0.0,0,,0,0,,,0000$";
			break;
		case devices.QUECLINK_GL521MG:
			command = "AT+GTQSS={pwd},{apn},{username},{password},4,,2,protocolparser.com,4753,0.0.0.0,0,,0,0,0,300,0000$";
			break;
		case devices.QUECLINK_GV200:
		case devices.QUECLINK_GV300:
		case devices.QUECLINK_GV500:
		case devices.QUECLINK_GV500MAP:
		case devices.QUECLINK_GB100:
			command = "AT+GTQSS={pwd},{apn},{username},{password},4,,2,protocolparser.com,4753,0.0.0.0,0,,0,0,0,,0000$";
			break;
		case devices.QUECLINK_GT300:
			command = "AT+GTSRI={pwd},3,{apn},{username},{password},protocolparser.com,4753,,0000";
			break;
		case devices.QUECLINK_GT301:
			command = "AT+GTSRI={pwd},3,{apn},{username},{password},protocolparser.com,4753,,0000$";
			break;
		case devices.QUECLINK_GV600W:
		case devices.QUECLINK_GV620MG:
			command = "AT+GTQSS={pwd},{apn},{username},{password},4,,2,protocolparser.com,4753,0.0.0.0,0,,0,0,0,0,0000$";
			break;
	}
	command = command
		.replace("{pwd}", devicePassword)
		.replace("{username}", username)
		.replace("{apn}", apn)
		.replace("{password}", password);
	$j("#queclink-apn-form div.command-summary").text(command);
}

export function requestInmarsatCInformation(asset) {
	var data = {
		assetId: asset.Id,
	};
	var status = document.getElementById("inmarsatc-query-status");
	toggleLoadingMessage(true, "inmarsatc-information");
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/InmarsatCGetRegionStatus"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			var result = msg.d;
			if (result) {
				$j("#InmarsatCOceanRegions label").removeClass("success error");
				if (result.Success == true) {
					$j("#InmarsatCAORW")
						.text(result.AORW == null ? strings.UNKNOWN : result.AORW == true ? strings.DOWNLOADED : strings.QUEUED)
						.addClass(result.AORW == null ? "" : result.AORW == true ? "success" : "error");
					$j("#InmarsatCAORE")
						.text(result.AORE == null ? strings.UNKNOWN : result.AORE == true ? strings.DOWNLOADED : strings.QUEUED)
						.addClass(result.AORE == null ? "" : result.AORE == true ? "success" : "error");
					$j("#InmarsatCPOR")
						.text(result.POR == null ? strings.UNKNOWN : result.POR == true ? strings.DOWNLOADED : strings.QUEUED)
						.addClass(result.POR == null ? "" : result.POR == true ? "success" : "error");
					$j("#InmarsatCIOR")
						.text(result.IOR == null ? strings.UNKNOWN : result.IOR == true ? strings.DOWNLOADED : strings.QUEUED)
						.addClass(result.IOR == null ? "" : result.IOR == true ? "success" : "error");
					$j("#InmarsatCMORP")
						.text(result.MORP == null ? strings.UNKNOWN : result.MORP == true ? strings.DOWNLOADED : strings.QUEUED)
						.addClass(result.MORP == null ? "" : result.MORP == true ? "success" : "error");
				} else {
					// message failure, keep text field to allow retry
					formShowErrorMessage(status, strings.MSG_GET_DEVICE_INFORMATION_ERROR);
					if (result.ErrorMessage != null && result.ErrorMessage != "") {
						formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
					}
				}
			}
			toggleLoadingMessage(false, "inmarsatc-information");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_GET_DEVICE_INFORMATION_ERROR);
			toggleLoadingMessage(false, "inmarsatc-information");
		},
	});
}
