
import { map, switchMapMode } from "./map-base.js";
import { viewModes, mapModes, sortModes } from "./const.js";
import state from "./state.js";
import { getPostDataForAssetPositions } from "./assets-active.js"
import { wrapUrl } from "./wrapurl.js";
import { createMarkerPath } from "./marker-path.js";
import { findAssetById } from "./assets.js";
import { assetActiveNotifier } from "./asset-select.js";

import { fragment, hookSliderInput, setMinMax } from "./time-slider-ui.js";

import trajectorify from "./gleo/src/symboldecorators/trajectorify.mjs";
import intensify from "./gleo/src/symboldecorators/intensify.mjs";
import TintedSprite from "./gleo/src/symbols/TintedSprite.mjs";
import HeatPoint from "./gleo/src/symbols/HeatPoint.mjs";
import VertexDot from "./gleo/src/symbols/VertexDot.mjs";
import AcetateHeatMap from "./gleo/src/fields/HeatMap.mjs";
import AcetateHexBin from "./gleo/src/fields/HexBin.mjs";



import $ from "jquery";
import moment from "moment";

const TrajectorySprite = trajectorify(TintedSprite);
const TrajectoryHeatPoint = trajectorify(HeatPoint);
const HexBinDot = trajectorify(intensify(VertexDot));

$("#form-history-date-range").on("click", "#btnGoTimeSlider", (ev) => {
	ev.preventDefault();

	if (state.activeMapMode !== mapModes.TIME_SLIDER) {
		switchMapMode(mapModes.TIME_SLIDER, null, true);
	} else {
		// queryActiveAssets();	// Only for history mode
	}

});


export let trajectoryAcetate;

let heatmapAcetate;
let trajectoryHeatPointAcetate;


let startTimestamp;
let minTimestamp = Infinity;
let maxTimestamp = -Infinity;
let postData;

export async function enableTimeSlider(sensitivity, toPredefinedDateRange) {
	console.log("enabling time slider");

	setMinMax(NaN, NaN);
	document.body.appendChild(fragment);

	postData = getPostDataForAssetPositions(sensitivity, toPredefinedDateRange, /*loadLimitedData, dateFilter*/);

	/// TODO: Have a better way of requesting **more** than the default max of 3500
	/// positions since this will be able to handle it
	postData.loadLimitedData = true;

	const req = await fetch(
		wrapUrl("/services/GPSService.asmx/GetAssetPositionsForDateRange"),
		{
			method: "POST",
			body: JSON.stringify(postData),
			headers: new Headers({
				"Content-Type": "application/json; charset=utf-8",
			}),
		}
	);

	const json = (await req.json()).d;

	// Start of the interval, as unix timestamp (seconds from 1970).
	// This will be used as the relative zero for all other date-times.
	startTimestamp = moment(postData.fromDate, postData.format).unix();

	if (trajectoryAcetate) {
		// empty the acetate
		trajectoryAcetate.multiRemove(trajectoryAcetate.symbols);
		trajectoryHeatPointAcetate.multiRemove(trajectoryHeatPointAcetate.symbols);
	} else {
		trajectoryAcetate = map.platina.getAcetateOfClass(TrajectorySprite.Acetate);
		hookSliderInput((ev)=>{
			trajectoryAcetate.mValue = trajectoryHeatPointAcetate.mValue = ev.target.value;
		});

		// heatmapAcetate = new AcetateHeatMap(map.platina, {
		heatmapAcetate = new AcetateHexBin(map.platina, {
			// queryable: true,
			stops: {
				0: [0, 0, 0, 0],
				10: [0, 0, 255, 32],
				50: [0, 255, 255, 64],
				100: [255, 255, 0, 92],
				200: [255, 0, 0, 128],
			},
			cellSize: 64,	// applies to HexBin only
			marginSize: 8,	// applies to HexBin only
			zIndex: 3000,
		});

		// trajectoryHeatPointAcetate = new TrajectoryHeatPoint.Acetate(heatmapAcetate);
		trajectoryHeatPointAcetate = new HexBinDot.Acetate(heatmapAcetate);

	}

	window.trajectoryAcetate = trajectoryAcetate;	// DEBUG!!! FIXME: cleanup

	// console.log(postData, json);

	minTimestamp = Infinity;
	maxTimestamp = -Infinity;

	for (const asset of json.Assets) {
		assetToTrajectorySprite(asset, postData.format);
	}

	trajectoryAcetate.mValue = minTimestamp;

	console.log("time slider data loaded, from ", minTimestamp, " to ", maxTimestamp);

	setMinMax(minTimestamp, maxTimestamp, startTimestamp);

	assetActiveNotifier.addEventListener("select", onAssetSelected);
	assetActiveNotifier.addEventListener("deselect", onAssetDeselected);
}


export function disableTimeSlider() {
	// Clear the map
	trajectoryAcetate.multiRemove(trajectoryAcetate.symbols);

	// Hide the time slider
	document.body.removeChild(fragment);

	// Stop listening for assets being (de)selected
	assetActiveNotifier.removeEventListener("select", onAssetSelected);
	assetActiveNotifier.removeEventListener("deselect", onAssetDeselected);
}

async function onAssetSelected(ev) {

	const req = await fetch(
		wrapUrl("/services/GPSService.asmx/GetAssetPositionsForDateRange"),
		{
			method: "POST",
			body: JSON.stringify({
				...postData,
				assetIds: ev.detail.Id,
			}),
			headers: new Headers({
				"Content-Type": "application/json; charset=utf-8",
			}),
		}
	);

	const json = (await req.json()).d;

	for (const asset of json.Assets) {
		assetToTrajectorySprite(asset, postData.format);
	}

}

function onAssetDeselected(ev) {
	trajectoryAcetate.symbols.find(s=>s.assetId === ev.detail.Id)?.remove();
	trajectoryHeatPointAcetate.symbols.find(s=>s.assetId === ev.detail.Id)?.remove();
}



// Spawns a trajectory sprite given a data structure with an asset and its
// positions
function assetToTrajectorySprite(asset, dateformat) {

		if (!asset.Positions.length) {
			// skip assets with zero positions in the desired interval
			return;
		}

		const positions = asset.Positions.map(p => ({
			latlng: [p.Lat, p.Lng],
			timestamp: moment(p.Time, dateformat).unix() - startTimestamp
		})).sort((a,b)=>a.timestamp-b.timestamp);

		minTimestamp = Math.min(minTimestamp, positions[0].timestamp);
		maxTimestamp = Math.max(maxTimestamp, positions[positions.length - 1].timestamp);

		const geom = [];	// The geometry shall be a multipoint
		const mvalues = [];	// The M-values shall be the timestamps, relative to the
		// start of the interval

		for (const position of positions) {
			geom.push(position.latlng);
			mvalues.push(position.timestamp);
		}

		// Add one extra segment at the end, so the asset is visible when stopped
		geom.push(positions[positions.length - 1].latlng);
		mvalues.push(1e20);	// WebGL1 cannot handle IEEE float infinity, use this instead


		// Fetch asset to get the asset type (truck/plane/etc)
		const assetStuff = findAssetById(asset.Id);


		const imagePath = createMarkerPath(
			assetStuff.Class,	// Asset class
			"white", 	// Colour, handled by tint
			null, 	// Course
			null, 	// Alpha
			asset.Id,
			false,
			'',	// Event type, handled by status sprite
			false,	// isFirst
			false	// isLast
		);

		const sprite = new TrajectorySprite(geom, {
			mcoords: mvalues,
			image: imagePath,
			spriteAnchor: [18, 18],
			spriteSize: [36, 36],
			tint: assetStuff.Color ?? [255, 255, 255, 255],
		});

		sprite.assetId = asset.Id;

		sprite.addTo(trajectoryAcetate);

		// const heatPoint = new TrajectoryHeatPoint(geom, {
		const heatPoint = new HexBinDot(geom, {
			mcoords: mvalues,
			radius: 50,
			intensity: 10,
		});

		heatPoint.assetId = asset.Id;

		heatPoint.addTo(trajectoryHeatPointAcetate);
}









