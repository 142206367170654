import strings from "./strings.js";
import trkData from "./data.js";
import state from "./state.js";
import { viewModes, mapModes } from "./const.js";
import { intervals } from "./timers.js";
import domNodes from "./domNodes.js";
import { findAssetById } from "./assets.js";
import { toggleAssetActive } from "./asset-select.js";
import { getPositionInHistoryRelative } from "./position-history.js";

import $ from "jquery";
import _ from "lodash";
import { el, svg } from "redom"; // https://redom.js.org/

export function initPlayback() {
	$(domNodes.infoDialogs.mapItemInformation).on("click", "#playback-first", function (e) {
		e.preventDefault();
		playbackFirstPosition();
	});
	$(domNodes.infoDialogs.mapItemInformation).on("click", "#playback-prev", function (e) {
		e.preventDefault();
		playbackPrevPosition();
	});
	$(domNodes.infoDialogs.mapItemInformation).on("click", "#playback-next", function (e) {
		e.preventDefault();
		playbackNextPosition();
	});
	$(domNodes.infoDialogs.mapItemInformation).on("click", "#playback-last", function (e) {
		e.preventDefault();
		playbackLastPosition();
	});
	$(domNodes.infoDialogs.mapItemInformation).on("click", "#playback", function (e) {
		e.preventDefault();
		playbackClick();
	});
}

export function createPositionPlaybackForAsset(asset, marker) {
	var location = marker.data.location;
	var tripId = marker.data.tripId;
	var isForTrip = tripId !== undefined && tripId !== null;
	if (
		state.activeMapMode === mapModes.LIVE &&
		state.activeViewMode === viewModes.NORMAL &&
		(tripId === undefined || tripId === null)
	) {
		return null;
	}

	// include position history playback buttons in history mode
	var playbackTextElements;
	var total = null;
	var current = null;

	var linkOptions = {
		dataset: { assetId: asset.Id, positionId: location.Id },
		href: "#",
		title: strings.PLAYBACK_PLAY,
	};
	if (isForTrip) {
		linkOptions.dataset.tripId = tripId;
	}

	if (state.isInPlaybackMode) {
		total = trkData.playback.positions.length;
		current = trkData.playback.positions.length - trkData.playback.index;
		playbackTextElements = [svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#pause-solid" } }))]; // text(strings.PLAYBACK_STOP)
		linkOptions.title = strings.PLAYBACK_STOP;
	} else {
		// todo: doesn't work with trips
		// determine current position in history through calculation
		total = null;
		current = null;
		var assetPositions = [];
		if (isForTrip) {
			assetPositions = trkData.trips.positionsByTripId[tripId];
		} else if (state.activeViewMode === viewModes.NORMAL) {
			assetPositions = trkData.history.positionsByAssetId[asset.Id];
		} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
			assetPositions = trkData.sharedView.positionsByAssetId[asset.Id];
		}

		if (assetPositions !== undefined) {
			assetPositions = assetPositions.Positions;
			for (var j = 0; j < assetPositions.length; j++) {
				if (assetPositions[j].Id === location.Id) {
					total = assetPositions.length;
					current = assetPositions.length - j;
					break;
				}
			}
		}

		playbackTextElements = [svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#play-solid" } }))]; // text(strings.PLAYBACK_PLAY)
		linkOptions.title = strings.PLAYBACK_PLAY;
	}

	var playbackStatus = strings.POSITION_INDEX.replace("{0}", current).replace("{1}", total) + " ";
	var disablePrev = current === 1;
	var disableNext = current >= total;

	var playback = [
		el(
			"a#playback-first.btn.btn-secondary.btn-sm.playback-link" + (disablePrev ? ".disabled" : ""),
			{ href: "#", title: strings.FIRST },
			[svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#fast-backward-solid" } }))]
		), // , el('span', strings.FIRST)
		el(
			"a#playback-prev.btn.btn-secondary.btn-sm.playback-link" + (disablePrev ? ".disabled" : ""),
			{ href: "#", title: strings.POSITION_PREV },
			[svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#backward-solid" } }))]
		), //, el('span', strings.POSITION_PREV)
		el("span#playback-status.btn.btn-secondary.btn-sm.disabled.w-100", playbackStatus),
		el(
			"a#playback-next.btn.btn-secondary.btn-sm.playback-link" + (disableNext ? ".disabled" : ""),
			{ href: "#", title: strings.POSITION_NEXT },
			[svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#forward-solid" } }))]
		), //el('span', strings.POSITION_NEXT),
		el(
			"a#playback-last.btn.btn-secondary.btn-sm.playback-link" + (disableNext ? ".disabled" : ""),
			{ href: "#", title: strings.LAST },
			[svg("svg", svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#fast-forward-solid" } }))]
		), // el('span', strings.LAST),
		el(
			"a#playback.btn.btn-secondary.btn-sm.playback-link" + (disableNext ? ".disabled" : ""),
			linkOptions,
			playbackTextElements
		),
	];
	return el("div.btn-group.w-100.my-1.playback-container", playback);
}

export function playbackPrevPosition() {
	var link = document.getElementById("playback");
	var assetId = parseInt(link.getAttribute("data-asset-id"));
	var positionId = link.getAttribute("data-position-id");
	var tripId = link.getAttribute("data-trip-id");
	if (tripId !== null) {
		tripId = parseInt(tripId);
	}

	if (state.activeMapMode === mapModes.LIVE && state.activeViewMode === viewModes.NORMAL && tripId === null) {
		return;
	}

	// get previous position id
	var relativePosition = getPositionInHistoryRelative(assetId, positionId, tripId, 1);
	if (relativePosition == null) {
		return;
	}

	// reset the playback interval if in playback mode
	if (state.isInPlaybackMode) {
		if (intervals.playbackInterval != null) {
			clearInterval(intervals.playbackInterval);
		}
		intervals.playbackInterval = setInterval(playbackNext, 5000);
		trkData.playback.index++;
	}

	// open its marker
	openMarkerForPosition(relativePosition.Id, tripId);
}

function playbackLastPosition() {
	var link = document.getElementById("playback");
	var assetId = parseInt(link.getAttribute("data-asset-id"));
	var tripId = link.getAttribute("data-trip-id");
	if (tripId !== null) {
		tripId = parseInt(tripId);
	}

	// doesn't have to be in live mode if playing back a trip
	if (state.activeMapMode === mapModes.LIVE && state.activeViewMode === viewModes.NORMAL && tripId === null) {
		return;
	}

	var relativePosition = null;
	var assetPositions = [];
	if (tripId !== null) {
		assetPositions = trkData.trips.positionsByTripId[tripId].Positions;
	} else if (state.activeViewMode === viewModes.NORMAL) {
		assetPositions = trkData.history.positionsByAssetId[assetId].Positions;
	} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
		assetPositions = trkData.sharedView.positionsByAssetId[assetId].Positions;
	}
	if (assetPositions.length === 0) {
		return;
	}

	relativePosition = assetPositions[0];

	// reset the playback interval if in playback mode
	if (state.isInPlaybackMode) {
		if (intervals.playbackInterval != null) {
			clearInterval(intervals.playbackInterval);
		}
		intervals.playbackInterval = setInterval(playbackNext, 5000);
		trkData.playback.index = 0;
	}

	// open its marker
	openMarkerForPosition(relativePosition.Id, tripId);
}

function playbackFirstPosition() {
	var link = document.getElementById("playback");
	var assetId = parseInt(link.getAttribute("data-asset-id"));
	var tripId = link.getAttribute("data-trip-id");
	if (tripId !== null) {
		tripId = parseInt(tripId);
	}

	if (state.activeMapMode === mapModes.LIVE && state.activeViewMode === viewModes.NORMAL && tripId === null) {
		return;
	}

	var relativePosition = null;
	var assetPositions = [];
	if (tripId !== null) {
		assetPositions = trkData.trips.positionsByTripId[tripId].Positions;
	} else if (state.activeViewMode === viewModes.NORMAL) {
		assetPositions = trkData.history.positionsByAssetId[assetId].Positions;
	} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
		assetPositions = trkData.sharedView.positionsByAssetId[assetId].Positions;
	}
	if (assetPositions.length === 0) {
		return;
	}
	relativePosition = assetPositions[assetPositions.length - 1];

	if (relativePosition == null) {
		return;
	}

	// reset the playback interval if in playback mode
	if (state.isInPlaybackMode) {
		if (intervals.playbackInterval != null) {
			clearInterval(intervals.playbackInterval);
		}
		intervals.playbackInterval = setInterval(playbackNext, 5000);
		trkData.playback.index = assetPositions.length - 1;
	}

	// open its marker
	openMarkerForPosition(relativePosition.Id, tripId);
}

export function playbackNextPosition() {
	var link = document.getElementById("playback");
	var assetId = parseInt(link.getAttribute("data-asset-id"));
	var positionId = link.getAttribute("data-position-id");
	var tripId = link.getAttribute("data-trip-id");
	if (tripId !== null) {
		tripId = parseInt(tripId);
	}

	if (state.activeMapMode === mapModes.LIVE && state.activeViewMode === viewModes.NORMAL && tripId === null) {
		return;
	}

	var relativePosition = getPositionInHistoryRelative(assetId, positionId, tripId, -1);
	if (relativePosition == null) {
		return;
	}

	// reset the playback interval if in playback mode
	if (state.isInPlaybackMode) {
		if (intervals.playbackInterval != null) {
			clearInterval(intervals.playbackInterval);
		}
		intervals.playbackInterval = setInterval(playbackNext, 5000);
		trkData.playback.index--;
	}

	// open its marker
	openMarkerForPosition(relativePosition.Id, tripId);
}

function playbackNext() {
	if (
		state.activeMapMode === mapModes.LIVE &&
		state.activeViewMode === viewModes.NORMAL &&
		trkData.playback.tripId === null
	) {
		playbackEnd();
	}
	if (trkData.playback.positions == null) {
		playbackEnd();
	}

	// move to the next position in playback index
	trkData.playback.index--;
	if (trkData.playback.positions.length > trkData.playback.index && trkData.playback.index >= 0) {
		var position = trkData.playback.positions[trkData.playback.index];
		openMarkerForPosition(position.Id, trkData.playback.tripId);
		if (trkData.playback.index == 0) {
			playbackEnd();
		}
	} else {
		// reached the last position
		playbackEnd();
	}
}

export function playbackStart(assetId, positionId, tripId) {
	console.log("playback: " + assetId + "," + positionId + "," + tripId);
	var asset = findAssetById(assetId);
	if (asset == null) {
		return;
	}

	var playbackPositions = [];
	trkData.playback.assetId = assetId;
	trkData.playback.tripId = tripId;
	if (tripId === null && state.activeViewMode === viewModes.NORMAL) {
		playbackPositions = trkData.history.positionsByAssetId[assetId];
	} else if (tripId !== undefined && tripId !== null) {
		playbackPositions = trkData.trips.positionsByTripId[tripId];
	} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
		playbackPositions = trkData.sharedView.positionsByTripId[tripId];
	}
	if (playbackPositions !== undefined && playbackPositions !== null) {
		trkData.playback.positions = playbackPositions.Positions;
		// find current position
		for (var j = 0; j < trkData.playback.positions.length; j++) {
			if (trkData.playback.positions[j].Id == positionId) {
				trkData.playback.index = j;
				if (intervals.playbackInterval != null) {
					clearInterval(intervals.playbackInterval);
				}
				intervals.playbackInterval = setInterval(playbackNext, 5000);
				var total = trkData.playback.positions.length;
				var current = trkData.playback.positions.length - trkData.playback.index;
				var positionStatus = strings.POSITION_INDEX.replace("{0}", current).replace("{1}", total) + " ";
				$("#playback-status").text(positionStatus);
				var playback = document.getElementById("playback");
				playback.title = strings.PLAYBACK_STOP;
				playback
					.querySelector("use")
					.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#pause-solid");
				state.isInPlaybackMode = true;
				break;
			}
		}
	}
}

function playbackClick() {
	// start playback from this position if not already running
	if (state.isInPlaybackMode) {
		playbackEnd();
	} else {
		var link = document.getElementById("playback");
		if (link === null) {
			return false;
		}
		var assetId = parseInt(link.getAttribute("data-asset-id"));
		var positionId = link.getAttribute("data-position-id");
		var tripId = link.getAttribute("data-trip-id");
		if (tripId !== null) {
			tripId = parseInt(tripId);
		}
		if (state.activeMapMode === mapModes.LIVE && state.activeViewMode === viewModes.NORMAL && tripId === null) {
			return false;
		}
		playbackStart(assetId, positionId, tripId);
	}
	return false;
}

export function playbackEnd() {
	clearInterval(intervals.playbackInterval);
	trkData.playback.assetId = null;
	trkData.playback.tripId = null;
	state.isInPlaybackMode = false;
	trkData.playback.positions = [];
	trkData.playback.index = 0;
	var playback = document.getElementById("playback");
	if (playback !== null) {
		playback.title = strings.PLAYBACK_PLAY;
		playback
			.querySelector("use")
			.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#play-solid");
	}
}

export function openMarkerForPosition(positionId, tripId) {
	var marker = null;
	if (tripId !== null) {
		marker = _.find(trkData.trips.markersByTripId[tripId], function (item) {
			return item.data.location.Id === positionId;
		});
	} else if (state.activeViewMode === viewModes.NORMAL) {
		marker = _.find(trkData.history.markers, function (item) {
			return item.data.location.Id === positionId;
		});
	} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
		marker = _.find(trkData.sharedView.markers, function (item) {
			return item.data.location.Id === positionId;
		});
	}
	if (marker !== undefined) {
		// if the asset for this location is not active, make it active
		if (tripId === null) {
			toggleAssetActive(marker.data.assetId, true, true);
		}
		marker.fire("click");
	}
}
