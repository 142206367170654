import strings from "./strings.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import { formShowErrorMessage } from "./ajax.js";

import $ from "jquery";
import $j from "jquery";

// start Edge Solar
export function requestEdgeSolarInformation(assetId) {
	var data = {
		assetId: assetId,
	};
	var status = document.getElementById("edge-solar-query-status");
	toggleLoadingMessage(true, "edge-solar-information");
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/EdgeSolarGetConfiguration"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			var result = msg.d;
			if (result) {
				if (result.Success == true) {
					if (result.LastRetrievedOn == null) {
						$j("#EdgeSolarConfigLastRetrieved").text(strings.NEVER);
					} else {
						$j("#EdgeSolarConfigLastRetrieved").text(result.LastRetrievedOn);
					}
					if (result.LastQueriedOn == null) {
						$j("#EdgeSolarConfigLastQueried").text(strings.NEVER);
					} else {
						$j("#EdgeSolarConfigLastQueried").text(result.LastQueriedOn);
					}

					var config = result.Configuration;
					populateEdgeConfig(config, assetId);
				} else {
					// message failure, keep text field to allow retry
					formShowErrorMessage(status, strings.MSG_GET_DEVICE_INFORMATION_ERROR);
					if (result.ErrorMessage != null && result.ErrorMessage != "") {
						formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
					}
				}
			}
			toggleLoadingMessage(false, "edge-solar-information");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_GET_DEVICE_INFORMATION_ERROR);
			toggleLoadingMessage(false, "edge-solar-information");
		},
	});
}

function populateEdgeConfig(config, assetId) {
	if (config == null) {
		// defaults
		for (var i = 1; i <= 8; i++) {
			document.getElementById("EdgeSolarReportingInterval" + i + "Disabled").checked = true;
			document.getElementById("EdgeSolarReportingInterval" + i + "IntervalMinutes").value = "0";
			document.getElementById("EdgeSolarReportingInterval" + i + "TimeHour").value = "0";
			document.getElementById("EdgeSolarReportingInterval" + i + "TimeMinute").value = "00";
		}
		document.getElementById("EdgeSolarReportingInterval1Interval").checked = true;
		document.getElementById("EdgeSolarReportingInterval1IntervalMinutes").value = "720";

		document.getElementById("EdgeSolarVbmrMode").value = "0";
		document.getElementById("EdgeSolarVbmrFields").classList.remove("is-visible");
		document.getElementById("EdgeSolarVbmrIntervalMinutes").value = "0";
		document.getElementById("EdgeSolarVbmrIsEnabledDuringPowerSaveNo").checked = true;

		document.getElementById("EdgeSolarGbmrIsEnabledNo").checked = true;
		document.getElementById("EdgeSolarGbmrFields").classList.remove("is-visible");
		document.getElementById("EdgeSolarGbmrCheckIntervalMinutes").value = "0";
		document.getElementById("EdgeSolarGbmrHomeRatio").value = "0";
		document.getElementById("EdgeSolarGbmrAwayRatio").value = "0";

		document.getElementById("EdgeSolarPowerSaveIsEnabledNo").checked = true;
		document.getElementById("EdgeSolarPowerSaveFields").classList.remove("is-visible");
		(document.getElementById("EdgeSolarPowerSaveIsDays").value = "0"),
			(document.getElementById("EdgeSolarPowerSaveCount").value = "0");
		document.getElementById("EdgeSolarPowerSaveIntervalHours").value = "0";

		document.getElementById("EdgeSolarEngineeringMessageInterval").value = "336";
		document.getElementById("EdgeSolarMailboxCheckIntervalHours").value = "0";
		document.getElementById("EdgeSolarMailboxCheckIsEnabledFalse").checked = true;
		document.getElementById("EdgeSolarIridiumRetryCount").value = "3";
		return;
	}

	// intervals
	for (var i = 1; i <= 8; i++) {
		var interval = "Interval" + i;
		var conf = config[interval];
		if (!conf.IsEnabled) {
			document.getElementById("EdgeSolarReporting" + interval + "Disabled").checked = true;
		} else {
			if (conf.IsTimed) {
				document.getElementById("EdgeSolarReporting" + interval + "TimeOfDay").checked = true;
			} else {
				document.getElementById("EdgeSolarReporting" + interval + "Interval").checked = true;
			}
		}

		// time of day, EdgeSolarReportingInterval2TimeHour, EdgeSolarReportingInterval2TimeMinute
		var hours = Math.floor(conf.TimeMinutesPastUtc / 60).toString();
		if (hours.length == 1) {
			hours = "0" + hours;
		}
		var minutes = (conf.TimeMinutesPastUtc % 60).toString();
		if (minutes.length == 1) {
			minutes = "0" + minutes;
		}
		// minutes to hours, minutes
		document.getElementById("EdgeSolarReporting" + interval + "TimeHour").value = hours;
		document.getElementById("EdgeSolarReporting" + interval + "TimeMinute").value = minutes;

		// interval in minutes
		document.getElementById("EdgeSolarReporting" + interval + "IntervalMinutes").value = conf.IntervalMinutes;
	}

	// vbmr
	document.getElementById("EdgeSolarVbmrMode").value = config.VbmrMode;
	document.getElementById("EdgeSolarVbmrIntervalMinutes").value = config.VbmrIntervalMinutes;
	if (config.VbmrIsEnabledDuringPowerSave) {
		document.getElementById("EdgeSolarVbmrIsEnabledDuringPowerSaveYes").checked = true;
	} else {
		document.getElementById("EdgeSolarVbmrIsEnabledDuringPowerSaveNo").checked = true;
	}

	// gbmr
	if (config.GbmrIsEnabled) {
		document.getElementById("EdgeSolarGbmrIsEnabledYes").checked = true;
	} else {
		document.getElementById("EdgeSolarGbmrIsEnabledNo").checked = true;
	}
	document.getElementById("EdgeSolarGbmrCheckIntervalMinutes").value = config.GbmrCheckIntervalMinutes;
	document.getElementById("EdgeSolarGbmrAwayRatio").value = config.GbmrAwayRatio;
	document.getElementById("EdgeSolarGbmrHomeRatio").value = config.GbmrHomeRatio;

	// power save
	if (config.PowerSaveIsEnabled) {
		document.getElementById("EdgeSolarPowerSaveIsEnabledYes").checked = true;
	} else {
		document.getElementById("EdgeSolarPowerSaveIsEnabledNo").checked = true;
	}
	if (config.PowerSaveIsDays) {
		document.getElementById("EdgeSolarPowerSaveIsDays").value = "1";
	} else {
		document.getElementById("EdgeSolarPowerSaveIsDays").checked = "0";
	}
	document.getElementById("EdgeSolarPowerSaveCount").value = config.PowerSaveCount;
	document.getElementById("EdgeSolarPowerSaveIntervalHours").value = config.PowerSaveIntervalHours;

	// settings
	document.getElementById("EdgeSolarEngineeringMessageInterval").value = config.EngineeringMessageIntervalHours;
	document.getElementById("EdgeSolarMailboxCheckIntervalHours").value = config.MailboxCheckIntervalHours;
	if (config.MailboxCheckIsEnabled) {
		document.getElementById("EdgeSolarMailboxCheckIsEnabledTrue").checked = true;
	} else {
		document.getElementById("EdgeSolarMailboxCheckIsEnabledFalse").checked = true;
	}
	document.getElementById("EdgeSolarMailboxCheckIntervalHours").value = config.MailboxCheckIntervalHours;
	document.getElementById("EdgeSolarIridiumRetryCount").value = config.IridiumRetryCount;

	// toggle enabled/disabled and is-visible based on item selection
	toggleEdgeSolarVisibleElements();
}

export function toggleEdgeSolarVisibleElements() {
	if ($("input[name=EdgeSolarPowerSaveIsEnabled]:checked").val() === "1") {
		$("#EdgeSolarPowerSaveFields").addClass("is-visible");
	} else {
		$("#EdgeSolarPowerSaveFields").removeClass("is-visible");
	}
	if ($("input[name=EdgeSolarGbmrIsEnabled]:checked").val() === "1") {
		$("#EdgeSolarGbmrFields").addClass("is-visible");
	} else {
		$("#EdgeSolarGbmrFields").removeClass("is-visible");
	}
	if ($("#EdgeSolarVbmrMode").val() !== "0") {
		$("#EdgeSolarVbmrFields").addClass("is-visible");
	} else {
		$("#EdgeSolarVbmrFields").removeClass("is-visible");
	}

	// TODO disable things based on chosen radio elements
}

// end Edge Solar
