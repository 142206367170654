import trkData from "./data.js";
import domNodes from "./domNodes.js";
import state from "./state.js";
import { clearSettingsNode } from "./shared-view.js";
import strings from "./strings.js";
import options from "./options.js";
import user from "./user.js";
import { svgPath, getSvgIconForItemType } from "./dom-util.js";
import { openDialogPanel } from "./panel-nav.js";
import { getGenericIconUrlForItemType } from "./marker-path.js";
import {
	highlightActiveItemInPrimaryPanel,
	openSecondaryPanel,
	changeActivePrimaryPanelToMatchSecondary,
} from "./panel.js";
import {
	isWaypointEnabledForAsset,
	isMessagingEnabledForAssetGroup,
	isMessagingEnabledForAsset,
	isOutputEnabledForAsset,
	isServiceMeterEnabledForAsset,
	findAssetById,
	findAssetIdsUnderGroup,
} from "./assets.js";
import { findJourneyById } from "./journey.js";
import { findGroupById } from "./asset-group.js";
import { findFenceById, findAssetIdsInGeofence } from "./fence.js";
import { findPlaceById } from "./place.js";
import { findTripById } from "./trips.js";
import { findSharedViewById } from "./shared-view.js";
import { querySharedViewData } from "./shared-view.js";
import { checkForShareViewChange } from "./shared-view.js";
import { isSendCommandDisabledForDevice, findDeviceById } from "./devices.js";
import { isFollowingGroup } from "./asset-live-follow.js";

import $ from "jquery";
import _ from "lodash";

export function itemSettingsClick(e) {
	var li = this.parentNode.parentNode;
	if (
		this.hasAttribute("data-asset-id") ||
		this.hasAttribute("data-group-id") ||
		this.hasAttribute("data-fence-id") ||
		this.hasAttribute("data-place-id") ||
		this.hasAttribute("data-trip-id") ||
		this.hasAttribute("data-shared-view-id")
	) {
		li = this;
	}
	if (li.hasAttribute("data-asset-id")) {
		var assetId = li.getAttribute("data-asset-id");
		var asset = findAssetById(assetId);
		if (asset === null) {
			return;
		}

		openAssetSettingsPanel(asset);
	} else if (li.hasAttribute("data-group-id")) {
		var groupId = li.getAttribute("data-group-id");
		var groupType = li.getAttribute("data-group-for");

		if (groupType === "journeys") {
			var journeyId = parseInt(groupId.substring(8));
			var journey = findJourneyById(journeyId);
			if (journey !== null) {
				openJourneySettingsPanel(journey);
				return;
			}
		}
		var group = findGroupById(groupId);
		if (group === null) {
			return;
		}

		switch (groupType) {
			case "assets":
				openAssetGroupSettingsPanel(group);
				break;
			case "fences":
				break;
			case "places":
				break;
			case "shared-views":
				break;
			default:
				break;
		}
	} else if (li.hasAttribute("data-fence-id")) {
		var fenceId = li.getAttribute("data-fence-id");
		var fence = findFenceById(fenceId);
		if (fence === null) {
			return;
		}

		openFenceSettingsPanel(fence);
	} else if (li.hasAttribute("data-place-id")) {
		var placeId = li.getAttribute("data-place-id");
		var place = findPlaceById(placeId);
		if (place === null) {
			return;
		}

		openPlaceSettingsPanel(place);
	} else if (li.hasAttribute("data-trip-id")) {
		var tripId = li.getAttribute("data-trip-id");
		var trip = findTripById(tripId);
		if (trip === null) {
			return;
		}
		openTripSettingsPanel(trip);
	} else if (li.hasAttribute("data-shared-view-id")) {
		var sharedViewId = li.getAttribute("data-shared-view-id");
		var sharedView = findSharedViewById(sharedViewId);
		if (sharedView === null) {
			return;
		}
		openSharedViewSettingsPanel(sharedView);
	}
}

export function openSettingsPanel(type, item, isDialog) {
	var icon = null;
	var svgIcon = null;
	var id = null;
	// var title = null;

	changeActivePrimaryPanelToMatchSecondary(type);

	if (item !== null) {
		id = item.Id;
		// title = item.Name;
		icon = getGenericIconUrlForItemType(type, item);
		svgIcon = getSvgIconForItemType(type, item);
	}

	var panelType = isDialog ? "dialog" : type;
	var panel = domNodes.panels.secondary;
	if (id !== null) {
		panel.setAttribute("data-item-type", type);
		panel.setAttribute("data-item-id", id);
	} else {
		panel.removeAttribute("data-item-type");
		panel.removeAttribute("data-item-id");
	}

	panel.setAttribute("data-group-for", panelType);
	panel.classList.remove("is-full-width");

	var itemOptions = document.getElementById("panel-secondary-item-options");
	//var itemNav = document.getElementById('panel-secondary-item-nav');
	var itemPrev = document.getElementById("panel-secondary-item-prev");
	var itemNext = document.getElementById("panel-secondary-item-next");
	var itemTitle = document.getElementById("panel-secondary-title");
	if (id === null) {
		// panel unrelated to a specific item (Get Route, Add Asset, etc)
		// so only the title should be shown
		// item selector should be hidden
		itemOptions.classList.remove("is-visible");
		itemPrev.classList.remove("is-visible");
		itemNext.classList.remove("is-visible");
		itemTitle.classList.add("is-visible");
	} else {
		// item selector should be shown
		itemOptions.classList.add("is-visible");
		itemPrev.classList.add("is-visible");
		itemNext.classList.add("is-visible");
		itemTitle.classList.remove("is-visible");
	}

	// clear all previous icons highlighted from having the prior panel open
	// todo: store previously selected item to deselect it instead of having to iterate all
	_.each(domNodes.assets, function (assetNodes) {
		_.each(assetNodes, clearSettingsNode);
	});
	_.each(domNodes.groups, clearSettingsNode);
	_.each(domNodes.fences, clearSettingsNode);
	_.each(domNodes.places, clearSettingsNode);
	//_.each(domNodes.journeys, clearSettingsNode); // handled by groups
	_.each(domNodes.trips, clearSettingsNode);
	_.each(domNodes.sharedViews, clearSettingsNode);

	// highlight item this panel is related to
	if (id !== null) {
		highlightActiveItemInPrimaryPanel(type, id);
	}

	// set icon
	var panelTitle = panel.querySelector(".panel-title");
	var panelSvgIcon = panel.querySelector(".panel-svg-icon");
	if (svgIcon !== null) {
		panelSvgIcon.classList.add("is-visible");
		panelSvgIcon.setAttribute(
			"style",
			"color: " + item.Color + "; --color-primary: " + item.Color + "; --color-secondary: " + item.Color
		);
		panelSvgIcon.querySelector("use").setAttributeNS("http://www.w3.org/1999/xlink", "href", svgIcon);
		panelTitle.style.backgroundImage = null;
	} else {
		panelSvgIcon.classList.remove("is-visible");
		if (icon !== null) {
			panelTitle.classList.add("has-icon");
		} else {
			panelTitle.classList.remove("has-icon");
		}
		panelTitle.style.backgroundImage = icon;
	}

	// hide any previous panel content
	var functionPanels = domNodes.panels.secondary.querySelectorAll(".item-group-functions");
	_.each(functionPanels, function (panel, index, list) {
		panel.classList.remove("is-visible");
	});

	// show panel content relevant to chosen item
	var functionPanel = document.getElementById(panelType + "-functions");
	functionPanel.classList.add("is-visible");

	openSecondaryPanel(true);
}

export function openAssetGroupSettingsPanel(group) {
	openDialogPanel(null, group.Name, group, false, null, "group", "group-options", openAssetGroupSettingsPanel);

	var groupMenuDom = {
		message: document.getElementById("group-function-message"),
		historyReplay: document.getElementById("group-function-history-replay"),
		followGroup: document.getElementById("group-function-follow-group"),
		editGroup: document.getElementById("group-function-edit-group"),
		deleteGroup: document.getElementById("group-function-delete-group"),
		copyGroup: document.getElementById("group-function-copy-group"),
	};

	var followText = groupMenuDom.followGroup.querySelector(".item-action-text");
	if (isFollowingGroup(group)) {
		followText.textContent = strings.UNFOLLOW_LIVE;
	} else {
		followText.textContent = strings.FOLLOW_LIVE;
	}

	_.each(groupMenuDom, function (item) {
		item.classList.remove("disabled");
		item.querySelector("a").classList.remove("disabled");
	});

	if ((user.isAnonymous && !options.allowAnonymousMessaging) || !isMessagingEnabledForAssetGroup(group)) {
		disableSettingsMenuItem(groupMenuDom.message);
	}

	// enable/disable menu items based on user permissions and group status
	if (trkData.assets === null || trkData.assets.length === 0 || findAssetIdsUnderGroup(group) === 0) {
		disableSettingsMenuItem(groupMenuDom.historyReplay);
		disableSettingsMenuItem(groupMenuDom.followGroup);
	}

	if (user.isAnonymous || user.canEditAssetGroups === undefined || !user.canEditAssetGroups) {
		disableSettingsMenuItem(groupMenuDom.editGroup);
		disableSettingsMenuItem(groupMenuDom.deleteGroup);
		disableSettingsMenuItem(groupMenuDom.copyGroup);
	}

	if (group.IsDefault) {
		disableSettingsMenuItem(groupMenuDom.editGroup);
		disableSettingsMenuItem(groupMenuDom.deleteGroup);
	}

	if (options.enabledFeatures.indexOf("UI_DUPLICATE_GROUP") === -1) {
		disableSettingsMenuItem(groupMenuDom.copyGroup);
	}

	// remove some options altogether based on menu exclusion options
	_.each(options.assetMenuExclude, function (excluded) {
		var domName = dashToCamelCase(excluded);
		if (groupMenuDom[domName] !== undefined) {
			if (groupMenuDom[domName].parentNode !== null) {
				groupMenuDom[domName].parentNode.removeChild(groupMenuDom[domName]);
			}
		}
	});
}

export function openPlaceSettingsPanel(place) {
	openDialogPanel(null, place.Name, place, false, null, "place", "place-options", openPlaceSettingsPanel);

	var placeMenuDom = {
		routeAsset: document.getElementById("place-function-route-asset"),
		editPlace: document.getElementById("place-function-edit-place"),
		deletePlace: document.getElementById("place-function-delete-place"),
	};

	_.each(placeMenuDom, function (item) {
		item.classList.remove("disabled");
	});

	// enable/disable menu items based on user permissions and place status
	if (
		trkData.places === null ||
		trkData.places.length === 0 ||
		trkData.assets === null ||
		trkData.assets.length === 0 ||
		options.enabledFeatures.indexOf("ASSET_ROUTING") === -1
	) {
		disableSettingsMenuItem(placeMenuDom.routeAsset);
	}

	if (user.isAnonymous || user.canEditPlaces === undefined || !user.canEditPlaces) {
		disableSettingsMenuItem(placeMenuDom.editPlace);
		disableSettingsMenuItem(placeMenuDom.deletePlace);
	}

	// remove some options altogether based on menu exclusion options
	_.each(options.assetMenuExclude, function (excluded) {
		var domName = dashToCamelCase(excluded);
		if (placeMenuDom[domName] !== undefined) {
			if (placeMenuDom[domName].parentNode !== null) {
				placeMenuDom[domName].parentNode.removeChild(placeMenuDom[domName]);
			}
		}
	});
}

export function openTripSettingsPanel(trip) {
	openDialogPanel(null, trip.Name, trip, false, null, "trip", "trip-options", openTripSettingsPanel);

	var itemMenuDom = {
		editTrip: document.getElementById("trip-function-edit-trip"),
		deleteTrip: document.getElementById("trip-function-delete-trip"),
	};

	_.each(itemMenuDom, function (item) {
		item.classList.remove("disabled");
	});

	if (user.isAnonymous || user.canEditJourneys === undefined || !user.canEditJourneys) {
		disableSettingsMenuItem(itemMenuDom.editTrip);
		disableSettingsMenuItem(itemMenuDom.deleteTrip);
	}

	// remove some options altogether based on menu exclusion options
	_.each(options.assetMenuExclude, function (excluded) {
		var domName = dashToCamelCase(excluded);
		if (itemMenuDom[domName] !== undefined) {
			if (itemMenuDom[domName].parentNode !== null) {
				itemMenuDom[domName].parentNode.removeChild(itemMenuDom[domName]);
			}
		}
	});
}

export function openJourneySettingsPanel(journey) {
	openDialogPanel(null, journey.Name, journey, false, null, "journey", "journey-options", openJourneySettingsPanel);

	var itemMenuDom = {
		editJourney: document.getElementById("journey-function-edit-journey"),
		deleteJourney: document.getElementById("journey-function-delete-journey"),
	};

	_.each(itemMenuDom, function (item) {
		item.classList.remove("disabled");
	});

	if (user.isAnonymous || user.canEditJourneys === undefined || !user.canEditJourneys) {
		disableSettingsMenuItem(itemMenuDom.editJourney);
		disableSettingsMenuItem(itemMenuDom.deleteJourney);
	}

	// remove some options altogether based on menu exclusion options
	_.each(options.assetMenuExclude, function (excluded) {
		var domName = dashToCamelCase(excluded);
		if (itemMenuDom[domName] !== undefined) {
			if (itemMenuDom[domName].parentNode !== null) {
				itemMenuDom[domName].parentNode.removeChild(itemMenuDom[domName]);
			}
		}
	});
}

function disableSettingsMenuItem(item) {
	if (item !== undefined && item !== null) {
		item.classList.add("disabled");
		item.querySelector("a").classList.add("disabled");
	}
}

export function openFenceSettingsPanel(fence) {
	openDialogPanel(null, fence.Name, fence, false, null, "fence", "fence-options", openFenceSettingsPanel);

	var fenceMenuDom = {
		message: document.getElementById("fence-function-message"),
		historyReplay: document.getElementById("fence-function-history-replay"),
		groupAssets: document.getElementById("fence-function-group-assets"),
		addAlert: document.getElementById("fence-function-add-alert"),
		locationReport: document.getElementById("fence-function-location-report"),
		editFence: document.getElementById("fence-function-edit-fence"),
		deleteFence: document.getElementById("fence-function-delete-fence"),
	};

	_.each(fenceMenuDom, function (item) {
		item.classList.remove("disabled");
		item.querySelector("a").classList.remove("disabled");
	});

	var hasNoAssets = findAssetIdsInGeofence(fence).length == 0;

	// enable/disable menu items based on user permissions and fence status
	if (user.isAnonymous || hasNoAssets) {
		disableSettingsMenuItem(fenceMenuDom.message);
		disableSettingsMenuItem(fenceMenuDom.groupAssets);
		disableSettingsMenuItem(fenceMenuDom.locationReport);
	}

	if (hasNoAssets) {
		disableSettingsMenuItem(fenceMenuDom.historyReplay);
	}

	if (user.isAnonymous) {
		disableSettingsMenuItem(fenceMenuDom.addAlert);
	}

	if (user.isAnonymous || user.canEditGeofences === undefined || !user.canEditGeofences) {
		disableSettingsMenuItem(fenceMenuDom.editFence);
		disableSettingsMenuItem(fenceMenuDom.deleteFence);
	}

	// remove some options altogether based on menu exclusion options
	_.each(options.assetMenuExclude, function (excluded) {
		var domName = dashToCamelCase(excluded);
		if (fenceMenuDom[domName] !== undefined) {
			if (fenceMenuDom[domName].parentNode !== null) {
				fenceMenuDom[domName].parentNode.removeChild(fenceMenuDom[domName]);
			}
		}
	});
}

export function openAssetSettingsPanel(asset) {
	openDialogPanel(null, asset.Name, asset, false, null, "asset", "asset-options", openAssetSettingsPanel);

	var assetMenuDom = {
		alerts: document.getElementById("asset-function-alerts"),
		events: document.getElementById("asset-function-events"),
		status: document.getElementById("asset-function-status"),
		messages: document.getElementById("asset-function-messages"),
		currentPosition: document.getElementById("asset-function-current-position"),
		followAsset: document.getElementById("asset-function-follow-asset"),
		historyReplay: document.getElementById("asset-function-history-replay"),
		sendWaypoint: document.getElementById("asset-function-send-waypoint"),
		setWaypoint: document.getElementById("asset-function-set-waypoint"),
		addPosition: document.getElementById("asset-function-add-position"),
		routeAsset: document.getElementById("asset-function-route-asset"),
		sendMessage: document.getElementById("asset-function-send-message"),
		sendCommand: document.getElementById("asset-function-send-command"),
		setOutput: document.getElementById("asset-function-set-output"),
		setDriver: document.getElementById("asset-function-set-driver"),
		viewLogs: document.getElementById("asset-function-view-logs"),
		viewLogsMessage: document.getElementById("asset-function-view-logs-message"),
		viewLogsIO: document.getElementById("asset-function-view-logs-io"),
		viewLogsDriver: document.getElementById("asset-function-view-logs-driver"),
		viewLogsWaypoint: document.getElementById("asset-function-view-logs-waypoint"),
		viewLogsRefuel: document.getElementById("asset-function-view-logs-refuel"),
		viewLogsServiceMeters: document.getElementById("asset-function-view-logs-service-meters"),
		viewLogsGarminForms: document.getElementById("asset-function-view-logs-garmin-forms"),
		editAsset: document.getElementById("asset-function-edit-asset"),
		deleteAsset: document.getElementById("asset-function-delete-asset"),
	};

	_.each(assetMenuDom, function (item) {
		if (item !== null) {
			item.classList.remove("disabled");
			item.querySelector("a").classList.remove("disabled");
		}
	});

	// enable/disable menu items based on asset capabilities
	var device = findDeviceById(asset.DeviceId);

	var currentPosition = trkData.live.latestPositionsByAssetId[asset.Id];
	if (currentPosition === undefined) {
		disableSettingsMenuItem(assetMenuDom.currentPosition);
	}

	if (_.indexOf(state.liveFollow.assets, asset) === -1) {
		assetMenuDom.followAsset.querySelector(".item-action-text").textContent = strings.FOLLOW_LIVE;
	} else {
		assetMenuDom.followAsset.querySelector(".item-action-text").textContent = strings.UNFOLLOW_LIVE;
	}

	if (
		trkData.places === null ||
		trkData.places.length === 0 ||
		options.enabledFeatures.indexOf("ASSET_ROUTING") === -1
	) {
		disableSettingsMenuItem(assetMenuDom.routeAsset);
	}

	if ((user.isAnonymous && !options.allowAnonymousMessaging) || !isMessagingEnabledForAsset(asset)) {
		if (assetMenuDom.messages !== null) {
			disableSettingsMenuItem(assetMenuDom.messages);
		}
		disableSettingsMenuItem(assetMenuDom.sendMessage);
	}

	if (user.isAnonymous || !isWaypointEnabledForAsset(asset) || options.enabledFeatures.indexOf("WAYPOINTS") === -1) {
		disableSettingsMenuItem(assetMenuDom.sendWaypoint);
	}

	if (user.isAnonymous || options.enabledFeatures.indexOf("WAYPOINTS") === -1) {
		disableSettingsMenuItem(assetMenuDom.setWaypoint);
	}

	if (
		user.isAnonymous ||
		user.canSendCommands === undefined ||
		!user.canSendCommands ||
		!isOutputEnabledForAsset(asset) ||
		options.enabledFeatures.indexOf("REMOTE_MANAGEMENT") === -1
	) {
		disableSettingsMenuItem(assetMenuDom.setOutput);
	}

	if (
		user.isAnonymous ||
		user.canEditAssets === undefined ||
		!user.canEditAssets ||
		options.enabledFeatures.indexOf("DRIVER_MANAGEMENT") === -1
	) {
		disableSettingsMenuItem(assetMenuDom.setDriver);
	}

	if (assetMenuDom.addPosition !== null) {
		if (user.isAnonymous || user.canEditAssets === undefined || !user.canEditAssets) {
			disableSettingsMenuItem(assetMenuDom.addPosition);
		}
	}

	if (user.isAnonymous || options.enabledFeatures.indexOf("LOGS_OBDII") === -1) {
	}
	if (user.isAnonymous || options.enabledFeatures.indexOf("LOGS_FUEL") === -1) {
		disableSettingsMenuItem(assetMenuDom.viewLogsRefuel);
	}
	if (user.isAnonymous || options.enabledFeatures.indexOf("LOGS_SERVICE_METER") === -1) {
		disableSettingsMenuItem(assetMenuDom.viewLogsServiceMeters);
	}
	if (
		(user.isAnonymous && !options.allowAnonymousMessaging) ||
		options.enabledFeatures.indexOf("LOGS_MESSAGE") === -1
	) {
		disableSettingsMenuItem(assetMenuDom.viewLogsMessage);
	}
	if (user.isAnonymous || options.enabledFeatures.indexOf("LOGS_DRIVER") === -1) {
		disableSettingsMenuItem(assetMenuDom.viewLogsDriver);
	}
	if (user.isAnonymous || options.enabledFeatures.indexOf("LOGS_WAYPOINT") === -1) {
		disableSettingsMenuItem(assetMenuDom.viewLogsWaypoint);
	}
	if (user.isAnonymous || options.enabledFeatures.indexOf("IO_MAPPING") === -1) {
		disableSettingsMenuItem(assetMenuDom.viewLogsIO);
	}

	if (assetMenuDom.viewLogsGarminForms !== null) {
		if (user.isAnonymous || options.enabledFeatures.indexOf("GARMIN_INTEGRATION") === -1) {
			disableSettingsMenuItem(assetMenuDom.viewLogsGarminForms);
		}
	}

	if (!isServiceMeterEnabledForAsset(asset)) {
		disableSettingsMenuItem(assetMenuDom.viewLogsServiceMeters);
	}

	if (!device.SupportsGarminForms) {
		disableSettingsMenuItem(assetMenuDom.viewLogsGarminForms);
	}

	if (isSendCommandDisabledForDevice(device)) {
		disableSettingsMenuItem(assetMenuDom.sendCommand);
	}

	if (user.isAnonymous || user.canEditAssets === undefined || !user.canEditAssets) {
		disableSettingsMenuItem(assetMenuDom.editAsset);
	}

	if (
		user.isAnonymous ||
		user.canDeleteAssets === undefined ||
		!user.canDeleteAssets ||
		user.limitAssetEditing ||
		user.assetControlFederated
	) {
		disableSettingsMenuItem(assetMenuDom.deleteAsset);
	}

	// remove some options altogether based on menu exclusion options
	_.each(options.assetMenuExclude, function (excluded) {
		var domName = dashToCamelCase(excluded);
		if (assetMenuDom[domName] !== undefined && assetMenuDom[domName] !== null) {
			if (assetMenuDom[domName].parentNode !== null) {
				assetMenuDom[domName].parentNode.removeChild(assetMenuDom[domName]);
			}
		}
	});
}

export function openSharedViewSettingsPanel(sharedView) {
	openDialogPanel(
		null,
		sharedView.Name,
		sharedView,
		false,
		checkForShareViewChange(sharedView),
		"shared-view",
		"shared-view-options",
		openSharedViewSettingsPanel
	);

	domNodes.panels.secondary.setAttribute("data-chat-enabled", sharedView.IsMessagingEnabled);

	if (trkData.sharedView.current !== sharedView) {
		querySharedViewData(sharedView, null, false);
	}
	trkData.sharedView.current = sharedView;
	$(domNodes.infoDialogs.sharedViewInformation).dialog("close");

	var sharedViewMenuDom = {
		sharedViewVisitorStatistics: document.getElementById("shared-view-function-statistics"),
		disableSharedView: document.getElementById("shared-view-function-disable"),
		sharedViewAccessControls: document.getElementById("shared-view-function-access-controls"),
		sharedViewDataVisualization: document.getElementById("shared-view-function-data-visualization"),
		sharedViewDetails: document.getElementById("shared-view-function-details"),
		sharedViewMapSettings: document.getElementById("shared-view-function-map-settings"),
		sharedViewPermissions: document.getElementById("shared-view-function-permissions"),
		sharedViewPreferences: document.getElementById("shared-view-function-preferences"),
		deleteSharedView: document.getElementById("shared-view-function-delete"),
	};

	// enable/disable menu items based on user permissions and shared view status

	var statusButtonIcon = sharedViewMenuDom.disableSharedView.querySelector("use");
	var statusButtonText = sharedViewMenuDom.disableSharedView.querySelector("span");
	if (sharedView.IsEnabled) {
		statusButtonIcon.setAttributeNS("http://www.w3.org/1999/xlink", "href", svgPath("ban"));
		statusButtonText.textContent = strings.DISABLE;
	} else {
		statusButtonIcon.setAttributeNS("http://www.w3.org/1999/xlink", "href", svgPath("check"));
		statusButtonText.textContent = strings.ENABLE;
	}

	// populate share links
	if (
		user.isAnonymous ||
		user.canEditSharedViews === undefined ||
		!user.canEditSharedViews ||
		options.enabledFeatures.indexOf("VIEWS_SHARED") === -1
	) {
		_.each(sharedViewMenuDom, function (item) {
			item.classList.add("disabled");
		});
	} else {
		_.each(sharedViewMenuDom, function (item) {
			item.classList.remove("disabled");
		});
	}

	// remove some options altogether based on menu exclusion options
	_.each(options.assetMenuExclude, function (excluded) {
		var domName = dashToCamelCase(excluded);
		if (sharedViewMenuDom[domName] !== undefined) {
			if (sharedViewMenuDom[domName].parentNode !== null) {
				sharedViewMenuDom[domName].parentNode.removeChild(sharedViewMenuDom[domName]);
			}
		}
	});
}

function dashToCamelCase(input) {
	return input.replace(/-([a-z])/gi, function ($0, $1) {
		return $1.toUpperCase();
	});
}
