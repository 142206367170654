import user from "./user.js";
import strings from "./strings.js";
import { disabledCheckboxEl } from "./dom-util.js";

import $ from "jquery";
import { el } from "redom"; // https://redom.js.org/

export function initDatatables() {
	$("#AssetAlerts").dataTable({
		destroy: true,
		filter: false,
		info: false,
		jQueryUI: false,
		autoWidth: false,
		lengthChange: false,
		paging: false,
		pageLength: 3,
		deferRender: true,
		order: [[2, "asc"]],
		columnDefs: [
			{
				targets: "_all",
				render: $.fn.dataTable.render.text(),
			},
		],
		columns: [
			{ visible: false }, // id
			{ visible: user.canEditAlerts, sortable: false, class: "center", render: renderDomElement }, // edit
			{}, // name
			{}, // description
			{}, // condition
			{ render: renderDomElement }, // filters
			{ visible: user.canEditAlerts, sortable: false, class: "center", render: renderDomElement }, // remove
		],
		language: strings.DATATABLE,
		drawCallback: function (oSettings) {},
		initComplete: function (oSettings, json) {
			$("#AssetAlerts").DataTable().clear();
		},
	});
}

export function renderDomElement(data, type, row) {
	if (data === undefined || data === null) {
		return "";
	}
	if (data instanceof Element || data instanceof HTMLDocument) {
		return data.outerHTML;
	}
	return "";
}

export function renderIDPCommandLog(commandLog) {
	var commandLogData = [];
	for (var i = 0; i < commandLog.length; i++) {
		var item = commandLog[i];
		commandLogData.push([
			item.CreatedOn,
			el("pre", item.Command),
			disabledCheckboxEl(item.IsSent),
			disabledCheckboxEl(item.IsResponse),
			disabledCheckboxEl(item.Success),
		]);
	}

	$("#IDPCommandLog").dataTable({
		data: commandLogData,
		destroy: true,
		filter: false,
		info: false,
		jQueryUI: true,
		autoWidth: false,
		lengthChange: false,
		paging: false,
		pageLength: 5,
		deferRender: true,
		order: [[0, "asc"]],
		columnDefs: [
			{
				targets: "_all",
				render: $.fn.dataTable.render.text(),
			},
		],
		columns: [
			{},
			{ render: renderDomElement },
			{ render: renderDomElement },
			{ render: renderDomElement },
			{ render: renderDomElement },
		],
		language: strings.DATATABLE,
	});
}
