import domNodes from "./domNodes.js";
import { findAssetById } from "./assets.js";
import strings from "./strings.js";
import { wrapUrl } from "./wrapurl.js";
import { handleWebServiceError } from "./ajax.js";
import { openDialogPanel } from "./panel-nav.js";
import { handleAjaxFormSubmission, toggleLoadingMessage, formShowErrorMessage } from "./ajax.js";
import { renderDomElement } from "./datatables.js";
import { showOrLoadAssetPosition } from "./asset-positions.js";
import { convertToLatLngPreference } from "./preferences.js";
import { formattedTextToDiv } from "./dom-util.js";

import $ from "jquery";
import $j from "jquery";
import { el } from "redom"; // https://redom.js.org/

export function initGarmin() {
	$("#asset-events-dialog").on("click", "button.ViewGarminSubmission", function (e) {
		e.preventDefault();
		var submissionId = $j(this).data("id");
		var assetId = $j(this).data("assetId");
		loadGarminFormSubmission(assetId, submissionId, null);
	});
	$j("#event-data").on("click", "button.ViewGarminSubmission", function (e) {
		e.preventDefault();

		var submissionId = $j(this).data("id");
		var assetId = $j(this).data("assetId");
		var positionId = $j(this).parent().parent().find("a.location").attr("data-marker");
		if (positionId != null) {
			$j.when(showOrLoadAssetPosition(positionId, assetId)).done(function () {
				// activate garmin form panel
				$("#accordion-garmin-content").collapse("show");
			});
		} else {
			loadGarminFormSubmission(assetId, submissionId, null);
		}
	});

	$(domNodes.dialogs.garminFormsHistory).on("click", "#RefreshGarminFormLogs", function (e) {
		e.preventDefault();
		var assetId = $(domNodes.dialogs.garminFormsHistory).data("assetId");
		var asset = findAssetById(assetId);
		if (asset != null) {
			loadGarminFormsHistory(asset);
		}
	});
	$(domNodes.dialogs.garminFormsHistory).on("click", "button.ViewGarminSubmission", function (e) {
		e.preventDefault();

		var submissionId = $j(this).data("id");
		var assetId = $(domNodes.dialogs.garminFormsHistory).data("assetId");

		var positionId = $j(this).parent().parent().find("a.location").attr("data-marker");
		if (positionId != null) {
			$j.when(showOrLoadAssetPosition(positionId, assetId)).done(function () {
				// activate garmin form panel for position
				$("#accordion-garmin-content").collapse("show");
			});
		} else {
			loadGarminFormSubmission(assetId, submissionId, null);
		}
	});
}

export function loadGarminFormSubmission(assetId, submissionId, panel) {
	toggleLoadingMessage(true, "form-submission");
	var data = { assetId: assetId, submissionId: submissionId };
	$j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetGarminFormSubmissionForAsset"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					showGarminFormSubmission(result.Item, panel);
				} else {
					handleWebServiceError(strings.MSG_GARMIN_FORM_SUBMISSION_ERROR);
				}
			}
			toggleLoadingMessage(false, "form-submission");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_GARMIN_FORM_SUBMISSION_ERROR);
			toggleLoadingMessage(false, "form-submission");
		},
	});
}

export function loadGarminFormsHistoryDialog(asset) {
	$(domNodes.dialogs.garminFormsHistory).data("assetId", asset.Id);
	openDialogPanel(
		domNodes.dialogs.garminFormsHistory,
		strings.GARMIN_FORMS,
		asset,
		true,
		null,
		"asset",
		"view-logs-garmin-forms",
		loadGarminFormsHistoryDialog
	);
	loadGarminFormsHistory(asset);
}

function loadGarminFormsHistory(asset) {
	var btn = document.getElementById("RefreshGarminFormLogs");
	var status = document.getElementById("garmin-forms-status");
	var data = { assetId: asset.Id };

	handleAjaxFormSubmission(
		"GetGarminFormSubmissionHistoryForAsset",
		data,
		btn,
		status,
		null,
		strings.MSG_GARMIN_FORMS_HISTORY_ERROR,
		function (result) {
			if (result.Success == true) {
				var itemData = [];
				if (result.Items != null) {
					for (var i = 0; i < result.Items.length; i++) {
						var item = result.Items[i];
						var address = "";
						var address = null;
						if (item.Position != null) {
							address = item.Position.Address;
							if (address == null || address.trim() == "" || asset.HideAddress) {
								address = convertToLatLngPreference(
									item.Position.DisplayLat,
									item.Position.DisplayLng,
									item.Position.Grid
								);
							}
							address = el("a.location", { href: "#", dataset: { marker: item.Position.Id } }, address);
						}
						itemData.push([
							item.Id,
							item.Title,
							address,
							el(
								"button.ViewGarminSubmission.command.details.btn.btn-secondary",
								{ dataset: { assetId: asset.Id, id: item.Id } },
								strings.VIEW
							),
							item.CreatedOn,
						]);
					}
				}

				$("#GarminFormLogs").dataTable({
					data: itemData,
					destroy: true,
					filter: false,
					info: false,
					jQueryUI: true,
					autoWidth: false,
					lengthChange: false,
					paging: true,
					pageLength: 10,
					deferRender: true,
					order: [[4, "desc"]],
					columnDefs: [
						{
							targets: "_all",
							render: $.fn.dataTable.render.text(),
						},
					],
					columns: [
						{ visible: false }, // id
						{}, // title
						{ render: renderDomElement }, // address
						{ sortable: false, render: renderDomElement }, // view submission
						{ width: "75px" }, // createdon
					],
					language: strings.DATATABLE,
				});

				$j("#GarminFormLogs").removeAttr("style");
			} else {
				formShowErrorMessage(status, strings.MSG_GARMIN_FORMS_HISTORY_ERROR);
			}
		}
	);
}

function showGarminFormSubmission(submission, panel) {
	var dialog = $j(domNodes.infoDialogs.garminSubmission);
	var openDialog = false;
	if (panel == null) {
		panel = dialog;
		openDialog = true;
		setDialogTitle(dialog, submission.Title);
	}
	var title = $j(".GarminSubmissionTitle", panel).text(submission.Title);
	var date = $j(".GarminSubmissionDate", panel).text(submission.CreatedOn);
	var items = $j(".GarminSubmissionItems", panel).empty();
	for (var i = 0; i < submission.Items.length; i++) {
		var item = submission.Items[i];
		var itemText = item.Title;
		if (item.Subtitle != null && item.Subtitle != "" && item.Subtitle != item.Title) {
			itemText += " - " + item.Subtitle;
		}
		var itemLI = $j("<li>").text(itemText);
		var itemUL = $j("<ul>");

		if (item.Choices != null) {
			for (var j = 0; j < item.Choices.length; j++) {
				var choice = item.Choices[j];
				itemUL.append(
					$j("<li>").append(
						$j('<div class="custom-control custom-checkbox">')
							.append(
								$j('<input class="custom-control-input" type="checkbox" disabled="disabled">').attr(
									"checked",
									choice.IsSelected
								)
							)
							.append($j('<label class="custom-control-label">').text(choice.Value))
					)
				);
			}
			itemUL.addClass("multiple");
		} else if (item.Waypoint != null) {
			itemUL.append(
				$j("<li>")
					.append(
						$j('<a class="location" href="#">')
							.text(item.Waypoint.Name)
							.data("location", item.Waypoint.Location)
							.data("name", item.Waypoint.Name)
					)
					.append($j("<br />"))
					.append($j("<span>").text(strings.MESSAGE + ": " + item.Waypoint.Message))
					.append($j("<br />"))
					.append($j("<span>").text(strings.STATUS + ": " + item.Waypoint.Status))
			);
		} else {
			itemUL.append(el("li", formattedTextToDiv(item.Choice)));
		}
		itemLI.append(itemUL);
		items.append(itemLI);
	}
	if (openDialog) {
		dialog.dialog("open");
	}
}

function setDialogTitle(dialog, title) {
	$j(dialog).dialog("option", "title", title);
}
