<li class="fences-item group-item place-{{id}} {{visibilityClass}}" data-fence-id="{{id}}">
    <div class="item-header d-flex flex-row align-items-center" style="background-image: url({{icon}});">
        {{#if isVisible}}
        <svg class="list-item-action showhide active" data-fence-id="{{id}}"><use xlink:href="/content/svg/tracking.svg?v=15#visible"></use></svg>
        {{else}}
        <svg class="list-item-action showhide" data-fence-id="{{id}}"><use xlink:href="/content/svg/tracking.svg?v=15#invisible"></use></svg>
        {{/if}}
        <div class="item-info expand mr-auto">
            <div class="fence-name">{{name}}</div>
            <div class="fence-description">{{description}}</div>
        </div>
        <svg class="list-item-action item-drag"><use xlink:href="/content/svg/tracking.svg?v=15#sort"></use></svg>
        <svg class="list-item-action item-settings" data-fence-id="{{id}}"><use xlink:href="/content/svg/tracking.svg?v=15#settings"></use></svg>
    </div>
</li>
