import {
	// isWaypointEnabledForAsset,
	// isMessagingEnabledForAssetGroup,
	// isMessagingEnabledForAsset,
	// isOutputEnabledForAsset,
	// isServiceMeterEnabledForAsset,
	getCurrentMarkerForAsset,
} from "./assets.js";
import { toggleLoadingMessage } from "./ajax.js";
import { findAssetById } from "./assets.js";
import { language } from "./const.js";
import { map } from "./map-base.js";
import preferences from "./preferences.js";
import strings from "./strings.js";
import domNodes from "./domNodes.js";
import { handleWebServiceError } from "./ajax.js";
import { wrapUrl } from "./wrapurl.js";
import { getOSRMLanguage } from "./routing.js";

import L from "leaflet";
import $ from "jquery";

//// Also possible: import L_Routing from "leaflet-routing-machine"
//import "leaflet-routing-machine/src/index.js";
import "../legacy/leaflet-routing-machine-all.js"; // Customized LRM

const waypointMarkers = [];
export { waypointMarkers };

export function waypointGetRoute(waypoint) {
	if (waypoint == null) {
		return;
	}
	var asset = findAssetById(waypoint.AssetId);

	if (asset == null) {
		return;
	}

	var assetMarker = getCurrentMarkerForAsset(asset);
	if (assetMarker === undefined) {
		return;
	}
	var waypointMarker = null;
	for (var i = 0; i < waypointMarkers.length; i++) {
		var wpId = waypointMarkers[i].data.waypointId;
		if (waypoint.Id == wpId) {
			waypointMarker = waypointMarkers[i];
			break;
		}
	}
	var assetLocation = assetMarker.data.location;
	var waypointLocation = waypointMarker.data.location;
	var route = {
		origin: L.latLng(assetLocation.Lat, assetLocation.Lng),
		destination: L.latLng(waypointLocation.Lat, waypointLocation.Lng),
	};

	waypointClearRoute(waypoint);

	// new routing service just for this waypoint and asset
	var routingService = L.Routing.control({
		router: new L.Routing.OSRMv1({
			serviceUrl: "/api/routing/route",
			language: getOSRMLanguage(language),
		}),
		formatter: new L.Routing.Formatter({
			language: getOSRMLanguage(language),
			units: preferences.PREFERENCE_SPEED === 1 ? "metric" : "imperial",
		}),
		useZoomParameter: false,
	})
		.on("routingerror", function (e) {
			$("#WaypointRoute").show().text(strings.WAYPOINT_ROUTE_ERROR).addClass("error");
		})
		.on("routeselected", function (e) {
			waypoint.route = e.route;
			$("#WaypointRoute").show().removeClass("error");
			// show clear route button
			$("#WaypointRemoveRoute").show();
			$("#WaypointGetRoute").hide();
		});
	routingService.control = routingService.onAdd(map);
	routingService.setWaypoints([route.origin, route.destination]);
	$("#WaypointRoutePanel").append(routingService.control);
	waypoint.routingService = routingService;
}

export function waypointClearRoute(waypoint) {
	if (waypoint == null) {
		return;
	}

	if (waypoint.route != null) {
		waypoint.route = null;
		if (waypoint.routingService != null) {
			waypoint.routingService.remove();
		}
	}
	$("#WaypointRemoveRoute").hide();
	$("#WaypointGetRoute").show();
}

export function waypointMarkComplete(waypoint) {
	if (waypoint == null) {
		return;
	}

	var asset = findAssetById(waypoint.AssetId);
	if (asset == null) {
		return;
	}

	var button = document.getElementById("WaypointMarkComplete");

	var key = "waypoint-complete";
	toggleLoadingMessage(true, key);
	if (button !== null) {
		button.disabled = true;
	}
	var dataPost = {
		assetId: asset.Id,
		id: waypoint.Id,
	};
	$.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/WaypointMarkComplete"),
		data: JSON.stringify(dataPost),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			var result = msg.d;
			if (result) {
				if (result.Success != true) {
					handleWebServiceError(strings.WAYPOINT_COMPLETE_ERROR);
				}
			}
			toggleLoadingMessage(false, key);
			$(domNodes.infoDialogs.mapItemInformation).dialog("close");
			if (button !== null) {
				button.disabled = false;
			}
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.WAYPOINT_COMPLETE_ERROR);
			toggleLoadingMessage(false, key);
			if (button !== null) {
				button.disabled = false;
			}
		},
	});
}
